import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import getIAPTById from "../../../utils/getIAPTById"
import { IIAPTService } from "../../../models/IIAPTService"
import { DiscussionSteps } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = BaseScriptState
export type SelfReferralPitchCCGScriptState = State
export class SelfReferralPitchCCGScript extends BaseScript<State> {
  readonly name: string = "SelfReferralPitchCCGScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return {
      body: ["No problem!", "I'm going to book that in for you"],
      prompt: {
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "Sounds interesting" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.askSelectIAPTService
    }
  }

  @step.logState
  handleHelpDecide1(d: IStepData<State, "more" | "bookMeIn">): IStepResult {
    if (d.response === "bookMeIn") return { nextStep: this.askSelectIAPTService }
    return { nextStep: this.helpDecide2 }
  }

  @step.logState
  helpDecide2(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Talking therapies can help you deal with negative thoughts and feelings, and make positive changes",
        'The NHS use a specific therapy called "cognitive behavioural therapy", which can be more effective than medication for adults experiencing similar issues to you'
      ],
      prompt: {
        id: this.getPromptId("helpDecide2"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Tell me more", value: "more" },
          { body: "I'm convinced - let's book a call", value: "bookMeIn" }
        ]
      },
      nextStep: this.handleHelpDecide2
    }
  }

  @step.logState
  handleHelpDecide2(d: IStepData<State, "more" | "bookMeIn">): IStepResult {
    if (d.response === "bookMeIn") return { nextStep: this.askSelectIAPTService }
    return { nextStep: this.helpDecide3 }
  }

  @step.logState
  helpDecide3(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Given you've just been through the toughest year in NHS history, I really think talking to a professional might help you...",
        "...even if it's just a little",
        "It can't hurt, right?"
      ],
      prompt: {
        id: this.getPromptId("helpDecide3"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay, book me in for a call", value: true },
          { body: "No thanks - I don't want to book a call", value: false }
        ]
      },
      nextStep: this.handleHelpDecide3
    }
  }

  @step.logState
  handleHelpDecide3(d: IStepData<State, boolean>): IStepResult {
    if (d.response) return { nextStep: this.askSelectIAPTService }
    return { nextStep: this.suggestWellbeing }
  }

  @step.logState
  suggestWellbeing(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Okay ${name}, it's your decision`,
        "If you don't want to speak to a health professional, I think you would really benefit from some online materials about wellbeing"
      ],
      prompt: {
        id: this.getPromptId("sayDeclined"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: false },
          { body: "Actually, book me in for a chat with a mental health professional", value: true }
        ]
      },
      nextStep: this.handleWellbeing
    }
  }

  @step.logState
  handleWellbeing(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return {
        body: "No problem!",
        nextStep: this.askSelectIAPTService
      }
    }
    return { nextStep: this.goToWellBeing }
  }

  @step.logState
  askSelectIAPTService(d: IStepData<State>): IStepResult {
    const eligibleIAPTIds = this.rootStore.configStore.eligibleIAPTIds
    const iapts = eligibleIAPTIds.map(id => getIAPTById(id)).filter(Boolean) as IIAPTService[]
    if (!iapts.length) {
      this.logBreadcrumb("[CCG] NO ELIGIBLE IAPTs", d.state, { iapts })
      this.logMessage("[CCG] NO ELIGIBLE IAPTs")
      return { nextStep: this.sayIllReferYou }
    }
    return {
      body: "Which service would you like to be referred into?",
      prompt: {
        id: this.getPromptId("askSelectIAPTService"),
        trackResponse: true,
        type: "inlinePicker",
        choices: iapts.map(iapt => ({ body: iapt.formattedName, value: iapt }))
      },
      nextStep: this.handleSelectIAPTService
    }
  }

  @step.logState
  handleSelectIAPTService(d: IStepData<State, IIAPTService>): IStepResult {
    this.setIAPT(d.state, d.response)
    return { nextStep: this.sayIllReferYou }
  }

  @step.logState
  sayIllReferYou(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: `I'm now going to add you as a new referral to ${iaptName}`,
      prompt: {
        id: this.getPromptId("step1"),
        type: "inlinePicker",
        choices: [{ body: "Great" }, { body: "Okay" }]
      },
      nextStep: this.sayThisWillTake5Minutes
    }
  }

  @step.logState
  sayThisWillTake5Minutes(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "There are just a few more details I need in order to do this",
        "This should take no more than 5 minutes - is that okay?"
      ],
      prompt: {
        id: this.getPromptId("sayThisWillTake5Minutes"),
        type: "inlinePicker",
        choices: [{ body: "Sure" }, { body: "Okay" }]
      },
      nextStep: this.handleThisWillTake5Minutes
    }
  }

  @step.logState
  handleThisWillTake5Minutes(_d: IStepData<State>): IStepResult {
    return {
      body: "Brilliant",
      nextStep: this.end
    }
  }

  @step.logState
  goToWellBeing(d: IStepData<State>): IStepResult {
    const WellBeingDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.WellbeingHub)
    return {
      nextDialogue: WellBeingDialogue //
        ? new WellBeingDialogue({ ...d.state })
        : undefined,
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class SelfReferralPitchDialogueCCG extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralPitchCCG
  readonly name: string = "SelfReferralPitchDialogueCCG"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralPitchDialogueCCG.id, new SelfReferralPitchCCGScript(), state, snapshot)
  }
}
