import moment from "moment"
import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptStateSchema } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { GENDER_VITALITY, ReferralPayloadVitality } from "@limbic/types"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import invariant from "../../../utils/invariant"
import { step } from "../../../backend/chatbot/decorators/step"
import { DiscussionSteps, TrackingEvents, VitalityTitles } from "../../../models/Constants"
import { getGPServicesByName } from "../../../backend/api/nhs"
import ISelectable from "../../../models/ISelectable"
import { IGPService } from "../../../models/IGPService"

interface State extends SelfReferralIAPTScriptState {
  preferredTitle?: VitalityTitles
  appointment?: string
  hideEarlierYouSaid?: boolean
  birthday?: number
  claimNumber?: string
  source?: "telephone" | "memberzone"
  assessmentPreference?: "telephone" | "digital"
  gpSurgeryNameEntered?: string
  gpAddressEntered?: string
}

export type SelfReferralVitalityScriptState = State

export const SelfReferralVitalityScriptStateSchema = SelfReferralIAPTScriptStateSchema.extend({
  preferredTitle: z
    .union([z.literal("Mr"), z.literal("Ms"), z.literal("Mrs"), z.literal("Miss"), z.literal("Mx")])
    .optional(),
  appointment: z.string().optional(),
  hideEarlierYouSaid: z.boolean().optional(),
  birthday: z.number().optional(),
  claimNumber: z.string().optional(),
  source: z.union([z.literal("telephone"), z.literal("memberzone")]).optional(),
  assessmentPreference: z.union([z.literal("telephone"), z.literal("digital")]).optional(),
  gpSurgeryNameEntered: z.string().optional(),
  gpAddressEntered: z.string().optional()
})

export class SelfReferralVitalityScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralVitalityScript"

  /** Script Steps */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0 })
  sayIntro(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return {
      body: ["I just need to collect some basic information from you", "Firstly..."],
      nextStep: this.askGender
    }
  }

  @step
  askGender(_d: IStepData<State>): IStepResult {
    const genders = this.getGenders()
    return {
      body: "Which gender do you identify as?",
      prompt: {
        id: this.getPromptId("askGender"),
        trackResponse: true,
        type: "inlinePicker",
        choices: genders.map(g => ({ body: g, value: g })),
        dataPointsName: "askGender"
      },
      nextStep: this.handleGender
    }
  }

  @step.logStateAndResponse
  async handleGender(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.gender = d.response
    this.referralStore.setCustomField<State>("gender", d.response)
    this.setPeople({ gender: d.response })
    this.track(d.response)
    // In the edge case that crisis was trigerred before this
    // the phone number may already be available, so we shouldn't
    // ask for it again
    if (d.state.phoneNumber) {
      return { nextStep: this.askDoYouHaveAnEmail }
    }
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  askDoYouHaveAnEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you also please share an email address?",
      prompt: {
        id: this.getPromptId("askDoYouHaveAnEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Ok", value: true },
          { body: "No, I'd rather not", value: false }
        ]
      },
      nextStep: this.handleDoYouHaveAnEmail
    }
  }

  @step.logState
  askGP(_d: IStepData<State>): IStepResult {
    return {
      body: "And what's the name of your GP",
      prompt: {
        id: this.getPromptId("askGP"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleGPWithCrisis
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralVitalityScript) => s.askGPAddress
  })
  handleGPWithCrisis(d: IStepData<State, string>): IStepResult {
    d.state.gpSurgeryNameEntered = d.response
    return { nextStep: this.askSelectGPByName }
  }

  @step.logState
  async askSelectGPByName(d: IStepData<State>): Promise<IStepResult> {
    const gpServices = await getGPServicesByName(d.state.gpSurgeryNameEntered)
    if (!gpServices?.data?.length) {
      return { nextStep: this.askGPAddress }
    }

    return {
      body: [
        "I've found a few GPs matching the name you typed",
        "Are you registered with any of the following? (Please select)"
      ],
      prompt: {
        id: this.getPromptId("askSelectGPByName"),
        type: "inlinePicker",
        choices: (
          gpServices.data.map(gp => ({
            body: gp.formattedName,
            value: gp
          })) as ISelectable<any>[]
        ) //
          .concat(
            { body: "My GP is not on this list", value: "notListed", backgroundColor: "#EC9CC8" },
            { body: "I'm not sure", value: "notSure", backgroundColor: "#EC9CC8" }
          )
      },
      nextStep: this.handleSelectGPByName
    }
  }

  @step.logStateAndResponse
  handleSelectGPByName(d: IStepData<State, "notListed" | "notSure" | IGPService>): IStepResult {
    const body =
      d.response === "notListed"
        ? "My GP is not on this list"
        : d.response === "notSure"
        ? "I'm not sure"
        : "GP selected"
    this.track(TrackingEvents.SELECT_GP_BY_NAME, { body })
    if (["notListed", "notSure"].includes(d.response as string)) {
      const name = this.getName(d.state)
      return { body: `Ok ${name}, no worries`, nextStep: this.askGPAddress }
    }
    const gp = d.response as IGPService
    d.state.gpSurgeryNameEntered = gp.formattedName || gp.name || d.state.gpSurgeryNameEntered
    if (gp.address1) {
      d.state.gpAddressEntered = gp.address2 ? gp.address1 + ", " + gp.address2 : gp.address1
      return { nextStep: this.checkNeedToAskMore }
    }
    return { nextStep: this.askGPAddress }
  }

  @step.logState
  askGPAddress(_d: IStepData<State>): IStepResult {
    return {
      body: "And what's your GP's address?",
      prompt: {
        id: this.getPromptId("askGPAddress"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleGPAddressWithCrisis
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralVitalityScript) => s.checkNeedToAskMore
  })
  handleGPAddressWithCrisis(d: IStepData<State, string>): IStepResult {
    d.state.gpAddressEntered = d.response
    return { nextStep: this.checkNeedToAskMore }
  }

  @step.logState
  checkNeedToAskMore(d: IStepData<State>): IStepResult {
    this.saveCustomFieldsToState(d.state)
    const needsToAskMore = !d.state.birthday || !d.state.userPostcode?.postcode || !d.state.address
    if (needsToAskMore) return { nextStep: this.sayINeedAFewMoreDetails }
    return { nextStep: this.askWhatIsYourGoal }
  }

  @step.logState
  sayINeedAFewMoreDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "There are just a few more details I need from you",
      prompt: {
        id: this.getPromptId("sayINeedAFewMoreDetails"),
        type: "inlinePicker",
        choices: [{ body: "Sure" }, { body: "Okay" }]
      },
      nextStep: this.askBirthday
    }
  }

  @step.logState
  askBirthday(d: IStepData<State>): IStepResult {
    if (d.state.birthday) return { nextStep: this.startAddressFlow }

    return {
      body: "What's your date of birth?",
      prompt: {
        id: this.getPromptId("askBirthday"),
        trackResponse: true,
        type: "date",
        isUndoAble: true
      },
      nextStep: this.handleBirthday
    }
  }

  @step.logState
  sayPleaseGiveABirthday(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your date of birth",
      prompt: {
        id: this.getPromptId("sayPleaseGiveABirthday"),
        trackResponse: true,
        type: "date",
        isUndoAble: true
      },
      nextStep: this.handleBirthday
    }
  }

  @step
  handleBirthday(d: IStepData<State, number>): IStepResult {
    try {
      const date = moment(d.response)
      invariant(date, "I'm sorry that's not a valid date. Please enter your date of birth")
      invariant(
        date.isValid(),
        "I'm sorry that's not a valid date. Please enter your date of birth"
      )
      invariant(
        date.isBefore(moment()),
        "Hmm… I don’t think humans can time-travel. Can you try and edit your date of birth?"
      )
      invariant(
        date.isAfter(moment("1899-12-31")),
        "Hmm… I don’t think humans live that long. Can you try and edit your date of birth?"
      )
      d.state.birthday = date.toDate().getTime()
      this.setPeople({ age: moment().diff(date, "years") })
    } catch (e) {
      this.logException(e, "handleBirthday")
      return {
        body: e.message,
        nextStep: this.sayPleaseGiveABirthday
      }
    }
    return {
      body: "Thanks for sharing",
      nextStep: this.startAddressFlow
    }
  }

  @step.logState
  startAddressFlow(d: IStepData<State>): IStepResult {
    d.state.hideEarlierYouSaid = true
    if (!d.state.userPostcode?.postcode || !d.state.address) {
      return { nextStep: this.askPostCodeForAddressLookup }
    }
    return { nextStep: this.askWhatIsYourGoal }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    return { nextStep: this.checkUserAge }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const phoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data",
        "I've notified my creators of this issue",
        `Please call ${organisationName} on: ${phoneNumber}`
      ],
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  checkUserAge(d: IStepData<State>): IStepResult {
    const age = this.getUserAge(d.state)
    if (age < 18) {
      this.setUnderAged(d.state, true)
      return { nextStep: this.sayMinorSignposting }
    }
    return { nextStep: this.checkPreferredAssessment }
  }

  @step.logState
  sayMinorSignposting(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, I was designed to offer a digital assessment to people over the age of 18`,
        "As you are under 18, I need to send your referral directly to IPRS Health’s specialist referral team",
        "They will give you a call in the next 1 working day to arrange your initial assessment",
        "This team are available Monday-Friday, 9am-5pm. If you have any queries in the meantime you can contact IPRS Health on 0800 2545244"
      ],
      prompt: {
        id: this.getPromptId("sayMinorSignposting"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "I understand" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  checkPreferredAssessment(d: IStepData<State>): IStepResult {
    if (d.state.assessmentPreference === "telephone") {
      return { nextStep: this.goToBookAppointmentDialogue }
    }
    return { nextStep: this.end }
  }

  @step.logState
  goToBookAppointmentDialogue(d: IStepData<State>): IStepResult {
    const BookAppointmentDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.BookAppointment
    )
    const nextDialogue = BookAppointmentDialogue ? new BookAppointmentDialogue(d.state) : undefined
    return {
      nextDialogue,
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralVitalityScriptStateSchema
  }

  saveCustomFieldsToState(state: State): void {
    const dateOfBirth = this.referralStore.getCustomField("dateOfBirth") || undefined
    if (dateOfBirth) state.birthday = dateOfBirth

    // checking if they are also a string because I don't trust
    // what vitality will send via their api. They might send an
    // object while we're waiting a string and screw us up
    const address = this.referralStore.getCustomField("address") || undefined
    const postcode = this.referralStore.getCustomField("postCode") || undefined
    const hasPostcode = !!postcode && typeof postcode === "string"
    const hasAddress = !!address && typeof address === "string"
    if (hasPostcode && hasAddress) {
      // we don't care for anything else other than the postcode
      // property here, because it will only be used for address
      // lookup which only needs the actual postcode entry.
      // Of course, this is an injected postcode, which means it's
      // not a validated postcode so the address look up might fail
      state.userPostcode = { postcode, longitude: 0, latitude: 0, ccg: "", ccgId: "" }
      state.address = address
      state.city = address
      state.county = address
    }
  }

  async onHandleDoYouHaveAnEmail(state: State): Promise<IStepResult> {
    if (state.canSendEmail) return { nextStep: this.askEmail }
    return { nextStep: this.askGP }
  }

  async onHandleEmail(state: State): Promise<IStepResult> {
    return { nextStep: this.askGP }
  }

  async onHandleSelectAddressFromPostCode(_state: State): Promise<IStepResult> {
    return { nextStep: this.askWhatIsYourGoal }
  }

  async onCheckFullAddress(_state: State): Promise<IStepResult> {
    return { nextStep: this.askWhatIsYourGoal }
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadVitality> {
    const instanceID = this.referralStore.instanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      dob: moment(state.birthday).format("DD/MM/YYYY"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      email: state.email,
      consentEmail: !!state.email,
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      assessmentPreference: state.assessmentPreference,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      title: state.preferredTitle,
      gender: this.getGender(state),
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      gpSurgery: state.gpSurgeryNameEntered,
      gpAddress: state.gpAddressEntered,
      questionnaires: this.getQuestionnairesPayload(state),
      clinicalNotes: this.referralStore.clinicalNotes,
      clinicalFlags: this.clinicalStore.flags,
      problemDescriptorPrimary: this.clinicalStore.primaryProblems,
      problemDescriptorSecondary: this.clinicalStore.secondaryProblems,
      treatmentExpectation: state.therapyGoal,
      source: state.source,
      claimNumber: state.claimNumber,
      memberNumber: this.referralStore.getCustomField("entityId"),
      nextRenewalDate: this.referralStore.getCustomField("nextRenewalDate"),
      // 👇don't confuse this with the isEligible that is being
      // set in the eligibility dialogue, this is a different
      // field that is being injected into the bot from Vitality's
      // eligibility API
      isEligible: this.referralStore.getCustomField("isEligible"),
      excessAmount: this.referralStore.getCustomField("excessAmount"),
      excessType: this.referralStore.getCustomField("excessType"),
      staffMember: this.referralStore.getCustomField("staffMember"),
      planType: this.referralStore.getCustomField("planType"),
      coverType: this.referralStore.getCustomField("coverType")
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onRiskReferralFinished(state: State): Promise<void> {}

  getGenders(): string[] {
    return ["Male", "Female", "Intersex", "Non-binary", "Don't want to say"]
  }

  getGender(state: State): GENDER_VITALITY {
    const map: Record<string, GENDER_VITALITY> = {
      Male: "MALE",
      Female: "FEMALE",
      Intersex: "INTERSEX",
      "Non-binary": "NON_BINARY",
      "Don't want to say": "NOT_ANSWERED"
    }
    const gender = map[state.gender!]
    return gender ?? "UNKNOWN"
  }
}

/* istanbul ignore next */
export default class SelfReferralVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralVitality
  readonly name: string = "SelfReferralVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralVitalityDialogue.id, new SelfReferralVitalityScript(), state, snapshot)
  }
}
