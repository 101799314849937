/* eslint-disable @typescript-eslint/no-var-requires */
import { IAPTIDs, IIAPTService } from "../models/IIAPTService"
import { VHGEmailsProduction, VHGEmailsStaging } from "./emails"

const VHGEmails =
  process.env.REACT_APP_BACKEND_ENV === "production" ? VHGEmailsProduction : VHGEmailsStaging

const KMCCGIaptEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        dover: ["angiewiggins@nhs.net"],
        faversham: ["kmccg.fcstalkingtherapies@nhs.net"],
        insight: ["admin.national@InsightHealthcare.org"],
        withYou: ["enquiries@wearewithyou.org.uk"]
      }
    : {
        dover: ["andreas+dover@limbic.ai", "john+dover@limbic.ai", "chaellene+dover@limbic.ai"],
        faversham: [
          "andreas+faversham@limbic.ai",
          "john+faversham@limbic.ai",
          "chaellene+faversham@limbic.ai"
        ],
        insight: [
          "andreas+insightkent@limbic.ai",
          "john+insightkent@limbic.ai",
          "chaellene+insightkent@limbic.ai"
        ],
        withYou: [
          "andreas+withyou@limbic.ai",
          "john+withyou@limbic.ai",
          "chaellene+withyou@limbic.ai"
        ]
      }

const SABPIaptEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        riskEmails: ["duty.mindmatters@sabp.nhs.uk"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }
    : {
        riskEmails: ["andreas@limbic.ai", "john@limbic.ai"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }

const EssexIaptEmails: Record<"southEast" | "northEast", string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        southEast: ["epunft.contactcentre.iapt@nhs.net"],
        northEast: ["epunft.neeiaptcontactcentre@nhs.net"]
      }
    : {
        southEast: ["andreas+se+essex@limbic.ai", "john+se+essex@limbic.ai"],
        northEast: ["andreas+ne+essex@limbic.ai", "john+ne+essex@limbic.ai"]
      }

const EssexIaptEmailsDemo: Record<"southEast" | "northEast", string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        southEast: ["andreas+se+essex@limbic.ai", "john+se+essex@limbic.ai"],
        northEast: ["andreas+ne+essex@limbic.ai", "john+ne+essex@limbic.ai"]
      }
    : {
        southEast: ["andreas+se+essex@limbic.ai", "john+se+essex@limbic.ai"],
        northEast: ["andreas+ne+essex@limbic.ai", "john+ne+essex@limbic.ai"]
      }

const NorfolkEmails: Record<string, string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        emails: [],
        riskEmails: ["MHPWellbeing@nsft.nhs.uk"],
        riskEmailsCC: ["Eliza.Pepper@nsft.nhs.uk", "Catherine.Ashby@nsft.nhs.uk"],
        riskEmailsBCC: []
      }
    : {
        emails: ["andreas+norfolk@limbic.ai", "john+norfolk@limbic.ai"],
        riskEmails: ["andreas+norfolk@limbic.ai", "john+norfolk@limbic.ai"],
        riskEmailsCC: [],
        riskEmailsBCC: []
      }

const MHMEmails: Record<"sefton" | "warrington" | "northumberland", string[]> =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? {
        sefton: [],
        warrington: ["tmw.referrals@nhs.net"],
        northumberland: ["tmn.info@nhs.net"]
      }
    : {
        sefton: ["zsuzsa+se+mhm@limbic.ai", "john+se+mhm@limbic.ai"],
        warrington: ["zsuzsa+wa+mhm@limbic.ai", "john+wa+mhm@limbic.ai"],
        northumberland: ["zsuzsa+no+mhm@limbic.ai", "john+no+mhm@limbic.ai"]
      }

export const iapts: Record<IAPTIDs, IIAPTService> = {
  [IAPTIDs.DEMO]: {
    id: IAPTIDs.DEMO,
    name: "Demo Service",
    formattedName: "Demo Service",
    postcode: "OL14 8RS",
    backendInstanceID: "DEMO_MAIN",
    phoneNumber: "+44 1234 567890",
    ageThreshold: 16,
    ageThresholdForTreatments: 18,
    emails: ["ross@limbic.ai", "john@limbic.ai"],
    riskEmails: ["ross@limbic.ai", "john@limbic.ai"],
    referralForm: require("./referralForms/demo.ts").default
  },
  [IAPTIDs.VHG_DERBY]: {
    id: IAPTIDs.VHG_DERBY,
    name: "Vita Health Group: Derby Talking Therapies",
    formattedName: "Derby Talking Therapies",
    postcode: "IP33 1UZ",
    phoneNumber: "+44 3330 153496",
    ageThreshold: 16,
    ageThresholdForTreatments: 18,
    ...VHGEmails["vhg-derby"]
  },
  [IAPTIDs.VHG_BB]: {
    id: IAPTIDs.VHG_BB,
    name: "Vita Health Group: Basildon & Brentwood Talking Therapies",
    formattedName: "Basildon & Brentwood Talking Therapies",
    postcode: "IP33 1UZ",
    phoneNumber: "+44 1268 977171",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VHGEmails["vhg-bb"]
  },
  [IAPTIDs.VHG_CALDERDALE]: {
    id: IAPTIDs.VHG_CALDERDALE,
    name: "Vita Health Group: Calderdale Talking Therapies",
    formattedName: "Calderdale Talking Therapies",
    postcode: "IP33 1UZ",
    phoneNumber: "+44 3330 153494",
    ageThreshold: 18,
    ageThresholdForTreatments: 18,
    ...VHGEmails["vhg"]
  },
  [IAPTIDs.VHG_BNSSG]: {
    id: IAPTIDs.VHG_BNSSG,
    name: "Vita Health Group: Bristol, North Somerset & South Gloucestershire Talking Therapies",
    formattedName: "Bristol, North Somerset & South Gloucestershire Talking Therapies",
    postcode: "BS1 1EH",
    phoneNumber: "+44 3332 001893",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VHGEmails["vhg-bnssg"]
  },
  [IAPTIDs.VHG_KM]: {
    id: IAPTIDs.VHG_KM,
    name: "Vita Health Group: Kent and Medway Talking Therapies",
    formattedName: "Kent and Medway Talking Therapies",
    postcode: "IP33 1UZ",
    phoneNumber: "+44 3330 153495",
    ageThreshold: 17.5,
    ageThresholdForTreatments: 17.5,
    ...VHGEmails["vhg-kent"]
  },
  [IAPTIDs.VHG_LLR]: {
    id: IAPTIDs.VHG_LLR,
    name: "Vita Health Group: Leicester, Leicestershire & Rutland Talking Therapies",
    formattedName: "Leicester, Leicestershire & Rutland Talking Therapies",
    postcode: "IP33 1UZ",
    phoneNumber: "+44 3300 945595",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VHGEmails["vhg-llr"]
  },
  [IAPTIDs.VHG_WEST_ESSEX]: {
    id: IAPTIDs.VHG_WEST_ESSEX,
    name: "Vita Health Group: West Essex Talking Therapies",
    formattedName: "West Essex Talking Therapies",
    postcode: "CM18 7BL",
    phoneNumber: "+44 3330 152966",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    ...VHGEmails["vhg-westessex"]
  },
  [IAPTIDs.VHG_NOTTS]: {
    id: IAPTIDs.VHG_NOTTS,
    name: "Nottingham and Nottinghamshire Talking Therapies",
    formattedName: "Nottingham and Nottinghamshire Talking Therapies",
    postcode: "NG3 6AA",
    backendInstanceID: "VHG_NOTTS2",
    phoneNumber: "+44 3330 152966",
    ageThreshold: 18,
    ageThresholdForTreatments: 18,
    ...VHGEmails["vhg-notts"],
    referralForm: require("./referralForms/vhg-form.ts").default
  },
  [IAPTIDs.INSIGHT_KENT]: {
    id: IAPTIDs.INSIGHT_KENT,
    name: "Everyturn NHS Talking Therapies (Kent)",
    formattedName: "Everyturn NHS Talking Therapies (Kent) service",
    postcode: "ME13 8XZ",
    emails: KMCCGIaptEmails.insight,
    phoneNumber: "0300 555 5555",
    backendInstanceID: "INSIGHT_KENT_EAST",
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_PETTERSBOROUGH]: {
    id: IAPTIDs.INSIGHT_PETTERSBOROUGH,
    name: "Everyturn - Peterborough Talking Therapies",
    formattedName: "Everyturn - Peterborough Talking Therapies",
    postcode: "PE1 3PB",
    phoneNumber: "0300 555 0888",
    backendInstanceID: "INSIGHT_PETERBOROUGH",
    ageThreshold: 17,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_NOTTS]: {
    id: IAPTIDs.INSIGHT_NOTTS,
    name: "Everyturn NHS Talking Therapies - Nottinghamshire",
    formattedName: "Everyturn NHS Talking Therapies - Nottinghamshire service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_NOTTINGHAM_GREATER",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_BASSETLAW]: {
    id: IAPTIDs.INSIGHT_BASSETLAW,
    name: "Everyturn NHS Talking Therapies (Bassetlaw)",
    formattedName: "Everyturn NHS Talking Therapies (Bassetlaw) service",
    postcode: "S80 2PU",
    phoneNumber: "0300 300 0033",
    backendInstanceID: "INSIGHT_BASSETLAW",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_DERBY]: {
    id: IAPTIDs.INSIGHT_DERBY,
    name: "Derbyshire Talking Therapies",
    formattedName: "Derbyshire Talking Therapies service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_DERBY",
    ageThreshold: 18,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_WIRRAL]: {
    id: IAPTIDs.INSIGHT_WIRRAL,
    name: "Talking Together Wirral",
    formattedName: "Talking Together Wirral service",
    postcode: "CH41 1EL",
    backendInstanceID: "INSIGHT_WIRRAL",
    phoneNumber: "0151 649 1859",
    ageThreshold: 16,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_MEDWAY]: {
    id: IAPTIDs.INSIGHT_MEDWAY,
    name: "Medway Talking Therapies",
    formattedName: "Medway Talking Therapies service",
    backendInstanceID: "INSIGHT_MEDWAY",
    postcode: "ME4 6SL",
    emails: ["john+with-you@limbic.ai", "ross+with-you@limbic.ai"],
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_KENT_DEMO]: {
    id: IAPTIDs.INSIGHT_KENT,
    name: "Insight IAPT Talking Therapies (Kent)",
    formattedName: "Insight IAPT Talking Therapies (Kent) service",
    postcode: "ME13 8XZ",
    phoneNumber: "0300 555 5555",
    backendInstanceID: "INSIGHT_KENT_EAST",
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_PETTERSBOROUGH_DEMO]: {
    id: IAPTIDs.INSIGHT_PETTERSBOROUGH,
    name: "Insight IAPT - Peterborough Talking Therapies",
    formattedName: "Insight IAPT - Peterborough Talking Therapies service",
    postcode: "PE1 3PB",
    phoneNumber: "0300 555 0888",
    backendInstanceID: "INSIGHT_PETERBOROUGH",
    ageThreshold: 17,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_NOTTS_DEMO]: {
    id: IAPTIDs.INSIGHT_NOTTS,
    name: "Insight IAPT - Nottinghamshire",
    formattedName: "Insight IAPT - Nottinghamshire service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_NOTTINGHAM_GREATER",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_BASSETLAW_DEMO]: {
    id: IAPTIDs.INSIGHT_BASSETLAW,
    name: "Insight IAPT Talking Therapies (Bassetlaw)",
    formattedName: "Insight IAPT Talking Therapies (Bassetlaw) service",
    postcode: "S80 2PU",
    phoneNumber: "0300 300 0033",
    backendInstanceID: "INSIGHT_BASSETLAW",
    ageThreshold: 17.75,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_DERBY_DEMO]: {
    id: IAPTIDs.INSIGHT_DERBY,
    name: "Derbyshire Talking Therapies",
    formattedName: "Derbyshire Talking Therapies service",
    postcode: "NG2 1RT",
    phoneNumber: "0300 555 5582",
    backendInstanceID: "INSIGHT_DERBY",
    ageThreshold: 18,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_WIRRAL_DEMO]: {
    id: IAPTIDs.INSIGHT_WIRRAL,
    name: "Talking Together Wirral",
    formattedName: "Talking Together Wirral service",
    postcode: "CH41 1EL",
    backendInstanceID: "INSIGHT_WIRRAL",
    phoneNumber: "0151 649 1859",
    ageThreshold: 16,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.INSIGHT_MEDWAY_DEMO]: {
    id: IAPTIDs.INSIGHT_MEDWAY,
    name: "Medway Talking Therapies",
    formattedName: "Medway Talking Therapies service",
    backendInstanceID: "INSIGHT_MEDWAY",
    postcode: "ME4 6SL",
    emails: ["john+with-you@limbic.ai", "ross+with-you@limbic.ai"],
    ageThreshold: 17.5,
    referralForm: require("./referralForms/insight-form.ts").default
  },
  [IAPTIDs.NORFOLK_WAVENEY]: {
    id: IAPTIDs.NORFOLK_WAVENEY,
    name: "Wellbeing",
    formattedName: "Wellbeing",
    backendInstanceID: "NORFOLK_MAIN",
    postcode: "NR6 5BE",
    phoneNumber: "+44 0300 1231503",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    emails: NorfolkEmails.emails,
    riskEmails: NorfolkEmails.riskEmails,
    riskEmailsCC: NorfolkEmails.riskEmailsCC,
    riskEmailsBCC: NorfolkEmails.riskEmailsBCC,
    referralForm: require("./referralForms/norfolk.ts").default
  },
  [IAPTIDs.TRENT_NOTTS]: {
    id: IAPTIDs.TRENT_NOTTS,
    name: "Trent PTS - Ropewalk",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_NOTTS",
    postcode: "NG1 5DU",
    phoneNumber: "+44 1158 965950",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.TRENT_DERBY_WOODLANDS]: {
    id: IAPTIDs.TRENT_DERBY_WOODLANDS,
    name: "Trent PTS - Woodlands Lodge",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_DERBY",
    postcode: "DE22 3NL",
    phoneNumber: "+44 1332 265659",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.TRENT_DERBY_SALTERGATE]: {
    id: IAPTIDs.TRENT_DERBY_SALTERGATE,
    name: "Trent PTS - Saltergate",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_DERBY",
    postcode: "S40 1JS",
    phoneNumber: "+44 1246 385752",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.TRENT_NOTTS_DEMO]: {
    id: IAPTIDs.TRENT_NOTTS_DEMO,
    name: "Trent PTS - Ropewalk",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_NOTTS",
    postcode: "NG1 5DU",
    phoneNumber: "+44 1158 965950",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.TRENT_DERBY_WOODLANDS_DEMO]: {
    id: IAPTIDs.TRENT_DERBY_WOODLANDS_DEMO,
    name: "Trent PTS - Woodlands Lodge",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_DERBY",
    postcode: "DE22 3NL",
    phoneNumber: "+44 1332 265659",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.TRENT_DERBY_SALTERGATE_DEMO]: {
    id: IAPTIDs.TRENT_DERBY_SALTERGATE_DEMO,
    name: "Trent PTS - Saltergate",
    formattedName: "Trent PTS",
    backendInstanceID: "TRENT_E2E_DEMO",
    postcode: "S40 1JS",
    phoneNumber: "+44 1246 385752",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: [],
    riskEmailsCC: [],
    riskEmailsBCC: [],
    referralForm: require("./referralForms/trent-pts.ts").default
  },
  [IAPTIDs.DOVER_KM]: {
    id: IAPTIDs.DOVER_KM,
    name: "Dover Counselling Centre",
    formattedName: "Dover Counselling Centre service",
    postcode: "CT16 2ES",
    emails: KMCCGIaptEmails.dover
  },
  [IAPTIDs.FAVERSHAM_KM]: {
    id: IAPTIDs.FAVERSHAM_KM,
    name: "Faversham Counselling Service",
    formattedName: "Faversham Counselling service",
    postcode: "ME13 8NX",
    emails: KMCCGIaptEmails.faversham
  },
  [IAPTIDs.WITH_YOU_KM]: {
    id: IAPTIDs.WITH_YOU_KM,
    name: "With You",
    formattedName: "We Are With You service",
    postcode: "TN23 7SG",
    emails: KMCCGIaptEmails.withYou
  },
  [IAPTIDs.MIND_MATTERS_SAB]: {
    id: IAPTIDs.MIND_MATTERS_SAB,
    name: "Mind Matters Surrey NHS",
    formattedName: "Mind Matters Surrey NHS service",
    postcode: "KT16 9FG",
    backendInstanceID: "SABP_MAIN",
    phoneNumber: "+44 3003 305450",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    riskEmails: SABPIaptEmails.riskEmails,
    riskEmailsCC: SABPIaptEmails.riskEmailsCC,
    riskEmailsBCC: SABPIaptEmails.riskEmailsBCC,
    referralForm: require("./referralForms/sabp.ts").default
  },
  [IAPTIDs.LINCS_STAMFORD]: {
    id: IAPTIDs.LINCS_STAMFORD,
    backendInstanceID: "LINCS_STAMFORD",
    name: "Lincolnshire Talking Therapies (Stamford)",
    clinicName: "steps2change-stamford",
    formattedName: "Lincolnshire Talking Therapies (Stamford) service",
    postcode: "PE9 1UN",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_SPALDING]: {
    id: IAPTIDs.LINCS_SPALDING,
    backendInstanceID: "LINCS_SPALDING",
    name: "Lincolnshire Talking Therapies (Spalding)",
    clinicName: "steps2change-spalding",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "PE11 1TW",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_GRANTHAM]: {
    id: IAPTIDs.LINCS_GRANTHAM,
    backendInstanceID: "LINCS_GRANTHAM",
    name: "Lincolnshire Talking Therapies (Grantham)",
    clinicName: "steps2change-grantham",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "NG31 9DF",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_BOSTON]: {
    id: IAPTIDs.LINCS_BOSTON,
    backendInstanceID: "LINCS_BOSTON",
    name: "Lincolnshire Talking Therapies (Boston)",
    clinicName: "steps2change-boston",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "PE21 8EG",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_SLEAFORD]: {
    id: IAPTIDs.LINCS_SLEAFORD,
    backendInstanceID: "LINCS_SLEAFORD",
    name: "Lincolnshire Talking Therapies (Sleaford)",
    clinicName: "steps2change-sleaford",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "NG34 8GG",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_SKEGNESS]: {
    id: IAPTIDs.LINCS_SKEGNESS,
    backendInstanceID: "LINCS_SKEGNESS",
    name: "Lincolnshire Talking Therapies (Skegness)",
    clinicName: "steps2change-skegness",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "PE25 2JA",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_LINCOLN]: {
    id: IAPTIDs.LINCS_LINCOLN,
    backendInstanceID: "LINCS_LINCOLN",
    name: "Lincolnshire Talking Therapies (Lincoln)",
    clinicName: "steps2change-lincoln",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "LN2 4WA",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_LOUTH]: {
    id: IAPTIDs.LINCS_LOUTH,
    backendInstanceID: "LINCS_LOUTH",
    name: "Lincolnshire Talking Therapies (Louth)",
    clinicName: "steps2change-louth",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "LN11 0LF",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.LINCS_GAINSBOROUGH]: {
    id: IAPTIDs.LINCS_GAINSBOROUGH,
    backendInstanceID: "LINCS_GAINSBOROUGH",
    name: "Lincolnshire Talking Therapies (Gainsborough)",
    clinicName: "steps2change-gainsborough",
    formattedName: "Lincolnshire Talking Therapies service",
    postcode: "DN21 2DZ",
    phoneNumber: "+44 3031 234000",
    ageThreshold: 16,
    referralForm: require("./referralForms/lincolnshire-form.ts").default
  },
  [IAPTIDs.NOTTINGHAMSHIRE_NOTTS_CC]: {
    id: IAPTIDs.NOTTINGHAMSHIRE_NOTTS_CC,
    name: "Let's Talk-Wellbeing",
    backendInstanceID: "NOTTS_MAIN",
    formattedName: "Let's Talk-Wellbeing service",
    postcode: "NG3 6AA",
    phoneNumber: "+44 3003 002200",
    ageThreshold: 18,
    ageThresholdForTreatments: 18,
    referralForm: require("./referralForms/nottinghamshire.ts").default
  },
  [IAPTIDs.NOTTINGHAMSHIRE_NOTTS]: {
    id: IAPTIDs.NOTTINGHAMSHIRE_NOTTS,
    name: "Let's Talk-Wellbeing",
    backendInstanceID: "NOTTS_MAIN",
    formattedName: "Let's Talk-Wellbeing service",
    postcode: "NG3 6AA",
    phoneNumber: "+44 3003 002200",
    ageThreshold: 18,
    ageThresholdForTreatments: 18,
    referralForm: require("./referralForms/nottinghamshire.ts").default
  },
  [IAPTIDs.ITALK]: {
    id: IAPTIDs.ITALK,
    name: "italk",
    backendInstanceID: "ITALK_MAIN",
    formattedName: "italk NHS talking therapies service",
    postcode: "SO50 9FH",
    phoneNumber: "+44 2380 383 920",
    ageThreshold: 16,
    ageThresholdForTreatments: 16,
    referralForm: require("./referralForms/italk-form.ts").default
  },
  [IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX]: {
    id: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
    name: "Therapy For You",
    formattedName: "Therapy For You",
    postcode: "CO3 3BT",
    backendInstanceID: "ESSEX_MAIN",
    phoneNumber: "+44 1206 334001",
    ageThreshold: 16,
    emails: EssexIaptEmails.northEast,
    referralForm: require("./referralForms/essex-form.ts").ne_form
  },
  [IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX]: {
    id: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
    name: "Therapy For You",
    formattedName: "Therapy For You",
    postcode: "SS15 6EA",
    backendInstanceID: "ESSEX_SOUTHEAST",
    phoneNumber: "+44 1268 739128",
    ageThreshold: 18,
    emails: EssexIaptEmails.southEast,
    referralForm: require("./referralForms/essex-form.ts").se_form
  },
  [IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO]: {
    id: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
    name: "Therapy For You",
    formattedName: "Therapy For You (NE Essex)",
    postcode: "CO3 3BT",
    backendInstanceID: "ESSEX_E2E_DEMO",
    phoneNumber: "+44 1206 334001",
    ageThreshold: 16,
    emails: EssexIaptEmailsDemo.northEast,
    referralForm: require("./referralForms/essex-form.ts").ne_form
  },
  [IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO]: {
    id: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
    name: "Therapy For You",
    formattedName: "Therapy For You (SE Essex)",
    postcode: "SS15 6EA",
    backendInstanceID: "ESSEX_E2E_DEMO",
    phoneNumber: "+44 1268 739128",
    ageThreshold: 18,
    emails: EssexIaptEmailsDemo.southEast,
    referralForm: require("./referralForms/essex-form.ts").se_form
  },
  [IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON]: {
    id: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
    name: "NHS Talking Therapies Warrington",
    formattedName: "NHS Talking Therapies Warrington",
    postcode: "OL14 8RS",
    backendInstanceID: "MHM_WARRINGTON",
    phoneNumber: "+44 01925 401 720",
    ageThreshold: 16,
    emails: [],
    riskEmails: MHMEmails.warrington,
    referralForm: require("./referralForms/mental-health-matters.ts").warrington_form
  },
  [IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND]: {
    id: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
    name: "NHS Talking Therapies Northumberland",
    formattedName: "NHS Talking Therapies Northumberland",
    postcode: "OL14 8RS",
    backendInstanceID: "MHM_NORTHUMBERLAND",
    phoneNumber: "+44 300 3030 700",
    ageThreshold: 16,
    emails: [],
    riskEmails: MHMEmails.northumberland,
    referralForm: require("./referralForms/mental-health-matters.ts").northumberland_form
  },
  [IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON]: {
    id: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
    name: "NHS Talking Therapies Sefton",
    formattedName: "NHS Talking Therapies Sefton",
    postcode: "OL14 8RS",
    backendInstanceID: "MHM_SEFTON",
    phoneNumber: "+44 300 303 2708",
    ageThreshold: 16,
    emails: [],
    riskEmails: MHMEmails.sefton,
    referralForm: require("./referralForms/mental-health-matters.ts").sefton_form
  }
}
