import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState

export type CrisisEssexScriptState = State

export class CrisisEssexScript extends CrisisScript {
  readonly name: string = "CrisisEssexScript"

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "However, this is not an emergency response service",
        "To get more appropriate help, you can call NHS 111 and selection Option 2",
        "If you need urgent, life threatening medical help please call 999",
        "Alternatively, you can also call Samaritans on 116 123"
      ],
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSorryToHear
    }
  }

  @step.logState
  handleSorryToHear(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999, NHS 111 and Samaritans")
    return { nextStep: this.sayTheyWillCall }
  }

  sayTheyWillCall(d: IStepData<State>): IStepResult {
    if (!d.state.phoneNumber) {
      return { nextStep: this.askPhoneNumber }
    }

    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: `One of ${serviceName}'s clinical team will contact you on ${d.state.phoneNumber} within one working day to check that you have referred yourself in to one of the crisis support services`,
      nextStep: this.end
    }
  }
}

export default class CrisisEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.CrisisEssex
  readonly name: string = "CrisisEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisEssexDialogue.id, new CrisisEssexScript(), state, snapshot)
  }
}
