import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import { DiscussionSteps } from "../../../models/Constants"

type State = BaseScriptState

export type AssessmentPitchIAPTScriptState = State

export const AssessmentPitchIAPTScriptStateSchema = BaseScriptStateSchema.extend({})

export class AssessmentPitchIAPTScript extends BaseScript<State> {
  readonly name: string = "AssessmentPitchIAPTScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    if (d.state.phoneNumber) {
      return { nextStep: this.checkForNeedToBeCalled }
    }
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  goToCollectPhoneNumber(d: IStepData<State>): IStepResult | Promise<IStepResult> {
    const CollectPhoneNumberDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectPhoneNumber
    )
    const nextDialogue = CollectPhoneNumberDialogue
      ? new CollectPhoneNumberDialogue({ ...d.state })
      : undefined
    return {
      nextDialogue,
      nextStep: this.checkForNeedToBeCalled
    }
  }

  @step.logState
  checkForNeedToBeCalled(d: IStepData<State>): IStepResult {
    if (this.clinicalStore.isRisk) {
      return { nextStep: this.saySomeoneFromDutyWillContact }
    }
    if (d.state.needsAssessmentCall) {
      return { nextStep: this.saySomeoneFromAdminWillContact }
    }
    return { nextStep: this.sayWeCanSpeedUpReferral }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Well ${name}, I've actually got something more I can offer you`,
        "Based on the information you've provided, it might be possible for you to access support sooner"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sounds interesting 🤔", value: true },
          { body: "Okay", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWeCanSpeedUpReferral
    }
  }

  @step.logState
  async handleWeCanSpeedUpReferral(d: IStepData<State>): Promise<IStepResult> {
    const result = await this.onHandleWeCanSpeedUpReferral?.(d.state)
    if (result) return result

    if (d.response) {
      return { body: "I agree 😌", nextStep: this.askWannaDoQuestionnaires }
    }
    return { nextStep: this.askWannaDoQuestionnaires }
  }

  @step.logState
  askWannaDoQuestionnaires(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [
        "To do this, we need to go through a few more questions together",
        "It should take no more than 15 minutes 😊",
        `Alternatively, we can finish our conversation here, and someone from ${iaptName} will be in touch over the next 3 working days to book you in for a clinical assessment (which usually takes place 2-8 weeks after booking)`,
        "What do you think? Shall we go through the questions together now?"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoQuestionnaires"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sure!", value: "sure" },
          { body: "Okay... If it could help speed things up", value: "okay" },
          {
            body: "No thanks, I'll wait for you to call me in the next 3 working days",
            value: "no"
          }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaDoQuestionnaires
    }
  }

  @step.logState
  handleWannaDoQuestionnaires(d: IStepData<State, "sure" | "okay" | "no">): IStepResult {
    if (d.response === "no") {
      return { body: "Okay", nextStep: this.saySomeoneFromAdminWillContact }
    }
    return {
      body: d.response === "sure" ? "Great!" : "Alright, let's see what we can do",
      nextStep: this.end
    }
  }

  @step.logState
  saySomeoneFromAdminWillContact(d: IStepData<State>): IStepResult {
    const body = d.state.iaptManuallySelected
      ? "Someone from our admin team will be in touch in the next 3 working days to discuss booking you in for an assessment with one of our clinicians"
      : "Someone from our admin team will be in touch in the next 3 working days to book you in for an assessment with one of our clinicians"
    return {
      body,
      prompt: {
        id: this.getPromptId("saySomeoneFromAdminWillContact"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Looking forward to it" }, { body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.goToGoodbye // TODO: Here we were asking for special requirements
    }
  }

  @step.logState
  saySomeoneFromDutyWillContact(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Someone from our admin team will be in touch in the next 3 working days to book you in for an assessment with one of our clinicians",
        `Also, I'd like someone from our duty of care team to give you a call in the next 24 hours`,
        "This is just to check in with you and make sure everything is okay"
      ],
      prompt: {
        id: this.getPromptId("saySomeoneFromDutyWillContact"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Looking forward to it" }, { body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.goToGoodbye // TODO: Here we were asking for special requirements
    }
  }

  onHandleWeCanSpeedUpReferral?(state: State): Promise<IStepResult | void>
}

/* istanbul ignore next */
export default class AssessmentPitchIAPTDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchIAPT
  readonly name: string = "AssessmentPitchIAPTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchIAPTDialogue.id, new AssessmentPitchIAPTScript(), state, snapshot)
  }
}
