import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import AssessmentScript, { AssessmentScriptState } from "./AssessmentScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentScriptState

export class AssessmentHealixScript extends AssessmentScript {
  readonly name: string = "AssessmentHealixScript"

  /** Generic Handlers */

  // TODO: Check if we need to keep Employment questions (and remove if we do)
  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

/* istanbul ignore next */
export default class AssessmentHealixDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentHealix
  readonly name: string = "AssessmentHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentHealixDialogue.id, new AssessmentHealixScript(), state, snapshot)
  }
}
