import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { fullNameRegex } from "../../../utils/fullNameRegex"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { TrackingEvents } from "../../../models/Constants"
import sendEmail from "../../../backend/api/sendEmail"
import { SendEmailStatus } from "../../../models/ISendEmail"

interface State extends SelfReferralIAPTScriptState {
  role?: string
  organisation?: string
  mainIssue?: string
  someoneAtRisk?: boolean
}

export type SelfReferralGMHubManagerTeamScriptState = State

export class SelfReferralGMHubManagerTeamScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralGMHubManagerTeamScript"

  /** Script Steps */
  @step.logState
  @step.setState<State>({ addressLookupCounter: 0, postcodeLookupCounter: 0 })
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.askWannaDoSelfReferral }
  }

  @step.logState
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    return {
      body: "What's your full name? ✏️",
      nextStep: this.showPromptForFullName
    }
  }

  @step
  sayPleaseGiveFullName(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your full name",
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  showPromptForFullName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForFullName"),
        type: "text",
        validation: [new RegExp(fullNameRegex)],
        validationExplainer: ["Please enter your full name"],
        forceValue: true
      },
      nextStep: this.handleFullNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralGMHubManagerTeamScript) => {
      const username = d.response?.trim()
      d.state.username = username
      script.rootStore.applicationStore.setUsername(username)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralGMHubManagerTeamScript) => s.sayPleaseGiveFullName
  })
  async handleFullNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkFullName }
  }

  @step.logState
  async checkFullName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.username || d.state.username.trim() === "") {
      return { nextStep: this.sayPleaseGiveFullName }
    }
    return { nextStep: this.askRole }
  }

  @step.logState
  @step.logState
  askRole(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thanks ${name}. What is your role?`,
      prompt: {
        id: this.getPromptId("askRole"),
        type: "text",
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleRoleWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralGMHubManagerTeamScript) => {
      d.state.role = d.response
      script.referralStore.setCustomField<State>("role", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralGMHubManagerTeamScript) => s.askOrganisation
  })
  handleRoleWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askOrganisation }
  }

  @step.logState
  @step.logState
  askOrganisation(_d: IStepData<State>): IStepResult {
    return {
      body: "And what is the organisation you work for?",
      prompt: {
        id: this.getPromptId("askOrganisation"),
        type: "text",
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleOrganisationWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralGMHubManagerTeamScript) => {
      d.state.organisation = d.response
      script.referralStore.setCustomField<State>("organisation", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralGMHubManagerTeamScript) => s.askEmail
  })
  handleOrganisationWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEmail }
  }

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.goToCollectPhoneNumber(d)
    return { ...result, nextStep: this.askWelfareSituationThatSupportIsNeeded }
  }

  @step.logState
  askWelfareSituationThatSupportIsNeeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thanks ${name}. Could you briefly outline the welfare situation you would like support with?`,
      prompt: {
        id: this.getPromptId("askWelfareSituationThatSupportIsNeeded"),
        type: "text",
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleWelfareSituationThatSupportIsNeededWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralGMHubManagerTeamScript) => {
      d.state.mainIssue = d.response
      script.referralStore.setCustomField<State>("mainIssue", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralGMHubManagerTeamScript) => s.askIsAnyoneAtRisk
  })
  handleWelfareSituationThatSupportIsNeededWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askIsAnyoneAtRisk }
  }

  @step.logState
  askIsAnyoneAtRisk(_d: IStepData<State>): IStepResult {
    return {
      body: "In your opinion, is any member of the team at risk, in crisis or unable to keep themself safe?",
      prompt: {
        id: this.getPromptId("askIsAnyoneAtRisk"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleIsAnyoneAtRisk
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralGMHubManagerTeamScript) => {
      d.state.someoneAtRisk = d.response
      script.referralStore.setCustomField<State>("someoneAtRisk", d.response)
    }
  )
  handleIsAnyoneAtRisk(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.sayRiskSignposting }
    }
    // TODO: When we have the backend setup we also need to create a referral for ManagerTeam
    // i.e. nextStep: this.onReferralFinished - and send email there
    return { nextStep: this.submitReferralEmail }
  }

  sayRiskSignposting(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Thanks ${name}`,
        "There is some additional information you can share with any members of the team if they are in crisis:",
        "In an urgent emergency they should seek help via 999",
        "If they're experiencing a mental health crisis they can also go to: [Getting Help](http://www.nhs.uk/Conditions/Suicide/Pages/Getting-help.aspx)",
        "Papyrus is available for under 35s on 0800 0684141 (call), pat@papyrus-uk.org and 07860 039967 (text)",
        "And finally, they can call the service directly on 0333 009 5071 or email [GM.help@nhs.net](GM.help@nhs.net)"
      ],
      prompt: {
        id: this.getPromptId("sayRiskSignposting"),
        type: "inlinePicker",
        choices: [
          { body: "Ok", value: false },
          { body: "I understand", value: false }
        ],
        isUndoAble: true
      },
      // TODO: When we have the backend setup we also need to create a referral for ManagerTeam
      // i.e. nextStep: this.onReferralFinished - and send email there
      nextStep: this.submitReferralEmail
    }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "And that's everything",
        `You've officially submitted this referral to ${organisationName}`
      ],
      nextStep: this.end
    }
  }

  @step.logState
  async submitReferralEmail(d: IStepData<State>): Promise<IStepResult> {
    const emails = this.rootStore.configStore.emails ?? []

    try {
      // prettier-ignore
      const text = this.createReferralEmail(d.state)
      // [secure] was requested by GMHUB - with this tag in the subject
      // they can encrypt it on their side
      const status = await sendEmail({
        from: "customersupport@limbic.ai",
        subject: "[secure] Limbic Referral | Team Support",
        to: emails,
        text
      })
      if (status === SendEmailStatus.SendEmailFailed) {
        d.state.referralSubmitted = false
        d.state.referralSubmissionFailed = true
        this.track(TrackingEvents.MANAGER_TEAM_REFERRAL_NOT_SUBMITTED)
        return { nextStep: this.sayReferralFailed }
      }
      d.state.referralSubmitted = true
      d.state.referralSubmissionFailed = false
      this.track(TrackingEvents.MANAGER_TEAM_REFERRAL_SUBMITTED)
    } catch (e) {
      this.logException(e, "onReferralFinished -> sendEmail")
    }
    return { nextStep: this.sayReferralSucceeded }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const referralUrl = "https://www.penninecare.nhs.uk/c19screening"
    return {
      body: [
        `Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit the provided data to ${organisationName}`,
        "I've notified my creators of this issue",
        `If you don't wish to wait, you can manually refer yourself by following this link [here](${referralUrl})`
      ],
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }],
        isUndoAble: false
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  // TODO: When we have the backend setup we also need to create a referral for ManagerIndividual
  // i.e. nextStep: this.onReferralFinished - and send email there
  async getReferralPayload(_state: State): Promise<Record<string, null>> {
    return {}
  }

  async onHandleEmail(_state: State): Promise<IStepResult> {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  createReferralEmail(state: State): string {
    // prettier-ignore
    return `
    <html lang='en'>
      <head>
      <title>Limbic Referral | Team Support</title>
      ${this.getEmailHTMLStyle()}
      </head>
      <body>
        <h1 style="text-align: left;">Limbic Referral | Team Support</h1>
        <b>Referrer name:</b> ${state.username}<br/>
        <b>Referral Type:</b> Team Support<br/>
        <hr/>
        <h3>Referrer Info</h3>
        <b>Name:</b> ${state.username}<br/>
        <b>Role:</b> ${state.role}<br/>
        <b>Organisation:</b> ${state.organisation}<br/>
        <b>Email:</b> ${state.email}<br/>
        <b>Phone Number:</b> ${state.phoneNumber}<br/>
        <h3>Situation Details</h3>
        <b>Situation/issue details:</b> ${state.mainIssue}<br/>
        <b>Team member(s) at risk:</b> ${state.someoneAtRisk}<br/>
      </body>
    </html>
    `.replace(/undefined/gi, "-").replace(/true/gi, "Yes").replace(/false/gi, "No")
  }
}

export default class SelfReferralGMHubManagerTeamDialogue extends AdHocDialogue<
  State,
  SelfReferralGMHubManagerTeamScript
> {
  static id = DialogueIDs.SelfReferralGMHubManagerTeam
  readonly name: string = "SelfReferralGMHubManagerTeamDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralGMHubManagerTeamDialogue.id,
      new SelfReferralGMHubManagerTeamScript(),
      state,
      snapshot
    )
  }
}
