import { IClinicalPath, IClinicalPathOverride } from "../../../models/IClinicalPath"

export const clinicalPathsVHG: Record<IClinicalPath["id"], IClinicalPathOverride> = {
  complex_comorbid: { defaultReferralType: "Limbic Assessment" },
  undetermined: { defaultReferralType: "Limbic Assessment" },
  "1": { defaultReferralType: "Limbic Assessment" },
  "2": { defaultReferralType: "Limbic Assessment" },
  "3": { defaultReferralType: "Limbic Assessment" },
  "4": { defaultReferralType: "Limbic Assessment" },
  "5": { defaultReferralType: "Limbic Assessment" },
  "6": { defaultReferralType: "Limbic Assessment" },
  "7": { defaultReferralType: "Limbic Assessment" },
  "8": { defaultReferralType: "Limbic Assessment" },
  "9": { defaultReferralType: "Limbic Assessment" },
  "10": { defaultReferralType: "Limbic Assessment" },
  "11": { defaultReferralType: "Limbic Assessment" },
  "12": { defaultReferralType: "Limbic Assessment" },
  "13": { defaultReferralType: "Limbic Assessment" },
  "14": { defaultReferralType: "Limbic Assessment" },
  "15": { defaultReferralType: "Limbic Assessment" },
  "16": { defaultReferralType: "Limbic Assessment" },
  "17": { defaultReferralType: "Limbic Assessment" },
  "18": { defaultReferralType: "Limbic Assessment" },
  "19": { defaultReferralType: "Limbic Assessment" },
  "20": { defaultReferralType: "Limbic Assessment" },
  "21": { defaultReferralType: "Limbic Assessment" },
  "22": { defaultReferralType: "Limbic Assessment" },
  "23": { defaultReferralType: "Limbic Assessment" },
  "24": { defaultReferralType: "Limbic Assessment" },
  "25": { defaultReferralType: "Limbic Assessment" },
  "26": { defaultReferralType: "Limbic Assessment" },
  "27": { defaultReferralType: "Limbic Assessment" },
  "28": { defaultReferralType: "Limbic Assessment" },
  "29": { defaultReferralType: "Limbic Assessment" },
  "30": { defaultReferralType: "Limbic Assessment" },
  "31": { defaultReferralType: "Limbic Assessment" },
  "32": { defaultReferralType: "Limbic Assessment" },
  "33": { defaultReferralType: "Limbic Assessment" },
  "34": { defaultReferralType: "Limbic Assessment" },
  "35": { defaultReferralType: "Limbic Assessment" },
  "36": { defaultReferralType: "Limbic Assessment" },
  "37": { defaultReferralType: "Limbic Assessment" },
  "38": { defaultReferralType: "Limbic Assessment" },
  "39": { defaultReferralType: "Limbic Assessment" },
  "40": { defaultReferralType: "Limbic Assessment" },
  "41": { defaultReferralType: "Limbic Assessment" },
  "42": { defaultReferralType: "Limbic Assessment" }
}
