import { step } from "../../../backend/chatbot/decorators/step"
import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"

type State = AssessmentPitchIAPTScriptState

export class AssessmentPitchNottinghamshireScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchNottinghamshireScript"

  /** Script Steps */
  @step.logState
  checkForNeedToBeCalled(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeCanSpeedUpReferral }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "And I will use the results to match you with the most appropriate therapy"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.end
    }
  }

  @step.logState
  saySomeoneFromAdminWillContact(_d: IStepData<State>): IStepResult {
    return {
      body: "Someone from our admin team will be in touch within 72 hours to discuss booking you in for an assessment with one of our clinicians",
      prompt: {
        id: this.getPromptId("saySomeoneFromAdminWillContact"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Looking forward to it" }, { body: "Okay" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class AssessmentPitchNottinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchNottinghamshire
  readonly name: string = "AssessmentPitchNottinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      AssessmentPitchNottinghamshireDialogue.id,
      new AssessmentPitchNottinghamshireScript(),
      state,
      snapshot
    )
  }
}
