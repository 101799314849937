import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import AssessmentIAPTV2Script, { AssessmentIAPTV2ScriptState } from "./AssessmentIAPTV2Script"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import sendEmail from "../../../backend/api/sendEmail"

type State = AssessmentIAPTV2ScriptState

export class AssessmentNorfolkScript extends AssessmentIAPTV2Script {
  readonly name: string = "AssessmentNorfolkScript"

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

export default class AssessmentNorfolkDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentNorfolk
  readonly name: string = "AssessmentNorfolkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentNorfolkDialogue.id, new AssessmentNorfolkScript(), state, snapshot)
  }
}

if (process.env.REACT_APP_BACKEND_ENV === "staging") {
  // 👆just making sure this won't be put to production by mistake

  AssessmentNorfolkScript.prototype.onFinishAssessment = async function onFinishAssessment(
    this: AssessmentNorfolkScript,
    state: State
  ): Promise<void> {
    const emails = state.iapt?.emails || []
    // noinspection ES6MissingAwait - ⚠️ this is done on purpose as a FnF call
    void this.submitEmail(state, emails)
  }

  AssessmentNorfolkScript.prototype.submitEmail = async function submitEmail(
    this: AssessmentNorfolkScript,
    state: State,
    emails: string[]
  ): Promise<void> {
    const organisationName = this.rootStore.configStore.organisationName
    try {
      const text = this.createReferralEmail(state, this.clinicalStore.isRisk)
      const to = emails.filter(Boolean) as string[]
      await sendEmail(
        { to, subject: "Limbic | User Completed the Assessment", text },
        organisationName || ""
      )
    } catch (e) {
      this.logException(e, "submitEmail")
    }
  }
}
