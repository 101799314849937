import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { DialogueIDs } from "../../DialogueIDs"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"
import ISelectable from "../../../models/ISelectable"
import { DiscussionSteps } from "../../../models/Constants"

interface State extends EligibilityCheckWithPDSScriptState {
  requiresUrgentSupport?: boolean
}

export type EligibilityCheckEssexScriptState = State

export class EligibilityCheckEssexScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckEssexScript"

  /** Script Steps */
  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askRequiresUrgentSupport }
  }

  @step.logState
  askRequiresUrgentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you currently feel at risk of harming yourself, or can't keep yourself safe?",
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckEssexScript) => {
    d.state.requiresUrgentSupport = d.response
    script.referralStore.setCustomField<State>("requiresUrgentSupport", d.response)
  })
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.setRiskLevelHigh(d.state, "User said they require urgent support")
    }
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.goToCollectPhoneNumber
    }
  }

  @step.logState
  goToCollectPhoneNumber(d: IStepData<State>): IStepResult | Promise<IStepResult> {
    const CollectPhoneNumberDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectPhoneNumber
    )
    const nextDialogue = CollectPhoneNumberDialogue
      ? new CollectPhoneNumberDialogue({ ...d.state, skipCollectPhoneNumberIntro: true })
      : undefined
    return {
      nextDialogue,
      nextStep: this.handlePhoneNumber
    }
  }

  @step.logState
  handlePhoneNumber(d: IStepData<State>): IStepResult {
    if (!d.state.phoneNumber) {
      this.setUserNeedsToCallIn(d.state)
      this.setEligibility(d.state, false)
      this.referralStore.setCustomField("callInReason", "mobilePhone")
      const phoneNumbers = this.rootStore.configStore.organisationGenericPhoneNumber
      return {
        body: `To create a referral, please contact us in one of the following phone numbers: \n\n${phoneNumbers}`,
        prompt: {
          id: this.getPromptId("handlePhoneNumber"),
          trackResponse: true,
          type: "inlinePicker",
          choices: [
            { body: "Okay", value: "okay" },
            { body: "Thanks", value: "thanks" }
          ]
        },
        nextStep: this.goToGoodbye
      }
    }
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)

    return {
      body: [
        `${name}, you have indicated you are in crisis and need urgent help`,
        "However, this is not an emergency response service",
        "To get more appropriate help, you can call NHS 111 and selection Option 2",
        "If you need urgent, life threatening medical help please call 999",
        "Alternatively, you can also call Samaritans on 116 123"
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999, NHS 111 and Samaritans")
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  askSelectIAPTServiceManually(d: IStepData<State>): IStepResult {
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    return {
      body: "And which service would you like to be referred into?",
      prompt: {
        id: this.getPromptId("askSelectIAPTServiceManually"),
        trackResponse: true,
        type: "inlinePicker",
        choices: (
          eligibleIAPTs.map(iapt => ({
            body: `${iapt.formattedName} ${
              iapt.id === "7560833"
                ? "(North East Essex)"
                : iapt.id === "7757974"
                ? "(South East Essex)"
                : ""
            }`,
            value: iapt
          })) as ISelectable<any>[]
        ) //
          .concat({
            body: "Actually, I want to speak to a human",
            value: "speakToHuman",
            backgroundColor: "#EC9CC8"
          })
      },
      nextStep: this.handleSelectIAPTServiceManually
    }
  }
}

/* istanbul ignore next */
export default class EligibilityCheckEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckEssex
  readonly name: string = "EligibilityCheckEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckEssexDialogue.id, new EligibilityCheckEssexScript(), state, snapshot)
  }
}
