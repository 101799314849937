/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { NorfolkPreferredPronouns, ReferralType } from "../../../models/Constants"
import invariant from "../../../utils/invariant"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import {
  ARMED_FORCES_MAYDEN_NORFOLK,
  CorrespondencePreferred,
  CurrentSupportDetailsNorfolk,
  DISABILITY_MAYDEN_NORFOLK,
  ETHNICITY_MAYDEN_NORFOLK,
  GENDER_MAYDEN_NORFOLK,
  GenderBirthAssigned,
  LANGUAGE_MAYDEN,
  LANGUAGE_MAYDEN_NORFOLK,
  LIMBIC_IMPACT_LEVEL,
  LTC_MAYDEN_NORFOLK,
  NATIONALITY_MAYDEN,
  PERINATAL_MAYDEN_NORFOLK,
  ReferralPayloadMaydenNorfolk,
  ReferralSourceNorfolk,
  SEXUALITY_MAYDEN_SUFFOLK
} from "@limbic/types"
import { toLowerCaseProperties } from "../../../utils/object"
import {
  currentSupport,
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  languages,
  ltcs,
  nationalities,
  perinatalStatuses,
  preferredContactMethods,
  referralSources,
  sameGenderAsBirth,
  sexualities
} from "../../../config/referralForms/norfolk"
import { joinWithAnd } from "../../../utils/array"
import { Category } from "@limbic/crisis-detection"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type SELF_HELP_INTEREST = "Workshops" | "Online workshops" | undefined

interface State extends SelfReferralIAPTScriptState {
  currentMHSupport?: string
  currentMHSupportDetails?: string
  mhMedication?: boolean
  mhMedicationDetails?: string
  mainProblemAlcoholOrDrugs?: boolean
  permissionToEmail?: boolean
  preferredContactMethod?: string
  mainIssue?: string
  nationalIdentity?: string
  requiresInterpreter?: boolean
  interpreterLanguage?: string
  signLanguageInterpreter?: boolean
  keyWorker?: boolean
  seekingHelpForUnwantedMemories?: boolean
  seekingHelpForFearOfContamination?: boolean
  seekingHelpForUnpleasantThoughts?: boolean
  ltcOtherDetails?: string
  interestedInWorkshops?: boolean
  workshopsOrOnline?: "Workshops" | "Online"
  therapyGoal?: string
  preferredPronouns?: NorfolkPreferredPronouns
  preferredPronounsOther?: string
  whereDidYouHearAboutServiceOther?: string
}

export type SelfReferralNorfolkScriptState = State

export class SelfReferralNorfolkScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralNorfolkScript"

  /** Script Steps */

  @step.logState
  askWannaDoSelfReferral(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "There are just a few more details I need from you",
        "This should take no more than 5 minutes",
        "<b>Please note, that by providing any of your contact details, you are consenting to communication in those ways</b>"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoSelfReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Sure" }, { body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.startSelfReferralPart1
    }
  }

  @step.logState
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    return { nextStep: this.askSelectAddressFromPostCode }
  }

  @step.logState
  askCurrentMHSupport(d: IStepData<State>): IStepResult {
    const currentSupport = this.getCurrentSupportOptions(d.state)
    if (!currentSupport?.length) {
      this.logBreadcrumb("CURRENT SUPPORT NOT FOUND", d.state, { currentSupport })
      this.logMessage("CURRENT SUPPORT NOT FOUND")
      return { nextStep: this.askMHMedication }
    }

    return {
      body: "Are you currently receiving any kind of talking therapy or on the waiting list for another mental health service?",
      prompt: {
        id: this.getPromptId("askCurrentMHSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: currentSupport.map(g => ({ body: g, value: g })),
        dataPointsName: "askCurrentMHSupport"
      },
      nextStep: this.handleCurrentMHSupport
    }
  }

  @step.logStateAndResponse
  async handleCurrentMHSupport(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.currentMHSupport = d.response
    if (d.response === "No") {
      this.setPeople({ hasCurrentSupport: false })
      return { nextStep: this.askMHMedication }
    }
    this.setPeople({ hasCurrentSupport: true })
    return { nextStep: this.askCurrentMHSupportDetails }
  }

  @step.logState
  askCurrentMHSupportDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please give details about what kind of therapy you are having, or what service you're waiting for",
      prompt: {
        id: this.getPromptId("askCurrentMHSupportDetails"),
        trackResponse: true,
        type: "text",
        forceValue: true,
        isUndoAble: true,
        dataPointsName: "askCurrentMHSupportDetails"
      },
      nextStep: this.handleCurrentMHSupportDetailsWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.checkInputForCrisis({ getNextStep: (s: SelfReferralNorfolkScript) => s.askMHMedication })
  async handleCurrentMHSupportDetailsWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.currentMHSupportDetails = d.response
    return { nextStep: this.askMHMedication }
  }

  @step.logState
  askMHMedication(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently prescribed any medication for your mental health?",
      prompt: {
        id: this.getPromptId("askMHMedication"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askMHMedication"
      },
      nextStep: this.handleMHMedication
    }
  }

  @step.logStateAndResponse
  async handleMHMedication(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.mhMedication = d.response
    if (d.response) {
      return { nextStep: this.askMHMedicationDetails }
    }
    return { nextStep: this.askGender }
  }

  @step.logState
  askMHMedicationDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please give details about the medication for your mental health",
      prompt: {
        id: this.getPromptId("askMHMedicationDetails"),
        trackResponse: true,
        type: "text",
        isUndoAble: true,
        forceValue: true,
        dataPointsName: "askMHMedicationDetails"
      },
      nextStep: this.handleMHMedicationDetailsWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNorfolkScript) => {
    d.state.mhMedicationDetails = d.response
    script.referralStore.setCustomField<State>("mhMedicationDetails", d.response)
  })
  @step.checkInputForCrisis({
    ignoredCategories: [Category.Medication],
    getNextStep: (s: SelfReferralNorfolkScript) => s.askGender
  })
  async handleMHMedicationDetailsWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.askGender }
  }

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.goToCollectPhoneNumber(d)
    return { ...result, nextStep: this.askDoYouWantToShareEmail }
  }

  @step.logState
  askDoYouWantToShareEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Would you also like to provide an email address?",
      prompt: {
        id: this.getPromptId("askDoYouWantToShareEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleDoYouWantToShareEmail
    }
  }

  @step.logStateAndResponse
  handleDoYouWantToShareEmail(d: IStepData<State, boolean>): IStepResult {
    if (d.response) return { nextStep: this.askEmail }
    d.state.email = undefined // Just in case there's an undo, clear email
    return { nextStep: this.finishSelfReferral }
  }

  @step.logState
  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askMainIssue }
  }

  @step.logState
  askMainIssue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "Brilliant",
        `So ${name}, What's the main issue that has brought you here today?`,
        "(Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life)",
        "Please note this information will be stored on your personal record to support your assessment"
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNorfolkScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralNorfolkScript) => s.askWhatIsYourGoal
  })
  handleMainIssueWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      body: "Thank you for sharing this. Your response will help us direct you to the most appropriate care",
      nextStep: this.askWhatIsYourGoal
    }
  }

  @step.logState
  askWhatIsYourGoal(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "By seeking support what are you hoping will change for you?",
        "For example, being able to engage in activities you are not currently able to do, or get back to doing things you enjoy"
      ],
      prompt: {
        id: this.getPromptId("askWhatIsYourGoal"),
        type: "text",
        forceValue: true,
        dataPointsName: "askWhatIsYourGoal"
      },
      nextStep: this.handleWhatIsYourGoalWithCrisis
    }
  }

  @step.logState
  askSexuality(d: IStepData<State>): IStepResult {
    const sexualities = this.getSexualities(d.state)
    if (!sexualities?.length) {
      this.logBreadcrumb("SEXUALITIES NOT FOUND", d.state, { sexualities })
      this.logMessage("SEXUALITIES NOT FOUND")
      return { nextStep: this.askNationalIdentity }
    }
    return {
      body: [
        "How would you describe your sexuality?",
        "We ask this in order to ensure we are equally supporting all members of our community"
      ],
      prompt: {
        id: this.getPromptId("askSexuality"),
        trackResponse: true,
        type: "inlinePicker",
        choices: sexualities.map(g => ({ body: this.getChoiceBody(g), value: g })),
        dataPointsName: "askSexuality"
      },
      nextStep: this.handleSexuality
    }
  }

  @step.logState
  askNationalIdentity(d: IStepData<State>): IStepResult {
    const nationalities = this.getNationalities(d.state)
    if (!nationalities?.length) {
      this.logBreadcrumb("NATIONALITIES NOT FOUND", d.state, { nationalities })
      this.logMessage("NATIONALITIES NOT FOUND")
      return { nextStep: this.askEthnicity }
    }

    return {
      body: "What's your nationality?",
      prompt: {
        id: this.getPromptId("askNationalIdentity"),
        trackResponse: true,
        type: "inlinePicker",
        choices: nationalities.map(n => ({ body: n, value: n })),
        dataPointsName: "askNationalIdentity"
      },
      nextStep: this.handleNationalIdentity
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNorfolkScript) => {
    d.state.nationalIdentity = d.response
    script.referralStore.setCustomField<State>("nationalIdentity", d.state.nationalIdentity)
  })
  handleNationalIdentity(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEthnicity }
  }

  @step.logState
  askRequireInterpreter(d: IStepData<State>): IStepResult {
    if (d.state.spineInterpreterRequired) {
      return { nextStep: this.askSignLanguageInterpreter }
    }
    return {
      body: "Do you require an interpreter?",
      prompt: {
        id: this.getPromptId("askRequireInterpreter"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequireInterpreter
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralNorfolkScript) => {
    d.state.requiresInterpreter = d.response
    script.referralStore.setCustomField<State>("requiresInterpreter", d.response)
  })
  handleRequireInterpreter(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askInterpreterLanguage }
    }
    return { nextStep: this.askSignLanguageInterpreter }
  }

  @step.logState
  askInterpreterLanguage(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const primaryLanguage = d.state.primaryLanguage
    const languages = this.getLanguages(d.state)
    if (!languages?.length) {
      this.logBreadcrumb("LANGUAGES NOT FOUND", d.state, { languages })
      this.logMessage("LANGUAGES NOT FOUND")
      return { nextStep: this.askExArmedForces }
    }

    return {
      body: [
        `So ${name}, you stated your main spoken language is ${primaryLanguage}`,
        "Is this the language you need an interpreter for?",
        "(If not, just select the language you'd like an interpreter for)"
      ],
      prompt: {
        id: this.getPromptId("askInterpreterLanguage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: `Yes, I need an interpreter for ${primaryLanguage}`,
            value: primaryLanguage
          },
          ...languages
            .filter(language => language !== primaryLanguage)
            .map(g => ({ body: g, value: g }))
        ],
        dataPointsName: "askInterpreterLanguage"
      },
      nextStep: this.handleInterpreterLanguage
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralNorfolkScript) => {
    script.referralStore.addClinicalNote(`User needs interpreter for ${d.response} language`)
  })
  handleInterpreterLanguage(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askSignLanguageInterpreter }
  }

  @step.logState
  askSignLanguageInterpreter(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you need a sign language interpreter?",
      prompt: {
        id: this.getPromptId("askSignLanguageInterpreter"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        trackResponse: true
      },
      nextStep: this.handleSignLanguageInterpreter
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralNorfolkScript) => {
    d.state.signLanguageInterpreter = d.response
    script.referralStore.setCustomField<State>("signLanguageInterpreter", d.response)
  })
  handleSignLanguageInterpreter(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askExArmedForces }
  }

  @step.logState
  askKeyWorker(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you a NHS member of staff?",
      prompt: {
        id: this.getPromptId("askKeyWorker"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleAskKeyWorker
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralNorfolkScript) => {
    d.state.keyWorker = d.response
    script.referralStore.setCustomField<State>("keyWorker", d.response)
  })
  handleAskKeyWorker(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askPerinatal }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.askLongTermMedicalCondition }
    }
    return {
      body: "Are you currently pregnant, or do you have a child under the age of 1? This includes if you are a father or co-parent",
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: g, value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logState
  askLongTermMedicalCondition(d: IStepData<State>): IStepResult {
    const medicalConditions = this.getMedicalConditions(d.state)
    if (!medicalConditions?.length) {
      this.logBreadcrumb("MEDICAL CONDITIONS NOT FOUND", d.state, { medicalConditions })
      this.logMessage("MEDICAL CONDITIONS NOT FOUND")
      return { nextStep: this.askDisability }
    }
    return {
      body: "Do you have a long term medical condition?",
      prompt: {
        id: this.getPromptId("askLongTermMedicalCondition"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          { body: "I don't", value: "No", backgroundColor: "#EC9CC8", selectIndividually: true },
          ...medicalConditions.filter(m => m !== "No").map(m => ({ body: m, value: m }))
        ],
        dataPointsName: "askLongTermMedicalCondition"
      },
      nextStep: this.handleLongTermMedicalCondition
    }
  }

  @step
  askHowWellYouManageYourLTC(d: IStepData<State>): IStepResult {
    const condition = this.getCustomCondition(d.state)
    return {
      body: `And how well do you think you manage your ${condition}?`,
      prompt: {
        id: this.getPromptId("askHowWellYouManageYourLTC"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Not very well", value: "little" },
          { body: "Fairly well", value: "fairly" },
          { body: "Very well", value: "very" }
        ],
        dataPointsName: "askHowWellYouManageYourLTC"
      },
      nextStep: this.handleHowWellYouManageYourLTC
    }
  }

  @step.logState
  askWhereDidYouHearAboutUs(d: IStepData<State>): IStepResult {
    const referralSource = this.getReferralSources(d.state)
    if (!referralSource?.length) {
      this.logBreadcrumb("REFERRAL SOURCES NOT FOUND", d.state, {
        referralSource
      })
      this.logMessage("REFERRAL SOURCES NOT FOUND")
      return { nextStep: this.startSelfReferralPart3 }
    }

    return {
      body: "How did you hear about the service?",
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "inlinePicker",
        choices: referralSource
          .filter(rs => rs !== "Other (please state)")
          .map(g => ({ body: g, value: g })),
        textPrompt: {
          forceValue: true,
          placeholder: "Other (please specify)"
        },
        isUndoAble: true
      },
      nextStep: this.handleWhereDidYouHearAboutUs
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script) => {
    const referralSource = script.getReferralSources(d.state)
    if (referralSource.includes(d.response)) {
      d.state.whereDidYouHearAboutService = d.response
    } else {
      d.state.whereDidYouHearAboutService = "Other (please state)"
      d.state.whereDidYouHearAboutServiceOther = d.response
    }
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralNorfolkScript) => s.startSelfReferralPart3
  })
  handleWhereDidYouHearAboutUs(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.startSelfReferralPart3 }
  }

  @step.logState
  startSelfReferralPart3(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thanks ${name}. I have a few more questions about the reason for your referral, before we move onto more generalised questions about your symptoms`,
      nextStep: this.askInterestedInWorkshops
    }
  }

  @step.logState
  askInterestedInWorkshops(_d: IStepData<State>): IStepResult {
    return {
      body: "We can offer a range of online therapy modules that can be worked through at your own pace and online workshops, are these of interest to you?",
      prompt: {
        id: this.getPromptId("askInterestedInWorkshops"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleInterestedInWorkshops
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralNorfolkScript) => {
    d.state.interestedInWorkshops = d.response
    script.referralStore.setCustomField<State>("interestedInWorkshops", d.response)
  })
  handleInterestedInWorkshops(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askWorkshopsOrOnline }
    }
    return { nextStep: this.doReferralSubmission }
  }

  @step.logState
  askWorkshopsOrOnline(_d: IStepData<State>): IStepResult {
    const choices: Array<{ body: string; value: SELF_HELP_INTEREST }> = [
      { body: "Workshops", value: "Workshops" },
      { body: "Online workshops", value: "Online workshops" }
    ]

    return {
      body: "Which option is of interest?",
      prompt: {
        id: this.getPromptId("askWorkshopsOrOnline"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.handleWorkshopsOrOnline
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, "Workshops" | "Online">, script: SelfReferralNorfolkScript) => {
      script.referralStore.addClinicalNote(`User is interested in ${d.response} option`)
    }
  )
  handleWorkshopsOrOnline(_d: IStepData<State, "Workshops" | "Online">): IStepResult {
    return { nextStep: this.doReferralSubmission }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [
        "And that's everything",
        `You've officially been referred to ${iaptName}`,
        "You have taken an important step towards better mental health"
      ],
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: "What happens next?" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.end
    }
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadMaydenNorfolk> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      consentEmail: !!state.email,
      consentDataShare: true,
      consentDataStore: true,
      gpName: state.odsGP?.name ?? state.gp?.name,
      gpCodeNACS: state.odsGP?.id ?? state.gp?.nacsCode,
      gpCodePIMS: state.gp?.pimsCode,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      medicationPrescribed: state.mhMedication,
      title: state.title,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: this.getName(state),
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      email: state.email,
      problemInOwnWords: state.mainIssue,
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirthValue(state),
      pronounsPreferredList: state.preferredPronouns,
      pronounsPreferredOther: state.preferredPronounsOther,
      nationality: this.getNationality(state),
      ethnicity: this.getEthnicity(state),
      language: this.getLanguage(state),
      sexuality: this.getSexuality(state),
      armedForces: this.getArmedForce(state),
      treatmentExpectation: state.therapyGoal,
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      gpPractice: state.odsGP?.name ?? state.gp?.name,
      interpreter: state.requiresInterpreter,
      interpreterSignLanguage: state.signLanguageInterpreter,
      ltc: this.getLTC(state),
      ltcOtherDetails: state.ltcOtherDetails,
      ltcAffectMood: state.ltcAffectsMood,
      ltcManagement: this.getLTCManagement(state),
      disability: this.getDisability(state),
      adhd: state.hasADHD,
      asd: state.hasASD,
      perinatal: this.getPerinatalStatus(state),
      problemIsDrugsAlcohol: state.mainProblemAlcoholOrDrugs,
      currentSupportDetailsNorfolk: this.getCurrentSupport(state),
      currentSupportDetailsOther: state.currentMHSupportDetails,
      medicationForMHDetails: state.mhMedicationDetails,
      referralSourceNorfolk: this.getWhereDidYouHearAboutUs(state),
      referralSourceDetails: state.whereDidYouHearAboutServiceOther || undefined,
      correspondencePreferred: this.getPreferredContactMethod(state),
      consentResearch: state.consentResearch,
      keyWorker: state.keyWorker,
      clinicalNotes: this.referralStore.clinicalNotes
    }
  }

  getCustomReferralType(state: State): string | undefined {
    const currentMHTreatment = this.referralStore.getCustomField("currentMHTreatment")
    const hasCurrentMHTreatment = !!currentMHTreatment?.length
    if (hasCurrentMHTreatment) return "Extended Assessment (User seeing other MH professionals)"
  }

  getReferralTypeForRisk(_state: State): string | undefined {
    if (this.clinicalStore.isHighRisk) return ReferralType.RISK_PATIENT
    if (this.clinicalStore.isModerateRisk) return ReferralType.RISK_PATIENT
  }

  /** On Handle Overwrites */

  async onCheckFullAddress(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  async onHandleSelectAddressFromPostCode(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  async onHandlePermissionToSendMailToAddress(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  async onHandleEmail(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.finishSelfReferral }
  }

  async onHandleGender(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askSameGenderAsBirth }
  }

  async onHandleGenderSameAsBirth(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askSexuality }
  }

  async onHandleSexuality(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askNationalIdentity }
  }

  async onHandleEthnicity(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askPrimaryLanguage }
  }

  async onHandlePrimaryLanguage(state: State): Promise<IStepResult | void> {
    return {
      nextStep: state.primaryLanguage?.match(/english/gi) //
        ? this.askSignLanguageInterpreter
        : this.askRequireInterpreter
    }
  }

  async onHandleExArmedForces(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askKeyWorker }
  }

  async onHandlePerinatal(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askLongTermMedicalCondition }
  }

  async onHandleLongTermMedicalCondition(state: State): Promise<IStepResult | void> {
    if (state.longTermMedicalCondition && state.longTermMedicalCondition[0] === "No") {
      return {
        nextStep: this.askDisability
      }
    }
    return {
      nextStep: this.askDoesLTCAffectMood
    }
  }

  async onHandleDoesLTCAffectMood(state: State): Promise<IStepResult | void> {
    return { nextStep: this.askHowWellYouManageYourLTC }
  }

  async onHandleHowWellYouManageYourLTC(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askDisability }
  }

  async onHandleHasADHD(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askWhereDidYouHearAboutUs }
  }

  async onHandleWhatIsYourGoalWithCrisis(state: State): Promise<IStepResult> {
    this.referralStore.addClinicalNote(`Expectations from Support: ${state.therapyGoal}`)
    return { nextStep: this.askCurrentMHSupport }
  }

  /** Get Question Options */

  getCustomCondition(state: State): string {
    return joinWithAnd(state.longTermMedicalCondition)
  }

  getDisabilities(state: State): string[] {
    return state.iapt?.referralForm?.disabilities ?? []
  }

  getReferralSources(state: State): string[] {
    return state.iapt?.referralForm?.referralSources ?? []
  }

  getGenders(state: State): string[] {
    return state.iapt?.referralForm?.genders ?? []
  }

  getGenderSameAsBirthOptions(state: State): string[] {
    return state.iapt?.referralForm?.sameGenderAsBirth ?? []
  }

  getSexualities(state: State): string[] {
    return state.iapt?.referralForm?.sexualities ?? []
  }

  getPerinatalStatuses(state: State): string[] {
    return state.iapt?.referralForm?.perinatalStatuses ?? []
  }

  getMedicalConditions(state: State): string[] {
    return state.iapt?.referralForm?.LTCs ?? []
  }

  getPreferredContactMethods(state: State): string[] {
    return state.iapt?.referralForm?.preferredContactMethods ?? []
  }

  getCurrentSupportOptions(state: State): string[] {
    return state.iapt?.referralForm?.currentSupport ?? []
  }

  getNationalities(state: State): string[] {
    return state.iapt?.referralForm?.nationalities ?? []
  }

  /** Get Payload Values */

  getWhereDidYouHearAboutUs(state: State): ReferralSourceNorfolk {
    return referralSources[state.whereDidYouHearAboutService!] ?? "Other"
  }

  getLanguage(state: State): LANGUAGE_MAYDEN_NORFOLK | undefined {
    const spineLanguageKey = `${state.spineLanguage ?? ""}`.toLowerCase()
    const primaryLanguageKey = `${state.primaryLanguage ?? ""}`.toLowerCase()
    const languagesLowerCase = toLowerCaseProperties(languages)
    return languagesLowerCase[spineLanguageKey] ?? languagesLowerCase[primaryLanguageKey]
  }

  getLanguageInterpreterLanguage(state: State): LANGUAGE_MAYDEN | undefined {
    const interpreterLanguage = state.interpreterLanguage
    if (interpreterLanguage) {
      return languages[interpreterLanguage] ?? interpreterLanguage
    }
    return undefined
  }

  getDisability(state: State): DISABILITY_MAYDEN_NORFOLK | undefined {
    if (!state.disability) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }
    return disabilities[state.disability!]
  }

  getLTC(state: State): LTC_MAYDEN_NORFOLK[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getPreferredContactMethod(state: State): CorrespondencePreferred {
    return preferredContactMethods[state.preferredContactMethod!]
  }

  getNationality(state: State): NATIONALITY_MAYDEN {
    return nationalities[state.nationalIdentity!]
  }

  getCurrentSupport(state: State): CurrentSupportDetailsNorfolk {
    return currentSupport[state.currentMHSupport!]
  }

  getPerinatalStatus(state: State): PERINATAL_MAYDEN_NORFOLK {
    return perinatalStatuses[state.perinatalStatus!]
  }

  getArmedForce(state: State): ARMED_FORCES_MAYDEN_NORFOLK {
    return exArmedForces[state.isExArmedForces!]
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_NORFOLK {
    return ethnicities[state.ethnicity!]
  }

  getGenderSameAsBirthValue(state: State): GenderBirthAssigned {
    return sameGenderAsBirth[state.sameGenderAsBirth!]
  }

  getGender(state: State): GENDER_MAYDEN_NORFOLK {
    return genders[state.gender!]
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_SUFFOLK {
    return sexualities[state.sexuality!]
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }
}

export default class SelfReferralNorfolkDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralNorfolk
  readonly name: string = "SelfReferralNorfolkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralNorfolkDialogue.id, new SelfReferralNorfolkScript(), state, snapshot)
  }
}
