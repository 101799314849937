import { z, ZodSchema } from "zod"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import {
  ALCOHOL_FREQUENCIES,
  ALCOHOL_QUANTITIES,
  DiscussionSteps,
  TrackingEvents
} from "../../../models/Constants"
import { PostcodeStatus } from "../../../models/IPostcode"
import isEmail from "validator/lib/isEmail"
import getAddressesByPostcode, { IAddress } from "../../../backend/api/getAddressesByPostcode"
import { getPostCodeDetails } from "../../../backend/api/postcodes"
import { joinWithAnd, joinWithOr } from "../../../utils/array"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IGetAddressStatus } from "../../../models/IGetAddress"
import invariant from "../../../utils/invariant"
import moment from "moment"
import { CovidStatus } from "@limbic/types"
import { Category } from "@limbic/crisis-detection"
import {
  IInlinePickerMultiSelectPrompt,
  IInlinePickerSingleSelectPrompt
} from "../../../backend/chatbot/models/IPrompt"
import { Severity } from "../../../models/Logger/Severity"

interface State extends BaseScriptState {
  retryPostcode?: string
  invalidPostcodeEntered?: string
  addressLookupCounter?: number
  postcodeLookupCounter?: number
  hideEarlierYouSaid?: boolean
  userHasEmail?: boolean
  mainIssue?: string
  therapyGoal?: string
  hasASD?: boolean
  hasADHD?: boolean
}

export type SelfReferralScriptState = State

export const SelfReferralScriptStateSchema = BaseScriptStateSchema.extend({
  retryPostcode: z.string().optional(),
  invalidPostcodeEntered: z.string().optional(),
  addressLookupCounter: z.number().optional(),
  postcodeLookupCounter: z.number().optional(),
  hideEarlierYouSaid: z.boolean().optional(),
  userHasEmail: z.boolean().optional(),
  mainIssue: z.string().optional(),
  therapyGoal: z.string().optional(),
  hasASD: z.boolean().optional(),
  hasADHD: z.boolean().optional()
})

export default abstract class SelfReferralScript extends BaseScript<State> {
  /** Abstract Script Steps */
  abstract sayReferralSucceeded(d: IStepData<State>): IStepResult | Promise<IStepResult>
  abstract sayReferralFailed(d: IStepData<State>): IStepResult | Promise<IStepResult>

  /** Optional Abstract Script Steps */

  sayIntro?(_d: IStepData<State>): IStepResult | Promise<IStepResult>

  /** Abstract Generic Handlers */

  abstract getReferralPayload(state: State): Promise<Record<string, any>>
  abstract onReferralFinished(state: State): Promise<void>
  abstract onRiskReferralFinished(state: State): Promise<void>

  /** Optional Abstract Generic Handlers */

  onHandleDoYouHaveAnEmail?(state: State): Promise<IStepResult | void>
  onHandleEmail?(state: State): Promise<IStepResult | void>
  onHandlePostCodeForAddressLookup?(state: State): Promise<IStepResult | void>
  onHandleInvalidPostCodeForAddressLookupWithCrisis?(state: State): Promise<IStepResult | void>
  onHandleSelectAddressFromPostCode?(state: State): Promise<IStepResult | void>
  onCheckFullAddress?(state: State): Promise<IStepResult | void>
  onHandlePermissionToSendMailToAddress?(state: State): Promise<IStepResult | void>
  onHandleEthnicity?(state: State): Promise<IStepResult | void>
  onHandleNationality?(state: State): Promise<IStepResult | void>
  onHandleReligion?(state: State): Promise<IStepResult | void>
  onHandleGender?(state: State): Promise<IStepResult | void>
  onHandlePrimaryLanguage?(state: State): Promise<IStepResult | void>
  onHandlePerinatal?(state: State): Promise<IStepResult | void>
  onHandleDisabilityStatus?(state: State): Promise<IStepResult | void>
  onHandleDisability?(state: State): Promise<IStepResult | void>
  onHandleExArmedForces?(state: State): Promise<IStepResult | void>
  onHandleSexuality?(state: State): Promise<IStepResult | void>
  onHandleLongTermMedicalCondition?(state: State): Promise<IStepResult | void>
  onHandleDoesLTCAffectMood?(state: State): Promise<IStepResult | void>
  onHandleHowMuchLTCAffectsMood?(state: State): Promise<IStepResult | void>
  onHandleHowWellYouManageYourLTC?(state: State): Promise<IStepResult | void>
  onHandleSubstances?(_state: State): Promise<IStepResult | void>
  onHandleSubstancesOrigin?(state: State): Promise<IStepResult | void>
  onHandleSubstancesInfoWithCrisis?(state: State): Promise<IStepResult | void>
  onHandleMedicationInfoWithCrisis?(state: State): Promise<IStepResult | void>
  onHandleMedicationWithinDoseRange?(state: State): Promise<IStepResult | void>
  onHandleCurrentSupport?(state: State): Promise<IStepResult | void>
  onHandleHaveYouEverCaughtCovid?(state: State): Promise<IStepResult | void>
  onHandleWhenDidYouHaveCovid?(state: State): Promise<IStepResult | void>
  onHandleGenderSameAsBirth?(state: State): Promise<IStepResult | void>
  onHandleWhatIsYourGoalWithCrisis?(state: State): Promise<IStepResult | void>
  onHandleHasADHD?(state: State): Promise<IStepResult | void>
  onHandleHasASD?(state: State): Promise<IStepResult | void>
  /** Script Steps */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0, postcodeLookupCounter: 0 })
  start(d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.sayIntro || this.startSelfReferralPart1 }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(this.name)
    return super.end(d)
  }

  // ------------ PART 1 ------------

  @step.logState
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    const phoneNumber = d.state.phoneNumber || this.referralStore.getCustomField("phoneNumber")
    if (phoneNumber) {
      d.state.phoneNumber = phoneNumber
      return { nextStep: this.askDoYouHaveAnEmail }
    }
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  goToCollectPhoneNumber(d: IStepData<State>): IStepResult | Promise<IStepResult> {
    const CollectPhoneNumberDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectPhoneNumber
    )
    const nextDialogue = CollectPhoneNumberDialogue
      ? new CollectPhoneNumberDialogue({ ...d.state, skipCollectPhoneNumberIntro: true })
      : undefined
    return {
      nextDialogue,
      nextStep: this.askDoYouHaveAnEmail
    }
  }

  @step.logState
  askDoYouHaveAnEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have an email address?",
      prompt: {
        id: this.getPromptId("askDoYouHaveAnEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes, I have an email", value: true },
          { body: "No, I don't have an email", value: false }
        ]
      },
      nextStep: this.handleDoYouHaveAnEmail
    }
  }

  @step.logStateAndResponse
  async handleDoYouHaveAnEmail(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.userHasEmail = d.response
    this.setPeople({ hasEmail: d.response })
    this.track(TrackingEvents.DO_YOU_HAVE_EMAIL, { body: d.response ? "Yes" : "No" })
    d.state.canSendEmail = d.response
    const result = await this.onHandleDoYouHaveAnEmail?.(d.state)
    if (result) return result

    return {
      nextStep: d.response //
        ? this.askEmail
        : this.checkPostCodeFromAddressLookup
    }
  }

  @step.logState
  askEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type your email address",
      prompt: { id: this.getPromptId("askEmail"), type: "email" },
      nextStep: this.handleEmail
    }
  }

  @step.logStateAndResponse
  async handleEmail(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isEmail(d.response)
    if (!isValid) {
      return {
        body: "Sorry this is not a valid email address. Let's try again",
        nextStep: this.askEmail
      }
    }
    d.state.email = d.response
    const result = await this.onHandleEmail?.(d.state)
    if (result) return result

    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  @step
  @step.setState<State>({ addressLookupCounter: 0, postcodeLookupCounter: 0 })
  checkPostCodeFromAddressLookup(d: IStepData<State>): IStepResult {
    const p = d.state.userPostcode
    if (!p?.postcode) {
      return { nextStep: this.askPostCodeForAddressLookup }
    }
    const hasAddress = d.state.address && (d.state.address2 || d.state.city || d.state.county)
    if (hasAddress) return { nextStep: this.handleSelectAddressFromPostCode }
    return { nextStep: this.askSelectAddressFromPostCode }
  }

  @step.logState
  askRetrySelectAddressFromPostcode(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, something went wrong while looking up addresses based on your postcode",
      nextStep: this.returnToSelectAddressFromPostcode
    }
  }

  @step.logState
  returnToSelectAddressFromPostcode(_d: IStepData<State>): IStepResult {
    return {
      body: "Let's try again",
      prompt: {
        id: this.getPromptId("returnToSelectAddressFromPostcode"),
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: false },
          { body: "Let me re-type my postcode", value: true }
        ]
      },
      nextStep: this.handleReturnToSelectAddressFromPostcode
    }
  }

  @step.logStateAndResponse
  handleReturnToSelectAddressFromPostcode(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      d.state.addressLookupCounter = 1
      return { nextStep: this.askPostCodeForAddressLookup }
    }
    return { nextStep: this.askSelectAddressFromPostCode }
  }

  @step.logState
  askRetryInternetConnectionSelectAddressFromPostcode(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryInternetConnectionSelectAddressFromPostcode"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.askSelectAddressFromPostCode
    }
  }

  @step.logState
  async askSelectAddressFromPostCode(d: IStepData<State>): Promise<IStepResult> {
    const isFirstTime = !d.state.addressLookupCounter
    const hideEarlierYouSaid = d.state.hideEarlierYouSaid
    const p = d.state.userPostcode
    const [addresses, addressesStatus] = await getAddressesByPostcode(p)

    if (addressesStatus === IGetAddressStatus.RequestFailed) {
      return { nextStep: this.askRetrySelectAddressFromPostcode }
    }

    if (addressesStatus === IGetAddressStatus.NoInternetConnection) {
      return { nextStep: this.askRetryInternetConnectionSelectAddressFromPostcode }
    }

    if (!addresses?.length) {
      return { nextStep: this.askAddress }
    }

    const addressOptions = addresses.map(a => ({ body: `${a.address} ${a.address2}`, value: a }))
    return {
      body: [
        isFirstTime && !hideEarlierYouSaid
          ? `So earlier you said your postcode is ${p?.postcode}`
          : undefined,
        "Please can you select your house/apartment from the list below?"
      ],
      prompt: {
        id: this.getPromptId("askSelectAddressFromPostCode"),
        type: "inlinePicker",
        choices: [
          ...addressOptions,
          {
            body: "Let me enter my address manually",
            value: "manually",
            backgroundColor: "#EC9CC8"
          }
        ]
      },
      nextStep: this.handleSelectAddressFromPostCode
    }
  }

  @step
  askPostCodeForAddressLookup(d: IStepData<State>): IStepResult {
    if (d.response === "manual-address") {
      this.track(TrackingEvents.MANUAL_ADDRESS_POSTCODE_NOT_FOUND, {
        body: d.state.postcodeEntered
      })
      return {
        nextStep: this.askAddress
      }
    }
    return {
      body: "Okay, so what's your postcode?",
      prompt: {
        id: this.getPromptId("askPostCodeForAddressLookup"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostCodeForAddressLookupWithCrisis
    }
  }

  @step
  returnToAskPostCodeForAddressLookup(_d: IStepData<State>): IStepResult {
    return {
      body: "So I was going to ask you about your address",
      nextStep: this.askPostCodeForAddressLookup
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralScript) => s.returnToAskPostCodeForAddressLookup
  })
  async handlePostCodeForAddressLookupWithCrisis(d: IStepData<State>): Promise<IStepResult> {
    d.state.postcodeEntered = d.response || d.state.retryPostcode
    d.state.retryPostcode = d.state.postcodeEntered

    const [postcode, postcodeStatus] = await getPostCodeDetails(d.response || d.state.retryPostcode)
    if (postcodeStatus === PostcodeStatus.NoInternetConnection) {
      return { nextStep: this.askRetryInternetConnection }
    }

    if (postcodeStatus === PostcodeStatus.Success) {
      d.state.userPostcode = postcode
      const result = await this.onHandlePostCodeForAddressLookup?.(d.state)
      if (result) return result
      return { nextStep: this.askSelectAddressFromPostCode }
    }

    d.state.postcodeLookupCounter!++
    if (postcodeStatus === PostcodeStatus.RequestFailed) {
      return { nextStep: this.sayPostcodeLookupFailed }
    }

    d.state.invalidPostcodeEntered = d.response || d.state.retryPostcode
    const result = await this.onHandleInvalidPostCodeForAddressLookupWithCrisis?.(d.state)
    if (result) return result
    return { nextStep: this.sayNotValidPostcode }
  }

  @step.logState
  sayPostcodeLookupFailed(d: IStepData<State>): IStepResult {
    const body = ["Hmmm, it looks like something went wrong while looking up your postcode"]
    const needsToCallIn = d.state.postcodeLookupCounter! >= 3
    if (needsToCallIn) {
      this.setEligibility(d.state, false)
      this.setUserNeedsToCallIn(d.state)
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(d.state) || organisationName
      body.push(`And a valid UK postcode is required in order for me to refer you to ${iaptName}`)
      return { body, nextStep: this.sayUserNeedsToCallIn }
    }
    return {
      body,
      prompt: {
        id: this.getPromptId("askPostcodeRetry"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.retryPostCodeForAddressLookup
    }
  }

  @step.logState
  askRetryInternetConnection(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, It looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("askRetryConnection"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.retryPostCodeForAddressLookup
    }
  }

  @step.logStateAndResponse
  retryPostCodeForAddressLookup(_d: IStepData<State, boolean>): IStepResult {
    // This step is needed to ensure the next will run without a d.response
    return { nextStep: this.handlePostCodeForAddressLookupWithCrisis }
  }

  @step
  sayNotValidPostcode(d: IStepData<State>): IStepResult {
    const needsToCallIn = d.state.postcodeLookupCounter! >= 3
    if (needsToCallIn) {
      this.setEligibility(d.state, false)
      this.setUserNeedsToCallIn(d.state)
    }
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [
        "Hmmm, I wasn't able to find any addresses with the postcode you provided",
        `I need a valid UK postcode in order to refer you to ${iaptName}`
      ],
      nextStep: needsToCallIn //
        ? this.sayUserNeedsToCallIn
        : this.sayPleaseRetypeYourPostcode
    }
  }

  @step
  sayPleaseRetypeYourPostcode(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("sayPleaseRetypeYourPostcode"),
        type: "inlinePicker",
        choices: [
          { body: "Change my postcode" },
          { body: "My postcode is correct", value: "manual-address" }
        ]
      },
      nextStep: this.askPostCodeForAddressLookup
    }
  }

  @step
  sayUserNeedsToCallIn(_d: IStepData<State>): IStepResult {
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers ?? ""
    return {
      body:
        "Please give any one of our services a call on the following phone numbers:\n" +
        organisationPhoneNumbers,
      clearStack: true,
      nextStep: this.end
    }
  }

  @step.logStateAndResponse
  @step.setState((s: State) => ({ addressLookupCounter: s.addressLookupCounter || 0 }))
  async handleSelectAddressFromPostCode(
    d: IStepData<State, "manually" | IAddress>
  ): Promise<IStepResult> {
    const hasAddress = d.state.address && (d.state.address2 || d.state.city || d.state.county)
    if (!d.response && hasAddress) {
      d.response = {
        address: d.state.address!,
        address2: d.state.address2,
        city: d.state.city ?? d.state.county ?? d.state.userPostcode?.postcode ?? "unknown",
        county: d.state.county ?? d.state.city ?? d.state.userPostcode?.postcode ?? "unknown"
      }
    }
    const body = d.response === "manually" ? "Let me enter my address manually" : "Address selected"
    this.track(TrackingEvents.SELECT_ADDRESS, { body })
    if (d.response === "manually") {
      return { nextStep: this.askAddress }
    }
    d.state.address = d.response.address
    d.state.address2 = d.response.address2
    d.state.city = d.response.city
    d.state.county = d.response.county
    const result = await this.onHandleSelectAddressFromPostCode?.(d.state)
    if (result) return result

    return { nextStep: this.askPermissionToSendMailToAddress }
  }

  @step.logState
  askAddress(_d: IStepData<State>): IStepResult {
    return {
      body: "Okay, please type your address below",
      prompt: {
        id: this.getPromptId("askAddress"),
        type: "address"
      },
      nextStep: this.handleAddressWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.checkInputForCrisis({
    disableDetectionIfWrong: true,
    getInput: (d: IStepData<State, IAddress>) => {
      const { address, address2, city, county } = d.response
      return `${address}, ${address2 ? address2 + ", " : ""} ${city}, ${county}`
    },
    getNextStep: (s: SelfReferralScript) => s.returnToAskAddress
  })
  @step.handleResponse((d: IStepData<State, IAddress>, script: SelfReferralScript) => {
    d.state.address = d.response.address
    d.state.address2 = d.response.address2
    d.state.city = d.response.city
    d.state.county = d.response.county
  })
  async handleAddressWithCrisis(_d: IStepData<State, IAddress>): Promise<IStepResult> {
    return { nextStep: this.checkFullAddress }
  }

  @step.logState
  async checkFullAddress(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.address || !d.state.city || !d.state.county) {
      return { nextStep: this.sayPleaseGiveFullAddress }
    }
    const result = await this.onCheckFullAddress?.(d.state)
    if (result) return result
    return { nextStep: this.askPermissionToSendMailToAddress }
  }

  @step
  returnToAskAddress(_d: IStepData<State>): IStepResult {
    return {
      body: "So I asked about your address earlier",
      nextStep: this.sayPleaseGiveFullAddress
    }
  }

  @step
  sayPleaseGiveFullAddress(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your full address",
      prompt: {
        id: this.getPromptId("sayPleaseGiveFullAddress"),
        type: "address"
      },
      nextStep: this.handleAddressWithCrisis
    }
  }

  @step.logState
  askPermissionToSendMailToAddress(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you happy for us to send written communications to your home?",
      prompt: {
        id: this.getPromptId("askPermissionToSendMailToAddress"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handlePermissionToSendMailToAddress
    }
  }

  @step.logState
  async handlePermissionToSendMailToAddress(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.canSendMailToAddress = d.response
    const result = await this.onHandlePermissionToSendMailToAddress?.(d.state)
    if (result) return result

    return { nextStep: this.finishSelfReferral }
  }

  @step.logState
  finishSelfReferral(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askReadyForMore }
  }

  @step.logState
  askReadyForMore(_d: IStepData<State>): IStepResult {
    return {
      body: ["Great. We're nearly finished", "Just a few more questions", "Ready?"],
      prompt: {
        id: this.getPromptId("askReadyForMore"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "👍", value: false },
          { body: "Yes", value: true },
          { body: "Go on", value: false }
        ]
      },
      nextStep: this.handleReadyForMore
    }
  }

  @step.logState
  handleReadyForMore(d: IStepData<State, boolean>): IStepResult {
    return {
      body: d.response ? "That's what I like to hear!" : "Okay...",
      nextStep: this.startSelfReferralPart2
    }
  }

  // ------------ PART 2 ------------

  @step.logState
  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askEthnicity }
  }

  @step.logState
  askEthnicity(d: IStepData<State>): IStepResult {
    const ethnicities = this.getEthnicities(d.state)
    if (!ethnicities?.length) {
      this.logBreadcrumb("ETHNICITIES NOT FOUND", d.state, { ethnicities })
      this.logMessage("ETHNICITIES NOT FOUND")
      return { nextStep: this.askGender }
    }
    return {
      body: "Can you tell me which ethnicity you identify as?",
      prompt: {
        id: this.getPromptId("askEthnicity"),
        trackResponse: true,
        type: "inlinePicker",
        choices: ethnicities.map(e => ({ body: e, value: e })),
        dataPointsName: "askEthnicity"
      },
      nextStep: this.handleEthnicity
    }
  }

  @step.logStateAndResponse
  async handleEthnicity(d: IStepData<State, string>): Promise<IStepResult> {
    const ethnicities = this.getEthnicities(d.state)
    const isValid = ethnicities?.find(s => s === d.response)
    if (!isValid) {
      return {
        body: "Sorry I can't recognize this ethnicity. Let's try again",
        nextStep: this.askEthnicity
      }
    }
    d.state.ethnicity = d.response
    this.setPeople({ ethnicity: d.response })
    const result = await this.onHandleEthnicity?.(d.state)
    if (result) return result

    return { nextStep: this.askGender }
  }

  @step.logState
  async askNationality(d: IStepData<State>): Promise<IStepResult> {
    const nationalities = this.getNationalities(d.state)
    if (!nationalities?.length) {
      this.logBreadcrumb("NATIONALITIES NOT FOUND", d.state, { nationalities })
      this.logMessage("NATIONALITIES NOT FOUND")
      const result = await this.onHandleNationality?.(d.state)
      if (result) return result
      return { nextStep: this.askReligion }
    }

    return {
      body: "What's your nationality?",
      prompt: {
        id: this.getPromptId("askNationality"),
        trackResponse: true,
        type: "inlinePicker",
        choices: nationalities.map(g => ({ body: g, value: g }))
      },
      nextStep: this.handleNationality
    }
  }

  @step.logStateAndResponse
  async handleNationality(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.nationality = d.response
    this.setPeople({ nationality: d.response })
    const result = await this.onHandleNationality?.(d.state)
    if (result) return result
    return { nextStep: this.askReligion }
  }

  @step.logState
  askReligion(d: IStepData<State>): IStepResult {
    const religions = this.getReligions(d.state)
    if (!religions?.length) {
      this.logBreadcrumb("RELIGIONS NOT FOUND", d.state, { religions })
      this.logMessage("RELIGIONS NOT FOUND")
      return { nextStep: this.askGender }
    }
    return {
      body: "What's your religious group?",
      prompt: {
        id: this.getPromptId("askReligion"),
        trackResponse: true,
        type: "inlinePicker",
        choices: religions.map(e => ({ body: this.getChoiceBody(e), value: e })),
        dataPointsName: "askReligion"
      },
      nextStep: this.handleReligion
    }
  }

  @step.logStateAndResponse
  async handleReligion(d: IStepData<State, string>): Promise<IStepResult> {
    const religions = this.getReligions(d.state)
    const isValid = religions?.find(s => s === d.response)
    if (!isValid) {
      return {
        body: "Sorry I can't recognize this religion. Let's try again",
        nextStep: this.askReligion
      }
    }
    d.state.religion = d.response
    this.setPeople({ religion: d.response })
    const result = await this.onHandleReligion?.(d.state)
    if (result) return result

    return { nextStep: this.askGender }
  }

  @step.logState
  askGender(d: IStepData<State>): IStepResult {
    const genders = this.getGenders(d.state)
    if (!genders?.length) {
      this.logBreadcrumb("GENDERS NOT FOUND", d.state, { genders })
      this.logMessage("GENDERS NOT FOUND")
      return { nextStep: this.askDisabilityStatus }
    }

    return {
      body: "Which gender do you identify as?",
      prompt: {
        id: this.getPromptId("askGender"),
        trackResponse: true,
        type: "inlinePicker",
        choices: genders.map(g => ({ body: g, value: g })),
        dataPointsName: "askGender"
      },
      nextStep: this.handleGender
    }
  }

  @step.logStateAndResponse
  async handleGender(d: IStepData<State, string | undefined>): Promise<IStepResult> {
    d.response ??= d.state.gender
    // 👆 if the response is empty it means we have arrived here
    // because we already had a value in place and need to skip
    // asking the user again
    const genders = this.getGenders(d.state)
    const isValid = genders?.find(s => s === d.response)
    if (!isValid && d.response !== d.state.gender) {
      return {
        body: "Sorry I can't understand that. Let's try again",
        nextStep: this.askGender
      }
    }
    d.state.gender = d.response
    this.setPeople({ gender: d.response })
    if (d.state.gender?.includes("Male")) {
      d.state.title = "Mr"
    } else if (d.state.gender?.includes("Female")) {
      d.state.title = "Ms"
    } else {
      d.state.title = "Mx"
    }
    const result = await this.onHandleGender?.(d.state)
    if (result) return result

    return { nextStep: this.askPerinatal }
  }

  @step.logState
  async askSameGenderAsBirth(d: IStepData<State>): Promise<IStepResult> {
    const genderSameAsBirth = this.getGenderSameAsBirthValues(d.state)
    if (!genderSameAsBirth?.length) {
      this.logBreadcrumb("SAME GENDER AS BIRTH VALUES NOT FOUND", d.state, { genderSameAsBirth })
      this.logMessage("SAME GENDER AS BIRTH VALUES NOT FOUND")
      const result = await this.onHandleGenderSameAsBirth?.(d.state)
      if (result) return result
      return { nextStep: this.askSexuality }
    }

    const name = this.getName(d.state)
    return {
      body: `Thanks ${name}, and is your gender the same as the gender you were assigned at birth?`,
      prompt: {
        id: this.getPromptId("askSameGenderAsBirth"),
        trackResponse: true,
        type: "inlinePicker",
        choices: genderSameAsBirth.map(g => ({ body: g, value: g })),
        dataPointsName: "askSameGenderAsBirth"
      },
      nextStep: this.handleSameGenderAsBirth
    }
  }

  @step.logState
  async handleSameGenderAsBirth(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.sameGenderAsBirth = d.response
    const result = await this.onHandleGenderSameAsBirth?.(d.state)
    if (result) return result
    return {
      nextStep: this.askSexuality
    }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.askDisabilityStatus }
    }
    return {
      body: "What is your perinatal status?",
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: g, value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logState
  async handlePerinatal(d: IStepData<State, string>): Promise<IStepResult> {
    this.setPeople({ perinatalStatus: d.response })
    d.state.perinatalStatus = d.response

    const result = await this.onHandlePerinatal?.(d.state)
    if (result) return result
    return { nextStep: this.askDisabilityStatus }
  }

  @step
  askDisabilityStatus(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have a disability?",
      prompt: {
        id: this.getPromptId("askDisabilityStatus"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askDisability"
      },
      nextStep: this.handleDisabilityStatus
    }
  }

  @step
  async handleDisabilityStatus(d: IStepData<State, boolean>): Promise<IStepResult> {
    this.setPeople({ disabilityStatus: d.response })
    d.state.disabilityStatus = d.response
    const result = await this.onHandleDisabilityStatus?.(d.state)
    if (result) return result

    return {
      nextStep: d.response //
        ? this.askDisability
        : this.askHasASD
    }
  }

  @step.logState
  async askDisability(d: IStepData<State>): Promise<IStepResult> {
    const disabilities = this.getDisabilities(d.state)
    if (!disabilities?.length) {
      this.logBreadcrumb("DISABILITIES NOT FOUND", d.state, { disabilities })
      this.logMessage("DISABILITIES NOT FOUND")
      const result = await this.onHandleDisability?.(d.state)
      if (result) return result
      return { nextStep: this.askHasASD }
    }

    if (d.state.disabilityStatus === false) {
      // prettier-ignore
      this.logBreadcrumb("DISABILITIES SKIPPED BECAUSE DISABILITY STATUS IS ALREADY SET TO FALSE", d.state, { disabilities })
      this.logMessage("DISABILITIES SKIPPED BECAUSE DISABILITY STATUS IS ALREADY SET TO FALSE")
      const result = await this.onHandleDisability?.(d.state)
      if (result) return result
      return { nextStep: this.askHasASD }
    }

    return {
      body:
        d.state.disabilityStatus == null //
          ? "Do you have a disability?"
          : "Okay, please specify",
      prompt: this.getDisabilityPrompt(d.state),
      nextStep: this.handleDisability
    }
  }

  @step.logState
  async handleDisability(d: IStepData<State, string | string[]>): Promise<IStepResult> {
    if (typeof d.response === "string") {
      this.setPeople({ disability: d.response })
      d.state.disability = d.response
    } else {
      // prettier-ignore
      this.setPeople({ disability: d.response.length === 1 ? d.response[0] : d.response.join(", ") })
      this.setPeople({ disabilities: d.response })
      d.state.disabilities = d.response
    }

    const result = await this.onHandleDisability?.(d.state)
    if (result) return result
    return { nextStep: this.askHasASD }
  }

  @step.logState
  askHasASD(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have a confirmed diagnosis of Autistic Spectrum Disorder (ASD)?",
      prompt: {
        id: this.getPromptId("askHasASD"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askHasASD"
      },
      nextStep: this.handleHasASD
    }
  }

  @step.logStateAndResponse
  async handleHasASD(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.hasASD = d.response
    this.referralStore.setCustomField<State>("hasASD", d.state.hasASD)

    const result = await this.onHandleHasASD?.(d.state)
    if (result) return result
    return { nextStep: this.askHasADHD }
  }

  @step.logState
  askHasADHD(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have a confirmed diagnosis of Attention Deficit Hyperactivity Disorder (ADHD)?",
      prompt: {
        id: this.getPromptId("askHasADHD"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askHasADHD"
      },
      nextStep: this.handleHasADHD
    }
  }

  @step.logStateAndResponse
  async handleHasADHD(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.hasADHD = d.response
    this.referralStore.setCustomField<State>("hasADHD", d.state.hasADHD)

    const result = await this.onHandleHasADHD?.(d.state)
    if (result) return result
    return { nextStep: this.askExArmedForces }
  }

  @step.logState
  async askPrimaryLanguage(d: IStepData<State>): Promise<IStepResult> {
    // TODO: find a way to also account for the spine language in here
    //       the problem would be that if the language that comes from the
    //       spine is not in the list of languages that we have, then we
    //       would have to ask the user to select a language from the list
    if (d.state.primaryLanguage) {
      const result = await this.onHandlePrimaryLanguage?.(d.state)
      if (result) return result
      return { nextStep: this.askPerinatal }
    }

    const languages = this.getLanguages(d.state)
    if (!languages?.length) {
      this.logBreadcrumb("LANGUAGES NOT FOUND", d.state, { languages })
      this.logMessage("LANGUAGES NOT FOUND")
      const result = await this.onHandlePrimaryLanguage?.(d.state)
      if (result) return result
      return { nextStep: this.askPerinatal }
    }

    return {
      body: "What is your primary spoken language?",
      prompt: {
        id: this.getPromptId("askPrimaryLanguage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: languages.map(g => ({ body: g, value: g })),
        dataPointsName: "askPrimaryLanguage"
      },
      nextStep: this.handlePrimaryLanguage
    }
  }

  @step.logState
  async handlePrimaryLanguage(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.primaryLanguage = d.response
    this.referralStore.setIsMainSpokenLanguageEnglish(d.response)
    const result = await this.onHandlePrimaryLanguage?.(d.state)
    if (result) return result
    return { nextStep: this.askPerinatal }
  }

  @step.logState
  async askExArmedForces(d: IStepData<State>): Promise<IStepResult> {
    const exArmedForcesOptions = this.getExArmedForcesValues(d.state)
    if (!exArmedForcesOptions?.length) {
      this.logBreadcrumb("EX ARMED FORCES OPTIONS NOT FOUND", d.state, { exArmedForcesOptions })
      this.logMessage("EX ARMED FORCES OPTIONS NOT FOUND")
      const result = await this.onHandleExArmedForces?.(d.state)
      if (result) return result
      return { nextStep: this.askSexuality }
    }
    return {
      body: "Have you ever served in the British Armed Forces?",
      prompt: {
        id: this.getPromptId("askExArmedForces"),
        trackResponse: true,
        type: "inlinePicker",
        choices: exArmedForcesOptions.map(g => ({ body: this.getChoiceBody(g), value: g })),
        dataPointsName: "askExArmedForces"
      },
      nextStep: this.handleExArmedForces
    }
  }

  @step
  async handleExArmedForces(d: IStepData<State, string>): Promise<IStepResult> {
    this.setPeople({ isExArmedForces: d.response })
    d.state.isExArmedForces = d.response
    const result = await this.onHandleExArmedForces?.(d.state)
    if (result) return result
    return { nextStep: this.askSexuality }
  }

  @step.logState
  askSexuality(d: IStepData<State>): IStepResult {
    const sexualities = this.getSexualities(d.state)
    if (!sexualities?.length) {
      this.logBreadcrumb("SEXUALITIES NOT FOUND", d.state, { sexualities })
      this.logMessage("SEXUALITIES NOT FOUND")
      return { nextStep: this.askLongTermMedicalCondition }
    }
    return {
      body: "How would you describe your sexuality?",
      prompt: {
        id: this.getPromptId("askSexuality"),
        trackResponse: true,
        type: "inlinePicker",
        choices: sexualities.map(g => ({ body: this.getChoiceBody(g), value: g })),
        dataPointsName: "askSexuality"
      },
      nextStep: this.handleSexuality
    }
  }

  @step.logStateAndResponse
  async handleSexuality(d: IStepData<State, string>): Promise<IStepResult> {
    const sexualities = this.getSexualities(d.state)
    const isValid = sexualities?.find(s => s === d.response)
    if (!isValid) {
      return {
        body: "Sorry I can't understand this. Let's try again",
        nextStep: this.askSexuality
      }
    }
    this.setPeople({ sexuality: d.response })
    d.state.sexuality = d.response
    const result = await this.onHandleSexuality?.(d.state)
    if (result) return result
    return { nextStep: this.askLongTermMedicalCondition }
  }

  @step.logState
  askLongTermMedicalCondition(d: IStepData<State>): IStepResult {
    const medicalConditions = this.getMedicalConditions(d.state)
    if (!medicalConditions?.length) {
      this.logBreadcrumb("MEDICAL CONDITIONS NOT FOUND", d.state, { medicalConditions })
      this.logMessage("MEDICAL CONDITIONS NOT FOUND")
      return { nextStep: this.goToCollectAlcoholConsumption }
    }
    return {
      body: "Do you have a long term medical condition?",
      prompt: {
        id: this.getPromptId("askLongTermMedicalCondition"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          { body: "I don't", value: "No", backgroundColor: "#EC9CC8", selectIndividually: true },
          ...medicalConditions.filter(m => m !== "No").map(m => ({ body: m, value: m }))
        ],
        dataPointsName: "askLongTermMedicalCondition"
      },
      nextStep: this.handleLongTermMedicalCondition
    }
  }

  @step.logStateAndResponse
  async handleLongTermMedicalCondition(
    d: IStepData<State, ["No"] | string[]>
  ): Promise<IStepResult> {
    const medicalConditions = this.getMedicalConditions(d.state)
    const isInvalid = d.response.find(r => r !== "No" && !medicalConditions?.includes(r))
    if (isInvalid) {
      return {
        body: "Sorry I can't recognise this medical condition. Let's try again",
        nextStep: this.askLongTermMedicalCondition
      }
    }

    d.state.longTermMedicalCondition = d.response
    this.setPeople({ ltc: d.response })
    const result = await this.onHandleLongTermMedicalCondition?.(d.state)
    if (result) return result

    return {
      nextStep:
        d.response[0] === "No" //
          ? this.goToCollectAlcoholConsumption
          : this.askDoesLTCAffectMood
    }
  }

  @step
  askDoesLTCAffectMood(d: IStepData<State>): IStepResult {
    return {
      body: `Does your ${joinWithOr(d.state.longTermMedicalCondition)} impact your mood?`,
      prompt: {
        id: this.getPromptId("askDoesLTCAffectMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askDoesLTCAffectMood"
      },
      nextStep: this.handleDoesLTCAffectMood
    }
  }

  @step
  async handleDoesLTCAffectMood(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.ltcAffectsMood = d.response
    this.setPeople({ ltcAffectsMood: d.response })
    const result = await this.onHandleDoesLTCAffectMood?.(d.state)
    if (result) return result

    return {
      nextStep: !d.response //
        ? this.goToCollectAlcoholConsumption
        : this.askHowMuchLTCAffectsMood
    }
  }

  @step
  askHowMuchLTCAffectsMood(_d: IStepData<State>): IStepResult {
    return {
      body: "How much does it impact your mood?",
      prompt: {
        id: this.getPromptId("askHowMuchLTCAffectsMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "A little", value: "little" },
          { body: "Somewhat", value: "somewhat" },
          { body: "Very much", value: "very" }
        ],
        dataPointsName: "askHowMuchLTCAffectsMood"
      },
      nextStep: this.handleHowMuchLTCAffectsMood
    }
  }

  @step
  async handleHowMuchLTCAffectsMood(
    d: IStepData<State, "little" | "somewhat" | "very">
  ): Promise<IStepResult> {
    d.state.ltcMoodImpact = d.response
    this.setPeople({ ltcMoodImpact: d.response })
    const result = await this.onHandleHowMuchLTCAffectsMood?.(d.state)
    if (result) return result

    return {
      nextStep:
        d.response === "little" //
          ? this.goToCollectAlcoholConsumption
          : this.askHowWellYouManageYourLTC
    }
  }

  @step
  askHowWellYouManageYourLTC(d: IStepData<State>): IStepResult {
    const condition = joinWithAnd(d.state.longTermMedicalCondition)
    return {
      body: `And how well do you think you manage your ${condition}?`,
      prompt: {
        id: this.getPromptId("askHowWellYouManageYourLTC"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Not very well", value: "little" },
          { body: "Fairly well", value: "fairly" },
          { body: "Very well", value: "very" }
        ],
        dataPointsName: "askHowWellYouManageYourLTC"
      },
      nextStep: this.handleHowWellYouManageYourLTC
    }
  }

  @step
  async handleHowWellYouManageYourLTC(
    d: IStepData<State, "little" | "fairly" | "very">
  ): Promise<IStepResult> {
    const ltcManagement =
      {
        little: "Not very well",
        fairly: "Fairly well",
        very: "Very well"
      }[d.response] || d.response
    d.state.ltcManagement = d.response
    this.setPeople({ ltcManagement })
    const result = await this.onHandleHowWellYouManageYourLTC?.(d.state)
    if (result) return result

    return { nextStep: this.goToCollectAlcoholConsumption }
  }

  @step.logState
  askHaveYouEverCaughtCovid(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you ever caught COVID-19?",
      prompt: {
        id: this.getPromptId("askHaveYouEverCaughtCovid"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes - confirmed by PCR or lateral flow test", value: "YES_CONFIRMED_TEST" },
          {
            body: "Yes - suspected I've had it but not confirmed by a test",
            value: "YES_SUSPECTED"
          },
          { body: "No", value: "NO" }
        ],
        dataPointsName: "askHaveYouEverCaughtCovid"
      },
      nextStep: this.handleHaveYouEverCaughtCovid
    }
  }

  @step.logStateAndResponse
  async handleHaveYouEverCaughtCovid(d: IStepData<State, CovidStatus>): Promise<IStepResult> {
    d.state.covidStatus = d.response
    const result = await this.onHandleHaveYouEverCaughtCovid?.(d.state)
    if (result) return result
    if (d.response === "NO") {
      return { nextStep: this.askDisabilityStatus }
    }
    return { nextStep: this.askWhenDidYouHaveCovid }
  }

  @step.logState
  askWhenDidYouHaveCovid(_d: IStepData<State>): IStepResult {
    return {
      body: "When did you have COVID-19?",
      prompt: {
        id: this.getPromptId("askWhenDidYouHaveCovid"),
        trackResponse: true,
        type: "date",
        isUndoAble: true
      },
      nextStep: this.handleWhenDidYouHaveCovid
    }
  }

  @step.logStateAndResponse
  async handleWhenDidYouHaveCovid(d: IStepData<State, number>): Promise<IStepResult> {
    try {
      const date = moment(d.response)
      invariant(date, "I'm sorry that's not a valid date. Please enter the date you had covid")
      invariant(
        date.isValid(),
        "I'm sorry that's not a valid date. Please enter the date you had covid"
      )
      invariant(
        date.isBefore(moment()),
        "Hmm… I don't think you can predict the date you will have Covid. Can you please make sure the date is correct?"
      )
      invariant(
        date.isAfter(moment("2019-10-31")),
        "Hmm… the first case of Covid was reported in November 2019. Can you please make sure the date is correct?"
      )
      d.state.covidDate = moment(date).format("YYYY-MM-DD")
    } catch (e) {
      this.logException(e, "handleWhenDidYouHaveCovid", Severity.Warning)
      return {
        body: e.message,
        nextStep: this.askWhenDidYouHaveCovid
      }
    }
    const result = await this.onHandleWhenDidYouHaveCovid?.(d.state)
    if (result) return result
    return { nextStep: this.askDisabilityStatus }
  }

  @step.logState
  goToCollectAlcoholConsumption(d: IStepData<State>): IStepResult {
    const CollectAlcoholConsumptionDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectAlcoholConsumption
    )

    const nextDialogue = CollectAlcoholConsumptionDialogue
      ? new CollectAlcoholConsumptionDialogue({ ...d.state })
      : undefined

    return {
      nextDialogue,
      nextStep: this.askSubstances
    }
  }

  @step.logState
  askSubstances(_d: IStepData<State>): IStepResult {
    return {
      body: "Okay. And over the past month, have you taken any non-prescribed medication or substances to help you manage your mood? ",
      prompt: {
        id: this.getPromptId("askSubstances"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askSubstances"
      },
      nextStep: this.handleSubstances
    }
  }

  @step.logStateAndResponse
  async handleSubstances(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substances = d.response
    this.setPeople({ substances: d.response })
    const result = await this.onHandleSubstances?.(d.state)
    if (result) return result

    return {
      nextStep: d.response //
        ? this.askSubstancesOrigin
        : this.askCurrentSupport
    }
  }

  @step.logState
  askSubstancesOrigin(_d: IStepData<State>): IStepResult {
    return {
      body: "Are these medications brought over the counter at a pharmacy or supermarket? (and include herbal remedies, probiotics and vitamins)",
      prompt: {
        id: this.getPromptId("askSubstancesOrigin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askSubstancesOrigin"
      },
      nextStep: this.handleSubstancesOrigin
    }
  }

  @step.logState
  async handleSubstancesOrigin(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substancesAreMedications = d.response
    this.setPeople({ substancesAreMedications: d.response })
    const result = await this.onHandleSubstancesOrigin?.(d.state)
    if (result) return result

    return {
      nextStep: d.state.substancesAreMedications
        ? this.askMedicationWithinDoseRange
        : this.askSubstancesInfo
    }
  }

  @step.logState
  askSubstancesInfo(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you please describe what your use is, and how often do you use it?",
      prompt: {
        id: this.getPromptId("askSubstancesInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleSubstancesInfoWithCrisis
    }
  }

  @step.logState
  returnToAskSubstancesInfo(_d: IStepData<State>): IStepResult {
    return {
      body: "So we were talking about the substances you're taking to help manage your mood",
      nextStep: this.askSubstancesInfo
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    ignoredCategories: [Category.Medication],
    getNextStep: (s: SelfReferralScript) => s.returnToAskSubstancesInfo
  })
  async handleSubstancesInfoWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.substancesInfo = d.response
    this.setPeople({ substancesInfo: d.response })
    const result = await this.onHandleSubstancesInfoWithCrisis?.(d.state)
    if (result) return result
    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askMedicationInfo(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you please describe what your use is, and how often do you use it?",
      prompt: {
        id: this.getPromptId("askMedicationInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true,
        dataPointsName: "askMedicationInfo"
      },
      nextStep: this.handleMedicationInfoWithCrisis
    }
  }

  @step.logState
  returnToAskMedicationInfo(_d: IStepData<State>): IStepResult {
    return {
      body: "So we were talking about the medication you're taking to help manage your mood",
      nextStep: this.askSubstancesInfo
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    ignoredCategories: [Category.Medication],
    getNextStep: (s: SelfReferralScript) => s.returnToAskMedicationInfo
  })
  async handleMedicationInfoWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.medicationInfo = d.response
    this.setPeople({ medicationInfo: d.response })
    const result = await this.onHandleMedicationInfoWithCrisis?.(d.state)
    if (result) return result
    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askMedicationWithinDoseRange(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you using the medication within the recommended dose range on the packet?",
      prompt: {
        id: this.getPromptId("askMedicationDoseRange"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: "Yes" },
          { body: "No", value: "No" },
          { body: "Not sure", value: "Not sure" }
        ],
        dataPointsName: "askMedicationDoseRange"
      },
      nextStep: this.handleMedicationWithinDoseRange
    }
  }

  @step.logState
  async handleMedicationWithinDoseRange(
    d: IStepData<State, "Yes" | "No" | "Not sure">
  ): Promise<IStepResult> {
    d.state.medicationWithinDoseRange = d.response
    this.setPeople({ medicationWithinDoseRange: d.response })
    const result = await this.onHandleMedicationWithinDoseRange?.(d.state)
    if (result) return result

    return { nextStep: this.askCurrentSupport }
  }

  @step.logState
  askCurrentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently receiving mental health support from somewhere else?",
      prompt: {
        id: this.getPromptId("askCurrentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCurrentSupport"
      },
      nextStep: this.handleCurrentSupport
    }
  }

  @step.logStateAndResponse
  async handleCurrentSupport(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.hasCurrentSupport = d.response
    this.setPeople({ hasCurrentSupport: d.response })
    const result = await this.onHandleCurrentSupport?.(d.state)
    if (result) return result

    return { nextStep: this.askMainIssue }
  }

  @step.logState
  askMainIssue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, What's the main issue that has brought you here today?`,
        "(Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life)",
        "Please note this information will be stored on your personal record to support your assessment"
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssueWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralScript) => s.askWhatIsYourGoal
  })
  handleMainIssueWithCrisis(d: IStepData<State, string>): IStepResult {
    d.state.mainIssue = d.response
    this.referralStore.setCustomField<State>("mainIssue", d.response)
    return {
      body: "Thank you for sharing this. Your response will help us direct you to the most appropriate care",
      nextStep: this.askWhatIsYourGoal
    }
  }

  @step.logState
  askWhatIsYourGoal(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "By seeking support what are you hoping will change for you?",
        "For example, being able to engage in activities you are not currently able to do, or get back to doing things you enjoy"
      ],
      prompt: {
        id: this.getPromptId("askWhatIsYourGoal"),
        type: "text",
        dataPointsName: "askWhatIsYourGoal",
        cancelIsEmptySubmit: true,
        isUndoAble: false
      },
      nextStep: this.handleWhatIsYourGoalWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.therapyGoal = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: async (s: SelfReferralScript, state: State) => {
      return (await s.onHandleWhatIsYourGoalWithCrisis?.(state))?.nextStep ?? s.doReferralSubmission
    }
  })
  async handleWhatIsYourGoalWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    const result = await this.onHandleWhatIsYourGoalWithCrisis?.(d.state)
    if (result) return result
    return { nextStep: this.doReferralSubmission }
  }

  @step.logState
  async doReferralSubmission(d: IStepData<State>): Promise<IStepResult> {
    const referralSubmitted = await this.onSubmitReferralData(d.state)
    if (!referralSubmitted) {
      d.state.referralSubmitted = false
      d.state.referralSubmissionFailed = true
      this.setPeople({ referralSubmissionFailed: true })
      return { nextStep: this.sayReferralFailed }
    }
    const age = this.getUserAge(d.state)
    await this.rootStore.dataPointsStore.sendDataPoints(age)
    d.state.referralSubmitted = true
    d.state.referralSubmissionFailed = false
    this.setPeople({ referralSubmitted: true })
    await this.onReferralFinished?.(d.state)
    if (this.clinicalStore.isRisk) await this.onRiskReferralFinished(d.state)
    return { nextStep: this.sayReferralSucceeded }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralScriptStateSchema
  }

  getEthnicities(state: State): string[] {
    return []
  }

  getNationalities(state: State): string[] {
    return []
  }

  getReligions(state: State): string[] {
    return []
  }

  getGenders(state: State): string[] {
    return []
  }

  getGenderSameAsBirthValues(_state: State): string[] {
    return []
  }

  getPerinatalStatuses(state: State): string[] {
    return []
  }

  getLanguages(state: State): string[] {
    return []
  }

  getDisabilities(state: State): string[] {
    return []
  }

  getDisabilityPrompt(
    state: State
  ): IInlinePickerSingleSelectPrompt | IInlinePickerMultiSelectPrompt | undefined {
    const disabilities = this.getDisabilities(state)
    if (disabilities.length) {
      return {
        id: this.getPromptId("askDisability"),
        trackResponse: true,
        type: "inlinePicker",
        choices: disabilities.map(g => ({ body: g, value: g })),
        dataPointsName: "askDisability"
      }
    }
  }

  getExArmedForcesValues(state: State): string[] {
    return []
  }

  getSexualities(state: State): string[] {
    return []
  }

  getMedicalConditions(state: State): string[] {
    return []
  }

  getAlcoholRisk(state: State): boolean {
    switch (state.alcoholQuantity) {
      case ALCOHOL_QUANTITIES._3_4:
        return state.alcoholFrequency === ALCOHOL_FREQUENCIES.WEEKLY_4
      case ALCOHOL_QUANTITIES._5_6:
        return [
          ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3, //
          ALCOHOL_FREQUENCIES.WEEKLY_4
        ].includes(state.alcoholFrequency!)
      case ALCOHOL_QUANTITIES._7_9:
        return [
          ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4,
          ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3,
          ALCOHOL_FREQUENCIES.WEEKLY_4
        ].includes(state.alcoholFrequency!)
      case ALCOHOL_QUANTITIES._10_PLUS:
        return true
      case ALCOHOL_QUANTITIES._0_2:
      default:
        return false
    }
  }

  getChoiceBody(text: string): string {
    return (
      {
        "Not stated (Person asked but declined to provide a response)": "Prefer not to say",
        "Person asked and does not know or is not sure": "Not sure",
        "Unknown (Person asked and does not know or isn't sure)": "Not sure",
        "Patient Religion Unknown": "Not sure",
        "(None)": "None"
      }[text] || text
    )
  }

  async onSubmitReferralData(state: State): Promise<boolean> {
    try {
      const payload = await this.getReferralPayload(state)
      await this.referralStore.createReferral(payload)
      this.referralStore.setShouldHavePatientIdAndInstanceId(true)
      state.patientId = this.referralStore.patientId
      state.signupCode = this.referralStore.signupCode
      this.track(TrackingEvents.SELF_REFERRAL_SUBMITTED)
    } catch (e) {
      this.referralStore.setShouldHavePatientIdAndInstanceId(false)
      this.logException(e, "onSubmitReferralData")
      return false
    }
    return true
  }
}
