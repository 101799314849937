import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import dialoguesRegistry from "../../dialoguesRegistry"

type State = BaseScriptState

export class SelfReferralGMHubScript extends BaseScript<State> {
  readonly name: string = "SelfReferralGMHubScript"

  /** Script Steps */
  @step.logState
  start(_d: IStepData<State>): IStepResult<State> {
    const userType = this.wellbeingHubStore.userType
    if (userType === "managerTeam") {
      return { nextStep: this.goToSelfReferralManagerTeam }
    }
    if (userType === "managerIndividual") {
      return { nextStep: this.goToSelfReferralManagerIndividual }
    }
    return { nextStep: this.goToSelfReferralIndividual }
  }

  @step.logState
  goToSelfReferralManagerTeam(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const SelfReferralGMHubManagerTeamDialogue = dialoguesRegistry.get(DialogueIDs.SelfReferralGMHubManagerTeam)
    return {
      nextDialogue: new SelfReferralGMHubManagerTeamDialogue({ ...d.state }),
      nextStep: this.end
    }
  }

  @step.logState
  goToSelfReferralManagerIndividual(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const SelfReferralGMHubManagerIndividualDialogue = dialoguesRegistry.get(DialogueIDs.SelfReferralGMHubManagerIndividual)
    return {
      nextDialogue: new SelfReferralGMHubManagerIndividualDialogue({ ...d.state }),
      nextStep: this.end
    }
  }

  @step.logState
  goToSelfReferralIndividual(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const SelfReferralGMHubIndividualDialogue = dialoguesRegistry.get(DialogueIDs.SelfReferralGMHubIndividual)
    return {
      nextDialogue: new SelfReferralGMHubIndividualDialogue({ ...d.state }),
      nextStep: this.end
    }
  }

  /** Generic Handlers */
  async getReferralPayload(_state: State): Promise<Record<string, string>> {
    return {}
  }
}

export default class SelfReferralGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralGMHub
  readonly name: string = "SelfReferralGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralGMHubDialogue.id, new SelfReferralGMHubScript(), state, snapshot)
  }
}
