import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { PermissionsScriptState } from "./PermissionsScript"
import { PermissionsScript } from "./PermissionsScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = PermissionsScriptState
export type PermissionsCCGScriptState = State

export class PermissionsCCGScript extends PermissionsScript {
  readonly name: string = "PermissionsCCGScript"

  /** Script Steps */

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "I also know that talking about mental health can be difficult",
        `So ${name}, before I start finding out more about you, I'm going to run you through how we keep your data safe 👇`
      ],
      nextStep: this.showTOSLinks
    }
  }

  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""
    return {
      body: [
        `To get you the best referral, I will need to share your answers with ${organisationName}`,
        "I want to reassure you that your details will be stored safely on our Clinical recording system and kept confidential",
        "You can find all the details here:\n\n" +
          "Limbic [Terms of Service](https://static.limbic.ai/access-tos)\n" +
          "Limbic [Privacy Policy](https://static.limbic.ai/pp-access)\n" +
          organisationTerms
      ],
      nextStep: this.promptIUnderstandTerms
    }
  }

  // Skip consent for research for CCG since we don't save it in the database
  // (for now at least)
  @step.logState
  async handleIUnderstandTerms(d: IStepData<State>): Promise<IStepResult> {
    d.state.agreeTerms = d.response.agreeTerms
    d.state.agreeDetailsShared = d.response.agreeDetailsShared
    return {
      nextStep: this.sayReady
    }
  }
}

/** istanbul ignore next */
export default class PermissionsCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsCCG
  readonly name: string = "PermissionsCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsCCGDialogue.id, new PermissionsCCGScript(), state, snapshot)
  }
}
