import { z } from "zod"

export default interface IName {
  firstName: string
  middleNames?: string
  lastName: string
}

export const INameSchema = z.object({
  firstName: z.string().min(1),
  middleNames: z.string().optional(),
  lastName: z.string().min(1)
})
