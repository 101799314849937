import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IAPTIDs } from "../../../models/IIAPTService"
import { TrackingEvents } from "../../../models/Constants"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"
import { exArmedForces } from "../../../config/referralForms/mental-health-matters"

interface State extends EligibilityCheckWithPDSScriptState {
  armedForces?: boolean
  currentTherapy?: boolean
  currentTherapyDetails?: string
  previousTherapy?: boolean
  previousTherapyDetails?: string
  nhsStaff?: boolean
  requiresUrgentSupport?: boolean
}

export type EligibilityCheckMHMScriptState = State
export class EligibilityCheckMHMScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckMHMScript"

  /** Script Steps */

  /** Script Steps */
  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askRequiresUrgentSupport }
  }

  @step.logState
  askRequiresUrgentSupport(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Thanks ${name}. Before we continue, we need to check if you require urgent support`,
        "Do you need immediate help for a mental health crisis or emergency?"
      ],
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askRequiresUrgentSupport"
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.requiresUrgentSupport = d.response
  })
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.askPostCodeOfUser
    }
  }

  @step.logState
  checkUnderAgedForIAPT(d: IStepData<State>): IStepResult {
    const isUnderAged = this.getIsUnderagedForIAPT(d.state, d.state.iapt)
    this.setUnderAged(d.state, isUnderAged)
    if (d.state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND) {
      return {
        body: [
          "Before we get started we need to check you're in the right place to access support"
        ],
        nextStep: this.askArmedForces
      }
    }

    return {
      body: ["Before we get started we need to check you're in the right place to access support"],
      nextStep: this.askCurrentTherapy
    }
  }

  @step.logState
  askArmedForces(_d: IStepData<State>): IStepResult {
    return {
      body: ["Are you currently serving in the British Armed Forces?"],
      prompt: {
        id: this.getPromptId("askArmedForces"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askArmedForces"
      },
      nextStep: this.handleArmedForces
    }
  }

  @step.logState
  async handleArmedForces(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.armedForces = d.response
    this.setPeople({ armedForces: d.response })

    if (d.state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND && d.response) {
      return {
        nextStep: this.goToSignpostArmedForces
      }
    }

    return {
      nextStep: this.askCurrentTherapy
    }
  }

  @step.logState
  goToSignpostArmedForces(d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.ARMED_FORCES_SIGNPOST)
    const name = this.getName(d.state)

    return {
      body: [
        `So ${name}, as you’re currently serving in the Armed Forces, we advise that you please speak to your Chain of Command or any Medical Officer`,
        "They can talk to you about the options available to you",
        "You can also call Combat Stress via their 24 hour helpline: 0800 138 1619"
      ],
      prompt: {
        id: this.getPromptId("goToSignpostArmedForces"),
        type: "inlinePicker",
        choices: [{ body: "Understood" }, { body: "Okay" }],
        isUndoAble: true
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  askCurrentTherapy(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Are you currently receiving any kind of talking therapy or are you on the waiting list for another mental health service?"
      ],
      prompt: {
        id: this.getPromptId("askCurrentTherapy"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Currently receiving a talking therapy", value: true },
          { body: "Currently on the waiting list for another mental health service", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCurrentTherapy"
      },
      nextStep: this.handleCurrentTherapy
    }
  }

  @step.logState
  async handleCurrentTherapy(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.currentTherapy = d.response
    this.setPeople({ currentTherapy: d.response })

    return {
      nextStep: d.response ? this.askCurrentTherapyDetails : this.askPreviousTherapy
    }
  }

  @step.logState
  askCurrentTherapyDetails(_d: IStepData<State>): IStepResult {
    return {
      body: `Please give details on what kind of therapy you are receiving or what service you are accessing`,
      prompt: {
        id: this.getPromptId("askCurrentTherapyDetails"),
        trackResponse: true,
        type: "text",
        dataPointsName: "askCurrentTherapyDetails"
      },
      nextStep: this.handleCurrentTherapyDetailsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: EligibilityCheckMHMScript) => {
    d.state.currentTherapyDetails = d.response
    script.referralStore.setCustomField<State>("currentTherapyDetails", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: EligibilityCheckMHMScript) => s.askPreviousTherapy
  })
  handleCurrentTherapyDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askPreviousTherapy
    }
  }

  @step.logState
  askPreviousTherapy(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Have you ever had appointments with Secondary Care mental health services?",
        "(i.e. The Recovery Team, The Home Treatment Team, The Crisis Team, The Assessment Team (Wakefield House), Learning disability services, Dementia Services etc)"
      ],
      prompt: {
        id: this.getPromptId("askTherapyFollowUp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askTherapyFollowUp"
      },
      nextStep: this.handlePreviousTherapy
    }
  }

  @step.logState
  async handlePreviousTherapy(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.previousTherapy = d.response
    this.setPeople({ previousTherapy: d.response })
    return {
      nextStep: d.response ? this.askPreviousTherapyDetails : this.askExArmedForces
    }
  }

  @step.logState
  askPreviousTherapyDetails(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Please give details about what kind of therapy you received or what service you accessed"
      ],
      prompt: {
        id: this.getPromptId("askTherapyDetails"),
        type: "text",
        forceValue: false,
        dataPointsName: "askTherapyDetails"
      },
      nextStep: this.handlePreviousTherapyDetailsWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: EligibilityCheckMHMScript) => {
    d.state.previousTherapyDetails = d.response
    script.referralStore.setCustomField<State>("previousTherapyDetails", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: EligibilityCheckMHMScript) => s.askExArmedForces
  })
  handlePreviousTherapyDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askExArmedForces
    }
  }

  @step.logState
  askExArmedForces(d: IStepData<State>): IStepResult {
    const exArmedForcesOptions = this.getExArmedForcesValues(d.state)
    if (!exArmedForcesOptions?.length) {
      this.logBreadcrumb("EX ARMED FORCES OPTIONS NOT FOUND", d.state, { exArmedForcesOptions })
      this.logMessage("EX ARMED FORCES OPTIONS NOT FOUND")

      if (d.state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND) {
        return { nextStep: this.askNHSStaff }
      }

      return { nextStep: this.checkEligibility }
    }
    return {
      body: "Have you ever served in the British Armed Forces?",
      prompt: {
        id: this.getPromptId("askExArmedForces"),
        trackResponse: true,
        type: "inlinePicker",
        choices: exArmedForcesOptions.map(g => ({ body: g, value: g })),
        dataPointsName: "askExArmedForces"
      },
      nextStep: this.handleExArmedForces
    }
  }

  @step
  async handleExArmedForces(d: IStepData<State, string>): Promise<IStepResult> {
    this.setPeople({ isExArmedForces: d.response })
    d.state.isExArmedForces = d.response
    if (d.state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND) {
      return { nextStep: this.askNHSStaff }
    }

    return { nextStep: this.checkEligibility }
  }

  @step.logState
  askNHSStaff(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you a NHS member of staff?",
      prompt: {
        id: this.getPromptId("askNHSStaff"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askNHSStaff"
      },
      nextStep: this.handleNHSStaff
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckMHMScript) => {
    d.state.nhsStaff = d.response
    script.referralStore.setCustomField<State>("nhsStaff", d.response)
  })
  handleNHSStaff(_d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: this.checkEligibility
    }
  }

  @step.logState
  sayItsImportantToFindGP(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "It's important that we identify your GP in order to find the right mental health service for you"
      ],
      nextStep: this.sayWithoutGPICannotReferYou
    }
  }

  @step.logState
  sayWeDontSupportYourGP(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: `Unfortunately, ${iaptName} doesn't support your GP`,
      nextStep: this.closeWithCallIntoService
    }
  }

  @step.logState
  sayYoureEligible(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: d.state.iaptManuallySelected
        ? [`Thanks ${name}`]
        : [`Thanks ${name}. Good news - you're eligible for free support from ${iaptName}`],
      nextStep: this.end
    }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const crisisPhoneNumbers = this.rootStore.configStore.crisisPhoneNumbers
    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        `However ${organisationName} is not an emergency response service`,
        "To get more urgent help, please call NHS 111 and select Option 2",
        "If you need urgent, life threatening medical help please call 999",
        "Alternatively, you can also call Samaritans on 116 123",
        "You could also give any one of our services a call 24/7 on the following phone numbers:\n" +
          crisisPhoneNumbers,
        `You can refer yourself back to ${organisationName} when you are no longer in crisis`
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 option 2 and Samaritans (116 123)"
    )
    return { nextStep: this.goToGoodbye }
  }

  /** Generic Handlers */
  async onHandleBirthday(_state: State): Promise<IStepResult<State> | void> {
    return { nextStep: this.checkAgeThresholds }
  }

  getExArmedForcesValues(_state: State): string[] {
    return Object.keys(exArmedForces)
  }

  getNeedsToSelfReferManually(state: State): boolean {
    return false
  }
}

/* istanbul ignore next */
export default class EligibilityCheckMHMDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckMHM
  readonly name: string = "EligibilityCheckMHMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckMHMDialogue.id, new EligibilityCheckMHMScript(), state, snapshot)
  }
}
