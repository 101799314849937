import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import sendRiskEmail from "../../../backend/api/sendRiskEmail"

type State = CrisisScriptState
export type CrisisDemoPrivateProvidersState = State
export class CrisisDemoPrivateProvidersScript extends CrisisScript {
  readonly name: string = "CrisisDemoPrivateProvidersScript"

  /** Script Steps */

  @step
  async sayInstructions(d: IStepData<State>): Promise<IStepResult> {
    await this.sendRiskEmailToService(d.state)
    return {
      body: [
        "In the meantime, if you feel that you or someone you know is in danger, please call 999 immediately",
        "Further support is also provided by the Samaritans, available anytime by calling: 116 123"
      ],
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "I understand" }]
      },
      nextStep: this.handleSayInstructions
    }
  }

  /** Generic Handlers */

  async sendRiskEmailToService(state: State): Promise<void> {
    try {
      if (!this.clinicalStore.isRisk) return
      const to =
        new URLSearchParams().get("submitRiskTo") ??
        "john+risk@limbic.ai, ross+risk@limbic.ai, syed+risk@limbic.ai"
      const cc = new URLSearchParams().get("submitRiskToCC")
      const bcc = new URLSearchParams().get("submitRiskToBCC")
      const text = this.createReferralEmail(state, true)
      await sendRiskEmail({
        to: [to],
        cc: cc?.length ? [cc] : undefined, // This needs to be undefined in case of empty array because mailgun is stupid
        bcc: bcc?.length ? [bcc] : undefined, // This needs to be undefined in case of empty array because mailgun is stupid
        text
      })
    } catch (e) {
      this.logException(e, "onReferralFinished -> sendEmail")
    }
  }
}

/* istanbul ignore next */
export default class CrisisDemoPrivateProvidersDialogue extends AdHocDialogue<
  State,
  CrisisDemoPrivateProvidersScript
> {
  static id = DialogueIDs.CrisisDemoPrivateProviders
  readonly name: string = "CrisisDemoPrivateProvidersDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CrisisDemoPrivateProvidersDialogue.id,
      new CrisisDemoPrivateProvidersScript(),
      state,
      snapshot
    )
  }
}
