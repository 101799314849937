import {
  CollectPhoneNumberScript,
  CollectPhoneNumberScriptState
} from "./CollectPhoneNumberDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { IAPTIDs } from "../../../../models/IIAPTService"

interface State extends CollectPhoneNumberScriptState {
  preferredPhoneType?: string
}

export type CollectPhoneNumberMHMScriptState = State

export class CollectPhoneNumberMHMScript extends CollectPhoneNumberScript {
  readonly name: string = "CollectPhoneNumberMHMScript"

  /** Script Steps */

  @step.logState
  askPreferredPhoneType(_d: IStepData<State>): IStepResult {
    return {
      body: "And what type of phone number is this?",
      prompt: {
        id: this.getPromptId("askPreferredPhoneType"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Mobile phone", value: "Mobile" },
          { body: "Work phone", value: "Work" },
          { body: "Home phone", value: "Home" }
        ],
        dataPointsName: "askPreferredPhoneType"
      },
      nextStep: this.handlePreferredPhoneType
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (d: IStepData<State, "Mobile" | "Work" | "Home">, script: CollectPhoneNumberMHMScript) => {
      d.state.preferredPhoneType = d.response
      script.referralStore.setCustomField<State>("preferredPhoneType", d.response)
    }
  )
  handlePreferredPhoneType(_d: IStepData<State, "Mobile" | "Work" | "Home">): IStepResult {
    return { nextStep: this.askCanIContactYouOnPhoneNumber }
  }

  /** Generic Handlers */

  async onHandlePhoneNumber(state: State): Promise<IStepResult<State>> {
    if (state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON) {
      return {
        nextStep: this.askPreferredPhoneType
      }
    }
    return {
      nextStep: this.askCanIContactYouOnPhoneNumber
    }
  }
}

export default class CollectPhoneNumberMHMDialogue extends AdHocDialogue<
  State,
  CollectPhoneNumberMHMScript
> {
  static id = DialogueIDs.CollectPhoneNumberMHM
  readonly name: string = "CollectPhoneNumberMHMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CollectPhoneNumberMHMDialogue.id, new CollectPhoneNumberMHMScript(), state, snapshot)
  }
}
