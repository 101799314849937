import { z, ZodSchema } from "zod"
import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptStateSchema } from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import isEmail from "validator/lib/isEmail"
import isValidPhoneNumber from "../../../utils/isvalidPhoneNumber"
import { TrackingEvents } from "../../../models/Constants"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import sendEmail from "../../../backend/api/sendEmail"
import { SendEmailStatus } from "../../../models/ISendEmail"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends SelfReferralIAPTScriptState {
  role?: string
  organisation?: string
  nameOfIndividualBeingReferred?: string
  consentFromIndividual?: boolean
  phoneNumberOfIndividual?: string
  emailOfIndividual?: string
  mainIssue?: string
  individualAtRisk?: boolean
}

export type SelfReferralWellbeingHubManagerIndividualScriptState = State

export const SelfReferralWellbeingHubManagerIndividualScriptStateSchema =
  SelfReferralIAPTScriptStateSchema.extend({
    role: z.string().optional(),
    organisation: z.string().optional(),
    nameOfIndividualBeingReferred: z.string().optional(),
    consentFromIndividual: z.boolean().optional(),
    phoneNumberOfIndividual: z.string().optional(),
    emailOfIndividual: z.string().optional(),
    mainIssue: z.string().optional(),
    individualAtRisk: z.boolean().optional()
  })

export class SelfReferralWellbeingHubManagerIndividualScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralWellbeingHubManagerIndividualScript"

  /** Script Steps */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0, postcodeLookupCounter: 0 })
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.askWannaDoSelfReferral }
  }

  @step.logState
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    return {
      body: ["What's your full name? ✏️", "Feel free to use initials or an alias if preferred"],
      nextStep: this.showPromptForFullName
    }
  }

  @step
  sayPleaseGiveFullName(_d: IStepData<State>): IStepResult {
    return {
      body: ["Please enter your name", "Feel free to use initials or an alias if preferred"],
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  showPromptForFullName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForFullName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleFullNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerIndividualScript) => {
      const username = d.response?.trim()
      d.state.username = username
      script.rootStore.applicationStore.setUsername(username)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerIndividualScript) => s.sayPleaseGiveFullName
  })
  async handleFullNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkFullName }
  }

  @step.logState
  async checkFullName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.username || d.state.username.trim() === "") {
      return { nextStep: this.sayPleaseGiveFullName }
    }
    return { nextStep: this.askRole }
  }

  @step.logState
  @step.logState
  askRole(d: IStepData<State>): IStepResult {
    const roles = this.getRoles(d.state)
    if (!roles?.length) {
      this.logBreadcrumb("ROLES NOT FOUND", d.state, { roles })
      this.logMessage("ROLES NOT FOUND")
      return { nextStep: this.askOrganisation }
    }

    const name = this.getName(d.state)
    return {
      body: `Thanks ${name}. Which of these job categories best describes your role? This is to help us support you, all staff will be accepted`,
      prompt: {
        id: this.getPromptId("askRole"),
        type: "inlinePicker",
        choices: roles.map(role => ({ body: role, value: role })),
        textPrompt: {
          forceValue: false,
          placeholder: "Other (please describe)"
        },
        isUndoAble: true
      },
      nextStep: this.handleRole
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerIndividualScript) => {
      d.state.role = d.response
      script.referralStore.setCustomField<State>("role", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerIndividualScript) => s.askOrganisation
  })
  handleRole(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askOrganisation }
  }

  @step.logState
  askOrganisation(d: IStepData<State>): IStepResult {
    const organisations = this.getOrganisations(d.state)
    if (!organisations?.length) {
      this.logBreadcrumb("ORGANISATIONS NOT FOUND", d.state, { organisations })
      this.logMessage("ORGANISATIONS NOT FOUND")
      return { nextStep: this.askEmail }
    }

    return {
      body: "And what is the organisation you work for?",
      prompt: {
        id: this.getPromptId("askOrganisation"),
        type: "inlinePicker",
        choices: organisations.map(organisation => ({ body: organisation, value: organisation })),
        isUndoAble: true
      },
      nextStep: this.handleOrganisation
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerIndividualScript) => {
      d.state.organisation = d.response
      script.referralStore.setCustomField<State>("organisation", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerIndividualScript) => s.askEmail
  })
  handleOrganisation(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEmail }
  }

  @step.logState
  askEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "What is your email address?",
      prompt: { id: this.getPromptId("askEmail"), type: "email" },
      nextStep: this.handleEmail
    }
  }

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.goToCollectPhoneNumber(d)
    return { ...result, nextStep: this.askReferralOrConsultation }
  }

  @step.logState
  askReferralOrConsultation(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you referring a member of staff or would you like a consultation on a Wellbeing issue?",
      prompt: {
        id: this.getPromptId("askReferralOrConsultation"),
        type: "inlinePicker",
        choices: [
          { body: "Referral", value: true },
          { body: "Consultation", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleReferralOrConsultation
    }
  }

  @step.logStateAndResponse
  handleReferralOrConsultation(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askNameOfIndividual }
    }
    return { nextStep: this.goToManualReferral }
  }

  @step.logState
  goToManualReferral(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "In this case, please submit a form directly by sending us an email",
        "If you still wish to refer someone else, just continue typing to wake me up when you're ready"
      ],
      prompt: {
        id: this.getPromptId("goToManualReferral"),
        type: "text",
        trackResponse: true,
        placeholder: 'Type "Hello Limbic" to wake me up',
        isUndoAble: true,
        disableCrisis: true
      },
      nextStep: this.askReferralOrConsultation
    }
  }

  @step.logState
  askNameOfIndividual(_d: IStepData<State>): IStepResult {
    return {
      body: "What is the name of the individual/employee you are seeking to refer or get support for from the Hub?",
      prompt: {
        id: this.getPromptId("askNameOfIndividual"),
        type: "text",
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleNameOfIndividualWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerIndividualScript) => {
      d.state.nameOfIndividualBeingReferred = d.response
      script.referralStore.setCustomField<State>("nameOfIndividualBeingReferred", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerIndividualScript) => s.askConsentFromIndividual
  })
  handleNameOfIndividualWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askConsentFromIndividual }
  }

  @step.logState
  askConsentFromIndividual(d: IStepData<State>): IStepResult {
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: `Do you have consent from ${nameOfIndividualBeingReferred} to share this information with ${organisationName}?`,
      prompt: {
        id: this.getPromptId("askConsentFromIndividual"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleConsentFromIndividual
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralWellbeingHubManagerIndividualScript) => {
      d.state.consentFromIndividual = d.response
      script.referralStore.setCustomField<State>("consentFromIndividual", d.response)
    }
  )
  handleConsentFromIndividual(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askEmailOfIndividual }
    }
    return { nextStep: this.sayCannotReferIndividual }
  }

  @step.logState
  askEmailOfIndividual(d: IStepData<State>): IStepResult {
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    return {
      body: `Can you provide us with ${nameOfIndividualBeingReferred}'s email address?`,
      prompt: {
        id: this.getPromptId("askEmailOfIndividual"),
        placeholder: `Enter ${nameOfIndividualBeingReferred}'s email`,
        type: "email"
      },
      nextStep: this.handleEmailOfIndividual
    }
  }

  @step.logStateAndResponse
  async handleEmailOfIndividual(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isEmail(d.response)
    if (!isValid) {
      return {
        body: "Sorry this is not a valid email address. Let's try again",
        nextStep: this.askEmailOfIndividual
      }
    }
    d.state.emailOfIndividual = d.response

    return { nextStep: this.askPhoneNumberOfIndividual }
  }

  @step.logState
  askPhoneNumberOfIndividual(d: IStepData<State>): IStepResult {
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    return {
      body: "And what is the best number to contact them on?",
      prompt: {
        id: this.getPromptId("askPhoneNumberOfIndividual"),
        placeholder: `Enter ${nameOfIndividualBeingReferred}'s phone number`,
        type: "phoneNumber",
        cancelLabel: "skip",
        cancelIsEmptySubmit: true,
        skipValue: "Not provided"
      },
      nextStep: this.handlePhoneNumberOfIndividual
    }
  }

  @step
  returnToAskPhoneNumberOfIndividual(_d: IStepData<State>): IStepResult {
    return {
      body: "So...",
      nextStep: this.askPhoneNumberOfIndividual
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralWellbeingHubManagerIndividualScript) =>
      s.returnToAskPhoneNumberOfIndividual
  })
  async handlePhoneNumberOfIndividual(d: IStepData<State, string>): Promise<IStepResult> {
    if (d.response !== "Not provided") {
      const isValid = isValidPhoneNumber(d.response)
      if (!isValid) {
        this.track(TrackingEvents.INVALID_PHONE_NUMBER)
        return {
          body: "Sorry this is not a valid phone number. Let's try again",
          nextStep: this.askPhoneNumberOfIndividual
        }
      }
    }
    d.state.phoneNumberOfIndividual = d.response

    return {
      body: "Thanks",
      nextStep: this.askToDescribeIssue
    }
  }

  @step.logState
  askToDescribeIssue(_d: IStepData<State>): IStepResult {
    return {
      body: "And finally, could you briefly describe the situation or issue that is the reason for this referral?",
      prompt: {
        id: this.getPromptId("askToDescribeIssue"),
        type: "text",
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleDescribeIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerIndividualScript) => {
      d.state.mainIssue = d.response
      script.referralStore.setCustomField<State>("mainIssue", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerIndividualScript) => s.askIsIndividualAtRisk
  })
  handleDescribeIssueWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askIsIndividualAtRisk }
  }

  @step.logState
  askIsIndividualAtRisk(d: IStepData<State>): IStepResult {
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    return {
      body: `In your opinion, is ${nameOfIndividualBeingReferred} at risk, in crisis or unable to keep themself safe?`,
      prompt: {
        id: this.getPromptId("askIsIndividualAtRisk"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleIsIndividualAtRisk
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralWellbeingHubManagerIndividualScript) => {
      d.state.individualAtRisk = d.response
      script.referralStore.setCustomField<State>("individualAtRisk", d.response)
    }
  )
  handleIsIndividualAtRisk(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.sayRiskSignposting }
    }
    // TODO: When we have the backend setup we also need to create a referral for ManagerIndividual
    // i.e. nextStep: this.onReferralFinished - and send email there
    return { nextStep: this.submitReferralEmail }
  }

  sayRiskSignposting(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Thanks ${name}`,
        `${organisationName} will follow up with ${nameOfIndividualBeingReferred}, however it is not a crisis service`,
        "There is some additional information you can share with them if they are in crisis:",
        "For urgent mental health support and advice:",
        "Call NHS 111 for physical or mental health emergencies (available 24/7)",
        "Samaritans: call 116 123 (available 24/7)",
        "SHOUT85258: text 'SHOUT' to 85258 (available 24/7)",
        "Always dial 999 in an emergency or, to contact the Police in a non - emergency, use 101"
      ],
      prompt: {
        id: this.getPromptId("sayRiskSignposting"),
        type: "inlinePicker",
        choices: [
          { body: "Ok", value: false },
          { body: "I understand", value: false }
        ],
        isUndoAble: true
      },
      // TODO: When we have the backend setup we also need to create a referral for ManagerIndividual
      // i.e. nextStep: this.onReferralFinished - and send email there
      nextStep: this.submitReferralEmail
    }
  }

  sayCannotReferIndividual(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `I'm sorry ${name}, in order to refer another individual to the ${organisationName} you need to get consent from them`,
        "This is because the Hub will follow up with them directly and confirm you as the source of the contact",
        `Please confirm consent with the individual or encourage them to complete a self referral by visiting this page and completing a self-referral`
      ],
      prompt: {
        id: this.getPromptId("sayCannotReferIndividual"),
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: false },
          { body: "I understand", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "And that's everything",
        `You've officially submitted this referral to ${organisationName}`
      ],
      nextStep: this.end
    }
  }

  @step.logState
  async submitReferralEmail(d: IStepData<State>): Promise<IStepResult> {
    const emails = this.rootStore.configStore.wellbeingHubEmails ?? []

    try {
      // prettier-ignore
      const text = this.createReferralEmail(d.state)

      // TODO: need to confirm if custom `from` email is needed or just
      // use the default noreply@limbic.ai
      const status = await sendEmail({
        from: "customersupport@limbic.ai",
        subject: "Limbic Referral | Individual Support",
        to: emails,
        text
      })
      if (status === SendEmailStatus.SendEmailFailed) {
        d.state.referralSubmitted = false
        d.state.referralSubmissionFailed = true
        this.track(TrackingEvents.MANAGER_INDIVIDUAL_REFERRAL_NOT_SUBMITTED)
        return { nextStep: this.sayReferralFailed }
      }
      d.state.referralSubmitted = true
      d.state.referralSubmissionFailed = false
      this.track(TrackingEvents.MANAGER_INDIVIDUAL_REFERRAL_SUBMITTED)
    } catch (e) {
      this.logException(e, "onReferralFinished -> sendEmail")
    }
    return { nextStep: this.sayReferralSucceeded }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit the provided data to ${organisationName}`,
        "I've notified my creators of this issue",
        `If you don't wish to wait, you can manually refer yourself by visiting this page and completing a self-referral`
      ],
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }],
        isUndoAble: false
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralWellbeingHubManagerIndividualScriptStateSchema
  }

  // TODO: When we have the backend setup we also need to create a referral for ManagerIndividual
  // i.e. nextStep: this.onReferralFinished - and send email there
  async getReferralPayload(state: State): Promise<Record<string, any>> {
    return {}
  }

  async onHandleEmail(_state: State): Promise<IStepResult> {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  createReferralEmail(state: State): string {
    // prettier-ignore
    return `
    <html lang='en'>
      <head>
      <title>Limbic Referral | Individual Support</title>
      ${this.getEmailHTMLStyle()}
      </head>
      <body>
        <h1 style="text-align: left;">Limbic Referral | Individual Support</h1>
        <b>Individual:</b> ${state.nameOfIndividualBeingReferred}<br/>
        <b>Referrer:</b> ${state.username}<br/>
        <b>Referral Type:</b> Individual Support<br/>
        <hr/>
        <h3>Individual Info</h3>
        <b>Name:</b> ${state.nameOfIndividualBeingReferred}<br/>
        <b>Email:</b> ${state.emailOfIndividual}<br/>
        <b>Phone Number:</b> ${state.phoneNumberOfIndividual}<br/>
        <h3>Situation Details</h3>
        <b>Individual at risk:</b> ${state.individualAtRisk}<br/>
        <b>Situation/issue details:</b> ${state.mainIssue}<br/>
        <h3>Referrer Info</h3>
        <b>Name:</b> ${state.username}<br/>
        <b>Role:</b> ${state.role}<br/>
        <b>Organisation:</b> ${state.organisation}<br/>
        <b>Email:</b> ${state.email}<br/>
        <b>Phone Number:</b> ${state.phoneNumber}<br/>
        <b>Consent from Individual:</b> ${state.consentFromIndividual}<br/>
      </body>
    </html>
    `.replace(/undefined/gi, "-").replace(/true/gi, "Yes").replace(/false/gi, "No")
  }

  getRoles(_state: State): string[] {
    return []
  }

  getOrganisations(_state: State): string[] {
    return []
  }
}

export default class SelfReferralWellbeingHubManagerIndividualDialogue extends AdHocDialogue<
  State,
  SelfReferralWellbeingHubManagerIndividualScript
> {
  static id = DialogueIDs.SelfReferralWellbeingHubManagerIndividual
  readonly name: string = "SelfReferralWellbeingHubManagerIndividualDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralWellbeingHubManagerIndividualDialogue.id,
      new SelfReferralWellbeingHubManagerIndividualScript(),
      state,
      snapshot
    )
  }
}
