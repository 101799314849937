import moment from "moment"
import { action, observable } from "mobx"
import Persistable from "../../models/Persistable"
import { ReferralType } from "../../models/Constants"
import invariant from "../../utils/invariant"
import createReferral from "../../backend/api/createReferral"
import updateReferral, { IUpdateReferralResponse } from "../../backend/api/updateReferral"
import getReferral from "../../backend/api/getReferral"
import type { IReferralDoc } from "@limbic/types"
import type { ACCESS_INSTANCES } from "@limbic/types"
import { Severity } from "../../models/Logger/Severity"

export default class ReferralStore extends Persistable {
  readonly name: string = "ReferralStore"
  _instanceID?: keyof typeof ACCESS_INSTANCES
  pingTimer?: NodeJS.Timeout
  patientId?: string
  signupCode?: string
  isMainSpokenLanguageEnglish?: boolean
  referralType: string
  customFields: Record<string, unknown>
  clinicalNotes: string[]
  idleSubmissionActive: boolean
  skipOnlineReferral?: boolean
  shouldHavePatientIdAndInstanceId?: boolean
  phq9HasBeenSend?: boolean
  @observable referralCreated: boolean
  @observable referralSubmitted: boolean
  @observable submissionTime?: string
  @observable updateSubmissionTimeFailed: boolean

  onReferralSubmitted?(): void
  getInstanceID?(): undefined | keyof typeof ACCESS_INSTANCES

  constructor() {
    super()
    this._instanceID = this.hydrate("_instanceID")
    this.patientId = this.hydrate("patientId")
    this.signupCode = this.hydrate("signupCode")
    this.isMainSpokenLanguageEnglish = this.hydrate("isMainSpokenLanguageEnglish") ?? true
    this.referralType = this.hydrate("referralType") ?? ReferralType.SELF_REFERRAL
    this.customFields = this.hydrate("customFields") ?? {}
    this.clinicalNotes = this.hydrate("clinicalNotes") ?? []
    this.idleSubmissionActive = this.hydrate("idleSubmissionActive") ?? true
    this.skipOnlineReferral = this.hydrate("skipOnlineReferral") ?? false
    this.shouldHavePatientIdAndInstanceId =
      this.hydrate("shouldHavePatientIdAndInstanceId") ?? false
    this.referralCreated = this.hydrate("referralCreated") ?? false
    this.referralSubmitted = this.hydrate("referralSubmitted") ?? false
    this.submissionTime = this.hydrate("submissionTime")
    this.updateSubmissionTimeFailed = this.hydrate("updateSubmissionTimeFailed") ?? false
    this.phq9HasBeenSend = this.hydrate("phq9HasBeenSend") ?? false
  }

  setInstanceID(id: keyof typeof ACCESS_INSTANCES): void {
    this._instanceID = id
    this.persist("_instanceID", id)
  }

  setPatientId(id: string | undefined): void {
    this.patientId = id
    this.persist("patientId", id)
    this.logger.setTag("referral", id)
  }

  setSignupCode(code: string | undefined): void {
    this.signupCode = code
    this.persist("signupCode", code)
    this.logger.setTag("signupCode", code)
  }

  setReferralType(referralType: string): void {
    const changed = this.referralType !== referralType
    this.referralType = referralType
    this.persist("referralType", referralType)
    if (changed) {
      void this.updateReferral({ output: referralType })
    }
  }

  setCustomField<T = any>(key: keyof T, data: T[keyof T]): ReferralStore {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(this.customFields as T)[key] = data
    this.persist("customFields", this.customFields)
    return this
  }

  setPHQ9HasBeenSend(hasBeenSend: boolean): void {
    this.phq9HasBeenSend = hasBeenSend
    this.persist("phq9HasBeenSend", hasBeenSend)
  }

  private setClinicalNotes(notes: string[]): void {
    this.clinicalNotes = [...new Set(notes)]
    this.persist("clinicalNotes", notes)
  }

  /**
   * This is purely to make sure we don't set the clinical notes
   * outside this class unless we're using the addClinicalNote
   * method which doesn't run the risk to overwrite them all, while
   * also allowing for it to be done in very specific situations.
   *
   * NOTE: if you use this, it will overwrite the clinical notes, so
   *       make sure you copy the current ones first before you add
   *       any new clinical notes
   * @param notes {string[]} the clinical notes the referral should have
   */
  __dangerouslySetClinicalNotes(notes: string[]): void {
    return this.setClinicalNotes(notes)
  }

  addClinicalNote(note: string): void {
    this.setClinicalNotes([...this.clinicalNotes, note])
    void this.updateReferral({ clinicalNotes: [note] })
  }

  setSkipOnlineReferral(skipOnlineReferral: boolean): void {
    this.skipOnlineReferral = skipOnlineReferral
    this.persist("skipOnlineReferral", skipOnlineReferral)
  }

  setShouldHavePatientIdAndInstanceId(shouldHavePatientIdAndInstanceId: boolean): void {
    this.shouldHavePatientIdAndInstanceId = shouldHavePatientIdAndInstanceId
    this.persist("shouldHavePatientIdAndInstanceId", shouldHavePatientIdAndInstanceId)
  }

  setIdleSubmissionActive(idleSubmissionActive: boolean): void {
    this.idleSubmissionActive = idleSubmissionActive
    this.persist("idleSubmissionActive", idleSubmissionActive)
  }

  setReferralCreated(referralCreated: boolean): void {
    this.referralCreated = referralCreated
    this.persist("referralCreated", referralCreated)
  }

  setReferralSubmitted(referralSubmitted: boolean): void {
    this.referralSubmitted = referralSubmitted
    this.persist("referralSubmitted", referralSubmitted)
  }

  @action
  setSubmissionTime(submissionTime?: string): void {
    this.submissionTime = submissionTime
    this.persist("submissionTime", submissionTime)
  }

  @action
  setUpdateSubmissionTimeFailed(updateSubmissionTimeFailed: boolean): void {
    this.updateSubmissionTimeFailed = updateSubmissionTimeFailed
    this.persist("updateSubmissionTimeFailed", updateSubmissionTimeFailed)
  }

  setIsMainSpokenLanguageEnglish(language: string): void {
    if (language.toLowerCase().match(/english/i)) {
      this.isMainSpokenLanguageEnglish = true
      this.persist("isMainSpokenLanguageEnglish", true)
    } else {
      this.isMainSpokenLanguageEnglish = false
      this.persist("isMainSpokenLanguageEnglish", false)
    }
  }

  /** Generic Handlers */

  getCustomField<T = Record<string, any>, Key extends keyof T = keyof T>(key: Key): T[Key] {
    const fields = this.customFields as T
    return fields[key]
  }

  startPinging(): void {
    this.stopPinging()
    const isSubmitted = this.submissionTime
      ? moment.utc().isAfter(moment.utc(this.submissionTime))
      : false
    if (this.patientId && !isSubmitted) {
      this.pingTimer = setTimeout(() => this.updateSyncAt(), 60 * 1000)
    }
  }

  stopPinging(): void {
    if (this.pingTimer) {
      clearTimeout(this.pingTimer)
      this.pingTimer = undefined
    }
  }

  /** Server Handlers */

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async createReferral(payload: any): Promise<void> {
    try {
      invariant(this.instanceID, "Cannot use createReferral without an instanceID")
      const instanceID = this.instanceID
      const waitSubmitUntil = moment.utc().add(5, "minutes").toISOString()
      const result = await createReferral({ waitSubmitUntil, ...payload, instanceID })
      const signupCode = result?.signupCode
      invariant(result?._id, "Referral ID was not returned from call to createReferral")
      invariant(signupCode, "signupCode was not returned from call to createReferral")
      this.setPatientId(result?._id)
      this.setSignupCode(signupCode)
      this.setSubmissionTime(waitSubmitUntil)
      this.setReferralCreated(true)
      this.startPinging()
      this.setPeople({ signupCode })
      this.track("Signup Code Set", { body: signupCode })
    } catch (e) {
      this.logException(e, "createReferral")
      throw e
    } finally {
      this.logMessage("createReferral")
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async updateReferral(payload: any): Promise<IUpdateReferralResponse | undefined> {
    if (this.skipOnlineReferral || !this.shouldHavePatientIdAndInstanceId) {
      this.logMessage("skipping updateReferral - email referral")
      this.logBreadcrumb("updateReferralSkipped", payload)
      return
    }
    const errorMessageInstanceID = "Cannot use updateReferral without an instanceID"
    const errorMessagePatientID = "Cannot use updateReferral without a patientId"
    try {
      invariant(this.instanceID, errorMessageInstanceID)
      invariant(this.patientId, errorMessagePatientID)
      return await updateReferral(this.patientId, { instanceID: this.instanceID, ...payload })
    } catch (e) {
      let severity: Severity | undefined = undefined
      if (e.message === errorMessagePatientID || e.message === errorMessageInstanceID)
        severity = Severity.Warning
      this.logException(e, "updateReferral", severity)
    } finally {
      this.logMessage("updateReferral")
    }
  }

  async getReferral(): Promise<IReferralDoc | undefined> {
    try {
      invariant(this.patientId, "Cannot use getReferral without a patientId")
      return await getReferral(this.patientId)
    } catch (e) {
      this.logException(e, "getReferral")
    }
  }

  async updateReferralType(): Promise<void> {
    try {
      if (this.patientId) {
        const referralType = this.referralType
        const uploadData = { referralType }
        this.logBreadcrumb("updateReferralType", uploadData)
        await this.updateReferral({ output: referralType })
      }
    } catch (e) {
      this.logException(e, "updateReferralType")
    } finally {
      this.logMessage("updateReferralType")
    }
  }

  @action
  async updateSyncAt(): Promise<void> {
    try {
      const waitSubmitUntil = moment.utc().add(5, "minutes").toISOString()
      if (this.patientId) {
        const output = this.referralType
        const result = await this.updateReferral({ output, waitSubmitUntil })
        invariant(result, "updating waitSubmitUntil failed")
        if (result?.status === "PENDING") {
          this.startPinging()
        } else if (!this.referralSubmitted) {
          this.onReferralSubmitted?.()
          this.stopPinging()
          return
        }
        this.setSubmissionTime(waitSubmitUntil)
        this.setUpdateSubmissionTimeFailed(false)
      }
    } catch (e) {
      console.log({ e })
      this.setUpdateSubmissionTimeFailed(true)
      this.logException(e, "updateSyncAt")
      this.startPinging()
    }
  }

  /** Getters / Setters */

  get instanceID(): undefined | keyof typeof ACCESS_INSTANCES {
    return this.getInstanceID?.() ?? this._instanceID
  }
}
