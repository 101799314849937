import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { RiskLevelReason } from "../../../models/Constants"
import CrisisDetector from "../../../models/CrisisDetector"

type State = CrisisScriptState
export type CrisisNorfolkScriptState = State

export class CrisisNorfolkScript extends CrisisScript {
  readonly name: string = "CrisisNorfolkScript"

  /** Script Steps */

  @step
  async handleAreYouInCrisis(d: IStepData<State, boolean>): Promise<IStepResult> {
    // TODO: When all services are using the new backend remove this step entirely
    this.clinicalStore.setIsCrisis(d.response)
    this.setCrisisDetectionCorrect(d.state, d.response)

    if (d.response) {
      this.blockUndo(d.state)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CRISIS_DETECTION)
      this.referralStore
        .updateReferral({
          riskLevel: this.clinicalStore.riskLevel,
          riskLevelReason: this.clinicalStore.riskLevelReason,
          triggerWords: this.clinicalStore.triggerWords
        })
        .catch(e => this.logException(e, "handleAreYouInCrisis -> updateReferral"))
      this.updateReferralType(d.state)
    }

    if (!d.response && d.state.disableDetectionIfWrong) {
      CrisisDetector.getInstance().disableCrisisDetectionForNextInput()
    }
    return {
      nextStep: d.response //
        ? this.saySorryToHear
        : this.saySorryForTheMisunderstanding
    }
  }

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "However, this is not an emergency response service"
      ],
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "To get more appropriate help, you can call NHS 111 and selection Option 2",
        "If you need urgent, life threatening medical help please call 999",
        "Alternatively, you can also call Samaritans on 116 123"
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999, NHS 111, Samaritans on 116 123")
    return {
      body: "Ok, let's carry on with the mental health check in",
      nextStep: this.end
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ],
      nextStep: this.end
    }
  }

  @step
  async theyllCallYou(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.phoneNumber) {
      return { nextStep: this.askPhoneNumber }
    }

    return {
      body: `They'll call you within 72 hours on the number you provided earlier (${d.state.phoneNumber})`,
      prompt: {
        id: this.getPromptId("theyllCallYou"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: true },
          { body: "Change number", value: false }
        ]
      },
      nextStep: this.handleTheyllCallYou
    }
  }

  /** Generic Handlers */
}

export default class CrisisNorfolkDialogue extends Dialogue<State> {
  static id = DialogueIDs.CrisisNorfolk
  readonly name: string = "CrisisNorfolkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisNorfolkDialogue.id, new CrisisNorfolkScript(), state, snapshot)
  }
}
