import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionScriptState } from "./IntroductionScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = IntroductionScriptState

export class IntroductionHealixScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionHealixScript"

  /** Script Steps */

  @step.logState
  greet(d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const firstName = this.referralStore.getCustomField("firstName") ?? undefined
    const middleNames = this.referralStore.getCustomField("middleNames") ?? undefined
    const lastName = this.referralStore.getCustomField("lastName") ?? undefined
    let greet = "Hi there!"
    if (firstName && lastName) {
      d.state.name = { firstName, middleNames, lastName }
      const username = this.getFullName(d.state)
      const name = this.getName(d.state)
      d.state.username = username
      this.rootStore.applicationStore.setUsername(username)
      greet = `Hi ${name}!`
    }
    return {
      body: [
        greet,
        "I’m Limbic",
        `I'm a friendly robot assistant to help you access psychological support`
      ],
      nextStep: directReferral ? this.end : this.askCanIHelp
    }
  }

  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    return {
      body: "Can I help?",
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: `Yes, I'd like to do a mental health check-in`, value: true },
          { body: "No, I'm just browsing", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }
}

/* istanbul ignore next */
export default class IntroductionHealixDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionHealix
  readonly name: string = "IntroductionHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionHealixDialogue.id, new IntroductionHealixScript(), state, snapshot)
  }
}
