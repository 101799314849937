import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionIAPTScriptState } from "./IntroductionIAPTDialogue"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = IntroductionIAPTScriptState

export class IntroductionVHGScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionVHGScript"

  /* Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    const hostname = window.location.hostname
    const queryParams = new URLSearchParams(window.location.search)
    const type = queryParams.get("referral-source")
    const clinicalNotes = this.referralStore.clinicalNotes
    const urlNote = [`Referral came in through ${hostname}`]
    if (type === "phone") {
      urlNote.push(`Referral was made by ${type}`)
      this.rootStore.botStore.setMessageDelay(0, 0, 0)
    }
    this.referralStore.__dangerouslySetClinicalNotes([...clinicalNotes, ...urlNote])
    return super.start(d)
  }
}

/* istanbul ignore next */
export default class IntroductionVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionVHG
  readonly name: string = "IntroductionVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionVHGDialogue.id, new IntroductionVHGScript(), state, snapshot)
  }
}
