import { z } from "zod"

export default interface IUserResponse {
  title: string
  answer: string
}

export const IUserResponseSchema = z.object({
  title: z.string(),
  answer: z.string()
})

export interface IPersistableUserResponse extends IUserResponse {
  id: string
}

export const IPersistableUserResponseSchema = IUserResponseSchema.extend({
  id: z.string()
})

export interface IMockUserAnswer {
  type: "answer"
  id?: string
  body?: string
  value?: any
}

export interface IMockUndoAction {
  type: "undo"
}

export interface IMockDropOffAction {
  type: "dropOff"
}

export type MockUserAction = IMockUserAnswer | IMockUndoAction | IMockDropOffAction
