import Dialogue, { IDialogueSnapshot } from "../../backend/chatbot/Dialogue"
import { step } from "../../backend/chatbot/decorators/step"
import BaseScript from "../BaseScript"
import { DialogueIDs } from "../DialogueIDs"
import type { SurveyScriptState } from "../createSurveyDialogue"
import type { IStepData, IStepResult } from "../../backend/chatbot/models/IStep"

type State = SurveyScriptState

class DrugsAndSmokingScript extends BaseScript<State> {
  readonly name: string = "DrugsAndSmokingScript"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult<State> {
    this.timeEvent(`${this.name} Finished`)
    return { nextStep: this.askRecreationalOrOtherLegalDrugs }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step.logState
  askRecreationalOrOtherLegalDrugs(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently using recreational, over the counter and/or controlled drugs?",
      prompt: {
        id: this.getPromptId("askRecreationalOrOtherLegalDrugs"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleRecreationalOrOtherLegalDrugs
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "1",
    "1. Are you currently using recreational, over the counter and/or controlled drugs?",
    "drugsAndSmokingResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleRecreationalOrOtherLegalDrugs(d: IStepData<State, string>): IStepResult {
    if (!d.response) {
      this.rootStore.applicationStore.setCurrentProgress(0.5)
      return { nextStep: this.askDoYouSmoke }
    }
    this.rootStore.applicationStore.setCurrentProgress(0.25)
    return { nextStep: this.askWouldYouLikeSupportToStopDrugs }
  }

  @step.logState
  askWouldYouLikeSupportToStopDrugs(d: IStepData<State>): IStepResult {
    return {
      body: `Would you like support with reducing / managing your drug use?`,
      prompt: {
        id: this.getPromptId("askWouldYouLikeSupportToStopDrugs"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleWouldYouLikeSupportToStopDrugs
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "2",
    "2. Would you like support with reducing / managing your drug use?",
    "drugsAndSmokingResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleWouldYouLikeSupportToStopDrugs(d: IStepData<State, boolean>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.5)
    return { nextStep: this.askDoYouSmoke }
  }

  @step.logState
  askDoYouSmoke(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you smoke?",
      prompt: {
        id: this.getPromptId("askDoYouSmoke"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleDoYouSmoke
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>("3", "3. Do you smoke?", "drugsAndSmokingResponses", (r: boolean) =>
    r ? "Yes" : "No"
  )
  handleDoYouSmoke(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.rootStore.applicationStore.setCurrentProgress(0.75)
      return { nextStep: this.askWouldYouLikeSupportToStopSmoking }
    }
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.end }
  }

  @step.logState
  askWouldYouLikeSupportToStopSmoking(_d: IStepData<State>): IStepResult {
    return {
      body: "Would you like support in reducing/stopping smoking?",
      prompt: {
        id: this.getPromptId("askWouldYouLikeSupportToStopSmoking"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleWouldYouLikeSupportToStopSmoking
    }
  }

  @step.logStateAndResponse
  @step.saveResponse<State>(
    "4",
    "4. Would you like support in reducing/stopping smoking?",
    "drugsAndSmokingResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleWouldYouLikeSupportToStopSmoking(d: IStepData<State, boolean>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.end }
  }
}

export default class DrugsAndSmokingDialogue extends Dialogue<State> {
  static id = DialogueIDs.DrugsAndSmoking
  readonly name: string = "DrugsAndSmokingDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(DrugsAndSmokingDialogue.id, new DrugsAndSmokingScript(), state, snapshot)
  }
}
