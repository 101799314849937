import self from "./countsByInstanceID"
import invariant from "../../utils/invariant"
import { backendEnv } from "../../config/config"
import Logger from "../../utils/Logger"
import delay from "../../utils/delay"
import NetworkError from "../../models/NetworkError"
import { ACCESS_INSTANCES } from "@limbic/types"

const TOTAL_RETRIES = 2

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/referral/countsByInstanceID",
  development: "https://access-v2-staging.limbic.ai/referral/countsByInstanceID",
  production: "https://access-v2.limbic.ai/referral/countsByInstanceID"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const COUNTS_BY_INSTANCE_ID = endpoints[backendEnv] || endpoints.production
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export default async function countsByInstanceID(
  fromDate: string,
  instanceIDs?: Array<keyof typeof ACCESS_INSTANCES>,
  retry = 0
): Promise<Record<keyof typeof ACCESS_INSTANCES, number>> {
  let params = `fromDate=${fromDate}`
  if (instanceIDs?.length) params = `${params}&instanceIDs=${instanceIDs.join(",")}`
  try {
    const url = `${COUNTS_BY_INSTANCE_ID}?${params}`
    const result = await fetch(url, { headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) throw new NetworkError("", error)
    if (!success && validations) throw new NetworkError("", validations?.join(", "))
    return data
  } catch (e) {
    Logger.getInstance().exception(e, "countsByInstanceID fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`countsByInstanceID body for retry ${retry}`, params)
      Logger.getInstance().message("countsByInstanceID retry")
      await delay(1)
      return await self(fromDate, instanceIDs, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
