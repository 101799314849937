import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PeaceOfMindScript } from "./PeaceOfMindScript"
import type { PeaceOfMindScriptState } from "./PeaceOfMindScript"

type State = PeaceOfMindScriptState

export class PeaceOfMindWellbeingHubScript extends PeaceOfMindScript {
  readonly name: string = "PeaceOfMindWellbeingHubScript"
}
/* istanbul ignore next */
export default class PeaceOfMindWellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.PeaceOfMindWellbeingHub
  readonly name: string = "PeaceOfMindWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PeaceOfMindWellbeingHubDialogue.id, new PeaceOfMindWellbeingHubScript(), state, snapshot)
  }
}
