/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment"
import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptStateSchema } from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { EligibilityCheckSABPScriptState } from "../eligibility/EligibilityCheckSABPDialogue"
import sendEmail from "../../../backend/api/sendEmail"
import {
  ALCOHOL_FREQUENCIES,
  ALCOHOL_QUANTITIES,
  DiscussionSteps,
  ReferralType,
  TrackingEvents
} from "../../../models/Constants"
import {
  ALCOHOL_QUANTITIES as AQ,
  ALCOHOL_FREQUENCIES as AF,
  ARMED_FORCES_MAYDEN_SABP,
  CIVIL_STATUS_MAYDEN_SABP,
  DISABILITY_MAYDEN_SABP,
  ETHNICITY_MAYDEN_SABP,
  GENDER_MAYDEN_SABP,
  LANGUAGE_MAYDEN_SABP,
  LIMBIC_IMPACT_LEVEL,
  LTC_MAYDEN_SABP,
  NATIONALITY_MAYDEN,
  ReferralPayloadMaydenSABP,
  RELIGION_MAYDEN_SABP,
  SEXUALITY_MAYDEN_SABP,
  GenderBirthAssigned
} from "@limbic/types"
import invariant from "../../../utils/invariant"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  languages,
  ltcs,
  religions,
  sameGenderAsBirth,
  sexualities
} from "../../../config/referralForms/sabp"
import { CCGCode } from "../../../models/ICCG"
import { gpsSABP } from "../../../config/iaptMaps/sabp"
import { Category } from "@limbic/crisis-detection"

interface State extends SelfReferralIAPTScriptState {
  parentOrGuardianInfo?: string
  nationalIdentity?: string
  primaryLanguage?: string
  requiresInterpreter?: boolean
  interpreterLanguage?: string
  relationshipStatus?: string
  hasChildOrIsPregnant?: boolean
  teenChildren?: boolean
  teenChildrenInfo?: string
  teenChildrenInHousehold?: boolean
  otherAgenciesInYourCare?: boolean
  otherAgenciesInYourCareInfo?: string
  registeredCarer?: boolean
  caredForContactInfo?: string
  isSmoker?: boolean
  smokingQuantity?: string
  isEmployed?: boolean
  currentMHTreatment?: string[]
  priorMHTreatment?: string
  historyOfViolence?: boolean
  longTermConditionOther?: string
}
export type SelfReferralSABPState = State

export const SelfReferralSABPStateSchema = SelfReferralIAPTScriptStateSchema.extend({
  parentOrGuardianInfo: z.string().optional(),
  nationalIdentity: z.string().optional(),
  primaryLanguage: z.string().optional(),
  requiresInterpreter: z.boolean().optional(),
  interpreterLanguage: z.string().optional(),
  relationshipStatus: z.string().optional(),
  hasChildOrIsPregnant: z.boolean().optional(),
  teenChildren: z.boolean().optional(),
  teenChildrenInfo: z.string().optional(),
  teenChildrenInHousehold: z.boolean().optional(),
  otherAgenciesInYourCare: z.boolean().optional(),
  otherAgenciesInYourCareInfo: z.string().optional(),
  registeredCarer: z.boolean().optional(),
  caredForContactInfo: z.string().optional(),
  isSmoker: z.boolean().optional(),
  smokingQuantity: z.string().optional(),
  isEmployed: z.boolean().optional(),
  currentMHTreatment: z.array(z.string()).optional(),
  priorMHTreatment: z.string().optional(),
  historyOfViolence: z.boolean().optional(),
  longTermConditionOther: z.string().optional()
})

export class SelfReferralSABPScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralSABPScript"

  /** Script Steps */

  @step.logState
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    if (d.state.nhsNumber) {
      return { nextStep: this.goToCollectPhoneNumber }
    }
    // NOTE: This should never be the case but keeping it as a backup
    return { nextStep: this.askNHSNumber }
  }

  @step.logState
  askNHSNumber(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "What is your NHS number?",
        "Your treatment will be delayed if we do not have your NHS number, it can be found on any NHS correspondence or your NHS medical App"
      ],
      prompt: {
        id: this.getPromptId("askNHSNumber"),
        trackResponse: false,
        type: "inlinePicker",
        choices: [{ body: "I don't know", value: null }],
        textPrompt: {
          trimAllSpacesOnSubmit: true,
          trimAllSpacesOnValidation: true,
          validation: [/^\d{10}$/],
          validationExplainer: [
            "This is not a valid NHS number",
            "It needs to be a number with 10 digits"
          ],
          forceValue: true
        }
      },
      nextStep: this.handleNHSNumber
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    // This 👇 is important to make sure we don't add empty strings
    d.state.nhsNumber = d.response ? d.response : undefined
  })
  handleNHSNumber(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.goToCollectPhoneNumber(d)
    return { ...result, nextStep: this.askEmail }
  }

  @step.logState
  askEmail(d: IStepData<State>): IStepResult {
    return {
      body: [
        "Do you have an email we can reach you on?",
        "We will need to contact you occasionally about your treatment"
      ],
      prompt: {
        id: this.getPromptId("askEmail"),
        trackResponse: false,
        type: "inlinePicker",
        choices: [
          {
            body: "I don't have an email address",
            value: false
          }
        ],
        emailPrompt: {
          placeholder: "Your email"
        }
      },
      nextStep: this.handleEmail
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string | boolean>, script: SelfReferralSABPScript) => {
    const hasEmail = typeof d.response !== "boolean"
    script.setPeople({ hasEmail })
    script.track(TrackingEvents.DO_YOU_HAVE_EMAIL, { body: hasEmail ? "Yes" : "No" })
  })
  async handleEmail(d: IStepData<State, string | boolean>): Promise<IStepResult> {
    d.state.email = typeof d.response !== "boolean" ? d.response : undefined
    const result = await this.onHandleEmail(d.state)
    if (result) return result
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  @step.logState
  startSelfReferralPart2(d: IStepData<State>): IStepResult {
    const age = this.getUserAge(d.state)
    if (age < 18) return { nextStep: this.askParentOrGuardianInfo }
    return { nextStep: this.askNationalIdentity }
  }

  @step.logState
  askParentOrGuardianInfo(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Where can we contact your parent/guardian?",
        "(Please provide both a name and a contact number)"
      ],
      prompt: {
        id: this.getPromptId("askParentOrGuardianInfo"),
        trackResponse: false,
        type: "text",
        forceValue: true
      },
      nextStep: this.handleParentOrGuardianInfoWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralSABPScript) => {
    d.state.parentOrGuardianInfo = d.response
    script.referralStore.setCustomField<State>("parentOrGuardianInfo", d.state.parentOrGuardianInfo)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralSABPScript) => s.askNationalIdentity
  })
  handleParentOrGuardianInfoWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askNationalIdentity }
  }

  @step.logState
  askPermissionToEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you allow us to contact you over email?",
      prompt: {
        id: this.getPromptId("askPermissionToEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPermissionToEmail"
      },
      nextStep: this.handlePermissionToEmail
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canSendEmail = d.response
  })
  handlePermissionToEmail(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  @step.logState
  askPermissionToSendMailToAddress(_d: IStepData<State>): IStepResult {
    return {
      body: ["Brilliant!", "Can we contact you via regular mail about your treatment plan?"],
      prompt: {
        id: this.getPromptId("askPermissionToSendMailToAddress"),
        trackResponse: true,
        type: "checkbox",
        options: [
          {
            body: "Yes, you can send me letters at home",
            key: "canSendMailToAddress",
            initialValue: true
          }
        ]
      },
      nextStep: this.handlePermissionToSendMailToAddressCheckbox
    }
  }

  @step.logState
  async handlePermissionToSendMailToAddressCheckbox(
    d: IStepData<State, { canSendMailToAddress: boolean }>
  ): Promise<IStepResult> {
    d.state.canSendMailToAddress = d.response.canSendMailToAddress
    return { nextStep: this.finishSelfReferral }
  }

  @step.logState
  askNationalIdentity(_d: IStepData<State>): IStepResult {
    // TODO: Move in referral form?
    const nationalities = ["British", "English", "Irish", "Scottish", "Welsh", "Other"]
    return {
      body: "What is your national identity?",
      prompt: {
        id: this.getPromptId("askNationalIdentity"),
        trackResponse: true,
        type: "inlinePicker",
        choices: nationalities.map(n => ({ body: n, value: n })),
        dataPointsName: "askNationalIdentity"
      },
      nextStep: this.handleNationalIdentity
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralSABPScript) => {
    d.state.nationalIdentity = d.response
    script.referralStore.setCustomField<State>("nationalIdentity", d.state.nationalIdentity)
  })
  handleNationalIdentity(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEthnicity }
  }

  @step.logState
  askRequiresInterpreter(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you require an interpreter?",
      prompt: {
        id: this.getPromptId("askRequiresInterpreter"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askRequiresInterpreter"
      },
      nextStep: this.handleRequiresInterpreter
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralSABPScript) => {
    d.state.requiresInterpreter = d.response
    script.referralStore.setCustomField<State>("requiresInterpreter", d.state.requiresInterpreter)
  })
  handleRequiresInterpreter(d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: d.response //
        ? this.askInterpreterLanguage
        : this.askExArmedForces
    }
  }

  @step.logState
  askInterpreterLanguage(d: IStepData<State>): IStepResult {
    const primaryLanguage = d.state.primaryLanguage
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, you stated your main spoken language is ${primaryLanguage}.`,
        "Is this the language you need an interpreter for?",
        "(If not, just type the language you'd like an interpreter for)"
      ],
      prompt: {
        id: this.getPromptId("askInterpreterLanguage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: `Yes, I need an interpreter for ${primaryLanguage}`, value: primaryLanguage }
        ],
        textPrompt: {
          forceValue: true,
          placeholder: "Enter language"
        },
        dataPointsName: "askInterpreterLanguage"
      },
      nextStep: this.handleInterpreterLanguage
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralSABPScript) => {
    d.state.interpreterLanguage = d.response
    script.referralStore.setCustomField<State>("interpreterLanguage", d.state.interpreterLanguage)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralSABPScript) => s.askExArmedForces
  })
  handleInterpreterLanguage(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askExArmedForces }
  }

  @step.logState
  askRelationshipStatus(_d: IStepData<State>): IStepResult {
    // TODO: move to referral form?
    const statuses = [
      "Single",
      "Married",
      "Divorced",
      "Widowed",
      "Separated",
      "Co-Habiting",
      "Long term",
      "Civil Partnership",
      "Not Disclosed"
    ]
    return {
      body: "What is your relationship status?",
      prompt: {
        id: this.getPromptId("askRelationshipStatus"),
        trackResponse: true,
        type: "inlinePicker",
        choices: statuses.map(s => ({ body: s, value: s })),
        dataPointsName: "askRelationshipStatus"
      },
      nextStep: this.handleRelationshipStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralSABPScript) => {
    d.state.relationshipStatus = d.response
    script.referralStore.setCustomField<State>("relationshipStatus", d.state.relationshipStatus)
  })
  handleRelationshipStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPerinatalStatus }
  }

  @step.logState
  askPerinatalStatus(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you pregnant or have a child under two?",
      prompt: {
        id: this.getPromptId("askPerinatalStatus"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPerinatalStatus"
      },
      nextStep: this.handlePerinatalStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralSABPScript) => {
    d.state.hasChildOrIsPregnant = d.response
    script.referralStore.setCustomField<State>("hasChildOrIsPregnant", d.state.hasChildOrIsPregnant)
  })
  handlePerinatalStatus(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askTeenChildren }
  }

  @step.logState
  askTeenChildren(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have children under the age of 18?",
      prompt: {
        id: this.getPromptId("askTeenChildren"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askTeenChildren"
      },
      nextStep: this.handleTeenChildren
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralSABPScript) => {
    d.state.teenChildren = d.response
    script.referralStore.setCustomField<State>("teenChildren", d.state.teenChildren)
  })
  handleTeenChildren(d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: d.response //
        ? this.askTeenChildrenInfo
        : this.askOtherAgenciesInYourCare
    }
  }

  @step.logState
  askTeenChildrenInfo(_d: IStepData<State>): IStepResult {
    return {
      body: "And what are their names and date of births?",
      prompt: {
        id: this.getPromptId("askTeenChildrenInfo"),
        trackResponse: false,
        type: "text",
        forceValue: true
      },
      nextStep: this.handleTeenChildrenInfoWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralSABPScript) => {
    d.state.teenChildrenInfo = d.response
    script.referralStore.setCustomField<State>("teenChildrenInfo", d.state.teenChildrenInfo)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralSABPScript) => s.askTeenChildrenInHousehold
  })
  handleTeenChildrenInfoWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askTeenChildrenInHousehold }
  }

  @step.logState
  askTeenChildrenInHousehold(_d: IStepData<State>): IStepResult {
    return {
      body: "Do those under 18 live in your household?",
      prompt: {
        id: this.getPromptId("askTeenChildrenInHousehold"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askTeenChildrenInHousehold"
      },
      nextStep: this.handleTeenChildrenInHousehold
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralSABPScript) => {
    d.state.teenChildrenInHousehold = d.response
    script.referralStore.setCustomField<State>(
      "teenChildrenInHousehold",
      d.state.teenChildrenInHousehold
    )
  })
  handleTeenChildrenInHousehold(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askOtherAgenciesInYourCare }
  }

  @step.logState
  askOtherAgenciesInYourCare(_d: IStepData<State>): IStepResult {
    return {
      body: "Are other agencies involved in your care?",
      prompt: {
        id: this.getPromptId("askOtherAgenciesInYourCare"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askOtherAgenciesInYourCare"
      },
      nextStep: this.handleOtherAgenciesInYourCare
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralSABPScript) => {
    d.state.otherAgenciesInYourCare = d.response
    script.referralStore.setCustomField<State>(
      "otherAgenciesInYourCare",
      d.state.otherAgenciesInYourCare
    )
  })
  handleOtherAgenciesInYourCare(d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: d.response //
        ? this.askOtherAgenciesInYourCareInfo
        : this.askRegisteredCarer
    }
  }

  @step.logState
  askOtherAgenciesInYourCareInfo(_d: IStepData<State>): IStepResult {
    return {
      body: "Please specify",
      prompt: {
        id: this.getPromptId("askOtherAgenciesInYourCareInfo"),
        trackResponse: true,
        type: "text",
        forceValue: true
      },
      nextStep: this.handleOtherAgenciesInYourCareInfoWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralSABPScript) => {
    d.state.otherAgenciesInYourCareInfo = d.response
    script.referralStore.setCustomField<State>(
      "otherAgenciesInYourCareInfo",
      d.state.otherAgenciesInYourCareInfo
    )
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralSABPScript) => s.askRegisteredCarer
  })
  handleOtherAgenciesInYourCareInfoWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askRegisteredCarer }
  }

  @step.logState
  askRegisteredCarer(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you a registered carer?",
      prompt: {
        id: this.getPromptId("askRegisteredCarer"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askRegisteredCarer"
      },
      nextStep: this.handleRegisteredCarer
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralSABPScript) => {
    d.state.registeredCarer = d.response
    script.referralStore.setCustomField<State>("registeredCarer", d.state.registeredCarer)
  })
  handleRegisteredCarer(d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: d.response //
        ? this.askCaredForContactInfo
        : this.askSubstances
    }
  }

  @step.logState
  askCaredForContactInfo(_d: IStepData<State>): IStepResult {
    return {
      body: "And what is the name and date of birth of the person you care for?",
      prompt: {
        id: this.getPromptId("askCaredForContactInfo"),
        trackResponse: false,
        type: "text",
        forceValue: true
      },
      nextStep: this.handleCaredForContactInfoWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralSABPScript) => {
    d.state.caredForContactInfo = d.response
    script.referralStore.setCustomField<State>("caredForContactInfo", d.state.caredForContactInfo)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralSABPScript) => s.askSubstances
  })
  handleCaredForContactInfoWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askSubstances }
  }

  @step.logState
  askIsSmoker(_d: IStepData<State>): IStepResult {
    return {
      body: "And do you smoke?",
      prompt: {
        id: this.getPromptId("askIsSmoker"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askIsSmoker"
      },
      nextStep: this.handleIsSmoker
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralSABPScript) => {
    // TODO: This is a temporary solution to deal with missing value for medication info
    // and be able to submit the forms properly if user chooses to skip question.
    // Need to find a better option i.e.update the prompt script to set a specific response on "skip"
    if (d.state.substances && d.state.substancesAreMedications && !d.state.medicationInfo) {
      d.state.medicationInfo = "Not stated"
      script.referralStore.setCustomField<State>("medicationInfo", "Not stated")
    }
    if (d.state.substances && !d.state.substancesAreMedications && !d.state.substancesInfo) {
      d.state.substancesInfo = "Not stated"
      script.referralStore.setCustomField<State>("substancesInfo", "Not stated")
    }
    d.state.isSmoker = d.response
    script.referralStore.setCustomField<State>("isSmoker", d.state.isSmoker)
  })
  handleIsSmoker(d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: d.response //
        ? this.askSmokingQuantity
        : this.goToCollectAlcoholConsumption
    }
  }

  @step.logState
  askSmokingQuantity(_d: IStepData<State>): IStepResult {
    return {
      body: "How many cigarettes do you smoke per day?",
      prompt: {
        id: this.getPromptId("askSmokingQuantity"),
        trackResponse: true,
        type: "text",
        trimAllSpacesOnSubmit: true,
        trimAllSpacesOnValidation: true,
        validation: [/^\d+$/],
        validationExplainer: ["This is not a number"],
        forceValue: true,
        dataPointsName: "askSmokingQuantity"
      },
      nextStep: this.handleSmokingQuantityWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralSABPScript) => {
    d.state.smokingQuantity = d.response
    script.referralStore.setCustomField<State>("smokingQuantity", d.state.smokingQuantity)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralSABPScript) => s.goToCollectAlcoholConsumption
  })
  handleSmokingQuantityWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToCollectAlcoholConsumption }
  }

  @step.logState
  goToCollectAlcoholConsumption(d: IStepData<State>): IStepResult {
    const CollectAlcoholConsumptionDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectAlcoholConsumption
    )

    const nextDialogue = CollectAlcoholConsumptionDialogue
      ? new CollectAlcoholConsumptionDialogue({ ...d.state })
      : undefined

    return {
      nextDialogue,
      nextStep: this.askLongTermMedicalCondition
    }
  }

  @step.logState
  askLongTermMedicalCondition(d: IStepData<State>): IStepResult {
    const medicalConditions = this.getMedicalConditions(d.state)
    if (!medicalConditions?.length) {
      this.logBreadcrumb("MEDICAL CONDITIONS NOT FOUND", d.state, { medicalConditions })
      this.logMessage("MEDICAL CONDITIONS NOT FOUND")
      return { nextStep: this.askHaveYouEverCaughtCovid }
    }
    return {
      body: "Do you have a physical long term condition?",
      prompt: {
        id: this.getPromptId("askLongTermMedicalCondition"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          { body: "I don't", value: "No", backgroundColor: "#EC9CC8", selectIndividually: true },
          ...medicalConditions.filter(m => m !== "No").map(m => ({ body: m, value: m }))
        ],
        dataPointsName: "askLongTermMedicalCondition"
      },
      nextStep: this.handleLongTermMedicalCondition
    }
  }

  @step.logState
  askLongTermMedicalConditionOther(_d: IStepData<State>): IStepResult {
    return {
      body: ['I see you selected "other"', "Please specify"],
      prompt: {
        id: this.getPromptId("askLongTermMedicalConditionOther"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleLongTermMedicalConditionOtherWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralSABPScript) => {
    d.state.longTermConditionOther = d.response
    script.referralStore.setCustomField<State>(
      "longTermConditionOther",
      d.state.longTermConditionOther
    )
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralSABPScript) => s.askDoesOtherLTCAffectMood,
    ignoredCategories: [Category.Medication]
  })
  handleLongTermMedicalConditionOtherWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askDoesOtherLTCAffectMood }
  }

  @step.logState
  askDoesOtherLTCAffectMood(d: IStepData<State>): IStepResult {
    return {
      body: `Does your ${d.state.longTermConditionOther} impact your mood?`,
      prompt: {
        id: this.getPromptId("askDoesOtherLTCAffectMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askDoesOtherLTCAffectMood"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleDoesOtherLTCAffectMood
    }
  }

  @step.logState
  async handleDoesOtherLTCAffectMood(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.ltcAffectsMood = d.response
    this.setPeople({ ltcAffectsMood: d.response })
    return {
      nextStep: !d.response //
        ? this.askHaveYouEverCaughtCovid
        : this.askHowMuchLTCAffectsMood
    }
  }

  @step.logState
  askIsEmployed(_d: IStepData<State>): IStepResult {
    return {
      body: "And are you employed?",
      prompt: {
        id: this.getPromptId("askIsEmployed"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askIsEmployed"
      },
      nextStep: this.handleIsEmployed
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralSABPScript) => {
    d.state.isEmployed = d.response
    script.referralStore.setCustomField<State>("isEmployed", d.state.isEmployed)
  })
  handleIsEmployed(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askCurrentMHTreatment }
  }

  @step.logState
  askCurrentMHTreatment(_d: IStepData<State>): IStepResult {
    const professionals = [
      "Psychiatrist",
      "Community Psychiatric Nurse",
      "Community Mental Health Team",
      "Substance Misuse team",
      "Private therapist or counsellor",
      "Psychological therapy team – Mind Matters",
      "Psychological therapy team – other",
      "Other/prefer not to say"
    ]
    return {
      body: "Are you currently seeing a mental health professional?",
      prompt: {
        id: this.getPromptId("askCurrentMHTreatment"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          {
            body: "I'm not seeing a mental health professional currently",
            value: null,
            selectIndividually: true,
            backgroundColor: "#da4b4b"
          },
          ...professionals.map(p => ({ body: p, value: p }))
        ],
        dataPointsName: "askCurrentSupport"
      },
      nextStep: this.handleCurrentMHTreatment
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, Array<string | null>>, script: SelfReferralSABPScript) => {
      d.state.currentMHTreatment = (d.response?.filter(Boolean) || []) as string[]
      script.referralStore.setCustomField<State>("currentMHTreatment", d.state.currentMHTreatment)
    }
  )
  handleCurrentMHTreatment(_d: IStepData<State, Array<string | null>>): IStepResult {
    return { nextStep: this.askPriorMHTreatment }
  }

  @step.logState
  askPriorMHTreatment(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Have you had any prior treatment for your mental health?",
        "If yes, please give me some details"
      ],
      prompt: {
        id: this.getPromptId("askPriorMHTreatment"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I haven't had any prior treatment", value: null }],
        textPrompt: {
          forceValue: true
        },
        dataPointsName: "askPriorMHTreatment"
      },
      nextStep: this.handlePriorMHTreatment
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string | null>, script: SelfReferralSABPScript) => {
    d.state.priorMHTreatment = d.response ?? undefined
    script.referralStore.setCustomField<State>("priorMHTreatment", d.state.priorMHTreatment)
  })
  @step.checkInputForCrisis({
    getInput: (d: IStepData<State, string | null>) => {
      // 👇 This fixes the Crisis Detection error
      // "crisis detection input must be a string"
      return !d.response ? "" : d.response
    },
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralSABPScript) => s.askHistoryOfViolence
  })
  handlePriorMHTreatment(_d: IStepData<State, string | undefined>): IStepResult {
    return { nextStep: this.askHistoryOfViolence }
  }

  @step.logState
  askHistoryOfViolence(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have a history of violence towards others or have been involved in the Criminal Justice System or Police?",
      prompt: {
        id: this.getPromptId("askHistoryOfViolence"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askHistoryOfViolence"
      },
      nextStep: this.handleHistoryOfViolence
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralSABPScript) => {
    d.state.historyOfViolence = d.response
    script.referralStore.setCustomField<State>("historyOfViolence", d.state.historyOfViolence)
  })
  handleHistoryOfViolence(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askWhatIsYourGoal }
  }

  @step
  sayUserNeedsToCallIn(_d: IStepData<State>): IStepResult {
    return {
      body: "Please give us a call on 0300 330 5450",
      clearStack: true,
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralSABPStateSchema
  }

  async onHandleEmail(state: State): Promise<IStepResult> {
    if (state.email) {
      return { nextStep: this.askPermissionToEmail }
    }
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  async onHandleEthnicity(_state: State): Promise<IStepResult> {
    return { nextStep: this.askPrimaryLanguage }
  }

  async onHandlePrimaryLanguage(state: State): Promise<IStepResult | void> {
    return {
      nextStep: state.primaryLanguage?.match(/english/gi) //
        ? this.askExArmedForces
        : this.askRequiresInterpreter
    }
  }

  async onHandleExArmedForces(_state: State): Promise<IStepResult> {
    return { nextStep: this.askReligion }
  }

  async onHandleGender(_state: State): Promise<IStepResult> {
    return { nextStep: this.askSameGenderAsBirth }
  }

  async onHandleSexuality(_state: State): Promise<IStepResult> {
    return { nextStep: this.askRelationshipStatus }
  }

  async onHandleSubstances(state: State): Promise<IStepResult> {
    return {
      nextStep: state.substances //
        ? this.askSubstancesOrigin
        : this.askIsSmoker
    }
  }

  async onHandleSubstancesOrigin(state: State): Promise<IStepResult> {
    return {
      nextStep: state.substancesAreMedications //
        ? this.askMedicationInfo
        : this.askSubstancesInfo
    }
  }

  async onHandleSubstancesInfoWithCrisis(_state: State): Promise<IStepResult> {
    return { nextStep: this.askIsSmoker }
  }

  async onHandleMedicationInfoWithCrisis(_state: State): Promise<IStepResult> {
    return { nextStep: this.askIsSmoker }
  }

  async onHandleLongTermMedicalCondition(state: State): Promise<IStepResult> {
    const hasLTC =
      state.longTermMedicalCondition?.length && //
      state.longTermMedicalCondition?.[0] !== "No"

    const hasOther = !!state.longTermMedicalCondition?.find(
      ltc => ltc.includes("other") || ltc.includes("Other")
    )

    if (hasOther) return { nextStep: this.askLongTermMedicalConditionOther }

    if (hasLTC) return { nextStep: this.askDoesLTCAffectMood }

    return {
      nextStep: this.askHaveYouEverCaughtCovid
    }
  }

  async onHandleDoesLTCAffectMood(state: State): Promise<IStepResult> {
    return {
      nextStep: state.ltcAffectsMood //
        ? this.askHowMuchLTCAffectsMood
        : this.askHaveYouEverCaughtCovid
    }
  }

  async onHandleHowMuchLTCAffectsMood(state: State): Promise<IStepResult> {
    return {
      nextStep:
        state.ltcMoodImpact === "little" //
          ? this.askHaveYouEverCaughtCovid
          : this.askHowWellYouManageYourLTC
    }
  }

  async onHandleHowWellYouManageYourLTC(state: State): Promise<IStepResult> {
    return { nextStep: this.askHaveYouEverCaughtCovid }
  }

  async onHandleHasADHD(state: State): Promise<IStepResult> {
    return { nextStep: this.askIsEmployed }
  }

  getGenderSameAsBirthValues(state: State): string[] {
    return state.iapt?.referralForm?.sameGenderAsBirth ?? []
  }

  getLanguages(state: State): string[] {
    return [
      "Akan (Ashanti)",
      "Albanian",
      "Amharic",
      "Arabic",
      "Bengali & Sylheti",
      "Brawa & Somali",
      "British Signing Language",
      "Cantonese",
      "Cantonese & Vietnamese",
      "Creole",
      "Dutch",
      "English",
      "Ethiopian",
      "Farsi (Persian)",
      "Finnish",
      "Flemish French",
      "French Creole",
      "Gaelic",
      "German",
      "Greek",
      "Gujarati",
      "Hakka",
      "Hause",
      "Hebrew",
      "Hindi",
      "Igbo (Ibo)",
      "Italian",
      "Japanese",
      "Korean",
      "Kurdish",
      "Lingala",
      "Luganda",
      "Makaton (Sign Language)",
      "Malayalam",
      "Mandarin",
      "Norwegian",
      "Pashto (Pushtoo)",
      "Patois",
      "Polish",
      "Portuguese",
      "Punjabi",
      "Russian",
      "Serbian / Croatian",
      "Sinhala",
      "Somali",
      "Spanish",
      "Swahili",
      "Swedish",
      "Sylheti",
      "Tagalog (Filipino)",
      "Tamil",
      "Thai",
      "Tigrinya",
      "Turkish",
      "Urdu",
      "Vietnamese",
      "Welsh",
      "Yoruba",
      "Other"
    ]
  }

  async onReferralFinished(state: State): Promise<void> {
    await super.onReferralFinished(state)

    if (state.email && state.canSendEmail) {
      await this.sendReferralSubmittedEmail(state)
    }
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadMaydenSABP> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      genderSameAsBirthAssigned: this.getGenderSameAsBirthAssigned(state),
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: this.getName(state),
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      consentDataShare: true,
      consentDataStore: true,
      gpName: state.odsGP?.name ?? state.gp?.name,
      gpPractice: state.odsGP?.name ?? state.gp?.name,
      gpCodeNACS: state.odsGP?.id ?? state.gp?.nacsCode,
      gpCodePIMS: state.gp?.pimsCode,
      // TODO: Sync with Aaron in which property to send CCG Name
      // eslint-disable-next-line
      // @ts-ignore
      ccgName: this.getCCGName(state),
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      referralSource: "Other - please specify",
      referralSourceDetails: state.whereDidYouHearAboutService || undefined,
      title: state.title,
      nhsNumber: state.nhsNumber,
      under18ContactName: state.parentOrGuardianInfo,
      under18ContactPhone: undefined, // 👈 TODO: check how to get this cause we only ask one free text Q
      gender: this.getGender(state),
      nationality: this.getNationality(state),
      ethnicity: this.getEthnicity(state),
      religion: this.getReligion(state),
      language: this.getLanguage(state),
      interpreter: state.requiresInterpreter,
      interpreterLanguage: this.getInterpreterLanguage(state), // 👈 TODO: check how to get this properly cause it's a free text
      sexuality: this.getSexuality(state),
      civilStatus: this.getCivilStatus(state),
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      ltcOtherDetails: state.longTermConditionOther,
      covidStatus: state.covidStatus,
      covidDate: state.covidDate,
      disability: this.getDisability(state),
      asd: state.hasASD,
      adhd: state.hasADHD,
      armedForces: this.getArmedForce(state),
      pregnant: state.hasChildOrIsPregnant,
      childUnder18: state.teenChildren,
      childUnder18Details: state.teenChildrenInfo,
      childUnder18LiveInHousehold: state.teenChildrenInHousehold,
      agenciesInvolvedInCare: state.otherAgenciesInYourCare,
      agenciesInvolvedInCareDetails: state.otherAgenciesInYourCareInfo,
      isCarer: state.registeredCarer,
      isCarerDetails: state.caredForContactInfo,
      substances: !!state.substances && !state.substancesAreMedications,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      medication:
        state.substancesAreMedications && state.medicationInfo //
          ? [state.medicationInfo]
          : undefined,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      medicationForMH: !!state.substances && !!state.substancesAreMedications,
      medicationForMHDetails:
        state.substancesAreMedications && state.medicationInfo //
          ? state.medicationInfo
          : undefined,
      smoke: state.isSmoker,
      smokeDetails: state.smokingQuantity,
      alcohol: state.alcohol,
      alcoholFrequency: this.getAlcoholFrequency(state),
      alcoholQuantity: this.getAlcoholQuantity(state),
      alcoholDetails: state.alcohol
        ? `${state.alcoholQuantity} units ${state.alcoholFrequency}`
        : undefined,
      violentHistory: state.historyOfViolence,
      currentSupport: !!state.currentMHTreatment?.length,
      currentSupportDetails: state.currentMHTreatment as any[],
      pastTreatment: !!state.priorMHTreatment?.length,
      pastTreatmentDetails: state.priorMHTreatment,
      consentResearch: state.consentResearch,
      treatmentExpectation: state.therapyGoal
    }
  }

  getCCGName(state: State): CCGCode | "NOT_FOUND" {
    if (state.odsGP?.id || state.gp?.nacsCode) {
      const gpCode = state.odsGP?.id || state.gp?.nacsCode || "NONE_FOUND"
      const gpCCGCode = gpsSABP[gpCode]
      const keys = Object.keys(CCGCode).filter(x => CCGCode[x] === gpCCGCode)
      return keys.length > 0 ? (keys[0] as CCGCode) : "NOT_FOUND"
    }
    return "NOT_FOUND"
  }

  getGenderSameAsBirthAssigned(state: State): GenderBirthAssigned {
    const genderSameAsBirthAssigned = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirthAssigned ?? "UNKNOWN"
  }

  getGender(state: State): GENDER_MAYDEN_SABP {
    const gender = genders[state.gender!]
    return gender ?? "UNKNOWN"
  }

  getNationality(state: State): NATIONALITY_MAYDEN {
    const map: Record<string, NATIONALITY_MAYDEN> = {
      British: "BRITAIN",
      English: "ENGLAND",
      Irish: "IRELAND",
      Scottish: "SCOTLAND",
      Welsh: "WALES",
      Other: "NOT_LISTED"
    }
    return map[state.nationalIdentity!] ?? "NOT_LISTED"
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_SABP {
    return ethnicities[state.ethnicity!] ?? "UNKNOWN"
  }

  getReligion(state: State): RELIGION_MAYDEN_SABP {
    return religions[state.religion!] ?? "OTHER"
  }

  getLanguage(state: State): LANGUAGE_MAYDEN_SABP {
    return languages[state.primaryLanguage!] ?? "NOT_ANSWERED"
  }

  getInterpreterLanguage(state: State): LANGUAGE_MAYDEN_SABP {
    // This is pointless... the input is either the primary language
    // or a free text...
    // TODO: Need a fix for this
    return languages[state.interpreterLanguage!] ?? "NOT_ANSWERED"
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_SABP {
    return sexualities[state.sexuality!] ?? "UNKNOWN"
  }

  getCivilStatus(state: State): CIVIL_STATUS_MAYDEN_SABP {
    const map: Record<string, CIVIL_STATUS_MAYDEN_SABP> = {
      Single: "SINGLE",
      Married: "MARRIED",
      Divorced: "DIVORCED",
      Widowed: "WIDOWED",
      Separated: "SEPARATED",
      "Co-Habiting": "COHABITING",
      "Long term": "LONG_TERM",
      "Civil Partnership": "CIVIL_PARTNERSHIP",
      "Not Disclosed": "NOT_ANSWERED"
    }
    return map[state.relationshipStatus!] ?? "NOT_ANSWERED"
  }

  getLTC(state: State): LTC_MAYDEN_SABP[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getDisability(state: State): DISABILITY_MAYDEN_SABP {
    return disabilities[state.disability!] ?? "NONE"
  }

  getArmedForce(state: State): ARMED_FORCES_MAYDEN_SABP {
    return exArmedForces[state.isExArmedForces!] ?? "UNKNOWN"
  }

  getAlcoholFrequency(state: State): typeof AF[keyof typeof AF] | undefined {
    const map = {
      [ALCOHOL_FREQUENCIES.MONTHLY]: AF.MONTHLY,
      [ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4]: AF.MONTHLY_2_TO_4,
      [ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3]: AF.WEEKLY_2_TO_3,
      [ALCOHOL_FREQUENCIES.WEEKLY_4]: AF.WEEKLY_4
    }
    return map[state.alcoholFrequency!]
  }

  getAlcoholQuantity(state: State): typeof AQ[keyof typeof AQ] | undefined {
    const map = {
      [ALCOHOL_QUANTITIES._0_2]: AQ._0_2,
      [ALCOHOL_QUANTITIES._3_4]: AQ._3_4,
      [ALCOHOL_QUANTITIES._5_6]: AQ._5_6,
      [ALCOHOL_QUANTITIES._7_9]: AQ._7_9,
      [ALCOHOL_QUANTITIES._10_PLUS]: AQ._10_PLUS
    }
    return map[state.alcoholQuantity!]
  }

  getCustomField(field: keyof State | keyof EligibilityCheckSABPScriptState): any {
    return this.referralStore.getCustomField(field)
  }

  async sendReferralSubmittedEmail(state: State): Promise<void> {
    const name = this.getName(state)
    const to = [state.email || ""]
    const subject = "Welcome to Mind Matters"
    const attachmentUrls = [
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/sabp/Welcome+to+Mind+Matters.pdf"
    ]
    const text = `
      <html lang='en'>
        <body>
          <p>Dear ${name},</p>
          <p>Welcome to <b>${state.iapt?.name}</b>. We have received your referral and will be in contact to book your Initial Assessment with us within the next few days.</p>
          <p>If you have supplied us with your mobile number and permission to send an SMS, we will send you an autobooking link where you can book your appointment from our live availability at your convenience.</p>
          <p>Alternatively, we will call you to arrange this.</p>
          <p>We have put together a booklet to give you some information about what to expect including the therapies, courses and digital packages that we offer. Currently, due to covid, we have very limited face to face appointments and the majority of our appointments are offered via Microsoft Teams or Telephone. Our patients have reported that these are more convenient and preferable to wearing PPE during their therapy session.</p>
          <p>Hopefully, our Welcome Booklet answers any questions you may have, however please do contact us should you need to do so.</p>
          <p>If your initial appointment is not yet booked, do look out for the Auto Booking Text and book at your leisure or you can call us on 0300 330 5450.</p>
          <p>Best Wishes</p>
          <p>Mind Matters Team</p>
        </body>
      </html>
    `
    const emailData = {
      to,
      text,
      subject,
      attachmentUrls
    }
    await sendEmail(emailData)
  }

  getCustomReferralType(state: State): string | undefined {
    const currentMHTreatment = this.referralStore.getCustomField("currentMHTreatment")
    const hasCurrentMHTreatment = !!currentMHTreatment?.length
    if (hasCurrentMHTreatment) return "Extended Assessment (User seeing other MH professionals)"
  }

  getReferralTypeForRisk(_state: State): string | undefined {
    if (this.clinicalStore.isHighRisk) return ReferralType.RISK_PATIENT
    if (this.clinicalStore.isModerateRisk) return ReferralType.RISK_PATIENT
  }
}

/* istanbul ignore next */
export default class SelfReferralSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralSABP
  readonly name: string = "SelfReferralSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralSABPDialogue.id, new SelfReferralSABPScript(), state, snapshot)
  }
}
