import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = AssessmentPitchIAPTScriptState
export type AssessmentPitchTrentPTSState = State
export class AssessmentPitchTrentPTSScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchTrentPTSScript"

  /** Script Steps */

  @step.logState
  checkForNeedToBeCalled(d: IStepData<State>): IStepResult {
    return { nextStep: this.sayWeCanSpeedUpReferral }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Well ${name}, I've actually got something more I can offer you`,
        "With some more information, I can match you up to a therapist that can treat you best"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sounds interesting 🤔", value: true },
          { body: "Okay", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWeCanSpeedUpReferral
    }
  }

  @step.logState
  askWannaDoQuestionnaires(d: IStepData<State>): IStepResult {
    return {
      body: [
        "To do this, we need to go through a few more questions together",
        "It should take no more than 15 minutes 😊",
        "What do you think? Shall we go through the questions together now?"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoQuestionnaires"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sure!", value: "sure" },
          { body: "Okay... If it helps me find a better matched therapist", value: "okay" }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaDoQuestionnaires
    }
  }
}

/* istanbul ignore next */
export default class AssessmentPitchTrentPTSDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchTrentPTS
  readonly name: string = "AssessmentPitchTrentPTSDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchTrentPTSDialogue.id, new AssessmentPitchTrentPTSScript(), state, snapshot)
  }
}
