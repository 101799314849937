import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import { ReferralType } from "../../../models/Constants"
import type { GoodbyeScriptState } from "./GoodbyeScript"

type State = GoodbyeScriptState
export type GoodbyeSABPScriptState = State

export class GoodbyeSABPScript extends GoodbyeScript {
  readonly name: string = "GoodbyeSABPScript"

  /** Generic Handlers */

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return `Because you identified as being in crisis, I have let ${iaptName} know in the referral. Remember, for life threatening medical help you can call 999 or the Mental Health Crisis Helpline on 0800 915 4644 if it's not a life-threatening situation`
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return `Because you identified as being in crisis, I have let ${iaptName} know in the referral. Remember, for life threatening medical help you can call 999 or the Mental Health Crisis Helpline on 0800 915 4644 if it's not a life-threatening situation`
    }
  }

  getCustomReferralType(state: State): string | undefined {
    const currentMHTreatment = this.referralStore.getCustomField("currentMHTreatment")
    const hasCurrentMHTreatment = !!currentMHTreatment?.length
    if (hasCurrentMHTreatment) return "Extended Assessment (User seeing other MH professionals)"
  }

  getReferralTypeForRisk(_state: State): string | undefined {
    if (this.clinicalStore.isHighRisk) return ReferralType.RISK_PATIENT
    if (this.clinicalStore.isModerateRisk) return ReferralType.RISK_PATIENT
  }
}

/* istanbul ignore next */
export default class GoodbyeSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeSABP
  readonly name: string = "GoodbyeSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeSABPDialogue.id, new GoodbyeSABPScript(), state, snapshot)
  }
}
