import {
  CollectPhoneNumberScript,
  CollectPhoneNumberScriptState
} from "./CollectPhoneNumberDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"

type State = CollectPhoneNumberScriptState
export type CollectPhoneNumberEssexScriptState = State

export class CollectPhoneNumberEssexScript extends CollectPhoneNumberScript {
  readonly name: string = "CollectPhoneNumberEssexScript"

  /** Script Steps */

  @step.logState
  askPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "And what's the best mobile phone number to reach you on?",
        "Please note, that a mobile phone number is required to continue with your referral",
        "We only use these details to contact you in relation to your treatment"
      ],
      nextStep: this.showPhoneNumberPrompt
    }
  }

  /**
   * We're overwriting this method because the prompt for
   * essex needs to enforce a mobile phone number and at
   * the same time give the option to say they don't have
   * a mobile phone number
   */
  @step.logState
  showPhoneNumberPrompt(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPhoneNumberPrompt"),
        type: "inlinePicker",
        choices: [{ body: "I don't have a mobile phone", value: false }],
        phonePrompt: {
          forceMobile: true,
          placeholder: "Your mobile phone number"
        },
        isUndoAble: true
      },
      nextStep: this.handlePhoneNumber
    }
  }

  /**
   * We're overwriting this method because the input coming in
   * is not just a string as in the base method, but it can
   * also be a boolean, so we need to declare a different type
   * as well as handle each type differently
   */
  @step.logState
  @step.checkInputForCrisis({
    getInput: (d: IStepData<State, string | boolean>) => {
      // 👇 This fixes the Crisis Detection error
      // "crisis detection input must be a string"
      return typeof d.response === "boolean" ? "" : d.response
    },
    getNextStep: (s: CollectPhoneNumberEssexScript) => s.returnToAskPhoneNumber
  })
  async handlePhoneNumber(d: IStepData<State, string | boolean>): Promise<IStepResult> {
    if (typeof d.response === "boolean") {
      this.setPeople({ phoneNumber: "NONE" })
      return { nextStep: this.sayMobilePhoneIsRequired }
    }

    // 👇we know here that d.response is string
    return await super.handlePhoneNumber(d as IStepData<State, string>)
  }

  /**
   * The only reason for these next two steps is to add an intermediate
   * step between the user saying they don't have a mobile phone and
   * having to close this ad-hoc dialogue for the eligibility check to
   * take over, so that the user has the opportunity to change their
   * mind and click undo on the above step, or select for another
   * chance to type their phone number
   */
  @step.logState
  sayMobilePhoneIsRequired(_d: IStepData<State>): IStepResult {
    return {
      body: "Unfortunately I can not continue without a mobile phone number",
      prompt: {
        id: this.getPromptId("sayMobilePhoneIsRequired"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: false },
          { body: "I changed my mind", value: true }
        ]
      },
      nextStep: this.handleMobilePhoneIsRequired
    }
  }

  @step.logState
  handleMobilePhoneIsRequired(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { body: "Sure", nextStep: this.sayPleaseTypePhoneNumber }
    }
    return { nextStep: this.end }
  }
}

export default class CollectPhoneNumberEssexDialogue extends AdHocDialogue<
  State,
  CollectPhoneNumberEssexScript
> {
  static id = DialogueIDs.CollectPhoneNumberEssex
  readonly name: string = "CollectPhoneNumberEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CollectPhoneNumberEssexDialogue.id, new CollectPhoneNumberEssexScript(), state, snapshot)
  }
}
