import { action } from "mobx"
import Loggable from "../../models/Loggable"
import ChatStore from "./chatStore"
import BotStore from "./botStore"
import ApplicationStore from "./applicationStore"
import ConfigStore from "./configStore"
import DiscussionStore from "./discussionStore"
import ClinicalStore from "./clinicalStore"
import ReferralStore from "./referralStore"
import DataPointsStore from "./dataPointsStore"
import WellbeingHubStore from "./wellbeingHubStore"
import { DiscussionSteps } from "../../models/Constants"
import type { IDataPoints } from "@limbic/types"
import type { ACCESS_INSTANCES } from "@limbic/types"

export default class RootStore extends Loggable {
  static _instance: RootStore

  /** Static Methods */

  static getInstance(): RootStore {
    if (!this._instance) {
      this._instance = new RootStore()
    }
    return this._instance
  }

  /** Static Getters / Setters */

  static get ChatStore(): ChatStore {
    return this.getInstance().chatStore
  }

  static get BotStore(): BotStore {
    return this.getInstance().botStore
  }

  static get ApplicationStore(): ApplicationStore {
    return this.getInstance().applicationStore
  }

  static get ConfigStore(): ConfigStore {
    return this.getInstance().configStore
  }

  static get DiscussionStore(): DiscussionStore {
    return this.getInstance().discussionStore
  }

  static get ClinicalStore(): ClinicalStore {
    return this.getInstance().clinicalStore
  }

  static get ReferralStore(): ReferralStore {
    return this.getInstance().referralStore
  }

  static get WellbeingHubStore(): WellbeingHubStore {
    return this.getInstance().wellbeingHubStore
  }

  static get DataPointsStore(): DataPointsStore {
    return this.getInstance().dataPointsStore
  }

  /** Instance Members */

  readonly name: string = "RootStore"
  readonly chatStore: ChatStore
  readonly botStore: BotStore
  readonly applicationStore: ApplicationStore
  readonly configStore: ConfigStore
  readonly discussionStore: DiscussionStore
  readonly clinicalStore: ClinicalStore
  readonly referralStore: ReferralStore
  readonly dataPointsStore: DataPointsStore
  readonly wellbeingHubStore: WellbeingHubStore

  constructor() {
    super()
    this.chatStore = new ChatStore()
    this.dataPointsStore = new DataPointsStore()
    this.botStore = new BotStore(this.chatStore)
    this.applicationStore = new ApplicationStore()
    this.configStore = new ConfigStore()
    this.discussionStore = new DiscussionStore()
    this.clinicalStore = new ClinicalStore()
    this.referralStore = new ReferralStore()
    this.wellbeingHubStore = new WellbeingHubStore()
    this.setup()
  }

  setup(): void {
    this.botStore.getUserName = this._getUserName.bind(this)
    this.botStore.getDiscussionStarted = this._getDiscussionStarted.bind(this)
    this.botStore.onDataPointsReceived = this._onDataPointsReceived.bind(this)
    this.referralStore.onReferralSubmitted = this._onReferralSubmitted.bind(this)
    this.referralStore.getInstanceID = this._getInstanceID.bind(this)
    this.dataPointsStore.getSignupCode = this._getSignupCode.bind(this)
    this.dataPointsStore.getPatientID = this._getPatientID.bind(this)
  }

  onAppLaunch(): void {
    this.logBreadcrumb("onAppLaunch")
    try {
      this.botStore.setup()
      void this.referralStore.updateSyncAt()
    } catch (e) {
      this.logException(e, "onAppLaunch")
    }
  }

  private _getUserName(): string | undefined {
    return this.applicationStore.username
  }

  private _getDiscussionStarted(): boolean {
    return this.discussionStore.started
  }

  private _onDataPointsReceived(dataPoints: IDataPoints): void {
    return this.dataPointsStore.addDataPoints(dataPoints)
  }

  _getInstanceID(): undefined | keyof typeof ACCESS_INSTANCES {
    return this.configStore.backendInstanceID
  }

  _onReferralSubmitted(): void {
    this.referralStore.setReferralSubmitted(true)
    const discussionIsActive = !!this.botStore.bot?.activeDialogue
    if (discussionIsActive) {
      this.chatStore.freezeDialogueMessages()
      this.referralStore.stopPinging()
      this.referralStore.setSubmissionTime()
      if (this.referralStore.idleSubmissionActive) {
        const GoodbyeDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.Goodbye)
        if (GoodbyeDialogue) {
          const state = this.botStore.bot?.activeDialogue?.state
          this.botStore.bot?.clearDialogues()
          this.botStore.bot?.pushDialogue(
            new GoodbyeDialogue({ ...state, isIdleSubmitted: true }),
            true
          )
        }
      }
    }
  }

  private _getSignupCode(): string | undefined {
    return this.referralStore.signupCode
  }

  private _getPatientID(): string | undefined {
    return this.referralStore.patientId
  }

  @action
  removeAllListeners(): void {
    this.botStore.removeBotListeners()
  }
}

interface WindowWithRootStore extends Window {
  RootStore: typeof RootStore
}

declare let window: WindowWithRootStore

window.RootStore = RootStore
