import { step } from "../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../backend/chatbot/Dialogue"
import BaseScript from "../BaseScript"
import { DialogueIDs } from "../DialogueIDs"
import type { SurveyScriptState } from "../createSurveyDialogue"
import type { IStepData, IStepResult } from "../../backend/chatbot/models/IStep"
import type { IPersistableSurveyResponse, ISurveyResponse } from "../../models/ISurvey"

type State = SurveyScriptState
export type SHAI18ScriptState = State

export class SHAI18Script extends BaseScript<State> {
  readonly name: string = "SHAI18Script"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult<State> {
    this.timeEvent(`${this.name} Finished`)
    return { nextStep: this.goToPart1 }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step
  goToPart1(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Each of the following questions consists of a group of four statements. Please read each group of statements carefully and then select the one which best describes how you have felt over the past six months",
        "If you find that more than one statement applies, please select any of the applicable statements"
      ],
      nextStep: this.step1
    }
  }

  @step
  step1(_d: IStepData<State>): IStepResult {
    // TODO: May need to find a way to remove the question text for this questionnaire...
    const q = "1. Worrying about your health"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "I do not worry about my health", 0),
          getQuestionChoice(q, "I occasionally worry about my health", 1),
          getQuestionChoice(q, "I spend much of my time worrying about my health", 2),
          getQuestionChoice(q, "I spend most of my time worrying about my health", 3)
        ],
        dataPointsName: "SHAI18 - step1"
      },
      nextStep: this.handleStep1
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("1", "shai18Responses")
  handleStep1(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.06)
    return { nextStep: this.step2 }
  }

  @step
  step2(_d: IStepData<State>): IStepResult {
    const q = "2. Noticing aches and pains"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step2"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "I notice aches/pains less than most other people (of my age)", 0),
          getQuestionChoice(q, "I notice aches/pains as much as most other people (of my age)", 1),
          getQuestionChoice(q, "I notice aches/pains more than most other people (of my age)", 2),
          getQuestionChoice(q, "I am aware of aches/pains in my body all the time", 3)
        ],
        dataPointsName: "SHAI18 - step2"
      },
      nextStep: this.handleStep2
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("2", "shai18Responses")
  handleStep2(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.11)
    return { nextStep: this.step3 }
  }

  @step
  step3(_d: IStepData<State>): IStepResult {
    const q = "3. Being aware of bodily sensations"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step3"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "As a rule I am not aware of bodily sensations or changes", 0),
          getQuestionChoice(q, "Sometimes I am aware of bodily sensations or changes", 1),
          getQuestionChoice(q, "I am often aware of bodily sensations or changes", 2),
          getQuestionChoice(q, "I am constantly aware of bodily sensations or changes", 3)
        ],
        dataPointsName: "SHAI18 - step3"
      },
      nextStep: this.handleStep3
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("3", "shai18Responses")
  handleStep3(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.17)
    return { nextStep: this.step4 }
  }

  @step
  step4(_d: IStepData<State>): IStepResult {
    const q = "4. Thoughts of illness"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step4"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Resisting thoughts of illness is never a problem", 0),
          getQuestionChoice(q, "Most of the time I can resist thoughts of illness", 1),
          getQuestionChoice(
            q,
            "I try to resist thoughts of illness but am often unable to do so",
            2
          ),
          getQuestionChoice(
            q,
            "Thoughts of illness are so strong that I no longer even try to resist them",
            3
          )
        ],
        dataPointsName: "SHAI18 - step4"
      },
      nextStep: this.handleStep4
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("4", "shai18Responses")
  handleStep4(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.22)
    return { nextStep: this.step5 }
  }

  @step
  step5(_d: IStepData<State>): IStepResult {
    const q = "5. Fear of serious illness"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step5"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "As a rule I am not afraid that I have a serious illness", 0),
          getQuestionChoice(q, "I am sometimes afraid that I have a serious illness", 1),
          getQuestionChoice(q, "I am often afraid that I have a serious illness", 2),
          getQuestionChoice(q, "I am always afraid that I have a serious illness", 3)
        ],
        dataPointsName: "SHAI18 - step5"
      },
      nextStep: this.handleStep5
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("5", "shai18Responses")
  handleStep5(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.28)
    return { nextStep: this.step6 }
  }

  @step
  step6(_d: IStepData<State>): IStepResult {
    const q = "6. Images of being ill"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step6"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "I do not have images (mental pictures) of myself being ill", 0),
          getQuestionChoice(q, "I occasionally have images of myself being ill", 1),
          getQuestionChoice(q, "I frequently have images of myself being ill", 2),
          getQuestionChoice(q, "I constantly have images of myself being ill", 3)
        ],
        dataPointsName: "SHAI18 - step6"
      },
      nextStep: this.handleStep6
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("6", "shai18Responses")
  handleStep6(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.33)
    return { nextStep: this.step7 }
  }

  @step
  step7(_d: IStepData<State>): IStepResult {
    const q = "7. Thoughts about your health"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step7"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(
            q,
            "I do not have any difficulty taking my mind off thoughts about my health",
            0
          ),
          getQuestionChoice(
            q,
            "I sometimes have difficulty taking my mind off thoughts about my health",
            1
          ),
          getQuestionChoice(
            q,
            "I often have difficulty in taking my mind off thoughts about my health",
            2
          ),
          getQuestionChoice(q, "Nothing can take my mind off thoughts about my health", 3)
        ],
        dataPointsName: "SHAI18 - step7"
      },
      nextStep: this.handleStep7
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("7", "shai18Responses")
  handleStep7(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.39)
    return { nextStep: this.step8 }
  }

  @step
  step8(_d: IStepData<State>): IStepResult {
    const q = "8. The doctor tells you there's nothing wrong"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step8"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(
            q,
            "I am lastingly relieved if my doctor tells me there is nothing wrong",
            0
          ),
          getQuestionChoice(q, "I am initially relieved but the worries sometimes return later", 1),
          getQuestionChoice(q, "I am initially relieved but the worries always return later", 2),
          getQuestionChoice(q, "I am not relieved if my doctor tells me there is nothing wrong", 3)
        ],
        dataPointsName: "SHAI18 - step8"
      },
      nextStep: this.handleStep8
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("8", "shai18Responses")
  handleStep8(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.44)
    return { nextStep: this.step9 }
  }

  @step
  step9(_d: IStepData<State>): IStepResult {
    const q = "9. Hearing about an illness"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step9"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "If I hear about an illness I never think I have it myself", 0),
          getQuestionChoice(q, "If I hear about an illness I sometimes think I have it myself", 1),
          getQuestionChoice(q, "If I hear about an illness I often think I have it myself", 2),
          getQuestionChoice(q, "If I hear about an illness I always think I have it myself", 3)
        ],
        dataPointsName: "SHAI18 - step9"
      },
      nextStep: this.handleStep9
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("9", "shai18Responses")
  async handleStep9(_d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    this.rootStore.applicationStore.setCurrentProgress(0.5)
    return { nextStep: this.step10 }
  }

  @step
  step10(_d: IStepData<State>): IStepResult {
    const q = "10. Having a bodily sensation"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step10"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(
            q,
            "If I have a bodily sensation or change I rarely wonder what it means",
            0
          ),
          getQuestionChoice(
            q,
            "If I have a bodily sensation or change I often wonder what it means",
            1
          ),
          getQuestionChoice(
            q,
            "If I have a bodily sensation or change I always wonder what it means",
            2
          ),
          getQuestionChoice(
            q,
            "If I have a bodily sensation or change I must know what it means",
            3
          )
        ],
        dataPointsName: "SHAI18 - step10"
      },
      nextStep: this.handleStep10
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("10", "shai18Responses")
  async handleStep10(_d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    this.rootStore.applicationStore.setCurrentProgress(0.56)
    return { nextStep: this.step11 }
  }

  @step
  step11(_d: IStepData<State>): IStepResult {
    const q = "11. Risk of developing a serious illness"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step11"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(
            q,
            "I usually feel at very low risk for developing a serious illness",
            0
          ),
          getQuestionChoice(
            q,
            "I usually feel at fairly low risk for developing a serious illness",
            1
          ),
          getQuestionChoice(
            q,
            "I usually feel at moderate risk for developing a serious illness",
            2
          ),
          getQuestionChoice(q, "I usually feel at high risk for developing a serious illness", 3)
        ],
        dataPointsName: "SHAI18 - step11"
      },
      nextStep: this.handleStep11
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("11", "shai18Responses")
  async handleStep11(_d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    this.rootStore.applicationStore.setCurrentProgress(0.61)
    return { nextStep: this.step12 }
  }

  @step
  step12(_d: IStepData<State>): IStepResult {
    const q = "12. Thoughts of having a serious illness"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step12"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "I never think I have a serious illness", 0),
          getQuestionChoice(q, "I sometimes think I have a serious illness", 1),
          getQuestionChoice(q, "I often think I have a serious illness", 2),
          getQuestionChoice(q, "I usually think that I am seriously ill", 3)
        ],
        dataPointsName: "SHAI18 - step12"
      },
      nextStep: this.handleStep12
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("12", "shai18Responses")
  async handleStep12(_d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    this.rootStore.applicationStore.setCurrentProgress(0.67)
    return { nextStep: this.step13 }
  }

  @step
  step13(_d: IStepData<State>): IStepResult {
    const q = "13. Noticing an unexplained bodily sensation"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step13"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(
            q,
            "If I notice an unexplained bodily sensation I don't find it difficult to think about other things",
            0
          ),
          getQuestionChoice(
            q,
            "If I notice an unexplained bodily sensation I sometimes find it difficult to think about other things",
            1
          ),
          getQuestionChoice(
            q,
            "If I notice an unexplained bodily sensation I often find it difficult to think about other things",
            2
          ),
          getQuestionChoice(
            q,
            "If I notice an unexplained bodily sensation I always find it difficult to think about other things",
            3
          )
        ],
        dataPointsName: "SHAI18 - step13"
      },
      nextStep: this.handleStep13
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("13", "shai18Responses")
  async handleStep13(_d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    this.rootStore.applicationStore.setCurrentProgress(0.72)
    return { nextStep: this.step14 }
  }

  @step
  step14(_d: IStepData<State>): IStepResult {
    const q = "14. Worrying about your health"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step14"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(
            q,
            "My family/friends would say I do not worry enough about my health",
            0
          ),
          getQuestionChoice(
            q,
            "My family/friends would say I have a normal attitude to my health",
            1
          ),
          getQuestionChoice(q, "My family/friends would say I worry too much about my health", 2),
          getQuestionChoice(q, "My family/friends would say I am a hypochondriac", 3)
        ],
        dataPointsName: "SHAI18 - step14"
      },
      nextStep: this.handleStep14
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("14", "shai18Responses")
  async handleStep14(_d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    this.rootStore.applicationStore.setCurrentProgress(0.78)
    return { nextStep: this.goToPart2 }
  }

  @step
  goToPart2(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "For the following questions, please think about what it might be like if you had a serious illness of a type which particularly concerns you (such as heart disease, cancer, multiple sclerosis, etc)",
        "Obviously you cannot know for certain what it would be like; but please give your best estimate of what you think might happen, basing your estimate on what you know about yourself and serious illness in general"
      ],
      nextStep: this.step15
    }
  }

  @step
  step15(_d: IStepData<State>): IStepResult {
    const q = "15. Serious illness and enjoying things"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step15"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(
            q,
            "If I had a serious illness I would still be able to enjoy things in my life quite a lot",
            0
          ),
          getQuestionChoice(
            q,
            "If I had a serious illness I would still be able to enjoy things in my life a little",
            1
          ),
          getQuestionChoice(
            q,
            "If I had a serious illness I would be almost completely unable to enjoy things in my life",
            2
          ),
          getQuestionChoice(
            q,
            "If I had a serious illness I would be completely unable to enjoy life at all",
            3
          )
        ],
        dataPointsName: "SHAI18 - step15"
      },
      nextStep: this.handleStep15
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("15", "shai18Responses")
  async handleStep15(_d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    this.rootStore.applicationStore.setCurrentProgress(0.83)
    return { nextStep: this.step16 }
  }

  @step
  step16(_d: IStepData<State>): IStepResult {
    const q = "16. Serious illness and modern medicine"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step16"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(
            q,
            "If I developed a serious illness there is a good chance that modern medicine would be able to cure me",
            0
          ),
          getQuestionChoice(
            q,
            "If I developed a serious illness there is a moderate chance that modern medicine would be able to cure me",
            1
          ),
          getQuestionChoice(
            q,
            "If I developed a serious illness there is a very small chance that modern medicine would be able to cure me",
            2
          ),
          getQuestionChoice(
            q,
            "If I developed a serious illness there is no chance that modern medicine would be able to cure me",
            3
          )
        ],
        dataPointsName: "SHAI18 - step16"
      },
      nextStep: this.handleStep16
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("16", "shai18Responses")
  async handleStep16(_d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    this.rootStore.applicationStore.setCurrentProgress(0.89)
    return { nextStep: this.step17 }
  }

  @step
  step17(_d: IStepData<State>): IStepResult {
    const q = "17. Serious illness and aspects of your life"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step17"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "A serious illness would ruin some aspects of my life", 0),
          getQuestionChoice(q, "A serious illness would ruin many aspects of my life", 1),
          getQuestionChoice(q, "A serious illness would ruin almost every aspect of my life", 2),
          getQuestionChoice(q, "A serious illness would ruin every aspect of my life", 3)
        ],
        dataPointsName: "SHAI18 - step17"
      },
      nextStep: this.handleStep17
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("17", "shai18Responses")
  async handleStep17(_d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    this.rootStore.applicationStore.setCurrentProgress(0.94)
    return { nextStep: this.step18 }
  }

  @step
  step18(_d: IStepData<State>): IStepResult {
    const q = "18. Serious illness and dignity"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step18"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(
            q,
            "If I had a serious illness I would not feel that I had lost my dignity",
            0
          ),
          getQuestionChoice(
            q,
            "If I had a serious illness I would feel that I had lost a little of my dignity",
            1
          ),
          getQuestionChoice(
            q,
            "If I had a serious illness I would feel that I had lost quite a lot of my dignity",
            2
          ),
          getQuestionChoice(
            q,
            "If I had a serious illness I would feel that I had totally lost my dignity",
            3
          )
        ],
        dataPointsName: "SHAI18 - step18"
      },
      nextStep: this.handleStep18
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("18", "shai18Responses")
  async handleStep18(_d: IStepData<State, ISurveyResponse>): Promise<IStepResult> {
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.handleSHAI18Score }
  }

  @step.logState
  async handleSHAI18Score(d: IStepData<State>): Promise<IStepResult> {
    // TODO: Setup cutoffs and score calculation
    // const total = this.getPHQ9Total(d.state)!
    // if (total >= 10 && total <= 14) {
    //   this.rootStore.clinicalStore.addPrimaryProblem(ProblemCategories.M_Depression)
    // }
    // if (total >= 15 && total <= 19) {
    //   this.rootStore.clinicalStore.addPrimaryProblem(ProblemCategories.MS_Depression)
    // }
    // if (total >= 20) {
    //   this.rootStore.clinicalStore.addPrimaryProblem(ProblemCategories.S_Depression)
    // }
    // if (d.state.php9q9Score! >= 1) {
    //   const result = await this.onHandleRiskQ9Score(d.state)
    //   if (result) return result
    // }
    const name = this.getName(d.state)
    return { body: `Thanks for sharing ${name}`, nextStep: this.end }
  }

  saveResponse<T extends IPersistableSurveyResponse>(item: T, state: State): void {
    super.saveResponse(item, state, "shai18Responses")
  }
}

export default class SHAI18Dialogue extends Dialogue<State> {
  static id = DialogueIDs.SHAI18
  readonly name: string = "survey:SHAI18 Dialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SHAI18Dialogue.id, new SHAI18Script(), state, snapshot)
  }
}

function getQuestionChoice(title: string, answer: string, points: number) {
  return {
    body: answer,
    value: {
      title,
      answer,
      points
    }
  }
}
