import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PeaceOfMindIAPTScript } from "./PeaceOfMindIAPTDialogue"
import type { PeaceOfMindIAPTScriptState } from "./PeaceOfMindIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = PeaceOfMindIAPTScriptState

export class PeaceOfMindVitalityScript extends PeaceOfMindIAPTScript {
  readonly name: string = "PeaceOfMindVitalityScript"

  /** Script Steps */

  @step.logState
  sayItsConfidential(_d: IStepData<State>): IStepResult {
    // If in future we are able to check details of what kind of
    // cover someone has, this peace of mind will go into exactly
    // what cover the user has (as apparently people need some
    // reassurance around the fact that this isn't going to cost them)
    return {
      body: "Fantastic! You're in the right place 👌",
      prompt: {
        id: this.getPromptId("sayItsConfidential"),
        type: "inlinePicker",
        choices: [{ body: "Nice 👍" }, { body: "Good" }]
      },
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class PeaceOfMindVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.PeaceOfMindVitality
  readonly name: string = "PeaceOfMindVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PeaceOfMindVitalityDialogue.id, new PeaceOfMindVitalityScript(), state, snapshot)
  }
}
