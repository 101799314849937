import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState,
  EligibilityCheckWithPDSScriptStateSchema
} from "./EligibilityCheckWithPDSScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IAPTIDs } from "../../../models/IIAPTService"

interface State extends EligibilityCheckWithPDSScriptState {
  requiresUrgentSupport?: boolean
}

export type EligibilityCheckInsightScriptState = State

export const EligibilityCheckInsightScriptStateSchema =
  EligibilityCheckWithPDSScriptStateSchema.extend({ requiresUrgentSupport: z.boolean().optional() })

export class EligibilityCheckInsightScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckInsightScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askRequiresUrgentSupport }
  }

  @step
  sayINeedToAskSomeDetails(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `In order to refer you to ${organisationName}, I need to confirm your details`,
        "You can re-type your answer at any point by clicking the 'Undo' button next to the message you want to change"
      ],
      nextStep: this.askBirthday
    }
  }

  @step.logState
  askRequiresUrgentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you currently feel at risk of harming yourself, or can't keep yourself safe?",
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckInsightScript) => {
    d.state.requiresUrgentSupport = d.response
    script.referralStore.setCustomField<State>("requiresUrgentSupport", d.response)
  })
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.setRiskLevelHigh(d.state, "User said they require urgent support")
    }
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.askReceivedPreviousHelp
    }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: [
        `${name}, you have indicated you are in crisis and need urgent help`,
        `${serviceName} is not a crisis service. If you need urgent, life-threatening medical help please call 999`,
        "If the help you need is urgent but not life-threatening, follow this [link](https://www.insightiapt.org/need-urgent-help/) to find out about other services, including your local mental health crisis team that will be able to support you",
        "Please make sure you contact your local crisis team or 999 as soon as possible so they can support you"
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999 and service")
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  askReceivedPreviousHelp(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName

    return {
      body: `Have you been discharged from treatment by ${iaptName} within the last 12 weeks or have you had a clinical assessment with ${iaptName} within the last 4 weeks?`,
      prompt: {
        id: this.getPromptId("askReceivedPreviousHelp"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleReceivedPreviousHelp
    }
  }

  @step.logState
  handleReceivedPreviousHelp(d: IStepData<State, string>): IStepResult {
    if (d.response) {
      this.setEligibility(d.state, false)
      return { nextStep: this.sayWeCantOfferYouService }
    }
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  sayWeCantOfferYouService(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: [
        `Sorry ${name}, you're unable to re-refer to ${organisationName} if you've been discharged from the service within 12 weeks or have received a clinical assessment from the service within 4 weeks`,
        "You can re-refer after this time period",
        "In the meantime contact your GP if you need additional support",
        "If you feel that you or someone you know is in danger, please call 999 immediately"
      ],
      prompt: {
        id: this.getPromptId("sayWeCantOfferYouService"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "I understand" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayItsImportantToFindGP(_d: IStepData<State>): IStepResult {
    return {
      body: "It's important that we identify your GP in order to find the right mental health service for you",
      nextStep: this.sayWithoutGPICannotReferYou
    }
  }

  @step
  checkEligibility(d: IStepData<State>): IStepResult {
    const ineligibleInsightIAPTs: any[] = [IAPTIDs.INSIGHT_BASSETLAW, IAPTIDs.INSIGHT_NOTTS]
    if (ineligibleInsightIAPTs.includes(d.state.iapt?.id)) {
      return { nextStep: this.signpostBassetlawOrNotts }
    }
    return super.checkEligibility(d)
  }

  @step.logState
  signpostBassetlawOrNotts(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "From 1st April, Everyturn Mental Health (Formally known as Insight IAPT) talking therapies in Nottingham, Nottinghamshire and Bassetlaw is changing to a new service: NHS Nottingham and Nottinghamshire Talking Therapies",
        "This new service will be provided through a partnership of ourselves Everyturn and Vita Health Group.",
        "Our online referral system will therefore be closed for those areas, and will reopen on 1st April.",
        "You can access support via Samaritans on 116 123, Saneline on 0300 304 7000 or text SHOUT to 85258 and contact your GP.",
        "In the event of an emergency, or if unable to keep yourself safe contact the 24/7 Crisis Line on 0300 303 0165, NHS 111 Option 2, or call 999 / attend your local A&E department."
      ],
      nextStep: this.end,
      clearStack: true
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return EligibilityCheckInsightScriptStateSchema
  }
}

/* istanbul ignore next */
export default class EligibilityCheckInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckInsight
  readonly name: string = "EligibilityCheckInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckInsightDialogue.id, new EligibilityCheckInsightScript(), state, snapshot)
  }
}
