import Logger from "../utils/Logger"
import { version } from "../../package.json"
import Loggable from "../models/Loggable"

const dist = process.env.REACT_APP_DIST
const dsn = "https://81e0fe6d52154e3ebeadfb175cb0d5e8@o138988.ingest.sentry.io/5392826"
const allowUrls = undefined

Logger.setup(version, dsn, dist, allowUrls)
const loggerSingleton = Logger.getInstance()
Loggable.setLogger(loggerSingleton)
