import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

export const iaptGPMapItalk: IAPTGPMap = {
  J82622: IAPTIDs.ITALK,
  J82005: IAPTIDs.ITALK,
  J82006: IAPTIDs.ITALK,
  J82007: IAPTIDs.ITALK,
  J82008: IAPTIDs.ITALK,
  J82009: IAPTIDs.ITALK,
  J82010: IAPTIDs.ITALK,
  J82012: IAPTIDs.ITALK,
  J82016: IAPTIDs.ITALK,
  J82017: IAPTIDs.ITALK,
  J82018: IAPTIDs.ITALK,
  J82019: IAPTIDs.ITALK,
  J82020: IAPTIDs.ITALK,
  J82021: IAPTIDs.ITALK,
  J82023: IAPTIDs.ITALK,
  J82025: IAPTIDs.ITALK,
  J82026: IAPTIDs.ITALK,
  J82033: IAPTIDs.ITALK,
  J82034: IAPTIDs.ITALK,
  J82035: IAPTIDs.ITALK,
  J82036: IAPTIDs.ITALK,
  J82039: IAPTIDs.ITALK,
  J82041: IAPTIDs.ITALK,
  J82042: IAPTIDs.ITALK,
  J82044: IAPTIDs.ITALK,
  J82050: IAPTIDs.ITALK,
  J82051: IAPTIDs.ITALK,
  J82053: IAPTIDs.ITALK,
  J82056: IAPTIDs.ITALK,
  J82058: IAPTIDs.ITALK,
  J82059: IAPTIDs.ITALK,
  J82061: IAPTIDs.ITALK,
  J82063: IAPTIDs.ITALK,
  J82064: IAPTIDs.ITALK,
  J82069: IAPTIDs.ITALK,
  J82071: IAPTIDs.ITALK,
  J82072: IAPTIDs.ITALK,
  J82074: IAPTIDs.ITALK,
  J82075: IAPTIDs.ITALK,
  J82077: IAPTIDs.ITALK,
  J82079: IAPTIDs.ITALK,
  J82082: IAPTIDs.ITALK,
  J82083: IAPTIDs.ITALK,
  J82084: IAPTIDs.ITALK,
  J82089: IAPTIDs.ITALK,
  J82094: IAPTIDs.ITALK,
  J82098: IAPTIDs.ITALK,
  J82103: IAPTIDs.ITALK,
  J82104: IAPTIDs.ITALK,
  J82106: IAPTIDs.ITALK,
  J82112: IAPTIDs.ITALK,
  J82116: IAPTIDs.ITALK,
  J82119: IAPTIDs.ITALK,
  J82121: IAPTIDs.ITALK,
  J82124: IAPTIDs.ITALK,
  J82129: IAPTIDs.ITALK,
  J82130: IAPTIDs.ITALK,
  J82131: IAPTIDs.ITALK,
  J82132: IAPTIDs.ITALK,
  J82133: IAPTIDs.ITALK,
  J82134: IAPTIDs.ITALK,
  J82136: IAPTIDs.ITALK,
  J82138: IAPTIDs.ITALK,
  J82139: IAPTIDs.ITALK,
  J82143: IAPTIDs.ITALK,
  J82144: IAPTIDs.ITALK,
  J82145: IAPTIDs.ITALK,
  J82146: IAPTIDs.ITALK,
  J82147: IAPTIDs.ITALK,
  J82150: IAPTIDs.ITALK,
  J82151: IAPTIDs.ITALK,
  J82152: IAPTIDs.ITALK,
  J82154: IAPTIDs.ITALK,
  J82156: IAPTIDs.ITALK,
  J82157: IAPTIDs.ITALK,
  J82161: IAPTIDs.ITALK,
  J82163: IAPTIDs.ITALK,
  J82164: IAPTIDs.ITALK,
  J82169: IAPTIDs.ITALK,
  J82174: IAPTIDs.ITALK,
  J82184: IAPTIDs.ITALK,
  J82188: IAPTIDs.ITALK,
  J82192: IAPTIDs.ITALK,
  J82196: IAPTIDs.ITALK,
  J82197: IAPTIDs.ITALK,
  J82201: IAPTIDs.ITALK,
  J82210: IAPTIDs.ITALK,
  J82214: IAPTIDs.ITALK,
  J82215: IAPTIDs.ITALK,
  J82216: IAPTIDs.ITALK,
  J82218: IAPTIDs.ITALK,
  J82220: IAPTIDs.ITALK,
  J82625: IAPTIDs.ITALK,
  J82633: IAPTIDs.ITALK,
  J82639: IAPTIDs.ITALK,
  J82640: IAPTIDs.ITALK,
  J82646: IAPTIDs.ITALK,
  J82669: IAPTIDs.ITALK,
  Y01281: IAPTIDs.ITALK
}
