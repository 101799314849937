import Dialogue, { IDialogueSnapshot } from "./Dialogue"
import IMessage, { IBotMessage } from "./models/IMessage"
import { Disposable, Listener, TypedEvent } from "./TypedEvent"
import uuidv4 from "./utils/uuidv4"
import isNullOrUndefined from "../../utils/isNullOrUndefined"
import delay from "../../utils/delay"
import Loggable from "../../models/Loggable"
import invariant from "../../utils/invariant"
import { IStepResult } from "./models/IStep"

export interface IBotSnapshot {
  version: number
  didStart: boolean
  dialogues: Array<IDialogueSnapshot<unknown>>
}

export type DialogueHydrator = (dialogueIdentifier: string, snapshot: IDialogueSnapshot) => Dialogue

interface WindowWithLimbicNameSpace extends Window {
  MAX_TYPING_TIME: number
  MIN_TYPING_TIME: number
  BOT_THINKING_TIME: number
}

declare let window: WindowWithLimbicNameSpace

type BotEvent = "typing" | "newMessage" | "dialogueError" | "dialoguePushed" | "dialogueRemoved"

export default class Bot extends Loggable {
  static fromSnapshot(snapshot: IBotSnapshot, dialogueHydrator: DialogueHydrator): Bot {
    const dialogues = snapshot.dialogues.map(d => dialogueHydrator(d.identifier, d))
    const bot = new Bot()
    for (const dialogue of dialogues) {
      bot.pushDialogue(dialogue, false)
    }
    bot.didStart = snapshot.didStart
    return bot
  }

  readonly name: string = "Bot"
  readonly events = {
    typing: new TypedEvent<boolean>(),
    newMessage: new TypedEvent<IBotMessage>(),
    stepStarted: new TypedEvent<number>(),
    stepFinished: new TypedEvent<void>(),
    dialogueError: new TypedEvent<Error>(),
    dialoguePushed: new TypedEvent<Dialogue>(),
    dialogueRemoved: new TypedEvent<Dialogue>()
  }
  private didStart = false
  private handlingStepEnd = false
  dialogues: Dialogue[] = []

  constructor(rootDialogue?: Dialogue) {
    super()

    this.setMessageDelay(
      Number(process.env.REACT_APP_MAX_TYPING_TIME ?? 5),
      Number(process.env.REACT_APP_MIN_TYPING_TIME ?? 1),
      Number(process.env.REACT_APP_BOT_THINKING_TIME ?? 0.3)
    )

    if (rootDialogue) {
      this.pushDialogue(rootDialogue, false)
    }
  }

  addListener(event: BotEvent, listener: Listener<any>): Disposable {
    invariant(this.events[event], `Event [${event}] is not supported`)
    return this.events[event].addListener(listener)
  }

  removeListener(event: BotEvent, listener: Listener<any>): void {
    invariant(this.events[event], `Event [${event}] is not supported`)
    this.events[event].removeListener(listener)
  }

  start(): void {
    if (this.didStart) {
      return
    }

    if (this.activeDialogue) {
      this.activeDialogue.init().catch(e => this.logException(e, "start activeDialogue.init"))
      this.didStart = true
    } else {
      throw new Error("Cannot start because there are no dialogues on the stack")
    }
  }

  respond(body?: string, value?: unknown): void {
    try {
      this.activeDialogue?.onReceiveResponse(value !== undefined ? value : body)
    } catch (error) {
      this.events.dialogueError.emit(error)
    }
  }

  rewind(message?: IBotMessage): void {
    if (!message?._meta?.rewindData) {
      throw new Error("No rewind data found for preceding bot message")
    }

    // If the dialogue of the message we're undoing is still in the stack
    // then find its index and discard all dialogues in the stack that are
    // after it so that the dialogue we want is now the active one again
    const rewindDialogueUUID = message._meta.dialogueUUID
    const dialogueIndex = this.dialogues.findIndex(d => d.uuid === rewindDialogueUUID)
    if (dialogueIndex >= 0) {
      const dialoguesToClear = this.dialogues.filter((_, i) => i > dialogueIndex)
      dialoguesToClear.forEach(e => this.removeDialogue(e, false))
    }

    const isOfActiveDialogue = message._meta.dialogueUUID === this.activeDialogue?.uuid
    if (isNullOrUndefined(this.activeDialogue)) {
      throw new Error("Cannot undo a response when there are no dialogues in the stack")
    }
    if (!isOfActiveDialogue) {
      throw new Error("Cannot undo a response that was given outside of the active dialogue")
    }
    this.activeDialogue.rewind(message._meta.rewindData)
  }

  startDialogue(dialogue: Dialogue, clearDialogueStack = false): void {
    if (clearDialogueStack) {
      this.clearDialogues()
    }
    this.pushDialogue(dialogue, true)
  }

  interrupt(): void {
    try {
      this.activeDialogue?.onInterrupt?.(false)
    } catch (error) {
      this.events.dialogueError.emit(error)
    }
  }

  resume(lastMessage?: IMessage): void {
    try {
      this.activeDialogue?.onResume({ lastMessage })
    } catch (error) {
      this.events.dialogueError.emit(error)
    }
  }

  clearDialogues(): void {
    const dialogues = [...this.dialogues]
    dialogues.forEach(e => this.removeDialogue(e, false))
  }

  setMessageDelay(maxTyping: number, minTyping: number, thinking: number): void {
    window.MAX_TYPING_TIME = maxTyping
    window.MIN_TYPING_TIME = minTyping
    window.BOT_THINKING_TIME = thinking
  }

  pushDialogue(dialogue?: Dialogue, runStartStep = true): void {
    if (!dialogue) {
      return
    }
    try {
      this.activeDialogue?.onInterrupt?.(true)
    } catch (error) {
      this.events.dialogueError.emit(error)
    }

    dialogue.onStepStart = () => this.onDialogueStepStart(dialogue)
    dialogue.onStepEnd = (result, isFinished) =>
      this.onDialogueStepEnd(dialogue, result, isFinished)
    dialogue.onError = error => this.onDialogueError(dialogue, error)

    this.dialogues.push(dialogue)

    if (runStartStep) {
      try {
        dialogue.init().catch(e => this.logException(e, "pushDialogue activeDialogue.init"))
      } catch (error) {
        this.events.dialogueError.emit(error)
      }
    }

    this.events.dialoguePushed.emit(dialogue)
  }

  private onDialogueStepStart(dialogue: Dialogue) {
    if (dialogue !== this.activeDialogue) {
      return
    }
    this.events.stepStarted.emit(Date.now())
  }

  private async onDialogueStepEnd(
    dialogue: Dialogue,
    result: IStepResult,
    isFinished: boolean
  ): Promise<void> {
    if (this.handlingStepEnd) {
      this.logMessage("Ran onDialogueStepEnd multiple times on top of another one")
    }
    try {
      this.handlingStepEnd = true
      if (dialogue !== this.activeDialogue) {
        this.logMessage("Ran onDialogueStepEnd on a dialogue that's not the active one")
        this.handlingStepEnd = false
        return
      }

      // If clearStack is passed, then it means we want to
      // continue the conversation but without any previous
      // dialogues in the stack. So if we have a next dialogue
      // it means we want to continue the discussion having
      // only that dialogue so we clear everything and later
      // the new dialogue will be pushed to the stack. But if
      // there is no next dialogue, and the current one is not
      // yet finished then it means that we want to continue
      // the discussion having only the current dialogue so we
      // clear everything and re-add the current dialogue into
      // the stack so that it's the only dialogue in the convo
      if (result.clearStack) {
        this.clearDialogues()
        if (!isFinished && isNullOrUndefined(result.nextDialogue)) {
          this.logBreadcrumb("Cleared dialogues stack and re-added the current")
          this.pushDialogue(dialogue, false)
        }
      }

      const messages = this.messagesFromStepResult(result, dialogue)
      for (let i = 0, { length } = messages; i < length; i++) {
        const message = messages[i]
        const prevMessage = messages[i - 1] || ""
        await delay(window.BOT_THINKING_TIME)
        this.events.typing.emit(true)
        const messageTime = (message?.body?.length || 0) * 0.03
        const prevMessageTime = (prevMessage?.body?.length || 0) * 0.03
        const time = (messageTime + prevMessageTime) / 2
        const delayTime = Math.max(window.MIN_TYPING_TIME, Math.min(window.MAX_TYPING_TIME, time))
        await delay(delayTime)
        this.events.typing.emit(false)
        this.events.newMessage.emit(message)
      }

      if (isFinished) {
        try {
          await dialogue.onFinish(dialogue.state)
        } catch (error) {
          this.events.dialogueError.emit(error)
        }
        this.removeDialogue(dialogue)
      }

      if (!isNullOrUndefined(result.nextDialogue)) {
        if (result.clearStack) {
          this.clearDialogues()
          this.logBreadcrumb("Cleared dialogues stack and re-adding the next one")
        }
        this.pushDialogue(result.nextDialogue, true)
      }
      this.handlingStepEnd = false
    } finally {
      this.events.stepFinished.emit()
    }
  }

  private onDialogueError(_: any, error: Error) {
    this.events.dialogueError.emit(error)
  }

  private removeDialogue(dialogue: Dialogue, resumeNext = true) {
    const lastDialogue = this.activeDialogue
    this.dialogues = this.dialogues.filter(e => e !== dialogue)

    if (this.activeDialogue) {
      try {
        lastDialogue?.onRemove?.(this.activeDialogue)
        if (resumeNext) this.activeDialogue.onResume({ lastDialogue })
      } catch (error) {
        this.events.dialogueError.emit(error)
      }
    } else {
      this.didStart = false
    }

    this.events.dialogueRemoved.emit(dialogue)
  }

  private messagesFromStepResult(result: IStepResult, dialogue: Dialogue): IBotMessage[] {
    if (!result.body && !result.prompt) {
      return []
    }

    // Reason why a message might be hidden
    // is because the message contains only
    // the user prompt and nothing else. For
    // example we send a bot message, but want
    // the prompt to delay for a few seconds more.
    // For that we send the bot message as normal,
    // and in the next step we return only the
    // prompt making sure we delay as much as
    // needed right before we return.
    const bodies = Array.isArray(result.body) ? result.body : [result.body]
    return bodies.map((b, index, array) => {
      const body = typeof b === "string" ? b : undefined
      const attachment = b && typeof b !== "string" ? b : undefined
      const prompt = index === array.length - 1 ? result.prompt : undefined
      return {
        id: uuidv4(),
        author: "bot",
        createdAt: new Date(),
        body,
        attachment,
        prompt,
        isHidden: !body && !attachment,
        isStaticReferralURL: result.isStaticReferralURL ?? false,
        _meta: {
          promptId: result.prompt?.id,
          step: dialogue.currentStep?.stepName || "start",
          dialogueName: dialogue.name,
          dialogueUUID: dialogue.uuid,
          rewindData: result.rewindData
        }
      } as IBotMessage
    })
  }

  /** Getters / Setters */

  /**
   * Returns a snapshot that reflects the current state of the bot.
   * You can serialize and save this snapshot, then use it later to instantiate a Bot from it.
   */
  get snapshot(): IBotSnapshot {
    return {
      version: 1,
      didStart: this.didStart,
      dialogues: this.dialogues //
        .map(e => e.snapshot)
        .filter(e => !!e) as Array<IDialogueSnapshot<unknown>>
    }
  }

  get activeDialogue(): Dialogue | undefined {
    return this.dialogues?.[this.dialogues.length - 1]
  }
}
