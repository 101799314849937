import {
  ARMED_FORCES_MAYDEN_ESSEX,
  DISABILITY_MAYDEN_ESSEX,
  ETHNICITY_MAYDEN_ESSEX,
  GENDER_MAYDEN_ESSEX,
  GenderBirthAssigned,
  LANGUAGE_MAYDEN_ESSEX,
  LTC_MAYDEN_ESSEX,
  NATIONALITY_MAYDEN,
  PERINATAL_MAYDEN_ESSEX,
  RELIGION_MAYDEN_ESSEX,
  SEXUALITY_MAYDEN_ESSEX
} from "@limbic/types"

export const ltcs: Record<string, LTC_MAYDEN_ESSEX> = {
  Diabetes: "DIABETES",
  Respiratory: "RESPIRATORY",
  "Muscular-skeletal": "MUSCULOSKELETAL",
  Skin: "SKIN",
  Cancer: "CANCER",
  "Chronic Obstructive Lung Disease": "COPD",
  "Heart Disease": "HEART_DISEASE",
  Pain: "CHRONIC_PAIN",
  Digestive: "DIGESTIVE",
  Asthma: "ASTHMA",
  "Malignant Neoplastic Disease": "MALIGNANT_NEOPLASTIC_DISEASE",
  Epilepsy: "EPILEPSY",
  Dementia: "DEMENTIA",
  Other: "NOT_LISTED"
}

export const sexualities: Record<string, SEXUALITY_MAYDEN_ESSEX> = {
  Heterosexual: "HETEROSEXUAL",
  "Male homosexual": "HOMOSEXUAL_MALE",
  "Female homosexual": "HOMOSEXUAL_FEMALE",
  "Sexual orientation unknown": "UNKNOWN",
  "Prefer not to say": "NOT_ANSWERED",
  Undecided: "UNSURE",
  "Sexually attracted to neither male nor female": "ASEXUAL"
}

export const genders: Record<string, GENDER_MAYDEN_ESSEX> = {
  "Male (including trans man)": "MALE",
  "Female (including trans woman)": "FEMALE",
  "Non-binary": "NON_BINARY",
  Other: "UNKNOWN",
  "I don't want to say": "NOT_ANSWERED"
}

export const sameGenderAsBirth: Record<string, GenderBirthAssigned> = {
  Yes: "YES",
  No: "NO",
  "I don't know": "UNKNOWN",
  "I don't want to say": "NOT_ANSWERED"
}

export const ethnicities: Record<string, ETHNICITY_MAYDEN_ESSEX> = {
  "White British": "WHITE_BRITISH",
  "White Irish": "WHITE_IRISH",
  "White, any other White background": "WHITE_OTHER",
  "Mixed, White & Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "Mixed, White & Black African": "MIXED_WHITE_BLACK_AFRICAN",
  "Mixed, White & Asian": "MIXED_WHITE_ASIAN",
  "Any other mixed background": "MIXED_OTHER",
  "Asian or Asian British, Indian": "ASIAN_BRITISH_INDIAN",
  "Asian or Asian British, Pakistani": "ASIAN_BRITISH_PAKISTANI",
  "Asian or Asian British, Bangladeshi": "ASIAN_BRITISH_BANGLADESHI",
  "Any other Asian background": "ASIAN_BRITISH_OTHER",
  "Black or Black British, Caribbean": "BLACK_BRITISH_CARIBBEAN",
  "Black or Black British, African": "BLACK_BRITISH_AFRICAN",
  "Any other Black background": "BLACK_BRITISH_OTHER",
  "Other Ethnic Groups, Chinese": "CHINESE",
  "Any other ethinic group": "OTHER",
  "Not stated": "NOT_ANSWERED",
  "Not known": "UNKNOWN"
}

export const religions: Record<string, RELIGION_MAYDEN_ESSEX> = {
  "Not religious": "NONE",
  "Prefer not to say": "DECLINE_TO_RESPOND",
  "Baha'i": "BAHAI",
  Buddhist: "BUDDHIST",
  Christian: "CHRISTIAN",
  Hindu: "HINDU",
  Jain: "JAIN",
  Jewish: "JEWISH",
  Muslim: "MUSLIM",
  Pagan: "PAGAN",
  Zoroastrian: "ZOROASTRIAN",
  "Not known": "UNKNOWN",
  Sikh: "SIKH",
  "Not stated": "NOT_ANSWERED"
}

export const exArmedForces: Record<string, ARMED_FORCES_MAYDEN_ESSEX> = {
  No: "NO",
  "Ex-services member": "YES_EX",
  "Dependent of an ex-services member": "DEPENDENT",
  "Not known/not sure": "UNKNOWN",
  "Don't wish to say": "NOT_ANSWERED"
}

export const disabilities: Record<string, DISABILITY_MAYDEN_ESSEX> = {
  "No perceived difficulty": "NONE",
  "Behavioural and emotional": "BEHAVIOUR",
  Hearing: "HEARING",
  "Manual dexterity": "MANUAL",
  "Memory or ability to concentrate, learn or understand": "LEARNING",
  "Mobility or Gross motor control": "MOTOR",
  "Perception of physical danger": "PERCEPTION",
  "Personal self care / Continence": "SELF_CARE",
  "Progressive condition is HIV": "PROGRESSIVE",
  Sight: "SIGHT",
  "Speech ": "SPEECH",
  Other: "NOT_LISTED",
  "Not stated": "NOT_ANSWERED"
}

export const nationalities: Record<string, NATIONALITY_MAYDEN> = {
  English: "ENGLAND",
  Scottish: "SCOTLAND",
  Irish: "IRELAND",
  Welsh: "WALES",
  British: "BRITAIN",
  Other: "NOT_LISTED"
}
export const perinatalStatuses: Record<string, PERINATAL_MAYDEN_ESSEX> = {
  "None apply": "NONE",
  "Not answered": "NOT_ANSWERED",
  Pregnant: "PREGNANT",
  "Child under 2": "CHILD_UNDER_2",
  "New Parent": "NEW_PARENT"
}

export const languages: Record<string, LANGUAGE_MAYDEN_ESSEX> = {
  English: "ENGLISH",
  "Akan (Ashanti)": "AKAN",
  Albanian: "ALBANIAN",
  Amharic: "AMHARIC",
  Arabic: "ARABIC",
  "British Sign Language": "BRITISH_SIGN_LANGUAGE",
  Croatian: "CROATIAN",
  Dutch: "DUTCH",
  "Farsi (Persian)": "PERSIAN_FARSI",
  Finnish: "FINNISH",
  Flemish: "FLEMISH",
  French: "FRENCH",
  Gaelic: "GAELIC",
  German: "GERMAN",
  Greek: "GREEK",
  Gujarati: "GUJARATI",
  Hausa: "HAUSA",
  Hebrew: "HEBREW",
  Hindi: "HINDI",
  "Igbo (Ibo)": "IGBO",
  Italian: "ITALIAN",
  Japanese: "JAPANESE",
  Korean: "KOREAN",
  Kurdish: "KURDISH",
  Lingala: "LINGALA",
  "Makaton (Sign Language)": "MAKATON_SIGN_LANGUAGE",
  Malayalam: "MALAYALAM",
  Norwegian: "NORWEGIAN",
  "Pashto (Pashtoo)": "PASHTO",
  Polish: "POLISH",
  Portuguese: "PORTUGUESE",
  Punjabi: "PUNJABI",
  Russian: "RUSSIAN",
  Serbian: "SERBIAN",
  Somali: "SOMALI",
  Sinhala: "SINHALA",
  Spanish: "SPANISH",
  Swahili: "SWAHILI",
  Swedish: "SWEDISH",
  "Tagalog (Filipino)": "TAGALOG_FILIPINO",
  Tamil: "TAMIL",
  Thai: "THAI",
  Tigrinya: "TIGRINYA",
  Turkish: "TURKISH",
  Urdu: "URDU",
  Vietnamese: "VIETNAMESE",
  Welsh: "WELSH",
  Yoruba: "YORUBA",
  Other: "NOT_LISTED"
}

const form = {
  filled_by: "API",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  religions: Object.keys(religions),
  genders: Object.keys(genders),
  sameGenderAsBirth: Object.keys(sameGenderAsBirth),
  ethnicities: Object.keys(ethnicities),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs),
  nationalities: Object.keys(nationalities),
  languages: Object.keys(languages)
}

const se_form = {
  form_url: "https://gateway.mayden.co.uk/referral-v2/c9b04112-ef70-4729-af57-0855e04d0ded",
  ...form
}

const ne_form = {
  form_url: "https://gateway.mayden.co.uk/referral-v2/ce5010ed-94df-45ea-a213-669acde1ecd1",
  ...form
}

export { se_form, ne_form }
