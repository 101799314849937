import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { DiscussionSteps } from "../../../models/Constants"

type State = BaseScriptState
export type AssessmentPitchGPScriptState = State

export class AssessmentPitchGPScript extends BaseScript<State> {
  readonly name: string = "AssessmentPitchGPScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    if (d.state.phoneNumber) {
      return { nextStep: this.step1 }
    }
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  goToCollectPhoneNumber(d: IStepData<State>): IStepResult {
    const CollectPhoneNumberDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectPhoneNumber
    )
    const nextDialogue = CollectPhoneNumberDialogue
      ? new CollectPhoneNumberDialogue({ ...d.state })
      : undefined
    return {
      nextDialogue,
      nextStep: this.step1
    }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental illness",
        "I'll then share the results with you and help you find the best support options available"
      ],
      prompt: {
        id: this.getPromptId("step1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: false },
          { body: "Let's do it", value: false },
          { body: "Why is this important?", value: true }
        ]
      },
      nextStep: this.handleStep1
    }
  }

  @step.logState
  handleStep1(d: IStepData<State, boolean>): IStepResult {
    const body = d.response ? "That's a fair question" : "Great!"
    return { body, nextStep: this.sayPitch }
  }

  @step.logState
  sayPitch(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "So, these questions are routinely asked by NHS talk therapy services",
        "(Answering them is actually part of treatment!)",
        "By going through them with me now, I might be able to fast track your care journey and save you waiting time"
      ],
      prompt: {
        id: this.getPromptId("sayPitch"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: true },
          { body: "Hurry up then", value: false }
        ]
      },
      nextStep: this.handlePitch
    }
  }

  @step.logState
  handlePitch(d: IStepData<State, boolean>): IStepResult {
    return {
      body: d.response ? "👍" : "I promise to go as quick as I can 💪",
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class AssessmentPitchGPDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchGP
  readonly name: string = "AssessmentPitchGPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchGPDialogue.id, new AssessmentPitchGPScript(), state, snapshot)
  }
}
