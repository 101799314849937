/* eslint-disable @typescript-eslint/no-empty-function */
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { step } from "../../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import BaseScript, { BaseScriptState } from "../../../BaseScript"
import { ALCOHOL_FREQUENCIES, ALCOHOL_QUANTITIES } from "../../../../models/Constants"

interface State extends BaseScriptState {
  skipFollowUpQuestions?: boolean
}

export type CollectAlcoholConsumptionScriptState = State

export class CollectAlcoholConsumptionScript extends BaseScript<State> {
  readonly name: string = "CollectAlcoholConsumptionScript"

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askAlcohol }
  }

  @step.logState
  askAlcohol(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently using alcohol to manage your mood?",
      prompt: {
        id: this.getPromptId("askAlcohol"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAlcohol"
      },
      nextStep: this.handleAlcohol
    }
  }

  @step.logState
  async handleAlcohol(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.alcohol = d.response
    this.setPeople({ alcohol: d.response })
    const continueWithFollowUp = d.response && !d.state.skipFollowUpQuestions
    return {
      nextStep: continueWithFollowUp //
        ? this.askAlcoholFrequency
        : this.end
    }
  }

  @step.logState
  askAlcoholFrequency(_d: IStepData<State>): IStepResult {
    return {
      body: "How often do you have a drink containing alcohol?",
      prompt: {
        id: this.getPromptId("askAlcoholFrequency"),
        type: "inlinePicker",
        choices: [
          { body: ALCOHOL_FREQUENCIES.MONTHLY },
          { body: ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4 },
          { body: ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3 },
          { body: ALCOHOL_FREQUENCIES.WEEKLY_4 }
        ],
        dataPointsName: "askAlcoholFrequency"
      },
      nextStep: this.handleAlcoholFrequency
    }
  }

  @step.logState
  async handleAlcoholFrequency(d: IStepData<State, ALCOHOL_FREQUENCIES>): Promise<IStepResult> {
    d.state.alcoholFrequency = d.response
    this.setPeople({ alcoholFrequency: d.response })
    return { nextStep: this.askAlcoholQuantity }
  }

  @step.logState
  askAlcoholQuantity(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Ok, now take a look at the following image:",
        {
          type: "imageAttachment",
          image: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/alcohol-units.png"
        },
        "How many units would you have on a typical day when you are drinking alcohol?"
      ],
      prompt: {
        id: this.getPromptId("askAlcoholQuantity"),
        type: "inlinePicker",
        choices: [
          { body: ALCOHOL_QUANTITIES._0_2 },
          { body: ALCOHOL_QUANTITIES._3_4 },
          { body: ALCOHOL_QUANTITIES._5_6 },
          { body: ALCOHOL_QUANTITIES._7_9 },
          { body: ALCOHOL_QUANTITIES._10_PLUS }
        ],
        dataPointsName: "askAlcoholQuantity"
      },
      nextStep: this.handleAlcoholQuantity
    }
  }

  @step.logState
  async handleAlcoholQuantity(d: IStepData<State, ALCOHOL_QUANTITIES>): Promise<IStepResult> {
    d.state.alcoholQuantity = d.response
    this.setPeople({ alcoholQuantity: d.response })
    return { nextStep: this.end }
  }
}

/* istanbul ignore next */
export default class CollectAlcoholConsumptionDialogue extends AdHocDialogue<
  State,
  CollectAlcoholConsumptionScript
> {
  static id = DialogueIDs.CollectAlcoholConsumption
  readonly name: string = "CollectAlcoholConsumptionDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CollectAlcoholConsumptionDialogue.id,
      new CollectAlcoholConsumptionScript(),
      state,
      snapshot
    )
  }
}
