import React, { useState } from "react"
import "./AppointmentCalendarITalk.scss"
import ISelectable from "../../../models/ISelectable"
import { ITalkResponseWorkshop, IWorkshopInterval } from "../../../models/IAppointmentITalk"
import moment from "moment"
import { useConfigStore } from "../../contexts/RootStoreContext"
import clsx from "clsx"
import InlineButton from "../InlineButton/InlineButton"

interface Props {
  appointments: ITalkResponseWorkshop[] | number[]
  appointmentType: "digital" | "telephone"
  onSubmit: (item: ISelectable) => void
  isFullScreen?: boolean
}

export default function AppointmentCalendarITalk(props: Props): JSX.Element {
  const { appointments = [], appointmentType, onSubmit, isFullScreen = false } = props
  const offset = new Date().getTimezoneOffset()

  const groupByDate = (
    appointments: ITalkResponseWorkshop[] | number[],
    type: "digital" | "telephone"
  ) => {
    switch (type) {
      case "digital":
        return (appointments as ITalkResponseWorkshop[])[0].intervals.reduce((groups, item) => {
          const date = moment.unix(item.startDates[0]?.startDate).utc().format("dddd, Do of MMMM")
          if (!groups[date]) {
            groups[date] = []
          }
          groups[date].push(item)
          return groups
        }, {})
      case "telephone":
        return (appointments as number[]).reduce((groups, item) => {
          const date = moment.unix(item).utc().format("dddd, Do of MMMM")
          if (!groups[date]) {
            groups[date] = []
          }
          groups[date].push(item)
          return groups
        }, {})
    }
  }

  const groupedAppointments = Object.entries(groupByDate(appointments, appointmentType)).map(e => ({
    [e[0]]: e[1]
  }))
  const [currentPosition, setCurrentPosition] = useState(0)
  const lastPosition = groupedAppointments.length - 1
  const config = useConfigStore()
  const appointmentDay = Object.keys(groupedAppointments[currentPosition])[0]
  const appointmentsOnDay = groupedAppointments[currentPosition][appointmentDay]
  const isScrollable = groupedAppointments.length > 5
  const backgroundColor = config.userMessageBackground
  const noSuitableAppointmentMessage =
    appointmentType === "digital"
      ? "No suitable time, I will contact the service directly after completing this referral"
      : "No suitable appointment, please contact me"

  const onHandlePreviousPress = React.useCallback(
    (): void => setCurrentPosition(currentPosition - 1),
    [setCurrentPosition, currentPosition]
  )

  const onHandleNextPress = React.useCallback(
    (): void => setCurrentPosition(currentPosition + 1),
    [setCurrentPosition, currentPosition]
  )

  const handleSubmit = React.useCallback(
    (formattedDate: string, formattedTime: string, item: ITalkResponseWorkshop | number): void => {
      const body =
        appointmentType === "digital"
          ? `${
              (appointments as ITalkResponseWorkshop[])[0].title
            } on ${formattedDate} at ${formattedTime}`
          : `Phone assessment on ${formattedDate} at ${formattedTime}`
      return onSubmit({
        body,
        value: `${body}_${appointmentType === "digital" ? (item as ITalkResponseWorkshop) : item}`
      })
    },
    [onSubmit, appointmentType, appointments]
  )

  return (
    <div className="lb-appointment-italk-wrapper" data-testid="appointment-italk-input">
      <div
        className={clsx(
          "lb-appointment-italk-container",
          isScrollable
            ? "lb-appointment-italk-container-scrollable"
            : "lb-appointment-italk-container-non-scrollable"
        )}
      >
        <div className="lb-appointment-italk-calendar">
          {appointmentType === "digital" &&
            (appointmentsOnDay as IWorkshopInterval[]).map(item => {
              return item.startDates.map(item => {
                const backgroundColor = config.userMessageBackground
                const date = item.startDate
                const time = moment.unix(date).utc().format("HH:mm")
                const offsetTime = offset ? `${time} (UK time)` : time
                const btn: ISelectable = {
                  body: offsetTime,
                  value: date
                }

                const formattedDate = moment.unix(date).utc().format("dddd, Do of MMMM")
                return (
                  <InlineButton
                    className={clsx("lb-appointment-italk-button", {
                      "lb-appointment-italk-button-fullscreen": isFullScreen
                    })}
                    buttonContainerClassName="lb-appointment-italk-button-container"
                    style={{ backgroundColor, width: "100%" }}
                    key={date}
                    btn={btn}
                    onSelect={() => handleSubmit(formattedDate, offsetTime, item.id)}
                  />
                )
              })
            })}

          {appointmentType === "telephone" &&
            (appointmentsOnDay as number[]).map(item => {
              const time = moment.unix(item).utc().format("HH:mm")
              const offsetTime = offset ? `${time} (UK time)` : time
              const btn: ISelectable = {
                body: offsetTime,
                value: item
              }

              const formattedDate = moment.unix(item).utc().format("dddd, Do of MMMM")
              return (
                <InlineButton
                  className={clsx("lb-appointment-italk-button", {
                    "lb-appointment-italk-button-fullscreen": isFullScreen
                  })}
                  buttonContainerClassName="lb-appointment-italk-button-container"
                  style={{ backgroundColor, width: "100%" }}
                  key={item}
                  btn={btn}
                  onSelect={() => handleSubmit(formattedDate, offsetTime, item)}
                />
              )
            })}
        </div>
        <div className="lb-current-day-controls">
          <div
            onClick={onHandlePreviousPress}
            className={clsx(
              "lb-appointment-italk-prev",
              currentPosition === 0 && "lb-appointment-italk-disabled"
            )}
          >
            &#10094;
          </div>
          <div className="lb-current-day">{appointmentDay}</div>
          <div
            onClick={onHandleNextPress}
            className={clsx(
              "lb-appointment-italk-next",
              currentPosition === lastPosition && "lb-appointment-italk-disabled"
            )}
          >
            &#10095;
          </div>
        </div>
        <button
          onClick={() => onSubmit({ body: "No suitable time", value: "" })}
          style={{ color: backgroundColor }}
          className="lb-no-appointment-option-button"
        >
          {noSuitableAppointmentMessage}
        </button>
      </div>
    </div>
  )
}
