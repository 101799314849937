import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PeaceOfMindScript } from "./PeaceOfMindScript"
import type { PeaceOfMindScriptState } from "./PeaceOfMindScript"

type State = PeaceOfMindScriptState

export class PeaceOfMindCCGScript extends PeaceOfMindScript {
  readonly name: string = "PeaceOfMindCCGScript"
}
/* istanbul ignore next */
export default class PeaceOfMindCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.PeaceOfMindCCG
  readonly name: string = "PeaceOfMindCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PeaceOfMindCCGDialogue.id, new PeaceOfMindCCGScript(), state, snapshot)
  }
}
