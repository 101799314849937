import React from "react"
import { withStyles } from "@material-ui/core/styles"
import MUISlider from "@material-ui/core/Slider"
import Box from "@material-ui/core/Box"
import { observer } from "mobx-react"
import "./SliderInput.scss"
import InlineButton from "../InlineButton/InlineButton"
import { useConfigStore } from "../../contexts/RootStoreContext"
import Timer from "tiny-timer"
import { DataPointsType } from "../../../models/DataPoints"
import { IDataPoints } from "@limbic/types"

const timer = new Timer({ stopwatch: true })

interface Props {
  min: number
  max: number
  labels?: { [key: number]: string }
  notApplicable?: boolean
  dataPointsName?: string
  onSubmit: (points?: number, answer?: string, dataPoints?: IDataPoints) => void
}

function SliderInput(props: Props): JSX.Element {
  const { min, max, labels, notApplicable } = props
  const [sliderFirstInteractionTime, setSliderFirstInteractionTime] = React.useState<number>(0)
  const [sliderLastInteractionTime, setSliderLastInteractionTime] = React.useState<number>(0)
  const [numberOfSliderValueChanges, setNumberOfSliderValueChanges] = React.useState<number>(0)
  const [hasInteracted, setHasInteracted] = React.useState(false)
  const [value, setValue] = React.useState<number>(min)
  const config = useConfigStore()
  const currentLabel = labels?.[value] || `${value}/${max}`

  const getDataPoints = React.useCallback(
    value => {
      if (!props.dataPointsName) return undefined

      return {
        q: props.dataPointsName,
        type: DataPointsType.SLIDER,
        sliderFirstInteractionTime: sliderFirstInteractionTime / 1000,
        sliderLastInteractionTime: sliderLastInteractionTime / 1000,
        numberOfSliderValueChanges: numberOfSliderValueChanges,
        submitTimeFromFirstViewing: timer.time / 1000,
        value
      }
    },
    [
      sliderFirstInteractionTime,
      sliderLastInteractionTime,
      numberOfSliderValueChanges,
      props.dataPointsName
    ]
  )

  const onSubmit = React.useCallback(() => {
    const dataPoints: IDataPoints | undefined = getDataPoints(value)
    timer.stop()
    props.onSubmit(value, currentLabel, dataPoints)
  }, [props, value, currentLabel, getDataPoints])

  const onNotApplicable = React.useCallback(() => {
    const dataPoints: IDataPoints | undefined = getDataPoints("N/A")
    timer.stop()
    props.onSubmit(undefined, "N/A", dataPoints)
  }, [props, getDataPoints])

  React.useEffect(() => {
    timer.start(90000)
    return () => {
      timer.stop()
    }
  }, [])

  return (
    <div className="lb-slider-input-container" data-testid="slider-input">
      <Box className="lb-box">
        <Slider //
          marks
          value={value}
          min={min}
          max={max}
          valueLabelDisplay="auto"
          aria-label="slider"
          step={1}
          onChange={(_, value: any) => {
            if (numberOfSliderValueChanges === 0) {
              setSliderFirstInteractionTime(timer.time)
              setSliderLastInteractionTime(timer.time)
            } else {
              setSliderLastInteractionTime(timer.time)
            }
            setNumberOfSliderValueChanges(numberOfSliderValueChanges + 1)
            setValue(value)
            if (!hasInteracted) {
              setHasInteracted(true)
            }
          }}
        />
      </Box>
      <Box
        width="75%"
        marginBottom="1.44rem"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        {notApplicable && (
          <InlineButton
            btn={{ body: "N/A", fullWidth: true }}
            buttonClassName="lb-slider-input-not-applicable-button"
            onSelect={onNotApplicable}
          />
        )}
        {hasInteracted && (
          <InlineButton
            disabled={!hasInteracted}
            btn={{ body: labels ? currentLabel : "Submit", fullWidth: true }}
            style={{ backgroundColor: config.userMessageBackground }}
            onSelect={onSubmit}
          />
        )}
      </Box>
    </div>
  )
}

SliderInput.defaultProps = {
  min: 0,
  max: 10
}

export default observer(SliderInput)

const Slider = withStyles({
  root: {
    color: "#EC9CC8"
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 4,
    borderRadius: 4
  },
  rail: {
    height: 4,
    borderRadius: 4
  }
})(MUISlider)
