import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import delay from "../../utils/delay"
import isOnline from "is-online"
import invariant from "../../utils/invariant"
import { backendEnv } from "../../config/config"
import { ADSMStatus } from "../../models/ADSMStatus"
import { Questionnaires } from "@limbic/types"

const TOTAL_RETRIES = 3

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/v1/ml/adsm/triage",
  development: "https://access-v2-staging.limbic.ai/v1/ml/adsm/triage",
  production: "https://access-v2.limbic.ai/v1/ml/adsm/triage"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const BASE_URL = endpoints[backendEnv] || endpoints.production
const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export async function adsmTriage(
  signupCode: string,
  retry = 0
): Promise<[Array<keyof Questionnaires> | undefined, ADSMStatus]> {
  try {
    const hasConnection = await isOnline()
    if (!hasConnection) {
      return [undefined, ADSMStatus.NoInternetConnection]
    }
    const url = BASE_URL
    const body = JSON.stringify({ signupCode })
    const result = await fetch(url, { body, method, headers })
    const json = await result.json()
    const { success, data, error, validations } = json
    if (!success && error) {
      throw new NetworkError("", error)
    }
    if (!success && validations) {
      throw new NetworkError("", validations?.join(", "))
    }

    return [data.questionnaires, ADSMStatus.Success]
  } catch (e) {
    Logger.getInstance().breadcrumb({
      message: "adsmTriage post failed",
      data: { signupCode }
    })
    Logger.getInstance().exception(e, "adsmTriage post failed")
    if (retry < TOTAL_RETRIES) {
      Logger.getInstance().message("adsmTriage retry")
      await delay(1)
      return await adsmTriage(signupCode, retry + 1)
    }
    return [undefined, ADSMStatus.RequestFailed]
  }
}
