import {
  IClinicalPath,
  IClinicalPathOverride,
  ITreatmentOption
} from "../../../models/IClinicalPath"
import treatmentsInsight from "./treatments/insight"

const treatments: ITreatmentOption[] = treatmentsInsight

// TODO: need to revisit disorder categories and/or names
export const clinicalPathsInsight: Record<IClinicalPath["id"], IClinicalPathOverride> = {
  complex_comorbid: { defaultReferralType: "Full Assessment (Complex Comorbid)" },
  undetermined: { defaultReferralType: "Full Assessment (Undetermined)" },
  "1": { defaultReferralType: "Step 1 Limbic Assessment (Below Caseness)" },
  "2": { defaultReferralType: "Step 1 Limbic Assessment (Below Caseness)" },
  "3": { defaultReferralType: "Step 1 Limbic Assessment (Below Caseness)" },
  "4": { defaultReferralType: "Step 1 Limbic Assessment (Moderate Depression)", treatments },
  "5": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderate Depression, Moderately Severe Functional Impairment)",
    treatments
  },
  "6": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderate Depression, Severe Functional Impairment)",
    treatments
  },
  "7": {
    defaultReferralType: "Step 1 Limbic Assessment (Moderately Severe Depression)",
    treatments
  },
  "8": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderately Severe Depression, Moderately Severe Functional Impairment)",
    treatments
  },
  "9": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderately Severe Depression, Severe Functional Impairment)",
    treatments
  },
  "10": { defaultReferralType: "Full Assessment (Severe Depression)" },
  "11": {
    defaultReferralType:
      "Full Assessment (Severe Depression, Moderately Severe Functional Impairment)"
  },
  "12": {
    defaultReferralType: "Full Assessment (Severe Depression, Severe Functional Impairment)"
  },
  "13": { defaultReferralType: "Full Assessment (Social Phobia)" },
  "14": {
    defaultReferralType: "Full Assessment (Social Phobia, Moderately Severe Functional Impairment)"
  },
  "15": { defaultReferralType: "Full Assessment (Social Phobia, Severe Functional Impairment)" },
  "16": { defaultReferralType: "Full Assessment (Specific Phobia)" },
  "17": {
    defaultReferralType:
      "Full Assessment (Specific Phobia, Moderately Severe Functional Impairment)"
  },
  "18": { defaultReferralType: "Full Assessment (Specific Phobia, Severe Functional Impairment)" },
  "19": { defaultReferralType: "Full Assessment (Needle/Blood/Injury Phobia)" },
  "20": {
    defaultReferralType:
      "Full Assessment (Needle/Blood/Injury Phobia, Moderately Severe Functional Impairment)"
  },
  "21": {
    defaultReferralType:
      "Full Assessment (Needle/Blood/Injury Phobia, Severe Functional Impairment)"
  },
  "22": { defaultReferralType: "Full Assessment (Panic)" },
  "23": { defaultReferralType: "Full Assessment (Panic, Moderately Severe Functional Impairment)" },
  "24": { defaultReferralType: "Full Assessment (Severe Panic, Severe Functional Impairment)" },
  "25": { defaultReferralType: "Step 1 Limbic Assessment (Moderate GAD/Worry)", treatments },
  "26": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderate GAD/Worry, Moderately Severe Functional Impairment)",
    treatments
  },
  "27": {
    defaultReferralType:
      "Step 1 Limbic Assessment (Moderate GAD/Worry, Severe Functional Impairment)",
    treatments
  },
  "28": { defaultReferralType: "Full Assessment (Severe GAD/Worry)" },
  "29": {
    defaultReferralType:
      "Full Assessment (Severe GAD/Worry, Moderately Severe Functional Impairment)"
  },
  "30": { defaultReferralType: "Full Assessment (Severe GAD/Worry, Severe Functional Impairment)" },
  "31": { defaultReferralType: "Full Assessment (Health Anxiety)" },
  "32": {
    defaultReferralType: "Full Assessment (Health Anxiety, Moderately Severe Functional Impairment)"
  },
  "33": { defaultReferralType: "Full Assessment (Health Anxiety, Severe Functional Impairment)" },
  "34": { defaultReferralType: "Full Assessment (OCD)" },
  "35": { defaultReferralType: "Full Assessment (OCD, Moderately Severe Functional Impairment)" },
  "36": { defaultReferralType: "Full Assessment (OCD, Severe Functional Impairment)" },
  "37": { defaultReferralType: "Full Assessment (PTSD)" },
  "38": { defaultReferralType: "Full Assessment (PTSD, Moderately Severe Functional Impairment)" },
  "39": { defaultReferralType: "Full Assessment (PTSD, Severe Functional Impairment)" },
  "40": { defaultReferralType: "Full Assessment (Anxiety Disorder (Undetermined))" },
  "41": {
    defaultReferralType:
      "Full Assessment (Anxiety Disorder (Undetermined) with Moderately Severe functional impairment)"
  },
  "42": {
    defaultReferralType:
      "Full Assessment (Anxiety Disorder (Undetermined) with Severe functional impairment)"
  }
}
