import Logger from "../../utils/Logger"
import { backendEnv } from "../../config/config"
import delay from "../../utils/delay"
import NetworkError from "../../models/NetworkError"
import errorMessage from "../../utils/parseErrorMessage"
import invariant from "../../utils/invariant"

const TOTAL_RETRIES = 2

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/v1/email/send",
  development: "https://access-v2-staging.limbic.ai/v1/email/send",
  production: "https://access-v2.limbic.ai/v1/email/send"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const POST_SEND_EMAIL_URL = endpoints[backendEnv] || endpoints.production
export const riskEmail = process.env.REACT_APP_RISK_EMAIL === "enabled"
const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

interface Params {
  to: string[]
  cc?: string[]
  bcc?: string[]
  text: string
  isNewNumber?: boolean
}

export default async function sendRiskEmail(params: Params, retry = 0): Promise<void> {
  let body = ""
  try {
    const { to, cc, bcc, text, isNewNumber } = params
    const subject = isNewNumber
      ? "Urgent: LIMBIC RISK - UPDATED PHONE NUMBER"
      : "Urgent: LIMBIC RISK"
    if (!riskEmail) {
      console.log("email dispatch skipped")
      Logger.getInstance().breadcrumb({ message: "email dispatch skipped" })
      return
    }
    body = JSON.stringify({ to, cc, bcc, text, subject })
    const response = await fetch(POST_SEND_EMAIL_URL, { body, method, headers })
    if (!response.ok) {
      const text = await response.text()
      const message = errorMessage(response, { message: text })
      throw new NetworkError(String(response.status), message)
    }
  } catch (e) {
    Logger.getInstance().exception(e, "sendRiskEmail fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`email data for retry ${retry}`, body)
      Logger.getInstance().message("sendRiskEmail Retry")
      await delay(3 * retry || 1.5)
      return await sendRiskEmail(params, retry + 1)
    }
    throw e
  }
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
