/* eslint-disable @typescript-eslint/no-var-requires */
/* istanbul ignore file */
import { DialogueIDs } from "./DialogueIDs"
import type MainFlowDialogue from "./dialogues/MainFlowDialogue"
import type LeadClassificationLimbicDialogue from "./dialogues/leadClassification/LeadClassificationLimbicDialogue"
import type LearnAboutLimbicDialogue from "./dialogues/learnAbout/LearnAboutLimbicDialogue"
import type IntroductionIAPTDialogue from "./dialogues/introduction/IntroductionIAPTDialogue"
import type IntroductionWellbeingHubDialogue from "./dialogues/introduction/IntroductionWellbeingHubDialogue"
import type IntroductionGPDialogue from "./dialogues/introduction/IntroductionGPDialogue"
import type IntroductionCCGDialogue from "./dialogues/introduction/IntroductionCCGDialogue"
import type IntroductionEssexDialogue from "./dialogues/introduction/IntroductionEssexDialogue"
import type IntroductionHealixDialogue from "./dialogues/introduction/IntroductionHealixDialogue"
import type IntroductionGMHubDialogue from "./dialogues/introduction/IntroductionGMHubDialogue"
import type IntroductionDemoPrivateProvidersDialogue from "./dialogues/introduction/IntroductionDemoPrivateProvidersDialogue"
import type IntroductionInsightDialogue from "./dialogues/introduction/IntroductionInsightDialogue"
import type IntroductionInsightWaitlistDialogue from "./dialogues/introduction/IntroductionInsightWaitlistDialogue"
import type IntroductionInsightFollowUpDialogue from "./dialogues/introduction/IntroductionInsightFollowUpDialogue"
import type IntroductionITalkDialogue from "./dialogues/introduction/IntroductionITalkDialogue"
import type IntroductionVitalityDialogue from "./dialogues/introduction/IntroductionVitalityDialogue"
import type IntroductionVHGDialogue from "./dialogues/introduction/IntroductionVHGDialogue"
import type IntroductionLimbicDialogue from "./dialogues/introduction/IntroductionLimbicDialogue"
import type GetNameIAPTDialogue from "./dialogues/getName/GetNameIAPTDialogue"
import type GetNameITalkDialogue from "./dialogues/getName/GetNameITalkDialogue"
import type GetNameEssexDialogue from "./dialogues/getName/GetNameEssexDialogue"
import type GetNameCCGDialogue from "./dialogues/getName/GetNameCCGDialogue"
import type GetNameInsightDialogue from "./dialogues/getName/GetNameInsightDialogue"
import type GetNameLincolnshireDialogue from "./dialogues/getName/GetNameLincolnshireDialogue"
import type GetNameHealixDialogue from "./dialogues/getName/GetNameHealixDialogue"
import type GetNameNottinghamshireDialogue from "./dialogues/getName/GetNameNottinghamshireDialogue"
import type GetNameNorfolkDialogue from "./dialogues/getName/GetNameNorfolkDialogue"
import type GetNameTrentPTSDialogue from "./dialogues/getName/GetNameTrentPTSDialogue"
import type GetNameDemoPrivateProvidersDialogue from "./dialogues/getName/GetNameDemoPrivateProvidersDialogue"
import type GetNameInsightWaitlistDialogue from "./dialogues/getName/GetNameInsightWaitlistDialogue"
import type GetNameInsightFollowUpDialogue from "./dialogues/getName/GetNameInsightFollowUpDialogue"
import type GetNameSABPDialogue from "./dialogues/getName/GetNameSABPDialogue"
import type GetNameVHGDialogue from "./dialogues/getName/GetNameVHGDialogue"
import type GetNameVitalityDialogue from "./dialogues/getName/GetNameVitalityDialogue"
import type GetNameMHMDialogue from "./dialogues/getName/GetNameMHMDialogue"
import type GetNameLimbicDialogue from "./dialogues/getName/GetNameLimbicDialogue"
import type EligibilityCheckDemoDialogue from "./dialogues/eligibility/EligibilityCheckDemoDialogue"
import type EligibilityCheckEssexDialogue from "./dialogues/eligibility/EligibilityCheckEssexDialogue"
import type EligibilityCheckIAPTDialogue from "./dialogues/eligibility/EligibilityCheckIAPTDialogue"
import type EligibilityCheckITalkDialogue from "./dialogues/eligibility/EligibilityCheckITalkDialogue"
import type EligibilityCheckGPDialogue from "./dialogues/eligibility/EligibilityCheckGPDialogue"
import type EligibilityCheckCCGDialogue from "./dialogues/eligibility/EligibilityCheckCCGDialogue"
import type EligibilityCheckWellbeingHubDialogue from "./dialogues/eligibility/EligibilityCheckWellbeingHubDialogue"
import type EligibilityCheckTrentPTSDialogue from "./dialogues/eligibility/EligibilityCheckTrentPTSDialogue"
import type EligibilityCheckGMHubDialogue from "./dialogues/eligibility/EligibilityCheckGMHubDialogue"
import type EligibilityCheckTrentPTSDemoDialogue from "./dialogues/eligibility/EligibilityCheckTrentPTSDemoDialogue"
import type EligibilityCheckInsightDialogue from "./dialogues/eligibility/EligibilityCheckInsightDialogue"
import type EligibilityCheckNorfolkDialogue from "./dialogues/eligibility/EligibilityCheckNorfolkDialogue"
import type EligibilityCheckLincolnshireDialogue from "./dialogues/eligibility/EligibilityCheckLincolnshireDialogue"
import type EligibilityCheckNottinghamshireDialogue from "./dialogues/eligibility/EligibilityCheckNottinghamshireDialogue"
import type EligibilityCheckSABPDialogue from "./dialogues/eligibility/EligibilityCheckSABPDialogue"
import type EligibilityCheckVHGDialogue from "./dialogues/eligibility/EligibilityCheckVHGDialogue"
import type EligibilityCheckVitalityDialogue from "./dialogues/eligibility/EligibilityCheckVitalityDialogue"
import type EligibilityCheckMHMDialogue from "./dialogues/eligibility/EligibilityCheckMHMDialogue"
import type SuitableServicesSignpostIAPTDialogue from "./dialogues/signposts/SuitableServicesSignpostIAPTDialogue"
import type SuitableServicesSignpostCCGDialogue from "./dialogues/signposts/SuitableServicesSignpostCCGDialogue"
import type SuitableServicesSignpostSABPDialogue from "./dialogues/signposts/SuitableServicesSignpostSABPDialogue"
import type SuitableServicesSignpostEssexDialogue from "./dialogues/signposts/SuitableServicesSignpostEssexDialogue"
import type UnderAgeSignpostDialogue from "./dialogues/signposts/UnderAgeSignpostDialogue"
import type UnderAgeSignpostEssexDialogue from "./dialogues/signposts/UnderAgeSignpostEssexDialogue"
import type UnderAgeSignpostWellbeingHubDialogue from "./dialogues/signposts/UnderAgeSignpostWellbeingHubDialogue"
import type UnderAgeSignpostGMHubDialogue from "./dialogues/signposts/UnderAgeSignpostGMHubDialogue"
import type UnderAgeSignpostITalkDialogue from "./dialogues/signposts/UnderAgeSignpostITalkDialogue"
import type UnderAgeSignpostNorfolkDialogue from "./dialogues/signposts/UnderAgeSignpostNorfolkDialogue"
import type UnderAgeSignpostLincolnshireDialogue from "./dialogues/signposts/UnderAgeSignpostLincolnshireDialogue"
import type UnderAgeSignpostSABPDialogue from "./dialogues/signposts/UnderAgeSignpostSABPDialogue"
import type PeaceOfMindIAPTDialogue from "./dialogues/peaceOfMind/PeaceOfMindIAPTDialogue"
import type PeaceOfMindGPDialogue from "./dialogues/peaceOfMind/PeaceOfMindGPDialogue"
import type PeaceOfMindCCGDialogue from "./dialogues/peaceOfMind/PeaceOfMindCCGDialogue"
import type PeaceOfMindWellbeingHubDialogue from "./dialogues/peaceOfMind/PeaceOfMindWellbeingHubDialogue"
import type PeaceOfMindHealixDialogue from "./dialogues/peaceOfMind/PeaceOfMindHealixDialogue"
import type PeaceOfMindDemoPrivateProvidersDialogue from "./dialogues/peaceOfMind/PeaceOfMindDemoPrivateProvidersDialogue"
import type PeaceOfMindVitalityDialogue from "./dialogues/peaceOfMind/PeaceOfMindVitalityDialogue"
import type PermissionsEssexDialogue from "./dialogues/permissions/PermissionsEssexDialogue"
import type PermissionsIAPTDialogue from "./dialogues/permissions/PermissionsIAPTDialogue"
import type PermissionsCCGDialogue from "./dialogues/permissions/PermissionsCCGDialogue"
import type PermissionsNorfolkDialogue from "./dialogues/permissions/PermissionsNorfolkDialogue"
import type PermissionsWellbeingHubDialogue from "./dialogues/permissions/PermissionsWellbeingHubDialogue"
import type PermissionsGMDialogue from "./dialogues/permissions/PermissionsGMHubDialogue"
import type PermissionsSABPDialogue from "./dialogues/permissions/PermissionsSABPDialogue"
import type PermissionsInsightDialogue from "./dialogues/permissions/PermissionsInsightDialogue"
import type PermissionsDemoPrivateProvidersDialogue from "./dialogues/permissions/PermissionsDemoPrivateProvidersDialogue"
import type PermissionsVitalityDialogue from "./dialogues/permissions/PermissionsVitalityDialogue"
import type PermissionsMHMDialogue from "./dialogues/permissions/PermissionsMHMDialogue"
import type SelfReferralPitchIAPTDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchIAPTDialogue"
import type SelfReferralPitchHealixDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchHealixDialogue"
import type SelfReferralPitchGMHubDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchGMHubDialogue"
import type SelfReferralPitchDialogueCCG from "./dialogues/selfReferralPitch/SelfReferralPitchDialogueCCG"
import type SelfReferralPitchDialogueWellbeingHub from "./dialogues/selfReferralPitch/SelfReferralPitchDialogueWellbeingHub"
import type SelfReferralPitchDemoPrivateProvidersDialogue from "./dialogues/selfReferralPitch/SelfReferralPitchDemoPrivateProvidersDialogue"
import type SelfReferralDemoDialogue from "./dialogues/selfReferral/SelfReferralDemoDialogue"
import type SelfReferralEssexDialogue from "./dialogues/selfReferral/SelfReferralEssexDialogue"
import type SelfReferralCCGDialogue from "./dialogues/selfReferral/SelfReferralCCGDialogue"
import type SelfReferralWellbeingHubDialogue from "./dialogues/selfReferral/SelfReferralWellbeingHubDialogue"
import type SelfReferralWellbeingHubIndividualDialogue from "./dialogues/selfReferral/SelfReferralWellbeingHubIndividualDialogue"
import type SelfReferralWellbeingHubManagerIndividualDialogue from "./dialogues/selfReferral/SelfReferralWellbeingHubManagerIndividualDialogue"
import type SelfReferralWellbeingHubManagerTeamDialogue from "./dialogues/selfReferral/SelfReferralWellbeingHubManagerTeamDialogue"
import type SelfReferralTrentPTSDialogue from "./dialogues/selfReferral/SelfReferralTrentPTSDialogue"
import type SelfReferralGMHubDialogue from "./dialogues/selfReferral/SelfReferralGMHubDialogue"
import type SelfReferralGMHubManagerTeamDialogue from "./dialogues/selfReferral/SelfReferralGMHubManagerTeamDialogue"
import type SelfReferralGMHubManagerIndividualDialogue from "./dialogues/selfReferral/SelfReferralGMHubManagerIndividualDialogue"
import type SelfReferralGMHubIndividualDialogue from "./dialogues/selfReferral/SelfReferralGMHubIndividualDialogue"
import type SelfReferralHealixDialogue from "./dialogues/selfReferral/SelfReferralHealixDialogue"
import type SelfReferralInsightDialogue from "./dialogues/selfReferral/SelfReferralInsightDialogue"
import type SelfReferralITalkDialogue from "./dialogues/selfReferral/SelfReferralITalkDialogue"
import type SelfReferralNorfolkDialogue from "./dialogues/selfReferral/SelfReferralNorfolkDialogue"
import type SelfReferralNottinghamshireDialogue from "./dialogues/selfReferral/SelfReferralNottinghamshireDialogue"
import type SelfReferralSABPDialogue from "./dialogues/selfReferral/SelfReferralSABPDialogue"
import type SelfReferralLincolnshireDialogue from "./dialogues/selfReferral/SelfReferralLincolnshireDialogue"
import type SelfReferralVHGDialogue from "./dialogues/selfReferral/SelfReferralVHGDialogue"
import type SelfReferralDemoPrivateProvidersDialogue from "./dialogues/selfReferral/SelfReferralDemoPrivateProvidersDialogue"
import type SelfReferralInsightWaitlistDialogue from "./dialogues/selfReferral/SelfReferralInsightWaitlistDialogue"
import type SelfReferralVitalityDialogue from "./dialogues/selfReferral/SelfReferralVitalityDialogue"
import type SelfReferralLimbicDialogue from "./dialogues/selfReferral/SelfReferralLimbicDialogue"
import type ADSMConsentDialogue from "./dialogues/adsmConsent/ADSMConsentDialogue"
import type ADSMConsentInsightDialogue from "./dialogues/adsmConsent/ADSMConsentInsightDialogue"
import type SelfReferralMHMDialogue from "./dialogues/selfReferral/SelfReferralMHMDialogue"
import type AssessmentPitchIAPTDialogue from "./dialogues/assessmentPitch/AssessmentPitchIAPTDialogue"
import type AssessmentPitchGPDialogue from "./dialogues/assessmentPitch/AssessmentPitchGPDialogue"
import type AssessmentPitchCCGDialogue from "./dialogues/assessmentPitch/AssessmentPitchCCGDialogue"
import type AssessmentPitchEssexDialogue from "./dialogues/assessmentPitch/AssessmentPitchEssexDialogue"
import type AssessmentPitchWellbeingHubDialogue from "./dialogues/assessmentPitch/AssessmentPitchWellbeingHubDialogue"
import type AssessmentPitchTrentPTSDialogue from "./dialogues/assessmentPitch/AssessmentPitchTrentPTSDialogue"
import type AssessmentPitchGMHubDialogue from "./dialogues/assessmentPitch/AssessmentPitchGMHubDialogue"
import type AssessmentPitchHealixDialogue from "./dialogues/assessmentPitch/AssessmentPitchHealixDialogue"
import type AssessmentPitchITalkDialogue from "./dialogues/assessmentPitch/AssessmentPitchITalkDialogue"
import type AssessmentPitchInsightDialogue from "./dialogues/assessmentPitch/AssessmentPitchInsightDialogue"
import type AssessmentPitchInsightFollowUpDialogue from "./dialogues/assessmentPitch/AssessmentPitchInsightFollowUpDialogue"
import type AssessmentPitchLincolnshireDialogue from "./dialogues/assessmentPitch/AssessmentPitchLincolnshireDialogue"
import type AssessmentPitchNottinghamshireDialogue from "./dialogues/assessmentPitch/AssessmentPitchNottinghamshireDialogue"
import type AssessmentPitchNorfolkDialogue from "./dialogues/assessmentPitch/AssessmentPitchNorfolkDialogue"
import type AssessmentPitchSABPDialogue from "./dialogues/assessmentPitch/AssessmentPitchSABPDialogue"
import type AssessmentPitchVHGDialogue from "./dialogues/assessmentPitch/AssessmentPitchVHGDialogue"
import type AssessmentPitchDemoPrivateProvidersDialogue from "./dialogues/assessmentPitch/AssessmentPitchDemoPrivateProvidersDialogue"
import type AssessmentPitchVitalityDialogue from "./dialogues/assessmentPitch/AssessmentPitchVitalityDialogue"
import type AssessmentPitchMHMDialogue from "./dialogues/assessmentPitch/AssessmentPitchMHMDialogue"
import type AssessmentDemoDialogue from "./dialogues/assessment/AssessmentDemoDialogue"
import type AssessmentCCGDialogue from "./dialogues/assessment/AssessmentCCGDialogue"
import type AssessmentEssexDialogue from "./dialogues/assessment/AssessmentEssexDialogue"
import type AssessmentWellbeingHubDialogue from "./dialogues/assessment/AssessmentWellbeingHubDialogue"
import type AssessmentKentMedwayDialogue from "./dialogues/assessment/AssessmentKentMedwayDialogue"
import type AssessmentSABPDialogue from "./dialogues/assessment/AssessmentSABPDialogue"
import type AssessmentGMHubDialogue from "./dialogues/assessment/AssessmentGMHubDialogue"
import type AssessmentHealixDialogue from "./dialogues/assessment/AssessmentHealixDialogue"
import type AssessmentInsightDialogue from "./dialogues/assessment/AssessmentInsightDialogue"
import type AssessmentADSMInsightDialogue from "./dialogues/assessment/AssessmentADSMInsightDialogue"
import type AssessmentITalkDialogue from "./dialogues/assessment/AssessmentITalkDialogue"
import type AssessmentNorfolkDialogue from "./dialogues/assessment/AssessmentNorfolkDialogue"
import type AssessmentNottinghamshireDialogue from "./dialogues/assessment/AssessmentNottinghamshireDialogue"
import type AssessmentVHGDialogue from "./dialogues/assessment/AssessmentVHGDialogue"
import type AssessmentTrentPTSDialogue from "./dialogues/assessment/AssessmentTrentPTSDialogue"
import type AssessmentLincolnshireDialogue from "./dialogues/assessment/AssessmentLincolnshireDialogue"
import type AssessmentDemoPrivateProvidersDialogue from "./dialogues/assessment/AssessmentDemoPrivateProvidersDialogue"
import type AssessmentVitalityDialogue from "./dialogues/assessment/AssessmentVitalityDialogue"
import type AssessmentMHMDialogue from "./dialogues/assessment/AssessmentMHMDialogue"
import type PHQ9Dialogue from "./surveys/PHQ9/PHQ9"
import type PHQ9GMHubDialogue from "./surveys/PHQ9/PHQ9GMHubDialogue"
import type PHQ9NorfolkDialogue from "./surveys/PHQ9/PHQ9NorfolkDialogue"
import type PHQ9WellbeingHubDialogue from "./surveys/PHQ9/PHQ9WellbeingHubDialogue"
import type PHQ9SABPDialogue from "./surveys/PHQ9/PHQ9SABPDialogue"
import type PHQ9NottinghamshireDialogue from "./surveys/PHQ9/PHQ9NottinghamshireDialogue"
import type PHQ9VHGDialogue from "./surveys/PHQ9/PHQ9VHGDialogue"
import type PHQ9TrentPTSDialogue from "./surveys/PHQ9/PHQ9TrentPTSDialogue"
import type PHQ9VitalityDialogue from "./surveys/PHQ9/PHQ9VitalityDialogue"
import type RiskPathwayDemoDialogue from "./dialogues/riskPathway/RiskPathwayDemoDialogue"
import type RiskPathwayCCGDialogue from "./dialogues/riskPathway/RiskPathwayCCGDialogue"
import type RiskPathwayWellbeingHubDialogue from "./dialogues/riskPathway/RiskPathwayWellbeingHubDialogue"
import type RiskPathwayGMHubDialogue from "./dialogues/riskPathway/RiskPathwayGMHubDialogue"
import type RiskPathwayEssexDialogue from "./dialogues/riskPathway/RiskPathwayEssexDialogue"
import type RiskPathwayTrentPTSDialogue from "./dialogues/riskPathway/RiskPathwayTrentPTSDialogue"
import type RiskPathwayHealixDialogue from "./dialogues/riskPathway/RiskPathwayHealixDialogue"
import type RiskPathwayInsightDialogue from "./dialogues/riskPathway/RiskPathwayInsightDialogue"
import type RiskPathwayInsightFollowUpDialogue from "./dialogues/riskPathway/RiskPathwayInsightFollowUpDialogue"
import type RiskPathwayITalkDialogue from "./dialogues/riskPathway/RiskPathwayITalkDialogue"
import type RiskPathwayNottinghamshireDialogue from "./dialogues/riskPathway/RiskPathwayNottinghamshireDialogue"
import type RiskPathwayNorfolkDialogue from "./dialogues/riskPathway/RiskPathwayNorfolkDialogue"
import type RiskPathwaySABPDialogue from "./dialogues/riskPathway/RiskPathwaySABPDialogue"
import type RiskPathwayVHGDialogue from "./dialogues/riskPathway/RiskPathwayVHGDialogue"
import type RiskPathwayLincolnshireDialogue from "./dialogues/riskPathway/RiskPathwayLincolnshireDialogue"
import type RiskPathwayDemoPrivateProvidersDialogue from "./dialogues/riskPathway/RiskPathwayDemoPrivateProvidersDialogue"
import type RiskPathwayMHMDialogue from "./dialogues/riskPathway/RiskPathwayMHMDialogue"
import type AuditDialogue from "./surveys/Audit"
import type DrugsAndSmokingDialogue from "./surveys/DrugsAndSmoking"
import type ITQDialogue from "./surveys/ITQ"
import type IRQADialogue from "./surveys/IRQ-A"
import type RiskPathwayVitalityDialogue from "./dialogues/riskPathway/RiskPathwayVitalityDialogue"
import type GAD7Dialogue from "./surveys/GAD7"
import type IAPTPhobiaScaleDialogue from "./surveys/IAPTPhobiaScale"
import type IAPTEmploymentStatusDialogue from "./surveys/IAPTEmploymentStatus"
import type IAPTEmploymentStatusKMDialogue from "./surveys/IAPTEmploymentStatusKM"
import type IAPTWorkAndSocialAdjustmentDialogue from "./surveys/IAPTWorkAndSocialAdjustment"
import type IAPTMedicationDialogue from "./surveys/IAPTMedication"
import type IAPTAccommodationDialogue from "./surveys/IAPTAccommodation"
import type PCL5Dialogue from "./surveys/PCL5"
import type PDSSDialogue from "./surveys/PDSS"
import type SPINDialogue from "./surveys/SPIN"
import type OCIDialogue from "./surveys/OCI"
import type SHAI18Dialogue from "./surveys/SHAI18"
import type SpecificPhobiaIntroDialogue from "./surveys/SpecificPhobiaIntro"
import type SpecificPhobiaDialogue from "./surveys/SpecificPhobia"
import type TreatmentOptionsIAPTDialogue from "./dialogues/treatmentOptions/TreatmentOptionsIAPTDialogue"
import type TreatmentOptionsCCGDialogue from "./dialogues/treatmentOptions/TreatmentOptionsCCGDialogue"
import type TreatmentOptionsTrentPTSDialogue from "./dialogues/treatmentOptions/TreatmentOptionsTrentPTSDialogue"
import type TreatmentOptionsSABPDialogue from "./dialogues/treatmentOptions/TreatmentOptionsSABPDialogue"
import type TreatmentOptionsHealixDialogue from "./dialogues/treatmentOptions/TreatmentOptionsHealixDialogue"
import type TreatmentOptionsInsightDialogue from "./dialogues/treatmentOptions/TreatmentOptionsInsightDialogue"
import type TreatmentOptionsVHGDialogue from "./dialogues/treatmentOptions/TreatmentOptionsVHGDialogue"
import type TreatmentOptionsDemoPrivateProvidersDialogue from "./dialogues/treatmentOptions/TreatmentOptionsDemoPrivateProvidersDialogue"
import type TreatmentOptionsVitalityDialogue from "./dialogues/treatmentOptions/TreatmentOptionsVitalityDialogue"
import type BookMentalHealthProfessionalKMDialogue from "./dialogues/bookProfessional/BookMentalHealthProfessionalKMDialogue"
import type WellbeingCCGDialogue from "./dialogues/wellbeing/WellbeingCCGDialogue"
import type WellbeingHealixDialogue from "./dialogues/wellbeing/WellbeingHealixDialogue"
import type WellbeingDemoPrivateProvidersDialogue from "./dialogues/wellbeing/WellbeingDemoPrivateProvidersDialogue"
import type GoodbyeDemoDialogue from "./dialogues/goodbye/GoodbyeDemoDialogue"
import type GoodbyeCCGDialogue from "./dialogues/goodbye/GoodbyeCCGDialogue"
import type GoodbyeGMHubDialogue from "./dialogues/goodbye/GoodbyeGMHubDialogue"
import type GoodbyeWellbeingHubDialogue from "./dialogues/goodbye/GoodbyeWellbeingHubDialogue"
import type GoodbyeEssexDialogue from "./dialogues/goodbye/GoodbyeEssexDialogue"
import type GoodbyeTrentPTSDialogue from "./dialogues/goodbye/GoodbyeTrentPTSDialogue"
import type GoodbyeSABPDialogue from "./dialogues/goodbye/GoodbyeSABPDialogue"
import type GoodbyeHealixDialogue from "./dialogues/goodbye/GoodbyeHealixDialogue"
import type GoodbyeInsightDialogue from "./dialogues/goodbye/GoodbyeInsightDialogue"
import type GoodbyeInsightFollowUpDialogue from "./dialogues/goodbye/GoodbyeInsightFollowUpDialogue"
import type GoodbyeITalkDialogue from "./dialogues/goodbye/GoodbyeITalkDialogue"
import type GoodbyeNottinghamshireDialogue from "./dialogues/goodbye/GoodbyeNottinghamshireDialogue"
import type GoodbyeNorfolkDialogue from "./dialogues/goodbye/GoodbyeNorfolkDialogue"
import type GoodbyeVHGDialogue from "./dialogues/goodbye/GoodbyeVHGDialogue"
import type GoodbyeLincolnshireDialogue from "./dialogues/goodbye/GoodbyeLincolnshireDialogue"
import type GoodbyeDemoPrivateProvidersDialogue from "./dialogues/goodbye/GoodbyeDemoPrivateProvidersDialogue"
import type GoodbyeVitalityDialogue from "./dialogues/goodbye/GoodbyeVitalityDialogue"
import type GoodbyeMHMDialogue from "./dialogues/goodbye/GoodbyeMHMDialogue"
import type CrisisDemoDialogue from "./dialogues/crisis/CrisisDemoDialogue"
import type CrisisCCGDialogue from "./dialogues/crisis/CrisisCCGDialogue"
import type CrisisGMHubDialogue from "./dialogues/crisis/CrisisGMHubDialogue"
import type CrisisWellbeingHubDialogue from "./dialogues/crisis/CrisisWellbeingHubDialogue"
import type CrisisEssexDialogue from "./dialogues/crisis/CrisisEssexDialogue"
import type CrisisTrentPTSDialogue from "./dialogues/crisis/CrisisTrentPTSDialogue"
import type CrisisHealixDialogue from "./dialogues/crisis/CrisisHealixDialogue"
import type CrisisInsightDialogue from "./dialogues/crisis/CrisisInsightDialogue"
import type CrisisITalkDialogue from "./dialogues/crisis/CrisisITalkDialogue"
import type CrisisNottinghamshireDialogue from "./dialogues/crisis/CrisisNottinghamshireDialogue"
import type CrisisNorfolkDialogue from "./dialogues/crisis/CrisisNorfolkDialogue"
import type CrisisSABPDialogue from "./dialogues/crisis/CrisisSABPDialogue"
import type CrisisVHGDialogue from "./dialogues/crisis/CrisisVHGDialogue"
import type CrisisLincolnshireDialogue from "./dialogues/crisis/CrisisLincolnshireDialogue"
import type CrisisDemoPrivateProvidersDialogue from "./dialogues/crisis/CrisisDemoPrivateProvidersDialogue"
import type CrisisVitalityDialogue from "./dialogues/crisis/CrisisVitalityDialogue"
import type CrisisMHMDialogue from "./dialogues/crisis/CrisisMHMDialogue"
import type CrisisLimbicDialogue from "./dialogues/crisis/CrisisLimbicDialogue"
import type BookAppointmentVitalityDialogue from "./dialogues/bookAppointment/BookAppointmentVitalityDialogue"
import type BookAppointmentITalkDialogue from "./dialogues/bookAppointment/BookAppointmentITalkDialogue"
import type CollectPhoneNumberDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberDialogue"
import type CollectPhoneNumberEssexDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberEssexDialogue"
import type CollectPhoneNumberInsightDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberInsightDialogue"
import type CollectPhoneNumberITalkDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberITalkDialogue"
import type CollectPhoneNumberNorfolkDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberNorfolkDialogue"
import type CollectPhoneNumberMHMDialogue from "./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberMHMDialogue"
import type CollectAlcoholConsumptionDialogue from "./dialogues/ad-hoc/CollectAlcoholConsumption/CollectAlcoholConsumptionDialogue"

/**
 * @IMPORTANT: Dialogues in this file MUST NOT be imported directly.
 *             You can import the dialogue as a type only, because
 *             you'll need to use it in the interface below 👇, but
 *             it's important to only fetch dialogues by requiring
 *             them at runtime.
 */

export interface IDialoguesRegistry {
  [DialogueIDs.MainFlow]: typeof MainFlowDialogue
  [DialogueIDs.LeadClassificationLimbic]: typeof LeadClassificationLimbicDialogue
  [DialogueIDs.LearnAboutLimbic]: typeof LearnAboutLimbicDialogue
  [DialogueIDs.IntroductionIAPT]: typeof IntroductionIAPTDialogue
  [DialogueIDs.IntroductionGP]: typeof IntroductionGPDialogue
  [DialogueIDs.IntroductionCCG]: typeof IntroductionCCGDialogue
  [DialogueIDs.IntroductionWellbeingHub]: typeof IntroductionWellbeingHubDialogue
  [DialogueIDs.IntroductionEssex]: typeof IntroductionEssexDialogue
  [DialogueIDs.IntroductionHealix]: typeof IntroductionHealixDialogue
  [DialogueIDs.IntroductionGMHub]: typeof IntroductionGMHubDialogue
  [DialogueIDs.IntroductionDemoPrivateProviders]: typeof IntroductionDemoPrivateProvidersDialogue
  [DialogueIDs.IntroductionInsight]: typeof IntroductionInsightDialogue
  [DialogueIDs.IntroductionInsightWaitlist]: typeof IntroductionInsightWaitlistDialogue
  [DialogueIDs.IntroductionInsightFollowUp]: typeof IntroductionInsightFollowUpDialogue
  [DialogueIDs.IntroductionITalk]: typeof IntroductionITalkDialogue
  [DialogueIDs.IntroductionVitality]: typeof IntroductionVitalityDialogue
  [DialogueIDs.IntroductionVHG]: typeof IntroductionVHGDialogue
  [DialogueIDs.IntroductionLimbic]: typeof IntroductionLimbicDialogue
  [DialogueIDs.GetNameIAPT]: typeof GetNameIAPTDialogue
  [DialogueIDs.GetNameITalk]: typeof GetNameITalkDialogue
  [DialogueIDs.GetNameEssex]: typeof GetNameEssexDialogue
  [DialogueIDs.GetNameCCG]: typeof GetNameCCGDialogue
  [DialogueIDs.GetNameInsight]: typeof GetNameInsightDialogue
  [DialogueIDs.GetNameLincolnshire]: typeof GetNameLincolnshireDialogue
  [DialogueIDs.GetNameHealix]: typeof GetNameHealixDialogue
  [DialogueIDs.GetNameNottinghamshire]: typeof GetNameNottinghamshireDialogue
  [DialogueIDs.GetNameNorfolk]: typeof GetNameNorfolkDialogue
  [DialogueIDs.GetNameTrentPTS]: typeof GetNameTrentPTSDialogue
  [DialogueIDs.GetNameDemoPrivateProviders]: typeof GetNameDemoPrivateProvidersDialogue
  [DialogueIDs.GetNameInsightWaitlist]: typeof GetNameInsightWaitlistDialogue
  [DialogueIDs.GetNameInsightFollowUp]: typeof GetNameInsightFollowUpDialogue
  [DialogueIDs.GetNameSABP]: typeof GetNameSABPDialogue
  [DialogueIDs.GetNameVHG]: typeof GetNameVHGDialogue
  [DialogueIDs.GetNameMHM]: typeof GetNameMHMDialogue
  [DialogueIDs.GetNameVitality]: typeof GetNameVitalityDialogue
  [DialogueIDs.GetNameLimbic]: typeof GetNameLimbicDialogue
  [DialogueIDs.EligibilityCheckDemo]: typeof EligibilityCheckDemoDialogue
  [DialogueIDs.EligibilityCheckEssex]: typeof EligibilityCheckEssexDialogue
  [DialogueIDs.EligibilityCheckIAPT]: typeof EligibilityCheckIAPTDialogue
  [DialogueIDs.EligibilityCheckITalk]: typeof EligibilityCheckITalkDialogue
  [DialogueIDs.EligibilityCheckGP]: typeof EligibilityCheckGPDialogue
  [DialogueIDs.EligibilityCheckCCG]: typeof EligibilityCheckCCGDialogue
  [DialogueIDs.EligibilityCheckWellbeingHub]: typeof EligibilityCheckWellbeingHubDialogue
  [DialogueIDs.EligibilityCheckTrentPTS]: typeof EligibilityCheckTrentPTSDialogue
  [DialogueIDs.EligibilityCheckGMHub]: typeof EligibilityCheckGMHubDialogue
  [DialogueIDs.EligibilityCheckTrentPTSDemo]: typeof EligibilityCheckTrentPTSDemoDialogue
  [DialogueIDs.EligibilityCheckInsight]: typeof EligibilityCheckInsightDialogue
  [DialogueIDs.EligibilityCheckNorfolk]: typeof EligibilityCheckNorfolkDialogue
  [DialogueIDs.EligibilityCheckLincolnshire]: typeof EligibilityCheckLincolnshireDialogue
  [DialogueIDs.EligibilityCheckNottinghamshire]: typeof EligibilityCheckNottinghamshireDialogue
  [DialogueIDs.EligibilityCheckSABP]: typeof EligibilityCheckSABPDialogue
  [DialogueIDs.EligibilityCheckVHG]: typeof EligibilityCheckVHGDialogue
  [DialogueIDs.EligibilityCheckVitality]: typeof EligibilityCheckVitalityDialogue
  [DialogueIDs.EligibilityCheckMHM]: typeof EligibilityCheckMHMDialogue
  [DialogueIDs.SuitableServicesSignpostIAPT]: typeof SuitableServicesSignpostIAPTDialogue
  [DialogueIDs.SuitableServicesSignpostCCG]: typeof SuitableServicesSignpostCCGDialogue
  [DialogueIDs.SuitableServicesSignpostSABP]: typeof SuitableServicesSignpostSABPDialogue
  [DialogueIDs.SuitableServicesSignpostEssex]: typeof SuitableServicesSignpostEssexDialogue
  [DialogueIDs.UnderageSignpost]: typeof UnderAgeSignpostDialogue
  [DialogueIDs.UnderageSignpostGMHub]: typeof UnderAgeSignpostGMHubDialogue
  [DialogueIDs.UnderageSignpostNorfolk]: typeof UnderAgeSignpostNorfolkDialogue
  [DialogueIDs.UnderageSignpostWellbeingHub]: typeof UnderAgeSignpostWellbeingHubDialogue
  [DialogueIDs.UnderageSignpostITalk]: typeof UnderAgeSignpostITalkDialogue
  [DialogueIDs.UnderageSignpostEssex]: typeof UnderAgeSignpostEssexDialogue
  [DialogueIDs.UnderageSignpostLincolnshire]: typeof UnderAgeSignpostLincolnshireDialogue
  [DialogueIDs.UnderageSignpostSABP]: typeof UnderAgeSignpostSABPDialogue
  [DialogueIDs.PeaceOfMindIAPT]: typeof PeaceOfMindIAPTDialogue
  [DialogueIDs.PeaceOfMindGP]: typeof PeaceOfMindGPDialogue
  [DialogueIDs.PeaceOfMindCCG]: typeof PeaceOfMindCCGDialogue
  [DialogueIDs.PeaceOfMindWellbeingHub]: typeof PeaceOfMindWellbeingHubDialogue
  [DialogueIDs.PeaceOfMindHealix]: typeof PeaceOfMindHealixDialogue
  [DialogueIDs.PeaceOfMindDemoPrivateProviders]: typeof PeaceOfMindDemoPrivateProvidersDialogue
  [DialogueIDs.PeaceOfMindVitality]: typeof PeaceOfMindVitalityDialogue
  [DialogueIDs.PermissionsEssex]: typeof PermissionsEssexDialogue
  [DialogueIDs.PermissionsIAPT]: typeof PermissionsIAPTDialogue
  [DialogueIDs.PermissionsCCG]: typeof PermissionsCCGDialogue
  [DialogueIDs.PermissionsNorfolk]: typeof PermissionsNorfolkDialogue
  [DialogueIDs.PermissionsWellbeingHub]: typeof PermissionsWellbeingHubDialogue
  [DialogueIDs.PermissionsGMHub]: typeof PermissionsGMDialogue
  [DialogueIDs.PermissionsSABP]: typeof PermissionsSABPDialogue
  [DialogueIDs.PermissionsInsight]: typeof PermissionsInsightDialogue
  [DialogueIDs.PermissionsDemoPrivateProviders]: typeof PermissionsDemoPrivateProvidersDialogue
  [DialogueIDs.PermissionsVitality]: typeof PermissionsVitalityDialogue
  [DialogueIDs.PermissionsMHM]: typeof PermissionsMHMDialogue
  [DialogueIDs.SelfReferralPitchIAPT]: typeof SelfReferralPitchIAPTDialogue
  [DialogueIDs.SelfReferralPitchHealix]: typeof SelfReferralPitchHealixDialogue
  [DialogueIDs.SelfReferralPitchGMHub]: typeof SelfReferralPitchGMHubDialogue
  [DialogueIDs.SelfReferralPitchCCG]: typeof SelfReferralPitchDialogueCCG
  [DialogueIDs.SelfReferralPitchWellbeingHub]: typeof SelfReferralPitchDialogueWellbeingHub
  [DialogueIDs.SelfReferralPitchDemoPrivateProviders]: typeof SelfReferralPitchDemoPrivateProvidersDialogue
  [DialogueIDs.SelfReferralDemo]: typeof SelfReferralDemoDialogue
  [DialogueIDs.SelfReferralEssex]: typeof SelfReferralEssexDialogue
  [DialogueIDs.SelfReferralCCG]: typeof SelfReferralCCGDialogue
  [DialogueIDs.SelfReferralWellbeingHub]: typeof SelfReferralWellbeingHubDialogue
  [DialogueIDs.SelfReferralWellbeingHubIndividual]: typeof SelfReferralWellbeingHubIndividualDialogue
  [DialogueIDs.SelfReferralWellbeingHubManagerIndividual]: typeof SelfReferralWellbeingHubManagerIndividualDialogue
  [DialogueIDs.SelfReferralWellbeingHubManagerTeam]: typeof SelfReferralWellbeingHubManagerTeamDialogue
  [DialogueIDs.SelfReferralTrentPTS]: typeof SelfReferralTrentPTSDialogue
  [DialogueIDs.SelfReferralGMHub]: typeof SelfReferralGMHubDialogue
  [DialogueIDs.SelfReferralGMHubManagerTeam]: typeof SelfReferralGMHubManagerTeamDialogue
  [DialogueIDs.SelfReferralGMHubManagerIndividual]: typeof SelfReferralGMHubManagerIndividualDialogue
  [DialogueIDs.SelfReferralGMHubIndividual]: typeof SelfReferralGMHubIndividualDialogue
  [DialogueIDs.SelfReferralHealix]: typeof SelfReferralHealixDialogue
  [DialogueIDs.SelfReferralInsight]: typeof SelfReferralInsightDialogue
  [DialogueIDs.SelfReferralITalk]: typeof SelfReferralITalkDialogue
  [DialogueIDs.SelfReferralNorfolk]: typeof SelfReferralNorfolkDialogue
  [DialogueIDs.SelfReferralNottinghamshire]: typeof SelfReferralNottinghamshireDialogue
  [DialogueIDs.SelfReferralSABP]: typeof SelfReferralSABPDialogue
  [DialogueIDs.SelfReferralLincolnshire]: typeof SelfReferralLincolnshireDialogue
  [DialogueIDs.SelfReferralVHG]: typeof SelfReferralVHGDialogue
  [DialogueIDs.SelfReferralDemoPrivateProviders]: typeof SelfReferralDemoPrivateProvidersDialogue
  [DialogueIDs.SelfReferralInsightWaitlist]: typeof SelfReferralInsightWaitlistDialogue
  [DialogueIDs.SelfReferralVitality]: typeof SelfReferralVitalityDialogue
  [DialogueIDs.SelfReferralLimbic]: typeof SelfReferralLimbicDialogue
  [DialogueIDs.ADSMConsent]: typeof ADSMConsentDialogue
  [DialogueIDs.ADSMConsentInsight]: typeof ADSMConsentInsightDialogue
  [DialogueIDs.SelfReferralMHM]: typeof SelfReferralMHMDialogue
  [DialogueIDs.AssessmentPitchIAPT]: typeof AssessmentPitchIAPTDialogue
  [DialogueIDs.AssessmentPitchGP]: typeof AssessmentPitchGPDialogue
  [DialogueIDs.AssessmentPitchCCG]: typeof AssessmentPitchCCGDialogue
  [DialogueIDs.AssessmentPitchWellbeingHub]: typeof AssessmentPitchWellbeingHubDialogue
  [DialogueIDs.AssessmentPitchEssex]: typeof AssessmentPitchEssexDialogue
  [DialogueIDs.AssessmentPitchTrentPTS]: typeof AssessmentPitchTrentPTSDialogue
  [DialogueIDs.AssessmentPitchGMHub]: typeof AssessmentPitchGMHubDialogue
  [DialogueIDs.AssessmentPitchHealix]: typeof AssessmentPitchHealixDialogue
  [DialogueIDs.AssessmentPitchInsight]: typeof AssessmentPitchInsightDialogue
  [DialogueIDs.AssessmentPitchITalk]: typeof AssessmentPitchITalkDialogue
  [DialogueIDs.AssessmentPitchInsightFollowUp]: typeof AssessmentPitchInsightFollowUpDialogue
  [DialogueIDs.AssessmentPitchLincolnshire]: typeof AssessmentPitchLincolnshireDialogue
  [DialogueIDs.AssessmentPitchNottinghamshire]: typeof AssessmentPitchNottinghamshireDialogue
  [DialogueIDs.AssessmentPitchNorfolk]: typeof AssessmentPitchNorfolkDialogue
  [DialogueIDs.AssessmentPitchSABP]: typeof AssessmentPitchSABPDialogue
  [DialogueIDs.AssessmentPitchVHG]: typeof AssessmentPitchVHGDialogue
  [DialogueIDs.AssessmentPitchDemoPrivateProviders]: typeof AssessmentPitchDemoPrivateProvidersDialogue
  [DialogueIDs.AssessmentPitchVitality]: typeof AssessmentPitchVitalityDialogue
  [DialogueIDs.AssessmentPitchMHM]: typeof AssessmentPitchMHMDialogue
  [DialogueIDs.AssessmentDemo]: typeof AssessmentDemoDialogue
  [DialogueIDs.AssessmentCCG]: typeof AssessmentCCGDialogue
  [DialogueIDs.AssessmentWellbeingHub]: typeof AssessmentWellbeingHubDialogue
  [DialogueIDs.AssessmentEssex]: typeof AssessmentEssexDialogue
  [DialogueIDs.AssessmentKentMedway]: typeof AssessmentKentMedwayDialogue
  [DialogueIDs.AssessmentSABP]: typeof AssessmentSABPDialogue
  [DialogueIDs.AssessmentGMHub]: typeof AssessmentGMHubDialogue
  [DialogueIDs.AssessmentHealix]: typeof AssessmentHealixDialogue
  [DialogueIDs.AssessmentInsight]: typeof AssessmentInsightDialogue
  [DialogueIDs.AssessmentADSMInsight]: typeof AssessmentADSMInsightDialogue
  [DialogueIDs.AssessmentITalk]: typeof AssessmentITalkDialogue
  [DialogueIDs.AssessmentNorfolk]: typeof AssessmentNorfolkDialogue
  [DialogueIDs.AssessmentNottinghamshire]: typeof AssessmentNottinghamshireDialogue
  [DialogueIDs.AssessmentVHG]: typeof AssessmentVHGDialogue
  [DialogueIDs.AssessmentTrentPTS]: typeof AssessmentTrentPTSDialogue
  [DialogueIDs.AssessmentLincolnshire]: typeof AssessmentLincolnshireDialogue
  [DialogueIDs.AssessmentDemoPrivateProviders]: typeof AssessmentDemoPrivateProvidersDialogue
  [DialogueIDs.AssessmentVitality]: typeof AssessmentVitalityDialogue
  [DialogueIDs.AssessmentMHM]: typeof AssessmentMHMDialogue
  [DialogueIDs.PHQ9]: typeof PHQ9Dialogue
  [DialogueIDs.PHQ9GMHub]: typeof PHQ9GMHubDialogue
  [DialogueIDs.PHQ9Norfolk]: typeof PHQ9NorfolkDialogue
  [DialogueIDs.PHQ9WellbeingHub]: typeof PHQ9WellbeingHubDialogue
  [DialogueIDs.PHQ9SABP]: typeof PHQ9SABPDialogue
  [DialogueIDs.PHQ9Nottinghamshire]: typeof PHQ9NottinghamshireDialogue
  [DialogueIDs.PHQ9VHG]: typeof PHQ9VHGDialogue
  [DialogueIDs.PHQ9TrentPTS]: typeof PHQ9TrentPTSDialogue
  [DialogueIDs.PHQ9Vitality]: typeof PHQ9VitalityDialogue
  [DialogueIDs.RiskPathwayDemo]: typeof RiskPathwayDemoDialogue
  [DialogueIDs.RiskPathwayCCG]: typeof RiskPathwayCCGDialogue
  [DialogueIDs.RiskPathwayGMHub]: typeof RiskPathwayGMHubDialogue
  [DialogueIDs.RiskPathwayWellbeingHub]: typeof RiskPathwayWellbeingHubDialogue
  [DialogueIDs.RiskPathwayEssex]: typeof RiskPathwayEssexDialogue
  [DialogueIDs.RiskPathwayTrentPTS]: typeof RiskPathwayTrentPTSDialogue
  [DialogueIDs.RiskPathwayHealix]: typeof RiskPathwayHealixDialogue
  [DialogueIDs.RiskPathwayInsight]: typeof RiskPathwayInsightDialogue
  [DialogueIDs.RiskPathwayInsightFollowUp]: typeof RiskPathwayInsightFollowUpDialogue
  [DialogueIDs.RiskPathwayITalk]: typeof RiskPathwayITalkDialogue
  [DialogueIDs.RiskPathwayNottinghamshire]: typeof RiskPathwayNottinghamshireDialogue
  [DialogueIDs.RiskPathwayNorfolk]: typeof RiskPathwayNorfolkDialogue
  [DialogueIDs.RiskPathwaySABP]: typeof RiskPathwaySABPDialogue
  [DialogueIDs.RiskPathwayVHG]: typeof RiskPathwayVHGDialogue
  [DialogueIDs.RiskPathwayLincolnshire]: typeof RiskPathwayLincolnshireDialogue
  [DialogueIDs.RiskPathwayDemoPrivateProviders]: typeof RiskPathwayDemoPrivateProvidersDialogue
  [DialogueIDs.RiskPathwayMHM]: typeof RiskPathwayMHMDialogue
  [DialogueIDs.Audit]: typeof AuditDialogue
  [DialogueIDs.DrugsAndSmoking]: typeof DrugsAndSmokingDialogue
  [DialogueIDs.ITQ]: typeof ITQDialogue
  [DialogueIDs.IRQA]: typeof IRQADialogue
  [DialogueIDs.RiskPathwayVitality]: typeof RiskPathwayVitalityDialogue
  [DialogueIDs.GAD7]: typeof GAD7Dialogue
  [DialogueIDs.IAPTPhobiaScale]: typeof IAPTPhobiaScaleDialogue
  [DialogueIDs.IAPTEmploymentStatus]: typeof IAPTEmploymentStatusDialogue
  [DialogueIDs.IAPTEmploymentStatusKM]: typeof IAPTEmploymentStatusKMDialogue
  [DialogueIDs.IAPTWorkAndSocialAdjustment]: typeof IAPTWorkAndSocialAdjustmentDialogue
  [DialogueIDs.IAPTMedication]: typeof IAPTMedicationDialogue
  [DialogueIDs.IAPTAccommodation]: typeof IAPTAccommodationDialogue
  [DialogueIDs.PCL5]: typeof PCL5Dialogue
  [DialogueIDs.PDSS]: typeof PDSSDialogue
  [DialogueIDs.SPIN]: typeof SPINDialogue
  [DialogueIDs.OCI]: typeof OCIDialogue
  [DialogueIDs.SHAI18]: typeof SHAI18Dialogue
  [DialogueIDs.SpecificPhobiaIntro]: typeof SpecificPhobiaIntroDialogue
  [DialogueIDs.SpecificPhobia]: typeof SpecificPhobiaDialogue
  [DialogueIDs.TreatmentOptionsIAPT]: typeof TreatmentOptionsIAPTDialogue
  [DialogueIDs.TreatmentOptionsCCG]: typeof TreatmentOptionsCCGDialogue
  [DialogueIDs.TreatmentOptionsTrentPTS]: typeof TreatmentOptionsTrentPTSDialogue
  [DialogueIDs.TreatmentOptionsSABP]: typeof TreatmentOptionsSABPDialogue
  [DialogueIDs.TreatmentOptionsHealix]: typeof TreatmentOptionsHealixDialogue
  [DialogueIDs.TreatmentOptionsInsight]: typeof TreatmentOptionsInsightDialogue
  [DialogueIDs.TreatmentOptionsVHG]: typeof TreatmentOptionsVHGDialogue
  [DialogueIDs.TreatmentOptionsDemoPrivateProviders]: typeof TreatmentOptionsDemoPrivateProvidersDialogue
  [DialogueIDs.TreatmentOptionsVitality]: typeof TreatmentOptionsVitalityDialogue
  [DialogueIDs.BookMentalHealthProfessionalKM]: typeof BookMentalHealthProfessionalKMDialogue
  [DialogueIDs.WellBeingCCG]: typeof WellbeingCCGDialogue
  [DialogueIDs.WellbeingHealix]: typeof WellbeingHealixDialogue
  [DialogueIDs.WellbeingDemoPrivateProviders]: typeof WellbeingDemoPrivateProvidersDialogue
  [DialogueIDs.GoodbyeDemo]: typeof GoodbyeDemoDialogue
  [DialogueIDs.GoodbyeCCG]: typeof GoodbyeCCGDialogue
  [DialogueIDs.GoodbyeWellbeingHub]: typeof GoodbyeWellbeingHubDialogue
  [DialogueIDs.GoodbyeGMHub]: typeof GoodbyeGMHubDialogue
  [DialogueIDs.GoodbyeEssex]: typeof GoodbyeEssexDialogue
  [DialogueIDs.GoodbyeTrentPTS]: typeof GoodbyeTrentPTSDialogue
  [DialogueIDs.GoodbyeSABP]: typeof GoodbyeSABPDialogue
  [DialogueIDs.GoodbyeHealix]: typeof GoodbyeHealixDialogue
  [DialogueIDs.GoodbyeInsight]: typeof GoodbyeInsightDialogue
  [DialogueIDs.GoodbyeInsightFollowUp]: typeof GoodbyeInsightFollowUpDialogue
  [DialogueIDs.GoodbyeITalk]: typeof GoodbyeITalkDialogue
  [DialogueIDs.GoodbyeNottinghamshire]: typeof GoodbyeNottinghamshireDialogue
  [DialogueIDs.GoodbyeNorfolk]: typeof GoodbyeNorfolkDialogue
  [DialogueIDs.GoodbyeVHG]: typeof GoodbyeVHGDialogue
  [DialogueIDs.GoodbyeMHM]: typeof GoodbyeMHMDialogue
  [DialogueIDs.GoodbyeLincolnshire]: typeof GoodbyeLincolnshireDialogue
  [DialogueIDs.GoodbyeDemoPrivateProviders]: typeof GoodbyeDemoPrivateProvidersDialogue
  [DialogueIDs.GoodbyeVitality]: typeof GoodbyeVitalityDialogue
  [DialogueIDs.CrisisDemo]: typeof CrisisDemoDialogue
  [DialogueIDs.CrisisCCG]: typeof CrisisCCGDialogue
  [DialogueIDs.CrisisGMHub]: typeof CrisisGMHubDialogue
  [DialogueIDs.CrisisWellbeingHub]: typeof CrisisWellbeingHubDialogue
  [DialogueIDs.CrisisEssex]: typeof CrisisEssexDialogue
  [DialogueIDs.CrisisTrentPTS]: typeof CrisisTrentPTSDialogue
  [DialogueIDs.CrisisHealix]: typeof CrisisHealixDialogue
  [DialogueIDs.CrisisInsight]: typeof CrisisInsightDialogue
  [DialogueIDs.CrisisITalk]: typeof CrisisITalkDialogue
  [DialogueIDs.CrisisNottinghamshire]: typeof CrisisNottinghamshireDialogue
  [DialogueIDs.CrisisNorfolk]: typeof CrisisNorfolkDialogue
  [DialogueIDs.CrisisSABP]: typeof CrisisSABPDialogue
  [DialogueIDs.CrisisVHG]: typeof CrisisVHGDialogue
  [DialogueIDs.CrisisLincolnshire]: typeof CrisisLincolnshireDialogue
  [DialogueIDs.CrisisDemoPrivateProviders]: typeof CrisisDemoPrivateProvidersDialogue
  [DialogueIDs.CrisisVitality]: typeof CrisisVitalityDialogue
  [DialogueIDs.CrisisMHM]: typeof CrisisMHMDialogue
  [DialogueIDs.CrisisLimbic]: typeof CrisisLimbicDialogue
  [DialogueIDs.BookAppointmentVitality]: typeof BookAppointmentVitalityDialogue
  [DialogueIDs.BookAppointmentITalk]: typeof BookAppointmentITalkDialogue
  [DialogueIDs.CollectPhoneNumber]: typeof CollectPhoneNumberDialogue
  [DialogueIDs.CollectPhoneNumberEssex]: typeof CollectPhoneNumberEssexDialogue
  [DialogueIDs.CollectPhoneNumberInsight]: typeof CollectPhoneNumberInsightDialogue
  [DialogueIDs.CollectPhoneNumberITalk]: typeof CollectPhoneNumberITalkDialogue
  [DialogueIDs.CollectPhoneNumberNorfolk]: typeof CollectPhoneNumberNorfolkDialogue
  [DialogueIDs.CollectPhoneNumberMHM]: typeof CollectPhoneNumberMHMDialogue
  [DialogueIDs.CollectAlcoholConsumption]: typeof CollectAlcoholConsumptionDialogue
}

export class DialoguesRegistry {
  readonly #registry: Partial<IDialoguesRegistry>

  constructor() {
    this.#registry = {}
  }

  get<T extends keyof IDialoguesRegistry>(id: T): IDialoguesRegistry[T] {
    if (!this.#registry[id]) this.#registry[id] = DialoguesRegistry.require(id)
    return this.#registry[id] as IDialoguesRegistry[T]
  }

  /**
   * Before you WTF yourself to death looking at this, let
   * me shine some light into what happens here. This is
   * basically a replacement for inline requires or imports
   * from one dialogue to another in order to avoid require
   * cycles. Because of how the bot and the dialogues are
   * structured, each dialogue is responsible for pushing
   * the next dialogue into the dialogues stack, which means
   * a lot of dialogues have knowledge of the next dialogue
   * after them, so they need to instantiate it within their
   * own steps. So if import the next dialogue right in the
   * same file, then that next dialogue might be importing
   * another dialogue which in turn could be looping back by
   * importing the first dialogue
   * ¯\_(ツ)_/¯
   */
  private static require<T extends keyof IDialoguesRegistry>(id: T): IDialoguesRegistry[T] {
    switch (id) {
      case DialogueIDs.MainFlow:
        return require("./dialogues/MainFlowDialogue").default
      case DialogueIDs.IntroductionIAPT:
        return require("./dialogues/introduction/IntroductionIAPTDialogue").default
      case DialogueIDs.LeadClassificationLimbic:
        return require("./dialogues/leadClassification/LeadClassificationLimbicDialogue").default
      case DialogueIDs.LearnAboutLimbic:
        return require("./dialogues/learnAbout/LearnAboutLimbicDialogue").default
      case DialogueIDs.IntroductionGP:
        return require("./dialogues/introduction/IntroductionGPDialogue").default
      case DialogueIDs.IntroductionCCG:
        return require("./dialogues/introduction/IntroductionCCGDialogue").default
      case DialogueIDs.IntroductionWellbeingHub:
        return require("./dialogues/introduction/IntroductionWellbeingHubDialogue").default
      case DialogueIDs.IntroductionEssex:
        return require("./dialogues/introduction/IntroductionEssexDialogue").default
      case DialogueIDs.IntroductionHealix:
        return require("./dialogues/introduction/IntroductionHealixDialogue").default
      case DialogueIDs.IntroductionGMHub:
        return require("./dialogues/introduction/IntroductionGMHubDialogue").default
      case DialogueIDs.IntroductionDemoPrivateProviders:
        return require("./dialogues/introduction/IntroductionDemoPrivateProvidersDialogue").default
      case DialogueIDs.IntroductionInsight:
        return require("./dialogues/introduction/IntroductionInsightDialogue").default
      case DialogueIDs.IntroductionInsightWaitlist:
        return require("./dialogues/introduction/IntroductionInsightWaitlistDialogue").default
      case DialogueIDs.IntroductionInsightFollowUp:
        return require("./dialogues/introduction/IntroductionInsightFollowUpDialogue").default
      case DialogueIDs.IntroductionITalk:
        return require("./dialogues/introduction/IntroductionITalkDialogue").default
      case DialogueIDs.IntroductionVitality:
        return require("./dialogues/introduction/IntroductionVitalityDialogue").default
      case DialogueIDs.IntroductionVHG:
        return require("./dialogues/introduction/IntroductionVHGDialogue").default
      case DialogueIDs.IntroductionLimbic:
        return require("./dialogues/introduction/IntroductionLimbicDialogue").default
      case DialogueIDs.GetNameEssex:
        return require("./dialogues/getName/GetNameEssexDialogue").default
      case DialogueIDs.GetNameIAPT:
        return require("./dialogues/getName/GetNameIAPTDialogue").default
      case DialogueIDs.GetNameITalk:
        return require("./dialogues/getName/GetNameITalkDialogue").default
      case DialogueIDs.GetNameCCG:
        return require("./dialogues/getName/GetNameCCGDialogue").default
      case DialogueIDs.GetNameInsight:
        return require("./dialogues/getName/GetNameInsightDialogue").default
      case DialogueIDs.GetNameLincolnshire:
        return require("./dialogues/getName/GetNameLincolnshireDialogue").default
      case DialogueIDs.GetNameHealix:
        return require("./dialogues/getName/GetNameHealixDialogue").default
      case DialogueIDs.GetNameNottinghamshire:
        return require("./dialogues/getName/GetNameNottinghamshireDialogue").default
      case DialogueIDs.GetNameTrentPTS:
        return require("./dialogues/getName/GetNameTrentPTSDialogue").default
      case DialogueIDs.GetNameDemoPrivateProviders:
        return require("./dialogues/getName/GetNameDemoPrivateProvidersDialogue").default
      case DialogueIDs.GetNameInsightWaitlist:
        return require("./dialogues/getName/GetNameInsightWaitlistDialogue").default
      case DialogueIDs.GetNameInsightFollowUp:
        return require("./dialogues/getName/GetNameInsightFollowUpDialogue").default
      case DialogueIDs.GetNameNorfolk:
        return require("./dialogues/getName/GetNameNorfolkDialogue").default
      case DialogueIDs.GetNameSABP:
        return require("./dialogues/getName/GetNameSABPDialogue").default
      case DialogueIDs.GetNameVHG:
        return require("./dialogues/getName/GetNameVHGDialogue").default
      case DialogueIDs.GetNameVitality:
        return require("./dialogues/getName/GetNameVitalityDialogue").default
      case DialogueIDs.GetNameMHM:
        return require("./dialogues/getName/GetNameMHMDialogue").default
      case DialogueIDs.GetNameLimbic:
        return require("./dialogues/getName/GetNameLimbicDialogue").default
      case DialogueIDs.EligibilityCheckDemo:
        return require("./dialogues/eligibility/EligibilityCheckDemoDialogue").default
      case DialogueIDs.EligibilityCheckEssex:
        return require("./dialogues/eligibility/EligibilityCheckEssexDialogue").default
      case DialogueIDs.EligibilityCheckIAPT:
        return require("./dialogues/eligibility/EligibilityCheckIAPTDialogue").default
      case DialogueIDs.EligibilityCheckITalk:
        return require("./dialogues/eligibility/EligibilityCheckITalkDialogue").default
      case DialogueIDs.EligibilityCheckGP:
        return require("./dialogues/eligibility/EligibilityCheckGPDialogue").default
      case DialogueIDs.EligibilityCheckCCG:
        return require("./dialogues/eligibility/EligibilityCheckCCGDialogue").default
      case DialogueIDs.EligibilityCheckWellbeingHub:
        return require("./dialogues/eligibility/EligibilityCheckWellbeingHubDialogue").default
      case DialogueIDs.EligibilityCheckTrentPTS:
        return require("./dialogues/eligibility/EligibilityCheckTrentPTSDialogue").default
      case DialogueIDs.EligibilityCheckGMHub:
        return require("./dialogues/eligibility/EligibilityCheckGMHubDialogue").default
      case DialogueIDs.EligibilityCheckTrentPTSDemo:
        return require("./dialogues/eligibility/EligibilityCheckTrentPTSDemoDialogue").default
      case DialogueIDs.EligibilityCheckInsight:
        return require("./dialogues/eligibility/EligibilityCheckInsightDialogue").default
      case DialogueIDs.EligibilityCheckNorfolk:
        return require("./dialogues/eligibility/EligibilityCheckNorfolkDialogue").default
      case DialogueIDs.EligibilityCheckLincolnshire:
        return require("./dialogues/eligibility/EligibilityCheckLincolnshireDialogue").default
      case DialogueIDs.EligibilityCheckNottinghamshire:
        return require("./dialogues/eligibility/EligibilityCheckNottinghamshireDialogue").default
      case DialogueIDs.EligibilityCheckSABP:
        return require("./dialogues/eligibility/EligibilityCheckSABPDialogue").default
      case DialogueIDs.EligibilityCheckVHG:
        return require("./dialogues/eligibility/EligibilityCheckVHGDialogue").default
      case DialogueIDs.EligibilityCheckVitality:
        return require("./dialogues/eligibility/EligibilityCheckVitalityDialogue").default
      case DialogueIDs.EligibilityCheckMHM:
        return require("./dialogues/eligibility/EligibilityCheckMHMDialogue").default
      case DialogueIDs.SuitableServicesSignpostIAPT:
        return require("./dialogues/signposts/SuitableServicesSignpostIAPTDialogue").default
      case DialogueIDs.SuitableServicesSignpostCCG:
        return require("./dialogues/signposts/SuitableServicesSignpostCCGDialogue").default
      case DialogueIDs.SuitableServicesSignpostSABP:
        return require("./dialogues/signposts/SuitableServicesSignpostSABPDialogue").default
      case DialogueIDs.SuitableServicesSignpostEssex:
        return require("./dialogues/signposts/SuitableServicesSignpostEssexDialogue").default
      case DialogueIDs.UnderageSignpost:
        return require("./dialogues/signposts/UnderAgeSignpostDialogue").default
      case DialogueIDs.UnderageSignpostGMHub:
        return require("./dialogues/signposts/UnderAgeSignpostGMHubDialogue").default
      case DialogueIDs.UnderageSignpostNorfolk:
        return require("./dialogues/signposts/UnderAgeSignpostNorfolkDialogue").default
      case DialogueIDs.UnderageSignpostWellbeingHub:
        return require("./dialogues/signposts/UnderAgeSignpostWellbeingHubDialogue").default
      case DialogueIDs.UnderageSignpostITalk:
        return require("./dialogues/signposts/UnderAgeSignpostITalkDialogue").default
      case DialogueIDs.UnderageSignpostEssex:
        return require("./dialogues/signposts/UnderAgeSignpostEssexDialogue").default
      case DialogueIDs.UnderageSignpostLincolnshire:
        return require("./dialogues/signposts/UnderAgeSignpostLincolnshireDialogue").default
      case DialogueIDs.UnderageSignpostSABP:
        return require("./dialogues/signposts/UnderAgeSignpostSABPDialogue").default
      case DialogueIDs.PeaceOfMindIAPT:
        return require("./dialogues/peaceOfMind/PeaceOfMindIAPTDialogue").default
      case DialogueIDs.PeaceOfMindGP:
        return require("./dialogues/peaceOfMind/PeaceOfMindGPDialogue").default
      case DialogueIDs.PeaceOfMindCCG:
        return require("./dialogues/peaceOfMind/PeaceOfMindCCGDialogue").default
      case DialogueIDs.PeaceOfMindWellbeingHub:
        return require("./dialogues/peaceOfMind/PeaceOfMindWellbeingHubDialogue").default
      case DialogueIDs.PeaceOfMindHealix:
        return require("./dialogues/peaceOfMind/PeaceOfMindHealixDialogue").default
      case DialogueIDs.PeaceOfMindDemoPrivateProviders:
        return require("./dialogues/peaceOfMind/PeaceOfMindDemoPrivateProvidersDialogue").default
      case DialogueIDs.PeaceOfMindVitality:
        return require("./dialogues/peaceOfMind/PeaceOfMindVitalityDialogue").default
      case DialogueIDs.PermissionsEssex:
        return require("./dialogues/permissions/PermissionsEssexDialogue").default
      case DialogueIDs.PermissionsIAPT:
        return require("./dialogues/permissions/PermissionsIAPTDialogue").default
      case DialogueIDs.PermissionsCCG:
        return require("./dialogues/permissions/PermissionsCCGDialogue").default
      case DialogueIDs.PermissionsNorfolk:
        return require("./dialogues/permissions/PermissionsNorfolkDialogue").default
      case DialogueIDs.PermissionsWellbeingHub:
        return require("./dialogues/permissions/PermissionsWellbeingHubDialogue").default
      case DialogueIDs.PermissionsGMHub:
        return require("./dialogues/permissions/PermissionsGMHubDialogue").default
      case DialogueIDs.PermissionsSABP:
        return require("./dialogues/permissions/PermissionsSABPDialogue").default
      case DialogueIDs.PermissionsInsight:
        return require("./dialogues/permissions/PermissionsInsightDialogue").default
      case DialogueIDs.PermissionsDemoPrivateProviders:
        return require("./dialogues/permissions/PermissionsDemoPrivateProvidersDialogue").default
      case DialogueIDs.PermissionsVitality:
        return require("./dialogues/permissions/PermissionsVitalityDialogue").default
      case DialogueIDs.PermissionsMHM:
        return require("./dialogues/permissions/PermissionsMHMDialogue").default
      case DialogueIDs.SelfReferralPitchIAPT:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchIAPTDialogue").default
      case DialogueIDs.SelfReferralPitchHealix:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchHealixDialogue").default
      case DialogueIDs.SelfReferralPitchGMHub:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchGMHubDialogue").default
      case DialogueIDs.SelfReferralPitchCCG:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchDialogueCCG").default
      case DialogueIDs.SelfReferralPitchWellbeingHub:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchDialogueWellbeingHub")
          .default
      case DialogueIDs.SelfReferralPitchDemoPrivateProviders:
        return require("./dialogues/selfReferralPitch/SelfReferralPitchDemoPrivateProvidersDialogue")
          .default
      case DialogueIDs.SelfReferralDemo:
        return require("./dialogues/selfReferral/SelfReferralDemoDialogue").default
      case DialogueIDs.SelfReferralEssex:
        return require("./dialogues/selfReferral/SelfReferralEssexDialogue").default
      case DialogueIDs.SelfReferralCCG:
        return require("./dialogues/selfReferral/SelfReferralCCGDialogue").default
      case DialogueIDs.SelfReferralWellbeingHub:
        return require("./dialogues/selfReferral/SelfReferralWellbeingHubDialogue").default
      case DialogueIDs.SelfReferralWellbeingHubIndividual:
        return require("./dialogues/selfReferral/SelfReferralWellbeingHubIndividualDialogue")
          .default
      case DialogueIDs.SelfReferralWellbeingHubManagerIndividual:
        return require("./dialogues/selfReferral/SelfReferralWellbeingHubManagerIndividualDialogue")
          .default
      case DialogueIDs.SelfReferralWellbeingHubManagerTeam:
        return require("./dialogues/selfReferral/SelfReferralWellbeingHubManagerTeamDialogue")
          .default
      case DialogueIDs.SelfReferralTrentPTS:
        return require("./dialogues/selfReferral/SelfReferralTrentPTSDialogue").default
      case DialogueIDs.SelfReferralGMHub:
        return require("./dialogues/selfReferral/SelfReferralGMHubDialogue").default
      case DialogueIDs.SelfReferralGMHubManagerTeam:
        return require("./dialogues/selfReferral/SelfReferralGMHubManagerTeamDialogue").default
      case DialogueIDs.SelfReferralGMHubManagerIndividual:
        return require("./dialogues/selfReferral/SelfReferralGMHubManagerIndividualDialogue")
          .default
      case DialogueIDs.SelfReferralGMHubIndividual:
        return require("./dialogues/selfReferral/SelfReferralGMHubIndividualDialogue").default
      case DialogueIDs.SelfReferralHealix:
        return require("./dialogues/selfReferral/SelfReferralHealixDialogue").default
      case DialogueIDs.SelfReferralInsight:
        return require("./dialogues/selfReferral/SelfReferralInsightDialogue").default
      case DialogueIDs.SelfReferralITalk:
        return require("./dialogues/selfReferral/SelfReferralITalkDialogue").default
      case DialogueIDs.SelfReferralNorfolk:
        return require("./dialogues/selfReferral/SelfReferralNorfolkDialogue").default
      case DialogueIDs.SelfReferralNottinghamshire:
        return require("./dialogues/selfReferral/SelfReferralNottinghamshireDialogue").default
      case DialogueIDs.SelfReferralSABP:
        return require("./dialogues/selfReferral/SelfReferralSABPDialogue").default
      case DialogueIDs.SelfReferralLincolnshire:
        return require("./dialogues/selfReferral/SelfReferralLincolnshireDialogue").default
      case DialogueIDs.SelfReferralVHG:
        return require("./dialogues/selfReferral/SelfReferralVHGDialogue").default
      case DialogueIDs.SelfReferralDemoPrivateProviders:
        return require("./dialogues/selfReferral/SelfReferralDemoPrivateProvidersDialogue").default
      case DialogueIDs.SelfReferralInsightWaitlist:
        return require("./dialogues/selfReferral/SelfReferralInsightWaitlistDialogue").default
      case DialogueIDs.SelfReferralVitality:
        return require("./dialogues/selfReferral/SelfReferralVitalityDialogue").default
      case DialogueIDs.SelfReferralLimbic:
        return require("./dialogues/selfReferral/SelfReferralLimbicDialogue").default
      case DialogueIDs.ADSMConsent:
        return require("./dialogues/adsmConsent/ADSMConsentDialogue").default
      case DialogueIDs.ADSMConsentInsight:
        return require("./dialogues/adsmConsent/ADSMConsentInsightDialogue").default
      case DialogueIDs.SelfReferralMHM:
        return require("./dialogues/selfReferral/SelfReferralMHMDialogue").default
      case DialogueIDs.AssessmentPitchIAPT:
        return require("./dialogues/assessmentPitch/AssessmentPitchIAPTDialogue").default
      case DialogueIDs.AssessmentPitchGP:
        return require("./dialogues/assessmentPitch/AssessmentPitchGPDialogue").default
      case DialogueIDs.AssessmentPitchCCG:
        return require("./dialogues/assessmentPitch/AssessmentPitchCCGDialogue").default
      case DialogueIDs.AssessmentPitchWellbeingHub:
        return require("./dialogues/assessmentPitch/AssessmentPitchWellbeingHubDialogue").default
      case DialogueIDs.AssessmentPitchEssex:
        return require("./dialogues/assessmentPitch/AssessmentPitchEssexDialogue").default
      case DialogueIDs.AssessmentPitchTrentPTS:
        return require("./dialogues/assessmentPitch/AssessmentPitchTrentPTSDialogue").default
      case DialogueIDs.AssessmentPitchGMHub:
        return require("./dialogues/assessmentPitch/AssessmentPitchGMHubDialogue").default
      case DialogueIDs.AssessmentPitchHealix:
        return require("./dialogues/assessmentPitch/AssessmentPitchHealixDialogue").default
      case DialogueIDs.AssessmentPitchInsight:
        return require("./dialogues/assessmentPitch/AssessmentPitchInsightDialogue").default
      case DialogueIDs.AssessmentPitchITalk:
        return require("./dialogues/assessmentPitch/AssessmentPitchITalkDialogue").default
      case DialogueIDs.AssessmentPitchInsightFollowUp:
        return require("./dialogues/assessmentPitch/AssessmentPitchInsightFollowUpDialogue").default
      case DialogueIDs.AssessmentPitchLincolnshire:
        return require("./dialogues/assessmentPitch/AssessmentPitchLincolnshireDialogue").default
      case DialogueIDs.AssessmentPitchNottinghamshire:
        return require("./dialogues/assessmentPitch/AssessmentPitchNottinghamshireDialogue").default
      case DialogueIDs.AssessmentPitchNorfolk:
        return require("./dialogues/assessmentPitch/AssessmentPitchNorfolkDialogue").default
      case DialogueIDs.AssessmentPitchSABP:
        return require("./dialogues/assessmentPitch/AssessmentPitchSABPDialogue").default
      case DialogueIDs.AssessmentPitchVHG:
        return require("./dialogues/assessmentPitch/AssessmentPitchVHGDialogue").default
      case DialogueIDs.AssessmentPitchDemoPrivateProviders:
        return require("./dialogues/assessmentPitch/AssessmentPitchDemoPrivateProvidersDialogue")
          .default
      case DialogueIDs.AssessmentPitchVitality:
        return require("./dialogues/assessmentPitch/AssessmentPitchVitalityDialogue").default
      case DialogueIDs.AssessmentPitchMHM:
        return require("./dialogues/assessmentPitch/AssessmentPitchMHMDialogue").default
      case DialogueIDs.AssessmentDemo:
        return require("./dialogues/assessment/AssessmentDemoDialogue").default
      case DialogueIDs.AssessmentCCG:
        return require("./dialogues/assessment/AssessmentCCGDialogue").default
      case DialogueIDs.AssessmentWellbeingHub:
        return require("./dialogues/assessment/AssessmentWellbeingHubDialogue").default
      case DialogueIDs.AssessmentEssex:
        return require("./dialogues/assessment/AssessmentEssexDialogue").default
      case DialogueIDs.AssessmentKentMedway:
        return require("./dialogues/assessment/AssessmentKentMedwayDialogue").default
      case DialogueIDs.AssessmentSABP:
        return require("./dialogues/assessment/AssessmentSABPDialogue").default
      case DialogueIDs.AssessmentGMHub:
        return require("./dialogues/assessment/AssessmentGMHubDialogue").default
      case DialogueIDs.AssessmentHealix:
        return require("./dialogues/assessment/AssessmentHealixDialogue").default
      case DialogueIDs.AssessmentInsight:
        return require("./dialogues/assessment/AssessmentInsightDialogue").default
      case DialogueIDs.AssessmentADSMInsight:
        return require("./dialogues/assessment/AssessmentADSMInsightDialogue").default
      case DialogueIDs.AssessmentITalk:
        return require("./dialogues/assessment/AssessmentITalkDialogue").default
      case DialogueIDs.AssessmentNorfolk:
        return require("./dialogues/assessment/AssessmentNorfolkDialogue").default
      case DialogueIDs.AssessmentNottinghamshire:
        return require("./dialogues/assessment/AssessmentNottinghamshireDialogue").default
      case DialogueIDs.AssessmentVHG:
        return require("./dialogues/assessment/AssessmentVHGDialogue").default
      case DialogueIDs.AssessmentTrentPTS:
        return require("./dialogues/assessment/AssessmentTrentPTSDialogue").default
      case DialogueIDs.AssessmentLincolnshire:
        return require("./dialogues/assessment/AssessmentLincolnshireDialogue").default
      case DialogueIDs.AssessmentDemoPrivateProviders:
        return require("./dialogues/assessment/AssessmentDemoPrivateProvidersDialogue").default
      case DialogueIDs.AssessmentVitality:
        return require("./dialogues/assessment/AssessmentVitalityDialogue").default
      case DialogueIDs.AssessmentMHM:
        return require("./dialogues/assessment/AssessmentMHMDialogue").default
      case DialogueIDs.PHQ9:
        return require("./surveys/PHQ9/PHQ9").default
      case DialogueIDs.PHQ9GMHub:
        return require("./surveys/PHQ9/PHQ9GMHubDialogue").default
      case DialogueIDs.PHQ9Norfolk:
        return require("./surveys/PHQ9/PHQ9NorfolkDialogue").default
      case DialogueIDs.PHQ9WellbeingHub:
        return require("./surveys/PHQ9/PHQ9WellbeingHubDialogue").default
      case DialogueIDs.PHQ9SABP:
        return require("./surveys/PHQ9/PHQ9SABPDialogue").default
      case DialogueIDs.PHQ9Nottinghamshire:
        return require("./surveys/PHQ9/PHQ9NottinghamshireDialogue").default
      case DialogueIDs.PHQ9VHG:
        return require("./surveys/PHQ9/PHQ9VHGDialogue").default
      case DialogueIDs.PHQ9TrentPTS:
        return require("./surveys/PHQ9/PHQ9TrentPTSDialogue").default
      case DialogueIDs.PHQ9Vitality:
        return require("./surveys/PHQ9/PHQ9VitalityDialogue").default
      case DialogueIDs.RiskPathwayDemo:
        return require("./dialogues/riskPathway/RiskPathwayDemoDialogue").default
      case DialogueIDs.RiskPathwayCCG:
        return require("./dialogues/riskPathway/RiskPathwayCCGDialogue").default
      case DialogueIDs.RiskPathwayGMHub:
        return require("./dialogues/riskPathway/RiskPathwayGMHubDialogue").default
      case DialogueIDs.RiskPathwayWellbeingHub:
        return require("./dialogues/riskPathway/RiskPathwayWellbeingHubDialogue").default
      case DialogueIDs.RiskPathwayEssex:
        return require("./dialogues/riskPathway/RiskPathwayEssexDialogue").default
      case DialogueIDs.RiskPathwayTrentPTS:
        return require("./dialogues/riskPathway/RiskPathwayTrentPTSDialogue").default
      case DialogueIDs.RiskPathwayHealix:
        return require("./dialogues/riskPathway/RiskPathwayHealixDialogue").default
      case DialogueIDs.RiskPathwayInsight:
        return require("./dialogues/riskPathway/RiskPathwayInsightDialogue").default
      case DialogueIDs.RiskPathwayInsightFollowUp:
        return require("./dialogues/riskPathway/RiskPathwayInsightFollowUpDialogue").default
      case DialogueIDs.RiskPathwayITalk:
        return require("./dialogues/riskPathway/RiskPathwayITalkDialogue").default
      case DialogueIDs.RiskPathwayNottinghamshire:
        return require("./dialogues/riskPathway/RiskPathwayNottinghamshireDialogue").default
      case DialogueIDs.RiskPathwayNorfolk:
        return require("./dialogues/riskPathway/RiskPathwayNorfolkDialogue").default
      case DialogueIDs.RiskPathwaySABP:
        return require("./dialogues/riskPathway/RiskPathwaySABPDialogue").default
      case DialogueIDs.RiskPathwayVHG:
        return require("./dialogues/riskPathway/RiskPathwayVHGDialogue").default
      case DialogueIDs.RiskPathwayLincolnshire:
        return require("./dialogues/riskPathway/RiskPathwayLincolnshireDialogue").default
      case DialogueIDs.RiskPathwayDemoPrivateProviders:
        return require("./dialogues/riskPathway/RiskPathwayDemoPrivateProvidersDialogue").default
      case DialogueIDs.RiskPathwayMHM:
        return require("./dialogues/riskPathway/RiskPathwayMHMDialogue").default
      case DialogueIDs.Audit:
        return require("./surveys/Audit").default
      case DialogueIDs.DrugsAndSmoking:
        return require("./surveys/DrugsAndSmoking").default
      case DialogueIDs.ITQ:
        return require("./surveys/ITQ").default
      case DialogueIDs.IRQA:
        return require("./surveys/IRQ-A").default
      case DialogueIDs.RiskPathwayVitality:
        return require("./dialogues/riskPathway/RiskPathwayVitalityDialogue").default
      case DialogueIDs.GAD7:
        return require("./surveys/GAD7").default
      case DialogueIDs.IAPTPhobiaScale:
        return require("./surveys/IAPTPhobiaScale").default
      case DialogueIDs.IAPTEmploymentStatus:
        return require("./surveys/IAPTEmploymentStatus").default
      case DialogueIDs.IAPTEmploymentStatusKM:
        return require("./surveys/IAPTEmploymentStatusKM").default
      case DialogueIDs.IAPTWorkAndSocialAdjustment:
        return require("./surveys/IAPTWorkAndSocialAdjustment").default
      case DialogueIDs.IAPTMedication:
        return require("./surveys/IAPTMedication").default
      case DialogueIDs.IAPTAccommodation:
        return require("./surveys/IAPTAccommodation").default
      case DialogueIDs.PCL5:
        return require("./surveys/PCL5").default
      case DialogueIDs.PDSS:
        return require("./surveys/PDSS").default
      case DialogueIDs.SPIN:
        return require("./surveys/SPIN").default
      case DialogueIDs.OCI:
        return require("./surveys/OCI").default
      case DialogueIDs.SHAI18:
        return require("./surveys/SHAI18").default
      case DialogueIDs.SpecificPhobiaIntro:
        return require("./surveys/SpecificPhobiaIntro").default
      case DialogueIDs.SpecificPhobia:
        return require("./surveys/SpecificPhobia").default
      case DialogueIDs.TreatmentOptionsIAPT:
        return require("./dialogues/treatmentOptions/TreatmentOptionsIAPTDialogue").default
      case DialogueIDs.TreatmentOptionsCCG:
        return require("./dialogues/treatmentOptions/TreatmentOptionsCCGDialogue").default
      case DialogueIDs.TreatmentOptionsTrentPTS:
        return require("./dialogues/treatmentOptions/TreatmentOptionsTrentPTSDialogue").default
      case DialogueIDs.TreatmentOptionsHealix:
        return require("./dialogues/treatmentOptions/TreatmentOptionsHealixDialogue").default
      case DialogueIDs.TreatmentOptionsSABP:
        return require("./dialogues/treatmentOptions/TreatmentOptionsSABPDialogue").default
      case DialogueIDs.TreatmentOptionsInsight:
        return require("./dialogues/treatmentOptions/TreatmentOptionsInsightDialogue").default
      case DialogueIDs.TreatmentOptionsVHG:
        return require("./dialogues/treatmentOptions/TreatmentOptionsVHGDialogue").default
      case DialogueIDs.TreatmentOptionsDemoPrivateProviders:
        return require("./dialogues/treatmentOptions/TreatmentOptionsDemoPrivateProvidersDialogue")
          .default
      case DialogueIDs.TreatmentOptionsVitality:
        return require("./dialogues/treatmentOptions/TreatmentOptionsVitalityDialogue").default
      case DialogueIDs.BookMentalHealthProfessionalKM:
        return require("./dialogues/bookProfessional/BookMentalHealthProfessionalKMDialogue")
          .default
      case DialogueIDs.WellBeingCCG:
        return require("./dialogues/wellbeing/WellbeingCCGDialogue").default
      case DialogueIDs.WellbeingHealix:
        return require("./dialogues/wellbeing/WellbeingHealixDialogue").default
      case DialogueIDs.WellbeingDemoPrivateProviders:
        return require("./dialogues/wellbeing/WellbeingDemoPrivateProvidersDialogue").default
      case DialogueIDs.GoodbyeDemo:
        return require("./dialogues/goodbye/GoodbyeDemoDialogue").default
      case DialogueIDs.GoodbyeCCG:
        return require("./dialogues/goodbye/GoodbyeCCGDialogue").default
      case DialogueIDs.GoodbyeGMHub:
        return require("./dialogues/goodbye/GoodbyeGMHubDialogue").default
      case DialogueIDs.GoodbyeWellbeingHub:
        return require("./dialogues/goodbye/GoodbyeWellbeingHubDialogue").default
      case DialogueIDs.GoodbyeEssex:
        return require("./dialogues/goodbye/GoodbyeEssexDialogue").default
      case DialogueIDs.GoodbyeTrentPTS:
        return require("./dialogues/goodbye/GoodbyeTrentPTSDialogue").default
      case DialogueIDs.GoodbyeHealix:
        return require("./dialogues/goodbye/GoodbyeHealixDialogue").default
      case DialogueIDs.GoodbyeInsight:
        return require("./dialogues/goodbye/GoodbyeInsightDialogue").default
      case DialogueIDs.GoodbyeInsightFollowUp:
        return require("./dialogues/goodbye/GoodbyeInsightFollowUpDialogue").default
      case DialogueIDs.GoodbyeITalk:
        return require("./dialogues/goodbye/GoodbyeITalkDialogue").default
      case DialogueIDs.GoodbyeNottinghamshire:
        return require("./dialogues/goodbye/GoodbyeNottinghamshireDialogue").default
      case DialogueIDs.GoodbyeNorfolk:
        return require("./dialogues/goodbye/GoodbyeNorfolkDialogue").default
      case DialogueIDs.GoodbyeSABP:
        return require("./dialogues/goodbye/GoodbyeSABPDialogue").default
      case DialogueIDs.GoodbyeVHG:
        return require("./dialogues/goodbye/GoodbyeVHGDialogue").default
      case DialogueIDs.GoodbyeLincolnshire:
        return require("./dialogues/goodbye/GoodbyeLincolnshireDialogue").default
      case DialogueIDs.GoodbyeDemoPrivateProviders:
        return require("./dialogues/goodbye/GoodbyeDemoPrivateProvidersDialogue").default
      case DialogueIDs.GoodbyeVitality:
        return require("./dialogues/goodbye/GoodbyeVitalityDialogue").default
      case DialogueIDs.GoodbyeMHM:
        return require("./dialogues/goodbye/GoodbyeMHMDialogue").default
      case DialogueIDs.CrisisDemo:
        return require("./dialogues/crisis/CrisisDemoDialogue").default
      case DialogueIDs.CrisisCCG:
        return require("./dialogues/crisis/CrisisCCGDialogue").default
      case DialogueIDs.CrisisGMHub:
        return require("./dialogues/crisis/CrisisGMHubDialogue").default
      case DialogueIDs.CrisisWellbeingHub:
        return require("./dialogues/crisis/CrisisWellbeingHubDialogue").default
      case DialogueIDs.CrisisEssex:
        return require("./dialogues/crisis/CrisisEssexDialogue").default
      case DialogueIDs.CrisisTrentPTS:
        return require("./dialogues/crisis/CrisisTrentPTSDialogue").default
      case DialogueIDs.CrisisInsight:
        return require("./dialogues/crisis/CrisisInsightDialogue").default
      case DialogueIDs.CrisisITalk:
        return require("./dialogues/crisis/CrisisITalkDialogue").default
      case DialogueIDs.CrisisNottinghamshire:
        return require("./dialogues/crisis/CrisisNottinghamshireDialogue").default
      case DialogueIDs.CrisisNorfolk:
        return require("./dialogues/crisis/CrisisNorfolkDialogue").default
      case DialogueIDs.CrisisHealix:
        return require("./dialogues/crisis/CrisisHealixDialogue").default
      case DialogueIDs.CrisisSABP:
        return require("./dialogues/crisis/CrisisSABPDialogue").default
      case DialogueIDs.CrisisVHG:
        return require("./dialogues/crisis/CrisisVHGDialogue").default
      case DialogueIDs.CrisisLincolnshire:
        return require("./dialogues/crisis/CrisisLincolnshireDialogue").default
      case DialogueIDs.CrisisDemoPrivateProviders:
        return require("./dialogues/crisis/CrisisDemoPrivateProvidersDialogue").default
      case DialogueIDs.CrisisVitality:
        return require("./dialogues/crisis/CrisisVitalityDialogue").default
      case DialogueIDs.CrisisMHM:
        return require("./dialogues/crisis/CrisisMHMDialogue").default
      case DialogueIDs.CrisisLimbic:
        return require("./dialogues/crisis/CrisisLimbicDialogue").default
      case DialogueIDs.BookAppointmentVitality:
        return require("./dialogues/bookAppointment/BookAppointmentVitalityDialogue").default
      case DialogueIDs.BookAppointmentITalk:
        return require("./dialogues/bookAppointment/BookAppointmentITalkDialogue").default
      case DialogueIDs.CollectPhoneNumber:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberDialogue").default
      case DialogueIDs.CollectPhoneNumberEssex:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberEssexDialogue")
          .default
      case DialogueIDs.CollectPhoneNumberInsight:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberInsightDialogue")
          .default
      case DialogueIDs.CollectPhoneNumberITalk:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberITalkDialogue")
          .default
      case DialogueIDs.CollectPhoneNumberNorfolk:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberNorfolkDialogue")
          .default
      case DialogueIDs.CollectPhoneNumberMHM:
        return require("./dialogues/ad-hoc/CollectPhoneNumber/CollectPhoneNumberMHMDialogue")
          .default
      case DialogueIDs.CollectAlcoholConsumption:
        return require("./dialogues/ad-hoc/CollectAlcoholConsumption/CollectAlcoholConsumptionDialogue")
          .default
      default:
        throw new Error(`Unknown dialogue identifier: ${id}`)
    }
  }
}

export default new DialoguesRegistry()
