import Dialogue, { IDialogueSnapshot } from "../../backend/chatbot/Dialogue"
import { step } from "../../backend/chatbot/decorators/step"
import BaseScript from "../BaseScript"
import { DialogueIDs } from "../DialogueIDs"
import type { SurveyScriptState } from "../createSurveyDialogue"
import type { IStepData, IStepResult } from "../../backend/chatbot/models/IStep"
import type { IPersistableSurveyResponse, ISurveyResponse } from "../../models/ISurvey"

type State = SurveyScriptState

class Audit extends BaseScript<State> {
  readonly name: string = "Audit"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult<State> {
    this.timeEvent(`${this.name} Finished`)
    return {
      body: "The next set of questions are about your use of alcoholic drinks during the past year",
      nextStep: this.step1
    }
  }

  @step
  end(d: IStepData<State>): IStepResult {
    this.track(`${this.name} Finished`)
    return super.end(d)
  }

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    this.rootStore.applicationStore.setCurrentProgress(0)
    const q = "1. How often do you have a drink containing alcohol?"

    return {
      body: `1. ${name}, how often do you have a drink containing alcohol?`,
      prompt: {
        id: this.getPromptId("step1"),
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Never", 0),
          getQuestionChoice(q, "Monthly or less", 1),
          getQuestionChoice(q, "2-4 times per month", 2),
          getQuestionChoice(q, "2-3 times per week", 3),
          getQuestionChoice(q, "4+ times per week", 4)
        ],
        dataPointsName: "Audit - step1",
        isUndoAble: true
      },
      nextStep: this.handleStep1
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("1", "auditResponses")
  handleStep1(d: IStepData<State, ISurveyResponse>): IStepResult {
    if (d.response.answer === "Never") {
      return { nextStep: this.end }
    }
    this.rootStore.applicationStore.setCurrentProgress(0.1)
    return { nextStep: this.step2 }
  }

  @step
  step2(_d: IStepData<State>): IStepResult {
    const q =
      "2. How many drinks containing alcohol do you have on a typical day when you are drinking?"

    return {
      body: q,
      prompt: {
        id: this.getPromptId("step2"),
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "0-2", 0),
          getQuestionChoice(q, "3-4", 1),
          getQuestionChoice(q, "5-6", 2),
          getQuestionChoice(q, "7-9", 3),
          getQuestionChoice(q, "10+", 4)
        ],
        dataPointsName: "Audit - step2",
        isUndoAble: true
      },
      nextStep: this.handleStep2
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("2", "auditResponses")
  handleStep2(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.2)
    return { nextStep: this.step3 }
  }

  @step
  step3(_d: IStepData<State>): IStepResult {
    const q =
      "3. How often do you have 6 or more units (if female) or 8 or more units (if male) on a single occasion?"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step3"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Never", 0),
          getQuestionChoice(q, "Less than monthly", 1),
          getQuestionChoice(q, "Monthly", 2),
          getQuestionChoice(q, "Weekly", 3),
          getQuestionChoice(q, "Daily or almost daily", 4)
        ],
        dataPointsName: "Audit - step3"
      },
      nextStep: this.handleStep3
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("3", "auditResponses")
  handleStep3(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.3)
    return { nextStep: this.step4 }
  }

  @step
  step4(_d: IStepData<State>): IStepResult {
    const q =
      "4. How often during the last year have you found that you were not able to stop drinking once you had started?"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step4"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Never", 0),
          getQuestionChoice(q, "Less than monthly", 1),
          getQuestionChoice(q, "Monthly", 2),
          getQuestionChoice(q, "Weekly", 3),
          getQuestionChoice(q, "Daily or almost daily", 4)
        ],
        dataPointsName: "Audit - step4"
      },
      nextStep: this.handleStep4
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("4", "auditResponses")
  handleStep4(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.4)
    return { nextStep: this.step5 }
  }

  @step
  step5(_d: IStepData<State>): IStepResult {
    const q =
      "5. How often during the last year have you failed to do what was normally expected of you because of drinking?"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step5"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Never", 0),
          getQuestionChoice(q, "Less than monthly", 1),
          getQuestionChoice(q, "Monthly", 2),
          getQuestionChoice(q, "Weekly", 3),
          getQuestionChoice(q, "Daily or almost daily", 4)
        ],
        dataPointsName: "Audit - step5"
      },
      nextStep: this.handleStep5
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("5", "auditResponses")
  handleStep5(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.5)
    return { nextStep: this.step6 }
  }

  @step
  step6(_d: IStepData<State>): IStepResult {
    const q =
      "6. How often during the last year have you needed a first drink in the morning to get yourself going after a heavy drinking session?"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step6"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Never", 0),
          getQuestionChoice(q, "Less than monthly", 1),
          getQuestionChoice(q, "Monthly", 2),
          getQuestionChoice(q, "Weekly", 3),
          getQuestionChoice(q, "Daily or almost daily", 4)
        ],
        dataPointsName: "Audit - step6"
      },
      nextStep: this.handleStep6
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("6", "auditResponses")
  handleStep6(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.6)
    return { nextStep: this.step7 }
  }

  @step
  step7(_d: IStepData<State>): IStepResult {
    const q =
      "7. How often during the last year have you had a feeling of guilt or remorse after drinking?"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step7"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Never", 0),
          getQuestionChoice(q, "Less than monthly", 1),
          getQuestionChoice(q, "Monthly", 2),
          getQuestionChoice(q, "Weekly", 3),
          getQuestionChoice(q, "Daily or almost daily", 4)
        ],
        dataPointsName: "Audit - step7"
      },
      nextStep: this.handleStep7
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("7", "auditResponses")
  handleStep7(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.7)
    return { nextStep: this.step8 }
  }

  @step
  step8(_d: IStepData<State>): IStepResult {
    const q =
      "8. How often over the last year have you been unable to remember what happened the night before because of your drinking?"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step8"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "Never", 0),
          getQuestionChoice(q, "Less than monthly", 1),
          getQuestionChoice(q, "Monthly", 2),
          getQuestionChoice(q, "Weekly", 3),
          getQuestionChoice(q, "Daily or almost daily", 4)
        ],
        dataPointsName: "Audit - step8"
      },
      nextStep: this.handleStep8
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("8", "auditResponses")
  handleStep8(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.8)
    return { nextStep: this.step9 }
  }

  @step.logState
  step9(_d: IStepData<State>): IStepResult {
    const q = "9. Have you or someone else been injured because of your drinking?"

    return {
      body: q,
      prompt: {
        id: this.getPromptId("step9"),
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "No", 0),
          getQuestionChoice(q, "Yes, but not in the last year", 2),
          getQuestionChoice(q, "Yes, during the last year", 4)
        ],
        dataPointsName: "Audit - step9",
        isUndoAble: true
      },
      nextStep: this.handleStep9
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("9", "auditResponses")
  handleStep9(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(0.9)
    return { nextStep: this.step10 }
  }

  @step.logState
  step10(_d: IStepData<State>): IStepResult {
    const q =
      "10. Has a relative, friend, doctor or other care health worker been concerned about your drinking or suggested you cut down?"
    return {
      body: q,
      prompt: {
        id: this.getPromptId("step10"),
        type: "inlinePicker",
        choices: [
          getQuestionChoice(q, "No", 0),
          getQuestionChoice(q, "Yes, but not in the last year", 2),
          getQuestionChoice(q, "Yes, during the last year", 4)
        ],
        dataPointsName: "Audit - step10",
        isUndoAble: true
      },
      nextStep: this.handleStep10
    }
  }

  @step.logStateAndResponse
  @step.saveSurveyResponse<State>("10", "auditResponses")
  handleStep10(_d: IStepData<State, ISurveyResponse>): IStepResult {
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.end }
  }

  saveResponse<T extends IPersistableSurveyResponse>(item: T, state: State): void {
    super.saveResponse(item, state, "auditResponses")
  }
}

export default class AuditDialogue extends Dialogue<State> {
  static id = DialogueIDs.Audit
  readonly name: string = "AuditDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AuditDialogue.id, new Audit(), state, snapshot)
  }
}

function getQuestionChoice(title: string, answer: string, points: number) {
  return {
    body: answer,
    value: {
      title,
      answer,
      points
    }
  }
}
