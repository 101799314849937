import React from "react"
import ReactDOM from "react-dom"
import "../app/styles/styles.scss"
import { version } from "../../package.json"
import Logger from "../utils/Logger"
import Tracker from "../utils/Tracker"
import Trackable from "../models/Trackable"
import Persistable from "../models/Persistable"
import RootStore from "../app/stores/rootStore"
import { RootStoreProvider } from "../app/contexts/RootStoreContext"
import BaseScript from "../conversation/BaseScript"
import App from "../app/App"
import CrisisDetector from "../models/CrisisDetector"
import { getLanguage } from "../utils/getLanguage"
import invariant from "../utils/invariant"
import { apiKeysMap } from "../config/apiKeysMap"
import customFields from "../config/customFields"
import validateCustomFields from "../utils/validateCustomFields"
import { initNotts } from "./initNotts"
import type IAppLaunchConfig from "../models/IAppLaunchConfig"
import type { WindowWithLimbicNameSpace } from "./index"

declare let window: WindowWithLimbicNameSpace

const mixPanelAPIKey = process.env.REACT_APP_MIXPANEL_API_KEY ?? ""
const customInitsEnabled = process.env.REACT_APP_CUSTOM_INITS === "enabled"

const customInits: Record<keyof typeof apiKeysMap, (setupConfig: IAppLaunchConfig) => void> = {
  NOTTINGHAMSHIRE: initNotts
}

export function init(setupConfig: IAppLaunchConfig): void {
  console.log("==> Initialising Limbic Web Bot...")
  if (!window.LimbicChatbot.initialized) {
    const key = setupConfig.API_KEY
    invariant(key, "no API key found")
    invariant(apiKeysMap[key], "no configuration found for this API key")
    if (customInitsEnabled && customInits[key]) return customInits[key](setupConfig)

    window.LimbicChatbot.initialized = true
    const config = { ...apiKeysMap[key], ...(setupConfig.overrides || {}) }

    try {
      const language = getLanguage()
      const fullScreen = !!config.fullscreen
      if (mixPanelAPIKey) {
        Tracker.setAPIKey(mixPanelAPIKey)
      }
      Tracker.setup({ BOT_API_KEY: key })
      const trackerSingleton = Tracker.getInstance()
      trackerSingleton.register({ build: version })
      trackerSingleton.setPeople({ lastBuildUsed: version, language, ...config, fullScreen })
      trackerSingleton.register(config)
      Trackable.setTracker(trackerSingleton)
    } catch (e) {
      console.log(`init: ${e.message}`)
      Logger.getInstance().exception(e, "init -> Trackers setup")
    }

    try {
      Persistable.setStorage(sessionStorage)
      CrisisDetector.setStorage(localStorage)
      RootStore.getInstance()
      RootStore.ConfigStore.setConfig(key, config)
      RootStore.DiscussionStore.setDiscussionStepsOrder(config.discussionStepsOrder)
      RootStore.DiscussionStore.setDialoguesMap(config.dialoguesMap)
      RootStore.ClinicalStore.setDefaultPaths(config.defaultClinicalPaths)
      RootStore.ClinicalStore.setCustomPaths(config.customClinicalPaths)
      RootStore.ClinicalStore.setComplexComorbidPath(config.complexComorbidPath)
      RootStore.ClinicalStore.setUndeterminedPath(config.undeterminedPath)
      RootStore.ReferralStore.setSkipOnlineReferral(config.skipOnlineReferral ?? false)
      if (config.backendInstanceID) RootStore.ReferralStore.setInstanceID(config.backendInstanceID)

      const allFields = customFields[key]?.allFields ?? []
      const requiredFields = customFields[key]?.requiredFields ?? []
      validateCustomFields(allFields, requiredFields, setupConfig.customFields)
      if (setupConfig.customFields) {
        Object.entries(setupConfig.customFields) //
          .forEach(([key, value]) => RootStore.ReferralStore.setCustomField(key, value))
      }
    } catch (e) {
      Logger.getInstance().exception(e, "init -> RootStore setup")
    } finally {
      BaseScript.setRootStore(RootStore.getInstance())
    }

    void CrisisDetector.getInstance().updateCrisisDetection()

    const el = getMountingPoint()
    ReactDOM.render(
      <React.StrictMode>
        <RootStoreProvider>
          <App />
        </RootStoreProvider>
      </React.StrictMode>,
      el
    )
  }
}

function getMountingPoint(): Element {
  const el = document.querySelector("#limbic-chat")
  if (!el) {
    const newEl = document.createElement("div")
    newEl.setAttribute("id", "limbic-chat")
    document.body.appendChild(newEl)
    return newEl
  }
  return el
}
