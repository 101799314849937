import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { WellbeingHubUserTypes } from "../../../models/Constants"

type State = GoodbyeScriptState

export class GoodbyeWellbeingHubScript extends GoodbyeScript {
  readonly name: string = "GoodbyeWellbeingHubScript"

  /** Script Steps */

  /** Generic Handlers */
  async onHandleRecapMessage(
    _state: State,
    body: string[],
    recapMessage: string | undefined
  ): Promise<IStepResult> {
    const choices = [{ body: "Okay" }]
    if (recapMessage) {
      choices.push({ body: "I understand" })
    }

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askFeedback
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const wellbeingHubUserType = this.wellbeingHubStore.userType
      const organisationName = this.rootStore.configStore.organisationName
      const contactMessage = this.clinicalStore.isRisk
        ? ""
        : "\n\nSomeone from the service team will be in contact within one working day to discuss your referral further"
      const idleMessageMap: Record<WellbeingHubUserTypes, string> = {
        individual: `It looks like there hasn’t been any activity for some time so I've referred you to ${organisationName}`,
        managerIndividual: `It looks like there hasn’t been any activity for some time so I've submitted the information you've provided to ${organisationName}`,
        managerTeam: `It looks like there hasn’t been any activity for some time so I've submitted the information you've provided to ${organisationName}`
      }
      const referredMessageMap: Record<WellbeingHubUserTypes, string> = {
        individual: `I've referred you to ${organisationName}${contactMessage}`,
        managerIndividual: `I've submitted the information you've provided to ${organisationName}\n\nThe team will contact the individual directly to discuss their needs and will confirm you as the source of the contact\n\nThe ${organisationName} may follow up with you directly to discuss the referral you have submitted`,
        managerTeam: `I've submitted the information you've provided to ${organisationName}\n\nThe team will contact you within 5 working days to discuss next steps`
      }

      return state.isIdleSubmitted //
        ? idleMessageMap[wellbeingHubUserType || "managerIndividual"]
        : referredMessageMap[wellbeingHubUserType || "managerIndividual"]
    }
  }

  async getHighRiskContactMessage(_state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    const organisationGenericPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    if (this.clinicalStore.isHighRisk) {
      return (
        "Because you identified as being in crisis, someone from our team will call you within 24 hours during office days\n\n" +
        `Please remember, ${organisationName} is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111\n\n` +
        "You can also contact the Samaritans on 116123 to talk through how you are feeling\n\n" +
        `If you feel that your referral is something you would like to discuss with the team please call us on ${organisationGenericPhoneNumber} to discuss during our office hours: Monday to Friday 8am to 4pm`
      )
    }
  }

  async getModerateRiskContactMessage(_state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    const organisationGenericPhoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber
    if (this.clinicalStore.isModerateRisk) {
      return (
        "Because you identified as being in crisis, someone from our team will call you within 24 hours during office days\n\n" +
        `Please remember, ${organisationName} is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111\n\n` +
        "You can also contact the Samaritans on 116123 to talk through how you are feeling\n\n" +
        `If you feel that your referral is something you would like to discuss with the team please call us on ${organisationGenericPhoneNumber} to discuss during our office hours: Monday to Friday 8am to 4pm`
      )
    }
  }
}

export default class GoodbyeWellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeWellbeingHub
  readonly name: string = "GoodbyeWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeWellbeingHubDialogue.id, new GoodbyeWellbeingHubScript(), state, snapshot)
  }
}
