import { number, z, ZodSchema } from "zod"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { TrackingEvents } from "../../../models/Constants"
import { getITalkAppointments, reserveAppointment } from "../../../backend/api/iTalkAppointments"
import { AppointmentOption } from "../introduction/IntroductionITalkDialogue"
import {
  ITalkResponseWorkshop,
  ITalkResponseWorkshopSchema
} from "../../../models/IAppointmentITalk"
import { IAppointmentStatus } from "@limbic/types"

export const enum AppointmentSkippedReason {
  NO_EMAIL = "you did not provide an email address",
  NO_SUITABLE_SLOT = "there were no suitable appointment slots",
  NO_AVAILABLE = "there were no available appointments",
  FAILURE = "booking failed"
}

interface State extends BaseScriptState {
  retryReserveAppointment?: number
  retryBookAppointment?: number
  tempAppointment?: string
  appointment?: string | number
  appointmentOption?: AppointmentOption
  assessmentPreference?: "telephone" | "digital"
  availableWorkshops?: ITalkResponseWorkshop[]
  availableAppointments?: number[]
  reference?: string
  appointmentSkippedReason?: string
  emailPermission?: boolean
}

export type BookAppointmentITalkState = State

export const BookAppointmentITalkStateSchema = BaseScriptStateSchema.extend({
  retryReserveAppointment: z.number().optional(),
  retryBookAppointment: z.number().optional(),
  tempAppointment: z.string().optional(),
  appointment: z.union([z.string(), z.number()]).optional(),
  treatmentPath: z
    .union([z.literal("Self Referral"), z.literal("Wellbeing Courses and Webinars")])
    .optional(),
  assessmentPreference: z.union([z.literal("telephone"), z.literal("digital")]).optional(),
  availableWorkshops: z.array(ITalkResponseWorkshopSchema).optional(),
  availableAppointments: z.array(number()).optional(),
  reference: z.string().optional(),
  appointmentSkippedReason: z.string().optional(),
  emailPermission: z.boolean().optional()
})

export class BookAppointmentITalkScript extends BaseScript<State> {
  readonly name: string = "BookAppointmentITalkScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    d.state.assessmentPreference =
      d.state.appointmentOption === "Self Referral" ? "telephone" : "digital"
    this.referralStore.updateReferral({ assessmentPreference: d.state.assessmentPreference })

    if (!d.state.email) {
      this.referralStore.setCustomField("appointmentSet", false)
      this.track(TrackingEvents.APPOINTMENT_SKIPPED)
      this.referralStore.addClinicalNote(
        `Appointment: Not booked, user did not provide an email address`
      )
      d.state.appointmentSkippedReason = AppointmentSkippedReason.NO_EMAIL
      d.state.needsAssessmentCall = d.state.assessmentPreference === "telephone"
      return {
        nextStep: this.goToGoodbye
      }
    }

    return {
      body: "Now let's see what we can do about your appointment",
      nextStep: this.getAvailableAppointments
    }
  }

  @step.logState
  async getAvailableAppointments(d: IStepData<State>): Promise<IStepResult> {
    d.state.retryReserveAppointment ??= 0
    const patientId = this.referralStore.patientId!

    const [response, appointmentsStatus] = await getITalkAppointments(patientId)

    if (appointmentsStatus === IAppointmentStatus.RequestFailed) {
      return { nextStep: this.saySomethingWentWrongRetrievingAppointments }
    }

    if (appointmentsStatus === IAppointmentStatus.NoInternetConnection) {
      return { nextStep: this.sayNoInternetConnectionGetAppointments }
    }

    const appointment =
      d.state.assessmentPreference === "telephone" ? response?.telephone : response?.workshops || []

    if (!response || !appointment?.length) {
      this.referralStore.setCustomField("appointmentSet", false)
      this.track(TrackingEvents.APPOINTMENT_SKIPPED)
      this.referralStore.addClinicalNote(`Appointment: Not booked, no timeslot available`)
      d.state.appointmentSkippedReason = AppointmentSkippedReason.NO_AVAILABLE
      d.state.needsAssessmentCall = d.state.assessmentPreference === "telephone"
      return {
        body: `I'm sorry, I can't seem to find an any available appointment for ${d.state.appointmentOption} at the moment`,
        nextStep: this.goToGoodbye
      }
    }

    d.state.availableAppointments = response.telephone
    d.state.availableWorkshops = response.workshops

    if (d.state.assessmentPreference === "telephone") {
      return {
        nextStep: this.showAppointments
      }
    }

    return {
      nextStep: this.selectWorkshop
    }
  }

  @step.logState
  selectWorkshop(d: IStepData<State>): IStepResult {
    this.track(TrackingEvents.APPOINTMENTS_PROVIDED)
    const isFullScreen = this.rootStore.configStore.fullscreen
    return {
      body: [
        `Which workshop are you interested in?`,
        `You can find more information about the available workshops [here](https://www.italk.org.uk/how-we-help/)`
      ],
      prompt: {
        id: this.getPromptId("selectWorkshop"),
        type: "workshopsITalk",
        isFullScreen,
        appointments: d.state.availableWorkshops || [],
        isUndoAble: true
      },
      nextStep: this.showAppointments
    }
  }

  @step.logState
  showAppointments(d: IStepData<State, string>): IStepResult {
    this.track(TrackingEvents.APPOINTMENTS_PROVIDED)
    const isFullScreen = this.rootStore.configStore.fullscreen

    const appointments =
      d.state.assessmentPreference === "telephone"
        ? d.state.availableAppointments
        : d.state.availableWorkshops?.filter(i => i.title === d.response)

    return {
      body: "Please choose a date and time that works best for you",
      prompt: {
        id: this.getPromptId("showAppointments"),
        type: "appointmentITalk",
        appointmentType: d.state.assessmentPreference!,
        isFullScreen,
        appointments: appointments || [],
        isUndoAble: true
      },
      nextStep: this.confirmAppointment
    }
  }

  @step.logState
  sayNoInternetConnectionGetAppointments(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionGetAppointments"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.getAvailableAppointments
    }
  }

  @step.logState
  saySomethingWentWrongRetrievingAppointments(d: IStepData<State>): IStepResult {
    d.state.retryBookAppointment ??= 0
    d.state.retryBookAppointment = d.state.retryBookAppointment + 1

    if (d.state.retryBookAppointment > 2) {
      const name = this.getName(d.state)
      this.referralStore.setCustomField("appointmentSet", false)
      this.track(TrackingEvents.APPOINTMENT_BOOKING_FAILED)
      this.referralStore.addClinicalNote(
        `Appointment: Not booked, error encountered while trying to load appointments`
      )
      d.state.appointmentSkippedReason = AppointmentSkippedReason.FAILURE
      d.state.needsAssessmentCall = d.state.assessmentPreference === "telephone"
      return {
        body: `Sorry ${name}, we're encountering a persistent issue when trying to load the available appointments`,
        nextStep: this.goToGoodbye
      }
    }
    return {
      body: [
        "Hmmm... something went wrong while loading the available appointments",
        "Please try again"
      ],
      prompt: {
        id: this.getPromptId("saySomethingWentWrongRetrievingAppointments"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.getAvailableAppointments
    }
  }

  @step.logStateAndResponse
  async confirmAppointment(d: IStepData<State, string>): Promise<IStepResult> {
    if (!d.response) {
      this.referralStore.setCustomField("appointmentSet", false)
      this.track(TrackingEvents.APPOINTMENT_SKIPPED)
      this.referralStore.addClinicalNote(
        `Appointment: Not booked, user did not find suitable timeslot`
      )
      d.state.appointmentSkippedReason = AppointmentSkippedReason.NO_SUITABLE_SLOT
      d.state.needsAssessmentCall = d.state.assessmentPreference === "telephone"
      return { nextStep: this.goToGoodbye }
    }

    const [body, reference] = d.response.split("_")
    if (body && reference) {
      d.state.reference = reference
      d.state.tempAppointment = body
    }

    return {
      body: ["You have selected the following appointment", `${body}`, "Is this correct?"],
      prompt: {
        id: this.getPromptId("confirmAppointment"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No, let me change it", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleAppointment
    }
  }

  @step.logStateAndResponse
  async handleAppointment(d: IStepData<State, boolean>): Promise<IStepResult> {
    if (d.response && d.state.assessmentPreference && d.state.reference) {
      const [reservedAppointment, reservedAppointmentStatus] = await reserveAppointment(
        this.referralStore.patientId!,
        parseInt(d.state.reference),
        d.state.assessmentPreference
      )

      if (reservedAppointmentStatus === IAppointmentStatus.RequestFailed) {
        this.track(TrackingEvents.APPOINTMENT_BOOKING_FAILED)
        d.state.tempAppointment = undefined
        d.state.retryReserveAppointment ??= 0
        d.state.retryReserveAppointment = d.state.retryReserveAppointment + 1
        if (d.state.retryReserveAppointment > 2) {
          return { nextStep: this.sayAppointmentBookingFailed }
        }
      }

      if (reservedAppointmentStatus === IAppointmentStatus.NoInternetConnection) {
        return { nextStep: this.sayNoInternetConnectionReserveAppointment }
      }

      if (reservedAppointment) {
        d.state.appointment = d.state.tempAppointment
        this.referralStore.setCustomField("appointmentSet", true)
        this.track(TrackingEvents.APPOINTMENT_BOOKED)
        this.referralStore.addClinicalNote(`Appointment: ${d.state.appointment}`)
        return { nextStep: this.goToGoodbye }
      }

      return {
        body: ["Hmmm... something went wrong while booking your appointment", "Please try again"],
        nextStep: this.getAvailableAppointments
      }
    }
    d.state.tempAppointment = undefined
    return { nextStep: this.getAvailableAppointments }
  }

  @step.logState
  sayNoInternetConnectionReserveAppointment(_d: IStepData<State>): IStepResult {
    return {
      body: "Hmmm, it looks like you're not connected to the internet",
      prompt: {
        id: this.getPromptId("sayNoInternetConnectionReserveAppointment"),
        type: "inlinePicker",
        choices: [{ body: "Try again" }],
        isUndoAble: false
      },
      nextStep: this.handleAppointment
    }
  }

  @step.logState
  sayAppointmentBookingFailed(d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("appointmentSet", false)
    this.track(TrackingEvents.APPOINTMENT_BOOKING_FAILED)
    this.referralStore.addClinicalNote(`Appointment: Not booked, appointment booking failed`)
    d.state.appointmentSkippedReason = AppointmentSkippedReason.FAILURE
    d.state.needsAssessmentCall = d.state.assessmentPreference === "telephone"
    const name = this.getName(d.state)
    return {
      body: [
        `Sorry ${name}, we're encountering a persistent issue when trying to confirm your appointment booking`,
        "Your referral has been submitted successfully"
      ],
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return BookAppointmentITalkStateSchema
  }
}

/* istanbul ignore next */
export default class BookAppointmentITalkDialogue extends Dialogue<State> {
  static id = DialogueIDs.BookAppointmentITalk
  readonly name: string = "BookAppointmentITalkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(BookAppointmentITalkDialogue.id, new BookAppointmentITalkScript(), state, snapshot)
  }
}
