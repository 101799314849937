import { ClinicalFlags, ClinicalGroups, ProblemCategories } from "../../../../models/Constants"
import { IClinicalPath } from "../../../../models/IClinicalPath"

const trentCustomClinicalPaths: IClinicalPath[] = [
  {
    id: "trent-1",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.M_Depression],
      flags: []
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD,
    suggestTreatments: false,
    defaultReferralType: "Step 2 Trainee",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-2",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.M_Depression],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Step 2 (Qualified)",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-3",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.M_Depression],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Step 2 (Qualified)",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-4",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.MS_Depression],
      flags: []
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD,
    suggestTreatments: false,
    defaultReferralType: "Step 2 (Qualified)",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-5",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.MS_Depression],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Step 3 Integrative Counselling",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-6",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.MS_Depression],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Step 3 Integrative Counselling",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-7",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.M_GADWorry],
      flags: []
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD,
    suggestTreatments: false,
    defaultReferralType: "Step 2 Trainee",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-8",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.M_GADWorry],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Step 2 (Qualified)",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-9",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.M_GADWorry],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Step 2 (Qualified)",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-10",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.S_GADWorry],
      flags: []
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD,
    suggestTreatments: false,
    defaultReferralType: "Step 2 (Qualified)",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-11",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.S_GADWorry],
      flags: [ClinicalFlags.MS_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD_MS_FI,
    suggestTreatments: false,
    defaultReferralType: "Step 2 (Qualified)",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  },
  {
    id: "trent-12",
    matcher: {
      primaryProblems: [ProblemCategories.GAD],
      secondaryProblems: [ProblemCategories.S_GADWorry],
      flags: [ClinicalFlags.S_FunctionalImpairment]
    },
    enableSecondary: true,
    clinicalGroup: ClinicalGroups.GAD_S_FI,
    suggestTreatments: false,
    defaultReferralType: "Step 3 CBT",
    treatments: [],
    declinedTreatmentsReferralType:
      "Limbic Full Assessment - Assessment call required - Declined Treatment(s) offered"
  }
]

export { trentCustomClinicalPaths }
