import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

// NOTE: List is here:
// https://docs.google.com/spreadsheets/d/1KAo9qr1uuEGriQ0D7rRhXkFC0ppoYzNP/edit#gid=31930338

const iaptGPsMapEssexSE: IAPTGPMap = {
  C81680: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81001: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81003: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81007: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81032: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81046: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81051: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81061: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81065: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81066: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81070: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81073: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81075: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81081: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81086: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81089: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81092: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81096: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81097: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81101: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81112: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81121: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81123: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81125: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81128: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81142: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81144: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81147: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81159: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81164: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81176: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81200: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81205: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81207: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81209: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81223: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81613: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81618: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81622: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81639: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81649: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81656: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81661: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81675: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81684: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81688: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81690: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81696: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81700: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81704: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81713: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81718: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81724: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81733: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81739: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81740: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81744: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  F81755: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  Y00984: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  Y02177: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  Y02707: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
  Y06389: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX
}

const iaptGPsMapEssexNE: IAPTGPMap = {
  A91094: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81012: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81017: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81018: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81019: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81021: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81026: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81028: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81037: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81042: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81044: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81052: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81067: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81069: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81079: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81091: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81094: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81095: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81115: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81116: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81129: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81133: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81141: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81156: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81157: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81212: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81213: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81221: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81606: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81633: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81636: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81670: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81672: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81679: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81681: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81716: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81736: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81741: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81746: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  F81757: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  Y00484: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  Y01297: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
  Y02646: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX
}

export const iaptGPMapEssex: IAPTGPMap = {
  ...iaptGPsMapEssexSE,
  ...iaptGPsMapEssexNE
}

const iaptGPsMapEssexSEDemo: IAPTGPMap = {
  C81680: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81001: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81003: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81007: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81032: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81046: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81051: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81061: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81065: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81066: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81070: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81073: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81075: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81081: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81086: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81089: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81092: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81096: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81097: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81101: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81112: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81121: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81123: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81125: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81128: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81142: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81144: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81147: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81159: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81164: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81176: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81200: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81205: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81207: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81209: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81223: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81613: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81618: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81622: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81639: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81649: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81656: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81661: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81675: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81684: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81688: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81690: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81696: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81700: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81704: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81713: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81718: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81724: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81733: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81739: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81740: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81744: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  F81755: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  Y00984: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  Y02177: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  Y02707: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
  Y06389: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO
}

const iaptGPsMapEssexNEDemo: IAPTGPMap = {
  A91094: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81012: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81017: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81018: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81019: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81021: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81026: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81028: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81037: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81042: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81044: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81052: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81067: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81069: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81079: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81091: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81094: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81095: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81115: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81116: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81129: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81133: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81141: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81156: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81157: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81212: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81213: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81221: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81606: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81633: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81636: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81670: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81672: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81679: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81681: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81716: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81736: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81741: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81746: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  F81757: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  Y00484: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  Y01297: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
  Y02646: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO
}

export const iaptGPMapEssexDemo: IAPTGPMap = {
  ...iaptGPsMapEssexSEDemo,
  ...iaptGPsMapEssexNEDemo
}
