import moment from "moment"
import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import invariant from "../../../utils/invariant"
import { step } from "../../../backend/chatbot/decorators/step"
import { SelfReferralScriptStateSchema } from "./SelfReferralScript"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { ReferralPayloadHealix } from "@limbic/types"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

interface State extends SelfReferralIAPTScriptState {
  hideEarlierYouSaid?: boolean
}

export const SelfReferralHealixScriptStateSchema = SelfReferralScriptStateSchema.extend({
  hideEarlierYouSaid: z.boolean().optional()
})

export class SelfReferralHealixScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralHealixScript"

  /** Script Steps */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0 })
  sayIntro(d: IStepData<State>): IStepResult {
    const isDataAvailable =
      this.referralStore.getCustomField("address") &&
      this.referralStore.getCustomField("dateOfBirth")

    if (isDataAvailable) {
      d.state.address = this.referralStore.getCustomField("address")
      /** birthday is being handled in getReferralPayload */
    }

    this.timeEvent(this.name)
    return {
      body: `I'm now going to add you as a new referral`,
      prompt: {
        id: this.getPromptId("I'll add your referral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Great" }, { body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: isDataAvailable ? this.askWhatIsYourGoal : this.askWannaDoSelfReferral
    }
  }

  @step
  askWannaDoSelfReferral(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "There are just a few more details I need from you",
        "This should take no more than 2 minutes"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoSelfReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Sure" }, { body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.startSelfReferralPart1
    }
  }

  @step.logState
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    const dateOfBirth = this.referralStore.getCustomField("dateOfBirth") ?? undefined
    const address = this.referralStore.getCustomField("address") ?? undefined
    if (dateOfBirth) {
      if (address) {
        d.state.address = address
        return { nextStep: this.askWhatIsYourGoal }
      }
      return { nextStep: this.askPostCodeForAddressLookup }
    }
    return { nextStep: this.askBirthday }
  }

  @step.logState
  askBirthday(_d: IStepData<State>): IStepResult {
    return {
      body: ["Brilliant", "Firstly, what's your date of birth?"],
      prompt: {
        id: this.getPromptId("askBirthday"),
        trackResponse: true,
        type: "datePicker",
        future: false,
        isUndoAble: true
      },
      nextStep: this.handleBirthday
    }
  }

  @step.logState
  sayPleaseGiveABirthday(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your date of birth",
      prompt: {
        id: this.getPromptId("sayPleaseGiveABirthday"),
        trackResponse: true,
        type: "datePicker",
        future: false
      },
      nextStep: this.handleBirthday
    }
  }

  @step
  handleBirthday(d: IStepData<State, number>): IStepResult {
    try {
      const date = moment(d.response)
      invariant(date, "Date object is falsy")
      invariant(date.isValid(), "Date is not valid")
      invariant(date.isBefore(moment()), "Birth date cannot be in the future")
      invariant(date.isAfter(moment("1899-12-31")), "Birth date cannot be before 1900")
      d.state.birthday = date.toDate().getTime()
      this.setPeople({ age: moment().diff(date, "years") })
    } catch (e) {
      this.logException(e, "handleBirthday")
      return {
        body: "I'm sorry that's not a valid date",
        nextStep: this.sayPleaseGiveABirthday
      }
    }

    const address = this.referralStore.getCustomField("address") ?? undefined
    if (address) {
      d.state.address = address
      return {
        nextStep: this.askWhatIsYourGoal
      }
    }
    return {
      body: "Thanks for sharing",
      nextStep: this.askPostCodeForAddressLookup
    }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    return {
      body: [
        "And that's everything",
        "Congratulations on taking this important step towards better mental health!"
      ],
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: "What happens next?" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.end
    }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const phoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber

    return {
      body: [
        "Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data",
        "I've notified my creators of this issue",
        `If you don't wish to wait, you can call the service on this number: ${phoneNumber}`
      ],
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralHealixScriptStateSchema
  }

  async onHandlePostCodeForAddressLookup(state: State): Promise<IStepResult> {
    state.hideEarlierYouSaid = true
    return { nextStep: this.askSelectAddressFromPostCode }
  }

  async onHandleSelectAddressFromPostCode(_state: State): Promise<IStepResult> {
    return { nextStep: this.askWhatIsYourGoal }
  }

  async onCheckFullAddress(_state: State): Promise<IStepResult> {
    return { nextStep: this.askWhatIsYourGoal }
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadHealix> {
    const instanceID = this.referralStore.instanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      problemInOwnWords: state.mainIssue,
      dob:
        this.referralStore.getCustomField("dateOfBirth") ||
        moment(state.birthday).format("DD/MM/YYYY"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      email: state.email,
      consentEmail: !!state.email,
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      title: state.title,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      companyName: this.referralStore.getCustomField("companyName"),
      coverageEndDate: this.referralStore.getCustomField("coverEndDate"),
      insurerID: this.referralStore.getCustomField("insurerID"),
      memberID: this.referralStore.getCustomField("memberID"),
      gpName: this.referralStore.getCustomField("gpName"),
      coverageAvailable: this.referralStore.getCustomField("availableCover"),
      coverageLevel: this.referralStore.getCustomField("coverageLevel"),
      questionnaires: this.getQuestionnairesPayload(state),
      clinicalNotes: this.referralStore.clinicalNotes,
      clinicalFlags: this.clinicalStore.flags,
      problemDescriptorPrimary: this.clinicalStore.primaryProblems,
      problemDescriptorSecondary: this.clinicalStore.secondaryProblems,
      consentResearch: state.consentResearch,
      treatmentExpectation: state.therapyGoal
    }
  }

  // TODO: Do we actually need this? Because we already send a Risk Email
  // when the user replies NO to can keep self safe question
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onRiskReferralFinished(state: State): Promise<void> {}
}

/* istanbul ignore next */
export default class SelfReferralHealixDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralHealix
  readonly name: string = "SelfReferralHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralHealixDialogue.id, new SelfReferralHealixScript(), state, snapshot)
  }
}
