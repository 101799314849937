import BaseScript, { BaseScriptState } from "../../BaseScript"
import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { getChatGPTMessage, getChatGPTSession } from "../../../backend/api/chatGPT"

interface State extends BaseScriptState {
  sessionId?: string
  isIndividual?: boolean
  learnAboutQuestionsCount?: number
}

export class LearnAboutLimbicScript extends BaseScript<State> {
  readonly name = "LearnAboutLimbicScript"

  /** Script Steps */

  @step
  start(d: IStepData<State>): IStepResult {
    d.state.learnAboutQuestionsCount = 0
    return { nextStep: this.askWhatWouldYouLikeToKnow }
  }

  @step
  askWhatWouldYouLikeToKnow(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "What would you like to know?",
        "You can ask me about our products, company and evidence base by typing your question below"
      ],
      nextStep: this.showPromptForNextUserQuestion
    }
  }

  @step
  showPromptForNextUserQuestion(d: IStepData<State>): IStepResult {
    const id = this.getPromptId(
      `showPromptForNextUserQuestion-${d.state.learnAboutQuestionsCount! + 1}`
    )
    if (d.state.isIndividual) {
      return {
        prompt: {
          id,
          type: "text",
          placeholder:
            d.state.learnAboutQuestionsCount! > 0
              ? "What else would you like to know?"
              : "Type your question here"
        }
      }
    }
    return {
      prompt: {
        id,
        type: "inlinePicker",
        choices: [{ body: "Talk to sales", value: "__sales__" }],
        textPrompt:
          d.state.learnAboutQuestionsCount! >= 4
            ? undefined
            : {
                forceValue: true,
                placeholder:
                  d.state.learnAboutQuestionsCount! > 0
                    ? "What else would you like to know?"
                    : "Type your question here"
              }
      },
      nextStep: this.handleUserQuestionWithCrisis
    }
  }

  @step
  askWhatElseWouldYouLikeToKnow(_d: IStepData<State>): IStepResult {
    return {
      body: "So what else would you like to know about Limbic?",
      nextStep: this.showPromptForNextUserQuestion
    }
  }

  @step.checkInputForCrisis({
    getNextStep: (s: LearnAboutLimbicScript) => s.askWhatElseWouldYouLikeToKnow,
    disableDetectionIfWrong: true
  })
  @step
  @step.startTyping
  async handleUserQuestionWithCrisis(
    d: IStepData<State, "__sales__" | string>
  ): Promise<IStepResult> {
    if (d.response === "__sales__") return { nextStep: this.end }

    let body = [] as string[]
    try {
      if (!d.state.sessionId) {
        d.state.sessionId = await getChatGPTSession()
      }
      body = (await getChatGPTMessage(d.state.sessionId, d.response)) ?? []
      console.log({ body })
    } catch (e) {
      body = ["THIS IS JUST A HACKATHON PEOPLE! TIME WASN'T ENOUGH FOR ALL SCENARIOS MKAY?"]
      console.error(e.message)
    }

    d.state.learnAboutQuestionsCount! += 1
    const shouldStopDiscussion = d.state.isIndividual && d.state.learnAboutQuestionsCount! >= 4
    if (shouldStopDiscussion) body.push("Thank you for your interest in Limbic")

    return {
      body,
      nextStep: this.showPromptForNextUserQuestion,
      clearStack: shouldStopDiscussion
    }
  }
}

/* istanbul ignore next */
export default class LearnAboutLimbicDialogue extends AdHocDialogue<State, LearnAboutLimbicScript> {
  static id = DialogueIDs.LearnAboutLimbic
  readonly name: string = "LearnAboutLimbicDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(LearnAboutLimbicDialogue.id, new LearnAboutLimbicScript(), state, snapshot)
  }
}
