import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { RiskLevelReason } from "../../../models/Constants"
import sendRiskEmail from "../../../backend/api/sendRiskEmail"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import moment from "moment"

const VITALITY_RISK_EMAILS =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? ["mentalhealthreports@iprsgroup.com"]
    : ["andreas+vitality@limbic.ai", "john+vitality@limbic.ai"]

type State = RiskPathwayScriptState
export type RiskPathwayVitalityScriptState = State

export class RiskPathwayVitalityScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayVitalityScript"

  /** Script Steps */

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep yourself, and any dependants in your care, safe?",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe",
        isUndoAble: false
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
      return { nextStep: this.informRiskToService }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async informRiskToService(d: IStepData<State>): Promise<IStepResult> {
    await this.sendRiskEmailToService(d.state)
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "However, this is not an emergency response service",
        "There are alternative services that are better placed to support you",
        "If you need urgent, life threatening medical help please call [999](tel:999)",
        `One of ${serviceName}'s clinical team will contact you within the next working day to talk through these thoughts with you (This team operates Monday to Friday)`
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.sayThanksLetContinue
    }
  }

  @step.logState
  sayThanksLetContinue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: [
        `I really appreciate you sharing these thoughts with me, ${name}`,
        `I'll continue with the digital assessment, but if you do need to stop at any time, that's fine (someone from ${serviceName} will be in touch regardless)`
      ],
      prompt: {
        id: this.getPromptId("sayThanksLetContinue"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }],
        dataPointsName: "sayThanksLetContinue"
      },
      nextStep: this.sayClosingMessage
    }
  }

  @step.logState
  sayThanksForSharing(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `I really appreciate you sharing these thoughts with me, ${name}`,
      nextStep: this.sayClosingMessage
    }
  }

  @step.logState
  sayClosingMessage(_d: IStepData<State>): IStepResult {
    return {
      body: "Okay...",
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.sayThanksForSharing }
  }

  async sendRiskEmailToService(state: State): Promise<void> {
    try {
      if (!this.clinicalStore.isRisk) return
      const emails = VITALITY_RISK_EMAILS || []
      if (emails?.length) {
        const text = this.createReferralEmail(state, true)
        await sendRiskEmail({
          to: emails,
          text
        })
      }
    } catch (e) {
      this.logException(e, "sendRiskEmailToService")
    }
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns='http://www.w3.org/1999/xhtml' lang=''>
  <head>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
    <meta name='viewport' content='width=device-width, initial-scale=1.0' />
    <title>Limbic Therapy Assistant | Keeping Safe</title>
    ${this.getEmailHTMLStyle()}
    <!--[if IEMobile 7]>
      <style type="text/css">
        /* Targeting Windows Mobile */
      </style>
    <![endif]-->
    <!--[if gte mso 9]>
      <style>
        /* Target Outlook 2007 and 2010 */
      </style>
    <![endif]-->
  </head>

  <body>
  <table cellpadding='0' cellspacing='0' border='0' id='backgroundTable'>
    <tr>
      <td>
        <table cellpadding='0' cellspacing='0' border='0' id='contentTable'>
          <tr>
            <td> <img class='image_fix'
                style='max-height: 200px; display: block; margin-left: auto; margin-right: auto;'
                src='${logo}'
                alt='Keeping Safe Leaflet' title='Keeping Safe Leaflet' width='x' height='x' /> <br /><br /> </td>
          </tr>
          <tr>
            <td>
              <h1 style='color: #dc186d !important;'>Keeping Safe Leaflet</h1>
            </td>
          </tr>
          <tr>
            <td>
              <p>You have made a self-referral today to IPRS Health. In one of your answers you said you were
                experiencing some thoughts of self-harm or taking your own life.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>As you are waiting to access our service, if you feel you may act on <i>suicidal thoughts</i>, cannot
                keep yourself or are a risk to others around you, please call 999.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p> <strong>The IPRS Health Team</strong> </p>
            </td>
          <tr>
        </table>
      </td>
    </tr>
  </table>
</body>
</html>
    `
  }

  getPersonalInfoHTML(state: State): string {
    // prettier-ignore
    return ([] as Array<string | false | undefined>)
      .concat(
        `<b>Name:</b> ${state.username}<br/>`,
        !!state.birthday && `<b>Date of Birth:</b> ${moment(state.birthday).format("DD/MM/YYYY")}<br/>`,
        `<b>Phone number:</b> ${state.phoneNumber || "N/A"}<br/>`,
      )
      .filter(Boolean)
      .join("\n")
  }

  getClinicalNotesHTML(_state: State): string {
    return ""
  }

  getQuestionnairesInfoHTML(_state: State): string {
    return ""
  }

  getAdditionalInfoHTML(state: State): string {
    const triggerWords = this.clinicalStore.triggerWords?.length
      ? `<b>Crisis Trigger Words:</b> ${this.clinicalStore.triggerWords?.join(", ")}<br/>`
      : ""
    const triggerWordsPhrase =
      this.clinicalStore.triggerWords?.length && this.clinicalStore.triggerWordsPhrase //
        ? `<b>User Input With Trigger Words:</b> ${this.clinicalStore.triggerWordsPhrase}<br/>`
        : ""
    return `
      ${triggerWords}
      ${triggerWordsPhrase}
    `
  }
}

/* istanbul ignore next */
export default class RiskPathwayVitalityDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayVitality
  readonly name: string = "RiskPathwayVitalityDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayVitalityDialogue.id, new RiskPathwayVitalityScript(), state, snapshot)
  }
}
