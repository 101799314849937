import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PHQ9Script } from "./PHQ9"
import type { PHQ9ScriptState } from "./PHQ9"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = PHQ9ScriptState

export class PHQ9WellbeingHubScript extends PHQ9Script {
  readonly name: string = "PHQ9WellbeingHubScript"

  /** Generic Handlers */
  async onHandleRiskQ9Score(state: State): Promise<IStepResult | void> {
    this.setCrisisDetected(state)
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    const isRisk = this.clinicalStore.isRisk
    if (!isRisk && state.php9q9Score! >= 1) {
      return { nextStep: this.goToRiskPathway }
    }
  }
}

export default class PHQ9WellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.PHQ9WellbeingHub
  readonly name: string = "survey:PHQ9_WellbeingHub Dialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PHQ9WellbeingHubDialogue.id, new PHQ9WellbeingHubScript(), state, snapshot)
  }
}
