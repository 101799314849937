import moment from "moment"
import { z, ZodSchema } from "zod"
import { CovidStatus, QUESTIONNAIRE, ReferralPayload } from "@limbic/types"
import Script, { ScriptState, ScriptStateSchema } from "../backend/chatbot/Script"
import { step } from "../backend/chatbot/decorators/step"
import {
  ALCOHOL_FREQUENCIES,
  ALCOHOL_QUANTITIES,
  AssessmentCallReason,
  DiscussionSteps,
  ReferralType,
  RiskLevel,
  TrackingEvents
} from "../models/Constants"
import getIAPTById from "../utils/getIAPTById"
import isNullOrUndefined from "../utils/isNullOrUndefined"
import invariant from "../utils/invariant"
import IName, { INameSchema } from "../models/IName"
import IPostcode, { IPostcodeSchema } from "../models/IPostcode"
import { IIAPTService, IIAPTServiceSchema } from "../models/IIAPTService"
import {
  IGPService,
  IGPServiceODS,
  IGPServiceODSSchema,
  IGPServiceSchema
} from "../models/IGPService"
import { IPersistableSurveyResponse, IPersistableSurveyResponseSchema } from "../models/ISurvey"
import { IPersistableUserResponse, IPersistableUserResponseSchema } from "../models/IUserResponse"
import type RootStore from "../app/stores/rootStore"
import type ClinicalStore from "../app/stores/clinicalStore"
import type DiscussionStore from "../app/stores/discussionStore"
import type ReferralStore from "../app/stores/referralStore"
import type WellbeingHubStore from "../app/stores/wellbeingHubStore"
import type { IStep, IStepData, IStepResult } from "../backend/chatbot/models/IStep"
import type { ITreatmentOption } from "../models/IClinicalPath"
import type { IDialoguesRegistry } from "./dialoguesRegistry"

export interface BaseScriptState extends ScriptState {
  name?: IName
  spineName?: IName
  nhsNumber?: string
  gp?: IGPService
  odsGP?: IGPServiceODS
  iapt?: IIAPTService
  iaptManuallySelected?: boolean
  iaptSuggestions?: IIAPTService[]
  userPostcode?: IPostcode
  customUserPostcode?: string
  isCustomPostcode?: boolean
  gpPostcode?: IPostcode
  postcodeEntered?: string
  birthday?: number
  isUnderAged?: boolean
  isEligible?: boolean
  isMaybeEligible?: boolean
  signPostToManualReferral?: boolean
  needsToCall?: boolean
  patientId?: string
  signupCode?: string
  careSignupURL?: string
  phoneNumber?: string
  canSendTextMessagesToPhoneNumber?: boolean
  canLeaveVoicemailToPhoneNumber?: boolean
  email?: string
  canSendEmail?: boolean
  address?: string
  address2?: string
  street?: string
  city?: string
  county?: string
  canSendMailToAddress?: boolean
  ethnicity?: string
  nationality?: string
  gender?: string
  sameGenderAsBirth?: string
  spineGender?: string
  spineLanguage?: string
  spineInterpreterRequired?: boolean
  primaryLanguage?: string
  perinatalStatus?: string
  isExArmedForces?: string
  religion?: string
  title?: "Mr" | "Ms" | "Mx"
  sexuality?: string
  disabilityStatus?: boolean
  disability?: string
  disabilities?: string[]
  longTermMedicalCondition?: string[]
  ltcAffectsMood?: boolean
  ltcMoodImpact?: "little" | "somewhat" | "very"
  ltcManagement?: "little" | "fairly" | "very"
  covidStatus?: CovidStatus
  covidDate?: string
  alcohol?: boolean
  alcoholFrequency?: ALCOHOL_FREQUENCIES
  alcoholQuantity?: ALCOHOL_QUANTITIES
  substances?: boolean
  substancesAreMedications?: boolean
  substancesInfo?: string
  medicationWithinDoseRange?: "Yes" | "No" | "Not sure"
  medicationInfo?: string
  needsAssessmentCall?: boolean
  assessmentCallReason?: AssessmentCallReason
  hasCurrentSupport?: boolean
  improvementSuggestion?: string
  isHelpful?: "Yes" | "No" | "Needed more"
  referralSubmitted?: boolean
  referralSubmissionFailed?: boolean
  userInput?: string
  userInputExplanation?: string
  whereDidYouHearAboutService?: string
  canKeepSelfSafe?: boolean
  php9q9Score?: number
  phq9Responses?: IPersistableSurveyResponse[]
  auditResponses?: IPersistableSurveyResponse[]
  drugsAndSmokingResponses?: IPersistableSurveyResponse[]
  itqResponses?: IPersistableSurveyResponse[]
  irqaResponses?: IPersistableSurveyResponse[]
  gad7Responses?: IPersistableSurveyResponse[]
  wsasResponses?: IPersistableSurveyResponse[]
  pdssResponses?: IPersistableSurveyResponse[]
  phobiaScaleResponses?: IPersistableSurveyResponse[]
  employmentStatusResponses?: IPersistableSurveyResponse[]
  medicationResponses?: IPersistableSurveyResponse[]
  accommodationResponses?: IPersistableSurveyResponse[]
  pcl5Responses?: IPersistableSurveyResponse[]
  spinResponses?: IPersistableSurveyResponse[]
  ociResponses?: IPersistableSurveyResponse[]
  shai18Responses?: IPersistableSurveyResponse[]
  specificPhobiaResponses?: IPersistableSurveyResponse[]
  riskPathwayResponses?: IPersistableUserResponse[]
  experiencingCrisisAndSuicidalThoughts?: boolean
  consentADSM?: boolean
  readTheInformationSheetADSM?: boolean
  agreeToWithdrawByEmailADSM?: boolean
  agreeReferralDataToBeUsedADSM?: boolean
  wishToParticipateInTheProjectADSM?: boolean
  consentDataUsedInOtherProjectsADSM?: boolean
  consentParticipationInProjectADSM?: boolean
  agreeTerms?: boolean
  agreeDetailsShared?: boolean
  consentResearch?: boolean
}

export const BaseScriptStateSchema = ScriptStateSchema.extend({
  name: INameSchema.optional(),
  spineName: INameSchema.optional(),
  nhsNumber: z.string().length(10).regex(/^\d+$/).optional(),
  gp: IGPServiceSchema.optional(),
  odsGP: IGPServiceODSSchema.optional(),
  iapt: IIAPTServiceSchema.optional(),
  iaptManuallySelected: z.boolean().optional(),
  iaptSuggestions: z.array(IIAPTServiceSchema).optional(),
  userPostcode: IPostcodeSchema.optional(),
  customUserPostcode: z.string().optional(),
  isCustomPostcode: z.boolean().optional(),
  gpPostcode: IPostcodeSchema.optional(),
  postcodeEntered: z.string().optional(),
  birthday: z.number().optional(),
  isUnderAged: z.boolean().optional(),
  isEligible: z.boolean().optional(),
  isMaybeEligible: z.boolean().optional(),
  signPostToManualReferral: z.boolean().optional(),
  needsToCall: z.boolean().optional(),
  patientId: z.string().optional(),
  signupCode: z.string().optional(),
  careSignupURL: z.string().optional(),
  phoneNumber: z.string().optional(),
  canSendTextMessagesToPhoneNumber: z.boolean().optional(),
  canLeaveVoicemailToPhoneNumber: z.boolean().optional(),
  email: z.string().optional(),
  canSendEmail: z.boolean().optional(),
  address: z.string().optional(),
  address2: z.string().optional(),
  street: z.string().optional(),
  city: z.string().optional(),
  county: z.string().optional(),
  canSendMailToAddress: z.boolean().optional(),
  ethnicity: z.string().optional(),
  nationality: z.string().optional(),
  gender: z.string().optional(),
  sameGenderAsBirth: z.string().optional(),
  spineGender: z.string().optional(),
  spineLanguage: z.string().optional(),
  spineInterpreterRequired: z.boolean().optional(),
  primaryLanguage: z.string().optional(),
  perinatalStatus: z.string().optional(),
  isExArmedForces: z.string().optional(),
  religion: z.string().optional(),
  title: z.union([z.literal("Mr"), z.literal("Ms"), z.literal("Mx")]).optional(),
  sexuality: z.string().optional(),
  disabilityStatus: z.boolean().optional(),
  disability: z.string().optional(),
  disabilities: z.array(z.string()).optional(),
  longTermMedicalCondition: z.array(z.string()).optional(),
  ltcAffectsMood: z.boolean().optional(),
  ltcMoodImpact: z
    .union([z.literal("little"), z.literal("somewhat"), z.literal("very")])
    .optional(),
  ltcManagement: z.union([z.literal("little"), z.literal("fairly"), z.literal("very")]).optional(),
  covidStatus: z.string().optional(),
  covidDate: z.string().optional(),
  alcohol: z.boolean().optional(),
  alcoholFrequency: z.nativeEnum(ALCOHOL_FREQUENCIES).optional(),
  alcoholQuantity: z.nativeEnum(ALCOHOL_QUANTITIES).optional(),
  substances: z.boolean().optional(),
  substancesAreMedications: z.boolean().optional(),
  substancesInfo: z.string().optional(),
  medicationWithinDoseRange: z
    .union([z.literal("Yes"), z.literal("No"), z.literal("Not sure")])
    .optional(),
  medicationInfo: z.string().optional(),
  needsAssessmentCall: z.boolean().optional(),
  assessmentCallReason: z.nativeEnum(AssessmentCallReason).optional(),
  hasCurrentSupport: z.boolean().optional(),
  improvementSuggestion: z.string().optional(),
  isHelpful: z.union([z.literal("Yes"), z.literal("No"), z.literal("Needed more")]).optional(),
  referralSubmitted: z.boolean().optional(),
  referralSubmissionFailed: z.boolean().optional(),
  userInput: z.string().optional(),
  userInputExplanation: z.string().optional(),
  whereDidYouHearAboutService: z.string().optional(),
  canKeepSelfSafe: z.boolean().optional(),
  php9q9Score: z.number().optional(),
  phq9Responses: z.array(IPersistableSurveyResponseSchema).optional(),
  gad7Responses: z.array(IPersistableSurveyResponseSchema).optional(),
  wsasResponses: z.array(IPersistableSurveyResponseSchema).optional(),
  pdssResponses: z.array(IPersistableSurveyResponseSchema).optional(),
  phobiaScaleResponses: z.array(IPersistableSurveyResponseSchema).optional(),
  employmentStatusResponses: z.array(IPersistableSurveyResponseSchema).optional(),
  medicationResponses: z.array(IPersistableSurveyResponseSchema).optional(),
  accommodationResponses: z.array(IPersistableSurveyResponseSchema).optional(),
  riskPathwayResponses: z.array(IPersistableUserResponseSchema).optional(),
  agreeTerms: z.boolean().optional(),
  agreeDetailsShared: z.boolean().optional(),
  consentResearch: z.boolean().optional()
})

export default abstract class BaseScript<State extends BaseScriptState> extends Script<State> {
  static _rootStore: RootStore
  _rootStore?: RootStore

  /** Static Methods */

  static setRootStore(rootStore: RootStore): void {
    invariant(rootStore, `BaseScript needs a _rootStore property.[${rootStore}]`)
    this._rootStore = rootStore
  }

  /** Static Getters / Setters */

  static get rootStore(): RootStore {
    invariant(
      this._rootStore,
      `BaseScript needs a static _rootStore property. [${this._rootStore}]`
    )
    return this._rootStore!
  }

  setRootStore(rootStore: RootStore): void {
    invariant(rootStore, `BaseScript needs a _rootStore property.[${rootStore}]`)
    invariant(
      process.env.NODE_ENV === "test",
      "non-static setRootStore of BaseScript is only for testing"
    )
    this._rootStore = rootStore
  }

  /** Script Steps */

  @step.logState
  goToGoodbye(d: IStepData<State>): IStepResult {
    const GoodbyeDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.Goodbye)
    const nextDialogue = GoodbyeDialogue //
      ? new GoodbyeDialogue({ ...d.state })
      : undefined
    return {
      nextDialogue,
      nextStep: this.end,
      clearStack: true
    }
  }

  /** Optional Abstract Generic Handlers */

  getReferralTypeForRisk?(state: State): string | undefined
  getCustomReferralType?(state: State): string | undefined

  /** Overrides */

  getStateSchema(): ZodSchema | undefined {
    return BaseScriptStateSchema
  }

  getName(state: State): string {
    if (state.preferredName) return state.preferredName
    if (state.name) return this.getFirstName(state)
    return super.getName(state)
  }

  getFirstName(state: State): string {
    if (state.name) return state.name.firstName
    return super.getName(state)
  }

  getLastName(state: State): string {
    if (state.name) return state.name.lastName
    return super.getLastName(state)
  }

  async onHandleTriggerWords(
    state: State,
    triggerWords: string[],
    input?: string,
    nextStep?: IStep<State>
  ): Promise<IStepResult | void> {
    this.clinicalStore.setTriggerWords(triggerWords)
    this.clinicalStore.setTriggerWordsPhrase(input)
  }

  getCrisisDialogue(_state: State): IDialoguesRegistry[keyof IDialoguesRegistry] | undefined {
    return this.discussionStore.getDialogueClass(DiscussionSteps.Crisis)
  }

  blockUndo(state: State, temp?: boolean): void {
    super.blockUndo(state)
    this.rootStore.applicationStore.blockUndo(temp)
  }

  enableUndo(state: State): void {
    super.enableUndo(state)
    this.rootStore.applicationStore.enableUndo()
  }

  startTyping(): void {
    this.rootStore.chatStore.startBotTyping()
  }

  /** Generic Handlers */

  getFullName(state: State): string {
    if (!state.name) return state.username || ""
    const { firstName, lastName, middleNames } = state.name
    return `${firstName ?? ""}${middleNames ? ` ${middleNames}` : ""} ${lastName ?? ""}`.trim()
  }

  getPromptId(id: string): string {
    invariant(id, "You need to provide a prompt id")
    return `${this.name}.${id}`
  }

  setUnderAged(state: State, isUnderAged: boolean): void {
    state.isUnderAged = isUnderAged
    this.setPeople({ isUnderAged })
    this.logBreadcrumb("setUnderAged", state, { isUnderAged })
    this.logMessage("setUnderAged")
  }

  setEligibility(state: State, isEligible: boolean): void {
    state.isEligible = isEligible
    this.blockUndo(state, true) // 👈 temporary undo
    this.setPeople({ isEligible })
    this.track(TrackingEvents.ELIGIBILITY_DETERMINED, { isEligible })
    this.logBreadcrumb(TrackingEvents.ELIGIBILITY_DETERMINED, state, { isEligible })
    this.logMessage(TrackingEvents.ELIGIBILITY_DETERMINED)
    if (!state.isEligible) this.setPeople({ gpInfo: state.gp, odsGPInfo: state.odsGP })
  }

  setSignpostToManualSelfReferral(state: State, signPostToManualReferral: boolean): void {
    state.signPostToManualReferral = signPostToManualReferral
    this.setPeople({ signPostToManualReferral })
    this.track(TrackingEvents.SIGNPOST_MANUAL_REFERRAL, { signPostToManualReferral })
    this.logBreadcrumb(TrackingEvents.SIGNPOST_MANUAL_REFERRAL, state, { signPostToManualReferral })
    this.logMessage(TrackingEvents.SIGNPOST_MANUAL_REFERRAL)
  }

  setUserDoesNotNeedToCallIn(state: State): void {
    state.needsToCall = false
    this.setPeople({ needsToCall: false })
    this.track(TrackingEvents.USER_DOES_NOT_NEED_TO_CALL)
    this.logBreadcrumb(TrackingEvents.USER_DOES_NOT_NEED_TO_CALL, state, { needsToCall: false })
    this.logMessage(TrackingEvents.USER_DOES_NOT_NEED_TO_CALL)
  }

  setUserNeedsToCallIn(state: State): void {
    state.needsToCall = true
    this.setPeople({ needsToCall: true })
    this.track(TrackingEvents.USER_NEEDS_TO_CALL)
    this.logBreadcrumb(TrackingEvents.USER_NEEDS_TO_CALL, state, { needsToCall: true })
    this.logMessage(TrackingEvents.USER_NEEDS_TO_CALL)
  }

  setGP(state: State, gp?: IGPService): void {
    state.gp = gp
  }

  setODSGP(state: State, gp?: IGPServiceODS): void {
    state.odsGP = gp
  }

  setIAPT(state: State, iapt?: IIAPTService, iaptManuallySelected?: boolean): void {
    state.iapt = iapt
    state.iaptManuallySelected = !!iaptManuallySelected
    if (iapt?.backendInstanceID) this.referralStore.setInstanceID(iapt.backendInstanceID)
    const data = {
      iapt: iapt?.name ? `${iapt?.name} (${iapt?.id})` : undefined,
      iaptManuallySelected: !!iaptManuallySelected
    }
    this.setPeople(data)
    this.track(TrackingEvents.IAPT_DETERMINED, data)
    this.logBreadcrumb(TrackingEvents.IAPT_DETERMINED, state, data)
    this.logMessage(TrackingEvents.IAPT_DETERMINED)
  }

  setIAPTSuggestions(state: State, iaptSuggestions?: IIAPTService[]): void {
    state.iaptSuggestions = iaptSuggestions
    const data = { iaptSuggestions: iaptSuggestions?.map(i => `${i.name} (${i.id}`) }
    this.setPeople(data)
    this.track(TrackingEvents.EXTERNAL_IAPTS_DETERMINED, data)
    this.logBreadcrumb(TrackingEvents.EXTERNAL_IAPTS_DETERMINED, state, data)
    this.logMessage(TrackingEvents.EXTERNAL_IAPTS_DETERMINED)
  }

  setRiskLevelLow(state: State): void {
    const riskLevel = RiskLevel.Low
    this.clinicalStore.setRiskLevel(riskLevel)
    this.clinicalStore.setRiskLevelReason()
    const data = { riskLevel }
    this.setPeople(data)
    this.logBreadcrumb("setRiskLevelLow", state, data)
    this.logMessage("setRiskLevelLow")
  }

  setRiskLevelModerate(state: State, reason?: string): void {
    const riskLevel = RiskLevel.Moderate
    const riskLevelReason = reason
    this.clinicalStore.setRiskLevel(riskLevel)
    this.clinicalStore.setRiskLevelReason(riskLevelReason)
    this.blockUndo(state)
    const data = { riskLevel, riskLevelReason }
    this.setPeople(data)
    this.logBreadcrumb("setRiskLevelModerate", state, data)
    this.logMessage("setRiskLevelModerate")
  }

  setRiskLevelHigh(state: State, reason?: string): void {
    const riskLevel = RiskLevel.High
    const riskLevelReason = reason
    this.clinicalStore.setRiskLevel(riskLevel)
    this.clinicalStore.setRiskLevelReason(riskLevelReason)
    this.blockUndo(state)
    const data = { riskLevel, riskLevelReason }
    this.setPeople(data)
    this.logBreadcrumb("setRiskLevelHigh", state, data)
    this.logMessage("setRiskLevelHigh")
  }

  setTreatment(state: State, t?: ITreatmentOption): void {
    const treatment = t?.formattedName
    this.setPeople({ treatment })
    if (treatment) {
      void this.referralStore.updateReferral({ treatment: t?.name })
      this.track(TrackingEvents.TREATMENT_DETERMINED, { treatment })
      this.logBreadcrumb(TrackingEvents.TREATMENT_DETERMINED, undefined, { treatment })
    }
    this.logBreadcrumb("setTreatment", state, { treatment })
    this.logMessage("setTreatment")
  }

  setTreatmentSuggested(state: State, t: ITreatmentOption): void {
    void this.referralStore.updateReferral({ treatmentSuggested: t.name })
    const treatment = t?.formattedName
    const key = `${treatment} Suggested`
    this.setPeople({ [key]: true })
    this.track(key, { treatment })
    this.logBreadcrumb(key)
    this.logMessage(key)
  }

  setTreatmentAccepted(state: State, treatment: ITreatmentOption, accepted: boolean): void {
    const declinedTreatments = this.clinicalStore.getDeclinedTreatments()
    void this.referralStore.updateReferral({
      treatmentAccepted: { treatment: treatment.name, accepted },
      declinedTreatments: declinedTreatments.map(t => t.name)
    })
    const key = `${treatment.formattedName} Accepted`
    this.setPeople({ [key]: accepted, declinedTreatments })
    const event = accepted
      ? `${treatment.formattedName} Accepted`
      : `${treatment.formattedName} Declined`
    this.track(event, { treatment, accepted, declinedTreatments })
    this.logBreadcrumb(event, undefined, { accepted, declinedTreatments })
    this.logMessage(event)
  }

  setCrisisDetected(state: State): void {
    const crisisTriggerWords = this.clinicalStore.triggerWords
    const data = { crisisDetected: true, crisisTriggerWords }
    this.setPeople(data)
    this.track(TrackingEvents.CRISIS_DETECTED, data)
    this.logBreadcrumb(TrackingEvents.CRISIS_DETECTED, state, data)
    this.logMessage(TrackingEvents.CRISIS_DETECTED)
  }

  setCrisisDetectionCorrect(state: State, correct?: boolean): void {
    const crisisTriggerWords = this.clinicalStore.triggerWords
    const data = { crisisTriggerWords, crisisDetectionCorrect: !!correct }
    this.setPeople(data)
    const event = correct
      ? TrackingEvents.CRISIS_DETECTION_CORRECT
      : TrackingEvents.CRISIS_DETECTION_WRONG
    this.track(event, data)
    this.logBreadcrumb(TrackingEvents.CRISIS_DETECTED, state)
    this.logMessage(event)
  }

  updateReferralType(state: State, ref?: ReferralType): void {
    const patientId = this.referralStore.patientId
    if (!patientId) return
    const clinicalPath = this.clinicalStore.clinicalPath
    const riskLevel = this.clinicalStore.riskLevel
    const { needsAssessmentCall, assessmentCallReason } = state

    const data = { clinicalPath, riskLevel, needsAssessmentCall, assessmentCallReason }
    const referralType = ref ?? this.getReferralType(state)
    const message = `Updating Referral Type to ${referralType}`
    this.logBreadcrumb(message, undefined, { ref, ...data, patientId })

    this.referralStore.setReferralType(referralType)
    void this.referralStore.updateReferralType()
    this.setPeople({ referralType, output: referralType })
    this.logBreadcrumb("UPDATE REFERRAL TYPE", state, { referralType })
    this.logMessage("UPDATE REFERRAL TYPE")
  }

  getReferralType(state: State): string {
    const riskReferral = this.getReferralTypeForRisk?.(state)
    if (riskReferral) return riskReferral

    const customReferral = this.getCustomReferralType?.(state)
    if (customReferral) return customReferral

    const clinicalPathReferral = this.getReferralTypeForClinicalPath(state)
    if (clinicalPathReferral) return clinicalPathReferral

    return ReferralType.ASSESSMENT_CALL_REQUIRED
  }

  getReferralTypeForClinicalPath(_state: State): string | undefined {
    const clinicalPath = this.clinicalStore.clinicalPath
    if (!clinicalPath) return ReferralType.SELF_REFERRAL

    const treatment = clinicalPath.treatments?.find(t => t.accepted)
    const declinedTreatments = clinicalPath.treatments?.find(t => t.declined)
    if (!treatment && declinedTreatments && clinicalPath.declinedTreatmentsReferralType) {
      return clinicalPath.declinedTreatmentsReferralType
    }
    if (treatment?.referralType) {
      return treatment.referralType
    }
    if (clinicalPath.defaultReferralType) {
      return clinicalPath.defaultReferralType
    }
  }

  getIAPTName(state: State): string | undefined {
    return state.iapt?.formattedName
  }

  getUserAge(state: State): number {
    const now = moment()
    const birthday = state.birthday || now
    return now.diff(birthday, "years", true)
  }

  getIAPTServiceAgeThreshold(state: State): number {
    if (state.iapt?.ageThreshold != null) {
      return state.iapt.ageThreshold
    }
    const eligibleIAPTIds = this.rootStore.configStore.eligibleIAPTIds
    const ageThresholds = eligibleIAPTIds
      ?.map(getIAPTById)
      .filter(Boolean)
      .map(iapt => iapt?.ageThreshold ?? 18) ?? [18]
    if (!ageThresholds.length) {
      return 18
    }
    return Math.min.apply(null, ageThresholds)
  }

  getEligibleIAPTSByAgeThreshold(state: State): IIAPTService[] {
    const age = this.getUserAge(state)
    const eligibleIAPTIds = this.rootStore.configStore.eligibleIAPTIds
    return eligibleIAPTIds
      .map(getIAPTById)
      .filter(Boolean)
      .filter(iapt => Number(iapt!.ageThreshold) <= age) as IIAPTService[]
  }

  getCustomGP(): IGPService {
    return {
      id: "unknown ID",
      name: "unknown GP",
      pimsCode: "unknown",
      nacsCode: "unknown",
      formattedName: "unknown GP",
      postcode: "unknown postcode",
      ccg: {
        id: "unknown",
        name: "unknown CCG",
        code: "unknown"
      },
      isCustom: true
    }
  }

  getQuestionnairesPayload(state: State): NonNullable<ReferralPayload["questionnaires"]> {
    const phq9 = [...(state.phq9Responses || []), ...(state.riskPathwayResponses || [])].length
      ? {
          total: this.getPHQ9Total(state),
          responses: [...(state.phq9Responses || []), ...(state.riskPathwayResponses || [])],
          responsesDisplay: ""
        }
      : undefined

    const audit = state.auditResponses?.length
      ? {
          total: this.getAuditTotal(state),
          responses: state.auditResponses || [],
          responsesDisplay: ""
        }
      : undefined

    const drugsAndSmoking = state.drugsAndSmokingResponses?.length
      ? {
          total: this.getDrugsAndSmokingTotal(state),
          responses: state.drugsAndSmokingResponses || [],
          responsesDisplay: ""
        }
      : undefined

    const itq = state.itqResponses?.length
      ? {
          total: this.getITQ(state),
          responses: state.itqResponses || [],
          responsesDisplay: ""
        }
      : undefined

    // There is no scoring for IRQ-A
    const irqa = state.irqaResponses?.length
      ? {
          responses: state.irqaResponses || [],
          responsesDisplay: ""
        }
      : undefined

    const gad7 = state.gad7Responses?.length
      ? {
          total: this.getGAD7Total(state),
          responses: state.gad7Responses || [],
          responsesDisplay: ""
        }
      : undefined
    const phobia = state.phobiaScaleResponses?.length
      ? {
          total: this.getPhobiaScaleTotal(state),
          responses: state.phobiaScaleResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const employment = state.employmentStatusResponses?.length
      ? {
          responses: state.employmentStatusResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const wsas = state.wsasResponses?.length
      ? {
          total: this.getWSASTotal(state),
          responses: state.wsasResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const medication = state.medicationResponses?.length
      ? {
          responses: state.medicationResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const accommodation = state.accommodationResponses?.length
      ? {
          responses: state.accommodationResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const pcl5 = state.pcl5Responses?.length
      ? {
          total: this.getPCL5Total(state),
          responses: state.pcl5Responses || [],
          responsesDisplay: ""
        }
      : undefined
    const spin = state.spinResponses?.length
      ? {
          total: this.getSPINTotal(state),
          responses: state.spinResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const pdss = state.pdssResponses?.length
      ? {
          total: this.getPDSSTotal(state),
          responses: state.pdssResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const specificPhobia = state.specificPhobiaResponses?.length
      ? {
          total: this.getSpecificPhobiaTotal(state),
          responses: state.specificPhobiaResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const oci = state.ociResponses?.length
      ? {
          total: this.getOCITotal(state),
          responses: state.ociResponses || [],
          responsesDisplay: ""
        }
      : undefined
    const shai18 = state.shai18Responses?.length
      ? {
          total: this.getSHAI18Total(state),
          responses: state.shai18Responses || [],
          responsesDisplay: ""
        }
      : undefined

    return {
      ...(phq9 ? { [QUESTIONNAIRE.PHQ9]: phq9 } : {}),
      ...(audit ? { [QUESTIONNAIRE.AUDIT]: audit } : {}),
      ...(drugsAndSmoking ? { [QUESTIONNAIRE.DRUGS_SMOKING_PCMIS]: drugsAndSmoking } : {}),
      ...(itq ? { [QUESTIONNAIRE.ITQ]: itq } : {}),
      ...(irqa ? { [QUESTIONNAIRE.IRQ_MAN_PCMIS]: irqa } : {}),
      ...(gad7 ? { [QUESTIONNAIRE.GAD7]: gad7 } : {}),
      ...(phobia ? { [QUESTIONNAIRE.PHOBIA]: phobia } : {}),
      ...(employment ? { [QUESTIONNAIRE.EMPLOYMENT]: employment } : {}),
      ...(wsas ? { [QUESTIONNAIRE.WSAS]: wsas } : {}),
      ...(medication ? { [QUESTIONNAIRE.MEDICATION]: medication } : {}),
      ...(accommodation ? { [QUESTIONNAIRE.ACCOMMODATION]: accommodation } : {}),
      ...(pcl5 ? { [QUESTIONNAIRE.PCL5]: pcl5 } : {}),
      ...(pdss ? { [QUESTIONNAIRE.PDSS]: pdss } : {}),
      ...(spin ? { [QUESTIONNAIRE.SPIN]: spin } : {}),
      ...(specificPhobia ? { [QUESTIONNAIRE.SPECIFIC_PHOBIA]: specificPhobia } : {}),
      ...(oci ? { [QUESTIONNAIRE.OCI]: oci } : {}),
      ...(shai18 ? { [QUESTIONNAIRE.SHAI]: shai18 } : {})
    }
  }

  sendPHQ9(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore
      .updateReferral({ questionnaires })
      .then(() => this.referralStore.setPHQ9HasBeenSend(true))
  }

  sendAudit(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendDrugsAndSmoking(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendITQ(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendIRQA(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendGAD7(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendPhobiaScale(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendIAPTEmploymentStatus(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendWSAS(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendIAPTMedication(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendPCL5(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendOCI(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendPDSS(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendSpecificPhobia(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendSPIN(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  sendSHAI18(state: State): void {
    const questionnaires = this.getQuestionnairesPayload(state)
    void this.referralStore.updateReferral({ questionnaires })
  }

  getPHQ9Total(state: State): number | undefined {
    return state.phq9Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getAuditTotal(state: State): number | undefined {
    return state.auditResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getDrugsAndSmokingTotal(state: State): number | undefined {
    return state.drugsAndSmokingResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getITQ(state: State): number | undefined {
    return state.itqResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getIRQA(state: State): number | undefined {
    return state.irqaResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getGAD7Total(state: State): number | undefined {
    return state.gad7Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getPhobiaScaleTotal(state: State): number | undefined {
    return state.phobiaScaleResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getSocialPhobiaScore(state: State): number | undefined {
    return state.phobiaScaleResponses?.[0]?.points
  }

  getPanicPhobiaScore(state: State): number | undefined {
    return state.phobiaScaleResponses?.[1]?.points
  }

  getSpecificPhobiaScore(state: State): number | undefined {
    return state.phobiaScaleResponses?.[2]?.points
  }

  getWSASTotal(state: State): number | undefined {
    return state.wsasResponses?.reduce(
      (t, c, i, a) =>
        t +
        (!i && isNullOrUndefined(c.points)
          ? // If it's the 1st Q and doesn't have points (N/A was selected)
            // then count into the total score the 1st Q's points as equal
            // to the average of the points the rest of the Qs have
            a.reduce((t, c) => t + (c.points || 0), 0) / (a.length - 1)
          : // Otherwise, just count up all the answer points
            c.points || 0),
      0
    )
  }

  getPCL5Total(state: State): number | undefined {
    return state.pcl5Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getOCITotal(state: State): number | undefined {
    return state.ociResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getPDSSTotal(state: State): number | undefined {
    return state.pdssResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getSpecificPhobiaTotal(state: State): number | undefined {
    return state.specificPhobiaResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getSPINTotal(state: State): number | undefined {
    return state.spinResponses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getSHAI18Total(state: State): number | undefined {
    return state.shai18Responses?.reduce((total, curr) => total + (curr.points || 0), 0)
  }

  getTitleHTML(_state: State): string | undefined {
    return undefined
  }

  getReferralSubmittedIndicatorHTML(state: State): string | undefined {
    if (!state.referralSubmitted) {
      return "<p>Risk was detected before a referral was created.<br/><i>It’s possible that the user exited the chatbot before a referral was created and therefor it might not show up in the patient management system</i></p>"
    }
  }

  getPersonalInfoHTML(state: State): string {
    const { alcohol, alcoholQuantity, alcoholFrequency } = state
    const alcoholFreq = alcohol ? `(${alcoholQuantity} units ${alcoholFrequency})` : ""

    // prettier-ignore
    return ([] as Array<string | false | undefined>).concat(
      `<b>Name:</b> ${state.username}<br/>`,
      !!state.birthday && `<b>Date of Birth:</b> ${moment(state.birthday).format("DD/MM/YYYY")}<br/>`,
      !!state.birthday && `<b>Underaged:</b> ${!!state.isUnderAged}<br/>`,
      `<b>Postcode:</b> ${(state.isCustomPostcode ? state.customUserPostcode : state.userPostcode?.postcode) ?? "N/A"}<br/>`,
      `<b>PhoneNumber:</b> ${state.phoneNumber}<br/>`,
      state.canSendTextMessagesToPhoneNumber != null && `<b>Permission for SMS:</b> ${state.canSendTextMessagesToPhoneNumber}<br/>`,
      state.canLeaveVoicemailToPhoneNumber != null && `<b>Permission for Voicemail:</b> ${state.canLeaveVoicemailToPhoneNumber}<br/>`,
      `<b>Email:</b> ${state.email || "N/A"}<br/>`,
      state.canSendEmail != null && `<b>Permission to Send Email:</b> ${state.canSendEmail}<br/>`,
      state.address != null && `<b>Address:</b> ${state.address} ${state.address2}<br/>`,
      state.city != null && `<b>City:</b> ${state.city}<br/>`,
      state.county != null && `<b>County:</b> ${state.county}<br/>`,
      state.ethnicity != null && `<b>Ethnicity:</b> ${state.ethnicity}<br/>`,
      state.religion != null && `<b>Religion:</b> ${state.religion}<br/>`,
      state.gender != null && `<b>Gender:</b> ${state.gender}<br/>`,
      state.perinatalStatus != null && `<b>Perinatal Status:</b> ${state.perinatalStatus}<br/>`,
      state.isExArmedForces != null && `<b>Is Ex Armed Forces:</b> ${state.isExArmedForces}<br/>`,
      state.sexuality != null && `<b>Sexuality:</b> ${state.sexuality}<br/>`,
      state.longTermMedicalCondition != null && `<b>LTC:</b> ${state.longTermMedicalCondition?.join(", ")}<br/>`,
      state.substances != null && `<b>Using Substances:</b> ${state.substances}<br/>`,
      state.substancesAreMedications != null && `<b>Substances Are Medications:</b> ${state.substancesAreMedications}<br/>`,
      state.substancesInfo != null && `<b>Info About Substances:</b> ${state.substancesInfo}<br/>`,
      state.medicationWithinDoseRange != null && `<b>Medications are within recommended dose range:</b> ${state.medicationWithinDoseRange}<br/>`,
      state.alcohol != null && `<b>Alcohol:</b> ${state.alcohol} ${alcoholFreq}<br/>`,
      state.hasCurrentSupport != null && `<b>3rd Party Therapy:</b> ${state.hasCurrentSupport}<br/>`,
      state.signupCode != null && `<b>Limbic Referral ID:</b> ${state.signupCode}<br/>`,
    ).filter(Boolean).join("\n")
  }

  getServicesInfoHMTL(state: State): string {
    const strings = [] as string[]
    const gp = state.gp
    if (gp?.name) {
      // prettier-ignore
      strings.push(`<b>GP:</b> ${gp?.formattedName} (${gp?.postcode}, ${gp?.ccg.name})<br/>`)
    }
    const iapt = state.iapt
    if (iapt?.name) {
      strings.push(`<b>Talking Therapy:</b> ${iapt?.name} (${iapt?.postcode})<br/>`)
    }
    const iaptSuggestions = state.iaptSuggestions
    if (iaptSuggestions?.length) {
      // prettier-ignore
      strings.push(`<b>Other Talking Therapies Suggested:</b> ${!iapt && iaptSuggestions?.map(i => i.name).join(", ")}<br/>`)
    }
    if (!strings.length) return ""
    return `
    <h3>Services</h3>
    <p>
        ${strings.join("\n")}   
    </p>
    `
  }

  getClinicalNotesHTML(_state: State): string {
    const clinicalPath = this.clinicalStore.clinicalPath
    const primaries = this.clinicalStore.primaryProblems
    const primariesTitle = primaries.length
      ? "<b>Primary Problem Categories</b>"
      : "<b>Primary Problem Categories:</b> None"
    const primaryProblems = primaries.length
      ? `<ul>${primaries.map(i => `<li>${i}</li>`).join("\n")}</ul>`
      : ""

    const secondaries = this.clinicalStore.secondaryProblems
    const secondariesTitle = primaries.length
      ? "<b>Secondary Problem Categories</b>"
      : "<b>Secondary Problem Categories:</b> None"
    const secondaryProblems = primaries.length
      ? `<ul>${secondaries.map(i => `<li>${i}</li>`).join("\n")}</ul>`
      : ""

    const flags = this.clinicalStore.flags
    const flagsTitle = flags.length ? "<b>Problem flags</b>" : "<b>Problem flags:</b> None"
    const clinicalFlags = flags.length
      ? `<ul>${flags.map(i => `<li>${i}</li>`).join("\n")}</ul>`
      : ""

    const clinicalNotes = this.referralStore.clinicalNotes
    const notes = clinicalNotes.map(note => `<li>${note}</li>`).join("\n")
    return `
    <h3>Clinical Group</h3>
    <p>${clinicalPath?.clinicalGroup}</p>
    
    ${primariesTitle}
    ${primaryProblems}
    
    ${secondariesTitle}
    ${secondaryProblems}
    
    ${flagsTitle}
    ${clinicalFlags}
    
    <b>Clinical Notes</b>
    <ul>${notes}</ul>
    `
  }

  getQuestionnairesInfoHTML(state: State): string {
    return `
    <b>Questionnaires</b>
    <div>
    <b>PHQ-9 (${this.getPHQ9Total(state)}):</b>
    <ul>
      ${[...(state.phq9Responses || []), ...(state.riskPathwayResponses || [])]
        ?.map?.((q: any) =>
          `<i>${q.title}:</i> <b>${q.answer} (${q.points})</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>
      
    <b>GAD-7 (${this.getGAD7Total(state)}):</b>
    <ul>
      ${state.gad7Responses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer} (${q.points})</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>

    <b>Phobia Scale (${this.getPhobiaScaleTotal(state)}):</b>
    <ul>
      ${state.phobiaScaleResponses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer} (${q.points})</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>
      
    <b>Employment:</b>
    <ul>
      ${state.employmentStatusResponses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer}</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>
      
    <b>WASAS (${this.getWSASTotal(state)}):</b>
    <ul>
      ${state.wsasResponses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer} (${q.points})</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>
      
    <b>Medication:</b>
    <ul>
      ${state.medicationResponses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer}</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>
    </div>

    <b>Accommodation:</b>
    <ul>
      ${state.accommodationResponses
        ?.map?.(q =>
          `<i>${q.title}:</i> <b>${q.answer}</b>` //
            .replace(/^\d.\s+/, "")
            .replace(/\n+/g, "\n          ")
        )
        ?.map(i => `<li>${i}</li>`)
        ?.join("")}
    </ul>
    </div>
    `
  }

  getAdditionalInfoHTML(state: State): string {
    const triggerWords = this.clinicalStore.triggerWords?.length
      ? `<b>Crisis Trigger Words:</b> ${this.clinicalStore.triggerWords?.join(", ")}<br/>`
      : ""
    const triggerWordsPhrase =
      this.clinicalStore.triggerWords?.length && this.clinicalStore.triggerWordsPhrase //
        ? `<b>User Input With Trigger Words:</b> ${this.clinicalStore.triggerWordsPhrase}<br/>`
        : ""
    const treatment = this.clinicalStore.getAcceptedTreatment()
    const declinedTreatments = this.clinicalStore.getDeclinedTreatments()
    return `
    <b>Treatment:</b> ${treatment?.name}<br/>
    <b>Declined Treatments:</b> ${declinedTreatments.map(t => t.name).join(", ")}<br/>
    <b>Admin team should call:</b> ${!!state.needsAssessmentCall}<br/>
    <b>Reason why admin team should call:</b> ${state.assessmentCallReason}<br/>
    ${triggerWords}
    ${triggerWordsPhrase}
    <b>Was the bot helpful:</b> ${state.isHelpful}<br/>
    <b>Improvement Suggestion:</b> ${state.improvementSuggestion}<br/>
    `
  }

  getEmailHTMLStyle(): string {
    return `
    <style>
        #outlook a {
          padding: 0;
        }
  
        body {
          width: 100% !important;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
          margin: 0;
          padding: 0;
          font-family: sans-serif;
        }
  
        .ExternalClass {
          width: 100%;
        }
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
          line-height: 100%;
        }
  
        #backgroundTable {
          margin: 0;
          padding: 0;
          width: 100% !important;
          line-height: 100% !important;
        }
  
        #contentTable {
          margin: 64px auto;
          max-width: 800px !important;
          line-height: 100% !important;
        }
  
        img {
          outline: none;
          text-decoration: none;
          -ms-interpolation-mode: bicubic;
        }
  
        a img {
          border: none;
        }
        .image_fix {
          display: block;
        }
        p {
          margin: 1em 0;
        }
  
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: black !important;
        }
        h1 a,
        h2 a,
        h3 a,
        h4 a,
        h5 a,
        h6 a {
          color: blue !important;
        }
        h1 a:active,
        h2 a:active,
        h3 a:active,
        h4 a:active,
        h5 a:active,
        h6 a:active {
          color: red !important;
        }
        h1 a:visited,
        h2 a:visited,
        h3 a:visited,
        h4 a:visited,
        h5 a:visited,
        h6 a:visited {
          color: purple !important;
        }
  
        table td {
          padding-left: 24px;
          padding-right: 24px;
          border-collapse: collapse;
          line-height: 24px;
        }
  
        table {
          border-collapse: collapse;
          mso-table-lspace: 0;
          mso-table-rspace: 0;
        }
  
        a {
          color: orange;
        }
        h1,
        h2,
        h3,
        a {
          color: #375491 !important;
        }
        h1 {
          font-size: 1.8rem;
          text-align: center;
          font-weight: normal;
        }
        .red {
          color: red;
        }
  
        .blue {
          color: #375491 !important;
          font-weight: bold;
        }
  
        .big {
          font-size: 21px;
          font-weight: bold;
        }

        .image_fix {
          margin-left: auto;
          margin-right: auto;
        }
      </style>
    `
  }

  // TODO: This and the createBookingEmail need to be merged
  createReferralEmail(state: State, isCrisis = false): string {
    let referralType = this.referralStore.referralType
    if (isCrisis) referralType = ReferralType.LIMBIC_RISK_PATIENT
    // prettier-ignore
    return `
    <html lang='en'>
      <head>
      <title>Limbic Self Referral Asssistant</title>
      ${this.getEmailHTMLStyle()}
      </head>
      <body>
        <h1>${referralType}</h1>
        <h3>${state.username}</h3>
        ${this.getTitleHTML(state) ?? ""}
        <p>${this.getReferralSubmittedIndicatorHTML(state) ?? ""}</p>
        <hr/>
        <h3>Personal Info</h3>
        <p>
          ${this.getPersonalInfoHTML(state)}
        </p>
        ${this.getServicesInfoHMTL(state)}
        ${this.getClinicalNotesHTML(state)}
        ${this.getQuestionnairesInfoHTML(state)}
        <p>
          ${this.getAdditionalInfoHTML(state)}
        </p>
      </body>
    </html>
    `
      .replace(/undefined/gi, "-")
      .replace(/true/gi, "Yes")
      .replace(/false/gi, "No")
  }

  // TODO: This and the createReferralEmail need to be merged
  createBookingEmail(state: State, isCrisis = false, type: string): string {
    const jobCategory = this.referralStore.getCustomField("jobCategory")
    let referralType = this.referralStore.referralType
    if (isCrisis) referralType = ReferralType.LIMBIC_RISK_PATIENT
    // prettier-ignore
    return `
    <html lang='en'>
      <head>
      <title>Limbic Self Referral Asssistant</title>
      ${this.getEmailHTMLStyle()}
      </head>
      <body>
        <h1><b>${state.username}</b> ${type}</h1>
        ${this.getTitleHTML(state)}
        <p>${this.getReferralSubmittedIndicatorHTML(state) ?? ""}</p>
        <hr/>
        <h3>${referralType}</h3>
        <hr/>
        <h3>Personal Info</h3>
        <p>
          ${this.getPersonalInfoHTML(state)}
        </p>
        ${jobCategory ? `<p><b>Job Category:</b> ${jobCategory}</p>` : ""}
        ${this.getServicesInfoHMTL(state)}
        ${this.getClinicalNotesHTML(state)}
        ${this.getQuestionnairesInfoHTML(state)}
      </body>
    </html>
    `
      .replace(/undefined/gi, "-")
      .replace(/true/gi, "Yes")
      .replace(/false/gi, "No")
  }

  /** Getters / Setters */

  get rootStore(): RootStore {
    if (this._rootStore) return this._rootStore
    return (this.constructor as any).rootStore
  }

  get discussionStore(): DiscussionStore {
    return this.rootStore.discussionStore
  }

  get clinicalStore(): ClinicalStore {
    return this.rootStore.clinicalStore
  }

  get referralStore(): ReferralStore {
    return this.rootStore.referralStore
  }

  get wellbeingHubStore(): WellbeingHubStore {
    return this.rootStore.wellbeingHubStore
  }
}
