import { z, ZodSchema } from "zod"
import {
  AssessmentPitchIAPTScript,
  AssessmentPitchIAPTScriptStateSchema
} from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends AssessmentPitchIAPTScriptState {
  mainIssue?: string
}
export type AssessmentPitchDemoPrivateProvidersScriptState = State

export const AssessmentPitchDemoPrivateProvidersScriptStateSchema =
  AssessmentPitchIAPTScriptStateSchema.extend({
    mainIssue: z.string().optional()
  })

export class AssessmentPitchDemoPrivateProvidersScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchDemoPrivateProvidersScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    return {
      nextStep: this.sayIntro
    }
  }

  @step.logState
  sayIntro(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    return {
      body: "I've been designed by therapists to give you a free digital mental health assessment",
      prompt: {
        id: this.getPromptId("sayIntro"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: true },
          { body: "Tell me more", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleIntro
    }
  }

  @step.logState
  async handleIntro(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      body: d.response ? undefined : "Certainly",
      nextStep: this.sayWeCanSpeedUpReferral
    }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental illness",
        "I'll then share the results with you and help you find the best support options available",
        "Sounds ok?"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "Help me decide", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWeCanSpeedUpReferral
    }
  }

  @step.logState
  async handleWeCanSpeedUpReferral(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    if (d.response) {
      return { body: `Good to hear it, ${name}`, nextStep: this.goToCollectPhoneNumber }
    }
    return { nextStep: this.explainMore }
  }

  @step.logState
  explainMore(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "Of course. Mental wellbeing is a complicated topic. There are no quick fixes",
        "But trust me when I say that this is the first step towards a solution",
        "You're at the beginning of an evidence-based pathway that has been shown to help people with a range of common mental health issues",
        `I think this is worth doing now that you're here, ${name}`
      ],
      prompt: {
        id: this.getPromptId("explainMore"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Ok" }, { body: "Go on then" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleExplainMore
    }
  }

  @step.logState
  handleExplainMore(_d: IStepData<State>): IStepResult {
    return {
      body: "👍",
      nextStep: this.goToCollectPhoneNumber
    }
  }

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.goToCollectPhoneNumber(d)
    return { ...result, nextStep: this.askMainIssue }
  }

  @step.logState
  askMainIssue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        d.state.canSendEmail ? "Brilliant" : "Okay",
        `So ${name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you)`
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: AssessmentPitchDemoPrivateProvidersScript) => {
      d.state.mainIssue = d.response
      script.referralStore.setCustomField<State>("mainIssue", d.response)
    }
  )
  @step.checkInputForCrisis({
    getNextStep: (s: AssessmentPitchDemoPrivateProvidersScript) => s.end
  })
  handleMainIssueWithCrisis(d: IStepData<State, string>): IStepResult {
    const name = this.getName(d.state)

    return {
      body: `Thank you for sharing ${name}, you've come to the right place`,
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return AssessmentPitchDemoPrivateProvidersScriptStateSchema
  }
}

/* istanbul ignore next */
export default class AssessmentPitchDemoPrivateProvidersDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchDemoPrivateProviders
  readonly name: string = "AssessmentPitchDemoPrivateProvidersDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      AssessmentPitchDemoPrivateProvidersDialogue.id,
      new AssessmentPitchDemoPrivateProvidersScript(),
      state,
      snapshot
    )
  }
}
