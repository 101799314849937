import React from "react"
import "./CheckboxInput.scss"
import SwitchInput from "../SwitchInput/SwitchInput"
import InlineButton from "../InlineButton/InlineButton"
import classes from "classnames"
import { useConfigStore } from "../../contexts/RootStoreContext"

interface IOption {
  [key: string]: {
    value: boolean
    isRequired: boolean
  }
}

interface Props {
  options: Array<{ body: string; key: string; initialValue?: boolean; isRequired?: boolean }>
  isRequired?: boolean
  onSubmit: (body: string, value: { [key: string]: boolean }) => void
}

export default function CheckboxInput(props: Props): JSX.Element {
  const config = useConfigStore()
  const { options, isRequired = false } = props

  const [data, setData] = React.useState<IOption>(() =>
    options.reduce(
      (t, curr) => ({
        ...t,
        [curr.key]: {
          value: curr.initialValue ?? true,
          isRequired: curr.isRequired
        }
      }),
      {}
    )
  )

  const onSwitchValueChange = React.useCallback(
    (key: string, value: boolean) => {
      const newData = { ...data }
      if (newData[key]) {
        newData[key].value = value
      }
      setData(newData)
    },
    [data]
  )

  const onSubmit = React.useCallback(() => {
    const items = [] as string[]
    const valuesMap = {}
    for (let i = 0, { length } = options; i < length; i++) {
      const item = options[i]
      const value = data[item.key].value
      const label = item.body
      items.push(`${value ? "✅" : "❌"} ${label}`)
      valuesMap[item.key] = value
    }
    const body = items.join("\n")
    props.onSubmit(body, valuesMap)
  }, [data, options, props])

  const isDisabled = React.useMemo(
    () =>
      isRequired
        ? !!Object.values(data).filter(o => !o.value).length
        : !!Object.values(data).filter(o => o.isRequired && !o.value).length,
    [isRequired, data]
  )

  return (
    <div className="lb-checkbox-input-container" data-testid="checkbox-input">
      <div
        className={classes("lb-checkbox-input-options-container", {
          "lb-checkbox-input-options-fullscreen": config.fullscreen
        })}
      >
        {options.map((o, idx) => (
          <SwitchInput
            key={idx}
            label={o.body}
            value={data[o.key].value}
            onValueChange={value => onSwitchValueChange(o.key, value)}
          />
        ))}
      </div>
      <InlineButton
        btn={{ body: "Submit" }}
        onSelect={onSubmit}
        style={{ backgroundColor: config.userMessageBackground || "#6A6983" }}
        disabled={isDisabled}
      />
    </div>
  )
}
