import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

type State = PermissionsScriptState
export type PermissionsNorfolkScriptState = State

export class PermissionsNorfolkScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsNorfolkScript"

  /** Script Steps */

  @step.logState
  showTOSLinks(_d: IStepData<State>): IStepResult {
    const organisationName =
      this.rootStore.configStore.organisationName ?? this.rootStore.configStore.serviceName
    const organisationTerms = this.rootStore.configStore.organisationTerms ?? ""
    return {
      body: [
        `To get you the best referral, I will need to share your answers with ${organisationName}`,
        "I want to reassure you that your details will be stored safely",
        "You can find all the details here:\n\n" +
          "Limbic [Terms of Service](https://static.limbic.ai/access-tos)\n" +
          "Limbic [Privacy Policy](https://static.limbic.ai/pp-access)\n" +
          organisationTerms
      ],
      nextStep: this.promptIUnderstandTerms
    }
  }
}

/* istanbul ignore next */
export default class PermissionsNorfolkDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsNorfolk
  readonly name: string = "PermissionsNorfolkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsNorfolkDialogue.id, new PermissionsNorfolkScript(), state, snapshot)
  }
}
