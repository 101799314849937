import BaseScript, { BaseScriptState } from "../../../BaseScript"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { step } from "../../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import isValidPhoneNumber, { isValidMobilePhone } from "../../../../utils/isvalidPhoneNumber"
import { TrackingEvents } from "../../../../models/Constants"

export const TEXT_MESSAGES_PROMPT = {
  body: "You may send me text messages",
  key: "canSendTextMessagesToPhoneNumber"
}

export const VOICEMAIL_MESSAGES_PROMPT = {
  body: "You may leave voicemail messages",
  key: "canLeaveVoicemailToPhoneNumber"
}

interface State extends BaseScriptState {
  skipCollectPhoneNumberIntro?: boolean
}
export type CollectPhoneNumberScriptState = State

export class CollectPhoneNumberScript extends BaseScript<State> {
  readonly name: string = "CollectPhoneNumberScript"

  /** Optional Generic Handlers */

  onHandlePhoneNumber?(state: State): Promise<IStepResult>
  onHandleCanIContactYouOnPhoneNumber?(state: State): Promise<IStepResult>

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.askPhoneNumber }
  }

  @step.logState
  askPhoneNumber(d: IStepData<State>): IStepResult {
    return {
      body: d.state.skipCollectPhoneNumberIntro
        ? "What's the best phone number to reach you on?"
        : "So, in case we get disconnected, what's the best phone number to reach you on?",
      nextStep: this.showPhoneNumberPrompt
    }
  }

  @step
  sayPleaseTypePhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type your phone number",
      nextStep: this.showPhoneNumberPrompt
    }
  }

  @step.logState
  showPhoneNumberPrompt(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPhoneNumberPrompt"),
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumber
    }
  }

  @step
  returnToAskPhoneNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "So...",
      nextStep: this.sayPleaseTypePhoneNumber
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: CollectPhoneNumberScript) => s.returnToAskPhoneNumber
  })
  async handlePhoneNumber(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      this.track(TrackingEvents.INVALID_PHONE_NUMBER)
      return {
        body: "Sorry this is not a valid phone number. Let's try again",
        nextStep: this.sayPleaseTypePhoneNumber
      }
    }
    d.state.phoneNumber = d.response
    this.setPeople({ phoneNumber: isValidMobilePhone(d.response) ? "MOBILE" : "LANDLINE" })
    const result = await this.onHandlePhoneNumber?.(d.state)
    if (result) return result
    return {
      body: "Thanks",
      nextStep: this.askCanIContactYouOnPhoneNumber
    }
  }

  @step.logState
  askCanIContactYouOnPhoneNumber(d: IStepData<State>): IStepResult {
    const isMobilePhone = isValidMobilePhone(d.state.phoneNumber!)
    return {
      body: "And is it ok to send you text messages or leave a voicemail if I can't get through?",
      prompt: {
        id: this.getPromptId("askCanIContactYouOnPhoneNumber"),
        trackResponse: true,
        type: "checkbox",
        options: isMobilePhone
          ? [TEXT_MESSAGES_PROMPT, VOICEMAIL_MESSAGES_PROMPT]
          : [VOICEMAIL_MESSAGES_PROMPT]
      },
      nextStep: this.handleCanIContactYouOnPhoneNumber
    }
  }

  @step.logStateAndResponse
  async handleCanIContactYouOnPhoneNumber(
    d: IStepData<
      State,
      {
        canSendTextMessagesToPhoneNumber?: boolean
        canLeaveVoicemailToPhoneNumber: boolean
      }
    >
  ): Promise<IStepResult> {
    const canSendTextMessagesToPhoneNumber = d.response.canSendTextMessagesToPhoneNumber
    const canLeaveVoicemailToPhoneNumber = d.response.canLeaveVoicemailToPhoneNumber
    d.state.canSendTextMessagesToPhoneNumber = canSendTextMessagesToPhoneNumber
    d.state.canLeaveVoicemailToPhoneNumber = canLeaveVoicemailToPhoneNumber
    const data = { canSendTextMessagesToPhoneNumber, canLeaveVoicemailToPhoneNumber }
    this.setPeople(data)
    this.track(TrackingEvents.PHONE_PERMISSIONS, data)
    const result = await this.onHandleCanIContactYouOnPhoneNumber?.(d.state)
    if (result) return result
    return { nextStep: this.end }
  }
}

export default class CollectPhoneNumberDialogue extends AdHocDialogue<
  State,
  CollectPhoneNumberScript
> {
  static id = DialogueIDs.CollectPhoneNumber
  readonly name: string = "CollectPhoneNumberDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CollectPhoneNumberDialogue.id, new CollectPhoneNumberScript(), state, snapshot)
  }
}
