import * as Sentry from "@sentry/react"
import { Severity } from "../models/Logger/Severity"
import invariant from "./invariant"
import { backendEnv } from "../config/config"
import type { Primitive } from "@sentry/types"
import type IBreadcrumb from "../models/Logger/IBreadcrumb"
import type ILogger from "../models/Logger/ILogger"
import type { Breadcrumb, Event } from "@sentry/react"
import type { IIAPTService } from "../models/IIAPTService"
import type { IPersistableSurveyResponse } from "../models/ISurvey"

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
const REGEX_RECAPTCHA = /recaptcha/i

export default class Logger implements ILogger {
  static isReady = false
  static _instance: Logger

  /** Static Methods */

  static getInstance(): Logger {
    invariant(this.isReady, "Can't get Logger instance before setting it up")
    if (!this._instance) {
      this._instance = new Logger()
    }
    return this._instance
  }
  static setup(version: string, dsn: string, dist?: string, allowUrls?: string[]): void {
    Sentry.init({
      release: version,
      dist,
      dsn,
      debug: false,
      allowUrls,
      maxBreadcrumbs: 150,
      normalizeDepth: 5,
      environment: backendEnv,
      // 👇 https://github.com/getsentry/sentry-javascript/blob/ab7ba810a97a2acae3dbd2c82b07e3972147bb97/packages/browser/examples/app.js#L38
      ignoreErrors: [REGEX_RECAPTCHA, "reCAPTCHA"],
      beforeSend(event: Event): Event | null {
        if (event.tags && event.tags.fromBot === "true") {
          event.breadcrumbs = event.breadcrumbs?.map(toBreadcrumb).filter(Boolean) as Breadcrumb[]
          return event
        }
        return null
      }
    })
    Sentry.setTag("build", version)
    Sentry.setExtra("build", version)
    this.isReady = true
    this.getInstance()
  }

  /** Methods */

  message(message: string, level: Severity = Severity.Info): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isDev && global.__LB_LOGS__) {
      console.log(message)
    }
    Sentry.withScope(scope => {
      scope.setTag("fromBot", "true")
      Sentry.captureMessage(message, { level })
    })
  }

  breadcrumb(breadCrumb: IBreadcrumb): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isDev && global.__LB_LOGS__) {
      console.log(breadCrumb.message, breadCrumb.data || "")
    }
    Sentry.addBreadcrumb(breadCrumb)
  }

  exception(e: Error, message?: string, severity?: Severity | undefined): void {
    const isErrorRecaptchaRelated =
      e.message.toLowerCase().match(REGEX_RECAPTCHA) ||
      message?.toLowerCase().match(REGEX_RECAPTCHA)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isDev && global.__LB_LOGS__) {
      console.log("ERROR:", e.message)
    }

    let errorSeverity = severity ?? Severity.Error
    if (isErrorRecaptchaRelated) errorSeverity = Severity.Info

    if (message) {
      this.breadcrumb({
        message: `Error: ${e.message}`,
        level: errorSeverity
      })
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    e.name = message ?? e.name
    if (isErrorRecaptchaRelated) {
      // 👇 This related to line 78, if it's not a message a breadcrumb hasn't been send
      // no need to repeat it twice
      if (!message) {
        this.breadcrumb({
          message: `Error: ${e.message}`,
          level: Severity.Info
        })
      }
      return
    } else {
      Sentry.withScope(scope => {
        scope.setLevel(errorSeverity ?? Severity.Error)
        scope.setTag("fromBot", "true")
        Sentry.captureException(e)
      })
    }
  }

  setTag(key: string, value: Primitive): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isDev && global.__LB_LOGS__) {
      console.log({ [key]: value })
    }
    Sentry.setTag(key, value)
  }
}

function toBreadcrumb(b: Breadcrumb): Breadcrumb | null {
  try {
    if (b.category === "console") return null
    if (!b.data) return b
    const keys = Object.keys(b.data)
    for (const key of keys) {
      let item = b.data[key]
      switch (key) {
        case "iapt":
          item = toIAPT(item)
          break
        case "iaptSuggestions":
        case "eligibleIAPTs":
          item = item?.length
          break
        case "phq9Responses":
        case "gad7Responses":
        case "wsasResponses":
        case "pdssResponses":
        case "phobiaScaleResponses":
        case "pcl5Responses":
        case "spinResponses":
        case "ociResponses":
        case "shai18Responses":
          item = toQuestionnaire(item, true)
          break
        case "employmentStatusResponses":
        case "medicationResponses":
        case "riskPathwayResponses":
        case "accommodationResponses":
        case "specificPhobiaResponses":
          item = toQuestionnaire(item, false)
          break
        default:
          continue
      }
      b.data = { ...b.data, [key]: item }
    }
  } catch (e) {
    console.error(e)
  }
  return b
}

function toIAPT(iapt?: IIAPTService): Partial<IIAPTService> | undefined {
  if (!iapt) return iapt
  const { referralForm, eligibleGPs, phoneNumber, postcode, formattedName, ...restIAPT } = iapt
  return restIAPT
}

function toQuestionnaire(
  questionnaire: IPersistableSurveyResponse[],
  pointsOnly?: boolean
): Record<string, string | number | undefined>[] {
  return questionnaire.map(q => {
    const { title, points, id, ...rest } = q
    if (pointsOnly) return { id, points }
    return { id, points, ...rest }
  })
}
