import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import GoodbyeScript from "./GoodbyeScript"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { MHMCrisisPhoneMapped } from "../../../config/apiKeysMap"

interface State extends GoodbyeScriptState {
  canKeepSelfSafe?: boolean
  requiresUrgentSupport?: boolean
}

export type GoodbyeMHMScriptState = State

export class GoodbyeMHMScript extends GoodbyeScript {
  readonly name: string = "GoodbyeMHMScript"

  /** Script Steps */

  @step
  async start(d: IStepData<State>): Promise<IStepResult> {
    if (d.state.referralSubmitted) {
      return super.start(d)
    }
    return {
      nextStep: this.sayIHopeIHelped
    }
  }

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    await this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()

    return {
      body: [
        "Well done for taking this important step towards better mental health",
        "You can now close the window"
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    state: State,
    body: string[],
    recapMessage: string | undefined
  ): Promise<IStepResult> {
    const choices = [{ body: "Okay" }]
    if (recapMessage) {
      choices.push({ body: "I understand" })
    }

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askFeedback
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      let referralMessage = `I've referred you to ${iaptName}`
      if (!this.clinicalStore.isRisk) {
        referralMessage =
          referralMessage +
          "\n\nSomeone from the service team will be in contact within 6 weeks, but hopefully sooner, to book your appointment with a relevant clinician"
      }
      return state.isIdleSubmitted //
        ? `It looks like there hasn’t been any activity for some time so I've referred you to ${iaptName}`
        : referralMessage
    }
  }

  async getIntroMessage(state: State): Promise<string | void> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    if (state.referralSubmitted) {
      return isCrisis
        ? `Thank you for sharing this information with me ${name}`
        : "I hope I’ve been able to help you today"
    }
    return isCrisis
      ? "I hope I’ve been able to help you today"
      : `Thank you for sharing this information with me ${name}`
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    const contact = state.iapt?.id ? MHMCrisisPhoneMapped[state.iapt.id] : "999"
    if (this.clinicalStore.isHighRisk) {
      return `Because you identified as being in crisis, someone from our duty of care team will call you within 1 working day. Please remember, Limbic is not an emergency response service and you are encouraged to contact ${contact} if you feel you are in danger.`
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    const contact = state.iapt?.id ? MHMCrisisPhoneMapped[state.iapt.id] : "999"
    if (this.clinicalStore.isModerateRisk) {
      return `Because you identified as being in crisis, someone from our duty of care team will call you within 1 working day. Please remember, Limbic is not an emergency response service and you are encouraged to contact ${contact} if you feel you are in danger.`
    }
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    if (state.referralSubmitted) {
      const lastMessage = isCrisis
        ? `Thank you for sharing this information with me ${name}`
        : `Well done for taking this important step towards better mental health`
      return [lastMessage]
    }
    return [`Well done for taking this important step towards better mental health`]
  }
}

/* istanbul ignore next */
export default class GoodbyeMHMDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeMHM
  readonly name: string = "GoodbyeMHMDialogue"

  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeMHMDialogue.id, new GoodbyeMHMScript(), state, snapshot)
  }
}
