import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import AssessmentScript, { AssessmentScriptState } from "./AssessmentScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentScriptState

export class AssessmentDemoPrivateProvidersScript extends AssessmentScript {
  readonly name: string = "AssessmentDemoPrivateProvidersScript"

  /** Generic Handlers */

  // TODO: Check if we need to keep Employment questions (and remove if we do)
  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

/* istanbul ignore next */
export default class AssessmentDemoPrivateProvidersDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentDemoPrivateProviders
  readonly name: string = "AssessmentDemoPrivateProvidersDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      AssessmentDemoPrivateProvidersDialogue.id,
      new AssessmentDemoPrivateProvidersScript(),
      state,
      snapshot
    )
  }
}
