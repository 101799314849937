import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = BaseScriptState

export class SelfReferralPitchGMHubScript extends BaseScript<State> {
  readonly name: string = "SelfReferralPitchGMHubScript"

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.end }
  }
}

export default class SelfReferralPitchGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralPitchGMHub
  readonly name: string = "SelfReferralPitchGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralPitchGMHubDialogue.id, new SelfReferralPitchGMHubScript(), state, snapshot)
  }
}
