import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import "./SidebarEssex.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Refer yourself to NHS Talking Therapies</h3>
        </div>
        <p>
          We provide a range of therapy and guided self-help for common mental health conditions
          such as anxiety and depression. These treatments often take the form of ‘talking
          therapies’ where you work with a fully trained and accredited mental health professional.
        </p>
        <p>
          All services are free. Should you be experiencing emotional or mental health issues that
          affect your daily living, employment, or wider health needs then NHS Talking Therapies
          will almost certainly benefit you.
        </p>
        <p>
          If you feel that your referral is something you would like to discuss with the team please
          call us on one of these numbers:
        </p>
        <p>
          <div>Warrington: 01925 401 720</div>
          <div>Northumberland: 0300 3030 700</div>
          <div>Sefton: 0300 303 2708</div>
        </p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
