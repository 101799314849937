import React from "react"
import { observer } from "mobx-react"
import { useConfigStore } from "../../contexts/RootStoreContext"
import "./Sidebar.scss"

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Refer yourself to {config.organisationName}</h3>
        </div>
        <p>
          We are your talking therapies service. IPRS Health and Vitality are here to support you by
          offering a range of short term talking therapies to all members.
        </p>
        <div className="lb-vitality-iapt-icons">
          {config.iaptIcons?.map?.(link => (
            <img key={link} className="nhs-logo" src={link} alt={link} />
          ))}
        </div>
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card full-height">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest governance standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
