import React, { useCallback, useRef, useState } from "react"
import classnames from "classnames"
import { useConfigStore } from "../../contexts/RootStoreContext"
import TextInput from "./TextInput"
import InlineButton from "../InlineButton/InlineButton"
import "./AddressInput.scss"
import type { IDataPoints } from "@limbic/types"
import type ISelectable from "../../../models/ISelectable"
import IAddress from "../../../models/IAddress"

const submitButton = { body: "Submit" } as ISelectable

interface IProps {
  onSubmit?: (address: IAddress, dataPoints?: IDataPoints) => void
}

export default function AddressInput(props: IProps): JSX.Element {
  const config = useConfigStore()
  const [address, setAddress] = useState<string>()
  const [address2, setAddress2] = useState<string | undefined>()
  const [city, setCity] = useState<string>()
  const [county, setCounty] = useState<string>()
  const [addressError, setAddressError] = useState<string | undefined>()
  const [cityError, setCityError] = useState<string | undefined>()
  const [countyError, setCountyError] = useState<string | undefined>()
  const dirtyFields = useRef({ address: false, city: false, county: false })

  const changeAddress = useCallback((text?: string) => {
    const isDirty = dirtyFields.current.address
    dirtyFields.current.address = true
    if (text?.trim().replace("*", "")) setAddressError(undefined)
    else if (isDirty) setAddressError("Address can't be empty")
    setAddress(text)
  }, [])

  const changeAddress2 = useCallback((text?: string) => {
    setAddress2(text)
  }, [])

  const changeCity = useCallback((text?: string) => {
    const isDirty = dirtyFields.current.city
    dirtyFields.current.city = true
    if (text?.trim().replace("*", "")) setCityError(undefined)
    else if (isDirty) setCityError("City can't be empty")
    setCity(text)
  }, [])

  const changeCounty = useCallback((text?: string) => {
    const isDirty = dirtyFields.current.county
    dirtyFields.current.county = true
    if (text?.trim().replace("*", "")) setCountyError(undefined)
    else if (isDirty) setCountyError("County can't be empty")
    setCounty(text)
  }, [])

  const onSubmit = useCallback(() => {
    const fullAddress = {
      address: address?.trim().replace("*", ""),
      address2: address2?.trim().replace("*", ""),
      city: city?.trim().replace("*", ""),
      county: county?.trim().replace("*", "")
    }

    // sanity check
    if (!fullAddress.address || !fullAddress.city || !fullAddress.county) {
      !fullAddress.address && setAddressError("Address can not be empty")
      !fullAddress.city && setCityError("City can not be empty")
      !fullAddress.county && setCountyError("County can not be empty")
      return
    }

    props.onSubmit?.(fullAddress as IAddress)
  }, [address, address2, city, county, props])

  const active = address?.length && city?.length && county?.length // not sure about county?.length
  const submitButtonCSS = classnames("lb-user-input-address-submit-button", { active })
  return (
    <div className="lb-user-input-address-container" data-testid="address-input">
      <ErrorInfo error={addressError} />
      <TextInput
        borderless
        hasError={!!addressError}
        placeholder={"Address 1 *"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoComplete={"address"}
        onChangeText={changeAddress}
        onSubmit={onSubmit}
        showOnSubmitButton={false}
      />
      <TextInput
        borderless
        autoFocus={false}
        placeholder={"Address 2"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoComplete={"address2"}
        onChangeText={changeAddress2}
        onSubmit={onSubmit}
        showOnSubmitButton={false}
      />
      <ErrorInfo error={cityError} />
      <ErrorInfo error={countyError} />
      <div className="lb-address-city-county-container">
        <TextInput
          borderless
          autoFocus={false}
          hasError={!!cityError}
          placeholder={"City *"}
          clearOnSubmit={false}
          multiline={false}
          autoCorrect={"none"}
          autoComplete={"city"}
          onChangeText={changeCity}
          onSubmit={onSubmit}
          showOnSubmitButton={false}
        />
        <TextInput
          borderless
          autoFocus={false}
          hasError={!!countyError}
          placeholder={"County *"}
          clearOnSubmit={false}
          multiline={false}
          autoCorrect={"none"}
          autoComplete={"county"}
          onChangeText={changeCounty}
          onSubmit={onSubmit}
          showOnSubmitButton={false}
        />
      </div>
      <InlineButton //
        fullWidth
        disabled={!active}
        style={{ backgroundColor: config.userMessageBackground }}
        btn={submitButton}
        onSelect={onSubmit}
        buttonClassName={submitButtonCSS}
      />
    </div>
  )
}

interface IErrorInfoProps {
  error?: string
}

function ErrorInfo(props: IErrorInfoProps) {
  const { error } = props
  if (!error) return null
  return (
    <div className="lb-user-input-address-error-container">
      <span className="lb-user-input-address-error">{error}</span>
    </div>
  )
}
