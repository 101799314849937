import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentPitchIAPTScriptState

export class AssessmentPitchGMHubScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchGMHubScript"

  /** Script Steps */
  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    if (this.wellbeingHubStore.userType !== "individual") {
      return {
        nextStep: this.end
      }
    }
    return {
      nextStep: this.sayIntro
    }
  }

  @step.logState
  sayIntro(d: IStepData<State>): IStepResult {
    this.updateReferralType(d.state)
    return {
      body: "I've been designed by therapists to give you a free digital mental health assessment",
      prompt: {
        id: this.getPromptId("sayIntro"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: true },
          { body: "Tell me more", value: false }
        ]
      },
      nextStep: this.handleIntro
    }
  }

  @step.logState
  async handleIntro(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      body: d.response ? undefined : "Certainly",
      nextStep: this.sayWeCanSpeedUpReferral
    }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions that measure mental wellbeing",
        "I'll then share the results with you and help you find the best support options available",
        "Sounds ok?"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "Help me decide", value: false }
        ]
      },
      nextStep: this.handleWeCanSpeedUpReferral
    }
  }

  @step.logState
  async handleWeCanSpeedUpReferral(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    if (d.response) {
      return { body: `Good to hear it, ${name}`, nextStep: this.end }
    }
    return { nextStep: this.explainMore }
  }

  @step.logState
  explainMore(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "Of course. Mental wellbeing is a complicated topic. There are no quick fixes",
        "But trust me when I say that this is the first step towards a solution",
        "You're at the beginning of an evidence-based pathway that has been shown to help people with a range of common mental health issues",
        `I think this is worth doing now that you're here, ${name}`
      ],
      prompt: {
        id: this.getPromptId("explainMore"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Ok" }, { body: "Go on then" }]
      },
      nextStep: this.handleExplainMore
    }
  }

  @step.logState
  handleExplainMore(_d: IStepData<State>): IStepResult {
    return {
      body: "👍",
      nextStep: this.end
    }
  }
}

export default class AssessmentPitchGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchGMHub
  readonly name: string = "AssessmentPitchGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchGMHubDialogue.id, new AssessmentPitchGMHubScript(), state, snapshot)
  }
}
