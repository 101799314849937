import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import {
  EligibilityCheckIAPTScript,
  EligibilityCheckIAPTStateSchema
} from "./EligibilityCheckIAPTDialogue"
import { WellbeingHubUserTypes } from "../../../models/Constants"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type SEEKING_SUPPORT_FOR = "myself" | "someone_else"

const PRONOUNS_MAP: Record<WellbeingHubUserTypes, string[]> = {
  individual: ["you're", "You can", "you can"],
  managerIndividual: ["they're", "They can", "they can"],
  managerTeam: ["they're", "They can", "they can"]
}

interface State extends EligibilityCheckIAPTState {
  seekingSupportFor?: SEEKING_SUPPORT_FOR
  isHealthOrSocialWorker?: boolean
  supportForTeam?: boolean
  organisation?: string
}

export type EligibilityCheckWellbeingHubScriptState = State

export const EligibilityCheckWellbeingHubScriptStateSchema = EligibilityCheckIAPTStateSchema.extend(
  {
    seekingSupportFor: z.union([z.literal("myself"), z.literal("someone_else")]).optional(),
    isHealthOrSocialWorker: z.boolean().optional(),
    supportForTeam: z.boolean().optional(),
    organisation: z.string().optional()
  }
)

export class EligibilityCheckWellbeingHubScript extends EligibilityCheckIAPTScript {
  readonly name: string = "EligibilityCheckWellbeingHubScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askAreYouSeekingSupportForYourSelf }
  }

  @step.logState
  askAreYouSeekingSupportForYourSelf(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Are you seeking wellbeing support for yourself or someone else?",
        "Please note we are unable to support individuals under the age of 16"
      ],
      prompt: {
        id: this.getPromptId("askAreYouSeekingSupportForYourSelf"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Myself", value: "myself" },
          { body: "Someone else (over 16)", value: "someone_else" }
        ]
      },
      nextStep: this.handleAreYouSeekingSupportForYourSelf
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, SEEKING_SUPPORT_FOR>, script: EligibilityCheckWellbeingHubScript) => {
      d.state.seekingSupportFor = d.response
      script.referralStore.setCustomField<State>("seekingSupportFor", d.response)
    }
  )
  handleAreYouSeekingSupportForYourSelf(d: IStepData<State, SEEKING_SUPPORT_FOR>): IStepResult {
    if (d.response === "someone_else") {
      return { nextStep: this.askIndividualOrTeam }
    }
    return { nextStep: this.askDoYouWorkInHealthOrSocialCare }
  }

  @step.logState
  askIndividualOrTeam(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you looking for wellbeing support for a team?",
      prompt: {
        id: this.getPromptId("askIndividualOrTeam"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleIndividualOrTeam
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: EligibilityCheckWellbeingHubScript) => {
      d.state.supportForTeam = d.response
      script.referralStore.setCustomField<State>("supportForTeam", d.response)
    }
  )
  handleIndividualOrTeam(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.wellbeingHubStore.setWellbeingHubUserType("managerTeam")
      return { nextStep: this.sayYoureEligible }
    }
    this.wellbeingHubStore.setWellbeingHubUserType("managerIndividual")
    return { nextStep: this.sayYoureEligible }
  }

  @step.logState
  askDoYouWorkInHealthOrSocialCare(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you work in health or social care?",
      prompt: {
        id: this.getPromptId("askDoYouWorkInHealthOrSocialCare"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleDoYouWorkInHealthOrSocialCare
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: EligibilityCheckWellbeingHubScript) => {
      d.state.isHealthOrSocialWorker = d.response
      script.referralStore.setCustomField<State>("isHealthOrSocialWorker", d.response)
    }
  )
  handleDoYouWorkInHealthOrSocialCare(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askWhichOrganisationDoYouWorkFor }
    }
    return { nextStep: this.sayNotEligible }
  }

  @step.logState
  askWhichOrganisationDoYouWorkFor(d: IStepData<State>): IStepResult {
    const organisations = this.getOrganisations(d.state)
    if (!organisations?.length) {
      this.logBreadcrumb("ORGANISATIONS NOT FOUND", d.state, { organisations })
      this.logMessage("ORGANISATIONS NOT FOUND")
      return { nextStep: this.sayYoureEligible }
    }

    return {
      body: "Which organisation do you work in?",
      prompt: {
        id: this.getPromptId("askWhichOrganisationDoYouWorkFor"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: organisations.map(o => ({ body: o, value: o }))
      },
      nextStep: this.handleWhichOrganisationDoYouWorkFor
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: EligibilityCheckWellbeingHubScript) => {
      d.state.organisation = d.response
      script.referralStore.setCustomField<State>("organisation", d.response)
    }
  )
  handleWhichOrganisationDoYouWorkFor(_d: IStepData<State, string>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("individual")
    return { nextStep: this.sayYoureEligible }
  }

  @step.logState
  sayYoureEligible(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const wellbeingHubUserType = this.wellbeingHubStore.userType
    const pronouns = PRONOUNS_MAP[wellbeingHubUserType!]

    let nextStep, body
    switch (wellbeingHubUserType) {
      case "individual":
        body = [`Good news - ${pronouns[0]} eligible for free support from ${iaptName}`]
        nextStep = this.goToSelfReferralIndividual
        break
      case "managerIndividual":
        body = [
          `Okay you want to refer a team member for support. Thanks!`,
          `Good news - ${pronouns[0]} eligible for free support from ${iaptName}`
        ]
        nextStep = this.goToSelfReferralManagerIndividual
        break
      case "managerTeam":
        body = [
          `Okay you want to refer a team for support. Thanks!`,
          `Good news - ${pronouns[0]} eligible for free support from ${iaptName}`
        ]
        nextStep = this.goToSelfReferralManagerTeam
        break
      default:
        body = undefined
        nextStep = this.goToGoodbye
        break
    }
    return {
      body,
      nextStep
    }
  }

  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `So ${name}, ${organisationName} currently support staff members working within the Health and Social care sector only`,
        "Or you can talk to your GP and they will help you"
      ],
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  goToSelfReferralManagerTeam(_d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("managerTeam")
    return {
      nextStep: this.end
    }
  }

  @step.logState
  goToSelfReferralManagerIndividual(_d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("managerIndividual")
    return {
      nextStep: this.end
    }
  }

  @step.logState
  goToSelfReferralIndividual(_d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("individual")
    return {
      nextStep: this.end
    }
  }

  @step
  sayINeedToAskSomeDetails(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `In order to create a referral to ${organisationName}, I just need to confirm a few details with you`,
        "You can re-type your answer at any point by clicking the 'Undo' button next to the message you want to change"
      ],
      nextStep: this.askAreYouSeekingSupportForYourSelf
    }
  }

  getOrganisations(_state: State): string[] {
    return []
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return EligibilityCheckWellbeingHubScriptStateSchema
  }
}

export default class EligibilityCheckWellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckWellbeingHub
  readonly name: string = "EligibilityCheckWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckWellbeingHubDialogue.id,
      new EligibilityCheckWellbeingHubScript(),
      state,
      snapshot
    )
  }
}
