import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostITalkScriptState = State

export class UnderAgeSignpostITalkScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostITalkScript"

  /** Script Steps */
  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const threshold = this.getIAPTServiceAgeThreshold(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, there's a special type of mental health support for people under the age of ${threshold}`,
        `${iaptName} do not currently provide this`,
        "However, you can find loads of helpful resources and support from local services if you visit this page [https://www.solentmind.org.uk/support-for-you/our-services](https://www.solentmind.org.uk/support-for-you/our-services) and filter for Children and Young People",
        "Or you can talk to your doctor and they will help you"
      ],
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: "okay" },
          { body: "Thanks", value: "thanks" }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleNotEligible
    }
  }
}

/* istanbul ignore next */
export default class UnderAgeSignpostITalkDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderageSignpostITalk
  readonly name: string = "UnderAgeSignpostITalkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostITalkDialogue.id, new UnderAgeSignpostITalkScript(), state, snapshot)
  }
}
