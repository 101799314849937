import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { DiscussionSteps } from "../../../models/Constants"
import { DialogueIDs } from "../../DialogueIDs"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"

interface State extends BaseScriptState {
  isIndividual?: boolean
}

export class LeadClassificationLimbicScript extends BaseScript<State> {
  readonly name = "LeadClassificationLimbicScript"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askAboutUser }
  }

  @step
  askAboutUser(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Let's get to know one another a little better",
        "First of all, tell me a bit about yourself?"
      ],
      prompt: {
        id: this.getPromptId("askAboutUser"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "I work in a Mental Health Provider organisation", value: "provider" },
          { body: "I work in another company", value: "other" },
          { body: "I'm an individual looking for mental health support", value: "individual" }
        ]
      },
      nextStep: this.handleAboutUser
    }
  }

  @step
  handleAboutUser(d: IStepData<State, "provider" | "other" | "individual">): IStepResult {
    if (d.response === "individual") return { nextStep: this.signpostIndividual }
    return { body: "Thanks", nextStep: this.askWhatAreYouInterestedIn }
  }

  @step
  signpostIndividual(d: IStepData<State>): IStepResult {
    d.state.isIndividual = true
    return {
      body: [
        "Just to make you aware, Limbic is not currently available directly to individuals",
        "If you are in need of mental health support please contact your local health services",
        "Alternatively, you can talk to the Samaritans about how you are feeling or text SHOUT to 85258",
        "Always contact emergency services in the case of physical emergencies"
      ],
      nextStep: this.askIndividualEmail
    }
  }

  @step
  askIndividualEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "If you'd like to leave your email as an expression of interest, please write it here",
      prompt: {
        id: this.getPromptId("askIndividualEmail"),
        trackResponse: true,
        type: "email",
        placeholder: "Please enter your Email"
      },
      nextStep: this.handleIndividualEmail
    }
  }

  @step
  handleIndividualEmail(_d: IStepData<State, string>): IStepResult {
    // TODO: do something with the email here
    return { nextStep: this.sayThanksForIndividualEmail }
  }

  @step
  sayThanksForIndividualEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Thank you for your interest. If you want to learn more select the button below",
      prompt: {
        id: this.getPromptId("sayThanksForIndividualEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Learn more about Limbic", value: "limbic" }]
      },
      nextStep: this.goToLearnAboutLimbic
    }
  }

  @step
  askWhatAreYouInterestedIn(_d: IStepData<State>): IStepResult {
    return {
      body: "What is it that you're interested in today?",
      prompt: {
        id: this.getPromptId("askWhatAreYouInterestedIn"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Talk to sales", value: "sales" },
          { body: "Learn about Limbic", value: "limbic" }
        ]
      },
      nextStep: this.handleWhatAreYouInterestedIn
    }
  }

  @step
  handleWhatAreYouInterestedIn(d: IStepData<State, "sales" | "limbic">): IStepResult {
    if (d.response === "limbic") return { nextStep: this.goToLearnAboutLimbic }
    return { nextStep: this.end }
  }

  @step
  goToLearnAboutLimbic(d: IStepData<State>): IStepResult {
    const LearnAboutLimbic = this.discussionStore.getDialogueClass(DiscussionSteps.LearnAbout)
    return {
      nextDialogue: LearnAboutLimbic ? new LearnAboutLimbic({ ...d.state }) : undefined,
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class LeadClassificationLimbicDialogue extends Dialogue<State> {
  static id = DialogueIDs.LeadClassificationLimbic
  readonly name: string = "LeadClassificationLimbicDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      LeadClassificationLimbicDialogue.id,
      new LeadClassificationLimbicScript(),
      state,
      snapshot
    )
  }
}
