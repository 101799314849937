import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionIAPTScriptState } from "./IntroductionIAPTDialogue"
import {
  IntroductionIAPTScript,
  IntroductionIAPTScriptStateSchema
} from "./IntroductionIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { z, ZodSchema } from "zod"

export type TreatmentPaths = "Self Referral" | "Wellbeing Courses and Webinars" | "Online Modules"
export type AppointmentOption = "Self Referral" | "Wellbeing Courses and Webinars"
const possibleReferralTypes = ["qr", "text-message", "website"]

interface State extends IntroductionIAPTScriptState {
  treatmentPath?: TreatmentPaths
  appointmentOption?: AppointmentOption
}

export const IntroductionITalkStateSchema = IntroductionIAPTScriptStateSchema.extend({
  treatmentPath: z
    .union([
      z.literal("Self Referral"),
      z.literal("Online Modules"),
      z.literal("Wellbeing Courses and Webinars")
    ])
    .optional(),
  appointmentOption: z
    .union([z.literal("Self Referral"), z.literal("Wellbeing Courses and Webinars")])
    .optional()
})

export class IntroductionITalkScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionITalkScript"

  @step.logState
  start(d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    if (directReferral) {
      const queryParams = new URLSearchParams(window.location.search)
      const type = queryParams.get("referral-source") || "website"
      const clinicalNotes = this.referralStore.clinicalNotes
      if (possibleReferralTypes.includes(type?.toLowerCase())) {
        this.setPeople({ referralSource: type })
        const urlNote = [`Referral came in through ${type}`]
        this.referralStore.__dangerouslySetClinicalNotes([...clinicalNotes, ...urlNote])
      }
    }

    return super.start(d)
  }

  /* Script Steps */
  sayStaticReferralURL(_d: IStepData<State>): IStepResult {
    const { formLink } = this.rootStore.configStore
    if (formLink) {
      return {
        body: `If you are not in need of urgent help today and prefer to fill in a standard web form, you can do so [here](${formLink})`,
        isStaticReferralURL: true,
        nextStep: this.askTreatmentPath
      }
    }
    return { nextStep: this.askTreatmentPath }
  }

  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: "Can I help?",
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: `Yes, I'd like to get support from ${organisationName}`, value: true },
          { body: "No, I'm just browsing", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }

  @step.logState
  handleCanIHelp(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askTreatmentPath }
    }
    return { nextStep: this.sayIfYouChangeYourMindWakeMeUp }
  }

  @step.logState
  askTreatmentPath(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Which treatment path are you most interested in?",
        "For more information about the available options please visit [our website](https://www.italk.org.uk/)"
      ],
      prompt: {
        id: this.getPromptId("askTreatmentPath"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Self refer for an assessment", value: "Self Referral" },
          {
            body: "Book onto a wellbeing course or webinar",
            value: "Wellbeing Courses and Webinars"
          },
          { body: "Start online modules now", value: "Online Modules" }
        ]
      },
      nextStep: this.handleTreatmentPath
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, TreatmentPaths>, script: IntroductionITalkScript) => {
    d.state.treatmentPath = d.response
    script.referralStore.setCustomField<State>("treatmentPath", d.response)
  })
  handleTreatmentPath(d: IStepData<State, TreatmentPaths>): IStepResult {
    if (d.response === "Online Modules") {
      return { nextStep: this.signpostOnlineModules }
    }
    d.state.appointmentOption = d.response
    return { nextStep: this.end }
  }

  @step.logState
  signpostOnlineModules(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Please visit the link below to get started with your online modules",
        "Write 'Hello Limbic' at any time if you want to carry on with me instead"
      ],
      prompt: {
        id: this.getPromptId("signpostOnlineModules"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: "Online Modules 👉",
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://italk.silvercloudhealth.com/signup/")
              a.setAttribute("target", "_blank")
              a.click()
            }
          }
        ],
        textPrompt: {
          placeholder: 'Type "Hello Limbic" to continue here',
          forceValue: true
        }
      },
      nextStep: this.handleTypeHeyLimbicToWakeMeUpWithCrisis
    }
  }
  getStateSchema(): ZodSchema | undefined {
    return IntroductionITalkStateSchema
  }
}

/* istanbul ignore next */
export default class IntroductionITalkDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionITalk
  readonly name: string = "IntroductionITalkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionITalkDialogue.id, new IntroductionITalkScript(), state, snapshot)
  }
}
