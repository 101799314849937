import {
  CollectPhoneNumberScript,
  CollectPhoneNumberScriptState
} from "./CollectPhoneNumberDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { IStepResult } from "../../../../backend/chatbot/models/IStep"

type State = CollectPhoneNumberScriptState
export type CollectPhoneNumberNorfolkScriptState = State

export class CollectPhoneNumberNorfolkScript extends CollectPhoneNumberScript {
  readonly name: string = "CollectPhoneNumberNorfolkScript"

  /** Generic Handlers */

  async onHandlePhoneNumber(state: State): Promise<IStepResult> {
    state.canSendTextMessagesToPhoneNumber = true
    state.canLeaveVoicemailToPhoneNumber = true
    return { nextStep: this.end }
  }
}

export default class CollectPhoneNumberNorfolkDialogue extends AdHocDialogue<
  State,
  CollectPhoneNumberNorfolkScript
> {
  static id = DialogueIDs.CollectPhoneNumberNorfolk
  readonly name: string = "CollectPhoneNumberNorfolkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CollectPhoneNumberNorfolkDialogue.id,
      new CollectPhoneNumberNorfolkScript(),
      state,
      snapshot
    )
  }
}
