import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

/**
 * NOTE: List is here:
 * Northumberland: https://docs.google.com/spreadsheets/d/1p7daBkW6Xwc4dgMoEUSbvQkgn53r0u-m/edit#gid=1238824209
 * Sefton: https://docs.google.com/spreadsheets/d/1jyEzY6yA6uBRrFmEEIJVB7Kq5LrVh0zl/edit#gid=1175594561
 * Warrington: https://docs.google.com/spreadsheets/d/1olrGvf0D5NvXADqp6rvHHKuTBEuL_zGl/edit#gid=1382603063
 */

const iaptGPsMapNorthumberland: IAPTGPMap = {
  Y00151: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84002: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84003: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84005: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84006: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84007: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84008: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84009: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84011: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84013: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84014: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84015: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84016: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84018: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84020: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84021: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84022: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84024: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84025: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84026: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84027: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84028: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84029: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84030: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84031: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84032: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84033: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84034: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84035: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84036: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84037: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84038: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84039: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84040: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84042: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84043: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84044: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84045: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84047: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84048: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84604: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84609: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84613: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84614: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84618: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84619: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84001: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84010: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  A84046: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  S16206: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  S16047: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
  S16507: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND
}
const iaptGPsMapSefton: IAPTGPMap = {
  N84001: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84002: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84003: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84004: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84005: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84006: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84007: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84008: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84009: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84010: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84011: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84012: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84013: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84014: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84015: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84016: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84017: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84018: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84019: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84020: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84021: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84023: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84024: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84025: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84026: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84027: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84028: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84029: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84032: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84034: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84035: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84036: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84037: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84038: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84041: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84043: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84605: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84611: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84613: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84614: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84615: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84616: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84617: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84618: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84621: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84622: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84624: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84625: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84626: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84627: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84628: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  N84630: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  Y00446: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  Y02514: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
  Y02610: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON
}
const iaptGPsMapWarrington: IAPTGPMap = {
  N81645: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81114: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81014: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81028: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  Y04925: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81637: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81059: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81097: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81628: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  Y01108: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81048: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81056: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81089: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81012: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81041: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81007: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81108: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81065: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81107: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81109: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81083: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81020: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81036: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81075: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81623: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
  N81122: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON
}

export const iaptGPMapMHM: IAPTGPMap = {
  ...iaptGPsMapNorthumberland,
  ...iaptGPsMapSefton,
  ...iaptGPsMapWarrington
}
