import {
  ARMED_FORCES_MAYDEN_ITALK,
  CIVIL_STATUS_MAYDEN_ITALK,
  DISABILITY_MAYDEN_ITALK,
  ETHNICITY_MAYDEN_ITALK,
  GENDER_MAYDEN_ITALK,
  GenderBirthAssigned,
  LANGUAGE_MAYDEN_ITALK,
  LTC_MAYDEN_ITALK,
  NATIONALITY_MAYDEN,
  PERINATAL_MAYDEN_ITALK,
  RELIGION_MAYDEN_ITALK,
  SEXUALITY_MAYDEN_ITALK
} from "@limbic/types"

export const perinatalStatuses: Record<string, PERINATAL_MAYDEN_ITALK> = {
  "Prefer not to say": "NOT_ANSWERED",
  "None apply": "NONE",
  "Child under 1": "CHILD_UNDER_1",
  Pregnant: "PREGNANT"
}

export const sexualities: Record<string, SEXUALITY_MAYDEN_ITALK> = {
  Heterosexual: "HETEROSEXUAL",
  "Female Homosexual": "HOMOSEXUAL_FEMALE",
  "Male Homosexual": "HOMOSEXUAL_MALE",
  Bisexual: "BISEXUAL",
  "Sexually attracted to neither Male nor Female Sex": "ASEXUAL",
  Other: "NOT_LISTED",
  Unsure: "UNSURE",
  "Not stated": "NOT_ANSWERED"
}

export const genders: Record<string, GENDER_MAYDEN_ITALK> = {
  "Female (including trans woman)": "FEMALE",
  "Male (including trans man)": "MALE",
  "Non-Binary": "NON_BINARY",
  Other: "NOT_LISTED",
  "Not stated": "NOT_ANSWERED",
  "Not known": "UNKNOWN"
}

export const ethnicities: Record<string, ETHNICITY_MAYDEN_ITALK> = {
  "Asian or Asian British, Bangladeshi": "ASIAN_BRITISH_BANGLADESHI",
  "Asian or Asian British, Indian": "ASIAN_BRITISH_INDIAN",
  "Any other Asian background": "ASIAN_BRITISH_OTHER",
  "Asian or Asian British, Pakistani": "ASIAN_BRITISH_PAKISTANI",
  "Black or Black British, African": "BLACK_BRITISH_AFRICAN",
  "Black or Black British, Caribbean": "BLACK_BRITISH_CARIBBEAN",
  "Any other Black background": "BLACK_BRITISH_OTHER",
  "Other Ethnic Groups, Chinese": "CHINESE",
  "Any other mixed background": "MIXED_OTHER",
  "Mixed, White & Asian": "MIXED_WHITE_ASIAN",
  "Mixed, White & Black African": "MIXED_WHITE_BLACK_AFRICAN",
  "Mixed, White & Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "White British": "WHITE_BRITISH",
  "White Irish": "WHITE_IRISH",
  "White, any other White background": "WHITE_OTHER",
  "Any other ethnic group": "OTHER",
  "Not stated": "NOT_ANSWERED",
  "Not known": "UNKNOWN"
}

export const religions: Record<string, RELIGION_MAYDEN_ITALK> = {
  Agnostic: "AGNOSTIC",
  None: "NONE",
  "Prefer not to say": "DECLINE_TO_RESPOND",
  Bahai: "BAHAI",
  Buddhist: "BUDDHIST",
  Christian: "CHRISTIAN",
  Hindu: "HINDU",
  Jain: "JAIN",
  Jewish: "JEWISH",
  Muslim: "MUSLIM",
  Pagan: "PAGAN",
  Zoroastrian: "ZOROASTRIAN",
  Sikh: "SIKH",
  Other: "OTHER"
}

export const languages: Record<string, LANGUAGE_MAYDEN_ITALK> = {
  English: "ENGLISH",
  "Akan (Ashanti)": "AKAN",
  Albanian: "ALBANIAN",
  Amharic: "AMHARIC",
  Arabic: "ARABIC",
  "Bengali & Sylheti": "BENGALI",
  "Brawa & Somali": "BRAWA",
  "British Sign Language": "BRITISH_SIGN_LANGUAGE",
  Cantonese: "CANTONESE",
  Croatian: "CROATIAN",
  Dutch: "DUTCH",
  "Farsi (Persian)": "PERSIAN_FARSI",
  Finnish: "FINNISH",
  Flemish: "FLEMISH",
  French: "FRENCH",
  Gaelic: "GAELIC",
  German: "GERMAN",
  Greek: "GREEK",
  Gujarati: "GUJARATI",
  Hausa: "HAUSA",
  Hebrew: "HEBREW",
  Hindi: "HINDI",
  "Igbo (Ibo)": "IGBO",
  Italian: "ITALIAN",
  Japanese: "JAPANESE",
  Korean: "KOREAN",
  Kurdish: "KURDISH",
  Lingala: "LINGALA",
  Luganda: "LUGANDA",
  "Makaton (Sign Language)": "MAKATON_SIGN_LANGUAGE",
  Malayalam: "MALAYALAM",
  Mandarin: "MANDARIN",
  Norwegian: "NORWEGIAN",
  "Pashto (Pashtoo)": "PASHTO",
  Polish: "POLISH",
  Portuguese: "PORTUGUESE",
  Punjabi: "PUNJABI",
  Russian: "RUSSIAN",
  Serbian: "SERBIAN",
  Somali: "SOMALI",
  Sinhala: "SINHALA",
  Spanish: "SPANISH",
  Swahili: "SWAHILI",
  Swedish: "SWEDISH",
  Sylheti: "SYLHETI",
  "Tagalog (Filipino)": "TAGALOG_FILIPINO",
  Tamil: "TAMIL",
  Thai: "THAI",
  Tigrinya: "TIGRINYA",
  Turkish: "TURKISH",
  Urdu: "URDU",
  Vietnamese: "VIETNAMESE",
  Welsh: "WELSH",
  Yoruba: "YORUBA",
  Other: "NOT_LISTED"
}

export const exArmedForces: Record<string, ARMED_FORCES_MAYDEN_ITALK> = {
  "Prefer not to say": "NOT_ANSWERED",
  No: "NO",
  "Yes - ex-services": "YES_EX",
  "Dependant on ex-serving member": "DEPENDENT"
}

export const disabilities: Record<string, DISABILITY_MAYDEN_ITALK> = {
  None: "NONE",
  "Prefer not to say": "NOT_ANSWERED",
  "Autistic spectrum disorder": "AUTISM",
  "Behaviour & emotional": "BEHAVIOUR",
  Hearing: "HEARING",
  "Manual dexterity": "MANUAL",
  "Memory or ability to concentrate, learn or understand (Learning Disability)": "LEARNING",
  "Mobility & gross motor": "MOTOR",
  "Perception of physical danger": "PERCEPTION",
  "Personal, Self care & continence": "SELF_CARE",
  "Progressive conditions & physical health (eg. HIV, cancer, fits, MS etc.)": "PROGRESSIVE",
  Sight: "SIGHT",
  Speech: "SPEECH",
  Other: "NOT_LISTED"
}

export const ltcs: Record<string, LTC_MAYDEN_ITALK> = {
  Aids: "AIDS",
  Arthritis: "ARTHRITIS",
  "Auto Immune Disease": "AUTO_IMMUNE",
  Asthma: "ASTHMA",
  Cancer: "CANCER",
  "Chronic Pain": "CHRONIC_PAIN",
  "Crohns Disease": "CROHNS",
  "COPD (chronic obstructive pulmonary disease)": "COPD",
  "Cystic Fibrosis": "CYSTIC_FIBROSIS",
  Dementia: "DEMENTIA",
  "Diabetes Type 1": "DIABETES_TYPE1",
  "Diabetes Type 2": "DIABETES_TYPE2",
  Epilepsy: "EPILEPSY",
  "ME / Chronic Fatigue": "FATIGUE",
  Fibromyalgia: "FIBROMYALGIA",
  "FND (functional neurological disorder) ": "FND",
  "Gastrointestinal Disease": "GASTROINTESTINAL",
  "Gynaecological Disease": "GYNAECOLOGICAL",
  "Coronary Heart Disease / Cardiovascular Disease / Heart Attack": "HEART_DISEASE",
  "Hepatitis C": "HEPATITIS_C",
  HIV: "HIV",
  "IBS (Irritable Bowel Disease)": "IBS",
  "Kidney Disease": "KIDNEY",
  "Liver Disease": "LIVER",
  "MUS (Medically Unexplained Symptoms)": "MEDICALLY_UNEXPLAINED",
  Migraine: "MIGRAINE",
  "Multiple Sclerosis": "MS",
  "Musculoskeletal Disorder (MSK)": "MSK",
  "Neurological Disorder": "NEUROLOGICAL",
  "Parkinson's Disease": "PARKINSON",
  "Covid 19 - Long Term Effects": "POST_COVID",
  "Other Respiratory Disease (not COPD) ": "RESPIRATORY",
  "Skin Conditions, including Eczema ": "SKIN",
  "Stroke and Transient Ischaemic Attack": "STIA",
  "Thyroid Disease": "THYROID",
  "Tinnitus ": "TINNITUS",
  "Urological Disorder": "UROLOGICAL"
}

export const sameGenderAsBirth: Record<string, GenderBirthAssigned> = {
  Yes: "YES",
  No: "NO",
  "Not Stated": "NOT_ANSWERED"
}

export const nationalities: Record<string, NATIONALITY_MAYDEN> = {
  English: "ENGLAND",
  Scottish: "SCOTLAND",
  Irish: "IRELAND",
  Welsh: "WALES",
  British: "BRITAIN",
  Other: "NOT_LISTED"
}

export const maritalStatuses: Record<string, CIVIL_STATUS_MAYDEN_ITALK> = {
  Single: "SINGLE",
  Married: "MARRIED",
  Divorced: "DIVORCED",
  Widowed: "WIDOWED",
  Separated: "SEPARATED",
  "Co-Habiting": "COHABITING",
  "Long Term Relationship": "LONG_TERM",
  "Civil Partnership": "CIVIL_PARTNERSHIP",
  "Not disclosed": "NOT_ANSWERED"
}

const form = {
  form_url: "https://gateway.mayden.co.uk/referral-v2/20f88609-0d72-4fd7-a4fe-d5be2e76af91",
  filled_by: "form",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  sameGenderAsBirth: Object.keys(sameGenderAsBirth),
  ethnicities: Object.keys(ethnicities),
  religions: Object.keys(religions),
  languages: Object.keys(languages),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs),
  nationalities: Object.keys(nationalities),
  maritalStatuses: Object.keys(maritalStatuses)
}

export default form
