export async function getChatGPTSession(): Promise<string> {
  const result = await fetch("https://hackaton-jun23.nullabl.dev/session")
  return await result.text()
}

export async function getChatGPTMessage(sessionId: string, message: string): Promise<string[]> {
  // const URL = `https://hackaton-jun23.nullabl.dev/chat`
  // eslint-disable-next-line no-useless-catch
  return new Promise((res, rej) => setTimeout(() => rej(new Error("sdfkljas dflasj lk")), 5000))
}
