/* eslint-disable @typescript-eslint/no-empty-function */
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import {
  ALCOHOL_FREQUENCIES as AF,
  ALCOHOL_QUANTITIES as AQ,
  ARMED_FORCES_MAYDEN_MHM,
  DISABILITY_MAYDEN_MHM,
  ETHNICITY_MAYDEN_MHM,
  GENDER_MAYDEN_MHM,
  GenderBirthAssigned,
  LANGUAGE_MAYDEN_MHM,
  LIMBIC_IMPACT_LEVEL,
  LTC_MAYDEN_MHM,
  NATIONALITY_MAYDEN,
  PERINATAL_MAYDEN_MHM,
  PronounMaydenMHM,
  ReferralPayloadMaydenMHMNorthumberland,
  ReferralPayloadMaydenMHMSefton,
  ReferralPayloadMaydenMHMWarrington,
  RELIGION_MAYDEN_MHM,
  SEXUALITY_MAYDEN_MHM
} from "@limbic/types"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import moment from "moment/moment"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IAPTIDs } from "../../../models/IIAPTService"
import { step } from "../../../backend/chatbot/decorators/step"
import { fullNameRegex } from "../../../utils/fullNameRegex"
import invariant from "../../../utils/invariant"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  languages,
  ltcs,
  religions,
  sameGenderAsBirth,
  sexualities,
  nationalities,
  perinatalStatuses
} from "../../../config/referralForms/mental-health-matters"
import { toLowerCaseProperties } from "../../../utils/object"
import { ALCOHOL_FREQUENCIES, ALCOHOL_QUANTITIES, MHMTitles } from "../../../models/Constants"
import { joinWithAnd, joinWithOr } from "../../../utils/array"
import { z } from "zod"
import {
  IInlinePickerMultiSelectPrompt,
  IInlinePickerSingleSelectPrompt
} from "../../../backend/chatbot/models/IPrompt"

const educationChoices = ["No", "Yes - School", "Yes - College", "Yes - University"]
export enum ContactOptions {
  "Telephone" = "PHONE",
  "E-mail" = "EMAIL",
  "Letter" = "POST",
  "Text message" = "SMS"
}

interface State extends SelfReferralIAPTScriptState {
  preferredPhoneType?: "Mobile" | "Work" | "Home"
  emailPermission?: boolean
  preferredContactMethod?: ContactOptions
  disabilities?: string[]
  accommodationStatus?: string
  agreeProvideDetailsOfNextOfKin?: boolean
  nameOfNextOfKin?: string
  phoneNumberOfNextOfKin?: string
  relationshipOfNextOfKin?: string
  education?: string
  educationOrWorkImpact?: boolean
  employmentAdvisor?: boolean
  nationality?: string
  primaryLanguage?: string
  requiresInterpreter?: boolean
  interpreterLanguage?: string
  requiresSignInterpreter?: boolean
  accessibilityConsiderations?: string
  ltcOtherDetails?: string
  otherSubstances?: boolean
  substancesForMood?: boolean
  substancesOverTheCounter?: boolean
  takingPrescribedMedication?: boolean
  prescribedMedications?: string
  mainIssue?: string
  mainGoal?: string
  nhsStaff?: boolean
  currentTherapy?: boolean
  currentTherapyDetails?: string
  previousTherapy?: boolean
  previousTherapyDetails?: string
  preferredTitle?: MHMTitles
  preferredPronouns?: PronounMaydenMHM
  gpConsent?: boolean
  relationshipStatus?: string
}

export type SelfReferralMHMState = State

export class SelfReferralMHMScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralMHMScript"

  /** Script Steps */

  @step.logState
  askPermissionToSendEmail(d: IStepData<State>): IStepResult {
    const body =
      d.state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON
        ? "Do we have permission to send you an email (incl. webforms) to that address?"
        : "Do we have permission to send you an email to that address?"
    return {
      body,
      prompt: {
        id: this.getPromptId("askPermissionToSendEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPermissionToSendEmail"
      },
      nextStep: this.handlePermissionToSendEmail
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.emailPermission = d.response
    script.referralStore.setCustomField<State>("emailPermission", d.response)
  })
  handlePermissionToSendEmail(_d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: this.askPreferredContactMethod
    }
  }

  @step.logState
  askPreferredContactMethod(d: IStepData<State>): IStepResult {
    let options = Object.keys(ContactOptions)
    if (!d.state.emailPermission) {
      options = options.filter(i => i !== "E-mail")
    }
    return {
      body: "And what is your preferred method for correspondence?",
      prompt: {
        id: this.getPromptId("askPreferredContactMethod"),
        trackResponse: true,
        type: "inlinePicker",
        choices: options.map(o => ({ body: o, value: ContactOptions[o] })),
        dataPointsName: "askPreferredContactMethod"
      },
      nextStep: this.handlePreferredContactMethod
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, ContactOptions>, script: SelfReferralMHMScript) => {
    d.state.preferredContactMethod = d.response
    script.referralStore.setCustomField<State>("preferredContactMethod", d.response)
  })
  handlePreferredContactMethod(_d: IStepData<State, ContactOptions>): IStepResult {
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  @step
  returnToAskAddress(_d: IStepData<State>): IStepResult {
    return {
      nextStep: this.askAddress
    }
  }

  @step.logState
  askAccommodationStatus(_d: IStepData<State>): IStepResult {
    return {
      body: "What is your accommodation status?",
      prompt: {
        id: this.getPromptId("askAccommodationStatus"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Owner Occupier", value: "OWNER" },
          {
            body: "Tenant - Local Authority/Housing Association/Registered Landlord",
            value: "TENANT_NON_PRIVATE"
          },
          { body: "Tenant - private landlord", value: "TENANT_PRIVATE" },
          { body: "Nursing home for older persons", value: "NURSING_HOME" },
          {
            body: "Staying with friends/family as a short term guest",
            value: "AT_FRIENDS"
          },
          { body: "Other", value: "OTHER" },
          { body: "Homeless", value: "HOMELESS" }
        ],
        dataPointsName: "askAccommodationStatus"
      },
      nextStep: this.handleAccommodationStatus
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.accommodationStatus = d.response
    script.referralStore.setCustomField<State>("accommodationStatus", d.response)
  })
  handleAccommodationStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPermissionToSendMailToAddress }
  }

  @step.logState
  askProvideDetailsNextOfKin(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Are you able to provide details for someone to contact in case of an emergency only?",
        "You do not have to give us this information if you don't wish to and this will not affect your treatment with us"
      ],
      prompt: {
        id: this.getPromptId("askProvideDetailsNextOfKin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askProvideDetailsNextOfKin"
      },
      nextStep: this.handleProvideDetailsNextOfKin
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.agreeProvideDetailsOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("agreeProvideDetailsOfNextOfKin", d.response)
  })
  handleProvideDetailsNextOfKin(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return {
        nextStep: this.askNameOfNextOfKin
      }
    }
    return {
      nextStep: this.askGender
    }
  }

  @step.logState
  askNameOfNextOfKin(_d: IStepData<State>): IStepResult {
    return {
      body: "What's their full name?",
      prompt: {
        id: this.getPromptId("askNameOfNextOfKin"),
        type: "text",
        validation: [new RegExp(fullNameRegex)],
        validationExplainer: ["Please enter their full name"],
        placeholder: "Please type their name",
        forceValue: true
      },
      nextStep: this.handleNameOfNextOfKinWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.nameOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("nameOfNextOfKin", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMHMScript) => s.returnToAskNameOfNextOfKin
  })
  handleNameOfNextOfKinWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askRelationshipOfNextOfKin
    }
  }

  @step
  returnToAskNameOfNextOfKin(_d: IStepData<State>): IStepResult {
    return {
      body: "So I was going to ask you about your contact",
      nextStep: this.askNameOfNextOfKin
    }
  }

  @step.logState
  askRelationshipOfNextOfKin(d: IStepData<State>): IStepResult {
    const nameOfNextOfKin = d.state.nameOfNextOfKin
    return {
      body: `What's ${nameOfNextOfKin}'s relationship to you?`,
      prompt: {
        id: this.getPromptId("askRelationshipOfNextOfKin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Parent", value: "Parent" },
          { body: "Sibling", value: "Sibling" },
          { body: "Child", value: "Child" },
          { body: "Friend", value: "Friend" }
        ],
        textPrompt: {
          forceValue: true,
          placeholder: "Other - please specify"
        },
        dataPointsName: "askRelationshipOfNextOfKin"
      },
      nextStep: this.handleRelationshipOfNextOfKinWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.relationshipOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("relationshipOfNextOfKin", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMHMScript) => s.returnToAskRelationshipOfNextOfKin
  })
  handleRelationshipOfNextOfKinWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askPhoneNumberOfNextOfKin
    }
  }

  @step
  returnToAskRelationshipOfNextOfKin(_d: IStepData<State>): IStepResult {
    return {
      body: "So I was going to ask you about your contact",
      nextStep: this.askRelationshipOfNextOfKin
    }
  }

  @step.logState
  askPhoneNumberOfNextOfKin(d: IStepData<State>): IStepResult {
    const nameOfNextOfKin = d.state.nameOfNextOfKin
    return {
      body: `What's ${nameOfNextOfKin}'s phone number?`,
      prompt: {
        id: this.getPromptId("askPhoneNumberOfNextOfKin"),
        trackResponse: true,
        type: "phoneNumber",
        placeholder: "Type their phone number"
      },
      nextStep: this.handlePhoneNumberOfNextOfKin
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.phoneNumberOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("phoneNumberOfNextOfKin", d.response)
  })
  handlePhoneNumberOfNextOfKin(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askGender
    }
  }

  @step.logState
  askGender(d: IStepData<State>): IStepResult {
    const genders = this.getGenders(d.state)
    if (!genders?.length) {
      this.logBreadcrumb("GENDERS NOT FOUND", d.state, { genders })
      this.logMessage("GENDERS NOT FOUND")
      return { nextStep: this.askEthnicity }
    }

    return {
      body: [
        "Thank you. We now need to ask you a bit more information about yourself. We ask this in order to ensure we are equally supporting all members of our community",
        "Which gender do you identify as?"
      ],
      prompt: {
        id: this.getPromptId("askGender"),
        trackResponse: true,
        type: "inlinePicker",
        choices: genders.map(g => ({ body: g, value: g })),
        dataPointsName: "askGender"
      },
      nextStep: this.handleGender
    }
  }

  @step.logState
  async askSameGenderAsBirth(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const genderSameAsBirth = this.getGenderSameAsBirthValues(d.state)
    if (!genderSameAsBirth?.length) {
      this.logBreadcrumb("SAME GENDER AS BIRTH VALUES NOT FOUND", d.state, { genderSameAsBirth })
      this.logMessage("SAME GENDER AS BIRTH VALUES NOT FOUND")
      return { nextStep: this.askReligion }
    }

    return {
      body: `Thanks ${name}, and is your gender the same as the gender you were assigned at birth?`,
      prompt: {
        id: this.getPromptId("askSameGenderAsBirth"),
        trackResponse: true,
        type: "inlinePicker",
        choices: genderSameAsBirth.map(g => ({ body: g, value: g })),
        dataPointsName: "askSameGenderAsBirth"
      },
      nextStep: this.handleSameGenderAsBirth
    }
  }

  @step.logState
  askRelationshipStatus(d: IStepData<State>): IStepResult {
    // Not part of the form - will be setting them in the notes for now
    const relationshipStatuses = [
      { body: "Single", value: "Single" },
      { body: "Married", value: "Married" },
      { body: "Divorced", value: "Divorced" },
      { body: "Widowed", value: "Widowed" },
      { body: "Separated", value: "Separated" },
      { body: "Co-Habiting", value: "Co-Habiting" },
      { body: "Long Term", value: "Long Term" },
      { body: "Civil Partnership", value: "Civil Partnership" },
      { body: "Prefer Not To Say", value: "Not discolsed" }
    ]

    return {
      body: "What is your relationship status?",
      prompt: {
        id: this.getPromptId("askRelationshipStatus"),
        type: "inlinePicker",
        choices: relationshipStatuses,
        isUndoAble: true,
        dataPointsName: "askRelationshipStatus",
        trackResponse: true
      },
      nextStep: this.handleRelationshipStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.relationshipStatus = d.response
    script.referralStore.setCustomField<State>("relationshipStatus", d.response)
  })
  handleRelationshipStatus(d: IStepData<State, string>): IStepResult {
    this.referralStore.addClinicalNote(`Relationship Status: ${d.state.relationshipStatus}`)
    return { nextStep: this.askEducation }
  }

  @step.logState
  askEducation(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently in education?",
      prompt: {
        id: this.getPromptId("askEducation"),
        trackResponse: true,
        type: "inlinePicker",
        choices: educationChoices.map(edu => ({ body: edu, value: edu })),
        dataPointsName: "askEducation"
      },
      nextStep: this.handleEducation
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.education = d.response
    script.referralStore.setCustomField<State>("education", d.response)
  })
  handleEducation(d: IStepData<State, string>): IStepResult {
    if (d.state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND || d.response === "No") {
      return {
        nextStep: this.askReligion
      }
    }

    return {
      nextStep: this.askEducationOrWorkImpact
    }
  }

  @step.logState
  askEducationOrWorkImpact(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you feel that your work/education or search for work, is impacting on, or being impacted by your mental health?",
      prompt: {
        id: this.getPromptId("askEducationOrWorkImpact"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askEducationOrWorkImpact"
      },
      nextStep: this.handleEducationOrWorkImpact
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.educationOrWorkImpact = d.response
    script.referralStore.setCustomField<State>("educationOrWorkImpact", d.response)
  })
  handleEducationOrWorkImpact(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return {
        nextStep: this.askReferToEmploymentAdvisor
      }
    }

    return {
      nextStep: this.askReligion
    }
  }

  @step.logState
  askReferToEmploymentAdvisor(_d: IStepData<State>): IStepResult {
    return {
      body: "Would you like to be referred to one of our Employment Advisors who can help you with this?",
      prompt: {
        id: this.getPromptId("askReferToEmploymentAdvisor"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askReferToEmploymentAdvisor"
      },
      nextStep: this.handleReferToEmploymentAdvisor
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.employmentAdvisor = d.response
    script.referralStore.setCustomField<State>("employmentAdvisor", d.response)
  })
  handleReferToEmploymentAdvisor(_d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: this.askReligion
    }
  }

  @step.logState
  askReligion(d: IStepData<State>): IStepResult {
    const religions = this.getReligions(d.state)
    if (!religions?.length) {
      this.logBreadcrumb("RELIGIONS NOT FOUND", d.state, { religions })
      this.logMessage("RELIGIONS NOT FOUND")
      return { nextStep: this.askNationality }
    }
    return {
      body: "What's your religious group?",
      prompt: {
        id: this.getPromptId("askReligion"),
        trackResponse: true,
        type: "inlinePicker",
        choices: religions.map(e => ({ body: this.getChoiceBody(e), value: e })),
        dataPointsName: "askReligion"
      },
      nextStep: this.handleReligion
    }
  }

  @step.logStateAndResponse
  async handleReligion(d: IStepData<State, string>): Promise<IStepResult> {
    d.state.religion = d.response
    this.setPeople({ religion: d.response })
    return { nextStep: this.askNationality }
  }

  @step.logState
  async askNationality(d: IStepData<State>): Promise<IStepResult> {
    const nationalities = this.getNationalities(d.state)
    if (!nationalities?.length) {
      this.logBreadcrumb("NATIONALITIES NOT FOUND", d.state, { nationalities })
      this.logMessage("NATIONALITIES NOT FOUND")
      return { nextStep: this.askPrimaryLanguage }
    }

    return {
      body: "What's your nationality?",
      prompt: {
        id: this.getPromptId("askNationality"),
        trackResponse: true,
        type: "inlinePicker",
        choices: nationalities.map(g => ({ body: g, value: g }))
      },
      nextStep: this.handleNationality
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.nationality = d.response
    script.referralStore.setCustomField<State>("nationality", d.response)
  })
  async handleNationality(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.askPrimaryLanguage }
  }

  @step.logState
  async askPrimaryLanguage(d: IStepData<State>): Promise<IStepResult> {
    const languages = this.getLanguages(d.state)
    if (!languages?.length) {
      this.logBreadcrumb("LANGUAGES NOT FOUND", d.state, { languages })
      this.logMessage("LANGUAGES NOT FOUND")
      return { nextStep: this.askDisabilityStatus }
    }

    return {
      body: "What is your main/preferred spoken language?",
      prompt: {
        id: this.getPromptId("askPrimaryLanguage"),
        type: "inlinePicker",
        choices: languages.map(g => ({ body: g, value: g })),
        dataPointsName: "askPrimaryLanguage"
      },
      nextStep: this.handleLanguage
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.primaryLanguage = d.response
    script.referralStore.setCustomField<State>("primaryLanguage", d.response)
  })
  handleLanguage(d: IStepData<State, string>): IStepResult {
    if (d.state.primaryLanguage !== "English") {
      return { nextStep: this.askRequireInterpreter }
    }
    return { nextStep: this.askRequiresSignInterpreter }
  }

  @step.logState
  askRequireInterpreter(d: IStepData<State>): IStepResult {
    if (d.state.spineInterpreterRequired !== undefined) {
      return { nextStep: this.askRequiresSignInterpreter }
    }
    return {
      body: "Do you require an interpreter?",
      prompt: {
        id: this.getPromptId("askRequireInterpreter"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequireInterpreter
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.requiresInterpreter = d.response
    script.referralStore.setCustomField<State>("requiresInterpreter", d.response)
  })
  handleRequireInterpreter(d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: d.state.requiresInterpreter //
        ? this.askInterpreterLanguage
        : this.askRequiresSignInterpreter
    }
  }

  @step.logState
  askInterpreterLanguage(d: IStepData<State>): IStepResult {
    const primaryLanguage = d.state.primaryLanguage
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, you stated your main spoken language is ${primaryLanguage}.`,
        "Is this the language you need an interpreter for?",
        "(If not, just type the language you'd like an interpreter for)"
      ],
      prompt: {
        id: this.getPromptId("askInterpreterLanguage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: `Yes, I need an interpreter for ${primaryLanguage}`, value: primaryLanguage }
        ],
        textPrompt: {
          forceValue: true,
          placeholder: "Enter language"
        },
        dataPointsName: "askInterpreterLanguage"
      },
      nextStep: this.handleInterpreterLanguage
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.interpreterLanguage = d.response
    script.referralStore.setCustomField<State>("interpreterLanguage", d.state.interpreterLanguage)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralMHMScript) => s.askRequiresSignInterpreter
  })
  handleInterpreterLanguage(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askRequiresSignInterpreter }
  }

  @step.logState
  askRequiresSignInterpreter(d: IStepData<State>): IStepResult {
    if (d.state.spineInterpreterRequired !== undefined) {
      return { nextStep: this.askDisabilityStatus }
    }
    return {
      body: "Do you need a sign language interpreter?",
      prompt: {
        id: this.getPromptId("askRequiresSignInterpreter"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequiresSignInterpreter
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.requiresSignInterpreter = d.response
    script.referralStore.setCustomField<State>("requiresSignInterpreter", d.response)
  })
  handleRequiresSignInterpreter(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askDisabilityStatus }
  }

  getDisabilityPrompt(
    state: State
  ): IInlinePickerSingleSelectPrompt | IInlinePickerMultiSelectPrompt | undefined {
    const type =
      state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND
        ? "inlinePicker"
        : "inlinePickerMultiSelect"

    const disabilities = this.getDisabilities(state)
    if (disabilities.length) {
      return {
        id: this.getPromptId("askDisability"),
        trackResponse: true,
        type,
        choices: [
          {
            body: "No disability",
            value: "NONE",
            backgroundColor: "#8C96D4FF",
            selectIndividually: true
          },
          ...disabilities.map(g => ({ body: g, value: g }))
        ],
        dataPointsName: "askDisability"
      }
    }
  }

  @step.logState
  askHasASD(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have a confirmed diagnosis of Autistic Spectrum Disorder (ASD)?",
      prompt: {
        id: this.getPromptId("askHasASD"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askHasASD"
      },
      nextStep: this.handleHasASD
    }
  }

  @step.logStateAndResponse
  async handleHasASD(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.hasASD = d.response
    this.referralStore.setCustomField<State>("hasASD", d.state.hasASD)
    return { nextStep: this.askHasADHD }
  }

  @step.logState
  askHasADHD(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have a confirmed diagnosis of Attention Deficit Hyperactivity Disorder (ADHD)?",
      prompt: {
        id: this.getPromptId("askHasADHD"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askHasADHD"
      },
      nextStep: this.handleHasADHD
    }
  }

  @step.logStateAndResponse
  async handleHasADHD(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.hasADHD = d.response
    this.referralStore.setCustomField<State>("hasADHD", d.state.hasADHD)
    return { nextStep: this.askPerinatal }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.askDisabilityStatus }
    }
    return {
      body: "Are you a parent with caring responsibilities for a child or children under 2, or are you or your partner currently pregnant?",
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: g, value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logState
  askAccessibilityConsiderations(_d: IStepData<State>): IStepResult {
    return {
      body: "Is there any other information that would be useful for us to know to help you access the service (e.g. are you hard of hearing, do you struggle with stairs)?",
      prompt: {
        id: this.getPromptId("askAccessibilityConsiderations"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "No impairment", value: "No impairment" },
          { body: "Visual impairment", value: "Visual impairment" },
          { body: "Hearing impairment", value: "Hearing impairment" }
        ],
        textPrompt: {
          forceValue: true,
          placeholder: "Other - please specify"
        },
        dataPointsName: "askAccessibilityConsiderations"
      },
      nextStep: this.handleAccessibilityConsiderationsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.accessibilityConsiderations = d.response
    script.referralStore.setCustomField<State>("accessibilityConsiderations", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMHMScript) => s.askLongTermMedicalCondition
  })
  handleAccessibilityConsiderationsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askLongTermMedicalCondition
    }
  }

  @step.logState
  askLongTermMedicalCondition(d: IStepData<State>): IStepResult {
    const medicalConditions = this.getMedicalConditions(d.state)
    if (!medicalConditions?.length) {
      this.logBreadcrumb("MEDICAL CONDITIONS NOT FOUND", d.state, { medicalConditions })
      this.logMessage("MEDICAL CONDITIONS NOT FOUND")
      return { nextStep: this.goToCollectAlcoholConsumption }
    }
    return {
      body: "Do you have a long term medical condition?",
      prompt: {
        id: this.getPromptId("askLongTermMedicalCondition"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          { body: "I don't", value: "No", backgroundColor: "#EC9CC8", selectIndividually: true },
          ...medicalConditions.filter(m => m !== "No").map(m => ({ body: m, value: m }))
        ],
        dataPointsName: "askLongTermMedicalCondition"
      },
      nextStep: this.handleLongTermMedicalCondition
    }
  }

  @step.logStateAndResponse
  async handleLongTermMedicalCondition(d: IStepData<State, string[]>): Promise<IStepResult> {
    d.state.longTermMedicalCondition = d.response
    this.setPeople({ ltc: d.response })

    if (d.state.longTermMedicalCondition.includes("Other")) {
      return {
        nextStep: this.askLongTermMedicalConditionOtherDetails
      }
    }
    if (d.state.longTermMedicalCondition?.includes("No")) {
      return { nextStep: this.goToCollectAlcoholConsumption }
    }
    return { nextStep: this.askDoesLTCAffectMood }
  }

  @step.logState
  askLongTermMedicalConditionOtherDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please specify",
      prompt: {
        id: this.getPromptId("askLongTermMedicalConditionOtherDetails"),
        trackResponse: true,
        type: "text",
        forceValue: true,
        isUndoAble: true,
        dataPointsName: "askLongTermMedicalConditionOtherDetails"
      },
      nextStep: this.handleLongTermMedicalConditionOtherDetailsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.ltcOtherDetails = d.response
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralMHMScript) => s.askDoesLTCAffectMood
  })
  handleLongTermMedicalConditionOtherDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askDoesLTCAffectMood }
  }

  @step
  askDoesLTCAffectMood(d: IStepData<State>): IStepResult {
    const condition = this.getCustomConditionOr(d.state)
    return {
      body: `Does your ${condition} impact your mood?`,
      prompt: {
        id: this.getPromptId("askDoesLTCAffectMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askDoesLTCAffectMood"
      },
      nextStep: this.handleDoesLTCAffectMood
    }
  }

  @step
  askHowWellYouManageYourLTC(d: IStepData<State>): IStepResult {
    const condition = this.getCustomConditionAnd(d.state)
    return {
      body: `And how well do you think you manage your ${condition}?`,
      prompt: {
        id: this.getPromptId("askHowWellYouManageYourLTC"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Not very well", value: "little" },
          { body: "Fairly well", value: "fairly" },
          { body: "Very well", value: "very" }
        ],
        dataPointsName: "askHowWellYouManageYourLTC"
      },
      nextStep: this.handleHowWellYouManageYourLTC
    }
  }

  @step.logState
  askSubstances(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently using any recreational drugs?",
      prompt: {
        id: this.getPromptId("askSubstances"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askSubstances"
      },
      nextStep: this.handleSubstances
    }
  }

  @step.logStateAndResponse
  async handleSubstances(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substances = d.response //recreational
    this.setPeople({ substances: d.response })

    return {
      nextStep: d.response //
        ? this.askSubstancesForMood
        : this.otherSubstances
    }
  }

  @step.logState
  askSubstancesForMood(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you using these recreational drugs to manage your mood?",
      prompt: {
        id: this.getPromptId("askSubstancesForMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askSubstancesForMood"
      },
      nextStep: this.handleSubstancesForMood
    }
  }

  @step.logStateAndResponse
  async handleSubstancesForMood(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substancesForMood = d.response //recreational
    this.setPeople({ substancesForMood: d.response })

    return {
      nextStep: this.otherSubstances
    }
  }

  @step.logState
  otherSubstances(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you taken any other non-prescribed substances to manage your mood?",
      prompt: {
        id: this.getPromptId("otherSubstances"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "otherSubstances"
      },
      nextStep: this.handleOtherSubstances
    }
  }

  @step.logStateAndResponse
  async handleOtherSubstances(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.otherSubstances = d.response // substancesOther - non-prescribed substances
    this.setPeople({ otherSubstances: d.response })

    return {
      nextStep: d.response //
        ? this.otherSubstancesOrigin
        : this.askPrescribedMedication
    }
  }

  @step.logState
  otherSubstancesOrigin(_d: IStepData<State>): IStepResult {
    return {
      body: "Are these medications bought over the counter at a pharmacy or supermarket?",
      prompt: {
        id: this.getPromptId("otherSubstancesOrigin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "otherSubstancesOrigin"
      },
      nextStep: this.handleOtherSubstancesOrigin
    }
  }

  @step.logStateAndResponse
  async handleOtherSubstancesOrigin(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.substancesOverTheCounter = d.response // substancesAreMedication - over the counter
    this.setPeople({ substancesOverTheCounter: d.response })

    return {
      nextStep: this.askPrescribedMedication
    }
  }

  @step.logState
  askPrescribedMedication(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently taking any medication that has been prescribed by a doctor?",
      prompt: {
        id: this.getPromptId("prescribedMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "prescribedMedication"
      },
      nextStep: this.handlePrescribedMedication
    }
  }

  @step.logStateAndResponse
  async handlePrescribedMedication(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.takingPrescribedMedication = d.response
    this.setPeople({ takingPrescribedMedication: d.response })

    return {
      nextStep: d.response //
        ? this.askWhichMedication
        : this.askGPConsent
    }
  }

  @step.logState
  askWhichMedication(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you let me know the details of the medication you've been prescribed?",
      prompt: {
        id: this.getPromptId("askWhichMedication"),
        type: "text",
        forceValue: true,
        dataPointsName: "askWhichMedication"
      },
      nextStep: this.handleWhichMedicationWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.prescribedMedications = d.response // medication - prescribed medication
    script.referralStore.setCustomField<State>("prescribedMedications", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMHMScript) => s.askGPConsent
  })
  handleWhichMedicationWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askGPConsent
    }
  }

  @step.logState
  askGPConsent(_d: IStepData<State>): IStepResult {
    return {
      body: "Do we have permission to contact your GP about your referral?",
      prompt: {
        id: this.getPromptId("askGPConsent"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleGPConsent
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralMHMScript) => {
    d.state.gpConsent = d.response
    script.referralStore.setCustomField<State>("gpConsent", d.response)
  })
  handleGPConsent(d: IStepData<State, boolean>): IStepResult {
    this.referralStore.addClinicalNote(`GP Consent: ${d.state.gpConsent ? "Yes" : "No"}`)
    return { nextStep: this.askMainIssue }
  }

  @step.logState
  askMainIssue(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Thanks. You're doing really well",
        "Can you describe the problem you are seeking our help with and how long it has been troubling you?",
        "(Please give as much detail as you feel able to give)"
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMHMScript) => s.askMainGoal
  })
  handleMainIssueWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askMainGoal
    }
  }

  @step.logState
  askMainGoal(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Thank you for sharing ${name}, I know that may have been difficult`,
        "What would you like to change about your situation, or feel differently about?",
        "What would you like to achieve as your goal in having therapy?"
      ],
      prompt: {
        id: this.getPromptId("askMainGoal"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainGoal"
      },
      nextStep: this.handleMainGoalWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralMHMScript) => {
    d.state.mainGoal = d.response
    script.referralStore.setCustomField<State>("mainGoal", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralMHMScript) =>
      s.rootStore.botStore?.bot?.activeDialogue?.state.iapt?.id ===
      IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON
        ? s.doReferralSubmission
        : s.askWhereDidYouHearAboutUs
  })
  handleMainGoalWithCrisis(d: IStepData<State, string>): IStepResult {
    if (d.state.iapt?.id === IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON) {
      return {
        nextStep: this.doReferralSubmission
      }
    }
    return {
      nextStep: this.askWhereDidYouHearAboutUs
    }
  }

  @step.logState
  askWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    return {
      body: "Before we finish this part, how did you hear about the service?",
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleWhereDidYouHearAboutUsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, _script: SelfReferralMHMScript) => {
    d.state.whereDidYouHearAboutService = d.response
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralMHMScript) => s.doReferralSubmission
  })
  handleWhereDidYouHearAboutUsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.doReferralSubmission }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [
        "And that's everything",
        `You've officially been referred to ${iaptName}`,
        "Congratulations on taking this important step towards better mental health!"
      ],
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: "What happens next?" }]
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleDoYouHaveAnEmail(state: State): Promise<IStepResult<State>> {
    if (state.userHasEmail) {
      return {
        nextStep: this.askEmail
      }
    }
    return {
      nextStep: this.askPreferredContactMethod
    }
  }

  async onCheckFullAddress(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askAccommodationStatus
    }
  }

  async onHandleEmail(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askPermissionToSendEmail
    }
  }

  async onHandleSelectAddressFromPostCode(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askAccommodationStatus
    }
  }

  async onHandlePermissionToSendMailToAddress(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askProvideDetailsNextOfKin
    }
  }

  async onHandleGender(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askSameGenderAsBirth
    }
  }

  async onHandleGenderSameAsBirth(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askEthnicity
    }
  }

  async onHandleEthnicity(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askSexuality
    }
  }

  async onHandleSexuality(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askRelationshipStatus
    }
  }

  async onHandleReligion(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askNationality
    }
  }

  async onHandleDisabilityStatus(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: state.disabilityStatus ? this.askDisability : this.askHasASD
    }
  }

  async onHandleDisability(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askHasASD
    }
  }

  async onHandlePerinatal(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askAccessibilityConsiderations
    }
  }

  async onHandleLongTermMedicalCondition(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: !state.longTermMedicalCondition?.includes("No")
        ? this.askDoesLTCAffectMood
        : this.goToCollectAlcoholConsumption
    }
  }

  async onHandleDoesLTCAffectMood(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: state.ltcAffectsMood
        ? this.askHowMuchLTCAffectsMood
        : this.goToCollectAlcoholConsumption
    }
  }

  async onHandleHowMuchLTCAffectsMood(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: state.ltcMoodImpact
        ? this.askHowWellYouManageYourLTC
        : this.goToCollectAlcoholConsumption
    }
  }

  async onHandleHowWellYouManageYourLTC(state: State): Promise<IStepResult<State>> {
    const name = this.getName(state)
    return {
      body: [
        `Thank you for sharing, I know that may have been difficult. You're making good progress ${name}`,
        `Let's continue with the referral`
      ],
      nextStep: this.goToCollectAlcoholConsumption
    }
  }

  getNationalities(state: State): string[] {
    return state.iapt?.referralForm?.nationalities ?? []
  }

  getReligion(state: State): RELIGION_MAYDEN_MHM {
    return religions[state.religion!] ?? "NOT_ANSWERED"
  }

  getPerinatal(state: State): PERINATAL_MAYDEN_MHM {
    return perinatalStatuses[state.perinatalStatus!] ?? "NONE"
  }

  getDisability(state: State): DISABILITY_MAYDEN_MHM[] {
    if (state.disabilityStatus === false) return ["NONE"]
    if (!state.disabilityStatus || !state.disabilities?.length) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }
    return state.disabilities
      ? state.disabilities?.map(disability => disabilities[disability]).filter(Boolean)
      : ["NONE"]
  }

  getArmedForce(state: State): ARMED_FORCES_MAYDEN_MHM {
    return exArmedForces[state.isExArmedForces!] ?? "NOT_ANSWERED"
  }

  getNationality(state: State): NATIONALITY_MAYDEN {
    return nationalities[state.nationality!] || "NOT_LISTED"
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_MHM {
    return ethnicities[state.ethnicity!] ?? "NOT_ANSWERED"
  }

  getGender(state: State): GENDER_MAYDEN_MHM {
    return genders[state.gender!] ?? "NOT_ANSWERED"
  }

  getGenderSameAsBirthAssigned(state: State): GenderBirthAssigned {
    const genderSameAsBirthAssigned = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirthAssigned ?? "NOT_ANSWERED"
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_MHM | undefined {
    // NOT_LISTED when the response if a free text (custom input)
    return sexualities[state.sexuality!] || "NOT_ANSWERED"
  }

  getLanguage(state: State): LANGUAGE_MAYDEN_MHM | undefined {
    const spineLanguageKey = `${state.spineLanguage ?? ""}`.toLowerCase()
    const primaryLanguageKey = `${state.primaryLanguage ?? ""}`.toLowerCase()
    const languagesLowerCase = toLowerCaseProperties(languages)
    return languagesLowerCase[spineLanguageKey] ?? languagesLowerCase[primaryLanguageKey]
  }

  getLTC(state: State): LTC_MAYDEN_MHM[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getAlcoholFrequency(state: State): typeof AF[keyof typeof AF] | undefined {
    const map = {
      [ALCOHOL_FREQUENCIES.MONTHLY]: AF.MONTHLY,
      [ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4]: AF.MONTHLY_2_TO_4,
      [ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3]: AF.WEEKLY_2_TO_3,
      [ALCOHOL_FREQUENCIES.WEEKLY_4]: AF.WEEKLY_4
    }
    return map[state.alcoholFrequency!]
  }

  getAlcoholQuantity(state: State): typeof AQ[keyof typeof AQ] | undefined {
    const map = {
      [ALCOHOL_QUANTITIES._0_2]: AQ._0_2,
      [ALCOHOL_QUANTITIES._3_4]: AQ._3_4,
      [ALCOHOL_QUANTITIES._5_6]: AQ._5_6,
      [ALCOHOL_QUANTITIES._7_9]: AQ._7_9,
      [ALCOHOL_QUANTITIES._10_PLUS]: AQ._10_PLUS
    }
    return map[state.alcoholQuantity!]
  }

  getLanguages(state: State): string[] {
    return state.iapt?.referralForm?.languages ?? []
  }

  getCustomConditionAnd(state: State): string {
    const hasLTCOtherDetailsPlusOneLTC =
      state.longTermMedicalCondition!.filter(c => c !== "Other").length === 1 &&
      state.ltcOtherDetails
    if (hasLTCOtherDetailsPlusOneLTC) {
      return `${state.ltcOtherDetails} and ${joinWithAnd(
        state.longTermMedicalCondition?.filter(c => c !== "Other")
      )}`
    }
    if (state.ltcOtherDetails && state.longTermMedicalCondition?.length === 1) {
      return state.ltcOtherDetails
    }
    return state.ltcOtherDetails
      ? `${state.ltcOtherDetails}, ${joinWithAnd(
          state.longTermMedicalCondition?.filter(c => c !== "Other")
        )}`
      : joinWithAnd(state.longTermMedicalCondition)
  }

  getCustomConditionOr(state: State): string {
    const hasLTCOtherDetailsPlusOneLTC =
      state.longTermMedicalCondition!.filter(c => c !== "Other").length === 1 &&
      state.ltcOtherDetails
    if (hasLTCOtherDetailsPlusOneLTC) {
      return `${state.ltcOtherDetails} or ${joinWithOr(
        state.longTermMedicalCondition?.filter(c => c !== "Other")
      )}`
    }
    if (state.ltcOtherDetails && state.longTermMedicalCondition?.length === 1) {
      return state.ltcOtherDetails
    }
    return state.ltcOtherDetails
      ? `${state.ltcOtherDetails}, ${joinWithOr(
          state.longTermMedicalCondition?.filter(c => c !== "Other")
        )}`
      : joinWithOr(state.longTermMedicalCondition)
  }

  async getReferralPayload(
    state: State
  ): Promise<
    | ReferralPayloadMaydenMHMNorthumberland
    | ReferralPayloadMaydenMHMWarrington
    | ReferralPayloadMaydenMHMSefton
  > {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    // TODO should be moved somewhere else but setting ADSM to active
    // here for now, until insight is done with the consent and randomisation
    this.clinicalStore.setADSMActive(true)

    const basePayload = {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: state.preferredName,
      title: state.preferredTitle,
      pronounsPreferredMHM: state.preferredPronouns,
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      gpName: state.odsGP?.name ?? state.gp?.name,
      gpPractice: state.odsGP?.name ?? state.gp?.name,
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      alcohol: state.alcohol,
      alcoholFrequency: this.getAlcoholFrequency(state),
      alcoholQuantity: this.getAlcoholQuantity(state),
      substances: state.substances,
      substancesForMood: state.substancesForMood,
      substancesOther: state.otherSubstances,
      substancesAreMedication: state.substancesOverTheCounter,
      medication: state?.prescribedMedications ? [state.prescribedMedications] : [],
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      ltcOtherDetails: state?.ltcOtherDetails,
      disability: this.getDisability(state),
      language: this.getLanguage(state),
      interpreterSignLanguage: state.requiresSignInterpreter,
      interpreter:
        state.spineInterpreterRequired ??
        !!this.referralStore.getCustomField<State>("requiresInterpreter"),
      sexuality: this.getSexuality(state),
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirthAssigned(state),
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForce(state),
      nationality: this.getNationality(state),
      religion: this.getReligion(state),
      problemInOwnWords: state?.mainIssue,
      treatmentExpectation: state?.mainGoal,
      perinatal: this.getPerinatal(state),
      questionnaires: this.getQuestionnairesPayload(state),
      consentResearch: state.consentResearch,
      currentSupport: state?.currentTherapy,
      currentSupportDetailsOther: state?.currentTherapyDetails,
      pastTreatment: state?.previousTherapy,
      pastTreatmentDetails: state?.previousTherapyDetails,
      keyWorker: state?.nhsStaff,
      correspondencePreferred: state?.preferredContactMethod,
      emergencyContactName: state?.nameOfNextOfKin,
      emergencyContactRelationship: state?.relationshipOfNextOfKin,
      emergencyContactPhone: state?.phoneNumberOfNextOfKin,
      accessibilityIssue: state.accessibilityConsiderations,
      educationSetting: state.education,
      educationWorkImpact: state.educationOrWorkImpact,
      employmentAdvisorAccepted: state.employmentAdvisor,
      accommodationStatusDetails: state.accommodationStatus,
      preferredContactMethod: z.nativeEnum(ContactOptions).optional(),
      adhd: state?.hasADHD,
      asd: state?.hasASD,
      clinicalNotes: this.referralStore.clinicalNotes
    }

    if (instanceID === "MHM_WARRINGTON") {
      return {
        ...basePayload,
        phoneType: state.preferredPhoneType || "Mobile",
        gpPhone: state.odsGP?.phone.join(", ") ?? state.gp?.phoneNumber
      }
    } else if (instanceID === "MHM_SEFTON") {
      return {
        ...basePayload,
        whereHeard: state.whereDidYouHearAboutService,
        keyWorker: state.nhsStaff
      }
    } else if (instanceID === "MHM_NORTHUMBERLAND") {
      return {
        ...basePayload,
        whereHeard: state.whereDidYouHearAboutService,
        gpAddress: state.odsGP?.address
          ? `${state.odsGP.address.address1}, ${state.odsGP.address.address2}, ${state.odsGP.address.city}, ${state.odsGP.address.postcode}`
          : `${state.gp?.address1}, ${state.gp?.address2}, ${state.gp?.postcode}`
      }
    } else {
      throw Error("Unknown backendID")
    }
  }
}

export default class SelfReferralMHMDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralMHM
  readonly name: string = "SelfReferralMHMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralMHMDialogue.id, new SelfReferralMHMScript(), state, snapshot)
  }
}
