import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import { RiskLevelReason, TrackingEvents } from "../../../models/Constants"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends GoodbyeScriptState {
  requiresUrgentSupport?: boolean
}

export type GoodbyeVHGScriptState = State
export class GoodbyeVHGScript extends GoodbyeScript {
  readonly name: string = "GoodbyeVHGScript"

  @step.logState
  askWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    if (this.clinicalStore.riskLevelReason === RiskLevelReason.CRISIS_DETECTION) {
      this.track(TrackingEvents.SKIP_FEEDBACK_CRISIS)
      return { nextStep: this.sayGoodBye }
    }
    if (this.clinicalStore.isCrisis) {
      this.track(TrackingEvents.SKIP_WHERE_DID_YOU_HEAR)
      return { nextStep: this.sayIHopeIHelped }
    }
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: `Before we close, where did you hear about ${serviceName}?`,
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleWhereDidYouHearAboutUsWithCrisis
    }
  }

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    const crisisInInput = this.clinicalStore.riskLevelReason === RiskLevelReason.CRISIS_DETECTION
    if (!crisisInInput) {
      void this.referralStore.updateReferral({
        isHelpful: d.state.isHelpful,
        improvementSuggestion: d.state.improvementSuggestion
      })
    }
    this.referralStore.stopPinging()

    return {
      body: crisisInInput
        ? undefined
        : "Well done for taking this important step towards better mental health",
      nextStep: this.sayDynamicLink
    }
  }

  /** Generic Handlers */

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    const treatment = this.clinicalStore.getAcceptedTreatment()
    if (state.referralSubmitted && !this.clinicalStore.isRisk && !treatment) {
      return "A member of the Nottingham and Nottinghamshire Talking Therapies team will contact you via telephone or text within the next week to begin your access into Talking Therapies"
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      if (
        this.clinicalStore.riskLevelReason === RiskLevelReason.CRISIS_DETECTION ||
        !state.referralSubmitted
      ) {
        const organisationName = this.rootStore.configStore.organisationName
        const iaptName = this.getIAPTName(state) || organisationName
        return `Because you are in need of urgent support, I cannot refer you into ${iaptName}\n`
      }
      return "A member of the Nottingham and Nottinghamshire Talking Therapies team will contact you via telephone or text within the next week to begin your access into Talking Therapies"
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk && state.referralSubmitted) {
      return "A member of the Nottingham and Nottinghamshire Talking Therapies team will contact you via telephone or text within the next week to begin your access into Talking Therapies"
    }
  }

  async getTreatmentMessage(state: State): Promise<string | void> {
    const canKeepSelfSafe = state.canKeepSelfSafe ?? true

    if (!state.isEligible) return undefined

    if (canKeepSelfSafe && !state.requiresUrgentSupport && !this.clinicalStore.isRisk) {
      return "Someone from our team will contact you within 5 working days to book your assessment appointment\n\nIf you haven’t heard from them in this time, please give them a call to arrange your assessment"
    }
  }

  getCustomReferralType(state: State): string | undefined {
    if (this.clinicalStore.assessmentFinished) return "Limbic Assessment"
    return "Self Referral"
  }
}

/* istanbul ignore next */
export default class GoodbyeVHGDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeVHG
  readonly name: string = "GoodbyeVHGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeVHGDialogue.id, new GoodbyeVHGScript(), state, snapshot)
  }
}
