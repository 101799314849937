/* eslint-disable @typescript-eslint/no-empty-function */
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { TrackingEvents } from "../../../models/Constants"
import sendEmail from "../../../backend/api/sendEmail"
import SelfReferralScript, {
  SelfReferralScriptState,
  SelfReferralScriptStateSchema
} from "./SelfReferralScript"
import { SendEmailStatus } from "../../../models/ISendEmail"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { EligibilityCheckCCGState } from "../eligibility/EligibilityCheckCCGDialogue"
import { z, ZodSchema } from "zod"

interface State extends SelfReferralScriptState {
  accessibilityConsiderations?:
    | "No impairment"
    | "Visual impairment"
    | "Hearing impairment"
    | "Other"
  mainIssue?: string
}

export type SelfReferralCCGScriptState = State

export const SelfReferralCCGScriptStateSchema = SelfReferralScriptStateSchema.extend({
  accessibilityConsiderations: z
    .union([
      z.literal("No impairment"),
      z.literal("Visual impairment"),
      z.literal("Hearing impairment"),
      z.literal("Other")
    ])
    .optional(),
  mainIssue: z.string().optional()
})

export class SelfReferralCCGScript extends SelfReferralScript {
  readonly name: string = "SelfReferralCCGScript"

  /** Abstract Overrides */

  async getReferralPayload(state: State): Promise<any> {}
  async onReferralFinished(): Promise<void> {}
  async onRiskReferralFinished(): Promise<void> {}

  /** Script Steps */

  @step.logState
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  @step
  askPostCodeForAddressLookup(d: IStepData<State>): IStepResult {
    d.state.addressLookupCounter = 1
    if (d.state.isCustomPostcode) {
      return { nextStep: this.askAddress }
    }
    return {
      body: "So firstly, what's your postcode?",
      prompt: {
        id: this.getPromptId("askPostCodeForAddressLookup"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostCodeForAddressLookupWithCrisis
    }
  }

  @step.logState
  askAddress(_d: IStepData<State>): IStepResult {
    return {
      body: "So firstly, please type your address below",
      prompt: {
        id: this.getPromptId("askAddress"),
        type: "address"
      },
      nextStep: this.handleAddressWithCrisis
    }
  }

  @step
  sayPleaseTypeYourPostcode(_d: IStepData<State>): IStepResult {
    return {
      body: "Please type your postcode",
      prompt: {
        id: this.getPromptId("sayPleaseTypeYourPostcode"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostCodeForAddressLookupWithCrisis
    }
  }

  @step
  sayPleaseRetypeYourPostcode(_d: IStepData<State>): IStepResult {
    return {
      body: "Let's try again",
      prompt: {
        id: this.getPromptId("sayPleaseRetypeYourPostcode"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.sayPleaseTypeYourPostcode
    }
  }

  @step
  sayUserNeedsToCallIn(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const wellbeingHubEmails = this.rootStore.configStore.wellbeingHubEmails ?? []

    return {
      body: [
        "Ok, unfortunately this doesn't seem to be working",
        `Please send an email to ${organisationName} at ${wellbeingHubEmails[0]}`
      ],
      clearStack: true,
      nextStep: this.end
    }
  }

  @step.logState
  finishSelfReferral(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayJustAFewMore }
  }

  @step.logState
  sayJustAFewMore(_d: IStepData<State>): IStepResult {
    return {
      body: ["Great. We're nearly finished", "Just a few more questions"],
      nextStep: this.startSelfReferralPart2
    }
  }

  @step.logState
  startSelfReferralPart2(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const hasEthnicity = !!d.state.ethnicity || this.referralStore.getCustomField<State>("ethnicity")
    if (hasEthnicity) return { nextStep: this.askPerinatal }
    return { nextStep: this.askEthnicity }
  }

  @step.logState
  askAccessibilityConsiderations(_d: IStepData<State>): IStepResult {
    return {
      body: "Are there any accessibility considerations we should be aware of before making contact?",
      prompt: {
        id: this.getPromptId("askAccessibilityConsiderations"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "No impairment", value: "No impairment" },
          { body: "Visual impairment", value: "Visual impairment" },
          { body: "Hearing impairment", value: "Hearing impairment" },
          { body: "Other", value: "Other" }
        ]
      },
      nextStep: this.handleAccessibilityConsiderations
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (
      d: IStepData<State, "No impairment" | "Visual impairment" | "Hearing impairment" | "Other">
    ) => {
      d.state.accessibilityConsiderations = d.response
    }
  )
  handleAccessibilityConsiderations(
    _d: IStepData<State, "No impairment" | "Visual impairment" | "Hearing impairment" | "Other">
  ): IStepResult {
    return {
      nextStep: this.askMainIssue
    }
  }

  @step.logState
  askMainIssue(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "What's the main issue that has brought you here today?",
        "(Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life in a few sentences)"
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: false,
        cancelIsEmptySubmit: true,
        cancelLabel: "skip",
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralCCGScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
  })
  @step.checkInputForCrisis({ getNextStep: (s: SelfReferralCCGScript) => s.askWhatIsYourGoal })
  handleMainIssueWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      body: "Thank you for sharing, I know that may have been difficult",
      nextStep: this.askWhatIsYourGoal
    }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: [
        "And that's everything",
        `I've now shared your details with ${iaptName}`,
        "Congratulations on taking this important step towards better mental health!"
      ],
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const wellbeingHubEmails = this.rootStore.configStore.wellbeingHubEmails ?? []
    return {
      body: [
        `Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data to ${iaptName}`,
        "I've notified my creators of this issue",
        `Please send an email to ${organisationName} at ${wellbeingHubEmails[0]}`
      ],
      prompt: {
        id: this.getPromptId("sayReferralSubmissionFailed"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralCCGScriptStateSchema
  }

  getEthnicities(state: State): string[] {
    return [
      "White - British",
      "White - Irish",
      "White - Any other White background",
      "Mixed - White and Black Caribbean",
      "Mixed - White and Black African",
      "Mixed - White and Asian",
      "Mixed - Any other mixed background",
      "Asian or Asian British - Indian",
      "Asian or Asian British - Pakistani",
      "Asian or Asian British - Bangladeshi",
      "Asian or Asian British - Any other Asian background",
      "Black or Black British - Caribbean",
      "Black or Black British - African",
      "Black or Black British - Any other Black background",
      "Other Ethnic Groups - Chinese",
      "Other Ethnic Groups - Any other ethnic group",
      "Not Stated - Not Stated",
      "Not known - Not known"
    ]
  }

  getGenders(_state: State): string[] {
    return [
      "Male (including trans man)",
      "Female (including trans woman)",
      "Non-binary",
      "Not Known",
      "Not Stated",
      "Other"
    ]
  }

  getPerinatalStatuses(state: State): string[] {
    return [
      "None apply",
      "Pregnant",
      "Child under 1",
      "Pregnancy in the last year",
      "Pregnant and Child under 1",
      "Pregnancy in the last year and Child under 1",
      "New Father - Child born within last 12 months"
    ]
  }

  getDisabilities(state: State): string[] {
    return [
      "Behaviour and Emotional",
      "Hearing",
      "Manual Dexterity",
      "Memory or ability to concentrate, learn or understand (Learning Disability)",
      "Mobility and Gross Motor",
      "Perception of Physical Danger",
      "Personal, Self Care and Continence",
      "Progressive Conditions and Physical Health (such as HIV, cancer, multiple sclerosis, fits, etc)",
      "Sight",
      "Speech",
      "Other",
      "Do not wish to say"
    ]
  }

  getExArmedForcesValues(state: State): string[] {
    return [
      "Yes - ex services",
      "Yes - currently serving",
      "No",
      "Not stated (Person asked but declined to provide a response)"
    ]
  }

  getSexualities(_state: State): string[] {
    return ["Heterosexual", "Lesbian or Gay", "Bisexual", "Other", "Unknown", "Not Stated"]
  }

  getMedicalConditions(state: State): string[] {
    return [
      "No",
      "Asthma",
      "Bladder/Bowel Condition",
      "Cancer",
      "Chronic Kidney Disease",
      "Chronic Muscular Skeletal",
      "COPD",
      "Chronic Pain",
      "Chronic Pancreatitis",
      "Coronary Heart Disease (CHD)",
      "Crohns",
      "Dementia",
      "Diabetes - Type 1",
      "Diabetes - Type 2",
      "Digestive Tract Conditions",
      "Epilepsy",
      "Fibromyalgia",
      "Heart Failure",
      "Hypertension",
      "Irritable Bowel Syndrome",
      "M.E (Chronic Fatigue)",
      "Medically Unexplained Conditions",
      "Multiple Sclerosis",
      "Osteoporosis",
      "Parkinson's",
      "Sickle Cell Disease",
      "Skin Condition including Eczema",
      "Stroke and Transient Ischaemic Attack",
      "Tinnitus"
    ]
  }

  async onHandleEthnicity(state: State): Promise<IStepResult<State>> {
    if (state.gender || this.referralStore.getCustomField<State>("gender")) {
      return { nextStep: this.askPerinatal }
    } else {
      return { nextStep: this.askGender }
    }
  }

  async onHandleExArmedForces(state: State): Promise<IStepResult<State>> {
    if (state.sexuality || this.referralStore.getCustomField<State>("sexuality")) {
      return { nextStep: this.askLongTermMedicalCondition }
    } else {
      return { nextStep: this.askSexuality }
    }
  }

  async onHandleCurrentSupport(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askMainIssue }
  }

  async onHandleDisabilityStatus(state: State): Promise<IStepResult<State>> {
    if (state.disabilityStatus) {
      return { nextStep: this.askDisability }
    }
    return { nextStep: this.askAccessibilityConsiderations }
  }

  async onHandleDisability(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askAccessibilityConsiderations }
  }

  async onHandleWhatIsYourGoalWithCrisis(state: State): Promise<IStepResult> {
    this.referralStore.addClinicalNote(`Expectations from support: ${state.therapyGoal}`)
    return { nextStep: this.doReferralSubmission }
  }

  async onSubmitReferralData(state: State): Promise<boolean> {
    try {
      // prettier-ignore
      const text = this.createReferralEmail(state, this.clinicalStore.isRisk)
      const wellbeingHubEmails = this.rootStore.configStore.wellbeingHubEmails ?? []
      const iaptEmails = state.iapt?.emails ?? []
      const to = [...wellbeingHubEmails, ...iaptEmails].filter(Boolean) as string[]
      const organisationName = this.rootStore.configStore.organisationName
      const status = await sendEmail(
        { to, subject: "Limbic | New Referral to IAPT", text },
        organisationName
      )
      if (status === SendEmailStatus.SendEmailFailed) {
        state.referralSubmitted = false
        state.referralSubmissionFailed = true
        this.track(TrackingEvents.SELF_REFERRAL_NOT_SUBMITTED)
        return false
      }
      state.referralSubmitted = true
      state.referralSubmissionFailed = false
      this.track(TrackingEvents.SELF_REFERRAL_SUBMITTED)
    } catch (e) {
      this.logException(e, "onSubmitReferralData")
      return false
    }
    return true
  }

  getTitleHTML(state: State): string {
    const clinicalPath = this.clinicalStore.clinicalPath
    return `
    <p>
        <b>Problem Group:</b> ${clinicalPath?.clinicalGroup}<br/>
        <b>Self-referred to IAPT:</b> ${!!state.iapt?.emails?.length}<br/>
    </p>
    `
  }

  getPersonalInfoHTML(state: State): string {
    const personalInfo = super.getPersonalInfoHTML(state)
    const jobCategory = this.referralStore.getCustomField<EligibilityCheckCCGState>("jobCategory")
    const organisation = this.referralStore.getCustomField<EligibilityCheckCCGState>("organisation")
    const mainIssue = state.mainIssue
    const accessibilityConsiderations = state.accessibilityConsiderations
    const requiresUrgentSupport =
      this.referralStore.getCustomField<EligibilityCheckCCGState>("requiresUrgentSupport")
    const additionalDetails = `<b>Job category: </b> ${jobCategory ?? "-"}<br/>
<b>Organisation: </b> ${organisation ?? "-"}<br/>
<b>Main issue seeking support for: </b> ${mainIssue ?? "-"}<br/>
<b>Accessibility considerations: </b> ${accessibilityConsiderations ?? "-"}<br/>
<b>User's answer on keeping safe: </b> ${
      requiresUrgentSupport ? "No, I cannot keep myself safe" : "Yes, I can keep myself safe"
    }<br/>`

    return ([] as Array<string | false | undefined>)
      .concat(personalInfo, additionalDetails)
      .filter(Boolean)
      .join("\n")
  }

  getClinicalNotesHTML(_state: State): string {
    const primaries = this.clinicalStore.primaryProblems
    const primariesTitle = primaries.length
      ? "<b>Primary Problem Categories</b>"
      : "<b>Primary Problem Categories:</b> None"
    const primaryProblems = primaries.length
      ? `<ul>${primaries.map(i => `<li>${i}</li>`).join("\n")}</ul>`
      : ""

    const secondaries = this.clinicalStore.secondaryProblems
    const secondariesTitle = primaries.length
      ? "<b>Secondary Problem Categories</b>"
      : "<b>Secondary Problem Categories:</b> None"
    const secondaryProblems = primaries.length
      ? `<ul>${secondaries.map(i => `<li>${i}</li>`).join("\n")}</ul>`
      : ""

    const flags = this.clinicalStore.flags
    const flagsTitle = flags.length ? "<b>Problem flags</b>" : "<b>Problem flags:</b> None"
    const clinicalFlags = flags.length
      ? `<ul>${flags.map(i => `<li>${i}</li>`).join("\n")}</ul>`
      : ""

    const clinicalNotes = this.referralStore.clinicalNotes
    const notes = clinicalNotes.map(note => `<li>${note}</li>`).join("\n")
    return `
    ${primariesTitle}
    ${primaryProblems}
    
    ${secondariesTitle}
    ${secondaryProblems}
    
    ${flagsTitle}
    ${clinicalFlags}
    
    <b>Clinical Notes</b>
    <ul>${notes}</ul>
    `
  }

  getAdditionalInfoHTML(state: State): string {
    const riskLevel = this.clinicalStore.isRisk
      ? this.clinicalStore.isHighRisk
        ? "(High)"
        : "(Moderate)"
      : ""
    const potentialRisk = this.clinicalStore.isRisk
      ? `<span class='red'><b>Potential Risk Identified:</b> Yes ${riskLevel}</span>`
      : `<b>Potential Risk Identified:</b> No`

    const numbersShared = this.referralStore.getCustomField("crisisNumbersShared")
    const crisisNumbersShared = numbersShared
      ? `<b>Crisis service contact details shared with the user:</b> Yes - ${numbersShared}`
      : `<b>Crisis service contact details shared with the user:</b> No`

    const triggerWords = this.clinicalStore.triggerWords?.length
      ? `<b>Crisis Trigger Words:</b> ${this.clinicalStore.triggerWords?.join(", ")}<br/>`
      : ""
    const triggerWordsPhrase =
      this.clinicalStore.triggerWords?.length && this.clinicalStore.triggerWordsPhrase //
        ? `<b>User Input With Trigger Words:</b> ${this.clinicalStore.triggerWordsPhrase}<br/>`
        : ""
    return `
    ${potentialRisk}
    ${this.clinicalStore.isRisk ? crisisNumbersShared : ""}
    ${triggerWords}
    ${triggerWordsPhrase}
    `
  }

  getReferralSubmittedIndicatorHTML(_state: State): string | undefined {
    // we never send the referral to the service
    // so on reason to add the indicator
    return undefined
  }
}

/* istanbul ignore next */
export default class SelfReferralCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralCCG
  readonly name: string = "SelfReferralCCGDialogue"
  constructor(
    state: Omit<State, "addressLookupCounter" | "invalidPostcodeCounter">,
    snapshot?: IDialogueSnapshot<State>
  ) {
    super(SelfReferralCCGDialogue.id, new SelfReferralCCGScript(), state, snapshot)
  }
}
