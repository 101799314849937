import GoodbyeScript, { GoodbyeScriptState } from "./GoodbyeScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"

type State = GoodbyeScriptState

export type GoodbyeLincolnshireScriptState = State

export class GoodbyeLincolnshireScript extends GoodbyeScript {
  readonly name: string = "GoodbyeLincolnshireScript"

  /** Script Tests */

  @step
  goToAskWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    const referralSubmitted = this.referralStore.referralSubmitted
    return {
      body: referralSubmitted
        ? "Thank you for the referral. In the meantime, please look at the website if you need more information"
        : "If you need more information, please have a look at the website",
      nextStep: this.askWhereDidYouHearAboutUs
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    _state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    if (!body.length) {
      return { nextStep: this.goToAskWhereDidYouHearAboutUs }
    }

    const choices: any[] = [{ body: "Okay" }, recapMessage && { body: "I understand" }] //
      .filter(Boolean)

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.goToAskWhereDidYouHearAboutUs
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && !this.clinicalStore.isRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return state.isIdleSubmitted //
        ? `It looks like there hasn’t been any activity for some time so I've referred you to ${iaptName}`
        : `I've referred you to ${iaptName}\n\nSomeone from the ${iaptName} team will be in contact within 1-2 working days.`
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeLincolnshire
  readonly name: string = "GoodbyeLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeLincolnshireDialogue.id, new GoodbyeLincolnshireScript(), state, snapshot)
  }
}
