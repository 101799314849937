import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import AssessmentIAPTScript from "./AssessmentIAPTScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { IStepData } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { DiscussionSteps } from "../../../models/Constants"

type State = AssessmentIAPTScriptState

export class AssessmentITalkScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentITalkScript"

  /** Script Steps */

  @step.logState
  goToBookAppointmentDialogue(d: IStepData<State>): IStepResult {
    const BookAppointmentDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.BookAppointment
    )
    const nextDialogue = BookAppointmentDialogue ? new BookAppointmentDialogue(d.state) : undefined
    return {
      nextDialogue,
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.sayReadyForWASAS
    }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }

  async onFinishAssessment(state: State): Promise<IStepResult> {
    return { nextStep: this.goToBookAppointmentDialogue }
  }
}

/* istanbul ignore next */
export default class AssessmentITalkDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentITalk
  readonly name: string = "AssessmentITalkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentITalkDialogue.id, new AssessmentITalkScript(), state, snapshot)
  }
}
