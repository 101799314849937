import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { WellbeingHubUserTypes } from "../../../models/Constants"

type State = GoodbyeScriptState

export class GoodbyeGMHubScript extends GoodbyeScript {
  readonly name: string = "GoodbyeGMHubScript"

  /** Script Steps */

  /** Generic Handlers */
  async onHandleRecapMessage(
    _state: State,
    body: string[],
    recapMessage: string | undefined
  ): Promise<IStepResult> {
    const choices = [{ body: "Okay" }]
    if (recapMessage) {
      choices.push({ body: "I understand" })
    }

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askFeedback
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    const userType = this.wellbeingHubStore.userType
    if (state.referralSubmitted) {
      const organisationName = this.rootStore.configStore.organisationName
      const idleMessageMap: Record<WellbeingHubUserTypes, string> = {
        individual: `It looks like there hasn’t been any activity for some time so I've referred you to ${organisationName}`,
        managerIndividual: `It looks like there hasn’t been any activity for some time so I've submitted the information you've provided to ${organisationName}`,
        managerTeam: `It looks like there hasn’t been any activity for some time so I've submitted the information you've provided to ${organisationName}`
      }
      const referredMessageMap: Record<WellbeingHubUserTypes, string> = {
        individual: `I've referred you to ${organisationName}\n\nOne of the team will contact you within 5 working days to discuss your referral`,
        managerIndividual: `I've submitted the information you've provided to ${organisationName}\n\nThe team will contact the individual directly to discuss their needs and will confirm you as the source of the contact\n\nThe ${organisationName} may follow up with you directly to discuss the referral you have submitted`,
        managerTeam: `I've submitted the information you've provided to ${organisationName}\n\nThe team will contact you within 5 working days to discuss next steps`
      }

      return state.isIdleSubmitted //
        ? idleMessageMap[userType || "managerIndividual"]
        : referredMessageMap[userType || "managerIndividual"]
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      return `Because you identified as being in crisis, someone from our team will call you within the next 5 working days. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger.`
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      return `Because you identified as being in crisis, someone from our team will call you within the next 5 working days. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger.`
    }
  }
}

export default class GoodbyeGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeGMHub
  readonly name: string = "GoodbyeGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeGMHubDialogue.id, new GoodbyeGMHubScript(), state, snapshot)
  }
}
