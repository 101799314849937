import createSurveyDialogue from "../createSurveyDialogue"
import ISurvey, { SurveyType } from "../../models/ISurvey"
import { DialogueIDs } from "../DialogueIDs"

const PDSS: ISurvey = {
  questions: [
    {
      id: "1",
      questionName: "PDSS - step1",
      question: "1. How many panic and limited symptoms attacks did you have during the week?",
      type: "inline",
      progress: 0.14,
      answers: [
        { points: 0, answer: "No panic or limited symptom episodes" },
        {
          points: 1,
          answer: "No full panic attacks and no more than 1 limited symptom attack/day"
        },
        {
          points: 2,
          answer: "1 or 2 full panic attacks and/or multiple limited symptom attacks/day"
        },
        { points: 3, answer: "More than 2 full attacks but not more than 1/day on average" },
        {
          points: 4,
          answer: "Full panic attacks occurred more than once a day, more days than not"
        }
      ]
    },
    {
      id: "2",
      questionName: "PDSS - step2",
      question: [
        "2. If you had any panic attacks during the past week, how distressing (uncomfortable, frightening) were they while they were happening?",
        "(if you had more than one, give an average rating. If you didn't have any panic attacks but did have limited symptom attacks, answer for the limited symptom attacks)"
      ],
      type: "inline",
      progress: 0.28,
      answers: [
        {
          points: 0,
          answer:
            "Not at all distressing, or no panic or limited symptom attacks during the past week"
        },
        { points: 1, answer: "Mildy distressing (not too intense)" },
        { points: 2, answer: "Moderately distressing (intense, but still manageable)" },
        { points: 3, answer: "Severely distressing (very intense)" },
        { points: 4, answer: "Extremely distressing (extreme distress during all attacks)" }
      ]
    },
    {
      id: "3",
      questionName: "PDSS - step3",
      question: [
        "3. During the past week, how much have you worried or felt anxious about when your next panic attack would occur or about fears related to the attacks",
        "(for example, that they could mean you have physical or mental health problems or could cause you social embarrassment)?"
      ],
      type: "inline",
      progress: 0.42,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "Occasionally or only mildly" },
        { points: 2, answer: "Frequently or moderately" },
        { points: 3, answer: "Very often or to a very disturbing degree" },
        { points: 4, answer: "Nearly constantly and to a disabling extent" }
      ]
    },
    {
      id: "4",
      questionName: "PDSS - step4",
      question: [
        "4. During the past week were there any places or situations (e.g., public transportation, movie theatres, crowds, bridges, tunnels, shopping centres, being alone) you avoided, or felt afraid of (uncomfortable in, wanted to avoid or leave), because of fear of having a panic attack?",
        "Are there any other situations that you would have avoided or been afraid of if they had come up during the week, for the same reason? If yes to either question, please rate your level of fear and avoidance this past week"
      ],
      type: "inline",
      progress: 0.57,
      answers: [
        { points: 0, answer: "No fear or avoidance" },
        {
          points: 1,
          answer:
            "Occasional fear and/or avoidance but I could usually confront or endure the situation. There was little or no modification of my lifestyle due to this"
        },
        {
          points: 2,
          answer:
            "Noticeable fear and/or avoidance but still manageable. I avoided some situations, but I could confront them with a companion. There was some modification of my lifestyle because of this, but my overall functioning was not impaired"
        },
        {
          points: 3,
          answer:
            "Extensive avoidance. Substantial modification of my lifestyle was required to accommodate the avoidance making it difficult to manage usual activities"
        },
        {
          points: 4,
          answer:
            "Pervasive disabling fear and/or avoidance. Extensive modification in my lifestyle was required such that important tasks were not performed"
        }
      ]
    },
    {
      id: "5",
      questionName: "PDSS - step5",
      question: [
        "5. During the past week, were there any activities (e.g. physical exertion, sexual relations, taking a hot shower or bath, drinking coffee, watching an exciting or scary movie) that you avoided, or felt afraid of (uncomfortable doing, wanted to avoid or stop), because they caused physical sensations like those you feel during panic attacks or that you were afraid might trigger a panic attack?",
        "Are there any other activities that you would have avoided or been afraid of if they had come up during the week for that reason?",
        "If yes to either question, please rate your level of fear and avoidance of those activities this past week"
      ],
      type: "inline",
      progress: 0.71,
      answers: [
        {
          points: 0,
          answer:
            "No fear or avoidance of situations or activities because of distressing physical sensations"
        },
        {
          points: 1,
          answer:
            "Occasional fear and/or avoidance, but usually I could confront or endure with little distress activities that cause physical sensations. There was little modification of my lifestyle due to this"
        },
        {
          points: 2,
          answer:
            "Noticeable avoidance but still manageable. There was definite, but limited, modification of my lifestyle such that my overall functioning was not impaired"
        },
        {
          points: 3,
          answer:
            "Extensive avoidance. There was substantial modification of my lifestyle or interference in my functioning"
        },
        {
          points: 4,
          answer:
            "Pervasive and disabling avoidance. There was extensive modification in my lifestyle due to this such that important tasks or activities were not performed"
        }
      ]
    },
    {
      id: "6",
      questionName: "PDSS - step6",
      question: [
        "6. During the past week, how much did the above symptoms altogether (panic and limited symptom attacks, worry about attacks, and fear of situations and activities because of attacks) interfere with your ability to work or carry out your responsibilities at home?",
        "(If your work or home responsibilities were less than usual this past week, answer how you think you would have done if the responsibilities had been usual)"
      ],
      type: "inline",
      progress: 0.85,
      answers: [
        { points: 0, answer: "No interference with work or home responsibilities" },
        {
          points: 1,
          answer:
            "Slight interference with work or home responsibilities, but I could do nearly everything I could if I didn't have these problems"
        },
        {
          points: 2,
          answer:
            "Significant interference with work or home responsibilities, but I still could manage to do the things I needed to do"
        },
        {
          points: 3,
          answer:
            "Substantial impairment in work or home responsibilities; there were many important things I couldn't do because of these problems"
        },
        {
          points: 4,
          answer:
            "Extreme, incapacitating impairment such that I was essentially unable to manage any work or home responsibilities"
        }
      ]
    },
    {
      id: "7",
      questionName: "PDSS - step7",
      question: [
        "7. During the past week, how much did panic and limited symptom attacks, worry about attacks and fear of situations and activities because of attacks interfere with your social life?",
        "(if you didn't have many opportunities to socialize this past week, answer how you think you would have done if you did have opportunities)"
      ],
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "No interference" },
        {
          points: 1,
          answer:
            "Slight interference with social activities, but I could do nearly everything I could if I didn't have these problems"
        },
        {
          points: 2,
          answer:
            "Significant interference with social activities but I could manage to do most things if I made the effort"
        },
        {
          points: 3,
          answer:
            "Substantial impairment in social activities; there are many social things I couldn't do because of these problems"
        },
        {
          points: 4,
          answer:
            "Extreme, incapacitating impairment, such that there was hardly anything social I could do"
        }
      ]
    }
  ]
}

export default createSurveyDialogue(DialogueIDs.PDSS, PDSS, SurveyType.PDSS)
