import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState
export type CrisisLimbicScriptState = State

export class CrisisLimbicScript extends CrisisScript {
  readonly name: string = "CrisisLimbicScript"

  /** Script Steps */

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    return {
      body: [
        "Sorry to hear that",
        "If you are in need of mental health support please contact your local health services",
        "Alternatively, you can talk to the Samaritans about how you are feeling or text SHOUT to 85258",
        "Always contact emergency services in the case of physical emergencies"
      ],
      nextStep: this.askWannaContinue
    }
  }

  @step
  askWannaContinue(_d: IStepData<State>): IStepResult {
    return {
      body: "Would you like to continue?",
      prompt: {
        id: this.getPromptId("askWannaContinue"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleWannaContinue
    }
  }

  @step
  handleWannaContinue(d: IStepData<State, boolean>): IStepResult {
    if (d.response) return { body: "Okay", nextStep: this.end }
    return {
      body: [
        "Okay, I hope you feel better soon",
        'You can also click "continue" if you want to continue'
      ],
      prompt: {
        id: this.getPromptId("askWannaContinue"),
        trackResponse: false,
        type: "inlinePicker",
        choices: [{ body: "Continue" }]
      },
      nextStep: this.end
    }
  }
}

export default class CrisisLimbicDialogue extends Dialogue<State> {
  static id = DialogueIDs.CrisisLimbic
  readonly name: string = "CrisisLimbicDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisLimbicDialogue.id, new CrisisLimbicScript(), state, snapshot)
  }
}
