import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwayITalkScriptState = State

export class RiskPathwayITalkScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayITalkScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 2 weeks)?",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment (within 2 weeks)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayITalkScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async sayCrisis(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName

    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        `However ${organisationName} is not an emergency response service`
      ],
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "To get more urgent help, please call NHS 111 and select Option 2",
        "If you need urgent, life threatening medical help please call 999",
        "Alternatively, you can also call Samaritans on 116 123"
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 (Option 2), Samaritans (116 123)"
    )
    return {
      body: "Ok, let's carry on with the mental health check in",
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  getKeepingSafeAttachmentUrls(_state: State): string[] {
    // TODO: Do we have a leaflet?
    return []
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang='en'>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${title}</title>
    ${this.getEmailHTMLStyle()}
    <!--[if IEMobile 7]>
	<style type="text/css">
		/* Targeting Windows Mobile */
	</style>
	<![endif]-->
    <!--[if gte mso 9]>
	<style>
		/* Target Outlook 2007 and 2010 */
	</style>
	<![endif]-->
  </head>

  <body>
    <table cellpadding="0" cellspacing="0" border="0" id="backgroundTable">
      <tr>
        <td>
          <table cellpadding="0" cellspacing="0" border="0" id="contentTable">
            <tr>
              <td><img class="image_fix" src="${logo}" alt="${title}" title="${title}" width="x" height="x" /><br /><br /></td>
            </tr>
            <tr>
              <td>
                You have made a self-referral today to italk. In one of your answers you said you were experiencing some thoughts of self-harm or taking your own life.
              </td>
            </tr>
            <tr>
              <td>
                <br />
                As you are waiting to access our service, if you feel you may act on suicidal thoughts, cannot keep yourself or are a risk to others around you, please call 999, NHS 111 and select Option 2 or Samaritans on 116 123.
              </td>
            </tr>
            <tr>
              <td>
                <br /><br />
                <em>The italk team</em>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>`
  }
}

/* istanbul ignore next */
export default class RiskPathwayITalkDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayITalk
  readonly name: string = "RiskPathwayITalkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayITalkDialogue.id, new RiskPathwayITalkScript(), state, snapshot)
  }
}
