import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

export const iaptGPMapNorfolk: IAPTGPMap = {
  A99984: IAPTIDs.NORFOLK_WAVENEY,
  D82001: IAPTIDs.NORFOLK_WAVENEY,
  D82002: IAPTIDs.NORFOLK_WAVENEY,
  D82003: IAPTIDs.NORFOLK_WAVENEY,
  D82004: IAPTIDs.NORFOLK_WAVENEY,
  D82005: IAPTIDs.NORFOLK_WAVENEY,
  D82006: IAPTIDs.NORFOLK_WAVENEY,
  D82007: IAPTIDs.NORFOLK_WAVENEY,
  D82008: IAPTIDs.NORFOLK_WAVENEY,
  D82009: IAPTIDs.NORFOLK_WAVENEY,
  D82010: IAPTIDs.NORFOLK_WAVENEY,
  D82011: IAPTIDs.NORFOLK_WAVENEY,
  D82012: IAPTIDs.NORFOLK_WAVENEY,
  D82013: IAPTIDs.NORFOLK_WAVENEY,
  D82015: IAPTIDs.NORFOLK_WAVENEY,
  D82016: IAPTIDs.NORFOLK_WAVENEY,
  D82017: IAPTIDs.NORFOLK_WAVENEY,
  D82018: IAPTIDs.NORFOLK_WAVENEY,
  D82019: IAPTIDs.NORFOLK_WAVENEY,
  D82020: IAPTIDs.NORFOLK_WAVENEY,
  D82022: IAPTIDs.NORFOLK_WAVENEY,
  D82023: IAPTIDs.NORFOLK_WAVENEY,
  D82024: IAPTIDs.NORFOLK_WAVENEY,
  D82025: IAPTIDs.NORFOLK_WAVENEY,
  D82026: IAPTIDs.NORFOLK_WAVENEY,
  D82027: IAPTIDs.NORFOLK_WAVENEY,
  D82028: IAPTIDs.NORFOLK_WAVENEY,
  D82029: IAPTIDs.NORFOLK_WAVENEY,
  D82030: IAPTIDs.NORFOLK_WAVENEY,
  D82031: IAPTIDs.NORFOLK_WAVENEY,
  D82032: IAPTIDs.NORFOLK_WAVENEY,
  D82033: IAPTIDs.NORFOLK_WAVENEY,
  D82034: IAPTIDs.NORFOLK_WAVENEY,
  D82035: IAPTIDs.NORFOLK_WAVENEY,
  D82036: IAPTIDs.NORFOLK_WAVENEY,
  D82037: IAPTIDs.NORFOLK_WAVENEY,
  D82038: IAPTIDs.NORFOLK_WAVENEY,
  D82039: IAPTIDs.NORFOLK_WAVENEY,
  D82040: IAPTIDs.NORFOLK_WAVENEY,
  D82041: IAPTIDs.NORFOLK_WAVENEY,
  D82042: IAPTIDs.NORFOLK_WAVENEY,
  D82043: IAPTIDs.NORFOLK_WAVENEY,
  D82044: IAPTIDs.NORFOLK_WAVENEY,
  D82045: IAPTIDs.NORFOLK_WAVENEY,
  D82046: IAPTIDs.NORFOLK_WAVENEY,
  D82047: IAPTIDs.NORFOLK_WAVENEY,
  D82048: IAPTIDs.NORFOLK_WAVENEY,
  D82049: IAPTIDs.NORFOLK_WAVENEY,
  D82050: IAPTIDs.NORFOLK_WAVENEY,
  D82051: IAPTIDs.NORFOLK_WAVENEY,
  D82053: IAPTIDs.NORFOLK_WAVENEY,
  D82054: IAPTIDs.NORFOLK_WAVENEY,
  D82056: IAPTIDs.NORFOLK_WAVENEY,
  D82057: IAPTIDs.NORFOLK_WAVENEY,
  D82058: IAPTIDs.NORFOLK_WAVENEY,
  D82059: IAPTIDs.NORFOLK_WAVENEY,
  D82060: IAPTIDs.NORFOLK_WAVENEY,
  D82062: IAPTIDs.NORFOLK_WAVENEY,
  D82063: IAPTIDs.NORFOLK_WAVENEY,
  D82064: IAPTIDs.NORFOLK_WAVENEY,
  D82065: IAPTIDs.NORFOLK_WAVENEY,
  D82066: IAPTIDs.NORFOLK_WAVENEY,
  D82067: IAPTIDs.NORFOLK_WAVENEY,
  D82068: IAPTIDs.NORFOLK_WAVENEY,
  D82070: IAPTIDs.NORFOLK_WAVENEY,
  D82071: IAPTIDs.NORFOLK_WAVENEY,
  D82072: IAPTIDs.NORFOLK_WAVENEY,
  D82073: IAPTIDs.NORFOLK_WAVENEY,
  D82076: IAPTIDs.NORFOLK_WAVENEY,
  D82078: IAPTIDs.NORFOLK_WAVENEY,
  D82079: IAPTIDs.NORFOLK_WAVENEY,
  D82080: IAPTIDs.NORFOLK_WAVENEY,
  D82084: IAPTIDs.NORFOLK_WAVENEY,
  D82085: IAPTIDs.NORFOLK_WAVENEY,
  D82087: IAPTIDs.NORFOLK_WAVENEY,
  D82088: IAPTIDs.NORFOLK_WAVENEY,
  D82096: IAPTIDs.NORFOLK_WAVENEY,
  D82099: IAPTIDs.NORFOLK_WAVENEY,
  D82100: IAPTIDs.NORFOLK_WAVENEY,
  D82104: IAPTIDs.NORFOLK_WAVENEY,
  D82105: IAPTIDs.NORFOLK_WAVENEY,
  D82106: IAPTIDs.NORFOLK_WAVENEY,
  D82600: IAPTIDs.NORFOLK_WAVENEY,
  D82604: IAPTIDs.NORFOLK_WAVENEY,
  D82618: IAPTIDs.NORFOLK_WAVENEY,
  D82621: IAPTIDs.NORFOLK_WAVENEY,
  D82624: IAPTIDs.NORFOLK_WAVENEY,
  D82628: IAPTIDs.NORFOLK_WAVENEY,
  D82641: IAPTIDs.NORFOLK_WAVENEY,
  D82643: IAPTIDs.NORFOLK_WAVENEY,
  D83002: IAPTIDs.NORFOLK_WAVENEY,
  D83009: IAPTIDs.NORFOLK_WAVENEY,
  D83010: IAPTIDs.NORFOLK_WAVENEY,
  D83011: IAPTIDs.NORFOLK_WAVENEY,
  D83016: IAPTIDs.NORFOLK_WAVENEY,
  D83022: IAPTIDs.NORFOLK_WAVENEY,
  D83023: IAPTIDs.NORFOLK_WAVENEY,
  D83030: IAPTIDs.NORFOLK_WAVENEY,
  D83034: IAPTIDs.NORFOLK_WAVENEY,
  D83035: IAPTIDs.NORFOLK_WAVENEY,
  D83047: IAPTIDs.NORFOLK_WAVENEY,
  D83608: IAPTIDs.NORFOLK_WAVENEY,
  U04154: IAPTIDs.NORFOLK_WAVENEY,
  U05433: IAPTIDs.NORFOLK_WAVENEY,
  U10555: IAPTIDs.NORFOLK_WAVENEY,
  U10602: IAPTIDs.NORFOLK_WAVENEY,
  U13032: IAPTIDs.NORFOLK_WAVENEY,
  U13557: IAPTIDs.NORFOLK_WAVENEY,
  U31420: IAPTIDs.NORFOLK_WAVENEY,
  U36042: IAPTIDs.NORFOLK_WAVENEY,
  U47975: IAPTIDs.NORFOLK_WAVENEY,
  U48652: IAPTIDs.NORFOLK_WAVENEY,
  U59961: IAPTIDs.NORFOLK_WAVENEY,
  U62485: IAPTIDs.NORFOLK_WAVENEY,
  U72706: IAPTIDs.NORFOLK_WAVENEY,
  U73212: IAPTIDs.NORFOLK_WAVENEY,
  U90673: IAPTIDs.NORFOLK_WAVENEY,
  U91215: IAPTIDs.NORFOLK_WAVENEY,
  U94681: IAPTIDs.NORFOLK_WAVENEY,
  Y00297: IAPTIDs.NORFOLK_WAVENEY,
  Y00420: IAPTIDs.NORFOLK_WAVENEY,
  Y00502: IAPTIDs.NORFOLK_WAVENEY,
  Y01690: IAPTIDs.NORFOLK_WAVENEY,
  Y02082: IAPTIDs.NORFOLK_WAVENEY,
  Y02139: IAPTIDs.NORFOLK_WAVENEY,
  Y02751: IAPTIDs.NORFOLK_WAVENEY,
  Y02872: IAPTIDs.NORFOLK_WAVENEY,
  Y03222: IAPTIDs.NORFOLK_WAVENEY,
  Y03316: IAPTIDs.NORFOLK_WAVENEY,
  Y03424: IAPTIDs.NORFOLK_WAVENEY,
  Y03453: IAPTIDs.NORFOLK_WAVENEY,
  Y03595: IAPTIDs.NORFOLK_WAVENEY,
  Y03888: IAPTIDs.NORFOLK_WAVENEY,
  Y04208: IAPTIDs.NORFOLK_WAVENEY,
  Y04209: IAPTIDs.NORFOLK_WAVENEY,
  Y04210: IAPTIDs.NORFOLK_WAVENEY,
  Y04211: IAPTIDs.NORFOLK_WAVENEY,
  Y04212: IAPTIDs.NORFOLK_WAVENEY,
  Y04213: IAPTIDs.NORFOLK_WAVENEY,
  Y04214: IAPTIDs.NORFOLK_WAVENEY,
  Y04215: IAPTIDs.NORFOLK_WAVENEY,
  Y04486: IAPTIDs.NORFOLK_WAVENEY,
  Y04499: IAPTIDs.NORFOLK_WAVENEY,
  Y04516: IAPTIDs.NORFOLK_WAVENEY,
  Y04575: IAPTIDs.NORFOLK_WAVENEY,
  Y04581: IAPTIDs.NORFOLK_WAVENEY,
  Y04582: IAPTIDs.NORFOLK_WAVENEY,
  Y04583: IAPTIDs.NORFOLK_WAVENEY,
  Y04589: IAPTIDs.NORFOLK_WAVENEY,
  Y04590: IAPTIDs.NORFOLK_WAVENEY,
  Y04670: IAPTIDs.NORFOLK_WAVENEY,
  Y04961: IAPTIDs.NORFOLK_WAVENEY,
  Y05176: IAPTIDs.NORFOLK_WAVENEY,
  Y05291: IAPTIDs.NORFOLK_WAVENEY,
  Y05350: IAPTIDs.NORFOLK_WAVENEY,
  Y05472: IAPTIDs.NORFOLK_WAVENEY,
  Y05726: IAPTIDs.NORFOLK_WAVENEY,
  Y06021: IAPTIDs.NORFOLK_WAVENEY,
  Y06108: IAPTIDs.NORFOLK_WAVENEY,
  Y06145: IAPTIDs.NORFOLK_WAVENEY,
  Y06229: IAPTIDs.NORFOLK_WAVENEY,
  Y06230: IAPTIDs.NORFOLK_WAVENEY,
  Y06231: IAPTIDs.NORFOLK_WAVENEY,
  Y06232: IAPTIDs.NORFOLK_WAVENEY,
  Y06275: IAPTIDs.NORFOLK_WAVENEY,
  Y06350: IAPTIDs.NORFOLK_WAVENEY,
  Y06351: IAPTIDs.NORFOLK_WAVENEY,
  Y06382: IAPTIDs.NORFOLK_WAVENEY,
  Y06383: IAPTIDs.NORFOLK_WAVENEY,
  Y06384: IAPTIDs.NORFOLK_WAVENEY,
  Y06385: IAPTIDs.NORFOLK_WAVENEY,
  Y06386: IAPTIDs.NORFOLK_WAVENEY,
  Y06387: IAPTIDs.NORFOLK_WAVENEY,
  Y06394: IAPTIDs.NORFOLK_WAVENEY,
  Y06410: IAPTIDs.NORFOLK_WAVENEY,
  Y06411: IAPTIDs.NORFOLK_WAVENEY,
  Y06412: IAPTIDs.NORFOLK_WAVENEY,
  Y06413: IAPTIDs.NORFOLK_WAVENEY,
  Y06802: IAPTIDs.NORFOLK_WAVENEY
}
