import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { AssessmentIAPTScriptStateSchema } from "./AssessmentIAPTScript"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import type { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import { AssessmentWithADSMScript } from "./AssessmentWithADSMScript"

interface State extends AssessmentIAPTScriptState {
  sleepTherapyInterest?: boolean
}
export type AssessmentTrentPTSState = State

export const AssessmentTrentPTSStateSchema = AssessmentIAPTScriptStateSchema.extend({
  sleepTherapyInterest: z.boolean().optional()
})

export class AssessmentTrentPTSScript extends AssessmentWithADSMScript {
  readonly name: string = "AssessmentTrentPTSScript"

  /** Script Steps */

  @step
  askAreYouInterestedInSleepTherapy(_d: IStepData<State>): IStepResult {
    return {
      body: "One of our newest treatment options is an online therapy group for anyone who would describe sleep or insomnia as their main problem. Is this something you would be interested in?",
      prompt: {
        id: this.getPromptId("askAreYouInterestedInSleepTherapy"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAreYouInterestedInSleepTherapy"
      },
      nextStep: this.handleAreYouInterestedInSleepTherapy
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.sleepTherapyInterest = d.response
  })
  async handleAreYouInterestedInSleepTherapy(d: IStepData<State, boolean>): Promise<IStepResult> {
    await this.referralStore.updateReferral({
      sleepTherapyInterest: d.state.sleepTherapyInterest
    })
    return { nextStep: this.sayThatsEverything }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return AssessmentTrentPTSStateSchema
  }

  async onHandleIAPTMedication(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askAreYouInterestedInSleepTherapy }
  }
}

/* istanbul ignore next */
export default class AssessmentTrentPTSDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentTrentPTS
  readonly name: string = "AssessmentTrentPTSDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentTrentPTSDialogue.id, new AssessmentTrentPTSScript(), state, snapshot)
  }
}
