import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import TreatmentOptionsScript from "./TreatmentOptionsScript"
import type { TreatmentOptionsIAPTScriptState } from "./TreatmentOptionsIAPTDialogue"

type State = TreatmentOptionsIAPTScriptState

export class TreatmentOptionsDemoPrivateProvidersScript extends TreatmentOptionsScript {
  readonly name: string = "TreatmentOptionsDemoPrivateProvidersScript"
}

/* istanbul ignore next */
export default class TreatmentOptionsDemoPrivateProvidersDialogue extends Dialogue<State> {
  static id = DialogueIDs.TreatmentOptionsDemoPrivateProviders
  readonly name: string = "TreatmentOptionsDemoPrivateProvidersDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      TreatmentOptionsDemoPrivateProvidersDialogue.id,
      new TreatmentOptionsDemoPrivateProvidersScript(),
      state,
      snapshot
    )
  }
}
