import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"
import { EligibilityCheckIAPTScript } from "./EligibilityCheckIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { WellbeingHubUserTypes } from "../../../models/Constants"

type SEEKING_SUPPORT_FOR = "myself" | "over16" | "under16"

const PRONOUNS_MAP: Record<WellbeingHubUserTypes, string[]> = {
  individual: ["you're", "You can", "you can"],
  managerIndividual: ["they're", "They can", "they can"],
  managerTeam: ["they're", "They can", "they can"]
}

interface State extends EligibilityCheckIAPTState {
  seekingSupportFor?: SEEKING_SUPPORT_FOR
  isHealthOrSocialWorker?: boolean
  individualOrTeam?: "team" | "individual"
}

export type EligibilityCheckGMHubScriptState = State

export class EligibilityCheckGMHubScript extends EligibilityCheckIAPTScript {
  readonly name: string = "EligibilityCheckGMHubScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askAreYouSeekingSupportForYourSelf }
  }

  @step.logState
  askAreYouSeekingSupportForYourSelf(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you seeking wellbeing support for yourself or someone else?",
      prompt: {
        id: this.getPromptId("askAreYouSeekingSupportForYourSelf"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Myself", value: "myself" },
          { body: "Someone else (over 16)", value: "over16" },
          { body: "Someone else (under 16)", value: "under16" }
        ]
      },
      nextStep: this.handleAreYouSeekingSupportForYourSelf
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, SEEKING_SUPPORT_FOR>, script: EligibilityCheckGMHubScript) => {
      d.state.seekingSupportFor = d.response
      script.referralStore.setCustomField<State>("seekingSupportFor", d.response)
    }
  )
  handleAreYouSeekingSupportForYourSelf(d: IStepData<State, SEEKING_SUPPORT_FOR>): IStepResult {
    if (d.response === "under16") {
      this.wellbeingHubStore.setWellbeingHubUserType("managerIndividual")
      return { nextStep: this.askFamilyMemberOrWorksInHealthCare }
    }
    if (d.response === "over16") {
      return { nextStep: this.askIndividualOrTeam }
    }
    return { nextStep: this.askAreYouAHealthOrSocialWorker }
  }

  @step.logState
  askFamilyMemberOrWorksInHealthCare(_d: IStepData<State>): IStepResult {
    return {
      body: "Is the young person you are seeking support for a close family member of someone who works in health or care?",
      prompt: {
        id: this.getPromptId("askFamilyMemberOrWorksInHealthCare"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleFamilyMemberOrWorksInHealthCare
    }
  }

  @step.logState
  handleFamilyMemberOrWorksInHealthCare(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.sayEligibleForManualSelfReferral }
    }
    return { nextStep: this.sayNotEligible }
  }

  @step.logState
  askIndividualOrTeam(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you looking for wellbeing support for an individual or a team?",
      prompt: {
        id: this.getPromptId("askIndividualOrTeam"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: false,
        choices: [
          { body: "Individual in my team /organisation", value: "individual" },
          { body: "Team in my organisation", value: "team" }
        ]
      },
      nextStep: this.handleIndividualOrTeam
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, "team" | "individual">, script: EligibilityCheckGMHubScript) => {
      d.state.individualOrTeam = d.response
      script.referralStore.setCustomField<State>("seekingSupportFor", d.response)
    }
  )
  handleIndividualOrTeam(d: IStepData<State, "team" | "individual">): IStepResult {
    if (d.response === "team") {
      return { nextStep: this.goToSelfReferralManagerTeam }
    }
    return { nextStep: this.goToSelfReferralManagerIndividual }
  }

  @step.logState
  sayEligibleForManualSelfReferral(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const phoneNumbers = this.rootStore.configStore.organisationPhoneNumbers
    const iaptName = this.getIAPTName(d.state) || organisationName
    const userType = this.wellbeingHubStore.userType
    const pronouns = PRONOUNS_MAP[userType!]

    // TODO: Set this in referral form or config file
    const url = "https://www.penninecare.nhs.uk/c19screening"

    return {
      body: [
        `Thanks. Good news - ${pronouns[0]} eligible for free support for young people from ${iaptName}`,
        `The links to refer someone else under 16 years old are [here](${url})`,
        "For children aged between 14 and 15 or 8-13, you can find the related questionnaires on the left of the screen",
        "They'll need a parent or carer to help them",
        `Call on ${phoneNumbers} or [email](GM.help@nhs.net) the hub directly to discuss needs of children under 8`
      ],
      prompt: {
        id: this.getPromptId("sayEligibleForManualSelfReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "I understand" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayYoureEligible(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const userType = this.wellbeingHubStore.userType
    const pronouns = PRONOUNS_MAP[userType!]
    return {
      body: d.state.iaptManuallySelected
        ? `Thanks`
        : `Thanks. Good news - ${pronouns[0]} eligible for free support from ${iaptName}`,
      nextStep: this.end
    }
  }

  @step.logState
  askAreYouAHealthOrSocialWorker(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you a health or social care staff member living or working in the Greater Manchester area?",
      prompt: {
        id: this.getPromptId("askAreYouAHealthOrSocialWorker"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleAreYouAHealthOrSocialWorker
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckGMHubScript) => {
    d.state.isHealthOrSocialWorker = d.response
    script.referralStore.setCustomField<State>("seekingSupportFor", d.response)
  })
  handleAreYouAHealthOrSocialWorker(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.goToSelfReferralIndividual }
    }
    return { nextStep: this.askWorkDetails }
  }

  @step.logState
  askWorkDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Do any of the following apply to you?",
      prompt: {
        id: this.getPromptId("askWorkDetails"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "I work at the Critical Care Unit in Macclesfield", value: true },
          {
            body: "I'm a close family member of a health/social care worker who lives or works in the Greater Manchester area",
            value: true
          },
          {
            body: "I'm retired and used to work for the NHS/Social Care sector in Greater Manchester at some point since March 2020",
            value: true
          },
          { body: "I work for the North West Ambulance Service", value: true },
          { body: "I work at Nightingale Hospital North West", value: true },
          { body: "None of the above", value: false }
        ]
      },
      nextStep: this.handleWorkDetails
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: EligibilityCheckGMHubScript) => {
    d.state.isHealthOrSocialWorker = d.response
    script.referralStore.setCustomField<State>("seekingSupportFor", d.response)
  })
  handleWorkDetails(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.goToSelfReferralIndividual }
    }
    return { nextStep: this.sayNotEligible }
  }

  @step.logState
  sayNotEligible(_d: IStepData<State>): IStepResult {
    const userType = this.wellbeingHubStore.userType
    const body =
      !userType || userType === "individual"
        ? [
            `I'm sorry but it seems that you are not eligible for a self referral`,
            "You can visit the following links for more information on support for your case",
            "[Support for Residents and Vulnerable People](https://www.greatermanchester-ca.gov.uk/what-we-do/health/coronavirus/support-for-residents-and-vulnerable-people/)",
            "[Find a Psychological Therapies Service](https://www.nhs.uk/service-search/find-a-psychological-therapies-service/)",
            "You can also contact us by \nPhone: [03330 095 071](tel:03330095071) \nEmail: GM.help@nhs.net"
          ]
        : [
            `I'm sorry but it seems that they are not eligible for a self referral`,
            "You can visit the following links for more information on support for their case",
            "[Support for Residents and Vulnerable People](https://www.greatermanchester-ca.gov.uk/what-we-do/health/coronavirus/support-for-residents-and-vulnerable-people/)",
            "[Find a Psychological Therapies Service](https://www.nhs.uk/service-search/find-a-psychological-therapies-service/)",
            "You can also contact us by \nPhone: [03330 095 071](tel:03330095071) \nEmail: GM.help@nhs.net"
          ]

    return {
      body,
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  goToSelfReferralManagerTeam(d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("managerTeam")
    return {
      nextStep: this.end
    }
  }

  @step.logState
  goToSelfReferralManagerIndividual(d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("managerIndividual")
    return {
      nextStep: this.end
    }
  }

  @step.logState
  goToSelfReferralIndividual(d: IStepData<State>): IStepResult {
    this.wellbeingHubStore.setWellbeingHubUserType("individual")
    return {
      nextStep: this.end
    }
  }

  @step.logState
  checkAgeThresholds(d: IStepData<State>): IStepResult {
    const age = this.getUserAge(d.state)
    const ageThreshold = this.rootStore.configStore.ageThreshold
    if (Number(ageThreshold) > age) {
      this.setUnderAged(d.state, true)
      return { nextStep: this.checkEligibility }
    }
    d.state.isUnderAged = undefined
    d.state.isEligible = undefined
    d.state.signPostToManualReferral = undefined
    return { nextStep: this.startEligibilityCheck }
  }

  @step
  sayINeedToAskSomeDetails(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `In order to create a referral to ${organisationName}, I just need to confirm a few details with you`,
        "You can re-type your answer at any point by clicking the 'Undo' button next to the message you want to change"
      ],
      nextStep: this.askBirthday
    }
  }
}

export default class EligibilityCheckGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckGMHub
  readonly name: string = "EligibilityCheckGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckGMHubDialogue.id, new EligibilityCheckGMHubScript(), state, snapshot)
  }
}
