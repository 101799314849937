import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskLevelReason } from "../../../models/Constants"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwayTrentPTSScriptState = State

export class RiskPathwayTrentPTSScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayTrentPTSScript"

  /** Script Steps */

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have ways or means in which you can keep yourself safe until your referral is reviewed (in the next 72 hours)?",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Do you have ways or means in which you can keep yourself safe until your referral is reviewed (in the next 72 hours)?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canKeepSelfSafe = d.response
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    let body: string | undefined = undefined
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.PHQ9_Q9_CANT_KEEP_SAFE)
      const name = this.getName(d.state)
      body = `Very sorry to hear this is the case, ${name}`
    }
    return { body, nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayINeedToAskAFewQuestions(_d: IStepData<State>): IStepResult {
    return {
      body: "I need to ask you a few additional tricky questions so that we can arrange the most appropriate help for you",
      prompt: {
        id: this.getPromptId("sayINeedToAskAFewQuestions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "Sure" }]
      },
      nextStep: this.askInDangerFromSomeoneElse
    }
  }

  @step.logState
  askInDangerFromSomeoneElse(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you consider yourself at imminent danger from any other person?",
      prompt: {
        id: this.getPromptId("askInDangerFromSomeoneElse"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleInDangerFromSomeoneElse
    }
  }

  @step.logState
  @step.saveResponse<State>(
    "11",
    "Do you consider yourself at imminent danger from any other person?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleInDangerFromSomeoneElse(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askActionToEnsureSafety }
  }

  @step.logState
  askActionToEnsureSafety(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you taken action to ensure your safety at this time?",
      prompt: {
        id: this.getPromptId("askActionToEnsureSafety"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleActionToEnsureSafety
    }
  }

  @step.logState
  @step.saveResponse<State>(
    "12",
    "Have you taken action to ensure your safety at this time?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleActionToEnsureSafety(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askSuicidalThoughts }
  }

  @step.logState
  askSuicidalThoughts(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you having thoughts regarding ending your life?",
      prompt: {
        id: this.getPromptId("askSuicidalThoughts"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleSuicidalThoughts
    }
  }

  @step.logState
  @step.saveResponse<State>(
    "13",
    "Are you having thoughts regarding ending your life?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleSuicidalThoughts(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askSuicideAttemptRecently }
  }

  @step.logState
  askSuicideAttemptRecently(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you made an attempt to end your life in the last 7 days?",
      prompt: {
        id: this.getPromptId("askSuicideAttemptRecently"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleSuicideAttemptRecently
    }
  }

  @step.logState
  @step.saveResponse<State>(
    "14a",
    "Have you made an attempt to end your life in the last 7 days?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleSuicideAttemptRecently(d: IStepData<State, boolean>): IStepResult {
    if (d.response) return { nextStep: this.askMedicalAttentionForRecentSuicidalAttempt }
    return { nextStep: this.askSuicideAttempt }
  }

  @step.logState
  askMedicalAttentionForRecentSuicidalAttempt(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you sought medical attention regarding this?",
      prompt: {
        id: this.getPromptId("askMedicalAttentionForRecentSuicidalAttempt"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleMedicalAttentionForRecentSuicidalAttempt
    }
  }

  @step.logState
  @step.saveResponse<State>(
    "14b",
    "Have you sought medical attention regarding this?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleMedicalAttentionForRecentSuicidalAttempt(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askSuicideAttempt }
  }

  @step.logState
  askSuicideAttempt(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you ever made an attempt to end your life?",
      prompt: {
        id: this.getPromptId("askSuicideAttempt"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleSuicideAttempt
    }
  }

  @step.logState
  @step.saveResponse<State>(
    "15a",
    "Have you ever made an attempt to end your life?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleSuicideAttempt(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askMedicalAttentionForSuicidalAttempt }
    }
    return { nextStep: this.askMoreInfo }
  }

  @step.logState
  askMedicalAttentionForSuicidalAttempt(_d: IStepData<State>): IStepResult {
    return {
      body: "Did you seek medical attention regarding this?",
      prompt: {
        id: this.getPromptId("askMedicalAttentionForSuicidalAttempt"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleMedicalAttentionForSuicidalAttempt
    }
  }

  @step.logState
  @step.saveResponse<State>(
    "15b",
    "Did you seek medical attention regarding this?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  handleMedicalAttentionForSuicidalAttempt(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askMoreInfo }
  }

  @step.logState
  askMoreInfo(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const iaptName = this.getIAPTName(d.state) ?? this.rootStore.configStore.organisationName
    return {
      body: [
        `Thanks for sharing ${name}`,
        "I realise these questions are not easy",
        `I would really appreciate if you could give any additional information regarding the answers you gave me that you think ${iaptName} should know`
      ],
      prompt: {
        id: this.getPromptId("askMoreInfo"),
        trackResponse: false,
        type: "text",
        cancelLabel: "Skip",
        cancelIsEmptySubmit: true,
        disableCrisis: true
      },
      nextStep: this.handleMoreInfo
    }
  }

  @step.logState
  @step.saveResponse<State>(
    "16",
    "I would really appreciate if you could give any additional information regarding the answers you gave me",
    "riskPathwayResponses"
  )
  handleMoreInfo(d: IStepData<State, string>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thanks ${name}, I made a note of that`,
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return {
      nextStep: state.canKeepSelfSafe //
        ? this.end
        : this.sayINeedToAskAFewQuestions
    }
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns='http://www.w3.org/1999/xhtml' lang=''>
  <head>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
    <meta name='viewport' content='width=device-width, initial-scale=1.0' />
    <title>Limbic Therapy Assistant | Keeping Safe</title>
    ${this.getEmailHTMLStyle()}
    <!--[if IEMobile 7]>
      <style type="text/css">
        /* Targeting Windows Mobile */
      </style>
    <![endif]-->
    <!--[if gte mso 9]>
      <style>
        /* Target Outlook 2007 and 2010 */
      </style>
    <![endif]-->
  </head>

  <body>
    <table cellpadding='0' cellspacing='0' border='0' id='backgroundTable'>
      <tr>
        <td>
          <table cellpadding='0' cellspacing='0' border='0' id='contentTable'>
            <tr>
              <td>
                <img
                  class='image_fix'
                  src='${logo}'
                  alt='${title}'
                  title='${title}'
                  width='x'
                  height='x'
                /><br /><br />
              </td>
            </tr>
            <tr>
              <td>
                <h1>${title}</h1>
                <h1>Stay safe message!</h1>
                You have made a self-referral today to our service and we will process this as soon as possible. 
              </td>
            </tr>
            <tr>
              <td>
                <br />
                We will be in contact very soon (by email, text or phone), usually within 48-72 hours. Your appointment will be arranged as soon as possible.
              </td>
            </tr>
            <tr>
              <td>
                <br />
                If you feel at immediate risk of harm, then please seek help NOW by calling 999 (emergency) or 111 for less urgent medical advice. You can also ring these support lines:
                <br />
                Derbyshire Mental Health Helpline: <span class='blue'>0800 028 0077</span>
                <br />
                Nottingham Mental Health Support line: <span class='blue'>0808 196 3779</span>
              </td>
            </tr>
            <tr>
              <td>
                <br />
                Talk with a friend or family member and create a safety plan. Use this website to help you <a href='https://stayingsafe.net'><span class='blue'>Staying Safe</span></a>
              </td>
            </tr>
            <tr>
              <td>
                <h3>
                  <span class='red'
                    >Trent PTS are unable to offer crisis support or urgent intervention. We offer
                    routine appointments for psychological therapy.</span
                  >
                </h3>
              </td>
            </tr>
            <tr>
              <td>
                <br />
                Further help in a crisis:
                <a href='https://www.trentpts.co.uk/emergency-help-links'
                  ><span class='blue'>https://www.trentpts.co.uk/emergency-help-links</span></a
                >
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <h1><i>We will be in touch and can offer you help and guidance</i></h1>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>
    `
  }
}

/* istanbul ignore next */
export default class RiskPathwayTrentPTSDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayTrentPTS
  readonly name: string = "RiskPathwayTrentPTSDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayTrentPTSDialogue.id, new RiskPathwayTrentPTSScript(), state, snapshot)
  }
}
