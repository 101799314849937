import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import sendRiskEmail from "../../../backend/api/sendRiskEmail"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

const HEALIX_RISK_EMAILS =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? ["healix@mindright.co.uk"]
    : ["andreas@limbic.ai", "john@limbic.ai"]

type State = CrisisScriptState

export class CrisisHealixScript extends CrisisScript {
  readonly name: string = "CrisisHealixScript"

  /** Script Steps */
  @step
  async sayInstructions(d: IStepData<State>): Promise<IStepResult> {
    try {
      const dutyEmails = HEALIX_RISK_EMAILS || []
      const text = this.createReferralEmail(d.state, true)
      await sendRiskEmail({
        to: dutyEmails,
        text
      })
    } catch (e) {
      this.logException(e, "Crisis sayInstructions send email")
    }
    return {
      body: [
        "In the meantime, if you feel that you or someone you know is in danger, please call 999 immediately",
        "Further support is also provided by the Samaritans, available anytime by calling: 116 123"
      ],
      prompt: {
        id: this.getPromptId("sayInstructions"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }, { body: "I understand" }]
      },
      nextStep: this.handleSayInstructions
    }
  }
}

/* istanbul ignore next */
export default class CrisisHealixDialogue extends AdHocDialogue<State, CrisisHealixScript> {
  static id = DialogueIDs.CrisisHealix
  readonly name: string = "CrisisHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisHealixDialogue.id, new CrisisHealixScript(), state, snapshot)
  }
}
