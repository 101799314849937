import moment from "moment"
import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralScript, {
  SelfReferralScriptState,
  SelfReferralScriptStateSchema
} from "./SelfReferralScript"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import invariant from "../../../utils/invariant"
import { step } from "../../../backend/chatbot/decorators/step"
import { createDynamicLink } from "../../../backend/api/createDynamicLink"
import sendEmail from "../../../backend/api/sendEmail"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { ReferralPayload } from "@limbic/types"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

interface State extends SelfReferralScriptState {
  hideEarlierYouSaid?: boolean
  mainIssue?: string
}
export type SelfReferralDemoPrivateProvidersScriptState = State

export const SelfReferralDemoPrivateProvidersScriptStateSchema =
  SelfReferralScriptStateSchema.extend({
    hideEarlierYouSaid: z.boolean().optional(),
    mainIssue: z.string().optional()
  })

export class SelfReferralDemoPrivateProvidersScript extends SelfReferralScript {
  readonly name: string = "SelfReferralDemoPrivateProvidersScript"

  /** Script Steps */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0 })
  sayIntro(d: IStepData<State>): IStepResult {
    const isDataAvailable =
      this.referralStore.getCustomField("address") &&
      this.referralStore.getCustomField("dateOfBirth")

    if (isDataAvailable) {
      d.state.address = this.referralStore.getCustomField("address")
      /** birthday is being handled in getReferralPayload */
    }

    this.timeEvent(this.name)
    return {
      body: `I'm now going to add you as a new referral`,
      prompt: {
        id: this.getPromptId("I'll add your referral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Great" }, { body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: isDataAvailable ? this.doReferralSubmission : this.askWannaDoSelfReferral
    }
  }

  @step
  askWannaDoSelfReferral(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "There are just a few more details I need from you",
        "This should take no more than 2 minutes"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoSelfReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Sure" }, { body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.startSelfReferralPart1
    }
  }

  @step.logState
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    return { nextStep: this.askPostCodeForAddressLookup }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    return {
      body: [
        "And that's everything",
        "Congratulations on taking this important step towards better mental health!"
      ],
      prompt: {
        id: this.getPromptId("sayReferralSucceeded"),
        type: "inlinePicker",
        choices: [{ body: "What happens next?" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.end
    }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const phoneNumber = this.rootStore.configStore.organisationGenericPhoneNumber

    return {
      body: [
        "Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data",
        "I've notified my creators of this issue",
        `If you don't wish to wait, you can call the service on this number: ${phoneNumber}`
      ],
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralDemoPrivateProvidersScriptStateSchema
  }

  async onHandlePostCodeForAddressLookup(state: State): Promise<IStepResult> {
    state.hideEarlierYouSaid = true
    return { nextStep: this.askSelectAddressFromPostCode }
  }

  async onHandleSelectAddressFromPostCode(_state: State): Promise<IStepResult> {
    return { nextStep: this.askSexuality }
  }

  async onCheckFullAddress(_state: State): Promise<IStepResult> {
    return { nextStep: this.askSexuality }
  }

  async onHandleSexuality(state: State): Promise<IStepResult> {
    return { nextStep: this.doReferralSubmission }
  }

  getSexualities(state: State): string[] {
    return [
      "Heterosexual",
      "Female homosexual",
      "Male homosexual",
      "Bisexual",
      "Sexually attracted to neither male nor female sex",
      "Not stated (Person asked but declined to provide a response)",
      "Unknown",
      "Person asked and does not know or is not sure"
    ]
  }

  getSexualityPayload(state: State): ReferralPayload["sexuality"] {
    const map: Record<string, ReferralPayload["sexuality"]> = {
      Heterosexual: "HETEROSEXUAL",
      "Female homosexual": "HOMOSEXUAL_FEMALE",
      "Male homosexual": "HOMOSEXUAL_MALE",
      Bisexual: "BISEXUAL",
      "Sexually attracted to neither male nor female sex": "ASEXUAL",
      "Not stated (Person asked but declined to provide a response)": "NOT_ANSWERED",
      Unknown: "UNKNOWN",
      "Person asked and does not know or is not sure": "UNSURE"
    }
    return map[state.sexuality!] ?? "NOT_ANSWERED"
  }

  async getReferralPayload(state: State): Promise<ReferralPayload> {
    const instanceID = this.referralStore.instanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      problemInOwnWords: state.mainIssue,
      dob:
        this.referralStore.getCustomField("dateOfBirth") ||
        moment(state.birthday).format("DD/MM/YYYY"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      email: state.email,
      consentEmail: !!state.email,
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      title: state.title,
      sexuality: this.getSexualityPayload(state),
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      questionnaires: this.getQuestionnairesPayload(state),
      clinicalNotes: this.referralStore.clinicalNotes,
      clinicalFlags: this.clinicalStore.flags,
      problemDescriptorPrimary: this.clinicalStore.primaryProblems,
      problemDescriptorSecondary: this.clinicalStore.secondaryProblems,
      consentResearch: state.consentResearch
    }
  }

  async onReferralFinished(state: State): Promise<void> {
    await this.onCreateDeepLink(state)
    if (state.careSignupURL) {
      await this.referralStore.updateReferral({ careSignupURL: state.careSignupURL })
    }

    try {
      // prettier-ignore
      const to = new URLSearchParams().get("submitTo") ?? "john@limbic.ai, ross@limbic.ai, syed@limbic.ai"
      const cc = new URLSearchParams().get("submitToCC")
      const bcc = new URLSearchParams().get("submitToBCC")
      const text = this.createReferralEmail(state)
      await sendEmail({
        subject: "Referral Submitted",
        to: [to],
        cc: cc?.length ? [cc] : undefined, // This needs to be undefined in case of empty array because mailgun is stupid
        bcc: bcc?.length ? [bcc] : undefined, // This needs to be undefined in case of empty array because mailgun is stupid
        text
      })
    } catch (e) {
      this.logException(e, "onReferralFinished -> sendEmail")
    }
  }

  async onCreateDeepLink(state: State): Promise<void> {
    try {
      const signupCode = this.referralStore.signupCode!
      const deepLinkData = {
        author: "access",
        signupCode,
        slug: signupCode
      }
      state.careSignupURL = await createDynamicLink(deepLinkData)
    } catch (e) {
      this.logException(e, "submitReferralForm -> createDynamicLink")
    }
  }

  getPersonalInfoHTML(state: State): string {
    const org = super.getPersonalInfoHTML(state)
    const mainIssue = `<b>What brought you here:</b> ${state.mainIssue}`
    return `${org}\n${mainIssue}`
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onRiskReferralFinished(state: State): Promise<void> {}
}

/* istanbul ignore next */
export default class SelfReferralDemoPrivateProvidersDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralDemoPrivateProviders
  readonly name: string = "SelfReferralDemoPrivateProvidersDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralDemoPrivateProvidersDialogue.id,
      new SelfReferralDemoPrivateProvidersScript(),
      state,
      snapshot
    )
  }
}
