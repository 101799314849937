import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { MHMCrisisPhoneMappedText } from "../../../config/apiKeysMap"
import { IAPTIDs } from "../../../models/IIAPTService"

type State = RiskPathwayScriptState

export type RiskPathwayMHMScriptState = State

export class RiskPathwayMHMScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayMHMScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep yourself, and any dependants in your care, safe until your appointment? This could be up 6 weeks, although likely to be sooner",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment? This could be up 6 weeks, although likely to be sooner",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayMHMScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
      // Note: Ben has adviced that we should not be sending a risk email
      // instead a duty tag should be added
      return { nextStep: this.informRiskToService }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  async informRiskToService(d: IStepData<State>): Promise<IStepResult> {
    await this.sendRiskEmailToService(d.state)
    return { nextStep: this.sayCrisis }
  }

  @step.logState
  async sayCrisis(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const specificPhoneNumber = d.state.iapt?.id ? [MHMCrisisPhoneMappedText[d.state.iapt?.id]] : ""

    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        `However, ${organisationName} does not provide urgent care`,
        "If you need urgent support, please dial NHS 111 and select Option 2",
        "In medical emergency and life threatening situations only, please dial 999 or attend your local A&E department",
        "Other helplines available:",
        "You can contact Samaritans 24 hours a day, 365 days a year",
        "You can call 116 123 (free from any phone) or email jo@samaritans.org",
        "If you would prefer not to talk but want some mental health support, you could text SHOUT to 85258",
        "[Shout](https://giveusashout.org/) offers a confidential 24/7 text service providing support if you are in need of immediate help",
        ...specificPhoneNumber,
        `We’ll continue with the referral, however ${organisationName} does not provide urgent care. If you require immediate help, please contact one of the numbers listed above`
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  getKeepingSafeAttachmentUrls(state: State): string[] {
    const iaptID = state.iapt?.id
    const urls = {
      [IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON]:
        "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/mhm/NHS+Talking+Therapies+Sefton.pdf",
      [IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND]:
        "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/mhm/NHS+Talking+Therapies+Northumberland.pdf",
      [IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON]:
        "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/mhm/NHS+Talking+Therapies+Warrington.pdf"
    }
    return [urls[iaptID!]]
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang='en'>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${title}</title>
    ${this.getEmailHTMLStyle()}
    <!--[if IEMobile 7]>
	<style type="text/css">
		/* Targeting Windows Mobile */
	</style>
	<![endif]-->
    <!--[if gte mso 9]>
	<style>
		/* Target Outlook 2007 and 2010 */
	</style>
	<![endif]-->
  </head>

  <body>
    <table cellpadding="0" cellspacing="0" border="0" id="backgroundTable">
      <tr>
        <td>
          <table cellpadding="0" cellspacing="0" border="0" id="contentTable">
            <tr>
              <td><img style='max-height: 200px; display: block; margin-left: auto; margin-right: auto;' class="image_fix" src="${logo}" alt="${title}" title="${title}" width="x" height="x" /><br /><br /></td>
            </tr>
            <tr>
              <td>
                You have made a self-referral today to NHS Talking Therapies. In one of your answers you said you were experiencing some thoughts of self-harm or taking your own life.
              </td>
            </tr>
            <tr>
              <td>
                <br />
                As you are waiting to access our service, if you feel you may act on suicidal thoughts, cannot keep yourself or are a risk to others around you, please call 999 (available 24/7).
              </td>
            </tr>
            <tr>
              <td>
                <br />
                For more info on keeping safe please have a look at the file attached in this email
              </td>
            </tr>
            <tr>
              <td>
                <br /><br />
                <em>NHS Talking Therapies Team</em>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>`
  }
}

/* istanbul ignore next */
export default class RiskPathwayMHMDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayMHM
  readonly name: string = "RiskPathwayMHMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayMHMDialogue.id, new RiskPathwayMHMScript(), state, snapshot)
  }
}
