import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import { ProblemCategories } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

// prettier-ignore
const anxietyString = "An NHS self-help guide for anxiety [here](https://web.ntw.nhs.uk/selfhelp/leaflets/Anxiety.pdf)"
// prettier-ignore
const depressionString = "A video on how to break the cycle of low mood [here](https://www.youtube.com/watch?v=wXCeYgHLJdo)"
// prettier-ignore
const sleepString = "A video with tips for better sleep [here](https://www.youtube.com/watch?v=nysjq8VIwI8)"
// prettier-ignore
const ptsdString = "A website with information about trauma and how it affects mental health [here](https://www.mind.org.uk/information-support/types-of-mental-health-problems/trauma/effects-of-trauma/)"

interface State extends BaseScriptState {
  bookMentalHealthProfessional?: boolean
  bookMentalHealthProfessionalFailed?: boolean
  userAborted?: boolean
  isBelowCaseness?: boolean
}

export type WellbeingHealixScriptState = State

export const WellbeingHealixScriptStateSchema = BaseScriptStateSchema.extend({
  bookMentalHealthProfessional: z.boolean().optional(),
  bookMentalHealthProfessionalFailed: z.boolean().optional(),
  userAborted: z.boolean().optional(),
  isBelowCaseness: z.boolean().optional()
})

export class WellbeingHealixScript extends BaseScript<State> {
  readonly name: string = "WellbeingHealixScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, I think you would benefit from some online materials about wellbeing`,
        "I've selected some specific resources based on the answers to your questions"
      ],
      nextStep: this.sayResources
    }
  }

  @step.logState
  sayResources(d: IStepData<State>): IStepResult {
    const body: string[] = []
    const sleep = this.getSleepProblems(d.state)
    const depression = this.getDepressionProblems(d.state)
    const ptsd = this.getTraumaProblems()
    const anxiety = this.getAnxietyProblems(d.state)
    const belowCaseness = !depression && !anxiety

    ;(anxiety || belowCaseness) && body.push(anxietyString)
    ;(depression || belowCaseness) && body.push(depressionString)
    sleep && body.push(sleepString)
    ptsd && body.push(ptsdString)
    return {
      body,
      prompt: {
        id: this.getPromptId("sayResources"),
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: false },
          { body: "Thanks!", value: true }
        ]
      },
      nextStep: this.handleResources
    }
  }

  @step.logState
  handleResources(d: IStepData<State, boolean>): IStepResult {
    const body = d.response ? "You're welcome ☺️" : ""
    return { body, nextStep: this.end }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return WellbeingHealixScriptStateSchema
  }

  getSleepProblems(state: State): boolean {
    const phq9Responses = state.phq9Responses
    const q3Points = phq9Responses?.[2]?.points ?? 0
    return q3Points > 0
  }

  getDepressionProblems(state: State): boolean {
    const phq9Score = this.getPHQ9Total(state) ?? 0
    return phq9Score >= 10
  }

  getTraumaProblems(): boolean {
    const primaries = this.rootStore.clinicalStore.primaryProblems
    const secondaries = this.rootStore.clinicalStore.secondaryProblems
    return [...primaries, secondaries].includes(ProblemCategories.PTSD)
  }

  getAnxietyProblems(state: State): boolean {
    const gad7Score = this.getGAD7Total(state) ?? 0
    return gad7Score >= 8
  }
}

/* istanbul ignore next */
export default class WellbeingHealixDialogue extends Dialogue<State> {
  static id = DialogueIDs.WellbeingHealix
  readonly name: string = "WellbeingHealixDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(WellbeingHealixDialogue.id, new WellbeingHealixScript(), state, snapshot)
  }
}
