// noinspection UnreachableCodeJS

import moment from "moment"
import Logger from "../utils/Logger"
import { init } from "./init"
import countsByInstanceID from "../backend/api/countsByInstanceID"
import type IAppLaunchConfig from "../models/IAppLaunchConfig"

const THRESHOLD = 0

export async function initNotts(setupConfig: IAppLaunchConfig): Promise<void> {
  // they wanted to disable it
  return
  let API_KEY = sessionStorage.getItem("@limbic:notts:api_key")
  if (!API_KEY) {
    try {
      const fromDate = getFromDate()
      const { VHG_NOTTS = 0 } = await countsByInstanceID(fromDate, ["VHG_NOTTS"])
      API_KEY = VHG_NOTTS < THRESHOLD ? "VHG_NOTTS" : "INSIGHT_TALKING_THERAPIES"
    } catch (e) {
      API_KEY = "INSIGHT_TALKING_THERAPIES"
      Logger.getInstance().exception(e, "initNotts -> countsByInstanceID")
    } finally {
      sessionStorage.setItem("@limbic:notts:api_key", API_KEY!)
    }
  }

  const backendInstanceID = getBackendInstanceID(API_KEY!)
  const overrides = { ...setupConfig.overrides, backendInstanceID }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  init({ ...setupConfig, API_KEY, overrides })
}

function getBackendInstanceID(API_KEY?: string): "VHG_NOTTS" | "INSIGHT_NOTTINGHAM_GREATER" {
  return API_KEY === "VHG_NOTTS" ? "VHG_NOTTS" : "INSIGHT_NOTTINGHAM_GREATER"
}

function getFromDate(): string {
  const now = moment()
  const hour = now.get("hour")
  if (hour >= 0 && hour < 8) {
    // After midnight until 08:00 AM we're still counting the
    // referrals of the previous day
    return now.subtract(1, "day").startOf("day").set("hour", 8).toISOString()
  }
  return now.startOf("day").set("hour", 8).toISOString()
}
