import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { ReferralType } from "../../../models/Constants"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayScriptState

export type RiskPathwaySABPScriptState = State

export class RiskPathwaySABPScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwaySABPScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep safe from harm from yourself or harm from others?",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep safe from harm from yourself or harm from others?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwaySABPScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "It may be nothing to worry about",
        "However, your safety is my number one priority. And this is not an emergency response service",
        `I'll make sure to notify ${serviceName} of this in the referral`
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "If you need urgent, life threatening medical help please call [999](tel:999)",
        "Alternatively, you can call the Mental Health Crisis Helpline on [0800 915 4644](tel:08009154644) if it's not a life-threatening situation",
        "I've also sent you some useful material over email. If you don't see this in your inbox, maybe check your spam folder"
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999 and Mental Health Crisis Helpline (0800 915 4644)"
    )
    return {
      body: "Ok, let's carry on with the mental health check in",
      nextStep: this.informRiskToService
    }
  }

  @step.logState
  async informRiskToService(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.informRiskToService(d)
    return { ...result, nextStep: this.closeDialogue }
  }

  /** Generic Handlers */

  async onSaveRiskLevelAndReferralType(state: State): Promise<IStepResult> {
    return { nextStep: !state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  getKeepingSafeAttachmentUrls(_state: State): string[] {
    return ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/sabp/Staying+Safe+In+A+Crisis.pdf"]
  }

  createKeepingSafeLeafletEmail(title: string, logo: string): string {
    return `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns='http://www.w3.org/1999/xhtml' lang=''>
  <head>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
    <meta name='viewport' content='width=device-width, initial-scale=1.0' />
    <title>Limbic Therapy Assistant | Keeping Safe</title>
    ${this.getEmailHTMLStyle()}
    <!--[if IEMobile 7]>
      <style type="text/css">
        /* Targeting Windows Mobile */
      </style>
    <![endif]-->
    <!--[if gte mso 9]>
      <style>
        /* Target Outlook 2007 and 2010 */
      </style>
    <![endif]-->
  </head>

  <body>
    <table cellpadding='0' cellspacing='0' border='0' id='backgroundTable'>
      <tr>
        <td>
          <table cellpadding='0' cellspacing='0' border='0' id='contentTable'>
            <tr>
              <td>
                <img
                  class='image_fix'
                  src='${logo}'
                  alt='${title}'
                  title='${title}'
                  width='x'
                  height='x'
                /><br /><br />
              </td>
            </tr>
            <tr>
              <td>
                <h1>${title}</h1>
                <br />
                <b>Mind Matters Surrey NHS is not an emergency/crisis service so we can't help you with immediate crisis situations.</b>
              </td>
            </tr>
            <tr>
              <td>
                <br />
                Our phone lines and in-boxes are responded to during office hours and there may be occasions when we cannot respond within 24hrs.
              </td>
            </tr>
            <tr>
              <td>
                <br />
                You have made a self-referral today, to our service and we can see from the details you have provided that you may be distressed. If you feel at immediate risk of harm from yourself or others, please seek help IMMEDIATELY by calling 999 (emergency).
                Alternatively, you can call the Mental Health Crisis Helpline on 0800 915 4644, or the numbers on the 'Staying Safe in a Crisis' booklet, if it's not an immediate life threatening situation.
              </td>
            </tr>
            <tr>
              <td>
                <br />
                For more information on keeping safe please have a look at the file attached in this email.
              </td>
            </tr>
            <tr>
              <td>
                <br />
                One of our clinicians will be in touch in due course to discuss your therapy needs.
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <h2 style='text-align: left;'><i>The Mind Matters Team</i></h2>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>
    `
  }

  getCustomReferralType(state: State): string | undefined {
    const currentMHTreatment = this.referralStore.getCustomField("currentMHTreatment")
    const hasCurrentMHTreatment = !!currentMHTreatment?.length
    if (hasCurrentMHTreatment) return "Extended Assessment (User seeing other MH professionals)"
  }

  getReferralTypeForRisk(_state: State): string | undefined {
    if (this.clinicalStore.isHighRisk) return ReferralType.RISK_PATIENT
    if (this.clinicalStore.isModerateRisk) return ReferralType.RISK_PATIENT
  }
}

/* istanbul ignore next */
export default class RiskPathwaySABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwaySABP
  readonly name: string = "RiskPathwaySABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwaySABPDialogue.id, new RiskPathwaySABPScript(), state, snapshot)
  }
}
