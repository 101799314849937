import { action } from "mobx"
import Persistable from "../../models/Persistable"
import { WellbeingHubUserTypes } from "../../models/Constants"

export default class WellbeingHubStore extends Persistable {
  readonly name: string = "WellbeingHubStore"
  userType: WellbeingHubUserTypes

  constructor() {
    super()
    this.userType = this.hydrate("userType") ?? "individual"
  }

  @action
  setWellbeingHubUserType(type: WellbeingHubUserTypes): void {
    this.userType = type
    this.persist("userType", type)
  }

  /** Generic Handlers */

  /** Getters / Setters */
}
