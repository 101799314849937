import AssessmentV2Script, { AssessmentV2ScriptState } from "./AssessmentV2Script"

type State = AssessmentV2ScriptState
export type AssessmentIAPTV2ScriptState = State

export default abstract class AssessmentIAPTV2Script extends AssessmentV2Script {
  /** Generic Handlers */

  handlePHQ9Responses(state: State): void {
    if (!this.referralStore.phq9HasBeenSend) {
      this.sendPHQ9(state)
    }
  }

  handleGAD7Responses(state: State): void {
    this.sendGAD7(state)
  }

  handlePhobiaScaleResponses(state: State): void {
    this.sendPhobiaScale(state)
  }

  handleIAPTEmploymentStatusResponses(state: State): void {
    this.sendIAPTEmploymentStatus(state)
  }

  handleWSASResponses(state: State): void {
    this.sendWSAS(state)
  }

  handleIAPTMedicationResponses(state: State): void {
    this.sendIAPTMedication(state)
  }
}
