import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import {
  RiskPathwayInsightScript,
  RiskPathwayInsightScriptState
} from "./RiskPathwayInsightDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = RiskPathwayInsightScriptState
export type RiskPathwayInsightFollowUpScriptState = State

export class RiskPathwayInsightFollowUpScript extends RiskPathwayInsightScript {
  readonly name: string = "RiskPathwayInsightFollowUpScript"

  /** Script Steps */

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep yourself, and any dependants in your care, safe until your appointment?",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)

    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "However, this is not an emergency response service",
        "There are alternative services that are better placed to support you",
        "If you need urgent, life threatening medical help please call [999](tel:999)",
        "Alternatively, follow this [link](https://www.insightiapt.org/need-urgent-help/) to find out about other services, including your local mental health crisis team that will be able to support you"
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }],
        dataPointsName: "sayCrisis"
      },
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class RiskPathwayInsightFollowUpDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayInsightFollowUp
  readonly name: string = "RiskPathwayInsightFollowUpDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      RiskPathwayInsightFollowUpDialogue.id,
      new RiskPathwayInsightFollowUpScript(),
      state,
      snapshot
    )
  }
}
