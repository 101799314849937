import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { RiskLevelReason } from "../../../models/Constants"
import CrisisDetector from "../../../models/CrisisDetector"

type State = CrisisScriptState
export type CrisisNottinghamshireScriptState = State

export class CrisisNottinghamshireScript extends CrisisScript {
  readonly name: string = "CrisisNottinghamshireScript"

  /** Script Steps */

  @step
  async handleAreYouInCrisis(d: IStepData<State, boolean>): Promise<IStepResult> {
    // TODO: When all services are using the new backend remove this step entirely
    this.clinicalStore.setIsCrisis(d.response)
    this.setCrisisDetectionCorrect(d.state, d.response)

    if (d.response) {
      this.blockUndo(d.state)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CRISIS_DETECTION)
      this.referralStore
        .updateReferral({
          riskLevel: this.clinicalStore.riskLevel,
          riskLevelReason: this.clinicalStore.riskLevelReason,
          triggerWords: this.clinicalStore.triggerWords
        })
        .catch(e => this.logException(e, "handleAreYouInCrisis -> updateReferral"))
      this.updateReferralType(d.state)
    }

    if (!d.response && d.state.disableDetectionIfWrong) {
      CrisisDetector.getInstance().disableCrisisDetectionForNextInput()
    }
    return {
      nextStep: d.response //
        ? this.saySorryToHear
        : this.saySorryForTheMisunderstanding
    }
  }

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "However, this is not an emergency response service",
        `I'll make sure to notify ${iaptName} of this in the referral`
      ],
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "If you need urgent, life threatening medical help please call [999](tel:999)",
        "Alternatively, you can call the Support Crisis Line on [0808 196 3779](tel:0808 196 3779) available 24/7"
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999 and Mental Health Crisis Helpline (0800 915 4644)"
    )
    return {
      body: "Ok, let's carry on with the mental health check in",
      nextStep: this.end
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ],
      nextStep: this.end
    }
  }

  @step
  async theyllCallYou(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.phoneNumber) {
      return { nextStep: this.askPhoneNumber }
    }

    return {
      body: `They'll call you within 72 hours on the number you provided earlier (${d.state.phoneNumber})`,
      prompt: {
        id: this.getPromptId("theyllCallYou"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: true },
          { body: "Change number", value: false }
        ]
      },
      nextStep: this.handleTheyllCallYou
    }
  }
}

/* istanbul ignore next */
export default class CrisisNottinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.CrisisNottinghamshire
  readonly name: string = "CrisisNottinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisNottinghamshireDialogue.id, new CrisisNottinghamshireScript(), state, snapshot)
  }
}
