import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = CrisisScriptState
export type CrisisITalkScriptState = State

export class CrisisITalkScript extends CrisisScript {
  readonly name: string = "CrisisITalkScript"

  /** Script Steps */

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        `However ${organisationName} is not an emergency response service`,
        `I'll make sure to notify ${iaptName} of this in the referral`
      ],
      prompt: {
        id: this.getPromptId("saySorryToHear"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "To get more urgent help, please call NHS 111 and select Option 2",
        "If you need urgent, life threatening medical help please call 999",
        "Alternatively, you can also call Samaritans on 116 123"
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 and select Option 2 and Samaritans"
    )
    return {
      body: "Ok, let's carry on with the mental health check in",
      nextStep: this.end
    }
  }

  @step.logState
  saySorryForTheMisunderstanding(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "My creators have taught me to listen carefully for specific words or phrases",
        "In case you need help",
        "Sorry for the misunderstanding",
        "Ok, let's carry on with the mental health check in"
      ],
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class CrisisITalkDialogue extends AdHocDialogue<State, CrisisITalkScript> {
  static id = DialogueIDs.CrisisITalk
  readonly name: string = "CrisisITalkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisITalkDialogue.id, new CrisisITalkScript(), state, snapshot)
  }
}
