import createSurveyDialogue from "../createSurveyDialogue"
import ISurvey, { SurveyType } from "../../models/ISurvey"
import { DialogueIDs } from "../DialogueIDs"

const SPIN: ISurvey = {
  startingQuestion:
    "Please read each statement and select the option that indicates how much of the statement applied to you over the past week",
  questions: [
    {
      id: "1",
      questionName: "SPIN - step1",
      question: "1. I am afraid of people in authority",
      type: "inline",
      progress: 0.058,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "2",
      questionName: "SPIN - step2",
      question: "2. I am bothered by blushing in front of people",
      type: "inline",
      progress: 0.116,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "3",
      questionName: "SPIN - step3",
      question: "3. Parties and social events scare me",
      type: "inline",
      progress: 0.174,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "4",
      questionName: "SPIN - step4",
      question: "4. I avoid talking to people I don't know",
      type: "inline",
      progress: 0.232,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "5",
      questionName: "SPIN - step5",
      question: "5. Being criticized scares me a lot",
      type: "inline",
      progress: 0.29,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "6",
      questionName: "SPIN - step6",
      question: "6. I avoid doing things or speaking to people for fear of embarrassment",
      type: "inline",
      progress: 0.348,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "7",
      questionName: "SPIN - step7",
      question: "7. Sweating in front of people causes me distress",
      type: "inline",
      progress: 0.406,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "8",
      questionName: "SPIN - step8",
      question: "8. I avoid going to parties",
      type: "inline",
      progress: 0.464,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "9",
      questionName: "SPIN - step9",
      question: "9. I avoid activities in which I am the center of attention",
      type: "inline",
      progress: 0.522,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "10",
      questionName: "SPIN - step10",
      question: "10. Talking to strangers scares me",
      type: "inline",
      progress: 0.58,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "11",
      questionName: "SPIN - step11",
      question: "11. I avoid having to give speeches",
      type: "inline",
      progress: 0.638,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "12",
      questionName: "SPIN - step12",
      question: "12. I would do anything to avoid being criticized",
      type: "inline",
      progress: 0.696,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "13",
      questionName: "SPIN - step13",
      question: "13. Heart palpitations bother me when I am around people",
      type: "inline",
      progress: 0.754,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "14",
      questionName: "SPIN - step14",
      question: "14. I am afraid of doing things when people might be watching",
      type: "inline",
      progress: 0.812,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "15",
      questionName: "SPIN - step15",
      question: "15. Being embarrassed or looking stupid are among my worst fears",
      type: "inline",
      progress: 0.87,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "16",
      questionName: "SPIN - step16",
      question: "16. I avoid speaking to anyone in authority",
      type: "inline",
      progress: 0.928,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    },
    {
      id: "17",
      questionName: "SPIN - step17",
      question: "17. Trembling or shaking in front of others is distressing to me",
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "Not at all" },
        { points: 1, answer: "A little bit" },
        { points: 2, answer: "Somewhat" },
        { points: 3, answer: "Very much" },
        { points: 4, answer: "Extremely" }
      ]
    }
  ]
}

export default createSurveyDialogue(DialogueIDs.SPIN, SPIN, SurveyType.SPIN)
