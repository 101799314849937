import { DiscussionSteps } from "../../models/Constants"

export const stepsOrder = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_INSIGHT = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.ADSMConsent,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_KM_CCG = [
  DiscussionSteps.Intro,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.BookMentalHealthProfessionalKM,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Goodbye
]

export const stepsOrder_GM_HUB = [
  DiscussionSteps.Intro,
  DiscussionSteps.Eligibility,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_HEALIX = [
  DiscussionSteps.Intro,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Goodbye
]

export const stepsOrder_TRENT_PTS = [
  DiscussionSteps.Intro,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_VITALITY = [
  DiscussionSteps.Intro,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.BookAppointment,
  DiscussionSteps.Goodbye
]

export const stepsOrder_DEMO_PRIVATE_PROVIDERS = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.PeaceOfMind,
  DiscussionSteps.Permissions,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.Goodbye
]

export const stepsOrder_Insight_Waitlist = [
  DiscussionSteps.Intro,
  DiscussionSteps.GetName,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.ADSMConsent,
  DiscussionSteps.Assessment,
  DiscussionSteps.Goodbye
]

export const stepsOrder_MHM = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_LINCS = [
  DiscussionSteps.Intro,
  DiscussionSteps.Permissions,
  DiscussionSteps.GetName,
  DiscussionSteps.Eligibility,
  DiscussionSteps.SelfReferralPitch,
  DiscussionSteps.SelfReferral,
  DiscussionSteps.AssessmentPitch,
  DiscussionSteps.Assessment,
  DiscussionSteps.TreatmentOptions,
  DiscussionSteps.Goodbye
]

export const stepsOrder_LIMBIC_WEBSITE = [
  DiscussionSteps.Intro,
  DiscussionSteps.LeadClassification,
  DiscussionSteps.SelfReferral
]
