import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = AssessmentPitchIAPTScriptState

export class AssessmentPitchWellbeingHubScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchWellbeingHubScript"

  /** Script Steps */
  @step.logState
  start(d: IStepData<State>): IStepResult {
    const wellbeingHubUserType = this.wellbeingHubStore.userType
    this.updateReferralType(d.state)
    if (wellbeingHubUserType !== "individual") {
      return {
        nextStep: this.end
      }
    }
    return {
      nextStep: this.sayWeCanSpeedUpReferral
    }
  }

  @step.logState
  sayWeCanSpeedUpReferral(d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure your wellbeing",
        "And I will use the results to match you with the most appropriate therapy",
        "Alternatively, you can stop right here since your referral has already been submitted"
      ],
      prompt: {
        id: this.getPromptId("sayWeCanSpeedUpReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Continue with the free digital mental health assessment", value: true },
          {
            body: "No thanks, I don't wish to take the free digital mental health assessment",
            value: false
          }
        ]
      },
      nextStep: this.handleWeCanSpeedUpReferral
    }
  }

  @step.logState
  async handleWeCanSpeedUpReferral(d: IStepData<State>): Promise<IStepResult> {
    const name = this.getName(d.state)
    if (d.response) {
      return { body: `Good to hear it, ${name}`, nextStep: this.end }
    }
    return { body: `No worries, ${name}`, nextStep: this.goToGoodbye }
  }
}

export default class AssessmentPitchWellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchWellbeingHub
  readonly name: string = "AssessmentPitchWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      AssessmentPitchWellbeingHubDialogue.id,
      new AssessmentPitchWellbeingHubScript(),
      state,
      snapshot
    )
  }
}
