import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IntroductionIAPTScript } from "./IntroductionIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IntroductionScriptState } from "./IntroductionScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

type State = IntroductionScriptState

export class IntroductionGMHubScript extends IntroductionIAPTScript {
  readonly name: string = "IntroductionGMHubScript"

  /** Script Steps */
  @step.logState
  askCanIHelp(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: "Can I help?",
      prompt: {
        id: this.getPromptId("askCanIHelp"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: `Yes, I'd like to refer myself or someone else to ${organisationName}`,
            value: true
          },
          { body: "No, I'm just browsing", value: false }
        ],
        isUndoAble: false
      },
      nextStep: this.handleCanIHelp
    }
  }
}

export default class IntroductionGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionGMHub
  readonly name: string = "IntroductionGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionGMHubDialogue.id, new IntroductionGMHubScript(), state, snapshot)
  }
}
