import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

interface State extends PermissionsScriptState {
  consentDataShareGPAndNHS?: boolean
}
export type PermissionsGMHubScriptState = State

export class PermissionsGMHubScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsGMHubScript"

  /** Script Steps */

  @step.logState
  askResearchConsent(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Limbic is also working with researchers to improve mental health treatment",
        "Are you ok if Limbic uses your data anonymously to support the development of the product and research, which might be used for scientific publications?"
      ],
      prompt: {
        id: this.getPromptId("askResearchConsent"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleResearchConsent
    }
  }

  /** General Handlers */
}

/* istanbul ignore next */
export default class PermissionsGMHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsGMHub
  readonly name: string = "PermissionsGMHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsGMHubDialogue.id, new PermissionsGMHubScript(), state, snapshot)
  }
}
