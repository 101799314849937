import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { DiscussionSteps } from "../../../models/Constants"
import SelfReferralScript, { SelfReferralScriptState } from "./SelfReferralScript"
import { DialogueIDs } from "../../DialogueIDs"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"

interface State extends SelfReferralScriptState {
  problem?: string
  problemCause?: string
  implementationTimeline?: "3months" | "6months" | "year" | "unknown"
  budgetHolder?: boolean
  budgetAvailableThisYear?: boolean
}

export class SelfReferralLimbicScript extends SelfReferralScript {
  readonly name = "SelfReferralLimbicScript"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult {
    return { body: "I'd love to ask a few quick questions.", nextStep: this.askProblem }
  }

  @step
  askProblem(_d: IStepData<State>): IStepResult {
    return {
      body: "What problems are you trying to solve and how can Limbic help?",
      prompt: {
        id: this.getPromptId("askProblem"),
        type: "text"
      },
      nextStep: this.handleProblemWithCrisis
    }
  }

  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.problem = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralLimbicScript) => s.askProblem,
    disableDetectionIfWrong: false
  })
  @step.logState
  handleProblemWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askProblemCause }
  }

  @step
  askProblemCause(_d: IStepData<State>): IStepResult {
    return {
      body: "Can you tell me a little bit about the cause of the issue and its effects if not resolved?",
      prompt: {
        id: this.getPromptId("askProblemCause"),
        type: "text"
      },
      nextStep: this.handleProblemCauseWithCrisis
    }
  }

  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.problemCause = d.response
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralLimbicScript) => s.askProblemCause,
    disableDetectionIfWrong: false
  })
  @step.logState
  handleProblemCauseWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askImplementationTimeline }
  }

  @step
  askImplementationTimeline(_d: IStepData<State>): IStepResult {
    return {
      body: "Is there a timeline for implementation?",
      prompt: {
        id: this.getPromptId("askImplementationTimeline"),
        type: "inlinePicker",
        choices: [
          { body: "0-3 months", value: "3months" },
          { body: "3-6 months", value: "6months" },
          { body: "Within the next year", value: "year" },
          { body: "I don't know", value: "unknown" }
        ]
      },
      nextStep: this.handleImplementationTimeline
    }
  }

  @step
  handleImplementationTimeline(
    d: IStepData<State, "3months" | "6months" | "year" | "unknown">
  ): IStepResult {
    d.state.implementationTimeline = d.response
    return { nextStep: this.askBudgetHolder }
  }

  @step
  askBudgetHolder(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you the budget holder?",
      prompt: {
        id: this.getPromptId("askBudgetHolder"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleBudgetHolder
    }
  }

  @step
  handleBudgetHolder(d: IStepData<State, boolean>): IStepResult {
    d.state.budgetHolder = d.response
    if (d.response) return { nextStep: this.askBudgetAvailability }
    return { nextStep: this.sayThanksForYourResponses }
  }

  @step
  askBudgetAvailability(_d: IStepData<State>): IStepResult {
    return {
      body: "Is the budget available from this year's funding?",
      prompt: {
        id: this.getPromptId("askBudgetAvailability"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleBudgetAvailability
    }
  }

  @step
  handleBudgetAvailability(d: IStepData<State, boolean>): IStepResult {
    d.state.budgetAvailableThisYear = d.response
    return { nextStep: this.sayThanksForYourResponses }
  }

  @step
  sayThanksForYourResponses(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Thank you very much for your responses",
        "Let's make sure we know how to contact you"
      ],
      nextStep: this.goToGetFullName
    }
  }

  @step
  goToGetFullName(d: IStepData<State>): IStepResult {
    const GetFullName = this.discussionStore.getDialogueClass(DiscussionSteps.GetName)
    return {
      nextDialogue: GetFullName ? new GetFullName({ ...d.state }) : undefined,
      nextStep: this.askEmail
    }
  }

  @step.logState
  goToCollectPhoneNumber(d: IStepData<State>): IStepResult | Promise<IStepResult> {
    const CollectPhoneNumberDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectPhoneNumber
    )
    const nextDialogue = CollectPhoneNumberDialogue
      ? new CollectPhoneNumberDialogue({ ...d.state, skipCollectPhoneNumberIntro: true })
      : undefined
    return {
      nextDialogue,
      nextStep: this.doReferralSubmission
    }
  }

  @step
  sayReferralFailed(d: IStepData<State>): IStepResult {
    // TODO: Implement this properly
    return {
      body: "SOMETHING WENT WRONG TEXT - THIS IS JUST A HACKATHON PEOPLE! TIME WASN'T ENOUGH FOR ALL SCENARIOS MKAY?",
      nextStep: this.end,
      clearStack: true
    }
  }

  @step
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    return {
      body: [
        "It's been a pleasure getting to know you.",
        "A member of our sales team will be in touch shortly"
      ],
      nextStep: this.end,
      clearStack: true
    }
  }

  /** Generic Handlers */

  async onSubmitReferralData(state: State): Promise<boolean> {
    // TODO: Submit lead somewhere
    return true
  }

  async getReferralPayload(state: State): Promise<Record<string, any>> {
    return {}
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onReferralFinished(state: State): Promise<void> {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async onRiskReferralFinished(state: State): Promise<void> {}

  async onHandleEmail(state: State): Promise<IStepResult> {
    return { nextStep: this.goToCollectPhoneNumber }
  }
}

/* istanbul ignore next */
export default class SelfReferralLimbicDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralLimbic
  readonly name: string = "SelfReferralLimbicDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralLimbicDialogue.id, new SelfReferralLimbicScript(), state, snapshot)
  }
  h
}
