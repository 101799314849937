import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScript, GetNameScriptState } from "./GetNameScript"
import { fullNameRegex } from "../../../utils/fullNameRegex"

type State = GetNameScriptState
export type GetNameCCGScriptState = State

export class GetNameCCGScript extends GetNameScript {
  readonly name: string = "GetNameCCGScript"

  /** Script Steps */

  @step
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    const greatMessage = this.rootStore.configStore.directReferral
      ? "Great"
      : "Great - happy to help 😊"
    return {
      body: [greatMessage, "What's your full name? ✏️"],
      nextStep: this.showPromptForFullName
    }
  }

  @step
  sayPleaseGiveFullName(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your full name",
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  showPromptForFullName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForFullName"),
        type: "text",
        validation: [new RegExp(fullNameRegex)],
        validationExplainer: ["Please enter your full name"],
        forceValue: true
      },
      nextStep: this.handleFullNameStringWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: GetNameCCGScript) => {
    d.state.username = d.response
    script.rootStore.applicationStore.setUsername(d.response)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: GetNameScript) => s.sayPleaseGiveFullName
  })
  async handleFullNameStringWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkFullName }
  }
}

/* istanbul ignore next */
export default class GetNameCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameCCG
  readonly name: string = "GetNameCCGScript"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameCCGDialogue.id, new GetNameCCGScript(), state, snapshot)
  }
}
