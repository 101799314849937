export enum DialogueIDs {
  MainFlow = "mainFlow",
  LeadClassificationLimbic = "leadClassificationLimbic",
  LearnAboutLimbic = "learnAboutLimbic",
  IntroductionLimbic = "limbicIntro",
  IntroductionIAPT = "introductionIAPT",
  IntroductionWellbeingHub = "introductionWellbeingHub",
  IntroductionGP = "introductionGP",
  IntroductionCCG = "introductionCCG",
  IntroductionEssex = "introductionEssex",
  IntroductionHealix = "introductionHealix",
  IntroductionGMHub = "introductionGMHub",
  IntroductionDemoPrivateProviders = "introductionDemoPrivateProviders",
  IntroductionInsight = "introductionInsight",
  IntroductionInsightWaitlist = "introductionInsightWaitlist",
  IntroductionInsightFollowUp = "introductionInsightFollowUp",
  IntroductionITalk = "introductionITalk",
  IntroductionVitality = "introductionVitality",
  IntroductionVHG = "introductionVHG",
  GetNameIAPT = "getNameIAPT",
  GetNameITalk = "getNameITalk",
  GetNameCCG = "getNameCCG",
  GetNameEssex = "getNameEssex",
  GetNameInsight = "getNameInsight",
  GetNameLincolnshire = "getNameLincolGetNameLincolnshire",
  GetNameHealix = "getNameHealix",
  GetNameNottinghamshire = "getNameNottinghamshire",
  GetNameTrentPTS = "getNameTrentPTS",
  GetNameVitality = "getNameVitality",
  GetNameDemoPrivateProviders = "getNameDemoPrivateProviders",
  GetNameInsightWaitlist = "getNameInsightWaitlist",
  GetNameInsightFollowUp = "getNameInsightFollowUp",
  GetNameNorfolk = "getNameNorfolk",
  GetNameSABP = "getNameSABP",
  GetNameVHG = "getNameVHG",
  GetNameMHM = "getNameMHM",
  GetNameLimbic = "getNameLimbic",
  EligibilityCheckDemo = "eligibilityCheckDemo",
  EligibilityCheckIAPT = "eligibilityCheckIAPT",
  EligibilityCheckITalk = "eligibilityCheckITalk",
  EligibilityCheckEssex = "eligibilityCheckEssex",
  EligibilityCheckWithPDSSpine = "eligibilityCheckWithPDSSpine",
  EligibilityCheckGP = "eligibilityCheckGP",
  EligibilityCheckCCG = "eligibilityCheckCCG",
  EligibilityCheckWellbeingHub = "eligibilityCheckWellbeingHub",
  EligibilityCheckTrentPTS = "eligibilityCheckTrentPTS",
  EligibilityCheckGMHub = "eligibilityCheckGMHub",
  EligibilityCheckTrentPTSDemo = "eligibilityCheckTrentPTSDemo",
  EligibilityCheckInsight = "eligibilityCheckInsight",
  EligibilityCheckLincolnshire = "eligibilityCheckLincolnshire",
  EligibilityCheckNottinghamshire = "eligibilityCheckNottinghamshire",
  EligibilityCheckNorfolk = "eligibilityCheckNorfolk",
  EligibilityCheckSABP = "eligibilityCheckSABP",
  EligibilityCheckVHG = "eligibilityCheckVHG",
  EligibilityCheckVitality = "eligibilityCheckVitality",
  EligibilityCheckMHM = "eligibilityCheckMHM",
  SuitableServicesSignpostIAPT = "suitableServicesSignpostIAPT",
  SuitableServicesSignpostCCG = "suitableServicesSignpostCCG",
  SuitableServicesSignpostSABP = "suitableServicesSignpostSABP",
  SuitableServicesSignpostEssex = "suitableServicesSignpostEssex",
  UnderageSignpost = "underAgeSignpost",
  UnderageSignpostGMHub = "underAgeSignpostGMhub",
  UnderageSignpostNorfolk = "underAgeSignpostNorfolk",
  UnderageSignpostWellbeingHub = "underageSignpostWellbeingHub",
  UnderageSignpostITalk = "underAgeSignpostITalk",
  UnderageSignpostEssex = "underAgeSignpostEssex",
  UnderageSignpostLincolnshire = "underAgeSignpostLincolnshire",
  UnderageSignpostSABP = "underageSignpostSABP",
  PeaceOfMindIAPT = "peaceOfMindIAPT",
  PeaceOfMindGP = "peaceOfMindGP",
  PeaceOfMindCCG = "peaceOfMindCCG",
  PeaceOfMindWellbeingHub = "peaceOfMindWellbeingHub",
  PeaceOfMindHealix = "peaceOfMindHealix",
  PeaceOfMindVitality = "peaceOfMindVitality",
  PeaceOfMindDemoPrivateProviders = "peaceOfMindDemoPrivateProviders",
  PermissionsEssex = "permissionsEssex",
  PermissionsIAPT = "permissionsIAPT",
  PermissionsCCG = "permissionsCCG",
  PermissionsNorfolk = "permissionsNorfolk",
  PermissionsWellbeingHub = "permissionsWellbeingHub",
  PermissionsGMHub = "permissionsGMHub",
  PermissionsSABP = "permissionsSABP",
  PermissionsInsight = "permissionsInsight",
  PermissionsDemoPrivateProviders = "permissionsDemoPrivateProviders",
  PermissionsVitality = "permissionsVitality",
  PermissionsMHM = "permissionsMHM",
  ADSMConsent = "adsmConsent",
  ADSMConsentInsight = "adsmConsentInsight",
  AssessmentPitchIAPT = "assessmentPitchIAPT",
  AssessmentPitchGP = "assessmentPitchGP",
  AssessmentPitchCCG = "assessmentPitchCCG",
  AssessmentPitchWellbeingHub = "assessmentPitchWellbeingHub",
  AssessmentPitchEssex = "assessmentPitchEssex",
  AssessmentPitchTrentPTS = "assessmentPitchTrentPTS",
  AssessmentPitchGMHub = "assessmentPitchGMHub",
  AssessmentPitchHealix = "assessmentPitchHealix",
  AssessmentPitchITalk = "assessmentPitchITalk",
  AssessmentPitchInsight = "assessmentPitchInsight",
  AssessmentPitchInsightFollowUp = "assessmentPitchInsightFollowUp",
  AssessmentPitchLincolnshire = "assessmentPitchLincolnshire",
  AssessmentPitchNottinghamshire = "assessmentPitchNottinghamshire",
  AssessmentPitchNorfolk = "assessmentPitchNorfolk",
  AssessmentPitchSABP = "assessmentPitchSABP",
  AssessmentPitchVHG = "assessmentPitchVHG",
  AssessmentPitchDemoPrivateProviders = "assessmentPitchDemoPrivateProviders",
  AssessmentPitchVitality = "assessmentPitchVitality",
  AssessmentPitchMHM = "assessmentPitchMHM",
  AssessmentDemo = "assessmentDemo",
  AssessmentCCG = "assessmentCCG",
  AssessmentWellbeingHub = "assessmentWellbeingHub",
  AssessmentEssex = "assessmentEssex",
  AssessmentKentMedway = "assessmentKentMedway",
  AssessmentSABP = "assessmentSABP",
  AssessmentGMHub = "assessmentGMHub",
  AssessmentHealix = "assessmentHealix",
  AssessmentInsight = "assessmentInsight",
  AssessmentADSMInsight = "assessmentADSMInsight",
  AssessmentITalk = "assessmentITalk",
  AssessmentNorfolk = "assessmentNorfolk",
  AssessmentNottinghamshire = "assessmentNottinghamshire",
  AssessmentVHG = "assessmentVHG",
  AssessmentTrentPTS = "assessmentTrentPTS",
  AssessmentLincolnshire = "assessmentLincolnshire",
  AssessmentDemoPrivateProviders = "assessmentDemoPrivateProviders",
  AssessmentVitality = "assessmentVitality",
  AssessmentMHM = "assessmentMHM",
  TreatmentOptionsIAPT = "treatmentOptionsIAPT",
  TreatmentOptionsTrentPTS = "treatmentOptionsTrentPTS",
  TreatmentOptionsSABP = "treatmentOptionsSABP",
  TreatmentOptionsVHG = "treatmentOptionsVHG",
  TreatmentOptionsCCG = "treatmentOptionsCCG",
  TreatmentOptionsHealix = "treatmentOptionsHealix",
  TreatmentOptionsInsight = "treatmentOptionsInsight",
  TreatmentOptionsDemoPrivateProviders = "treatmentOptionsDemoPrivateProviders",
  TreatmentOptionsVitality = "treatmentOptionsVitality",
  BookMentalHealthProfessional = "bookMentalHealthProfessional",
  BookMentalHealthProfessionalKM = "bookMentalHealthProfessionalKM",
  WellBeingCCG = "wellbeingCCG",
  WellbeingHealix = "wellbeingHealix",
  WellbeingDemoPrivateProviders = "wellbeingDemoPrivateProviders",
  SelfReferralDemo = "selfReferralDemo",
  SelfReferralEssex = "selfReferralEssex",
  SelfReferralPitchIAPT = "selfReferralPitchIAPT",
  SelfReferralPitchCCG = "selfReferralPitchCCG",
  SelfReferralPitchWellbeingHub = "selfReferralPitchWellbeingHub",
  SelfReferralPitchHealix = "selfReferralPitchHealix",
  SelfReferralPitchGMHub = "selfReferralPitchGMHub",
  SelfReferralPitchDemoPrivateProviders = "selfReferralPitchDemoPrivateProviders",
  SelfReferralVHG = "selfReferralVHG",
  SelfReferralCCG = "selfReferralCCG",
  SelfReferralGMHub = "selfReferralGMHub",
  SelfReferralGMHubManagerTeam = "selfReferralGMHubManagerTeam",
  SelfReferralGMHubManagerIndividual = "selfReferralGMHubManagerIndividual",
  SelfReferralGMHubIndividual = "selfReferralGMHubIndividual",
  SelfReferralWellbeingHub = "selfReferralWellbeingHub",
  SelfReferralWellbeingHubIndividual = "selfReferralWellbeingHubIndividual",
  SelfReferralWellbeingHubManagerIndividual = "selfReferralWellbeingHubManagerIndividual",
  SelfReferralWellbeingHubManagerTeam = "selfReferralWellbeingHubManagerTeam",
  SelfReferralHealix = "selfReferralHealix",
  SelfReferralTrentPTS = "selfReferralTrentPTS",
  SelfReferralInsight = "selfReferralInsight",
  SelfReferralITalk = "selfReferralITalk",
  SelfReferralNorfolk = "selfReferralNorfolk",
  SelfReferralSABP = "selfReferralSABP",
  SelfReferralLincolnshire = "selfReferralLincolnshire",
  SelfReferralNottinghamshire = "selfReferralNottingSelfReferralNottinghamshire",
  SelfReferralDemoPrivateProviders = "selfReferralDemoPrivateProviders",
  SelfReferralInsightWaitlist = "selfReferralInsightWaitlist",
  SelfReferralVitality = "selfReferralVitality",
  SelfReferralMHM = "selfReferralMHM",
  SelfReferralLimbic = "selfReferralLimbic",
  PHQ9 = "survey:PHQ9",
  PHQ9GMHub = "survey:PHQ9_GMHub",
  PHQ9Norfolk = "survey:PHQ9_Norfolk",
  PHQ9WellbeingHub = "survey:PHQ9_WellbeingHub",
  PHQ9SABP = "survey:PHQ9_SABP",
  PHQ9Nottinghamshire = "survey:PHQ9_Nottinghamshire",
  PHQ9VHG = "survey:PHQ9_VHG",
  PHQ9TrentPTS = "survey:PHQ9_Trent_PTS",
  PHQ9Vitality = "survey:PHQ9_Vitality",
  RiskPathwayDemo = "riskPathwayDemo",
  RiskPathwayCCG = "riskPathwayCCG",
  RiskPathwayGMHub = "riskPathwayGMHub",
  RiskPathwayWellbeingHub = "riskPathwayWellbeingHub",
  RiskPathwayEssex = "riskPathwayEssex",
  RiskPathwayTrentPTS = "riskPathwayTrentPTS",
  RiskPathwayHealix = "riskPathwayHealix",
  RiskPathwayInsight = "riskPathwayInsight",
  RiskPathwayInsightFollowUp = "riskPathwayInsightFollowUp",
  RiskPathwayITalk = "riskPathwayITalk",
  RiskPathwayNottinghamshire = "riskPathwayNottinghamshire",
  RiskPathwayNorfolk = "riskPathwayNorfolk",
  RiskPathwaySABP = "riskPathwaySABP",
  RiskPathwayVHG = "riskPathwayVHG",
  RiskPathwayMHM = "riskPathwayMHM",
  RiskPathwayLincolnshire = "riskPathwayLincolnshire",
  RiskPathwayDemoPrivateProviders = "riskPathwayDemoPrivateProviders",
  RiskPathwayVitality = "riskPathwayVitality",
  GAD7 = "survey:GAD7",
  PCL5 = "survey:PCL5",
  PDSS = "survey:PDSS",
  SPIN = "survey:SPIN",
  OCI = "survey:OCI",
  SHAI18 = "survey:SHAI18",
  SpecificPhobiaIntro = "survey:SpecificPhobiaIntro",
  SpecificPhobia = "survey:SpecificPhobia",
  Audit = "survey:Audit",
  DrugsAndSmoking = "survey:DrugsAndSmoking",
  ITQ = "survey:ITQ",
  IRQA = "survey:IRQA",
  IAPTAccommodation = "survey:IAPTAccommodation",
  IAPTPhobiaScale = "survey:IAPTPhobiaScale",
  IAPTEmploymentStatus = "survey:IAPTEmploymentStatus",
  IAPTEmploymentStatusKM = "survey:IAPTEmploymentStatusKM",
  IAPTWorkAndSocialAdjustment = "survey:IAPTWorkAndSocialAdjustment",
  IAPTMedication = "survey:iaptMedication",
  Goodbye = "goodbye",
  GoodbyeDemo = "goodbyeDemo",
  GoodbyeCCG = "goodbyeCCG",
  GoodbyeGMHub = "goodbyeGMHub",
  GoodbyeWellbeingHub = "goodbyeWellbeingHub",
  GoodbyeEssex = "goodbyeEssex",
  GoodbyeTrentPTS = "goodbyeTrentPTS",
  GoodbyeSABP = "goodbyeSABP",
  GoodbyeHealix = "goodbyeHealix",
  GoodbyeInsight = "goodbyeInsight",
  GoodbyeInsightFollowUp = "goodbyeInsightFollowUp",
  GoodbyeITalk = "goodbyeITalk",
  GoodbyeNottinghamshire = "goodbyeNottinghashire",
  GoodbyeNorfolk = "goodbyeNorfolk",
  GoodbyeVHG = "goodbyeVHG",
  GoodbyeMHM = "goodbyeMHM",
  GoodbyeLincolnshire = "goodbyeLincolnshire",
  GoodbyeDemoPrivateProviders = "goodbyeDemoPrivateProviders",
  GoodbyeVitality = "goodbyeVitality",
  CrisisDemo = "crisisDemo",
  Crisis = "crisis",
  CrisisCCG = "crisisCCG",
  CrisisGMHub = "crisisGMHub",
  CrisisITalk = "crisisITalk",
  CrisisWellbeingHub = "crisisWellbeingHub",
  CrisisEssex = "crisisEssex",
  CrisisTrentPTS = "crisisTrentPTS",
  CrisisSABP = "crisisSABP",
  CrisisHealix = "crisisHealix",
  CrisisInsight = "crisisInsight",
  CrisisVHG = "crisisVHG",
  CrisisLincolnshire = "crisisLincolnshire",
  CrisisNottinghamshire = "crisisNottinghamshire",
  CrisisNorfolk = "crisisNorfolk",
  CrisisDemoPrivateProviders = "crisisDemoPrivateProviders",
  CrisisVitality = "crisisVitality",
  CrisisMHM = "crisisMHM",
  CrisisLimbic = "crisisLimbic",
  BookAppointmentVitality = "bookAppointmentVitality",
  BookAppointmentITalk = "bookAppointmentITalk",
  CollectPhoneNumber = "collectPhoneNumber",
  CollectPhoneNumberEssex = "collectPhoneNumberEssex",
  CollectPhoneNumberInsight = "collectPhoneNumberInsight",
  CollectPhoneNumberITalk = "collectPhoneNumberITalk",
  CollectPhoneNumberNorfolk = "collectPhoneNumberNorfolk",
  CollectPhoneNumberMHM = "collectPhoneNumberMHM",
  CollectAlcoholConsumption = "collectAlcoholConsumption"
}
