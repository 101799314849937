/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import invariant from "../../../utils/invariant"
import {
  ALCOHOL_FREQUENCIES as AF,
  ALCOHOL_QUANTITIES as AQ,
  ARMED_FORCES_MAYDEN_ESSEX,
  DISABILITY_MAYDEN_ESSEX,
  ETHNICITY_MAYDEN_ESSEX,
  GENDER_MAYDEN_ESSEX,
  GenderBirthAssigned,
  LANGUAGE_MAYDEN_ESSEX,
  LIMBIC_IMPACT_LEVEL,
  LTC_MAYDEN_ESSEX,
  PERINATAL_MAYDEN_ESSEX,
  ReferralPayloadMaydenEssex,
  RELIGION_MAYDEN_ESSEX,
  SEXUALITY_MAYDEN_ESSEX
} from "@limbic/types"
import { ALCOHOL_FREQUENCIES, ALCOHOL_QUANTITIES, TrackingEvents } from "../../../models/Constants"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import { removeCountryCodeFromPhoneNumber } from "../../../utils/formatPhoneNumber"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { joinWithAnd, joinWithOr } from "../../../utils/array"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  languages,
  ltcs,
  perinatalStatuses,
  religions,
  sameGenderAsBirth,
  sexualities
} from "../../../config/referralForms/essex-form"

interface State extends SelfReferralIAPTScriptState {
  gpConsent?: boolean
  sameGenderAsBirth?: string
  relationshipStatus?: string
  requiresInterpreter?: boolean
  interpreterLanguage?: string
  currentSupportDetails?: string
  isTakingMedication?: boolean
  disabilities?: string[]
  ltcOtherDetails?: string
  consentDataShareGPAndNHS?: boolean
}

export type SelfReferralEssexState = State

export class SelfReferralEssexScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralEssexScript"

  /** Script Steps */

  @step.logState
  askPermissionToEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you allow us to contact you over email (including unencrypted emails when required)?",
      prompt: {
        id: this.getPromptId("askPermissionToEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPermissionToEmail"
      },
      nextStep: this.handlePermissionToEmail
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canSendEmail = d.response
  })
  handlePermissionToEmail(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  @step.logState
  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askMainIssue }
  }

  @step.logState
  askMainIssue(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "Brilliant",
        `So ${name}, please could you describe the main concern or problem that brought you here today (be sure to include specific feelings, behaviours, or thoughts that are bothering you)`
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralEssexScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
  })
  @step.checkInputForCrisis({ getNextStep: (s: SelfReferralEssexScript) => s.askGender })
  handleMainIssueWithCrisis(d: IStepData<State, string>): IStepResult {
    const name = this.getName(d.state)
    return { body: `Thanks for sharing ${name}`, nextStep: this.askGender }
  }

  @step.logState
  askRequiresAnInterpreter(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you require an interpreter?",
      prompt: {
        id: this.getPromptId("askRequiresAnInterpreter"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequiresInterpreter
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralEssexScript) => {
    d.state.requiresInterpreter = d.response
    script.referralStore.setCustomField<State>("requiresInterpreter", d.response)
  })
  handleRequiresInterpreter(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askInterpreterLanguage }
    }
    return { nextStep: this.askReligion }
  }

  @step.logState
  askInterpreterLanguage(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const primaryLanguage = d.state.primaryLanguage
    const languages = this.getLanguages(d.state)
    if (!languages?.length) {
      this.logBreadcrumb("LANGUAGES NOT FOUND", d.state, { languages })
      this.logMessage("LANGUAGES NOT FOUND")
      return { nextStep: this.askReligion }
    }

    return {
      body: [
        `So ${name}, you stated your main spoken language is ${primaryLanguage}`,
        "Is this the language you need an interpreter for?",
        "(If not, just type the language you'd like an interpreter for)"
      ],
      prompt: {
        id: this.getPromptId("askInterpreterLanguage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: `Yes, I need an interpreter for ${primaryLanguage}`,
            value: primaryLanguage
          },
          ...languages
            .filter(language => language !== primaryLanguage)
            .map(g => ({ body: g, value: g }))
        ],
        dataPointsName: "askInterpreterLanguage"
      },
      nextStep: this.handleInterpreterLanguage
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralEssexScript) => {
    d.state.interpreterLanguage = d.response
    script.referralStore.setCustomField<State>("interpreterLanguage", d.state.interpreterLanguage)
  })
  handleInterpreterLanguage(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askReligion }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.askExArmedForces }
    }
    return {
      body: "Do any of the following statements about pregnancy or young children apply to you?",
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: g, value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logStateAndResponse
  async handleLongTermMedicalCondition(
    d: IStepData<State, ["No"] | string[]>
  ): Promise<IStepResult> {
    const medicalConditions = this.getMedicalConditions(d.state)
    const isInvalid = d.response.find(r => r !== "No" && !medicalConditions?.includes(r))
    if (isInvalid) {
      return {
        body: "Sorry I can't recognise this medical condition. Let's try again",
        nextStep: this.askLongTermMedicalCondition
      }
    }

    d.state.longTermMedicalCondition = d.response
    this.setPeople({ ltc: d.response })

    if (d.state.longTermMedicalCondition.includes("Other")) {
      return {
        nextStep: this.askLongTermMedicalConditionOtherDetails
      }
    }
    if (d.state.longTermMedicalCondition?.includes("No")) {
      return { nextStep: this.askDisability }
    }
    return { nextStep: this.askDoesLTCAffectMood }
  }

  @step.logState
  askLongTermMedicalConditionOtherDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please specify",
      prompt: {
        id: this.getPromptId("askLongTermMedicalConditionOtherDetails"),
        trackResponse: true,
        type: "text",
        forceValue: true,
        isUndoAble: true,
        dataPointsName: "askLongTermMedicalConditionOtherDetails"
      },
      nextStep: this.handleLongTermMedicalConditionOtherDetailsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.ltcOtherDetails = d.response
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralEssexScript) => s.askHowWellYouManageYourLTC
  })
  handleLongTermMedicalConditionOtherDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askDoesLTCAffectMood }
  }

  @step
  askDoesLTCAffectMood(d: IStepData<State>): IStepResult {
    const condition = this.getCustomConditionOr(d.state)
    return {
      body: `Does your ${condition} impact your mood?`,
      prompt: {
        id: this.getPromptId("askDoesLTCAffectMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askDoesLTCAffectMood"
      },
      nextStep: this.handleDoesLTCAffectMood
    }
  }

  @step
  askHowWellYouManageYourLTC(d: IStepData<State>): IStepResult {
    const condition = this.getCustomConditionAnd(d.state)
    return {
      body: `And how well do you think you manage your ${condition}?`,
      prompt: {
        id: this.getPromptId("askHowWellYouManageYourLTC"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Not very well", value: "little" },
          { body: "Fairly well", value: "fairly" },
          { body: "Very well", value: "very" }
        ],
        dataPointsName: "askHowWellYouManageYourLTC"
      },
      nextStep: this.handleHowWellYouManageYourLTC
    }
  }

  @step.logState
  askMedication(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently taking any prescribed medication for Mental Health?",
      prompt: {
        id: this.getPromptId("askMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askMedication"
      },
      nextStep: this.handleMedication
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralEssexScript) => {
    d.state.isTakingMedication = d.response
    script.referralStore.setCustomField<State>("isTakingMedication", d.response)
  })
  handleMedication(d: IStepData<State, boolean>): IStepResult {
    return { nextStep: d.response ? this.askMedicationInfo : this.askCurrentSupport }
  }

  @step.logState
  askMedicationInfo(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you let me know the details of the medication you've been prescribed?",
      prompt: {
        id: this.getPromptId("askMedicationInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true,
        dataPointsName: "askMedicationInfo"
      },
      nextStep: this.handleMedicationInfoWithCrisis
    }
  }

  @step.logState
  askCurrentSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently receiving any other form of mental health support?",
      prompt: {
        id: this.getPromptId("askCurrentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCurrentSupport"
      },
      nextStep: this.handleCurrentSupport
    }
  }

  @step.logState
  askCurrentSupportDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please select from the following",
      prompt: {
        id: this.getPromptId("askCurrentSupportDetails"),
        type: "inlinePicker",
        choices: [
          { body: "GP only", value: "GP only" },
          { body: "Consultant Psychiatrist only", value: "Consultant Psychiatrist only" },
          { body: "Care co-ordinated by CMHT", value: "Care co-ordinated by CMHT" },
          { body: "Voluntary services support", value: "Voluntary services support" },
          { body: "Other talking therapy.", value: "Other talking therapy" }
        ],
        dataPointsName: "askCurrentSupportDetails"
      },
      nextStep: this.handleCurrentSupportDetails
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralEssexScript) => {
    d.state.currentSupportDetails = d.response
    script.referralStore.setCustomField<State>("currentSupportDetails", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralEssexScript) => s.askCurrentSupportDetails
  })
  handleCurrentSupportDetails(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askWhatIsYourGoal }
  }

  /** Generic Handlers */

  async onSubmitReferralData(state: State): Promise<boolean> {
    try {
      const payload = await this.getReferralPayload(state)
      await this.referralStore.createReferral(payload)
      this.referralStore.setShouldHavePatientIdAndInstanceId(true)
      state.patientId = this.referralStore.patientId
      state.signupCode = this.referralStore.signupCode
      this.track(TrackingEvents.SELF_REFERRAL_SUBMITTED)
    } catch (e) {
      this.referralStore.setShouldHavePatientIdAndInstanceId(false)
      this.logException(e, "onSubmitReferralData")
      return false
    }

    return true
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadMaydenEssex> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      nhsNumber: state.nhsNumber,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: this.getName(state),
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually City and County maybe undefined
        // The backend submission then fails
        // Setting them to "unknown" if undefined
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode,
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: "", // country code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // country code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      problemInOwnWords: state.mainIssue,
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirth(state),
      disability: this.getDisability(state),
      adhd: state.hasADHD,
      asd: state.hasASD,
      perinatal: this.getPerinatal(state),
      language: this.getLanguage(state),
      interpreter: state.requiresInterpreter,
      interpreterLanguage: this.getInterpreterLanguage(state),
      title: state.nameTitle,
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForce(state),
      sexuality: this.getSexuality(state),
      religion: this.getReligion(state),
      ltc: this.getLTC(state),
      ltcOtherDetails: state.ltcOtherDetails,
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      substances: state.substances,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      // TODO: isTakingMedication needs to be handled
      // There's no property right now to send it
      // medication:
      //   state.substancesAreMedications && state.medicationInfo //
      //     ? [state.medicationInfo]
      //     : undefined,
      // medicationWithinDosage: state.isTakingMedication,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      medication: state.isTakingMedication ? [state.medicationInfo!] : undefined,
      consentDataShareGP: state.consentDataShareGPAndNHS,
      consentDataShare: state.consentDataShareGPAndNHS,
      consentDataStore: true,
      consentResearch: state.consentResearch,
      // When spine it's "odsGP" not "gp" 👇
      gpPhone: removeCountryCodeFromPhoneNumber(state.odsGP?.phone[0] ?? state.gp?.phoneNumber),
      gpCodeNACS: state.odsGP?.id ?? state.gp?.nacsCode,
      gpName: state.odsGP?.name ?? state.gp?.name,
      gpPractice: state.odsGP?.name ?? state.gp?.name,
      alcohol: state.alcohol,
      alcoholFrequency: this.getAlcoholFrequency(state),
      alcoholQuantity: this.getAlcoholQuantity(state),
      currentSupport: state.hasCurrentSupport,
      currentSupportDetailsOther: state.currentSupportDetails || undefined,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      clinicalNotes: this.referralStore.clinicalNotes,
      treatmentExpectation: state.therapyGoal
    }
  }

  async onHandleEmail(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askPermissionToEmail }
  }

  async onHandleGender(state: State): Promise<IStepResult<State>> {
    const name = this.getName(state)
    return { body: `Thanks ${name}`, nextStep: this.askSameGenderAsBirth }
  }

  async onHandleSexuality(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askEthnicity }
  }

  async onHandleEthnicity(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askPrimaryLanguage }
  }

  async onHandlePrimaryLanguage(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: state.primaryLanguage?.match(/english/gi) //
        ? this.askReligion
        : this.askRequiresAnInterpreter
    }
  }

  async onHandleReligion(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askPerinatal }
  }

  async onHandlePerinatal(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askExArmedForces }
  }

  async onHandleExArmedForces(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askLongTermMedicalCondition }
  }

  async onHandleLongTermMedicalCondition(state: State): Promise<IStepResult<State>> {
    if (state.longTermMedicalCondition?.includes("Other")) {
      return {
        nextStep: this.askLongTermMedicalConditionOtherDetails
      }
    }
    if (state.longTermMedicalCondition?.includes("No")) {
      return { nextStep: this.askDisability }
    }
    return { nextStep: this.askDoesLTCAffectMood }
  }

  async onHandleDoesLTCAffectMood(state: State): Promise<IStepResult<State>> {
    if (state.ltcAffectsMood) return { nextStep: this.askHowMuchLTCAffectsMood }
    return { nextStep: this.askDisability }
  }

  async onHandleHowMuchLTCAffectsMood(state: State): Promise<IStepResult<State>> {
    if (state.ltcMoodImpact === "little") return { nextStep: this.askDisability }
    return { nextStep: this.askHowWellYouManageYourLTC }
  }

  async onHandleHowWellYouManageYourLTC(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askDisability }
  }

  async onHandleHasADHD(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToCollectAlcoholConsumption }
  }

  async onHandleSubstances(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: state.substances //
        ? this.askSubstancesOrigin
        : this.askMedication
    }
  }

  async onHandleSubstancesInfo(_state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askMedication
    }
  }

  async onHandleMedicationWithinDoseRange(_state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.askMedication
    }
  }

  async onHandleCurrentSupport(state: State): Promise<IStepResult<State>> {
    if (state.hasCurrentSupport) {
      return {
        nextStep: this.askCurrentSupportDetails
      }
    }
    return {
      nextStep: this.askWhatIsYourGoal
    }
  }

  getGender(state: State): GENDER_MAYDEN_ESSEX {
    const gender = genders[state.gender!]
    return gender ?? "UNKNOWN"
  }

  getGenderSameAsBirth(state: State): GenderBirthAssigned {
    const genderSameAsBirth = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirth ?? "UNKNOWN"
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_ESSEX {
    const sexuality = sexualities[state.sexuality!]
    return sexuality ?? "UNKNOWN"
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_ESSEX {
    const ethnicity = ethnicities[state.ethnicity!]
    return ethnicity ?? "UNKNOWN"
  }

  getLanguage(state: State): LANGUAGE_MAYDEN_ESSEX | undefined {
    return languages[state.primaryLanguage!] ?? "NOT_ANSWERED"
  }

  getInterpreterLanguage(state: State): LANGUAGE_MAYDEN_ESSEX | undefined {
    return languages[state.interpreterLanguage!] ?? "NOT_ANSWERED"
  }

  getReligion(state: State): RELIGION_MAYDEN_ESSEX {
    const religion = religions[state.religion!]
    return religion ?? "NOT_ANSWERED"
  }

  getPerinatal(state: State): PERINATAL_MAYDEN_ESSEX {
    const perinatal = perinatalStatuses[state.perinatalStatus!]
    return perinatal ?? "NONE"
  }

  getArmedForce(state: State): ARMED_FORCES_MAYDEN_ESSEX {
    const armedForces = exArmedForces[state.isExArmedForces!]
    return armedForces ?? "UNKNOWN"
  }

  getLTC(state: State): LTC_MAYDEN_ESSEX[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getDisability(state: State): DISABILITY_MAYDEN_ESSEX[] {
    if (!state.disabilities?.length) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }

    return state.disabilities
      ? state.disabilities?.map(disability => disabilities[disability]).filter(Boolean)
      : ["NONE"]
  }

  getAlcoholFrequency(state: State): typeof AF[keyof typeof AF] | undefined {
    const map = {
      [ALCOHOL_FREQUENCIES.MONTHLY]: AF.MONTHLY,
      [ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4]: AF.MONTHLY_2_TO_4,
      [ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3]: AF.WEEKLY_2_TO_3,
      [ALCOHOL_FREQUENCIES.WEEKLY_4]: AF.WEEKLY_4
    }
    return map[state.alcoholFrequency!]
  }

  getAlcoholQuantity(state: State): typeof AQ[keyof typeof AQ] | undefined {
    const map = {
      [ALCOHOL_QUANTITIES._0_2]: AQ._0_2,
      [ALCOHOL_QUANTITIES._3_4]: AQ._3_4,
      [ALCOHOL_QUANTITIES._5_6]: AQ._5_6,
      [ALCOHOL_QUANTITIES._7_9]: AQ._7_9,
      [ALCOHOL_QUANTITIES._10_PLUS]: AQ._10_PLUS
    }
    return map[state.alcoholQuantity!]
  }

  getCustomConditionAnd(state: State): string {
    const hasLTCOtherDetailsPlusOneLTC =
      state.longTermMedicalCondition!.filter(c => c !== "Other").length === 1 &&
      state.ltcOtherDetails
    if (hasLTCOtherDetailsPlusOneLTC) {
      return `${state.ltcOtherDetails} and ${joinWithAnd(
        state.longTermMedicalCondition?.filter(c => c !== "Other")
      )}`
    }
    if (state.ltcOtherDetails && state.longTermMedicalCondition?.length === 1) {
      return state.ltcOtherDetails
    }
    return state.ltcOtherDetails
      ? `${state.ltcOtherDetails}, ${joinWithAnd(
          state.longTermMedicalCondition?.filter(c => c !== "Other")
        )}`
      : joinWithAnd(state.longTermMedicalCondition)
  }

  getCustomConditionOr(state: State): string {
    const hasLTCOtherDetailsPlusOneLTC =
      state.longTermMedicalCondition!.filter(c => c !== "Other").length === 1 &&
      state.ltcOtherDetails
    if (hasLTCOtherDetailsPlusOneLTC) {
      return `${state.ltcOtherDetails} or ${joinWithOr(
        state.longTermMedicalCondition?.filter(c => c !== "Other")
      )}`
    }
    if (state.ltcOtherDetails && state.longTermMedicalCondition?.length === 1) {
      return state.ltcOtherDetails
    }
    return state.ltcOtherDetails
      ? `${state.ltcOtherDetails}, ${joinWithOr(
          state.longTermMedicalCondition?.filter(c => c !== "Other")
        )}`
      : joinWithOr(state.longTermMedicalCondition)
  }
}

export default class SelfReferralEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralEssex
  readonly name: string = "SelfReferralEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralEssexDialogue.id, new SelfReferralEssexScript(), state, snapshot)
  }
}
