import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { DialogueIDs } from "../../DialogueIDs"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"

type State = BaseScriptState

export class IntroductionLimbicScript extends BaseScript<State> {
  readonly name = "IntroductionLimbicScript"

  /** Script Steps */

  @step
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.greet }
  }

  @step
  greet(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Hi there",
        "I'm Limbic",
        "I'm an AI assistant that was built to help people access mental health support"
      ],
      nextStep: this.end
    }
  }
}

export default class IntroductionLimbicDialogue extends Dialogue<State> {
  static id = DialogueIDs.IntroductionLimbic
  readonly name: string = "IntroductionLimbicDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(IntroductionLimbicDialogue.id, new IntroductionLimbicScript(), state, snapshot)
  }
}
