import React from "react"
import { findLastIndex } from "lodash"
import { observer } from "mobx-react"
import "./ChatMessageList.scss"
import { useLauncherContext } from "../../contexts/LauncherContext"
import IMessage from "../../../backend/chatbot/models/IMessage"
import IChatMessage from "../../../models/IChatMessage"
import ChatMessage from "../ChatMessage/ChatMessage"
import PoweredByLimbic from "../PoweredByLimbic/PoweredByLimbic"
import { useApplicationStore, useBotStore, useChatStore } from "../../contexts/RootStoreContext"
import scrollIntoView from "../../../utils/scrollIntoView"

function ChatMessageList(): JSX.Element {
  const app = useApplicationStore()
  const chat = useChatStore()
  const bot = useBotStore()
  const { isOpen } = useLauncherContext()
  const scrollContainer = React.useRef<any>()
  const scrollContentEnd = React.useRef<any>()
  const { preparedMessages } = prepareMessages(
    chat.messages,
    chat.isTyping,
    app.undoBlocked || bot.stepRunning
  )
  const onUndoPress = React.useCallback((id: string) => bot.undo?.(id!), [bot])

  React.useLayoutEffect(() => {
    if (isOpen) {
      let timer
      const t = setTimeout(
        () => (timer = scrollIntoView(scrollContainer.current, scrollContentEnd.current)),
        60
      )
      return () => {
        clearTimeout(t)
        clearTimeout(timer?.timeout)
      }
    }
  }, [isOpen, scrollContentEnd, chat.messages, chat.isTyping, chat.userPrompt])

  return (
    <ul ref={scrollContainer} className="lb-message-list">
      {preparedMessages.map((m: IChatMessage, i: number) => (
        <ChatMessage key={i} message={m} onUndoPress={onUndoPress} />
      ))}
      <PoweredByLimbic />
      <div ref={scrollContentEnd} className="lb-message-list-end" />
    </ul>
  )
}

export default observer(ChatMessageList)

interface IPreparedMessages {
  preparedMessages: IChatMessage[]
  rewindId?: string
}

function prepareMessages(
  messages: IMessage[] = [],
  isTyping: boolean,
  undoBlocked: boolean
): IPreparedMessages {
  const filteredMessages = messages.filter(m => !m.isHidden)
  const lastUserMessageIndex = findLastIndex(filteredMessages, m => m.author === "user")
  const preparedMessages: IChatMessage[] = filteredMessages.map(
    (m: IMessage, i: number, col: IMessage[]) => {
      const prevMessage = col[i - 1]
      const nextMessage = col[i + 1]
      const timestamp = m.createdAt?.getTime()
      const isFirst = prevMessage ? prevMessage.author !== m.author : true
      const isLast = nextMessage ? nextMessage.author !== m.author : true
      const type = "text"
      const isUndoAble = undoBlocked ? false : m.isUndoAble && i === lastUserMessageIndex
      let preparedMessage: any = { ...m, isUndoAble, type, timestamp, isFirst, isLast }

      if (m.attachment) {
        preparedMessage = { ...m.attachment, ...preparedMessage }
        switch (m.attachment.type) {
          case "imageAttachment":
            preparedMessage.type = "image"
            break
        }
      }

      return preparedMessage
    }
  )

  if (isTyping) {
    preparedMessages.push({
      id: "_typing",
      author: "bot",
      timestamp: new Date().getTime(),
      type: "typingIndicator",
      body: "..."
    })
  }

  return { preparedMessages }
}
