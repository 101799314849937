import {
  CollectPhoneNumberScript,
  CollectPhoneNumberScriptState
} from "./CollectPhoneNumberDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { isValidMobilePhone } from "../../../../utils/isvalidPhoneNumber"

type State = CollectPhoneNumberScriptState
export type CollectPhoneNumberITalkScriptState = State

export class CollectPhoneNumberITalkScript extends CollectPhoneNumberScript {
  readonly name: string = "CollectPhoneNumberITalkScript"

  /** Script Steps */

  @step.logState
  askCanIContactYouOnPhoneNumber(d: IStepData<State>): IStepResult {
    const isMobilePhone = isValidMobilePhone(d.state.phoneNumber!)
    return {
      body: [
        isMobilePhone
          ? "And do I have permission to message you or leave a voicemail on that number?"
          : "And do I have permission to leave a voicemail on that number?",
        "We will need at least one method of contact"
      ],
      prompt: {
        id: this.getPromptId("askCanIContactYouOnPhoneNumber"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleContactPhoneNumberBoolean
    }
  }

  /**
   * Creating this to basically bridge the handleCanIContactYouOnPhoneNumber
   * of the super class with this class, because iTalk wat to ask this question
   * with a simple Yes/No picker instead of a checkbox, so we're converting the
   * response of a Yes/No picker, into a checkbox response
   */
  @step.logState
  async handleContactPhoneNumberBoolean(d: IStepData<State, boolean>): Promise<IStepResult> {
    const isMobilePhone = isValidMobilePhone(d.state.phoneNumber!)
    const response = {
      canSendTextMessagesToPhoneNumber: d.response as boolean | undefined,
      canLeaveVoicemailToPhoneNumber: d.response
    }
    if (!isMobilePhone) delete response.canSendTextMessagesToPhoneNumber

    return await this.handleCanIContactYouOnPhoneNumber({ ...d, response })
  }
}

export default class CollectPhoneNumberITalkDialogue extends AdHocDialogue<
  State,
  CollectPhoneNumberITalkScript
> {
  static id = DialogueIDs.CollectPhoneNumberITalk
  readonly name: string = "CollectPhoneNumberITalkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CollectPhoneNumberITalkDialogue.id, new CollectPhoneNumberITalkScript(), state, snapshot)
  }
}
