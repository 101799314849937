import { z } from "zod"
import { ACCESS_INSTANCES } from "@limbic/types"
import { CCGCode } from "./ICCG"
import { IGPService, IGPServiceSchema } from "./IGPService"
import type { IReferralForm } from "./IReferralForm"

export interface IIAPTService {
  id: string
  name: string
  formattedName: string
  postcode: string
  emails?: string[]
  backendInstanceID?: keyof typeof ACCESS_INSTANCES
  clinicName?: string
  phoneNumber?: string
  ageThreshold?: number
  ageThresholdForTreatments?: number
  riskEmails?: string[]
  riskEmailsCC?: string[]
  riskEmailsBCC?: string[]
  eligibleGPs?: IGPService[]
  referralForm?: IReferralForm
}

export const IIAPTServiceSchema = z.object({
  id: z.string(),
  name: z.string(),
  formattedName: z.string(),
  postcode: z.string(),
  emails: z.array(z.string()).optional(),
  backendInstanceID: z.string().optional(),
  clinicName: z.string().optional(),
  phoneNumber: z.string().optional(),
  ageThreshold: z.number().optional(),
  ageThresholdForTreatments: z.number().optional(),
  riskEmails: z.array(z.string()).optional(),
  riskEmailsCC: z.array(z.string()).optional(),
  riskEmailsBCC: z.array(z.string()).optional(),
  eligibleGPs: z.array(IGPServiceSchema).optional(),
  referralForm: z.object({}).optional()
})

export enum IAPTIDs {
  "DEMO" = "demo",
  "VHG_DERBY" = "10921210",
  "VHG_BB" = "10565427",
  "VHG_CALDERDALE" = "10927593",
  "VHG_BNSSG" = "10802686",
  "VHG_KM" = "10921209",
  "VHG_LLR" = "10974279",
  "VHG_WEST_ESSEX" = "10802687",
  "VHG_NOTTS" = "72602v",
  "INSIGHT_KENT" = "2917833",
  "INSIGHT_PETTERSBOROUGH" = "76186",
  "INSIGHT_NOTTS" = "76190",
  "INSIGHT_BASSETLAW" = "3775518",
  "INSIGHT_DERBY" = "979390",
  "INSIGHT_WIRRAL" = "10290673",
  "INSIGHT_MEDWAY" = "7173844",
  "INSIGHT_KENT_DEMO" = "2917833_DEMO",
  "INSIGHT_PETTERSBOROUGH_DEMO" = "76186_DEMO",
  "INSIGHT_NOTTS_DEMO" = "76190_DEMO",
  "INSIGHT_BASSETLAW_DEMO" = "3775518_DEMO",
  "INSIGHT_DERBY_DEMO" = "979390_DEMO",
  "INSIGHT_WIRRAL_DEMO" = "10290673_DEMO",
  "INSIGHT_MEDWAY_DEMO" = "7173844_DEMO",
  "ITALK" = "75096",
  "TRENT_NOTTS" = "8836752",
  "TRENT_DERBY_WOODLANDS" = "750232",
  "TRENT_DERBY_SALTERGATE" = "8836753",
  "TRENT_NOTTS_DEMO" = "8836752_DEMO",
  "TRENT_DERBY_WOODLANDS_DEMO" = "750232_DEMO",
  "TRENT_DERBY_SALTERGATE_DEMO" = "8836753_DEMO",
  "DOVER_KM" = "2917766",
  "FAVERSHAM_KM" = "462412",
  "WITH_YOU_KM" = "3775462",
  "MIND_MATTERS_SAB" = "43379",
  "NORFOLK_WAVENEY" = "6010858",
  "LINCS_STAMFORD" = "3138268",
  "LINCS_SPALDING" = "3664784",
  "LINCS_GRANTHAM" = "41430",
  "LINCS_BOSTON" = "7353720",
  "LINCS_SLEAFORD" = "4210193",
  "LINCS_SKEGNESS" = "6995542",
  "LINCS_LINCOLN" = "41434",
  "LINCS_LOUTH" = "3138278",
  "LINCS_GAINSBOROUGH" = "71684",
  "NOTTINGHAMSHIRE_NOTTS_CC" = "72602",
  "NOTTINGHAMSHIRE_NOTTS" = "1708746",
  "THERAPY_FOR_YOU_NE_ESSEX" = "7560833",
  "THERAPY_FOR_YOU_SE_ESSEX" = "7757974",
  "THERAPY_FOR_YOU_NE_ESSEX_DEMO" = "7560833_DEMO",
  "THERAPY_FOR_YOU_SE_ESSEX_DEMO" = "7757974_DEMO",
  "MENTAL_HEALTH_MATTERS_WARRINGTON" = "750555",
  "MENTAL_HEALTH_MATTERS_SEFTON" = "10965968",
  "MENTAL_HEALTH_MATTERS_NORTHUMBERLAND" = "4511506"
}

export type IAPTCCGMap = Partial<Record<CCGCode, IAPTIDs>>
export type IAPTGPMap = Record<string, IAPTIDs>
