import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import getQRCodeURL from "../../../utils/getQRCodeURL"
import { step } from "../../../backend/chatbot/decorators/step"

interface State extends GoodbyeScriptState {
  canKeepSelfSafe?: boolean
  signpostUrgentSupport?: boolean
}

export type GoodbyeNorfolkScriptState = State

interface WindowWithLimbicNameSpace extends Window {
  DEEP_LINK: string
}

declare let window: WindowWithLimbicNameSpace

window.DEEP_LINK = String(process.env.REACT_APP_DEEP_LINK ?? "enabled")

const deepLinkEnabled = window.DEEP_LINK !== "disabled"

export class GoodbyeNorfolkScript extends GoodbyeScript {
  readonly name: string = "GoodbyeNorfolkScript"

  /** Script Steps */
  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    void this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()
    const name = this.getName(d.state)

    return {
      body: [
        "Well done for taking this important step towards better mental health",
        `Goodbye ${name} 👋`
      ],
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    const choices: any[] = [{ body: "Okay" }, recapMessage && { body: "I understand" }] //
      .filter(Boolean)
    const earlyCrisis = this.rootStore.referralStore.getCustomField("earlyCrisis")
    return {
      body: earlyCrisis
        ? body
        : [
            ...body,
            "If you do not hear from us within 7 days, or have any further questions, please do not hesitate to contact us on 03001231503 or email us at admin@wellbeingnandw.co.uk"
          ],
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askFeedback
    }
  }

  async getIntroMessage(state: State): Promise<string | void> {
    if (state.signpostUrgentSupport)
      return "We hope you feel able to contact these services for help. We wish you all the best"
    return "I'm looking forward to going on this journey with you"
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const serviceName = this.rootStore.configStore.serviceName
      let referralMessage = `I've referred you to ${serviceName}`
      if (!this.clinicalStore.isRisk) {
        referralMessage =
          referralMessage +
          `\n\nSomeone from the service team will be in contact within three working days with a link via SMS to book your appointment with a ${serviceName} clinician`
      }
      return state.isIdleSubmitted //
        ? `It looks like there hasn’t been any activity for some time so I've referred you to ${serviceName}`
        : referralMessage
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      return "Someone from our team will call you within three working days\n\nPlease remember, Limbic is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 11 and select Option 2"
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      return "Someone from our team will call you within three working days\n\nPlease remember, Limbic is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 11 and select Option 2"
    }
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const hasDynamicLink = deepLinkEnabled && state.careSignupURL
    if (state.referralSubmitted && hasDynamicLink) {
      const qrCodeURL = getQRCodeURL(state.careSignupURL!)
      const isCrisis = this.clinicalStore.isCrisis
      const name = this.getName(state)
      const lastMessage = isCrisis
        ? `Thank you for sharing this information with me ${name}`
        : `Well, it's been a pleasure getting to know you ${name}`

      return [
        "We can continue our conversations in my mobile app",
        `If you're already on your mobile device, just click [here](${state.careSignupURL}) to download it`,
        "If you're on a desktop computer, you can scan the QR code below",
        {
          type: "imageAttachment",
          image: qrCodeURL
        },
        lastMessage
      ]
    }
  }
}

export default class GoodbyeNorfolkDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeNorfolk
  readonly name: string = "GoodbyeNorfolkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeNorfolkDialogue.id, new GoodbyeNorfolkScript(), state, snapshot)
  }
}
