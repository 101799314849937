import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import AssessmentIAPTScript, { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import moment from "moment"

type State = AssessmentIAPTScriptState

export class AssessmentEssexScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentEssexScript"

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }

  async onFinishAssessment(state: State): Promise<IStepResult> {
    const emails = state.iapt?.emails || []
    void this.submitEmail(state, emails)
    return { nextStep: this.end }
  }

  getPersonalInfoHTML(state: State): string {
    // prettier-ignore
    return ([] as Array<string | false | undefined>)
      .concat(
        `<b>Name:</b> ${state.username}<br/>`,
        !!state.birthday && `<b>Date of Birth:</b> ${moment(state.birthday).format("DD/MM/YYYY")}<br/>`,
        !!state.birthday && `<b>Underaged:</b> ${!!state.isUnderAged}<br/>`,
        `<b>Postcode:</b> ${state.userPostcode?.postcode || "N/A"}<br/>`
      )
      .filter(Boolean)
      .join("\n")
  }

  getClinicalNotesHTML(state: State): string {
    return ""
  }

  getQuestionnairesInfoHTML(state: State): string {
    return ""
  }
}

export default class AssessmentEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentEssex
  readonly name: string = "AssessmentEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentEssexDialogue.id, new AssessmentEssexScript(), state, snapshot)
  }
}
