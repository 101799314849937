import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import { DialogueIDs } from "../../DialogueIDs"
import { TrackingEvents } from "../../../models/Constants"

type State = BaseScriptState
export type ADSMConsentScriptState = State

export class ADSMConsentScript extends BaseScript<State> {
  readonly name: string = "ADSMConsentScript"

  /** Script Steps */
  @step.logState
  start(_d: IStepData<State>): IStepResult {
    const adsmEnabled = this.rootStore.configStore.adsmEnabled
    if (adsmEnabled && this.referralStore.isMainSpokenLanguageEnglish) {
      return { nextStep: this.intro }
    }
    return { nextStep: this.end }
  }

  @step.logState
  intro(_d: IStepData<State>): IStepResult {
    return {
      body: [
        `We're also running a project to improve my abilities to perform automated triage and help patients get to the right care more efficiently`,
        "Would you be interested in helping us out?"
      ],
      prompt: {
        id: this.getPromptId("intro"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Sure", value: true },
          { body: "Potentially", value: true },
          { body: "No, thank you", value: false }
        ],
        dataPointsName: "adsmIntro"
      },
      nextStep: this.handleIntro
    }
  }

  @step.logStateAndResponse
  handleIntro(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.consentADSM }
    }
    const name = this.getName(d.state)
    this.referralStore
      .updateReferral({
        adsm: {
          consentParticipate: false,
          selectedAsParticipant: false
        }
      })
      .catch(e => this.logException(e, "handleIntro -> updateReferral"))
    this.track(TrackingEvents.ADSM_NO_CONSENT_A)
    return {
      body: `No worries ${name}`,
      nextStep: this.end
    }
  }

  @step.logState
  consentADSM(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Great. We're running this project in collaboration with ${organisationName}. The purpose is to use referral data to build an AI-enabled automated triage system`,
        "Participating won't impact your referral (which has been submitted) or the care you receive",
        "Please read this [information sheet](https://static.limbic.ai/adsm-sheet), as it contains all the necessary details",
        "If you have any questions after reading the information sheet, please email us at [research@limbic.ai](research@limbic.ai)",
        "Once you've taken as much time as you need to read the sheet and discuss this project with anyone you'd like to, you can agree to consent below or decide to not participate"
      ],
      prompt: {
        id: this.getPromptId("consentADSM"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Consent now", value: true },
          { body: "I'd rather not participate", value: false }
        ],
        dataPointsName: "consentADSM"
      },
      nextStep: this.showConsentPrompt
    }
  }

  @step.logState
  showConsentPrompt(d: IStepData<State>): IStepResult {
    if (d.response) {
      return {
        prompt: {
          id: this.getPromptId("showConsentPrompt"),
          trackResponse: true,
          type: "checkbox",
          options: [
            {
              body: "I have read the information sheet",
              key: "readTheInformationSheetADSM",
              initialValue: false
            },
            {
              body: "I understand I am free to withdraw from the study by email without giving a reason",
              key: "agreeToWithdrawByEmailADSM",
              initialValue: false
            },
            {
              body: "I agree for my referral data to be used in line with the purpose of the project",
              key: "agreeReferralDataToBeUsedADSM",
              initialValue: false
            },
            {
              body: "I wish to participate in the project under the conditions set out in the information sheet",
              key: "wishToParticipateInTheProjectADSM",
              initialValue: false
            },
            {
              body: "I consent to my data being used in other ethically approved projects run by Limbic Limited",
              key: "consentDataUsedInOtherProjectsADSM",
              initialValue: false
            },
            {
              body: "I consent to participation in this project",
              key: "consentParticipationInProjectADSM",
              initialValue: false
            }
          ].filter(Boolean),
          isRequired: true
        },
        nextStep: this.handleConsentADSM
      }
    }
    this.referralStore
      .updateReferral({
        adsm: {
          consentParticipate: false,
          selectedAsParticipant: false
        }
      })
      .catch(e => this.logException(e, "handleConsentADSM -> updateReferral"))
    this.track(TrackingEvents.ADSM_NO_CONSENT_B)

    const name = this.getName(d.state)
    return {
      body: `No worries ${name}`,
      nextStep: this.end
    }
  }

  @step.logState
  handleConsentADSM(
    d: IStepData<
      State,
      {
        readTheInformationSheetADSM: boolean
        agreeToWithdrawByEmailADSM: boolean
        agreeReferralDataToBeUsedADSM: boolean
        wishToParticipateInTheProjectADSM: boolean
        consentDataUsedInOtherProjectsADSM: boolean
        consentParticipationInProjectADSM: boolean
      }
    >
  ): IStepResult {
    this.track(d.response ? TrackingEvents.ADSM_CONSENT : TrackingEvents.ADSM_NO_CONSENT)
    const readTheInformationSheetADSM = d.response.readTheInformationSheetADSM
    const agreeToWithdrawByEmailADSM = d.response.agreeToWithdrawByEmailADSM
    const agreeReferralDataToBeUsedADSM = d.response.agreeReferralDataToBeUsedADSM
    const wishToParticipateInTheProjectADSM = d.response.wishToParticipateInTheProjectADSM
    const consentDataUsedInOtherProjectsADSM = d.response.consentDataUsedInOtherProjectsADSM
    const consentParticipationInProjectADSM = d.response.consentParticipationInProjectADSM

    d.state.readTheInformationSheetADSM = readTheInformationSheetADSM
    d.state.agreeToWithdrawByEmailADSM = agreeToWithdrawByEmailADSM
    d.state.agreeReferralDataToBeUsedADSM = agreeReferralDataToBeUsedADSM
    d.state.wishToParticipateInTheProjectADSM = wishToParticipateInTheProjectADSM
    d.state.consentDataUsedInOtherProjectsADSM = consentDataUsedInOtherProjectsADSM
    d.state.consentParticipationInProjectADSM = consentParticipationInProjectADSM

    this.clinicalStore.randomiseADSMActivation()
    const isActive = this.clinicalStore.isADSMActive
    this.track(isActive ? TrackingEvents.ADSM_IS_ACTIVE : TrackingEvents.ADSM_IS_NOT_ACTIVE)
    this.referralStore
      .updateReferral({
        adsm: {
          consentParticipate: true,
          selectedAsParticipant: isActive
        }
      })
      .catch(e => this.logException(e, "handleConsentADSM -> updateReferral"))

    return {
      nextStep: this.sayKeepCopyOfInformationSheet
    }
  }

  @step.logState
  sayKeepCopyOfInformationSheet(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Thanks, ${name}. Please keep a copy of the information sheet (e.g. by bookmarking it) as this has valuable information should you need it`,
        `Remember, you can withdraw your consent at any time without giving a reason. All you need to do is email ${organisationName} as instructed in the information sheet`
      ],
      prompt: {
        id: this.getPromptId("sayKeepCopyOfInformationSheet"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Ok" }, { body: "I understand" }]
      },
      nextStep: this.sayThatsEverything
    }
  }

  @step.logState
  sayThatsEverything(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [`Thanks ${name}, let's continue`],
      nextStep: this.end
    }
  }
}

export default class ADSMConsentDialogue extends Dialogue<State> {
  static id = DialogueIDs.ADSMConsent
  readonly name: string = "ADSMConsentDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(ADSMConsentDialogue.id, new ADSMConsentScript(), state, snapshot)
  }
}
