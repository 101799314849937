import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import { PermissionsIAPTScript } from "./PermissionsIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { PermissionsScriptState } from "./PermissionsScript"

type State = PermissionsScriptState
export type PermissionsMHMScriptState = State

export class PermissionsMHMScript extends PermissionsIAPTScript {
  readonly name: string = "PermissionsMHMScript"

  /** Script Steps */

  @step.logState
  sayReady(_d: IStepData<State>): IStepResult {
    return {
      body: ["Thanks for letting us know", "Let's continue", "Ready?"],
      prompt: {
        id: this.getPromptId("sayReady"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Let's get started" }]
      },
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class PermissionsMHMDialogue extends Dialogue<State> {
  static id = DialogueIDs.PermissionsMHM
  readonly name: string = "PermissionsMHMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PermissionsMHMDialogue.id, new PermissionsMHMScript(), state, snapshot)
  }
}
