import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { AssessmentCCGScript, AssessmentCCGScriptState } from "./AssessmentCCGDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { DiscussionSteps } from "../../../models/Constants"
import dialoguesRegistry from "../../dialoguesRegistry"

type State = AssessmentCCGScriptState

export class AssessmentKentMedwayScript extends AssessmentCCGScript {
  readonly name: string = "AssessmentKentMedwayScript"

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    // Do not trigger progress bars until preliminary questions are asked
    return {
      nextStep: this.step1
    }
  }

  @step.logState
  askEmail(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "What's a good email address to reach you on?",
        "This will only be used to reach you for essential communication"
      ],
      prompt: { id: this.getPromptId("askEmail"), type: "email" },
      nextStep: this.handleEmail
    }
  }

  @step.logState
  goToPHQ9(d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setTotalProgressBars(4)
    this.rootStore.applicationStore.setCurrentProgressBar(1)
    this.rootStore.applicationStore.setCurrentProgress(0)

    const PHQ9Dialogue = this.discussionStore.getDialogueClass(DiscussionSteps.PHQ9)
    return {
      nextDialogue: PHQ9Dialogue //
        ? new PHQ9Dialogue({ ...d.state })
        : undefined,
      nextStep: this.handlePHQ9
    }
  }

  @step.logState
  goToIAPTEmploymentStatus(d: IStepData<State>): IStepResult {
    const IAPTEmploymentStatusKMDialogue = dialoguesRegistry.get(DialogueIDs.IAPTEmploymentStatusKM)
    return {
      body: ["Nearly there", "This last set of questions is about your work and day-to-day life"],
      nextDialogue: new IAPTEmploymentStatusKMDialogue({ ...d.state }),
      nextStep: this.handleIAPTEmploymentStatus
    }
  }

  /** Generic Handlers */

  async onHandleCanIContactYouOnPhoneNumber(state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askEmail }
  }

  async onHandleEmail(state: State): Promise<IStepResult<State>> {
    state.canSendEmail = true
    return { nextStep: this.askBirthday }
  }
}

export default class AssessmentKentMedwayDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentKentMedway
  readonly name: string = "AssessmentKentMedwayDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentKentMedwayDialogue.id, new AssessmentKentMedwayScript(), state, snapshot)
  }
}
