import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import BaseScript, { BaseScriptState } from "../../BaseScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { DiscussionSteps } from "../../../models/Constants"

type State = BaseScriptState

export type SelfReferralWellbeingHubScriptState = State

export class SelfReferralWellbeingHubScript extends BaseScript<State> {
  readonly name: string = "SelfReferralWellbeingHubScript"

  /** Script Steps */
  @step.logState
  start(_d: IStepData<State>): IStepResult<State> {
    const wellbeingHubUserType = this.wellbeingHubStore.userType

    if (wellbeingHubUserType === "managerTeam") {
      return { nextStep: this.goToSelfReferralManagerTeam }
    }
    if (wellbeingHubUserType === "managerIndividual") {
      return { nextStep: this.goToSelfReferralManagerIndividual }
    }
    return { nextStep: this.goToSelfReferralIndividual }
  }

  @step.logState
  goToSelfReferralManagerTeam(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const SelfReferralWellbeingHubManagerTeamDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.SelfReferralWellbeingHubManagerTeam)
    return {
      nextDialogue: SelfReferralWellbeingHubManagerTeamDialogue //
        ? new SelfReferralWellbeingHubManagerTeamDialogue({ ...d.state })
        : undefined,
      nextStep: this.end
    }
  }

  @step.logState
  goToSelfReferralManagerIndividual(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const SelfReferralWellbeingHubManagerIndividualDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.SelfReferralWellbeingHubManagerIndividual)
    return {
      nextDialogue: SelfReferralWellbeingHubManagerIndividualDialogue //
        ? new SelfReferralWellbeingHubManagerIndividualDialogue({ ...d.state })
        : undefined,
      nextStep: this.end
    }
  }

  @step.logState
  goToSelfReferralIndividual(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const SelfReferralWellbeingHubIndividualDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.SelfReferralWellbeingHubIndividual)
    return {
      nextDialogue: SelfReferralWellbeingHubIndividualDialogue //
        ? new SelfReferralWellbeingHubIndividualDialogue({ ...d.state })
        : undefined,
      nextStep: this.end
    }
  }

  /** Generic Handlers */
  async getReferralPayload(_state: State): Promise<Record<string, string>> {
    return {}
  }
}

export default class SelfReferralWellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralWellbeingHub
  readonly name: string = "SelfReferralWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralWellbeingHubDialogue.id,
      new SelfReferralWellbeingHubScript(),
      state,
      snapshot
    )
  }
}
