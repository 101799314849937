import React from "react"
import classNames from "classnames"
import "./ChatMessage.scss"
import IChatMessage from "../../../models/IChatMessage"
import BotBubble from "../ChatBubbles/BotBubble"
import UserBubble from "../ChatBubbles/UserBubble"
import TypingIndicator from "../ChatBubbles/TypingIndicator"
import ImageBubble from "../ChatBubbles/ImageBubble"

interface Props {
  message: IChatMessage
  onUndoPress?: (id: string) => void
}

export default function ChatMessage(props: Props): JSX.Element {
  const { message } = props
  const onUndoPress = React.useCallback(
    () => message.isUndoAble && props.onUndoPress?.(message.id), //
    [message.isUndoAble, message.id, props]
  )
  const isBot = message.author === "bot"
  const containerCSS = classNames("lb-m-container", {
    last: message.isLast,
    staticReferralURL: message.isStaticReferralURL
  })

  const messageComponent = React.useMemo(() => {
    switch (message.type) {
      case "text":
        if (message.author === "bot") {
          // Not sure where this comes from or why its here - do we need it?
          if (message!.body!.includes("What's your full name? ✏️")) {
            message.body = "What's your full name?"
          }
          return (
            <BotBubble //
              isFirst={message.isFirst}
              isLast={message.isLast}
              message={message.body}
            />
          )
        }
        return (
          <UserBubble
            isFirst={message.isFirst}
            isLast={message.isLast}
            message={message.body}
            isUndoAble={message.isUndoAble}
            onUndoPress={onUndoPress}
          />
        )
      case "image":
        return (
          <ImageBubble //
            image={message.image}
            message={message.body}
            isFirst={!!message.isFirst}
            isLast={!!message.isLast}
          />
        )
      case "typingIndicator":
        return (
          <>
            <div className="spacer" />
            <TypingIndicator />
          </>
        )
      default:
        return null
    }
  }, [message, onUndoPress])
  return (
    <li className={containerCSS}>
      {isBot && message.isFirst && (
        <img
          className="lb-m-bot-logo"
          src={"https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png"}
          alt={"Logo"}
          aria-label="logo"
        />
      )}
      {messageComponent}
    </li>
  )
}
