import createSurveyDialogue from "../createSurveyDialogue"
import ISurvey, { SurveyType } from "../../models/ISurvey"
import { DialogueIDs } from "../DialogueIDs"

const SpecificPhobia: ISurvey = {
  startingQuestion:
    "Please respond to each item by marking one box per question\n\nDuring the PAST 7 DAYS, I have...",
  questions: [
    {
      id: "1",
      questionName: "SpecificPhobia - step1",
      question: "1. felt moments of sudden terror, fear, or fright in these situations",
      type: "inline",
      progress: 0.18,
      answers: [
        { points: 0, answer: "Never" },
        { points: 1, answer: "Occasionally" },
        { points: 2, answer: "Half of the time" },
        { points: 3, answer: "Most of the time" },
        { points: 4, answer: "All of the time" }
      ]
    },
    {
      id: "2",
      questionName: "SpecificPhobia - step2",
      question: "2. felt anxious, worried, or nervous about these situations",
      type: "inline",
      progress: 0.27,
      answers: [
        { points: 0, answer: "Never" },
        { points: 1, answer: "Occasionally" },
        { points: 2, answer: "Half of the time" },
        { points: 3, answer: "Most of the time" },
        { points: 4, answer: "All of the time" }
      ]
    },
    {
      id: "3",
      questionName: "SpecificPhobia - step3",
      question:
        "3. had thoughts of being injured, overcome with fear, or other bad things happening in these situations",
      type: "inline",
      progress: 0.36,
      answers: [
        { points: 0, answer: "Never" },
        { points: 1, answer: "Occasionally" },
        { points: 2, answer: "Half of the time" },
        { points: 3, answer: "Most of the time" },
        { points: 4, answer: "All of the time" }
      ]
    },
    {
      id: "4",
      questionName: "SpecificPhobia - step4",
      question:
        "4. felt a racing heart, sweaty, trouble breathing, faint, or shaky in these situations",
      type: "inline",
      progress: 0.45,
      answers: [
        { points: 0, answer: "Never" },
        { points: 1, answer: "Occasionally" },
        { points: 2, answer: "Half of the time" },
        { points: 3, answer: "Most of the time" },
        { points: 4, answer: "All of the time" }
      ]
    },
    {
      id: "5",
      questionName: "SpecificPhobia - step5",
      question:
        "5. felt tense muscles, felt on edge or restless, or had trouble relaxing in these situations",
      type: "inline",
      progress: 0.54,
      answers: [
        { points: 0, answer: "Never" },
        { points: 1, answer: "Occasionally" },
        { points: 2, answer: "Half of the time" },
        { points: 3, answer: "Most of the time" },
        { points: 4, answer: "All of the time" }
      ]
    },
    {
      id: "6",
      questionName: "SpecificPhobia - step6",
      question: "6. avoided, or did not approach or enter, these situations",
      type: "inline",
      progress: 0.63,
      answers: [
        { points: 0, answer: "Never" },
        { points: 1, answer: "Occasionally" },
        { points: 2, answer: "Half of the time" },
        { points: 3, answer: "Most of the time" },
        { points: 4, answer: "All of the time" }
      ]
    },
    {
      id: "7",
      questionName: "SpecificPhobia - step7",
      question: "7. moved away from these situations or left them early",
      type: "inline",
      progress: 0.72,
      answers: [
        { points: 0, answer: "Never" },
        { points: 1, answer: "Occasionally" },
        { points: 2, answer: "Half of the time" },
        { points: 3, answer: "Most of the time" },
        { points: 4, answer: "All of the time" }
      ]
    },
    {
      id: "8",
      questionName: "SpecificPhobia - step8",
      question:
        "8. spent a lot of time preparing for, or procrastinating about (i.e., putting off), these situations",
      type: "inline",
      progress: 0.81,
      answers: [
        { points: 0, answer: "Never" },
        { points: 1, answer: "Occasionally" },
        { points: 2, answer: "Half of the time" },
        { points: 3, answer: "Most of the time" },
        { points: 4, answer: "All of the time" }
      ]
    },
    {
      id: "9",
      questionName: "SpecificPhobia - step9",
      question: "9. distracted myself to avoid thinking about these situations",
      type: "inline",
      progress: 0.9,
      answers: [
        { points: 0, answer: "Never" },
        { points: 1, answer: "Occasionally" },
        { points: 2, answer: "Half of the time" },
        { points: 3, answer: "Most of the time" },
        { points: 4, answer: "All of the time" }
      ]
    },
    {
      id: "10",
      questionName: "SpecificPhobia - step10",
      question:
        "10. needed help to cope with these situations (e.g., alcohol or medications, superstitious objects, other people)",
      type: "inline",
      progress: 1,
      answers: [
        { points: 0, answer: "Never" },
        { points: 1, answer: "Occasionally" },
        { points: 2, answer: "Half of the time" },
        { points: 3, answer: "Most of the time" },
        { points: 4, answer: "All of the time" }
      ]
    }
  ]
}

export default createSurveyDialogue(
  DialogueIDs.SpecificPhobia,
  SpecificPhobia,
  SurveyType.SpecificPhobia
)
