import React, { useState } from "react"
import "./InlineButtonWithAccordion.scss"
import ISelectable from "../../../models/ISelectable"
import classNames from "classnames"
import BubbleButton from "../BubbleButton/BubbleButton"
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons"
import { useConfigStore } from "../../contexts/RootStoreContext"

interface Props {
  disabled?: boolean
  btn: ISelectable
  fullWidth?: boolean
  buttonClassName?: string
  className?: string
  buttonContainerClassName?: string
  style?: any
  textStyle?: any
  onSelect: (btn: ISelectable) => void
  content: string
}

export default function InlineButtonWithAccordion(props: Props): JSX.Element {
  const {
    disabled,
    btn,
    fullWidth,
    className,
    buttonClassName,
    buttonContainerClassName,
    onSelect,
    style,
    textStyle,
    content
  } = props
  const [showContent, setShowContent] = useState(false)
  const config = useConfigStore()

  const onClick = React.useCallback(() => {
    if (btn.onPress) {
      btn.onPress(btn)
      return
    }
    onSelect(btn)
  }, [btn, onSelect])
  const containerCSS = classNames("lb-inline-button-container", className, { fullWidth, disabled })
  const buttonCSS = classNames(buttonClassName)

  return (
    <div>
      <button className={containerCSS}>
        <BubbleButton
          onPress={onClick}
          disabled={!!disabled}
          containerClassName={buttonContainerClassName}
          className={buttonCSS}
          style={style}
        >
          <span className={"lb-inline-button-text"} style={textStyle}>
            <div>{btn.body}</div>
            {showContent ? (
              <div id="button-description" dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
              ""
            )}
          </span>
        </BubbleButton>
        {
          <div onClick={() => setShowContent(!showContent)}>
            {showContent ? (
              <ArrowDropUp
                style={{ color: config.userMessageBackground }}
                data-testid={`info-button-up-${btn.body}`}
                id="info-button"
              />
            ) : (
              <ArrowDropDown
                style={{ color: config.userMessageBackground }}
                data-testid={`info-button-down-${btn.body}`}
                id="info-button"
              />
            )}
          </div>
        }
      </button>
    </div>
  )
}
