import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"

type State = GoodbyeScriptState

export class GoodbyeTrentPTSScript extends GoodbyeScript {
  readonly name: string = "GoodbyeTrentPTSScript"

  /** Generic Handlers */

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      return `Because you identified as being in crisis, someone from our duty of care team will review your information and may call you if we need any more information. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger.`
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      return `Because you identified as being in crisis, someone from our duty of care team will review your information and may call you if we need any more information. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger.`
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeTrentPTSDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeTrentPTS
  readonly name: string = "GoodbyeTrentPTSDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeTrentPTSDialogue.id, new GoodbyeTrentPTSScript(), state, snapshot)
  }
}
