import Logger from "../../utils/Logger"
import { backendEnv } from "../../config/config"
import delay from "../../utils/delay"
import { postToSlack } from "../../utils/slack"
import errorMessage from "../../utils/parseErrorMessage"
import NetworkError from "../../models/NetworkError"
import { SendEmailStatus } from "../../models/ISendEmail"
import invariant from "../../utils/invariant"

const TOTAL_RETRIES = 2

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/v1/email/send",
  development: "https://access-v2-staging.limbic.ai/v1/email/send",
  production: "https://access-v2.limbic.ai/v1/email/send"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const POST_SEND_EMAIL_URL = endpoints[backendEnv] || endpoints.production
const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

interface Props {
  to: string[]
  from?: string
  cc?: string[]
  bcc?: string[]
  text: string
  subject?: string
  attachmentUrls?: string[]
}

export default async function sendEmail(
  props: Props,
  organisationName?: string,
  retry = 0
): Promise<SendEmailStatus> {
  const { to, cc, bcc, text, subject, attachmentUrls, from } = props
  const body = JSON.stringify({ to, cc, bcc, text, subject, attachmentUrls, from })
  try {
    const response = await fetch(POST_SEND_EMAIL_URL, { body, method, headers })
    if (!response.ok) {
      const text = await response?.text()
      const message = errorMessage(response, { message: text })
      throw new NetworkError(String(response.status), message)
    }
    return SendEmailStatus.Success
  } catch (e) {
    Logger.getInstance().exception(e, "sendEmail fetch failed")
    if (retry < TOTAL_RETRIES) {
      logLongJSON(`email data for retry ${retry}`, body)
      Logger.getInstance().message("sendEmail Retry")
      void postToSlack({ text: getSlackRetryMessage(organisationName, subject, to, retry) })
      await delay(3 * retry || 1.5)
      return await sendEmail(props, organisationName, retry + 1)
    }
    logLongJSON("sendEmail fetch failed data", body)
    Logger.getInstance().exception(e, "sendEmail fetch failed")
    void postToSlack({ text: getSlackFailureMessage(organisationName, subject, to, retry) })
    return SendEmailStatus.SendEmailFailed
  }
}

function getSlackRetryMessage(
  organisationName: string | undefined,
  subject: string | undefined,
  to: string[],
  retry: number
): string {
  return `🔄 *RETRY [${organisationName}]:* Email with subject *${subject}* to *${to}* failed to sent on attempt ${
    retry + 1
  } [Limbic Access Bot]`
}

function getSlackFailureMessage(
  organisationName: string | undefined,
  subject: string | undefined,
  to: string[],
  retries: number
): string {
  return `❌ *FAILURE [${organisationName}]:* Email with subject *${subject}* to *${to}* failed to sent after ${retries} retries [Limbic Access Bot]`
}

function logLongJSON(message: string, json: string) {
  try {
    const split = json.match(/(.|[\r\n]){1,1000}/g)
    split?.forEach(body => Logger.getInstance().breadcrumb({ message, data: { body } }))
  } catch (e) {
    console.error(e)
  }
}
