import NetworkError from "../../models/NetworkError"
import Logger from "../../utils/Logger"
import IDynamicLink from "../../models/IDynamicLink"
import { backendEnv } from "../../config/config"
import invariant from "../../utils/invariant"

const endpoints = {
  staging: "https://access-v2-staging.limbic.ai/v1/dynamic-links/create",
  development: "https://access-v2-staging.limbic.ai/v1/dynamic-links/create",
  production: "https://access-v2.limbic.ai/v1/dynamic-links/create"
}

const API_KEY = process.env.REACT_APP_ACCESS_BACKEND_API_KEY
invariant(API_KEY, "Environment variable ACCESS_BACKEND_API_KEY was not found")
const POST_CREATE_DYNAMIC_LINK = endpoints[backendEnv] || endpoints.production

const method = "POST"
const headers = new Headers()
headers.set("Content-Type", "application/json")
headers.set("x-api-key", API_KEY)

export async function createDynamicLink(data: IDynamicLink): Promise<string | undefined> {
  try {
    const payload = { ...data }
    const body = JSON.stringify(payload)
    const result = await fetch(POST_CREATE_DYNAMIC_LINK, { method, headers, body })

    const res = await result.json()

    if (!res?.success) {
      throw new NetworkError(undefined, res?.error)
    }

    return res?.data?.dynamicLink
  } catch (e) {
    Logger.getInstance().exception(e, `post ${POST_CREATE_DYNAMIC_LINK} failed`)
  }
}
