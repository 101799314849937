import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import CrisisScript, { CrisisScriptState } from "./CrisisScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { RiskLevel, RiskLevelReason } from "../../../models/Constants"
import CrisisDetector from "../../../models/CrisisDetector"

type State = CrisisScriptState
export type CrisisInsightState = State
export class CrisisInsightScript extends CrisisScript {
  readonly name: string = "CrisisInsightScript"

  /** Script Steps */

  @step
  /**
   * Extending due to a special request to mark all users
   * with crisis word at least moderate risk-level.
   */
  async handleAreYouInCrisis(d: IStepData<State, boolean>): Promise<IStepResult> {
    this.clinicalStore.setIsCrisis(d.response)
    this.setCrisisDetectionCorrect(d.state, d.response)

    this.blockUndo(d.state)

    d.response
      ? this.setRiskLevelHigh(d.state, RiskLevelReason.CRISIS_DETECTION)
      : this.setRiskLevelModerate(d.state, RiskLevelReason.CRISIS_DETECTION)

    this.referralStore
      .updateReferral({
        riskLevel: d.response ? this.clinicalStore.riskLevel : RiskLevel.Moderate,
        riskLevelReason: this.clinicalStore.riskLevelReason,
        triggerWords: this.clinicalStore.triggerWords
      })
      .catch(e => this.logException(e, "handleAreYouInCrisis -> updateReferral"))

    this.updateReferralType(d.state)

    const result = await this.onHandleAreYouInCrisis?.(d.state)
    if (result) return result

    if (!d.response && d.state.disableDetectionIfWrong) {
      CrisisDetector.getInstance().disableCrisisDetectionForNextInput()
    }
    return {
      nextStep: d.response //
        ? this.saySorryToHear
        : this.saySorryForTheMisunderstanding
    }
  }

  @step
  saySorryToHear(d: IStepData<State>): IStepResult {
    const crisisPhoneNumbers = this.rootStore.configStore.crisisPhoneNumbers
    const name = this.getName(d.state)
    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "However, this is not an emergency response service",
        "You can visit this [link](https://www.insightiapt.org/need-urgent-help/) to find out more about the options you have",
        "If you need urgent, life threatening medical help please call [999](tel:999)",
        `Alternatively, you can call our services if it's not a life-threatening situation on the following numbers:\n${crisisPhoneNumbers}`
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSorryToHear
    }
  }

  @step.logState
  handleSorryToHear(d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999 and crisis numbers from services")
    return { nextStep: d.state.referralSubmitted ? this.sayTheyWillCall : this.end }
  }

  sayTheyWillCall(d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName
    return {
      body: `One of ${serviceName}'s clinical team will contact you on ${d.state.phoneNumber} within one working day to check that you have referred yourself in to one of the crisis support services`,
      nextStep: this.end
    }
  }

  /* Generic Handlers */

  async onHandlePhoneNumber(_state: State): Promise<IStepResult | void> {
    return { body: "Thank you", nextStep: this.sayTheyWillCall }
  }
}

/* istanbul ignore next */
export default class CrisisInsightDialogue extends AdHocDialogue<State, CrisisInsightScript> {
  static id = DialogueIDs.CrisisInsight
  readonly name: string = "CrisisInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(CrisisInsightDialogue.id, new CrisisInsightScript(), state, snapshot)
  }
}
