import { z } from "zod"

export default interface IPostcode {
  postcode: string
  longitude: number
  latitude: number
  ccg: string
  ccgId: string
}

export const IPostcodeSchema = z.object({
  postcode: z.string(),
  longitude: z.number(),
  latitude: z.number(),
  ccg: z.string(),
  ccgId: z.string()
})

export enum PostcodeStatus {
  Success = "SUCCESS",
  InvalidPostcode = "INVALID_POSTCODE",
  RequestFailed = "REQUEST_FAILED",
  NoInternetConnection = "NO_INTERNET_CONNECTION",
  PostcodeNotFound = "POSTCODE_NOT_FOUND"
}
