import { AssessmentPitchIAPTScript } from "./AssessmentPitchIAPTDialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { AssessmentPitchIAPTScriptState } from "./AssessmentPitchIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = AssessmentPitchIAPTScriptState

export class AssessmentPitchInsightScript extends AssessmentPitchIAPTScript {
  readonly name: string = "AssessmentPitchInsightScript"

  /** Script Steps */

  @step.logState
  checkForNeedToBeCalled(d: IStepData<State>): IStepResult {
    return { nextStep: this.askWannaDoQuestionnaires }
  }

  @step.logState
  askWannaDoQuestionnaires(d: IStepData<State>): IStepResult {
    return {
      body: [
        "I'm now going to ask you a few questions to measure symptoms of common mental health issues",
        "And I will use the results to match you with the best therapist for your needs"
      ],
      prompt: {
        id: this.getPromptId("askWannaDoQuestionnaires"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWannaDoQuestionnaires
    }
  }
}

/* istanbul ignore next */
export default class AssessmentPitchInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentPitchInsight
  readonly name: string = "AssessmentPitchInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentPitchInsightDialogue.id, new AssessmentPitchInsightScript(), state, snapshot)
  }
}
