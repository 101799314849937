import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState

export type UnderAgeSignpostNorfolkScriptState = State

export class UnderAgeSignpostNorfolkScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostNorfolkScript"

  /** Script Steps */
  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const threshold = this.getIAPTServiceAgeThreshold(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, there's a special type of mental health support for people under the age of ${threshold}`,
        `${iaptName} do not currently provide this`,
        "However, you can find loads of helpful resources from Norfolk & Waverley NHS Trust [here](https://www.justonenorfolk.nhs.uk/)",
        "Or you can talk to your doctor and they will help you",
        "To find the best service for you, there's a helpful website [here](https://www.nhs.uk/service-search/other-services/Mental-health-support-for-young-people/LocationSearch/1430)"
      ],
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: "okay" },
          { body: "Thanks", value: "thanks" }
        ]
      },
      nextStep: this.handleNotEligible
    }
  }
}

/* istanbul ignore next */
export default class UnderAgeSignpostNorfolkDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderageSignpostNorfolk
  readonly name: string = "UnderAgeSignpostNorfolkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostNorfolkDialogue.id, new UnderAgeSignpostNorfolkScript(), state, snapshot)
  }
}
