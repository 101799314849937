import React from "react"
import isEmail from "validator/lib/isEmail"
import "./EmailInput.scss"
import TextInput from "./TextInput"

interface Props {
  placeholder?: string
  onSubmit: (body: string, value: string) => void
}

export default function EmailInput(props: Props): JSX.Element {
  const { onSubmit, placeholder } = props
  const [email, setEmail] = React.useState<string | undefined>("")
  const [error, setError] = React.useState<string | undefined>()

  const updateEmail = React.useCallback((text?: string) => {
    setEmail(text)
    setError(undefined)
  }, [])

  const submit = React.useCallback(() => {
    if (!email) {
      setError("You need to fill in your email first")
      return
    }
    if (!isEmail(email)) {
      setError("This is not a valid email address")
      return
    }
    const emailFormatted = email.toLowerCase?.()
    onSubmit?.(`Email: ${emailFormatted}`, emailFormatted)
  }, [email, onSubmit])
  return (
    <>
      {!!error && (
        <div className="lb-email-input-error-container">
          <span className="lb-email-input-error">{error}</span>
        </div>
      )}
      <TextInput
        forceValue
        borderless
        value={email}
        placeholder={placeholder || "Enter your Email"}
        clearOnSubmit={false}
        multiline={false}
        autoCorrect={"none"}
        autoCapitalize={"none"}
        autoComplete={"email"}
        onChangeText={updateEmail}
        onSubmit={submit}
      />
    </>
  )
}
