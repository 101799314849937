import { IClinicalPath, IClinicalPathOverride } from "../../../models/IClinicalPath"

//prettier-ignore
export const clinicalPathsNorfolk: Record<IClinicalPath["id"], IClinicalPathOverride> = {
  complex_comorbid: { defaultReferralType: "PWP (Complex Comorbid)" },
  undetermined: { defaultReferralType: "PWP (Undetermined)" },
  "1": {
    defaultReferralType: "PWP (Below Caseness)"
  },
  "2": {
    defaultReferralType: "PWP (Below Caseness)"
  },
  "3": {
    defaultReferralType: "PWP (Below Caseness)"
  },
  "4": {
    defaultReferralType: "PWP (Moderate Depression)"
  },
  "5": {
    defaultReferralType:
      "PWP (Moderate Depression, Moderately Severe Functional Impairment)"
  },
  "6": {
    defaultReferralType:
      "PWP (Moderate Depression, Severe Functional Impairment)"
  },
  "7": {
    defaultReferralType: "PWP (Moderately Severe Depression)"
  },
  "8": {
    defaultReferralType:
      "PWP (Moderately Severe Depression, Moderately Severe Functional Impairment)"
  },
  "9": {
    defaultReferralType:
      "PWP (Moderately Severe Depression, Severe Functional Impairment)"
  },
  "10": {
    defaultReferralType: "PWP (Severe Depression)"
  },
  "11": {
    defaultReferralType:
      "PWP (Severe Depression, Moderately Severe Functional Impairment)"
  },
  "12": {
    defaultReferralType: "PWP (Severe Depression, Severe Functional Impairment)"
  },
  "13": {
    defaultReferralType: "PWP (Social Phobia)"
  },
  "14": {
    defaultReferralType: "PWP (Social Phobia, Moderately Severe Functional Impairment)"
  },
  "15": {
    defaultReferralType: "PWP (Social Phobia, Severe Functional Impairment)"
  },
  "16": {
    defaultReferralType: "PWP (Specific Phobia)"
  },
  "17": {
    defaultReferralType:
      "PWP (Specific Phobia, Moderately Severe Functional Impairment)"
  },
  "18": {
    defaultReferralType: "PWP (Specific Phobia, Severe Functional Impairment)"
  },
  "19": {
    defaultReferralType: "PT (Needle/Blood/Injury Phobia)"
  },
  "20": {
    defaultReferralType:
      "PT (Needle/Blood/Injury Phobia, Moderately Severe Functional Impairment)"
  },
  "21": {
    defaultReferralType:
      "PT (Needle/Blood/Injury Phobia, Severe Functional Impairment)"
  },
  "22": {
    defaultReferralType: "PWP (Panic)"
  },
  "23": {
    defaultReferralType: "PWP (Panic, Moderately Severe Functional Impairment)"
  },
  "24": {
    defaultReferralType: "PWP (Severe Panic, Severe Functional Impairment)"
  },
  "25": {
    defaultReferralType: "PWP (Moderate GAD/Worry)"
  },
  "26": {
    defaultReferralType:
      "PWP (Moderate GAD/Worry, Moderately Severe Functional Impairment)"
  },
  "27": {
    defaultReferralType:
      "PWP (Moderate GAD/Worry, Severe Functional Impairment)"
  },
  "28": {
    defaultReferralType: "PWP (Severe GAD/Worry)"
  },
  "29": {
    defaultReferralType:
      "PWP (Severe GAD/Worry, Moderately Severe Functional Impairment)"
  },
  "30": {
    defaultReferralType: "PWP (Severe GAD/Worry, Severe Functional Impairment)"
  },
  "31": {
    defaultReferralType: "PWP (Health Anxiety)"
  },
  "32": {
    defaultReferralType: "PWP (Health Anxiety, Moderately Severe Functional Impairment)"
  },
  "33": {
    defaultReferralType: "PWP (Health Anxiety, Severe Functional Impairment)"
  },
  "34": {
    defaultReferralType: "PWP (OCD)"
  },
  "35": {
    defaultReferralType: "PWP Assessment (OCD, Moderately Severe Functional Impairment)"
  },
  "36": {
    defaultReferralType: "PWP Assessment (OCD, Severe Functional Impairment)"
  },
  "37": {
    defaultReferralType: "PT Assessment (PTSD)"
  },
  "38": {
    defaultReferralType: "PT Assessment (PTSD, Moderately Severe Functional Impairment)"
  },
  "39": {
    defaultReferralType: "PT Assessment (PTSD, Severe Functional Impairment)"
  },
  "40": {
    defaultReferralType: "PWP (Anxiety Disorder (Undetermined))"
  },
  "41": {
    defaultReferralType:
      "PWP (Anxiety Disorder (Undetermined) with Moderately Severe functional impairment)"
  },
  "42": {
    defaultReferralType:
      "PWP (Anxiety Disorder (Undetermined) with Severe functional impairment)"
  },
  "43": {
    defaultReferralType:
      "PWP (Agoraphobia)"
  }, "44": {
    defaultReferralType:
      "PWP (Agoraphobia with Moderately Severe functional impairment)"
  }, "45": {
    defaultReferralType:
      "PWP (Agoraphobia with Severe functional impairment)"
  }
}
