import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScriptState } from "./GetNameScript"
import { GetNameIAPTScript } from "./GetNameIAPTDialogue"
import { NorfolkPreferredPronouns, Titles } from "../../../models/Constants"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

interface State extends GetNameScriptState {
  preferredName?: string
  preferredPronouns?: NorfolkPreferredPronouns
  preferredPronounsOther?: string
}

export type GetNameNorfolkScriptState = State

export class GetNameNorfolkScript extends GetNameIAPTScript {
  readonly name: string = "GetNameNorfolkScript"

  /** Script Steps */
  @step
  step1(_d: IStepData<State>): IStepResult {
    const { directReferral } = this.rootStore.configStore
    const body = directReferral
      ? "I'm now going to create a personal record for you"
      : "Ok, I'm just going to create a personal record for you"
    const prompt = directReferral
      ? ({
          type: "inlinePicker",
          choices: [{ body: "Okay" }]
        } as IInlinePickerSingleSelectPrompt)
      : undefined
    return { body, prompt, nextStep: this.askFullName }
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "We also offer online workshops where you do not need an assessment, you can simply book onto them",
        "If you are interested click [here](https://www.wellbeingnands.co.uk/norfolk/get-support/courses/)",
        "So, what's your full name? ✏️"
      ],
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  askIsPreferredName(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Is ${name} your preferred first name?`,
      prompt: {
        id: this.getPromptId("askIsPreferredName"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleIsPreferredName
    }
  }

  @step.logStateAndResponse
  async handleIsPreferredName(d: IStepData<State, boolean>): Promise<IStepResult> {
    return {
      nextStep: d.response //
        ? this.sayNiceToMeetYou
        : this.sayPleaseGivePreferredName
    }
  }

  @step
  sayPleaseGivePreferredName(_d: IStepData<State>): IStepResult {
    return {
      body: "What would you like to be called?",
      nextStep: this.showPromptForPreferredName
    }
  }

  @step.logState
  showPromptForPreferredName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForPreferredName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePreferredNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: GetNameNorfolkScript) => {
    const preferredName = d.response?.trim()
    d.state.preferredName = preferredName
    script.rootStore.applicationStore.setPreferredName(preferredName)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: GetNameNorfolkScript) => s.checkPreferredName
  })
  async handlePreferredNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkPreferredName }
  }

  @step.logState
  async checkPreferredName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.preferredName || d.state.preferredName.trim() === "") {
      return { nextStep: this.sayPleaseGivePreferredName }
    }
    return { nextStep: this.sayNiceToMeetYou }
  }

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Nice to meet you ${name}`,
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: "Nice to meet you too" }]
      },
      nextStep: this.askHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  askHowWouldYouLikeToBeAddressed(d: IStepData<State>): IStepResult {
    const titles = this.getTitles()
    if (!titles?.length) {
      this.logBreadcrumb("TITLES NOT FOUND", d.state, { titles })
      this.logMessage("TITLES NOT FOUND")
      return { nextStep: this.askPreferredPronouns }
    }

    return {
      body: "And how would you like to be addressed?",
      prompt: {
        id: this.getPromptId("askHowWouldYouLikeToBeAddressed"),
        type: "inlinePicker",
        choices: titles.map(title => ({ body: title, value: title })),
        isUndoAble: true
      },
      nextStep: this.handleHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, Titles>, script: GetNameNorfolkScript) => {
    d.state.nameTitle = d.response
  })
  handleHowWouldYouLikeToBeAddressed(d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPreferredPronouns }
  }

  @step.logState
  askPreferredPronouns(d: IStepData<State>): IStepResult {
    const pronouns = this.getPreferredPronouns(d.state)
    if (!pronouns?.length) {
      this.logBreadcrumb("PRONOUNS NOT FOUND", d.state, { pronouns })
      this.logMessage("PRONOUNS NOT FOUND")
      return { nextStep: this.end }
    }
    return {
      body: "What are your pronouns?",
      prompt: {
        id: this.getPromptId("askPreferredPronouns"),
        trackResponse: true,
        type: "inlinePicker",
        choices: pronouns.filter(i => i !== "Other").map(g => ({ body: g, value: g })),
        textPrompt: {
          forceValue: true,
          placeholder: "Other (please specify)"
        }
      },
      nextStep: this.handlePreferredPronouns
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, NorfolkPreferredPronouns>, script: GetNameNorfolkScript) => {
      const pronouns = script.getPreferredPronouns(d.state)
      if (!pronouns.includes(d.response)) {
        d.state.preferredPronouns = "Other"
        d.state.preferredPronounsOther = d.response
      } else {
        d.state.preferredPronouns = d.response
      }
    }
  )
  handlePreferredPronouns(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  async onCheckFullName(_state: State): Promise<IStepResult> {
    return {
      nextStep: this.askIsPreferredName
    }
  }

  getTitles(): Titles[] {
    return [
      Titles.MR,
      Titles.MRS,
      Titles.MISS,
      Titles.MS,
      Titles.MX,
      Titles.DR,
      Titles.REV,
      Titles.PROF
    ]
  }

  getPreferredPronouns(_state: State): NorfolkPreferredPronouns[] {
    return ["He/him/his", "She/her/hers", "They/them/theirs", "Sie/hir/hirs", "Other"]
  }
}

export default class GetNameNorfolkDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameNorfolk
  readonly name: string = "GetNameNorfolkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameNorfolkDialogue.id, new GetNameNorfolkScript(), state, snapshot)
  }
}
