import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import GoodbyeScript from "./GoodbyeScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { RiskLevel, RiskLevelReason } from "../../../models/Constants"

interface State extends GoodbyeScriptState {
  appointment?: string
  requiresUrgentSupport?: boolean
}

export type GoodbyeInsightScriptState = State

export class GoodbyeInsightScript extends GoodbyeScript {
  readonly name: string = "GoodbyeInsightScript"

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    void this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()

    return {
      nextStep: this.sayDynamicLink
    }
  }

  @step.logState
  sayFinalWords(d: IStepData<State, true | undefined>): IStepResult {
    const name = this.getName(d.state)
    const great = d.response ? ["Great"] : []
    return {
      body: [
        ...great,
        "Well done for taking the first step towards improving your mental health",
        "For more information on symptoms, treatment, and support visit 'Mind' here: [https://www.mind.org.uk](https://www.mind.org.uk)",
        `Goodbye ${name} 👋`
      ],
      nextStep: this.end
    }
  }

  /** Generic Handler */

  async onHandleRecapMessage(
    state: State,
    body: string[],
    recapMessage: string | undefined
  ): Promise<IStepResult> {
    const choices = [{ body: "Okay" }]
    if (recapMessage) {
      choices.push({ body: "I understand" })
    }

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askFeedback
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    const isHighRisk = this.clinicalStore.isHighRisk
    if (isHighRisk) {
      return this.getRiskRecapMessage(state)
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    const isModerateRisk = this.clinicalStore.isModerateRisk
    if (isModerateRisk) {
      return this.getRiskRecapMessage(state)
    }
  }

  getRiskRecapMessage(state: State): string | undefined {
    // TODO: ask for recap message when we reach the end of the discussion
    //       and we didn't refer the patient because they are in crisis or
    //       said they need urgent support
    // TODO: i understand that we shouldn't mark as moderate risk here if patient needs urgent support
    const serviceName = this.rootStore.configStore.serviceName
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(state) || organisationName
    const canKeepSelfSafe = state.canKeepSelfSafe ?? true
    /**
     * Insight requested that if crisis is triggered user
     * should be marked at a minimum of medium risk-level.
     * For this reason, it is possible to reach the recap message with
     * below-caseness and moderate risk-level.
     */
    const crisisDetectedWithNoCrisis =
      !state.canKeepSelfSafe &&
      this.clinicalStore.riskLevel === RiskLevel.Moderate &&
      !this.clinicalStore.isCrisis &&
      this.clinicalStore.riskLevelReason === RiskLevelReason.CRISIS_DETECTION

    if (state.referralSubmitted) {
      if (!canKeepSelfSafe) {
        return (
          "Some of your answers suggest you may be struggling quite a bit right now.\n\n" +
          `As such, one of ${serviceName}'s clinicians will call you within 1 working day to check in and get you booked in to an assessment.\n\n` +
          "In the mean time, if you need urgent help please follow this [link](https://www.insightiapt.org/need-urgent-help/local-nhs-crisis-contacts/) to see what services are available to you locally"
        )
      }
      return (
        `Some of your answers suggest you may be struggling ${
          crisisDetectedWithNoCrisis ? "" : "quite "
        }a bit right now.\n\n` +
        `An ${serviceName} Patient Coordinator will be in contact with you within 2 working days to schedule an assessment appointment with one of our clinicians.\n\n` +
        "Assessment appointments can take up to 3 weeks to access in some of our busier services." +
        (crisisDetectedWithNoCrisis
          ? ""
          : "\n\nAs you are waiting to access our service, if you feel you may act on suicidal thoughts, cannot keep yourself or are a risk to others around you, please call 999 or look for your local crisis team contact details by following this [link](https://www.insightiapt.org/need-urgent-help/local-nhs-crisis-contacts/)")
      )
    }
    return `Because you are in need of urgent support, I cannot refer you into ${iaptName}. Please contact us on the numbers I shared above. If you feel at immediate risk of harming yourself, or can't keep yourself safe, call 999 or visit your local A&E department. If you are not at risk but need urgent support, visit [www.insightiapt.org/help](www.insightiapt.org/help) to find 24/7 support contacts`
  }

  async getTreatmentMessage(state: State): Promise<string | void> {
    const serviceName = this.rootStore.configStore.serviceName
    const treatment = this.clinicalStore.getAcceptedTreatment()
    const canKeepSelfSafe = state.canKeepSelfSafe ?? true

    if (!state.isEligible) return undefined

    if (treatment) {
      const treatmentMap = {
        ieso: "typed Cognitive Behavioural Therapy (CBT)",
        webinar: "attending a Wellbeing Webinar series",
        ccbt: "digital therapy or guided self-help",
        gsh: "digital therapy or guided self-help"
      }
      let message = `Based on your answers you may benefit from ${treatmentMap[treatment.name]}`
      if (state.appointment) {
        message =
          message +
          `\n\nYour appointment slot has been booked to discuss the most appropriate treatment option for you with the ${serviceName} team`
      }
      return (
        message +
        `\n\nSomeone from ${serviceName} will contact you within 5 working days to book your assessment appointment\n\nPlease note, that they will call you from a withheld number\n\nIf you haven’t heard from them in this time, please give them a call to arrange your assessment`
      )
    }

    if (canKeepSelfSafe && !state.requiresUrgentSupport && !this.clinicalStore.isRisk) {
      return `One of ${serviceName}'s team will contact you within 5 working days to book your assessment appointment\n\nPlease note, that they will call you from a withheld number\n\nIf you haven’t heard from them in this time, please give them a call to arrange your assessment`
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeInsight
  readonly name: string = "GoodbyeInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeInsightDialogue.id, new GoodbyeInsightScript(), state, snapshot)
  }
}
