import createSurveyDialogue from "../createSurveyDialogue"
import ISurvey, { SurveyType } from "../../models/ISurvey"
import { DialogueIDs } from "../DialogueIDs"

const IAPTEmploymentStatusKM: ISurvey = {
  questions: [
    {
      id: "1",
      questionName: "iaptEmploymentStatusCurrentEmploymentStatus",
      question:
        "Please indicate which of the following options best describes your current employment status",
      type: "inline",
      progress: 0.1,
      answers: [
        { answer: "Not Stated" },
        { answer: "Employed (Full Time)" },
        { answer: "Employed (Part Time)" },
        { answer: "Employed (Self)" },
        { answer: "Unemployed (Seeking Work)" },
        { answer: "Unemployed" },
        { answer: "Benefits" },
        { answer: "Student (Full Time)" },
        { answer: "Student (part Time)" },
        { answer: "Homemaker" },
        { answer: "Retired" }
      ]
    },
    {
      id: "2",
      questionName: "iaptEmploymentStatusReceivingSickPay",
      question: "Are you currently off sick from work?",
      type: "inline",
      progress: 0.2,
      answers: [{ answer: "Yes" }, { answer: "No" }]
    }
  ]
}

const id = DialogueIDs.IAPTEmploymentStatusKM
export default createSurveyDialogue(id, IAPTEmploymentStatusKM, SurveyType.IAPTEmploymentStatus)
