import moment from "moment"
import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript from "./SelfReferralIAPTScript"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import { fullNameRegex } from "../../../utils/fullNameRegex"
import invariant from "../../../utils/invariant"
import isEmail from "validator/lib/isEmail"
import isValidPhoneNumber from "../../../utils/isvalidPhoneNumber"
import { TrackingEvents } from "../../../models/Constants"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import sendEmail from "../../../backend/api/sendEmail"
import { SendEmailStatus } from "../../../models/ISendEmail"

interface State extends SelfReferralIAPTScriptState {
  role?: string
  organisation?: string
  nameOfIndividualBeingReferred?: string
  consentFromIndividual?: boolean
  phoneNumberOfIndividual?: string
  birthdayOfIndividual?: number
  emailOfIndividual?: string
  mainIssue?: string
  individualAtRisk?: boolean
}

export type SelfReferralGMHubManagerIndividualScriptState = State

export class SelfReferralGMHubManagerIndividualScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralGMHubManagerIndividualScript"

  /** Script Steps */
  @step.logState
  @step.setState<State>({ addressLookupCounter: 0, postcodeLookupCounter: 0 })
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.askWannaDoSelfReferral }
  }

  @step.logState
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    return {
      body: "What's your full name? ✏️",
      nextStep: this.showPromptForFullName
    }
  }

  @step
  sayPleaseGiveFullName(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your full name",
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  showPromptForFullName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForFullName"),
        type: "text",
        validation: [new RegExp(fullNameRegex)],
        validationExplainer: ["Please enter your full name"],
        forceValue: true
      },
      nextStep: this.handleFullNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralGMHubManagerIndividualScript) => {
      const username = d.response?.trim()
      d.state.username = username
      script.rootStore.applicationStore.setUsername(username)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralGMHubManagerIndividualScript) => s.sayPleaseGiveFullName
  })
  async handleFullNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkFullName }
  }

  @step.logState
  async checkFullName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.username || d.state.username.trim() === "") {
      return { nextStep: this.sayPleaseGiveFullName }
    }
    return { nextStep: this.askRole }
  }

  @step.logState
  @step.logState
  askRole(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thanks ${name}. What is your role?`,
      prompt: {
        id: this.getPromptId("askRole"),
        type: "text",
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleRoleWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralGMHubManagerIndividualScript) => {
      d.state.role = d.response
      script.referralStore.setCustomField<State>("role", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralGMHubManagerIndividualScript) => s.askOrganisation
  })
  handleRoleWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askOrganisation }
  }

  @step.logState
  @step.logState
  askOrganisation(_d: IStepData<State>): IStepResult {
    return {
      body: "And what is the organisation you work for?",
      prompt: {
        id: this.getPromptId("askOrganisation"),
        type: "text",
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleOrganisationWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralGMHubManagerIndividualScript) => {
      d.state.organisation = d.response
      script.referralStore.setCustomField<State>("organisation", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralGMHubManagerIndividualScript) => s.askEmail
  })
  handleOrganisationWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEmail }
  }

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.goToCollectPhoneNumber(d)
    return { ...result, nextStep: this.askNameOfIndividual }
  }

  @step.logState
  askNameOfIndividual(_d: IStepData<State>): IStepResult {
    return {
      body: "What is the name of the individual/employee you are seeking to refer or get support for from the Hub?",
      prompt: {
        id: this.getPromptId("askNameOfIndividual"),
        type: "text",
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleNameOfIndividualWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralGMHubManagerIndividualScript) => {
      d.state.nameOfIndividualBeingReferred = d.response
      script.referralStore.setCustomField<State>("nameOfIndividualBeingReferred", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralGMHubManagerIndividualScript) => s.askConsentFromIndividual
  })
  handleNameOfIndividualWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askConsentFromIndividual }
  }

  @step.logState
  askConsentFromIndividual(d: IStepData<State>): IStepResult {
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    return {
      body: `Do you have consent from ${nameOfIndividualBeingReferred} to share this information with the Greater Manchester Resilience Hub?`,
      prompt: {
        id: this.getPromptId("askConsentFromIndividual"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleConsentFromIndividual
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralGMHubManagerIndividualScript) => {
      d.state.consentFromIndividual = d.response
      script.referralStore.setCustomField<State>("consentFromIndividual", d.response)
    }
  )
  handleConsentFromIndividual(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askDateOfBirthOfIndividual }
    }
    return { nextStep: this.sayCannotReferIndividual }
  }

  @step.logState
  askDateOfBirthOfIndividual(d: IStepData<State>): IStepResult {
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    return {
      body: `What is ${nameOfIndividualBeingReferred}'s date of birth?`,
      prompt: {
        id: this.getPromptId("askDateOfBirthOfIndividual"),
        trackResponse: true,
        type: "date"
      },
      nextStep: this.handleBirthday
    }
  }

  @step.logState
  sayPleaseGiveABirthday(d: IStepData<State>): IStepResult {
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    return {
      body: `Please enter ${nameOfIndividualBeingReferred}'s date of birth`,
      prompt: {
        id: this.getPromptId("sayPleaseGiveABirthday"),
        trackResponse: true,
        type: "date"
      },
      nextStep: this.handleBirthday
    }
  }

  @step
  handleBirthday(d: IStepData<State, number>): IStepResult {
    try {
      const date = moment(d.response)
      invariant(date, "Date object is falsy")
      invariant(date.isValid(), "Date is not valid")
      invariant(date.isBefore(moment()), "Birth date cannot be in the future")
      const birthday = date.toDate().getTime()
      d.state.birthdayOfIndividual = birthday
      this.referralStore.setCustomField<State>("birthdayOfIndividual", birthday)
      // TODO: Not sure what age we need to track here?
      // The age of the manager or the age of the person being referred?
      // If its the age of the person being referred, then we need to extend
      // the askBirthday question in order to remove tracking from there
      // const age = moment().diff(date, "years")
      // this.setPeople({ age })
      // this.track(String(age))
    } catch (e) {
      this.logException(e, "handleBirthday")
      return {
        body: "I'm sorry that's not a valid date",
        nextStep: this.sayPleaseGiveABirthday
      }
    }
    return {
      body: "Great, that will do",
      nextStep: this.askEmailOfIndividual
    }
  }

  @step.logState
  askEmailOfIndividual(d: IStepData<State>): IStepResult {
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    return {
      body: `Can you provide us with ${nameOfIndividualBeingReferred}'s email address?`,
      prompt: {
        id: this.getPromptId("askEmailOfIndividual"),
        placeholder: `Enter ${nameOfIndividualBeingReferred}'s email`,
        type: "email"
      },
      nextStep: this.handleEmailOfIndividual
    }
  }

  @step.logStateAndResponse
  async handleEmailOfIndividual(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isEmail(d.response)
    if (!isValid) {
      return {
        body: "Sorry this is not a valid email address. Let's try again",
        nextStep: this.askEmailOfIndividual
      }
    }
    d.state.emailOfIndividual = d.response

    return { nextStep: this.askPhoneNumberOfIndividual }
  }

  @step.logState
  askPhoneNumberOfIndividual(d: IStepData<State>): IStepResult {
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    return {
      body: "And what is the best number to contact them on?",
      prompt: {
        id: this.getPromptId("askPhoneNumberOfIndividual"),
        placeholder: `Enter ${nameOfIndividualBeingReferred}'s phone number`,
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumberOfIndividual
    }
  }

  @step
  returnToAskPhoneNumberOfIndividual(_d: IStepData<State>): IStepResult {
    return {
      body: "So...",
      nextStep: this.askPhoneNumberOfIndividual
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralGMHubManagerIndividualScript) =>
      s.returnToAskPhoneNumberOfIndividual
  })
  async handlePhoneNumberOfIndividual(d: IStepData<State, string>): Promise<IStepResult> {
    const isValid = isValidPhoneNumber(d.response)
    if (!isValid) {
      this.track(TrackingEvents.INVALID_PHONE_NUMBER)
      return {
        body: "Sorry this is not a valid phone number. Let's try again",
        nextStep: this.askPhoneNumberOfIndividual
      }
    }
    d.state.phoneNumberOfIndividual = d.response

    return {
      body: "Thanks",
      nextStep: this.askToDescribeIssue
    }
  }

  @step.logState
  askToDescribeIssue(_d: IStepData<State>): IStepResult {
    return {
      body: "And finally, could you briefly describe the situation or issue that is the reason for this referral?",
      prompt: {
        id: this.getPromptId("askToDescribeIssue"),
        type: "text",
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleDescribeIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralGMHubManagerIndividualScript) => {
      d.state.mainIssue = d.response
      script.referralStore.setCustomField<State>("mainIssue", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralGMHubManagerIndividualScript) => s.askIsIndividualAtRisk
  })
  handleDescribeIssueWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askIsIndividualAtRisk }
  }

  @step.logState
  askIsIndividualAtRisk(d: IStepData<State>): IStepResult {
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    return {
      body: `In your opinion, is ${nameOfIndividualBeingReferred} at risk, in crisis or unable to keep themself safe?`,
      prompt: {
        id: this.getPromptId("askIsIndividualAtRisk"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleIsIndividualAtRisk
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralGMHubManagerIndividualScript) => {
      d.state.individualAtRisk = d.response
      script.referralStore.setCustomField<State>("individualAtRisk", d.response)
    }
  )
  handleIsIndividualAtRisk(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.sayRiskSignposting }
    }
    // TODO: When we have the backend setup we also need to create a referral for ManagerIndividual
    // i.e. nextStep: this.onReferralFinished - and send email there
    return { nextStep: this.submitReferralEmail }
  }

  sayRiskSignposting(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const nameOfIndividualBeingReferred = d.state.nameOfIndividualBeingReferred
    return {
      body: [
        `Thanks ${name}`,
        `The Greater Manchester Resilence Hub will follow up with ${nameOfIndividualBeingReferred}, however it is not a crisis service`,
        "There is some additional information you can share with them if they are in crisis:",
        "In an urgent emergency they should seek help via 999",
        "If they're experiencing a mental health crisis they can also go to: [Getting Help](http://www.nhs.uk/Conditions/Suicide/Pages/Getting-help.aspx)",
        "Papyrus is available for under 35s on 0800 0684141 (call), pat@papyrus-uk.org and 07860 039967 (text)",
        `And finally, ${nameOfIndividualBeingReferred} can call the service directly on 0333 009 5071 or email [GM.help@nhs.net](GM.help@nhs.net)`
      ],
      prompt: {
        id: this.getPromptId("sayRiskSignposting"),
        type: "inlinePicker",
        choices: [
          { body: "Ok", value: false },
          { body: "I understand", value: false }
        ],
        isUndoAble: true
      },
      // TODO: When we have the backend setup we also need to create a referral for ManagerIndividual
      // i.e. nextStep: this.onReferralFinished - and send email there
      nextStep: this.submitReferralEmail
    }
  }

  sayCannotReferIndividual(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const referralUrl = "https://www.penninecare.nhs.uk/c19screening"
    return {
      body: [
        `I'm sorry ${name}, in order to refer another individual to the Greater Manchester Resilience Hub you need to get consent from them`,
        "This is because the Hub will follow up with them directly and confirm you as the source of the contact",
        `Please confirm consent with the individual or encourage them to complete a self referral by visiting this [link](${referralUrl})`
      ],
      prompt: {
        id: this.getPromptId("sayCannotReferIndividual"),
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: false },
          { body: "I understand", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.goToGoodbye
    }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "And that's everything",
        `You've officially submitted this referral to ${organisationName}`
      ],
      nextStep: this.end
    }
  }

  @step.logState
  async submitReferralEmail(d: IStepData<State>): Promise<IStepResult> {
    const emails = this.rootStore.configStore.emails ?? []

    try {
      // prettier-ignore
      const text = this.createReferralEmail(d.state)
      // [secure] was requested by GMHUB - with this tag in the subject
      // they can encrypt it on their side
      const status = await sendEmail({
        from: "customersupport@limbic.ai",
        subject: "[secure] Limbic Referral | Individual Support",
        to: emails,
        text
      })
      if (status === SendEmailStatus.SendEmailFailed) {
        d.state.referralSubmitted = false
        d.state.referralSubmissionFailed = true
        this.track(TrackingEvents.MANAGER_INDIVIDUAL_REFERRAL_NOT_SUBMITTED)
        return { nextStep: this.sayReferralFailed }
      }
      d.state.referralSubmitted = true
      d.state.referralSubmissionFailed = false
      this.track(TrackingEvents.MANAGER_INDIVIDUAL_REFERRAL_SUBMITTED)
    } catch (e) {
      this.logException(e, "onReferralFinished -> sendEmail")
    }
    return { nextStep: this.sayReferralSucceeded }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const referralUrl = "https://www.penninecare.nhs.uk/c19screening"
    return {
      body: [
        `Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit the provided data to ${organisationName}`,
        "I've notified my creators of this issue",
        `If you don't wish to wait, you can manually refer yourself by following this link [here](${referralUrl})`
      ],
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }],
        isUndoAble: false
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  // TODO: When we have the backend setup we also need to create a referral for ManagerIndividual
  // i.e. nextStep: this.onReferralFinished - and send email there
  async getReferralPayload(_state: State): Promise<Record<string, null>> {
    return {}
  }

  async onHandleEmail(_state: State): Promise<IStepResult> {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  createReferralEmail(state: State): string {
    // prettier-ignore
    return `
    <html lang='en'>
      <head>
      <title>Limbic Referral | Individual Support</title>
      ${this.getEmailHTMLStyle()}
      </head>
      <body>
        <h1 style="text-align: left;">Limbic Referral | Individual Support</h1>
        <b>Individual:</b> ${state.nameOfIndividualBeingReferred}<br/>
        <b>Referrer:</b> ${state.username}<br/>
        <b>Referral Type:</b> Individual Support<br/>
        <hr/>
        <h3>Individual Info</h3>
        <b>Name:</b> ${state.nameOfIndividualBeingReferred}<br/>
        <b>Date of Birth:</b> ${state.birthdayOfIndividual ? `${moment(state.birthdayOfIndividual).format("DD/MM/YYYY")}<br/>` : "-"}
        <b>Email:</b> ${state.emailOfIndividual}<br/>
        <b>Phone Number:</b> ${state.phoneNumberOfIndividual}<br/>
        <h3>Situation Details</h3>
        <b>Individual at risk:</b> ${state.individualAtRisk}<br/>
        <b>Situation/issue details:</b> ${state.mainIssue}<br/>
        <h3>Referrer Info</h3>
        <b>Name:</b> ${state.username}<br/>
        <b>Role:</b> ${state.role}<br/>
        <b>Organisation:</b> ${state.organisation}<br/>
        <b>Email:</b> ${state.email}<br/>
        <b>Phone Number:</b> ${state.phoneNumber}<br/>
        <b>Consent from Individual:</b> ${state.consentFromIndividual}<br/>
      </body>
    </html>
    `.replace(/undefined/gi, "-").replace(/true/gi, "Yes").replace(/false/gi, "No")
  }
}

export default class SelfReferralGMHubManagerIndividualDialogue extends AdHocDialogue<
  State,
  SelfReferralGMHubManagerIndividualScript
> {
  static id = DialogueIDs.SelfReferralGMHubManagerIndividual
  readonly name: string = "SelfReferralGMHubManagerIndividualDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralGMHubManagerIndividualDialogue.id,
      new SelfReferralGMHubManagerIndividualScript(),
      state,
      snapshot
    )
  }
}
