import React from "react"
import classNames from "classnames"
import "./ChatWindow.scss"
import ChatHeader from "../ChatHeader/ChatHeader"
import { useLauncherContext } from "../../contexts/LauncherContext"
import { useConfigStore } from "../../contexts/RootStoreContext"
import { FocusScope } from "@react-aria/focus"
import Passthrough from "../Passthrough"
import { isIE } from "../../../models/Constants"

const FocusComponent = isIE ? Passthrough : FocusScope

interface Props {
  children?: React.ReactChild | React.ReactChild[] | null
}

export default function ChatWindow(props: Props): JSX.Element {
  const config = useConfigStore()
  const { isOpen, onToggle } = useLauncherContext()

  const css = classNames("lb-chat-window", {
    opened: isOpen,
    closed: !isOpen,
    fullscreen: config.fullscreen,
    "sidebar-hidden": config.key === "LIMBIC_WEBSITE"
  })

  return (
    <FocusComponent autoFocus contain restoreFocus>
      <div className={css} data-testid="chat-window">
        <ChatHeader onClose={onToggle} />
        {props.children}
      </div>
    </FocusComponent>
  )
}
