import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { RiskPathwayScript, RiskPathwayScriptState } from "./RiskPathwayScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { KeepingSafeLeafletViaStatus, ReferralType } from "../../../models/Constants"
import { RiskLevelReason } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { SendEmailStatus } from "../../../models/ISendEmail"
import sendEmail from "../../../backend/api/sendEmail"

const shouldSendKeepingSafe = process.env.REACT_APP_SHOULD_SEND_KEEPING_SAFE === "enabled"

interface State extends RiskPathwayScriptState {
  permissionToEmail?: boolean
}

export type RiskPathwayNorfolkScriptState = State

export class RiskPathwayNorfolkScript extends RiskPathwayScript {
  readonly name: string = "RiskPathwayNorfolkScript"

  /** Script Steps */
  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askCanYouKeepYourselfSafe }
  }

  @step.logState
  askCanYouKeepYourselfSafe(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to keep yourself, and any dependants in your care, safe until your appointment? This could be up to 6 weeks, although likely to be sooner",
      prompt: {
        id: this.getPromptId("askCanYouKeepYourselfSafe"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askCanYouKeepYourselfSafe"
      },
      nextStep: this.handleCanYouKeepYourselfSafe
    }
  }

  @step.logStateAndResponse
  @step.startTyping
  @step.saveResponse<State>(
    "10",
    "Are you able to keep yourself, and any dependants in your care, safe until your appointment?",
    "riskPathwayResponses",
    (r: boolean) => (r ? "Yes" : "No")
  )
  @step.handleResponse((d: IStepData<State, boolean>, script: RiskPathwayNorfolkScript) => {
    d.state.canKeepSelfSafe = d.response
    script.referralStore.setCustomField<State>("canKeepSelfSafe", d.response)
  })
  handleCanYouKeepYourselfSafe(d: IStepData<State>): IStepResult {
    if (!d.response) {
      this.clinicalStore.setIsCrisis(true)
      this.setCrisisDetectionCorrect(d.state, true)
      this.setRiskLevelHigh(d.state, RiskLevelReason.CANNOT_KEEP_SELF_SAFE)
      return { nextStep: this.saveRiskLevelAndReferralType }
    }
    return { nextStep: this.saveRiskLevelAndReferralType }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)

    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "However, this is not an emergency response service"
      ],
      nextStep: this.sayCrisisNumbers
    }
  }

  @step.logState
  sayCrisisNumbers(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "To get more appropriate help, you can call NHS 111 and selection Option 2",
        "If you need urgent, life threatening medical help please call 999",
        "Alternatively, you can also call Samaritans on 116 123"
      ],
      prompt: {
        id: this.getPromptId("sayCrisisNumbers"),
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleCrisisNumbers
    }
  }

  @step.logState
  handleCrisisNumbers(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField("crisisNumbersShared", "999, NHS 111, Samaritans on 116 123")
    return {
      nextStep: this.informRiskToService
    }
  }

  // Need custom saveRiskLevelAndReferralType in order to change nextStep
  @step.logState
  async saveRiskLevelAndReferralType(d: IStepData<State>): Promise<IStepResult> {
    const riskLevel = this.clinicalStore.riskLevel
    const riskLevelReason = this.clinicalStore.riskLevelReason
    const canKeepSelfSafe = d.state.canKeepSelfSafe ?? true
    void this.referralStore.updateReferral({ riskLevel, riskLevelReason, canKeepSelfSafe })
    this.updateReferralType(d.state)
    return { nextStep: !d.state.canKeepSelfSafe ? this.sayCrisis : this.end }
  }

  async sendKeepingSafeEmail(state: State): Promise<void> {
    try {
      if (shouldSendKeepingSafe && !state.email) {
        state.keepingSafeSent = false
        await this.referralStore.updateReferral({
          keepingSafeLeafletStatus: KeepingSafeLeafletViaStatus.NO_RECIPIENT
        })
      }
      // If not permission to email, set the reason
      if (shouldSendKeepingSafe && state.email && !state.permissionToEmail) {
        state.keepingSafeSent = false
        await this.referralStore.updateReferral({
          keepingSafeLeafletStatus: KeepingSafeLeafletViaStatus.NO_EMAIL_PERMISSION
        })
      }
      // Need to check that permission to send email is true!
      if (shouldSendKeepingSafe && state.email && state.permissionToEmail) {
        const logo = this.rootStore.configStore.keepingSafeLogo
        const title = this.rootStore.configStore.keepingSafeTitle
        const subject = this.getKeepingSafeEmailSubject?.(state)
        const keepingSafeEmail = this.createKeepingSafeLeafletEmail(title, logo)
        const attachmentUrls = this.getKeepingSafeAttachmentUrls?.(state) || undefined
        const organisationName = this.rootStore.configStore.organisationName
        const response: SendEmailStatus = await sendEmail(
          {
            subject,
            to: [state.email],
            text: keepingSafeEmail,
            attachmentUrls
          },
          organisationName
        )
        const hasKeepingSafeBeenSent = response === SendEmailStatus.Success
        state.keepingSafeSent = hasKeepingSafeBeenSent
        const keepingSafeLeafletStatus = hasKeepingSafeBeenSent
          ? KeepingSafeLeafletViaStatus.SENT
          : KeepingSafeLeafletViaStatus.FAILED
        await this.referralStore.updateReferral({
          keepingSafeLeafletVia: "EMAIL",
          keepingSafeLeafletStatus
        })
      }
    } catch (e) {
      this.logException(e, "sendKeepingSafe")
    }
  }

  @step.logState
  async informRiskToService(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.informRiskToService(d)
    return { ...result, nextStep: this.closeDialogue }
  }

  /** Generic Handlers */

  getKeepingSafeAttachmentUrls(_state: State): string[] {
    return [
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/norfolk/Wellbeing+Keeping+Safe+Leaflet.pdf"
    ]
  }

  getCustomReferralType(state: State): string | undefined {
    return
  }

  getReferralTypeForRisk(_state: State): string | undefined {
    if (this.clinicalStore.isHighRisk) return ReferralType.RISK_PATIENT
    if (this.clinicalStore.isModerateRisk) return ReferralType.RISK_PATIENT
  }

  getKeepingSafeEmailSubject(_state: State): string {
    return "Keeping Yourself Safe"
  }
}

export default class RiskPathwayNorfolkDialogue extends Dialogue<State> {
  static id = DialogueIDs.RiskPathwayNorfolk
  readonly name: string = "RiskPathwayNorfolkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(RiskPathwayNorfolkDialogue.id, new RiskPathwayNorfolkScript(), state, snapshot)
  }
}
