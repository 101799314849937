import { IAPTGPMap, IAPTIDs } from "../../models/IIAPTService"

// NOTE: List is here:
// https://docs.google.com/spreadsheets/d/1xYGkV6f9azMnx_087sI0EKpJO66AV5Kc/edit#gid=120188721

const iaptGPsMapLincolnshireBoston: IAPTGPMap = {
  C83614: IAPTIDs.LINCS_BOSTON,
  C83049: IAPTIDs.LINCS_BOSTON,
  C83055: IAPTIDs.LINCS_BOSTON,
  C83083: IAPTIDs.LINCS_BOSTON,
  C83059: IAPTIDs.LINCS_BOSTON,
  C83057: IAPTIDs.LINCS_BOSTON,
  C83004: IAPTIDs.LINCS_BOSTON,
  C83010: IAPTIDs.LINCS_BOSTON,
  C83015: IAPTIDs.LINCS_BOSTON,
  C83060: IAPTIDs.LINCS_BOSTON,
  C83047: IAPTIDs.LINCS_BOSTON,
  A91031: IAPTIDs.LINCS_BOSTON
}

const iaptGPsMapLincolnshireGainsborough: IAPTGPMap = {
  C83018: IAPTIDs.LINCS_GAINSBOROUGH,
  C83037: IAPTIDs.LINCS_GAINSBOROUGH,
  C83052: IAPTIDs.LINCS_GAINSBOROUGH,
  C83033: IAPTIDs.LINCS_GAINSBOROUGH,
  C83641: IAPTIDs.LINCS_GAINSBOROUGH,
  C83074: IAPTIDs.LINCS_GAINSBOROUGH,
  C83633: IAPTIDs.LINCS_GAINSBOROUGH,
  C83044: IAPTIDs.LINCS_GAINSBOROUGH,
  C83038: IAPTIDs.LINCS_GAINSBOROUGH,
  B81099: IAPTIDs.LINCS_GAINSBOROUGH
}

const iaptGPsMapLincolnshireGrantham: IAPTGPMap = {
  C83008: IAPTIDs.LINCS_GRANTHAM,
  C83075: IAPTIDs.LINCS_GRANTHAM,
  C83080: IAPTIDs.LINCS_GRANTHAM,
  C83048: IAPTIDs.LINCS_GRANTHAM,
  C83024: IAPTIDs.LINCS_GRANTHAM,
  C83040: IAPTIDs.LINCS_GRANTHAM,
  C83053: IAPTIDs.LINCS_GRANTHAM,
  C83067: IAPTIDs.LINCS_GRANTHAM,
  C83653: IAPTIDs.LINCS_GRANTHAM,
  C83628: IAPTIDs.LINCS_GRANTHAM,
  C83649: IAPTIDs.LINCS_GRANTHAM,
  C82123: IAPTIDs.LINCS_GRANTHAM,
  C82076: IAPTIDs.LINCS_GRANTHAM
}

const iaptGPsMapLincolnshireLincoln: IAPTGPMap = {
  C83635: IAPTIDs.LINCS_LINCOLN,
  C83051: IAPTIDs.LINCS_LINCOLN,
  C83001: IAPTIDs.LINCS_LINCOLN,
  C83002: IAPTIDs.LINCS_LINCOLN,
  C83009: IAPTIDs.LINCS_LINCOLN,
  C83014: IAPTIDs.LINCS_LINCOLN,
  C83637: IAPTIDs.LINCS_LINCOLN,
  C83025: IAPTIDs.LINCS_LINCOLN,
  C83029: IAPTIDs.LINCS_LINCOLN,
  C83031: IAPTIDs.LINCS_LINCOLN,
  C83041: IAPTIDs.LINCS_LINCOLN,
  C83046: IAPTIDs.LINCS_LINCOLN,
  C83058: IAPTIDs.LINCS_LINCOLN,
  C83062: IAPTIDs.LINCS_LINCOLN,
  C83071: IAPTIDs.LINCS_LINCOLN,
  C83072: IAPTIDs.LINCS_LINCOLN,
  C83073: IAPTIDs.LINCS_LINCOLN,
  C83078: IAPTIDs.LINCS_LINCOLN,
  C83016: IAPTIDs.LINCS_LINCOLN,
  C83079: IAPTIDs.LINCS_LINCOLN,
  C83082: IAPTIDs.LINCS_LINCOLN,
  C83611: IAPTIDs.LINCS_LINCOLN,
  C83626: IAPTIDs.LINCS_LINCOLN,
  C83656: IAPTIDs.LINCS_LINCOLN,
  C83652: IAPTIDs.LINCS_LINCOLN
}

const iaptGPsMapLincolnshireLouth: IAPTGPMap = {
  C83652: IAPTIDs.LINCS_LOUTH,
  C83032: IAPTIDs.LINCS_LOUTH,
  C83043: IAPTIDs.LINCS_LOUTH,
  C83064: IAPTIDs.LINCS_LOUTH,
  C83056: IAPTIDs.LINCS_LOUTH,
  C83042: IAPTIDs.LINCS_LOUTH,
  C83006: IAPTIDs.LINCS_LOUTH,
  C83061: IAPTIDs.LINCS_LOUTH, // Lancaster & Holten-Le-Clay Surgery
  C83027: IAPTIDs.LINCS_LOUTH,
  C83085: IAPTIDs.LINCS_LOUTH,
  C83605: IAPTIDs.LINCS_LOUTH,
  C83613: IAPTIDs.LINCS_LOUTH,
  C83634: IAPTIDs.LINCS_LOUTH,
  C83635: IAPTIDs.LINCS_LOUTH,
  C83643: IAPTIDs.LINCS_LOUTH,
  C83650: IAPTIDs.LINCS_LOUTH
}

const iaptGPsMapLincolnshireSkegness: IAPTGPMap = {
  C83005: IAPTIDs.LINCS_SKEGNESS,
  C83019: IAPTIDs.LINCS_SKEGNESS,
  C83045: IAPTIDs.LINCS_SKEGNESS
}

const iaptGPsMapLincolnshireSleaford: IAPTGPMap = {
  C83013: IAPTIDs.LINCS_SLEAFORD,
  C83020: IAPTIDs.LINCS_SLEAFORD,
  C83023: IAPTIDs.LINCS_SLEAFORD,
  C83030: IAPTIDs.LINCS_SLEAFORD,
  C83011: IAPTIDs.LINCS_SLEAFORD,
  Y01652: IAPTIDs.LINCS_SLEAFORD,
  A91021: IAPTIDs.LINCS_SLEAFORD
}

const iaptGPsMapLincolnshireSpalding: IAPTGPMap = {
  C83028: IAPTIDs.LINCS_SPALDING,
  C83003: IAPTIDs.LINCS_SPALDING,
  C83036: IAPTIDs.LINCS_SPALDING,
  C83022: IAPTIDs.LINCS_SPALDING,
  C83039: IAPTIDs.LINCS_SPALDING,
  C83065: IAPTIDs.LINCS_SPALDING,
  C83617: IAPTIDs.LINCS_SPALDING,
  C83631: IAPTIDs.LINCS_SPALDING,
  C83063: IAPTIDs.LINCS_SPALDING,
  C83063006: IAPTIDs.LINCS_SPALDING
}

const iaptGPsMapLincolnshireStamford: IAPTGPMap = {
  C83054: IAPTIDs.LINCS_STAMFORD,
  C83035: IAPTIDs.LINCS_STAMFORD,
  C83026: IAPTIDs.LINCS_STAMFORD,
  C83017: IAPTIDs.LINCS_STAMFORD,
  C83007: IAPTIDs.LINCS_STAMFORD,
  C83026002: IAPTIDs.LINCS_STAMFORD
}

export const iaptGPMapLincolnshire: IAPTGPMap = {
  ...iaptGPsMapLincolnshireBoston,
  ...iaptGPsMapLincolnshireGainsborough,
  ...iaptGPsMapLincolnshireGrantham,
  ...iaptGPsMapLincolnshireLincoln,
  ...iaptGPsMapLincolnshireLouth,
  ...iaptGPsMapLincolnshireSkegness,
  ...iaptGPsMapLincolnshireSleaford,
  ...iaptGPsMapLincolnshireSpalding,
  ...iaptGPsMapLincolnshireStamford
}
