import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { ZodSchema } from "zod"

type State = BaseScriptState
export type IntroductionScriptState = State

export const IntroductionScriptStateSchema = BaseScriptStateSchema.extend({})

export abstract class IntroductionScript extends BaseScript<State> {
  /** Abstract Required Script Steps */

  abstract greet(_d: IStepData<State>): IStepResult
  abstract askCanIHelp(_d: IStepData<State>): IStepResult

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.greet }
  }

  @step.logState
  handleCanIHelp(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.end }
    }
    return { nextStep: this.sayIfYouChangeYourMindWakeMeUp }
  }

  @step.logState
  sayIfYouChangeYourMindWakeMeUp(_d: IStepData<State>): IStepResult {
    return {
      body: 'No problem. If you change your mind, just type "Hello Limbic" to wake me up',
      prompt: {
        id: this.getPromptId("wakeMeUp"),
        trackResponse: true,
        type: "text",
        placeholder: 'Type "Hello Limbic" to wake me up',
        isUndoAble: false
      },
      nextStep: this.handleTypeHeyLimbicToWakeMeUpWithCrisis
    }
  }

  @step
  returnToAskCanIHelp(_d: IStepData<State>): IStepResult {
    return {
      body: "So...",
      nextStep: this.askCanIHelp
    }
  }

  @step.logStateAndResponse
  @step.checkInputForCrisis({ getNextStep: (s: IntroductionScript) => s.returnToAskCanIHelp })
  handleTypeHeyLimbicToWakeMeUpWithCrisis(_d: IStepData<State>): IStepResult {
    return { body: "Hello again!", nextStep: this.askCanIHelp }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return IntroductionScriptStateSchema
  }
}
