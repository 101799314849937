import React from "react"
import "./WorkshopsITalk.scss"
import ISelectable from "../../../models/ISelectable"
import { useConfigStore } from "../../contexts/RootStoreContext"
import clsx from "clsx"
import { ITalkResponseWorkshop } from "../../../models/IAppointmentITalk"
import InlineButtonWithAccordion from "../InlineButtonWithAccordion/InlineButtonWithAccordion"

interface Props {
  appointments: ITalkResponseWorkshop[]
  onSubmit: (item: ISelectable) => void
  isFullScreen?: boolean
}

export default function WorkshopsITalk(props: Props): JSX.Element {
  const { appointments = [], onSubmit, isFullScreen = false } = props

  const config = useConfigStore()

  const getAccordionContent = item => {
    const startDates = item.intervals.map(interval => `<li>${interval.description}</li>`).join(" ")
    return `
    <span>
      <u>Session start dates</u>
      <ul style="text-align: left; padding-left: 20px;">
        ${startDates}
      </ul>
    </span>
  `
  }

  const handleSubmit = React.useCallback(
    (title: string): void => {
      return onSubmit({
        body: title,
        value: title
      })
    },
    [onSubmit]
  )

  return (
    <div className="lb-appointment-italk-wrapper" data-testid="appointment-italk-input">
      <div className={"lb-appointment-italk-container lb-appointment-italk-container-scrollable"}>
        <div className="lb-appointment-italk-calendar">
          {appointments.map((item: ITalkResponseWorkshop) => {
            const backgroundColor = config.userMessageBackground
            const btn: ISelectable = {
              body: `${item.title}`,
              value: item.title
            }
            return (
              <InlineButtonWithAccordion
                className={clsx("lb-appointment-italk-button", {
                  "lb-appointment-italk-button-fullscreen": isFullScreen
                })}
                buttonContainerClassName="lb-appointment-italk-button-container"
                style={{ backgroundColor, width: "100%" }}
                key={item.title}
                btn={btn}
                content={getAccordionContent(item)}
                onSelect={() => handleSubmit(item.title)}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
