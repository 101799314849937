import { z } from "zod"
import { ITextPrompt } from "../backend/chatbot/models/IPrompt"
import IUserResponse, {
  IPersistableUserResponse,
  IPersistableUserResponseSchema,
  IUserResponseSchema
} from "./IUserResponse"

export interface ISurveyChoices {
  points?: number
  answer: string
}

interface ISurveyBaseQuestion {
  id: string
  question: string | string[]
  type: string
  progress?: number
}

interface ISurveyQuestionInline<T = undefined> extends ISurveyBaseQuestion {
  type: "inline"
  answers: ISurveyChoices[]
  subscales?: T
  textPrompt?: Omit<ITextPrompt, "type">
  questionName?: string
}

interface ISurveyQuestionSlider extends ISurveyBaseQuestion {
  type: "slider"
  min: number
  max: number
  labels?: { [key: number]: string }
  notApplicable?: boolean
  questionName?: string
}

export type ISurveyQuestion<T = undefined> = ISurveyQuestionInline<T> | ISurveyQuestionSlider

export interface ISurveyResponse extends IUserResponse {
  points?: number
  subscales?: string
}

export const ISurveyResponseSchema = IUserResponseSchema.extend({
  points: z.number().optional(),
  subscales: z.string().optional()
})

export interface IPersistableSurveyResponse extends ISurveyResponse, IPersistableUserResponse {}

export const IPersistableSurveyResponseSchema = ISurveyResponseSchema.and(
  IPersistableUserResponseSchema
)

export default interface ISurvey<T = undefined> {
  explanation?: string | string[]
  startingQuestion?: string | string[] | null
  endingMessage?: string | string[] | null
  questions: ISurveyQuestion<T>[]
}

export enum SurveyType {
  Audit = "Audit",
  DrugsAndSmoking = "DrugsAndSmoking",
  ITQ = "ITQ",
  IRQA = "IRQA",
  GAD7 = "GAD-7",
  PHQ9 = "PHQ-9",
  PCL5 = "PCL-5",
  PDSS = "PDSS",
  SPIN = "SPIN",
  OCI = "OCI",
  SHAI18 = "SHAI18",
  SpecificPhobiaIntro = "SpecificPhobiaIntro",
  SpecificPhobia = "SpecificPhobia",
  IAPTAccommodation = "IAPTAccommodation",
  IAPTPhobiaScale = "IAPTPhobiaScale",
  IAPTEmploymentStatus = "IAPTEmploymentStatus",
  IAPTWorkAndSocialAdjustment = "IAPTWorkAndSocialAdjustment",
  IAPTMedication = "IAPTMedication"
}
