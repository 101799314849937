import BaseScript, { BaseScriptState } from "../../BaseScript"
import { step } from "../../../backend/chatbot/decorators/step"
import dialoguesRegistry from "../../dialoguesRegistry"
import { DialogueIDs } from "../../DialogueIDs"
import {
  AssessmentClinicalNotes,
  ClinicalFlags,
  DiscussionSteps,
  ProblemCategories,
  problemCategoryUserFriendlyNames,
  TrackingEvents
} from "../../../models/Constants"
import { joinWithAnd } from "../../../utils/array"
import validateClinicalPath from "../../../utils/validateClinicalPath"
import sendEmail from "../../../backend/api/sendEmail"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"
import type { SurveyScriptState } from "../../createSurveyDialogue"

type State = BaseScriptState
export type AssessmentV2ScriptState = State

export default abstract class AssessmentV2Script extends BaseScript<State> {
  /** Abstract Generic Handlers */

  onFinishAssessment?(state: State): Promise<IStepResult | void>
  onHandleIAPTPhobiaScale?(state: State): Promise<IStepResult | void>
  onHandleIAPTWorkAndSocialAdjustment?(state: State): Promise<IStepResult | void>
  onHandleIAPTMedication?(state: State): Promise<IStepResult | void>
  handlePHQ9Responses?(state: State): void | Promise<void>
  handleGAD7Responses?(state: State): void | Promise<void>
  handlePhobiaScaleResponses?(state: State): void | Promise<void>
  handleIAPTEmploymentStatusResponses?(state: State): void | Promise<void>
  handleWSASResponses?(state: State): void | Promise<void>
  handleIAPTMedicationResponses?(state: State): void | Promise<void>

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    this.rootStore.applicationStore.setTotalProgressBars(4)
    this.rootStore.applicationStore.setCurrentProgressBar(1)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return {
      nextStep: this.step1
    }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.sayLetsGetStarted }
  }

  @step.logState
  sayLetsGetStarted(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Let's get started...",
        "I'm going to ask you 9 questions which look at  common symptoms of depression"
      ],
      prompt: {
        id: this.getPromptId("sayLetsGetStarted"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.goToPHQ9
    }
  }

  @step.logState
  goToPHQ9(d: IStepData<State>): IStepResult {
    const PHQ9Dialogue = this.discussionStore.getDialogueClass(DiscussionSteps.PHQ9)
    return {
      nextDialogue: PHQ9Dialogue //
        ? new PHQ9Dialogue({ ...d.state })
        : undefined,
      nextStep: this.handlePHQ9
    }
  }

  @step.logStateAndResponse
  async handlePHQ9(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handlePHQ9Responses?.(d.state)
    this.rootStore.applicationStore.setCurrentProgressBar(2)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return { nextStep: this.goToGAD7 }
  }

  @step.logState
  goToGAD7(d: IStepData<State>): IStepResult {
    const GAD7Dialogue = dialoguesRegistry.get(DialogueIDs.GAD7)
    const body =
      !d.state.php9q9Score && !this.clinicalStore.isRisk
        ? [
            "Moving on...",
            "This next set of 7 questions is designed to measure common symptoms of anxiety"
          ]
        : "This next set of 7 questions is designed to measure common symptoms of anxiety"
    return {
      body,
      nextDialogue: new GAD7Dialogue({ ...d.state }),
      nextStep: this.handleGAD7
    }
  }

  @step.logStateAndResponse
  async handleGAD7(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleGAD7Responses?.(d.state)
    this.rootStore.applicationStore.setCurrentProgressBar(3)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return { nextStep: this.sayWereMakingProgress }
  }

  @step.logState
  sayWereMakingProgress(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Okay ${name}, we're making good progress`,
        "(You can see from the coloured bar at the top of this chat window ☝️)",
        "I'm now going to ask you 3 short questions about things you might avoid",
        "Ready?"
      ],
      prompt: {
        id: this.getPromptId("sayWereMakingProgress"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "👍" }, { body: "Yes" }, { body: "Go on" }]
      },
      nextStep: this.goToIAPTPhobiaScale
    }
  }

  @step.logState
  goToIAPTPhobiaScale(d: IStepData<State>): IStepResult {
    const IAPTPhobiaScaleDialogue = dialoguesRegistry.get(DialogueIDs.IAPTPhobiaScale)
    return {
      nextDialogue: new IAPTPhobiaScaleDialogue({ ...d.state }),
      nextStep: this.handleIAPTPhobiaScale
    }
  }

  @step.logStateAndResponse
  async handleIAPTPhobiaScale(
    d: IStepData<State, undefined, SurveyScriptState>
  ): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handlePhobiaScaleResponses?.(d.state)
    this.rootStore.applicationStore.setCurrentProgressBar(4)
    this.rootStore.applicationStore.setCurrentProgress(0)
    const result = await this.onHandleIAPTPhobiaScale?.(d.state)
    if (result) return result
    return {
      body: "Okay, thanks again for sharing",
      nextStep: this.goToIAPTEmploymentStatus
    }
  }

  @step.logState
  goToIAPTEmploymentStatus(d: IStepData<State>): IStepResult {
    const IAPTEmploymentStatusDialogue = dialoguesRegistry.get(DialogueIDs.IAPTEmploymentStatus)
    return {
      body: ["Nearly there", "This last set of questions is about your work and day-to-day life"],
      nextDialogue: new IAPTEmploymentStatusDialogue({ ...d.state }),
      nextStep: this.handleIAPTEmploymentStatus
    }
  }

  @step.logStateAndResponse
  async handleIAPTEmploymentStatus(
    d: IStepData<State, undefined, SurveyScriptState>
  ): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleIAPTEmploymentStatusResponses?.(d.state)
    return { nextStep: this.sayReadyForWASAS }
  }

  @step
  sayReadyForWASAS(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        "People's problems sometimes affect their ability to do day-to-day tasks",
        `${name}, I'd like you to rate how your problems impair your ability to do things in 5 areas:`,
        `1. Work\n2. Home\n3. Social leisure\n4. Private leisure\n5. Family and relationships`
      ],
      prompt: {
        id: this.getPromptId("sayReadyForWASAS"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Let's do it", value: true },
          { body: "Okay", value: false }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleReadyForWASAS
    }
  }

  @step
  handleReadyForWASAS(d: IStepData<State, boolean>): IStepResult {
    return {
      body: d.response ? "Let's go!" : undefined,
      nextStep: this.goToIAPTWorkAndSocialAdjustment
    }
  }

  @step.logState
  goToIAPTWorkAndSocialAdjustment(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const IAPTWorkAndSocialAdjustmentDialogue = dialoguesRegistry.get(DialogueIDs.IAPTWorkAndSocialAdjustment)
    return {
      nextDialogue: new IAPTWorkAndSocialAdjustmentDialogue({ ...d.state }),
      nextStep: this.handleIAPTWorkAndSocialAdjustment
    }
  }

  @step.logStateAndResponse
  async handleIAPTWorkAndSocialAdjustment(
    d: IStepData<State, undefined, SurveyScriptState>
  ): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleWSASResponses?.(d.state)
    const total = this.getWSASTotal(d.state)!
    if (total >= 10 && total <= 20) {
      this.rootStore.clinicalStore.addFlag(ClinicalFlags.MS_FunctionalImpairment)
    } else if (total > 20) {
      this.rootStore.clinicalStore.addFlag(ClinicalFlags.S_FunctionalImpairment)
    }
    const result = await this.onHandleIAPTWorkAndSocialAdjustment?.(d.state)
    if (result) return result
    return { nextStep: this.goToIAPTMedication }
  }

  @step.logState
  goToIAPTMedication(d: IStepData<State>): IStepResult {
    const IAPTMedicationDialogue = dialoguesRegistry.get(DialogueIDs.IAPTMedication)
    return {
      body: "And finally",
      nextDialogue: new IAPTMedicationDialogue({ ...d.state }),
      nextStep: this.handleIAPTMedication
    }
  }

  @step.logStateAndResponse
  async handleIAPTMedication(
    d: IStepData<State, undefined, SurveyScriptState>
  ): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleIAPTMedicationResponses?.(d.state)
    const result = await this.onHandleIAPTMedication?.(d.state)
    if (result) return result
    return { nextStep: this.sayThatsEverything }
  }

  @step.logState
  sayThatsEverything(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: ["I really appreciate you taking the time to go through this with me", `Okay, ${name}`],
      nextStep: this.checkOutcomes
    }
  }

  @step.logState
  @step.startTyping
  @step.delay(3)
  checkOutcomes(d: IStepData<State>): IStepResult {
    this.blockUndo(d.state)
    this.rootStore.applicationStore.setTotalProgressBars(0)
    this.rootStore.applicationStore.setCurrentProgressBar(0)
    this.rootStore.applicationStore.setCurrentProgress(0)
    const phq9Total = this.getPHQ9Total(d.state)!
    const gad7Total = this.getGAD7Total(d.state)!
    if (phq9Total >= 10 && gad7Total >= 8) return { nextStep: this.sayDepressionAndAnxiety }
    if (phq9Total >= 10) return { nextStep: this.sayDepression }
    if (gad7Total >= 8) return { nextStep: this.sayAnxiety }
    return { nextStep: this.sayBelowCaseness }
  }

  @step.logState
  sayBelowCaseness(d: IStepData<State>): IStepResult {
    const body = [
      "Based on the answers you gave, I did not identify clinical levels of depression or anxiety"
    ]
    const phq9Total = this.getPHQ9Total(d.state)!
    const gad7Total = this.getGAD7Total(d.state)!
    if (phq9Total > 0 && gad7Total > 0) {
      body.push("But I understand you might still be finding things difficult")
      this.addClinicalNote(AssessmentClinicalNotes.BelowCaseness)
    }
    return {
      body,
      nextStep: this.checkMostAffectingPrimary
    }
  }

  @step.logState
  sayDepressionAndAnxiety(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Your answers indicate that you are experiencing symptoms of low mood and anxiety",
        "I have a few more questions for you to see what else might be going on for you"
      ],
      prompt: {
        id: this.getPromptId("sayDepressionAndAnxiety"),
        type: "inlinePicker",
        choices: [{ body: "okay" }]
      },
      nextStep: this.askOCD
    }
  }

  @step.logState
  sayDepression(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Your answers indicate that you are experiencing symptoms of low mood",
        "I have a few more questions for you to see what else might be going on for you"
      ],
      prompt: {
        id: this.getPromptId("sayDepression"),
        type: "inlinePicker",
        choices: [{ body: "okay" }]
      },
      nextStep: this.askOCD
    }
  }

  @step.logState
  sayAnxiety(_d: IStepData<State>): IStepResult {
    return {
      body: "Your answers indicate that you are experiencing symptoms of anxiety",
      nextStep: this.askOCD
    }
  }

  @step.logState
  askOCD(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have recurrent thoughts, impulses or images that you can't easily stop? And are there certain things such as washing hands, switching off lights or counting that you feel you must do?",
      prompt: {
        id: this.getPromptId("askOCD"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleOCD
    }
  }

  @step.logState
  handleOCD(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.clinicalStore.addPrimaryProblem(ProblemCategories.OCD)
      this.addClinicalNote(AssessmentClinicalNotes.OCDAnxiety)
    }
    return { nextStep: this.checkSocialPhobia }
  }

  @step.logState
  checkSocialPhobia(d: IStepData<State>): IStepResult {
    const socialPhobiaScore = this.getSocialPhobiaScore(d.state)!
    if (socialPhobiaScore >= 4) {
      return { nextStep: this.askSocialPhobia }
    }
    return { nextStep: this.checkSpecificPhobia }
  }

  @step.logState
  askSocialPhobia(_d: IStepData<State>): IStepResult {
    return {
      body: "Is your anxiety to do with fear of social or performance situations and accompanied by thoughts of embarrassment?",
      prompt: {
        id: this.getPromptId("askSocialPhobia"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleSocialPhobia
    }
  }

  @step.logState
  handleSocialPhobia(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.clinicalStore.addPrimaryProblem(ProblemCategories.SocialPhobia)
    }
    return { nextStep: this.checkSpecificPhobia }
  }

  @step.logState
  checkSpecificPhobia(d: IStepData<State>): IStepResult {
    const specificPhobiaScore = this.getSpecificPhobiaScore(d.state)!
    if (specificPhobiaScore >= 4) {
      return { nextStep: this.askSpecificPhobia }
    }
    return { nextStep: this.checkPanicPhobia }
  }

  @step.logState
  askSpecificPhobia(_d: IStepData<State>): IStepResult {
    return {
      body: "And do you experience anxiety related to a specific situation, activity or object?",
      prompt: {
        id: this.getPromptId("askSpecificPhobia"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes, something specific", value: true },
          { body: "No, nothing specific", value: false }
        ],
        dataPointsName: "askSpecificPhobia"
      },
      nextStep: this.handleSpecificPhobia
    }
  }

  @step.logState
  handleSpecificPhobia(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askAnxietyAssociations }
    }
    return { nextStep: this.checkPanicPhobia }
  }

  @step.logState
  askAnxietyAssociations(_d: IStepData<State>): IStepResult {
    return {
      body:
        "Is your anxiety associated with any of the following?\n\n" +
        "- avoiding or doing an activity\n" +
        "- being in contact with an object or animal\n" +
        "- being in a particular environment (e.g. flights or heights)",
      prompt: {
        id: this.getPromptId("askAnxietyAssociations"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAnxietyAssociations"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleAnxietyAssociations
    }
  }

  @step.logState
  handleAnxietyAssociations(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askAnxietyRelation }
    }
    return { nextStep: this.askAgoraphobia }
  }

  @step.logState
  askAgoraphobia(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you afraid of going out of the house, being in crowds or taking public transport?",
      prompt: {
        id: this.getPromptId("askAgoraphobia"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAgoraphobia"
      },
      nextStep: this.handleAgoraphobia
    }
  }

  @step.logState
  handleAgoraphobia(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.addClinicalNote(AssessmentClinicalNotes.Agoraphobia)
      this.clinicalStore.addPrimaryProblem(ProblemCategories.Agoraphobia)
      return { nextStep: this.checkPanicPhobia }
    }
    return { nextStep: this.askAnxietyRelation }
  }

  @step.logState
  askAnxietyRelation(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "What does your anxiety/fear relate to?",
        "Please briefly explain what situations/environments you avoid"
      ],
      prompt: {
        id: this.getPromptId("askAnxietyRelation"),
        type: "inlinePicker",
        choices: [
          { body: "I don't want to say", value: "dontWantToSay" },
          { body: "I don't know", value: "dontKnow" }
        ],
        textPrompt: {
          forceValue: true,
          validation: [/^([\w\s\-;:&]{0,50})$/],
          validationExplainer: ["Please try to explain it with simple words (50 characters max)"]
        },
        dataPointsName: "askAnxietyRelation"
      },
      nextStep: this.handleAnxietyRelation
    }
  }

  @step.logState
  handleAnxietyRelation(d: IStepData<State, string>): IStepResult {
    if (["dontWantToSay", "dontKnow"].includes(d.response)) {
      this.addClinicalNote(AssessmentClinicalNotes.SpecificPhobia)
    } else {
      this.referralStore.addClinicalNote(`User said their fear relates to ${d.response}`)
    }
    this.clinicalStore.addPrimaryProblem(ProblemCategories.SpecificPhobia)
    return { nextStep: this.checkPanicPhobia }
  }

  @step.logState
  checkPanicPhobia(d: IStepData<State>): IStepResult {
    const panicPhobiaScore = this.getPanicPhobiaScore(d.state)!
    if (panicPhobiaScore >= 4) return { nextStep: this.askAnxietySpeed }
    return { nextStep: this.askPTSD }
  }

  @step.logState
  askAnxietySpeed(_d: IStepData<State>): IStepResult {
    return {
      body: "Does the anxiety come on quickly? (i.e. do you feel panic?)",
      prompt: {
        id: this.getPromptId("askAnxietySpeed"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAnxietySpeed"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleAnxietySpeed
    }
  }

  @step.logState
  handleAnxietySpeed(d: IStepData<State, boolean>): IStepResult {
    if (!d.response) {
      this.addClinicalNote(AssessmentClinicalNotes.AnxietySpeedNo)
      return { nextStep: this.askPTSD }
    }
    this.addClinicalNote(AssessmentClinicalNotes.AnxietySpeedYes)
    return { nextStep: this.askAnxietyQuickPeak }
  }

  @step.logState
  askAnxietyQuickPeak(_d: IStepData<State>): IStepResult {
    return {
      body: "Would you say your symptoms peak within 10 minutes?",
      prompt: {
        id: this.getPromptId("askAnxietyQuickPeak"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAnxietyQuickPeak"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleAnxietyQuickPeak
    }
  }

  @step.logState
  handleAnxietyQuickPeak(d: IStepData<State, boolean>): IStepResult {
    if (!d.response) {
      this.addClinicalNote(AssessmentClinicalNotes.AnxietyPeakNo)
      return { nextStep: this.askPTSD }
    }
    this.addClinicalNote(AssessmentClinicalNotes.AnxietyPeakYes)
    return { nextStep: this.askAnxietyPhysicalSensations }
  }

  @step.logState
  askAnxietyPhysicalSensations(_d: IStepData<State>): IStepResult {
    return {
      body: "And does your anxiety involve any physical sensations? (E.g. sweating, trembling, shortness of breath, palpitations, chest pain, dizziness, nausea, or thoughts of losing control or dying)",
      prompt: {
        id: this.getPromptId("askAnxietyPhysicalSensations"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAnxietyPhysicalSensations"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleAnxietyPhysicalSensations
    }
  }

  @step.logState
  handleAnxietyPhysicalSensations(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      this.rootStore.clinicalStore.addPrimaryProblem(ProblemCategories.Panic)
    }
    const key = d.response //
      ? AssessmentClinicalNotes.AnxietySensationsYes
      : AssessmentClinicalNotes.AnxietySensationsNo
    this.addClinicalNote(key)
    return { nextStep: this.askPTSD }
  }

  @step.logState
  askPTSD(_d: IStepData<State>): IStepResult {
    return {
      body: "Has anything extremely upsetting or traumatic ever happened to you?",
      prompt: {
        id: this.getPromptId("askPTSD"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPTSD"
      },
      nextStep: this.handlePTSD
    }
  }

  @step.logState
  handlePTSD(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askFlashbacksOrNightmares }
    }
    return { nextStep: this.askWorry }
  }

  @step.logState
  askFlashbacksOrNightmares(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have flashbacks, thoughts or nightmares about the event?",
      prompt: {
        id: this.getPromptId("askFlashbacksOrNightmares"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askFlashbacksOrNightmares"
      },
      nextStep: this.handleFlashbacksOrNightmares
    }
  }

  @step.logState
  handleFlashbacksOrNightmares(d: IStepData<State, boolean>): IStepResult {
    // this check exists because the PTSD problem descriptor
    // needs to be added if askFlashbacksOrNightmares is
    // answered with yes OR askAvoidsReminders is answered
    // with yes, so adding the same check in both handler
    // steps achieves that result
    // ¯\_(ツ)_/¯
    if (d.response && !this.clinicalStore.primaryProblems.includes(ProblemCategories.PTSD)) {
      this.clinicalStore.addPrimaryProblem(ProblemCategories.PTSD)
    }
    return { nextStep: this.askAvoidsReminders }
  }

  @step.logState
  askAvoidsReminders(_d: IStepData<State>): IStepResult {
    return {
      body: "And/or: Do you try not to think about it, or go out of your way to avoid reminders?",
      prompt: {
        id: this.getPromptId("askAvoidsReminders"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAvoidsReminders"
      },
      nextStep: this.handleAvoidsReminders
    }
  }

  @step.logState
  handleAvoidsReminders(d: IStepData<State>): IStepResult {
    // this check exists because the PTSD problem descriptor
    // needs to be added if askFlashbacksOrNightmares is
    // answered with yes OR askAvoidsReminders is answered
    // with yes, so adding the same check in both handler
    // steps achieves that result
    // ¯\_(ツ)_/¯
    if (d.response && !this.clinicalStore.primaryProblems.includes(ProblemCategories.PTSD)) {
      this.clinicalStore.addPrimaryProblem(ProblemCategories.PTSD)
    }
    return { nextStep: this.askWorry }
  }

  @step.logState
  askWorry(_d: IStepData<State>): IStepResult {
    return {
      body: "Is worrying about a variety of different things an issue for you and something that you would like help with?",
      prompt: {
        id: this.getPromptId("askWorry"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askWorry"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWorry
    }
  }

  @step.logState
  handleWorry(d: IStepData<State, boolean>): IStepResult {
    if (!d.response) {
      this.addClinicalNote(AssessmentClinicalNotes.WorryNo)
      return { nextStep: this.checkAnxietyNOS }
    }

    this.addClinicalNote(AssessmentClinicalNotes.WorryYes)
    const gad7Score = this.getGAD7Total(d.state)!
    if (gad7Score >= 8 && gad7Score <= 15) {
      this.rootStore.clinicalStore.addPrimaryProblem(ProblemCategories.M_GADWorry)
    } else if (gad7Score > 15) {
      this.rootStore.clinicalStore.addPrimaryProblem(ProblemCategories.S_GADWorry)
    }
    return { body: "That's interesting...", nextStep: this.askHealthAnxiety }
  }

  @step.logState
  askHealthAnxiety(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you worry a lot about an illness that doctors haven't found?",
      prompt: {
        id: this.getPromptId("askHealthAnxiety"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askHealthAnxiety"
      },
      nextStep: this.handleHealthAnxiety
    }
  }

  @step.logState
  handleHealthAnxiety(d: IStepData<State>): IStepResult {
    if (d.response) {
      this.clinicalStore.addPrimaryProblem(ProblemCategories.HealthAnxiety)
    }
    return { nextStep: this.checkAnxietyNOS }
  }

  @step.logState
  checkAnxietyNOS(d: IStepData<State>): IStepResult {
    const hasAnxietyPrimaries =
      this.clinicalStore.primaryProblems.filter(
        p =>
          ![
            ProblemCategories.M_Depression,
            ProblemCategories.MS_Depression,
            ProblemCategories.S_Depression
          ].includes(p)
      ).length > 0
    const gad7Total = this.getGAD7Total(d.state)!
    // we're checking for the GAD7 score as well to make sure
    // that we only assign AnxietyNOS only if there was GAD
    // involved, otherwise we would end up with scenarios
    // where the user only has depression aka high PHQ9,
    // but then was also given the AnxietyNOS problem descriptor
    // just because they had to go through the anxiety screening
    // answering no to everything, and that wouldn't make sense
    // clinically
    if (!hasAnxietyPrimaries && gad7Total >= 8) {
      this.clinicalStore.addPrimaryProblem(ProblemCategories.GAD)
    }
    return { nextStep: this.sayThanksForSharing }
  }

  @step.logState
  sayThanksForSharing(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Ok, thanks for sharing ${name}`,
      nextStep: this.checkMostAffectingPrimary
    }
  }

  @step.logState
  checkMostAffectingPrimary(_d: IStepData<State>): IStepResult {
    const primaries = this.rootStore.clinicalStore.primaryProblems
    if (primaries.length > 1) return { nextStep: this.askMostAffectingPrimary }
    return { nextStep: this.finishAssessment }
  }

  @step.logState
  askMostAffectingPrimary(_d: IStepData<State>): IStepResult {
    const primaries = this.rootStore.clinicalStore.primaryProblems //
      .map(name => ({
        body: problemCategoryUserFriendlyNames[name]?.toLocaleLowerCase() as string,
        value: name as string
      }))
    const formattedProblems = primaries.map(i => i.body)
    const problemsString = joinWithAnd(formattedProblems)
    const body = [
      `Your answers indicate that you are experiencing symptoms of ${problemsString}`,
      "Are you able to tell me which one of these affects you most?"
    ]
    return {
      body,
      prompt: {
        id: this.getPromptId("askMostAffectingPrimary"),
        trackResponse: true,
        type: "inlinePicker",
        choices: primaries.concat({ body: "I don't know", value: "none" }),
        dataPointsName: "askMostAffectingPrimary"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleMostAffectingPrimary
    }
  }

  @step.logState
  handleMostAffectingPrimary(d: IStepData<State, ProblemCategories | "none">): IStepResult {
    const primaries = this.rootStore.clinicalStore.primaryProblems.slice()
    const selectedPrimary = d.response === "none" ? undefined : d.response

    if (selectedPrimary) {
      this.referralStore.setCustomField("mostAffectingPrimary", selectedPrimary)
    }
    this.addMostAffectingPrimaryClinicalNote(primaries, selectedPrimary)
    return { nextStep: this.askWhichPrimaryCameFirst }
  }

  @step.logState
  askWhichPrimaryCameFirst(_d: IStepData<State>): IStepResult {
    const primaries = this.rootStore.clinicalStore.primaryProblems //
      .map(name => ({
        body: problemCategoryUserFriendlyNames[name]?.toLocaleLowerCase() as string,
        value: name as string
      }))
    return {
      body: "Which symptoms did you notice first?",
      prompt: {
        id: this.getPromptId("askWhichPrimaryCameFirst"),
        trackResponse: true,
        type: "inlinePicker",
        choices: primaries.concat({ body: "I don't know", value: "none" }),
        dataPointsName: "askWhichPrimaryCameFirst"
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWhichPrimaryCameFirst
    }
  }

  @step.logState
  handleWhichPrimaryCameFirst(d: IStepData<State, ProblemCategories | "none">): IStepResult {
    const primaries = this.rootStore.clinicalStore.primaryProblems.slice()
    const secondaries = this.rootStore.clinicalStore.secondaryProblems.slice()
    const selectedPrimary = d.response === "none" ? undefined : d.response

    if (selectedPrimary) this.addWhichPrimaryCameFirstClinicalNote(selectedPrimary)
    else this.addWhichPrimaryCameFirstClinicalNote(selectedPrimary)

    const mostAffectingPrimary = this.referralStore.getCustomField("mostAffectingPrimary")

    if ((selectedPrimary || mostAffectingPrimary) && primaries.length > 1) {
      const newPrimary = mostAffectingPrimary || selectedPrimary
      const newPrimaries = [newPrimary]
      const newSecondaries = [...new Set([...secondaries, ...primaries])] //
        .filter(c => c !== newPrimary)
      this.rootStore.clinicalStore.setPrimaries(newPrimaries)
      this.rootStore.clinicalStore.setSecondaries(newSecondaries)
    }

    return { nextStep: this.finishAssessment }
  }

  @step.logState
  async finishAssessment(d: IStepData<State>): Promise<IStepResult> {
    const signupCode = this.referralStore.signupCode
    const patientID = this.referralStore.patientId

    /**
     NOTE:
     We need to do this because if the flow has the assessment first
     and then the self referral then sendDataPoints will always fail
    */
    if (signupCode || patientID) {
      const age = this.getUserAge(d.state)
      await this.rootStore.dataPointsStore.sendDataPoints(age)
    }

    const currentPath = this.clinicalStore.pathDescriptor
    if (!validateClinicalPath(currentPath)) {
      this.logBreadcrumb("Clinical Path", undefined, { currentPath })
      this.logMessage("Invalid Clinical Path found")
    }
    this.clinicalStore.generateClinicalPath()
    this.clinicalStore.setAssessmentFinished(true)
    this.track(TrackingEvents.ASSESSMENT_REACHED)
    this.updateReferralType(d.state)

    const primaryProblems = this.clinicalStore.primaryProblems
    const secondaryProblems = this.clinicalStore.secondaryProblems
    const clinicalFlags = this.clinicalStore.flags
    const clinicalGroup = this.clinicalStore.clinicalPath?.clinicalGroup
    await this.referralStore.updateReferral({
      problemDescriptorPrimary: primaryProblems,
      problemDescriptorSecondary: secondaryProblems,
      clinicalFlags
    })
    this.setPeople({ clinicalGroup, primaryProblems, secondaryProblems, clinicalFlags })
    const result = await this.onFinishAssessment?.(d.state)
    if (result) return result
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  addClinicalNote(key: AssessmentClinicalNotes): void {
    const map = this.getClinicalNotesMap()
    const clinicalNote = map[key]
    if (!clinicalNote) {
      this.logBreadcrumb("addClinicalNote with a wrong key", {}, { key, map })
      this.logMessage("addClinicalNote with a wrong key")
    }
    this.referralStore.addClinicalNote(clinicalNote)
  }

  addMostAffectingPrimaryClinicalNote(
    problems: ProblemCategories[],
    primary?: ProblemCategories
  ): void {
    if (!problems.length) return
    const problemsString = joinWithAnd(problems)
    if (primary) {
      const clinicalNote = `User indicated symptoms of ${problemsString}. They selected ${primary} as their primary problem.`
      this.referralStore.addClinicalNote(clinicalNote)
      return
    }
    const clinicalNote = `User indicated symptoms of ${problemsString}. They were unable to identify their primary problem`
    this.referralStore.addClinicalNote(clinicalNote)
  }

  addWhichPrimaryCameFirstClinicalNote(primary?: ProblemCategories): void {
    if (primary) {
      this.referralStore.addClinicalNote(`User indicated that ${primary} showed up first`)
      return
    }
    this.referralStore.addClinicalNote("User does not remember which symptoms showed up first")
  }

  getClinicalNotesMap(): Record<AssessmentClinicalNotes, string> {
    // prettier-ignore
    return {
      [AssessmentClinicalNotes.BelowCaseness]: "User identified some symptoms of anxiety and depression. Consider Mixed anxiety and depression disorder",
      [AssessmentClinicalNotes.BC_Depression]: "User scored above caseness for low mood and anxiety - but selected low mood as their primary problem",
      [AssessmentClinicalNotes.BC_Anxiety]: "User scored above caseness for low mood and anxiety - but selected anxiety as their primary problem",
      [AssessmentClinicalNotes.Agoraphobia]: "User's anxiety relates to AGORAPHOBIA",
      [AssessmentClinicalNotes.NonSpecificPhobia]: "Patient's anxiety relates to NOTHING SPECIFIC",
      [AssessmentClinicalNotes.SpecificPhobia]: "Patient's anxiety relates to SOMETHING SPECIFIC",
      [AssessmentClinicalNotes.AnxietySpeedYes]: "Patient's anxiety DOES come on quickly (i.e. they feel panic)",
      [AssessmentClinicalNotes.AnxietySpeedNo]: "Patient's anxiety DOES NOT come on quickly (i.e. they do not feel panic)",
      [AssessmentClinicalNotes.AnxietyPeakYes]: "Patient's anxiety symptoms DO peak within 10 minutes",
      [AssessmentClinicalNotes.AnxietyPeakNo]: "Patient's anxiety symptoms DO NOT peak within 10 minutes",
      [AssessmentClinicalNotes.AnxietySensationsYes]: "Patient's anxiety DOES involve physical sensations (e.g. sweating, trembling, shortness of breath, palpitations, chest pain, dizziness, nausea, or thoughts of losing control or dying)",
      [AssessmentClinicalNotes.AnxietySensationsNo]: "Patient's anxiety DOES NOT involve physical sensations (e.g. sweating, trembling, shortness of breath, palpitations, chest pain, dizziness, nausea, or thoughts of losing control or dying)",
      [AssessmentClinicalNotes.WorryYes]: "Patient DOES worry about a variety of things",
      [AssessmentClinicalNotes.WorryNo]: "Patient DOES NOT worry about a variety of things",
      [AssessmentClinicalNotes.HealthAnxiety]: "Patient DOES worry about their health/illness",
      [AssessmentClinicalNotes.OCDAnxiety]: "Patient DOES experience intrusive/unwanted thoughts/images",
      [AssessmentClinicalNotes.PTSDAnxiety]: "Patient DOES have distress over past traumatic experience(s)"
    }
  }

  async submitEmail(state: State, emails: string[]): Promise<void> {
    const subject = "Limbic | User Completed the Assessment"
    const organisationName = this.rootStore.configStore.organisationName
    try {
      const text = this.createBookingEmail(
        state,
        this.clinicalStore.isRisk,
        "completed the assessment"
      )
      const to = emails.filter(Boolean) as string[]
      await sendEmail({ to, subject, text }, organisationName || "")
    } catch (e) {
      this.logException(e, "submitEmail")
    }
  }
}
