import {
  ARMED_FORCES_MAYDEN_NORFOLK,
  CorrespondencePreferred,
  CurrentSupportDetailsNorfolk,
  DISABILITY_MAYDEN_NORFOLK,
  ETHNICITY_MAYDEN_NORFOLK,
  GENDER_MAYDEN_NORFOLK,
  GenderBirthAssigned,
  LANGUAGE_MAYDEN_NORFOLK,
  LTC_MAYDEN_NORFOLK,
  NATIONALITY_MAYDEN,
  PERINATAL_MAYDEN_NORFOLK,
  ReferralSourceNorfolk,
  SEXUALITY_MAYDEN_NORFOLK
} from "@limbic/types"

export const ltcs: Record<string, LTC_MAYDEN_NORFOLK> = {
  Asthma: "ASTHMA",
  Cancer: "CANCER",
  "Cardiovascular disease (angina, heart attack, stroke, TIA)": "CARDIOVASCULAR",
  "Chronic Fatigue Syndrome (ME)": "FATIGUE",
  COPD: "COPD",
  "Coronary heart disease": "CHD",
  "Diabetes (Type 1)": "DIABETES_TYPE1",
  "Diabetes (Type 2)": "DIABETES_TYPE2",
  "Irritable Bowel Syndrome": "IBS",
  "Musculoskeletal condition (eg. arthritis)": "MUSCULOSKELETAL",
  "Neurological condition": "NEUROLOGICAL",
  "Pain (chronic pain)": "CHRONIC_PAIN",
  "Long covid": "POST_COVID",
  Other: "NOT_LISTED",
  "Multiple long term conditions": "NOT_LISTED"
}

export const sexualities: Record<string, SEXUALITY_MAYDEN_NORFOLK> = {
  Heterosexual: "HETEROSEXUAL",
  "Lesbian or Gay": "HOMOSEXUAL",
  Bisexual: "BISEXUAL",
  Other: "NOT_LISTED",
  Unknown: "UNKNOWN",
  "Not stated": "NOT_ANSWERED"
}

export const genders: Record<string, GENDER_MAYDEN_NORFOLK> = {
  "Male (including trans man)": "MALE",
  "Female (including trans woman)": "FEMALE",
  "Non-binary": "NON_BINARY",
  Other: "UNKNOWN",
  "I don't want to say": "NOT_ANSWERED"
}

export const sameGenderAsBirth: Record<string, GenderBirthAssigned> = {
  Yes: "YES",
  No: "NO",
  "Not Stated": "NOT_ANSWERED"
}

export const ethnicities: Record<string, ETHNICITY_MAYDEN_NORFOLK> = {
  "White British": "WHITE_BRITISH",
  "White Irish": "WHITE_IRISH",
  "White, any other White background": "WHITE_OTHER",
  "Mixed, White & Black Caribbean": "MIXED_WHITE_BLACK_CARIBBEAN",
  "Mixed, White & Black African": "MIXED_WHITE_BLACK_AFRICAN",
  "Mixed, White & Asian": "MIXED_WHITE_ASIAN",
  "Any other mixed background": "MIXED_OTHER",
  "Asian or Asian British, Indian": "ASIAN_BRITISH_INDIAN",
  "Asian or Asian British, Pakistani": "ASIAN_BRITISH_PAKISTANI",
  "Asian or Asian British, Bangladeshi": "ASIAN_BRITISH_BANGLADESHI",
  "Any other Asian background": "ASIAN_BRITISH_OTHER",
  "Black or Black British, Caribbean": "BLACK_BRITISH_CARIBBEAN",
  "Black or Black British, African": "BLACK_BRITISH_AFRICAN",
  "Any other Black background": "BLACK_BRITISH_OTHER",
  "Other Ethnic Groups, Chinese": "CHINESE",
  "Any other ethinic group": "OTHER",
  "Not stated": "NOT_ANSWERED",
  "Not known": "UNKNOWN"
}

export const exArmedForces: Record<string, ARMED_FORCES_MAYDEN_NORFOLK> = {
  No: "NO",
  "Ex-services member": "YES_EX",
  "Dependent of an ex-services member": "DEPENDENT",
  "Not known/not sure": "UNKNOWN",
  "Don't wish to say": "NOT_ANSWERED"
}

export const disabilities: Record<string, DISABILITY_MAYDEN_NORFOLK> = {
  // TODO: None should be handled in the question as single select
  None: "NONE",
  "Autistic spectrum disorder": "AUTISM",
  "Behaviour & emotional": "BEHAVIOUR",
  Hearing: "HEARING",
  "Manual dexterity": "MANUAL",
  "Memory or ability to concentrate, learn or understand (Learning Disability)": "LEARNING",
  "Mobility & gross motor": "MOTOR",
  "Perception of physical danger": "PERCEPTION",
  "Personal, Self care & continence": "SELF_CARE",
  "Progressive conditions & physical health (eg. HIV, cancer, fits, MS etc.)": "PROGRESSIVE",
  Sight: "SIGHT",
  ADHD: "ADHD",
  Speech: "SPEECH",
  Other: "NOT_LISTED",
  "Do not wish to say": "NOT_ANSWERED"
}

export const nationalities: Record<string, NATIONALITY_MAYDEN> = {
  English: "ENGLAND",
  Scottish: "SCOTLAND",
  Irish: "IRELAND",
  Welsh: "WALES",
  British: "BRITAIN",
  Other: "NOT_LISTED"
}

export const perinatalStatuses: Record<string, PERINATAL_MAYDEN_NORFOLK> = {
  "None apply": "NONE",
  Pregnant: "PREGNANT",
  "Child under 1": "CHILD_UNDER_1"
}

export const languages: Record<string, LANGUAGE_MAYDEN_NORFOLK> = {
  English: "ENGLISH",
  "Akan (Ashanti)": "AKAN",
  Albanian: "ALBANIAN",
  Amharic: "AMHARIC",
  Arabic: "ARABIC",
  "Bengali & Sylheti": "BENGALI",
  "Brawa & Somali": "BRAWA",
  "British Sign Language": "BRITISH_SIGN_LANGUAGE",
  Cantonese: "CANTONESE",
  // TODO: Remove this or add a specific value 👇
  "Cantonese & Vietnamese": "CANTONESE",
  Creole: "CREOLE",
  Dutch: "DUTCH",
  Ethiopian: "ETHIOPIAN",
  "Farsi (Persian)": "PERSIAN_FARSI",
  Finnish: "FINNISH",
  Flemish: "FLEMISH",
  French: "FRENCH",
  "French Creole": "FRENCH_CREOLE",
  Gaelic: "GAELIC",
  German: "GERMAN",
  Greek: "GREEK",
  Gujerati: "GUJARATI",
  Hakka: "HAKKA",
  Hausa: "HAUSA",
  Hebrew: "HEBREW",
  Hindi: "HINDI",
  "Igbo (Ibo)": "IGBO",
  Italian: "ITALIAN",
  Japanese: "JAPANESE",
  Korean: "KOREAN",
  Kurdish: "KURDISH",
  Lingala: "LINGALA",
  Luganda: "LUGANDA",
  "Makaton (Sign Language)": "MAKATON_SIGN_LANGUAGE",
  Malayalam: "MALAYALAM",
  Mandarin: "MANDARIN",
  Norwegian: "NORWEGIAN",
  "Pashto (Pashtoo)": "PASHTO",
  Patois: "PATOIS",
  Polish: "POLISH",
  Portuguese: "PORTUGUESE",
  Punjabi: "PUNJABI",
  Russian: "RUSSIAN",
  "Serbian/Croatian": "SERBIAN",
  Sinhala: "SINHALA",
  Somali: "SOMALI",
  Spanish: "SPANISH",
  Swahili: "SWAHILI",
  Swedish: "SWEDISH",
  Sylheti: "SYLHETI",
  "Tagalog (Filipino)": "TAGALOG_FILIPINO",
  Tamil: "TAMIL",
  Thai: "THAI",
  Tigrinya: "TIGRINYA",
  Turkish: "TURKISH",
  Urdu: "URDU",
  Vietnamese: "VIETNAMESE",
  Welsh: "WELSH",
  Yoruba: "YORUBA",
  Other: "NOT_LISTED"
}

export const preferredContactMethods: Record<string, CorrespondencePreferred> = {
  Telephone: "PHONE",
  Text: "SMS",
  "E-mail": "EMAIL",
  Letter: "POST"
}

export const currentSupport: Record<string, CurrentSupportDetailsNorfolk> = {
  "Currently receiving a talking therapy": "CURRENT_TALKING_THERAPY",
  "Currently on the waiting list for another mental health service": "CURRENT_WAITLIST_MH_SERVICE",
  No: "NO"
}

export const referralSources: Record<string, ReferralSourceNorfolk> = {
  "Bus back advertising": "Bus back advertising",
  Radio: "Radio",
  GP: "GP",
  "Other healthcare care professional": "Other healthcare care professional",
  "Word of mouth": "Word of mouth",
  "Social media": "Social media",
  Newspaper: "Newspaper",
  "Job centre/employment service": "Job centre/employment service",
  "A workshop at your organisation": "A workshop at your organisation",
  Event: "Event",
  "Poster/leaflet": "Poster/leaflet",
  "Received a letter": "Received a letter",
  "Search engine (i.e Google)": "Search engine (i.e Google)",
  "Other (please state)": "Other (please state)"
}

const form = {
  form_url: "https://gateway.mayden.co.uk/referral-v2/4f947c6a-1997-4e27-8d89-ecc0905bc7cc",
  filled_by: "API",
  perinatalStatuses: Object.keys(perinatalStatuses),
  sexualities: Object.keys(sexualities),
  genders: Object.keys(genders),
  sameGenderAsBirth: Object.keys(sameGenderAsBirth),
  ethnicities: Object.keys(ethnicities),
  exArmedForces: Object.keys(exArmedForces),
  disabilities: Object.keys(disabilities),
  LTCs: Object.keys(ltcs),
  nationalities: Object.keys(nationalities),
  languages: Object.keys(languages),
  preferredContactMethods: Object.keys(preferredContactMethods),
  referralSources: Object.keys(referralSources),
  currentSupport: Object.keys(currentSupport)
}

export default form
