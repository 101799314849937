import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript, { GoodbyeScriptStateSchema } from "./GoodbyeScript"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import getQRCodeURL from "../../../utils/getQRCodeURL"

interface State extends GoodbyeScriptState {
  appointment?: string
  canKeepSelfSafe?: boolean
  requiresUrgentSupport?: boolean
}

export type GoodbyeNottinghamshireScriptState = State

export const GoodbyeNottinghamshireScriptStateSchema = GoodbyeScriptStateSchema.extend({
  appointment: z.string().optional(),
  canKeepSelfSafe: z.boolean().optional(),
  requiresUrgentSupport: z.boolean().optional()
})

interface WindowWithLimbicNameSpace extends Window {
  DEEP_LINK: string
}

declare let window: WindowWithLimbicNameSpace

window.DEEP_LINK = String(process.env.REACT_APP_DEEP_LINK ?? "enabled")

const deepLinkEnabled = window.DEEP_LINK !== "disabled"

export class GoodbyeNottinghamshireScript extends GoodbyeScript {
  readonly name: string = "GoodbyeNottinghamshireScript"

  /** Script Steps */

  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    await this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()
    const result = await super.sayGoodBye(d)

    return { ...result, nextStep: this.end }
  }

  // This is here to use api v2
  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: GoodbyeNottinghamshireScript) => {
    d.state.whereDidYouHearAboutService = d.response
    void script.referralStore.updateReferral({ whereDidYouHearAboutService: d.response })
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: GoodbyeScript) => s.askFeedback
  })
  handleWhereDidYouHearAboutUs(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askFeedback }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return GoodbyeNottinghamshireScriptStateSchema
  }

  async getIntroMessage(_state: State): Promise<string | void> {
    return "I'm looking forward to going on this journey with you"
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      let referralMessage = `I've referred you to ${iaptName}`
      if (!this.clinicalStore.isRisk) {
        referralMessage =
          referralMessage +
          "\n\nA member of the team will be in touch to discuss the treatment options within 72 hours"
      }
      return state.isIdleSubmitted //
        ? `It looks like there hasn’t been any activity for some time so I've referred you to ${iaptName}`
        : referralMessage
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      return "Because you identified as being in crisis, someone from our duty of care team will call you within 72 hours. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger."
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      return "Because you identified as being in crisis, someone from our duty of care team will call you within 72 hours. Please remember, Limbic is not an emergency response service and you are encouraged to contact 999 if you feel you are in danger."
    }
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const hasDynamicLink = deepLinkEnabled && state.careSignupURL
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    const lastMessage = isCrisis
      ? `Thank you for sharing this information with me ${name}`
      : `Well, it's been a pleasure getting to know you ${name}`
    if (state.referralSubmitted && hasDynamicLink) {
      const qrCodeURL = getQRCodeURL(state.careSignupURL!)

      return [
        "We can continue our conversations in my mobile app",
        `If you're already on your mobile device, just click [here](${state.careSignupURL}) to download it`,
        "If you're on a desktop computer, you can scan the QR code below",
        {
          type: "imageAttachment",
          image: qrCodeURL
        },
        lastMessage
      ]
    }
    return [lastMessage]
  }
}

/* istanbul ignore next */
export default class GoodbyeNottinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeNottinghamshire
  readonly name: string = "GoodbyeNottinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeNottinghamshireDialogue.id, new GoodbyeNottinghamshireScript(), state, snapshot)
  }
}
