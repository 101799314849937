import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { AssessmentWithADSMScript } from "./AssessmentWithADSMScript"

type State = AssessmentIAPTScriptState

export class AssessmentLincolnshireScript extends AssessmentWithADSMScript {
  readonly name: string = "AssessmentLincolnshireScript"

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

/* istanbul ignore next */
export default class AssessmentLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentLincolnshire
  readonly name: string = "AssessmentLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentLincolnshireDialogue.id, new AssessmentLincolnshireScript(), state, snapshot)
  }
}
