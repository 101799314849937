/* eslint-disable @typescript-eslint/no-empty-function */
import moment from "moment"
import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptStateSchema } from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import invariant from "../../../utils/invariant"
import type {
  ARMED_FORCES_MAYDEN_LINCS,
  CIVIL_STATUS_MAYDEN_LINCS,
  DISABILITY_MAYDEN_LINCS,
  ETHNICITY_MAYDEN_LINCS,
  GENDER_MAYDEN_LINCS,
  LANGUAGE_MAYDEN_LINCS,
  LIMBIC_IMPACT_LEVEL,
  LTC_MAYDEN_LINCS,
  PERINATAL_MAYDEN_LINCS,
  ReferralPayloadMaydenLincs,
  RELIGION_MAYDEN_LINCS,
  SEXUALITY_MAYDEN_LINCS,
  GenderBirthAssigned
} from "@limbic/types"
import {
  ALCOHOL_FREQUENCIES,
  ALCOHOL_QUANTITIES,
  DiscussionSteps,
  TrackingEvents
} from "../../../models/Constants"
import { ALCOHOL_QUANTITIES as AQ, ALCOHOL_FREQUENCIES as AF } from "@limbic/types"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  genderSameAsBirth,
  languages,
  ltcs,
  maritalStatuses,
  perinatalStatuses,
  religions,
  sexualities
} from "../../../config/referralForms/lincolnshire-form"
import { removeCountryCodeFromPhoneNumber } from "../../../utils/formatPhoneNumber"
import {
  IInlinePickerMultiSelectPrompt,
  IInlinePickerSingleSelectPrompt
} from "../../../backend/chatbot/models/IPrompt"

interface State extends SelfReferralIAPTScriptState {
  gpConsent?: boolean
  sameGenderAsBirth?: string
  relationshipStatus?: string
  ableToCommunicateInEnglish?: boolean
  requiresInterpreter?: boolean
  hasMobilityIssues?: boolean
  hasFormalDiagnosis?: boolean
  formalDiagnosisDetails?: string
  currentSupportDetails?: string
  isTakingMedication?: boolean
}

export type SelfReferralLincolnshireState = State

export const SelfReferralLincolnshireStateSchema = SelfReferralIAPTScriptStateSchema.extend({
  gpConsent: z.boolean().optional(),
  sameGenderAsBirth: z.string().optional(),
  relationshipStatus: z.string().optional(),
  mainSpokenLanguage: z.string().optional(),
  ableToCommunicateInEnglish: z.boolean().optional(),
  requiresInterpreter: z.boolean().optional(),
  hasMobilityIssues: z.boolean().optional(),
  hasFormalDiagnosis: z.boolean().optional(),
  formalDiagnosisDetails: z.string().optional(),
  currentSupportDetails: z.string().optional(),
  isTakingMedication: z.boolean().optional()
})

export class SelfReferralLincolnshireScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralLincolnshireScript"

  /** Script Steps */

  @step.logState
  startSelfReferralPart1(d: IStepData<State>): IStepResult {
    if (d.state.nhsNumber) {
      return { nextStep: this.goToCollectPhoneNumber }
    }
    return { nextStep: this.askNHSNumber }
  }

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.goToCollectPhoneNumber(d)
    return { ...result, nextStep: this.askEmail }
  }

  @step.logState
  askNHSNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "What is your NHS number?",
      prompt: {
        id: this.getPromptId("askNHSNumber"),
        trackResponse: false,
        type: "inlinePicker",
        choices: [{ body: "I don't know", value: null }],
        textPrompt: {
          trimAllSpacesOnSubmit: true,
          trimAllSpacesOnValidation: true,
          validation: [/^\d{10}$/],
          validationExplainer: [
            "This is not a valid NHS number",
            "It needs to be a number with 10 digits"
          ],
          forceValue: true
        }
      },
      nextStep: this.handleNHSNumber
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.nhsNumber = d.response ? d.response : undefined
  })
  handleNHSNumber(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  askGPConsent(_d: IStepData<State>): IStepResult {
    const serviceName = this.rootStore.configStore.serviceName

    return {
      body: [
        `As part of our process in ${serviceName}, we share assessment outcomes and treatment summaries with your GP`,
        "Are you happy for us to share these summaries with your GP?"
      ],
      prompt: {
        id: this.getPromptId("askGPConsent1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleGPConsent
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralLincolnshireScript) => {
    d.state.gpConsent = d.response
    script.referralStore.setCustomField<State>("gpConsent", d.response)
  })
  handleGPConsent(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askMainIssue }
    }
    return {
      body: "You selected that you don’t want the clinical summaries shared with you GP, please be aware that in the event of an emergency or issues with risk or safeguarding we would contact your GP for their support",
      nextStep: this.askMainIssue
    }
  }

  @step.logState
  askPermissionToEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you allow us to contact you over email?",
      prompt: {
        id: this.getPromptId("askPermissionToEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPermissionToEmail"
      },
      nextStep: this.handlePermissionToEmail
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.canSendEmail = d.response
  })
  handlePermissionToEmail(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  @step.logState
  askMainIssue(d: IStepData<State>): IStepResult {
    return {
      body: [
        d.state.gpConsent ? "Brilliant" : undefined,
        "What's the main problem that has brought you here today? (Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life)"
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralLincolnshireScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralLincolnshireScript) => s.finishSelfReferral
  })
  handleMainIssueWithCrisis(d: IStepData<State, string>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Thank you for sharing ${name}`,
        "We'll use the information collected in this referral to try and get you the best available help"
      ],
      nextStep: this.finishSelfReferral
    }
  }

  @step.logState
  startSelfReferralPart2(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askGender }
  }

  @step.logState
  askIdentifyAsSameGenderAtBirth(d: IStepData<State>): IStepResult {
    const genderSameAsBirth = this.getGenderSameAsBirthChoices(d.state)
    if (!genderSameAsBirth?.length) {
      this.logBreadcrumb("GENDER SAME AS BIRTH OPTIONS NOT FOUND", d.state, { genderSameAsBirth })
      this.logMessage("GENDER SAME AS BIRTH OPTIONS NOT FOUND")
      return { nextStep: this.askRelationshipStatus }
    }

    return {
      body: "Do you identify as the same gender you were assigned at birth?",
      prompt: {
        id: this.getPromptId("askIdentifyAsSameGenderAtBirth"),
        type: "inlinePicker",
        choices: genderSameAsBirth.map(g => ({ body: g, value: g })),
        isUndoAble: true,
        dataPointsName: "askIdentifyAsSameGenderAtBirth"
      },
      nextStep: this.handleIdentifyAsSameGenderAtBirth
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralLincolnshireScript) => {
    d.state.sameGenderAsBirth = d.response
    script.referralStore.setCustomField<State>("sameGenderAsBirth", d.response)
  })
  handleIdentifyAsSameGenderAtBirth(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askRelationshipStatus }
  }

  @step.logState
  askRelationshipStatus(d: IStepData<State>): IStepResult {
    const relationshipStatuses = this.getMaritalStatuses(d.state)
    if (!relationshipStatuses?.length) {
      this.logBreadcrumb("RELATIONSHIP STATUSES NOT FOUND", d.state, { relationshipStatuses })
      this.logMessage("RELATIONSHIP STATUSES NOT FOUND")
      return { nextStep: this.askPerinatal }
    }

    return {
      body: "What is your relationship status?",
      prompt: {
        id: this.getPromptId("askRelationshipStatus"),
        type: "inlinePicker",
        choices: relationshipStatuses.map(g => ({ body: g, value: g })),
        isUndoAble: true,
        dataPointsName: "askRelationshipStatus"
      },
      nextStep: this.handleRelationshipStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralLincolnshireScript) => {
    d.state.relationshipStatus = d.response
    script.referralStore.setCustomField<State>("relationshipStatus", d.response)
  })
  handleRelationshipStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPerinatal }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.askPrimaryLanguage }
    }
    return {
      body: "Do any of the following statements about pregnancy or young children apply to you?",
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePicker",
        choices: perinatalStatuses.map(g => ({ body: g, value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logState
  askAbleToCommunicateInEnglish(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you able to communicate in English?",
      prompt: {
        id: this.getPromptId("askAbleToCommunicateInEnglish"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true,
        dataPointsName: "askAbleToCommunicateInEnglish"
      },
      nextStep: this.handleAbleToCommunicateInEnglish
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralLincolnshireScript) => {
    d.state.ableToCommunicateInEnglish = d.response
    script.referralStore.setCustomField<State>("ableToCommunicateInEnglish", d.response)
  })
  handleAbleToCommunicateInEnglish(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askEthnicity }
    }
    return { nextStep: this.askDoYouRequireAnInterpreter }
  }

  @step.logState
  askDoYouRequireAnInterpreter(_d: IStepData<State>): IStepResult {
    return {
      body: "Ok, do you require an interpreter?",
      prompt: {
        id: this.getPromptId("askDoYouRequireAnInterpreter"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleDoYouRequireAnInterpreter
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralLincolnshireScript) => {
    d.state.requiresInterpreter = d.response
    script.referralStore.setCustomField<State>("requiresInterpreter", d.response)
  })
  handleDoYouRequireAnInterpreter(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askEthnicity }
  }

  @step.logState
  askMobilityIssues(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you have mobility issues?",
      prompt: {
        id: this.getPromptId("askMobilityIssues"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true,
        dataPointsName: "askMobilityIssues"
      },
      nextStep: this.handleMobilityIssues
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralLincolnshireScript) => {
    d.state.hasMobilityIssues = d.response
    script.referralStore.setCustomField<State>("hasMobilityIssues", d.response)
  })
  handleMobilityIssues(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askLongTermMedicalCondition }
  }

  @step.logState
  goToCollectAlcoholConsumption(d: IStepData<State>): IStepResult {
    const CollectAlcoholConsumptionDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectAlcoholConsumption
    )

    const nextDialogue = CollectAlcoholConsumptionDialogue
      ? new CollectAlcoholConsumptionDialogue({ ...d.state })
      : undefined

    return {
      nextDialogue,
      nextStep: this.askMedication
    }
  }

  @step.logState
  askMedication(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently taking any medication that has been prescribed by a doctor?",
      prompt: {
        id: this.getPromptId("askMedication"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askMedication"
      },
      nextStep: this.handleMedication
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralLincolnshireScript) => {
    d.state.isTakingMedication = d.response
    script.referralStore.setCustomField<State>("hasMobilityIssues", d.response)
  })
  handleMedication(d: IStepData<State, boolean>): IStepResult {
    return { nextStep: d.response ? this.askMedicationInfo : this.askCurrentSupport }
  }

  @step.logState
  askMedicationInfo(_d: IStepData<State>): IStepResult {
    return {
      body: "Could you let me know the details of the medication you've been prescribed?",
      prompt: {
        id: this.getPromptId("askMedicationInfo"),
        trackResponse: false,
        type: "text",
        forceValue: false,
        cancelLabel: "skip",
        cancelIsEmptySubmit: true,
        dataPointsName: "askMedicationInfo"
      },
      nextStep: this.handleMedicationInfoWithCrisis
    }
  }

  @step.logState
  askCurrentSupportDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please give details",
      prompt: {
        id: this.getPromptId("askCurrentSupportDetails"),
        type: "text",
        forceValue: true,
        isUndoAble: false
      },
      nextStep: this.handleCurrentSupportDetailsWithCrisis
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralLincolnshireScript) => s.askFormalDiagnosis
  })
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralLincolnshireScript) => {
    d.state.currentSupportDetails = d.response
    script.referralStore.setCustomField<State>("currentSupportDetails", d.response)
  })
  handleCurrentSupportDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askFormalDiagnosis }
  }

  @step.logState
  askFormalDiagnosis(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you had any formal diagnosis from a GP, psychiatrist or other mental health professional?",
      prompt: {
        id: this.getPromptId("askFormalDiagnosis"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleFormalDiagnosis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralLincolnshireScript) => {
    d.state.hasFormalDiagnosis = d.response
    script.referralStore.setCustomField<State>("hasFormalDiagnosis", d.response)
  })
  handleFormalDiagnosis(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.askGiveDetailsFormalDiagnosis }
    }
    return { nextStep: this.askWhatIsYourGoal }
  }

  @step.logState
  askGiveDetailsFormalDiagnosis(_d: IStepData<State>): IStepResult {
    return {
      body: "Please give details?",
      prompt: {
        id: this.getPromptId("askGiveDetailsFormalDiagnosis"),
        type: "text",
        forceValue: true,
        isUndoAble: false
      },
      nextStep: this.handleGiveDetailsFormalDiagnosisWithCrisis
    }
  }

  @step.logState
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralLincolnshireScript) => s.askWhatIsYourGoal
  })
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralLincolnshireScript) => {
    d.state.formalDiagnosisDetails = d.response
    script.referralStore.setCustomField<State>("formalDiagnosisDetails", d.response)
  })
  handleGiveDetailsFormalDiagnosisWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askWhatIsYourGoal }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralLincolnshireStateSchema
  }

  async onSubmitReferralData(state: State): Promise<boolean> {
    try {
      const payload = await this.getReferralPayload(state)
      await this.referralStore.createReferral(payload)
      this.referralStore.setShouldHavePatientIdAndInstanceId(true)
      state.patientId = this.referralStore.patientId
      state.signupCode = this.referralStore.signupCode
      this.track(TrackingEvents.SELF_REFERRAL_SUBMITTED)
    } catch (e) {
      this.referralStore.setShouldHavePatientIdAndInstanceId(false)
      this.logException(e, "onSubmitReferralData")
      return false
    }

    return true
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadMaydenLincs> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    // TODO should be moved somewhere else but setting ADSM to active
    // here for now, until insight is done with the consent and randomisation
    this.clinicalStore.setADSMActive(true)

    return {
      instanceID,
      nhsNumber: state.nhsNumber,
      nameFirst: this.getFirstName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: this.getName(state),
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually City and County maybe undefined
        // The backend submission then fails
        // Setting them to "unknown" if undefined
        city: state.city || "unknown",
        county: state.county || "unknown",
        postcode: state.userPostcode?.postcode,
        consentMail: !!state.canSendMailToAddress
      },
      phoneHome: isValidLandline
        ? {
            cc: "",
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // TODO: 👈 the hell is that?
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      problemInOwnWords: state.mainIssue,
      civilStatus: this.getRelationshipStatus(state),
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirth(state),
      disability: this.getDisability(state),
      adhd: state.hasADHD,
      asd: state.hasASD,
      perinatal: this.getPerinatal(state),
      language: this.getLanguage(state),
      speakEnglish: state.ableToCommunicateInEnglish ?? true,
      interpreter: state.requiresInterpreter,
      title: state.nameTitle,
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForce(state),
      sexuality: this.getSexuality(state),
      religion: this.getReligion(state),
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      ltcManagement: this.getLTCManagement(state),
      mobilityProblems: state.hasMobilityIssues,
      consentDataShareGP: state.gpConsent,
      consentDataShare: true,
      consentDataStore: true,
      // When spine it's "odsGP" not "gp" 👇
      gpPhone: removeCountryCodeFromPhoneNumber(state.odsGP?.phone[0] ?? state.gp?.phoneNumber),
      gpCodeNACS: state.odsGP?.id ?? state.gp?.nacsCode,
      gpName: state.odsGP?.name ?? state.gp?.name,
      gpPractice: state.odsGP?.name ?? state.gp?.name,
      alcohol: state.alcohol,
      alcoholFrequency: this.getAlcoholFrequency(state),
      alcoholQuantity: this.getAlcoholQuantity(state),
      medicationWithinDosage: state.isTakingMedication,
      medication: state.isTakingMedication ? [state.medicationInfo!] : undefined,
      currentSupport: state.hasCurrentSupport,
      currentSupportDetailsOther: state.currentSupportDetails || undefined,
      diagnosisMHProfessional: state.hasFormalDiagnosis,
      diagnosisMHProfessionalDetails: state.formalDiagnosisDetails,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      consentResearch: state.consentResearch,
      treatmentExpectation: state.therapyGoal
    }
  }

  getGenderSameAsBirthChoices(state: State): string[] {
    return state.iapt?.referralForm?.sameGenderAsBirth ?? []
  }

  getDisabilities(state: State): string[] {
    return super.getDisabilities(state).filter(d => d !== "No disability")
  }

  getDisabilityPrompt(
    state: State
  ): IInlinePickerSingleSelectPrompt | IInlinePickerMultiSelectPrompt | undefined {
    const disabilities = this.getDisabilities(state)
    if (disabilities.length) {
      return {
        id: this.getPromptId("askDisability"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: [
          {
            body: "No disability",
            value: "NONE",
            backgroundColor: "#8C96D4FF",
            selectIndividually: true
          },
          ...disabilities.map(g => ({ body: g, value: g }))
        ],
        dataPointsName: "askDisability"
      }
    }
  }

  getGender(state: State): GENDER_MAYDEN_LINCS {
    const gender = genders[state.gender!]
    return gender ?? "UNKNOWN"
  }

  getGenderSameAsBirth(state: State): GenderBirthAssigned {
    return genderSameAsBirth[state.sameGenderAsBirth!]
  }

  getDisability(state: State): DISABILITY_MAYDEN_LINCS[] {
    if (!state.disabilities?.length) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }

    return state.disabilities
      ? state.disabilities?.map(disability => disabilities[disability]).filter(Boolean)
      : ["NONE"]
  }

  getPerinatal(state: State): PERINATAL_MAYDEN_LINCS {
    const perinatal = perinatalStatuses[state.perinatalStatus!]
    return perinatal ?? "NONE"
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_LINCS {
    const ethnicity = ethnicities[state.ethnicity!]
    return ethnicity ?? "UNKNOWN"
  }

  getArmedForce(state: State): ARMED_FORCES_MAYDEN_LINCS {
    const armedForces = exArmedForces[state.isExArmedForces!]
    return armedForces ?? "NO"
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_LINCS {
    const sexuality = sexualities[state.sexuality!]
    return sexuality ?? "UNKNOWN"
  }

  getRelationshipStatus(state: State): CIVIL_STATUS_MAYDEN_LINCS {
    const relationshipStatus = maritalStatuses[state.relationshipStatus!]
    return relationshipStatus ?? "NOT_ANSWERED"
  }

  getReligion(state: State): RELIGION_MAYDEN_LINCS {
    const religion = religions[state.religion!]
    return religion ?? "NOT_ANSWERED"
  }

  getLanguage(state: State): LANGUAGE_MAYDEN_LINCS {
    const language = languages[state.primaryLanguage!]
    return language ?? "NOT_ANSWERED"
  }

  getLTC(state: State): LTC_MAYDEN_LINCS[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTCManagement(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      fairly: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcManagement!]
  }

  getAlcoholFrequency(state: State): typeof AF[keyof typeof AF] | undefined {
    const map = {
      [ALCOHOL_FREQUENCIES.MONTHLY]: AF.MONTHLY,
      [ALCOHOL_FREQUENCIES.MONTHLY_2_TO_4]: AF.MONTHLY_2_TO_4,
      [ALCOHOL_FREQUENCIES.WEEKLY_2_TO_3]: AF.WEEKLY_2_TO_3,
      [ALCOHOL_FREQUENCIES.WEEKLY_4]: AF.WEEKLY_4
    }
    return map[state.alcoholFrequency!]
  }

  getAlcoholQuantity(state: State): typeof AQ[keyof typeof AQ] | undefined {
    const map = {
      [ALCOHOL_QUANTITIES._0_2]: AQ._0_2,
      [ALCOHOL_QUANTITIES._3_4]: AQ._3_4,
      [ALCOHOL_QUANTITIES._5_6]: AQ._5_6,
      [ALCOHOL_QUANTITIES._7_9]: AQ._7_9,
      [ALCOHOL_QUANTITIES._10_PLUS]: AQ._10_PLUS
    }
    return map[state.alcoholQuantity!]
  }

  async onHandleEmail(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askPermissionToEmail }
  }

  async onHandlePermissionToSendMailToAddress(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askGPConsent }
  }

  async onHandleGender(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askIdentifyAsSameGenderAtBirth }
  }

  async onHandlePerinatal(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askPrimaryLanguage }
  }

  async onHandlePrimaryLanguage(state: State): Promise<IStepResult<State>> {
    return {
      nextStep: state.primaryLanguage?.match(/english/gi) //
        ? this.askEthnicity
        : this.askAbleToCommunicateInEnglish
    }
  }

  async onHandleEthnicity(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askReligion }
  }

  async onHandleReligion(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askSexuality }
  }

  async onHandleSexuality(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askMobilityIssues }
  }

  async onHandleLongTermMedicalCondition(state: State): Promise<IStepResult<State>> {
    const hasNoMedicalCondition =
      state.longTermMedicalCondition && state.longTermMedicalCondition[0] === "No"
    return {
      nextStep: hasNoMedicalCondition ? this.askDisability : this.askDoesLTCAffectMood
    }
  }

  async onHandleDoesLTCAffectMood(state: State): Promise<IStepResult<State>> {
    if (state.ltcAffectsMood) {
      return { nextStep: this.askHowMuchLTCAffectsMood }
    }
    return { nextStep: this.askDisability }
  }

  async onHandleHowMuchLTCAffectsMood(state: State): Promise<IStepResult<State>> {
    if (state.ltcMoodImpact === "little") {
      return { nextStep: this.askDisability }
    }
    return { nextStep: this.askHowWellYouManageYourLTC }
  }

  async onHandleHowWellYouManageYourLTC(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.askDisability }
  }

  async onHandleHasADHD(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.goToCollectAlcoholConsumption }
  }

  async onHandleCurrentSupport(state: State): Promise<IStepResult<State>> {
    if (state.hasCurrentSupport) {
      return { nextStep: this.askCurrentSupportDetails }
    }
    return { nextStep: this.askFormalDiagnosis }
  }

  getGenders(state: State): string[] {
    return state.iapt?.referralForm?.genders ?? []
  }

  getMaritalStatuses(state: State): string[] {
    return state.iapt?.referralForm?.maritalStatuses ?? []
  }

  getPerinatalStatuses(state: State): string[] {
    return state.iapt?.referralForm?.perinatalStatuses ?? []
  }
}

/* istanbul ignore next */
export default class SelfReferralLincolnshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralLincolnshire
  readonly name: string = "SelfReferralLincolnshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralLincolnshireDialogue.id,
      new SelfReferralLincolnshireScript(),
      state,
      snapshot
    )
  }
}
