/** Setup */
import Persistable from "./Persistable"
import { updateListFromRemote, detectCrisis, Category, setList } from "@limbic/crisis-detection"
import { CrisisDetectionData } from "./CrisisDetectionData"
import invariant from "../utils/invariant"

export default class CrisisDetector extends Persistable {
  static _instance: CrisisDetector

  /** Static Methods */

  static getInstance(): CrisisDetector {
    if (!this._instance) {
      this._instance = new CrisisDetector()
    }
    return this._instance
  }

  /** Instance */

  readonly name: string = "CrisisDetector"

  detectionData: CrisisDetectionData
  skipNextCheck = false

  constructor() {
    super()
    this.detectionData = this.hydrate("detectionData") ?? {}
  }

  disableCrisisDetectionForNextInput(): void {
    this.skipNextCheck = true
  }

  async detectCrisis(input = "", ignoredCategories?: Category[]): Promise<string[] | null> {
    try {
      if (this.skipNextCheck) {
        this.skipNextCheck = false
        return null
      }
      invariant(typeof input === "string", "crisis detection input must be a string")
      const triggerWords = await detectCrisis(input, ignoredCategories && { ignoredCategories })
      return triggerWords.map(i => i.match)
    } catch (e) {
      console.log(`${this.name} - detectCrisis`)
      this.logBreadcrumb("CrisisDetector.detectCrisis()", { input, options: { ignoredCategories } })
      this.logException(e, "detectCrisis")
    }
    return null
  }

  async updateCrisisDetection(): Promise<void> {
    try {
      this.logBreadcrumb("updateCrisisDetection()")
      const storedData = await this.getCrisisDetectionData()

      if (storedData?.version === "1") {
        setList(storedData.list!)
      }

      const list = await updateListFromRemote()
      await this.setCrisisDetectionData({
        version: "1",
        list
      })
    } catch (e) {
      this.logBreadcrumb("updateCrisisDetection", e)
    }
  }

  setCrisisDetectionData(data?: CrisisDetectionData): void {
    if (data?.version === "1") {
      return localStorage.setItem("@Crisis:detectionData", JSON.stringify(data))
    }
  }

  async getCrisisDetectionData(): Promise<CrisisDetectionData | void> {
    const data = await localStorage.getItem("@Crisis:detectionData")
    let result: CrisisDetectionData | void
    if (data) {
      try {
        result = JSON.parse(data)
      } catch (error) {
        this.logException(error, "getCrisisDetectionData() failed")
      }
    }
    return result
  }
}
