import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import "./SidebarEssex.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"
import Bell from "../../../assets/images/bell.svg"

const URL_RESOURCES = {
  crisis: "https://www.therapyforyou.co.uk/crisis",
  waysToGetHelp: "https://www.therapyforyou.co.uk/ways-to-get-help",
  onlineCourses: "https://www.therapyforyou.co.uk/courses/online"
}

export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Refer yourself to {config.organisationName}</h3>
        </div>
        <p>
          We are your local NHS talking therapies (IAPT) service. It's a free service. We are open
          and ready to support you. We offer a range of short-term psychological therapies to
          adults, over the age of 18 in South East Essex and over 16 in North East Essex.
        </p>
        <div>
          More information on the various{" "}
          <a href={URL_RESOURCES.waysToGetHelp} target="_blank" rel="noreferrer">
            ways to get help
          </a>
          . More information on our{" "}
          <a href={URL_RESOURCES.onlineCourses} target="_blank" rel="noreferrer">
            online courses
          </a>
          .
        </div>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
      <div className="lb-sidebar-spacer" />
      <a className="crisis" href={URL_RESOURCES.crisis} target="_blank" rel="noreferrer">
        <article className="lb-sidebar-card">
          <img src={Bell} alt="Crisis bell" />
          Crisis Link
        </article>
      </a>{" "}
    </div>
  )
}

export default observer(Sidebar)
