/* eslint-disable @typescript-eslint/no-empty-function */
import AssessmentIAPTScript, { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import {
  ProblemCategories,
  problemCategoryUserFriendlyNames,
  TrackingEvents
} from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { SurveyScriptState } from "../../createSurveyDialogue"
import { adsmTriage } from "../../../backend/api/adsmTriage"
import { capitaliseFirst } from "../../../utils/string"
import dialoguesRegistry from "../../dialoguesRegistry"
import { DialogueIDs } from "../../DialogueIDs"
import { ADSMStatus } from "../../../models/ADSMStatus"

const PROGRESS_BARS_WITHOUT_ADSM = 4

export interface AssessmentWithADSMScriptState extends AssessmentIAPTScriptState {
  hasSocialPhobia?: boolean
  hasSpecificPhobiaNBI?: boolean
  hasSpecificPhobia?: boolean
  hasPanic?: boolean
  hasModerateWorry?: boolean
  hasSevereWorry?: boolean
  hasHealthAnxiety?: boolean
  hasOCD?: boolean
  hasPTSD?: boolean
  hasAnxietyNOS?: boolean
}

export abstract class AssessmentWithADSMScript<
  State extends AssessmentWithADSMScriptState = AssessmentWithADSMScriptState
> extends AssessmentIAPTScript<State> {
  readonly name: string = "AssessmentWithADSMScriptState"

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    if (!this.referralStore.isMainSpokenLanguageEnglish) {
      this.rootStore.applicationStore.setTotalProgressBars(PROGRESS_BARS_WITHOUT_ADSM)
    } else {
      this.rootStore.applicationStore.setTotalProgressBars(PROGRESS_BARS_WITHOUT_ADSM + 2)
    }
    this.rootStore.applicationStore.setCurrentProgressBar(1)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return { nextStep: this.step1 }
  }

  @step.logState
  async sayBelowCaseness(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.sayBelowCaseness(d)
    return { ...result, nextStep: this.checkADSM }
  }

  @step.logState
  async sayDepression(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.sayDepression(d)
    return { ...result, nextStep: this.checkADSM }
  }

  @step.logState
  async handleDepressionOrAnxiety(
    d: IStepData<State, "lowMood" | "anxiety">
  ): Promise<IStepResult> {
    const result = await super.handleDepressionOrAnxiety(d)
    if (d.response === "lowMood") return { ...result, nextStep: this.checkADSM }
    return result
  }

  @step.logState
  sayThanksForSharing(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Ok, thanks for sharing ${name}`,
      nextStep: this.checkADSM
    }
  }

  @step.logState
  async checkADSM(d: IStepData<State>): Promise<IStepResult> {
    const signupCode = this.referralStore.signupCode
    const patientID = this.referralStore.patientId
    // NOTE: We need to upload datapoints before we do the ADSM study

    if (signupCode || patientID) {
      const age = this.getUserAge(d.state)
      await this.rootStore.dataPointsStore.sendDataPoints(age)
    }

    const dataPointsFailed = this.rootStore.dataPointsStore.dataPointsFailed
    const speakEnglish = this.referralStore.isMainSpokenLanguageEnglish
    let shouldRetroActivelyIncludeScreeningQuestions = false

    if (!speakEnglish) {
      this.track(TrackingEvents.ADSM_DEACTIVATED_NON_ENGLISH_SPEAKING)
      this.clinicalStore.disableADSM()
      shouldRetroActivelyIncludeScreeningQuestions = true
      void this.referralStore.updateReferral({
        adsm: {
          selectedAsParticipant: false,
          deactivatedAsParticipantReason: "non_english_speaking"
        }
      })
    }

    if (dataPointsFailed) {
      this.track(TrackingEvents.ADSM_DEACTIVATED_ERROR)
      this.clinicalStore.disableADSM()
      shouldRetroActivelyIncludeScreeningQuestions = true
      void this.referralStore.updateReferral({
        adsm: {
          selectedAsParticipant: false,
          deactivatedAsParticipantReason: "dataPoints_missing"
        }
      })
    }

    if (!dataPointsFailed && signupCode && speakEnglish) {
      const [newQuestionnaires, status] = await adsmTriage(signupCode)
      if (newQuestionnaires && newQuestionnaires.length) {
        this.rootStore.clinicalStore.setADSMQuestionnaires(newQuestionnaires)
        this.rootStore.clinicalStore.setCurrentADSMQuestionnairePosition(0)
        this.rootStore.applicationStore.setTotalProgressBars(
          PROGRESS_BARS_WITHOUT_ADSM + newQuestionnaires.length
        )
        return {
          body: "Based on the information you have provided so far, we would like to ask you some more questions tailored to your symptoms",
          nextStep: this.handleAdditionalQuestionnaires
        }
      }

      if ([ADSMStatus.RequestFailed, ADSMStatus.NoInternetConnection].includes(status)) {
        this.track(TrackingEvents.ADSM_DEACTIVATED_REQUEST_ERROR)
        this.clinicalStore.disableADSM()
        shouldRetroActivelyIncludeScreeningQuestions = true
        void this.referralStore.updateReferral({
          adsm: {
            selectedAsParticipant: false,
            deactivatedAsParticipantReason: "adsmTriage_request_failed"
          }
        })
      }
    }
    this.rootStore.applicationStore.setTotalProgressBars(0)
    if (shouldRetroActivelyIncludeScreeningQuestions) {
      // When we end up not doing the ADSM flow we need to use
      // the screening questions to determine the clinical groups
      // as if they were answered in the non ADSM assessment script
      this.retroActivelyIncludeScreeningQuestions(d.state)
    }
    this.checkGAD(d.state)
    return { nextStep: this.checkMostAffectingPrimary }
  }

  @step
  handleAdditionalQuestionnaires(d: IStepData<State>): IStepResult {
    const adsmQuestionnaires = this.rootStore.clinicalStore.adsmQuestionnaires
    const currentIndex = this.rootStore.clinicalStore.currentADSMQuestionnairePosition!
    const nextQuestionnaire = adsmQuestionnaires?.[currentIndex]
    if (nextQuestionnaire) {
      this.rootStore.applicationStore.setCurrentProgressBar(
        PROGRESS_BARS_WITHOUT_ADSM + currentIndex + 1
      )
      this.rootStore.applicationStore.setCurrentProgress(0)
      this.rootStore.clinicalStore.setCurrentADSMQuestionnairePosition(currentIndex + 1)

      if (nextQuestionnaire === "PCL5") return { nextStep: this.goToPCL5 }
      if (nextQuestionnaire === "OCI") return { nextStep: this.goToOCI }
      if (nextQuestionnaire === "PDSS") return { nextStep: this.goToPDSS }
      if (nextQuestionnaire === "SPECIFIC_PHOBIA") return { nextStep: this.goToSpecificPhobia }
      if (nextQuestionnaire === "SPIN") return { nextStep: this.goToSPIN }
      if (nextQuestionnaire === "SHAI") return { nextStep: this.goToSHAI18 }
    }

    this.checkGAD(d.state)
    this.rootStore.applicationStore.setTotalProgressBars(0)
    return { nextStep: this.checkMostAffectingPrimary }
  }

  @step.logState
  goToPCL5(d: IStepData<State>): IStepResult {
    const PCL5Dialogue = dialoguesRegistry.get(DialogueIDs.PCL5)
    return {
      nextDialogue: new PCL5Dialogue({ ...d.state }),
      nextStep: this.handlePCL5
    }
  }

  @step.logStateAndResponse
  async handlePCL5(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handlePCL5Responses?.(d.state)
    const result = await this.onHandlePCL5?.(d.state)
    if (result) return result
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  @step.logState
  goToOCI(d: IStepData<State>): IStepResult {
    const OCIDialogue = dialoguesRegistry.get(DialogueIDs.OCI)
    return {
      nextDialogue: new OCIDialogue({ ...d.state }),
      nextStep: this.handleOCI
    }
  }

  @step.logStateAndResponse
  async handleOCI(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleOCIResponses?.(d.state)
    const result = await this.onHandleOCI?.(d.state)
    if (result) return result
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  @step.logState
  goToPDSS(d: IStepData<State>): IStepResult {
    const PDSSDialogue = dialoguesRegistry.get(DialogueIDs.PDSS)
    return {
      nextDialogue: new PDSSDialogue({ ...d.state }),
      nextStep: this.handlePDSS
    }
  }

  @step.logStateAndResponse
  async handlePDSS(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handlePDSSResponses?.(d.state)
    const result = await this.onHandlePDSS?.(d.state)
    if (result) return result
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  @step.logState
  goToSpecificPhobia(d: IStepData<State>): IStepResult {
    const SpecificPhobiaIntro = dialoguesRegistry.get(DialogueIDs.SpecificPhobiaIntro)
    return {
      nextDialogue: new SpecificPhobiaIntro({ ...d.state }),
      nextStep: this.handleSpecificPhobiaQuestionnaire
    }
  }

  @step.logStateAndResponse
  async handleSpecificPhobiaQuestionnaire(
    d: IStepData<State, undefined, SurveyScriptState>
  ): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleSpecificPhobiaResponses?.(d.state)
    const result = await this.onHandleSpecificPhobiaQuestionnaire?.(d.state)
    if (result) return result
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  @step.logState
  goToSPIN(d: IStepData<State>): IStepResult {
    const SpinDialogue = dialoguesRegistry.get(DialogueIDs.SPIN)
    return {
      nextDialogue: new SpinDialogue({ ...d.state }),
      nextStep: this.handleSPIN
    }
  }

  @step.logStateAndResponse
  async handleSPIN(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleSPINResponses?.(d.state)
    const result = await this.onHandleSPIN?.(d.state)
    if (result) return result
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  @step.logState
  goToSHAI18(d: IStepData<State>): IStepResult {
    const SHAI18Dialogue = dialoguesRegistry.get(DialogueIDs.SHAI18)
    return {
      nextDialogue: new SHAI18Dialogue({ ...d.state }),
      nextStep: this.handleSHAI18
    }
  }

  @step.logStateAndResponse
  async handleSHAI18(d: IStepData<State, undefined, SurveyScriptState>): Promise<IStepResult> {
    this.updateState(d.state, d.previousDialogue?.state)
    await this.handleSHAI18Responses?.(d.state)
    const result = await this.onHandleSHAI18?.(d.state)
    if (result) return result
    return { nextStep: this.handleAdditionalQuestionnaires }
  }

  @step.logState
  handleMostAffectingPrimary(d: IStepData<State, ProblemCategories | "none">): IStepResult {
    const primaries = this.rootStore.clinicalStore.primaryProblems.slice()
    const selectedPrimary = d.response === "none" ? undefined : d.response

    if (selectedPrimary) {
      this.referralStore.setCustomField("mostAffectingPrimary", selectedPrimary)
    }
    this.addMostAffectingPrimaryClinicalNote(primaries, selectedPrimary)
    return { nextStep: this.askWhichPrimaryCameFirst }
  }

  @step.logState
  askWhichPrimaryCameFirst(_d: IStepData<State>): IStepResult {
    const primaries = this.rootStore.clinicalStore.primaryProblems //
      .map(name => ({
        body: problemCategoryUserFriendlyNames[name]?.toLocaleLowerCase() as string,
        value: name as string
      }))
    return {
      body: "Which symptoms did you notice first?",
      prompt: {
        id: this.getPromptId("askWhichPrimaryCameFirst"),
        trackResponse: true,
        type: "inlinePicker",
        choices: primaries.concat({ body: "I don't know", value: "none" }).map(primary => ({
          body: capitaliseFirst(primary.body),
          value: primary.value
        })),
        dataPointsName: "askWhichPrimaryCameFirst"
      },
      nextStep: this.handleWhichPrimaryCameFirst
    }
  }

  @step.logState
  handleWhichPrimaryCameFirst(d: IStepData<State, ProblemCategories | "none">): IStepResult {
    const primaries = this.rootStore.clinicalStore.primaryProblems.slice()
    const secondaries = this.rootStore.clinicalStore.secondaryProblems.slice()
    const selectedPrimary = d.response === "none" ? undefined : d.response

    if (selectedPrimary) this.addWhichPrimaryCameFirstClinicalNote(selectedPrimary)
    else this.addWhichPrimaryCameFirstClinicalNote(selectedPrimary)

    const mostAffectingPrimary = this.referralStore.getCustomField("mostAffectingPrimary")

    if ((selectedPrimary || mostAffectingPrimary) && primaries.length > 1) {
      const newPrimary = mostAffectingPrimary || selectedPrimary
      const newPrimaries = [newPrimary]
      const newSecondaries = [...new Set([...secondaries, ...primaries])] //
        .filter(c => c !== newPrimary)
      this.rootStore.clinicalStore.setPrimaries(newPrimaries)
      this.rootStore.clinicalStore.setSecondaries(newSecondaries)
    }

    return { nextStep: this.finishAssessment }
  }

  /** Generic Handlers */

  onCheckOutcomes(state: State): void {
    this.blockUndo(state)
    this.rootStore.applicationStore.setCurrentProgress(1)
  }

  //👇these onHandle methods are overridden to not add
  //  primary problems from any screening questions
  onHandleSocialPhobiaScore(state: State): void {
    const socialPhobiaScore = this.getSocialPhobiaScore(state)!
    if (socialPhobiaScore >= 4) state.hasSocialPhobia = true
  }

  onHandleAnxietyNBI(state: State, hasAnxietyNBI: boolean): void {
    state.hasSpecificPhobiaNBI = hasAnxietyNBI
    state.hasSpecificPhobia = !hasAnxietyNBI
  }

  onHandleAnxietyPhysicalSensations(state: State, hasAnxietyPhysicalSensations: boolean): void {
    if (hasAnxietyPhysicalSensations) state.hasPanic = true
  }

  onHandleWorry(state: State, hasWorry: boolean): void {
    if (hasWorry) {
      const gad7Score = this.getGAD7Total(state)!
      if (gad7Score >= 8 && gad7Score <= 15) state.hasModerateWorry = true
      if (gad7Score > 15) state.hasSevereWorry = true
    }
  }

  onHandleAnxietyExtras(state: State, anxietyExtras: "health" | "ocd" | "ptsd" | "nos"): void {
    if (anxietyExtras === "health") state.hasHealthAnxiety = true
    if (anxietyExtras === "ocd") state.hasOCD = true
    if (anxietyExtras === "ptsd") state.hasPTSD = true
    if (anxietyExtras === "nos") state.hasAnxietyNOS = true
  }

  retroActivelyIncludeScreeningQuestions(state: State): void {
    if (state.hasSocialPhobia) this.clinicalStore.addPrimaryProblem(ProblemCategories.SocialPhobia)
    if (state.hasSpecificPhobiaNBI)
      this.clinicalStore.addPrimaryProblem(ProblemCategories.SpecificPhobiaNBI)
    if (state.hasSpecificPhobia)
      this.clinicalStore.addPrimaryProblem(ProblemCategories.SpecificPhobia)
    if (state.hasPanic) this.clinicalStore.addPrimaryProblem(ProblemCategories.Panic)
    if (state.hasModerateWorry) this.clinicalStore.addPrimaryProblem(ProblemCategories.M_GADWorry)
    if (state.hasSevereWorry) this.clinicalStore.addPrimaryProblem(ProblemCategories.S_GADWorry)
    if (state.hasHealthAnxiety)
      this.clinicalStore.addPrimaryProblem(ProblemCategories.HealthAnxiety)
    if (state.hasOCD) this.clinicalStore.addPrimaryProblem(ProblemCategories.OCD)
    if (state.hasPTSD) this.clinicalStore.addPrimaryProblem(ProblemCategories.PTSD)
    if (state.hasAnxietyNOS) this.clinicalStore.addPrimaryProblem(ProblemCategories.GAD)
  }

  checkGAD(state: State): void {
    const gad7Total = this.getGAD7Total(state)!
    if (gad7Total >= 8 && !this.clinicalStore.primaryProblems.length) {
      this.clinicalStore.addPrimaryProblem(ProblemCategories.GAD)
    }
  }
}
