import moment from "moment"
import { z, ZodSchema } from "zod"
import { getGPServicesByName } from "../../../backend/api/nhs"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { iapts } from "../../../config/iapts"
import { TrackingEvents } from "../../../models/Constants"
import { IGPService } from "../../../models/IGPService"
import ISelectable from "../../../models/ISelectable"
import { DialogueIDs } from "../../DialogueIDs"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState,
  EligibilityCheckWithPDSScriptStateSchema
} from "./EligibilityCheckWithPDSScript"

interface State extends EligibilityCheckWithPDSScriptState {
  requiresUrgentSupport?: boolean
  isAwayFromGP?: boolean
}

export type EligibilityCheckITalkScriptState = State

export const EligibilityCheckITalkScriptStateSchema =
  EligibilityCheckWithPDSScriptStateSchema.extend({
    requiresUrgentSupport: z.boolean().optional()
  })

export class EligibilityCheckITalkScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckITalkScript"

  /** Script Steps */
  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askRequiresUrgentSupport }
  }

  @step.logState
  askRequiresUrgentSupport(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Thanks ${name}. Before we continue, we need to check if you require urgent support`,
        "Are you currently experiencing a crisis and require immediate help because of suicidal thoughts?"
      ],
      prompt: {
        id: this.getPromptId("askRequiresUrgentSupport"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askRequiresUrgentSupport"
      },
      nextStep: this.handleRequiresUrgentSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.requiresUrgentSupport = d.response
  })
  handleRequiresUrgentSupport(d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.askAwayFromGP
    }
  }

  @step
  askAwayFromGP(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently working or studying away from your local GP?",
      prompt: {
        id: this.getPromptId("askAwayFromGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAwayFromGP"
      },
      nextStep: this.handleAwayFromGP
    }
  }

  @step
  async handleAwayFromGP(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.isAwayFromGP = d.response
    if (d.response) {
      return { nextStep: this.askNHSNumber }
    }
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  askNHSNumber(_d: IStepData<State>): IStepResult {
    return {
      body: "What is your NHS number?",
      prompt: {
        id: this.getPromptId("askNHSNumber"),
        trackResponse: false,
        type: "inlinePicker",
        choices: [{ body: "I don't know" }],
        textPrompt: {
          trimAllSpacesOnSubmit: true,
          trimAllSpacesOnValidation: true,
          validation: [/^\d{10}$/],
          validationExplainer: [
            "This is not a valid NHS number",
            "It needs to be a number with 10 digits"
          ],
          forceValue: true
        }
      },
      nextStep: this.handleNHSNumber
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.nhsNumber = d.response === "I don't know" ? undefined : d.response
  })
  handleNHSNumber(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askGPName }
  }

  @step.logState
  askWantMeToReferYouAnyway(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "But that's okay 😊",
        `I can still refer you to ${organisationName}`,
        "Would you like me to do that for you?"
      ],
      prompt: {
        id: this.getPromptId("askWantMeToReferYouAnyway"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No, I want to speak to a human", value: false }
        ]
      },
      nextStep: this.handleWantMeToReferYouAnyway
    }
  }

  @step.logState
  async askSelectGPByName(d: IStepData<State>): Promise<IStepResult> {
    const gpServices = await getGPServicesByName(d.state.gpNameEntered)
    if (!gpServices?.data?.length) {
      return { nextStep: this.sayIDidntFindGP }
    }
    return {
      body: [
        "I've found a few GPs matching the name you typed",
        "Are you registered with any of the following? (Please select)"
      ],
      prompt: {
        id: this.getPromptId("askSelectGPByName"),
        type: "inlinePicker",
        choices: (
          gpServices.data.map(gp => ({
            body: gp.formattedName,
            value: gp
          })) as ISelectable<any>[]
        ) //
          .concat(
            { body: "My GP is not on this list", value: "notListed", backgroundColor: "#EC9CC8" },
            { body: "I'm not sure", value: "notSure", backgroundColor: "#EC9CC8" }
          )
      },
      // ITALK asked to let people refer that state they are away from their GP
      nextStep: d.state.isAwayFromGP
        ? this.handleSelectGPByNameAwayFromGP
        : this.handleSelectGPByName
    }
  }

  // Setting up a new/custom handleSelectGPByName to avoid causing side effects to tge original handleSelectGPByName
  @step.logStateAndResponse
  handleSelectGPByNameAwayFromGP(
    d: IStepData<State, "notListed" | "notSure" | IGPService>
  ): IStepResult {
    const body =
      d.response === "notListed"
        ? "My GP is not on this list"
        : d.response === "notSure"
        ? "I'm not sure"
        : "GP selected"
    this.track(TrackingEvents.SELECT_GP_BY_NAME, { body })
    if (["notListed", "notSure"].includes(d.response as string)) {
      return { body: "Hmmmm...", nextStep: this.sayItsImportantToFindGP }
    }
    this.setGP(d.state, d.response as IGPService)
    // ITALK asked to let people refer that state they are away from their GP
    // Therefore setting the IAPT here manually whatever GP they select
    const iapt = iapts[this.rootStore.configStore.eligibleIAPTIds[0]]
    this.setIAPT(d.state, iapt)
    this.setIAPTSuggestions(d.state, [])
    d.state.iaptManuallySelected = true
    return { nextStep: this.checkUnderAgedForIAPT }
  }

  @step.logState
  selectCustomGP(d: IStepData<State>): IStepResult {
    this.setGP(d.state, this.getCustomGP())
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    this.setIAPT(d.state, eligibleIAPTs[0], true)
    this.setIAPTSuggestions(d.state, [])
    // Skipping selecting an IAPT specifically for this service (only 1 IAPT available)
    return { nextStep: this.checkEligibility }
  }

  @step.logState
  selectCustomGPByNameEntered(d: IStepData<State>): IStepResult {
    const customGP = this.getCustomGP()
    const name = d.state.gpNameEntered ?? "unknown"
    const eligibleIAPTs = this.getEligibleIAPTSByAgeThreshold(d.state)
    this.setGP(d.state, { ...customGP, name, formattedName: name })
    this.setIAPT(d.state, eligibleIAPTs[0], true)
    this.setIAPTSuggestions(d.state, [])
    // Skipping selecting an IAPT specifically for this service (only 1 IAPT available)
    return { nextStep: this.checkEligibility }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        `However ${organisationName} is not an emergency response service`,
        "To get more urgent help, please call NHS 111 and select Option 2",
        "If you need urgent, life threatening medical help please call 999",
        "Alternatively, you can also call Samaritans on 116 123",
        `You can refer yourself back to ${organisationName} when you are no longer in crisis`
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999, NHS 111 option 2 and Samaritans (116 123)"
    )
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  askConfirmBirthday(d: IStepData<State>): IStepResult {
    const dob = moment(d.state.birthday).format("DD MMM YYYY")
    return {
      body: [`Please confirm this is the correct date of birth: <b>${dob}</b>`],
      prompt: {
        id: this.getPromptId("askConfirmBirthday"),
        type: "inlinePicker",
        choices: [
          { body: "Yes, it's correct", value: true },
          { body: "No, I need to re-type it", value: false }
        ]
      },
      nextStep: this.handleConfirmBirthday
    }
  }

  @step.logState
  handleConfirmBirthday(d: IStepData<State>): IStepResult {
    if (d.response) {
      return { nextStep: this.checkAgeThresholds }
    }
    return { nextStep: this.reAskBirthday }
  }

  @step.logState
  reAskBirthday(_d: IStepData<State>): IStepResult {
    return {
      body: "Please enter your date of birth",
      nextStep: this.showPromptForBirthday
    }
  }

  /** Generic Handlers */
  async onHandleBirthday(_state: State): Promise<IStepResult<State> | void> {
    return { nextStep: this.askConfirmBirthday }
  }

  getStateSchema(): ZodSchema | undefined {
    return EligibilityCheckITalkScriptStateSchema
  }
}

/* istanbul ignore next */
export default class EligibilityCheckITalkDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckITalk
  readonly name: string = "EligibilityCheckITalkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckITalkDialogue.id, new EligibilityCheckITalkScript(), state, snapshot)
  }
}
