import {
  CollectPhoneNumberScript,
  CollectPhoneNumberScriptState,
  TEXT_MESSAGES_PROMPT,
  VOICEMAIL_MESSAGES_PROMPT
} from "./CollectPhoneNumberDialogue"
import AdHocDialogue from "../../../../backend/chatbot/AdHocDialogue"
import { DialogueIDs } from "../../../DialogueIDs"
import { IDialogueSnapshot } from "../../../../backend/chatbot/Dialogue"
import { step } from "../../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../../backend/chatbot/models/IStep"
import { isValidMobilePhone } from "../../../../utils/isvalidPhoneNumber"

type State = CollectPhoneNumberScriptState
export type CollectPhoneNumberInsightScriptState = State

export class CollectPhoneNumberInsightScript extends CollectPhoneNumberScript {
  readonly name: string = "CollectPhoneNumberInsightScript"

  /** Script Steps */

  @step.logState
  askCanIContactYouOnPhoneNumber(d: IStepData<State>): IStepResult {
    const isMobilePhone = isValidMobilePhone(d.state.phoneNumber!)
    return {
      body: "And do I have permission to message you or leave a voicemail on that number?",
      prompt: {
        id: this.getPromptId("askCanIContactYouOnPhoneNumber"),
        trackResponse: true,
        type: "checkbox",
        options: isMobilePhone
          ? [TEXT_MESSAGES_PROMPT, VOICEMAIL_MESSAGES_PROMPT]
          : [VOICEMAIL_MESSAGES_PROMPT]
      },
      nextStep: this.handleCanIContactYouOnPhoneNumber
    }
  }
}

export default class CollectPhoneNumberInsightDialogue extends AdHocDialogue<
  State,
  CollectPhoneNumberInsightScript
> {
  static id = DialogueIDs.CollectPhoneNumberInsight
  readonly name: string = "CollectPhoneNumberInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      CollectPhoneNumberInsightDialogue.id,
      new CollectPhoneNumberInsightScript(),
      state,
      snapshot
    )
  }
}
