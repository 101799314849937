import IAppLaunchConfig, { IAppLaunchConfigOptions } from "../models/IAppLaunchConfig"
import { clinicalPaths, complexComorbidPath, undeterminedPath } from "./clinical/clinicalPaths"
import {
  stepsOrder,
  stepsOrder_DEMO_PRIVATE_PROVIDERS,
  stepsOrder_GM_HUB,
  stepsOrder_HEALIX,
  stepsOrder_Insight_Waitlist,
  stepsOrder_KM_CCG,
  stepsOrder_VITALITY,
  stepsOrder_INSIGHT,
  stepsOrder_MHM,
  stepsOrder_LINCS,
  stepsOrder_TRENT_PTS,
  stepsOrder_LIMBIC_WEBSITE
} from "./discussion/stepsOrder"
import {
  dialogueMap_HEALIX,
  dialogueMap_NORFOLK,
  dialogueMap_VITALITY,
  dialoguesMap_Demo,
  dialoguesMap_DemoPrivateProviders,
  dialoguesMap_ESSEX,
  dialoguesMap_GM_HUB,
  dialoguesMap_INSIGHT,
  dialoguesMap_ITALK,
  dialoguesMap_KM_CCG,
  dialoguesMap_LINCOLNSHIRE,
  dialoguesMap_MHM,
  dialoguesMap_NOTTINGHAMSHIRE,
  dialoguesMap_SABP,
  dialoguesMap_TRENT_PTS,
  dialoguesMap_TRENT_PTSDemo,
  dialoguesMap_VHG,
  dialoguesMapINSIGHT_FOLLOWUP,
  dialoguesMapINSIGHT_WAITLIST,
  limbicDialoguesMap
} from "./discussion/dialoguesMap"
import { ccgServices } from "./ccgServices"
import { mergeOverrides } from "./clinical/mergeOverrides"
import {
  clinicalPathsEssex,
  clinicalPathsInsight,
  clinicalPathsLincolnshire,
  clinicalPathsNorfolk,
  clinicalPathsNottinghamshire,
  clinicalPathsTrent
} from "./clinical/overrides"
import { clinicalPathsVHG } from "./clinical/overrides/vhg"
import { clinicalPathsSABP } from "./clinical/overrides/sabp"
import { CCGCode } from "../models/ICCG"
import { IAPTIDs } from "../models/IIAPTService"
import { iaptGPMapInsight, iaptGPMapInsightDEMO } from "./iaptMaps/insight"
import { iaptGPMapLincolnshire } from "./iaptMaps/lincolnshire"
import { iaptGPMapEssex, iaptGPMapEssexDemo } from "./iaptMaps/essex"
import { iaptGPMapSABP } from "./iaptMaps/sabp"
import { iaptGPMapNorfolk } from "./iaptMaps/norfolk"
import {
  insightCustomClinicalPaths,
  lincolnshireCustomClinicalPaths,
  trentCustomClinicalPaths
} from "./clinical/overrides/custom"
import { iaptGPMapItalk } from "./iaptMaps/italk"
import { iaptGPMapMHM } from "./iaptMaps/mhm"

const demoPhoneNumbers =
  "Demo Clinic London: +44 1234 567890\n" +
  "Demo Clinic Kent: +44 1234 567890\n" +
  "Demo Clinic Calderdale: +44 1234 567890"

const vhgPhoneNumbers = ""

const vhgTerms =
  "Notts Talking Therapies [Terms & Conditions](https://notts-talk.co.uk/terms-conditions/)\n" +
  "Notts Talking Therapies [Privacy Notice](https://notts-talk.co.uk/privacy-policy/)"

const insightPhoneNumbers =
  "NHS Mental Health (Everyturn NHS Talking Therapies)\n\n" +
  "Kent Talking Therapies: +44 3005 555555\n" +
  "Peterborough Talking Therapies: +44 3005 550888\n" +
  "Nottingham and Nottinghamshire Talking Therapies: +44 3005 555582\n" +
  "Bassetlaw Talking Therapies: +44 3003 000033\n" +
  "Derbyshire Talking Therapies: +44 3005 555582\n" +
  "Medway Talking Therapies: +44 3000 293000\n" +
  "Wirral Talking Together: +44 1516 491859"

const insightCrisisPhoneNumbers =
  "Local NHS Crisis Contacts\n\n" +
  "Bassetlaw: +44 8081 963779\n" +
  "Derby and Derbyshire: +44 8000 280077\n" +
  "Kend and Medway (Adults): +44 8007 839111\n" +
  "Kend and Medway (Under 18s): +44 8000 113474\n" +
  "Nottingham and Nottinghamshire: +44 8081 963779\n" +
  "Peterborough: 111 & press option 2 for the first response service\n" +
  "Wirral: +44 8001 456485"

const insightTerms =
  "Everyturn Mental Health [Privacy Policy](https://www.insightiapt.org/privacy-policy/)"

const italkPhoneNumbers = "0238 038 3920"

const italkCrisisPhoneNumbers = "0238 038 3920"

const italkTerms = "italk IAPT [Privacy Policy](https://www.italk.org.uk/privacy-policy/)"

const norfolkTerms =
  "Wellbeing Norfolk [Privacy Policy](https://www.wellbeingnands.co.uk/norfolk/privacy-policy/)"

const lincolnshirePhoneNumbers =
  "NHS Mental Health (Lincolnshire)\n\n" +
  "Lincolnshire Talking Therapies (Boston): +44 0303 123 4000\n" +
  "Lincolnshire Talking Therapies (Gainsborough): +44 0303 123 4000\n" +
  "Lincolnshire Talking Therapies (Grantham): +44 0303 123 4000\n" +
  "Lincolnshire Talking Therapies (Lincoln): +44 0303 123 4000\n" +
  "Lincolnshire Talking Therapies (Louth): +44 0303 123 4000\n" +
  "Lincolnshire Talking Therapies (Skegness): +44 0303 123 4000\n" +
  "Lincolnshire Talking Therapies (Sleaford): +44 0303 123 4000\n" +
  "Lincolnshire Talking Therapies (Spalding): +44 0303 123 4000\n" +
  "Lincolnshire Talking Therapies (Stamford): +44 0303 123 4000"

const lincolnshireCrisisPhoneNumbers =
  "Local NHS Crisis Contacts\n\n" + "Lincolnshire Mental Health Helpline: 0800 001 4331" // eslint-disable-line

const lincolnshireTerms =
  "Lincolnshire Talking Therapies [Terms of Service](https://www.lpft.nhs.uk/terms-use)\n" +
  "Lincolnshire Talking Therapies [Website Privacy Notice](https://www.lpft.nhs.uk/privacy-policy)"

const essexTerms =
  "Therapy For You [Privacy Policy](https://www.therapyforyou.co.uk/privacy-policy)"

const essexOrganisationPhoneNumbers =
  "Therapy For You (South East Essex): 01268 739128\n" +
  "Therapy For You (North East Essex): 01206 334001"

const kentMedwayPhoneNumbers = ""
const kentMedwayTerms = ""
const kentMedwayWellbeingHubEMails =
  process.env.REACT_APP_BACKEND_ENV === "production" && process.env.REACT_APP_WELLBEINGHUB_EMAILS
    ? process.env.REACT_APP_WELLBEINGHUB_EMAILS?.split(" ")
    : [
        "john+kmhub@limbic.ai",
        "andreas+kmhub@limbic.ai",
        "chaellene+kmhub@limbic.ai",
        "zsuzsa+kmhub@limbic.ai"
      ]

const gmHubEmails =
  process.env.REACT_APP_BACKEND_ENV === "production"
    ? ["GM.help@nhs.net"]
    : ["andreas+gmhub@limbic.ai", "ben+gmhub@limbic.ai", "zsuzsa+gmhub@limbic.ai"]

const nottinghamshirePhoneNumbers = "Let's Talk Wellbeing\n\nNottinghamshire: +44 3003 002200"
const nottinghamshireTerms =
  "Let's Talk Wellbeing [Privacy Policy](https://www.nottinghamshirehealthcare.nhs.uk/privacy-policy)"

const trentPhoneNumbers =
  "NHS Mental Health (Trent PTS)\n\n" +
  "Trent PTS - Ropewalk: +44 1158 965950\n" +
  "Trent PTS - Woodlands Lodge: +44 1332 265659\n" +
  "Trent PTS - Saltergate: +44 1246 385752"
const trentTerms = "Trent PTS [Privacy Policy](https://www.trentpts.co.uk/privacy-statement)"

const sabpPhoneNumbers = ""
const sabpPhoneTerms =
  "Mind Matters [Terms of Service](https://www.mindmattersnhs.co.uk/about/Confidentiality)"

const gmHubTerms =
  "Greater Manchester Resilience Hub [Privacy Policy](https://www.penninecare.nhs.uk/yourinformation)"

const MHMTerms = "NHS Talking Therapies [Privacy Policy](https://www.mhm.org.uk/privacy-policy)"

const MHMOrganisationPhoneNumbers =
  "Warrington: [01925 401 720](tel:01925401720)\nNorthumberland: [0300 3030 700](tel:03003030700)\nSefton: [0300 303 2708](tel:03003032708)"

const MHMCrisisPhoneNumbers =
  "Sefton: [0800 145 6570](tel:08001456570)\nWarrington: [0800 051 1508](tel:08000511508)\nNorthumberland: [0303 123 1146](tel:03031231146)"

export const MHMCrisisPhoneMapped = {
  [IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON]: "[0800 051 1508](tel:08000511508)",
  [IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON]: "[0800 145 6570](tel:08001456570)",
  [IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND]: "[0303 123 1146](tel:03031231146)"
}

export const MHMCrisisPhoneMappedText = {
  [IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON]:
    "If you need urgent mental health support, please call our 24/7 freephone crisis line on: [0800 051 1508](tel:08000511508)",
  [IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON]:
    "For urgent mental health support, please call our 24/7 freephone helpline: [0800 145 6570](tel:08001456570)",
  [IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND]:
    "If you need urgent mental health support, please call our 24/7 crisis team on: [0303 123 1146](tel:03031231146)"
}

export const apiKeysMap: Record<IAppLaunchConfig["API_KEY"], IAppLaunchConfigOptions> = {
  LIMBIC_WEBSITE: {
    title: "Limbic Assistant",
    serviceName: "Limbic Assistant",
    organisationName: "Limbic",
    organisationTerms: "",
    organisationPhoneNumbers: "",
    organisationGenericPhoneNumber: "",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "E396C1FF",
    fullscreen: true,
    faqLink: "https://limbic.ai/faq",
    discussionStepsOrder: stepsOrder_LIMBIC_WEBSITE,
    dialoguesMap: limbicDialoguesMap,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo: "",
    keepingSafeTitle: ""
  },
  DEMO: {
    title: "Limbic Self Referral Assistant",
    serviceName: "Demo Service",
    organisationName: "Demo Organisation",
    organisationTerms: "",
    organisationPhoneNumbers: demoPhoneNumbers,
    organisationGenericPhoneNumber: "0123 4567 890",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [IAPTIDs.DEMO],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_Demo,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-keeping-safe-logo.png",
    keepingSafeTitle: "Demo"
  },
  DEMO_PRIVATE_PROVIDERS: {
    backendInstanceID: "DEMO_MAIN",
    title: "Limbic Self Referral Assistant",
    serviceName: "",
    organisationName: "Private Therapy Provider",
    organisationTerms: "",
    organisationPhoneNumbers: "0123 4567 890",
    organisationGenericPhoneNumber: "0123 4567 890",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_DEMO_PRIVATE_PROVIDERS,
    dialoguesMap: dialoguesMap_DemoPrivateProviders,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-keeping-safe-logo.png",
    keepingSafeTitle: "Demo"
  },
  VHG_NOTTS: {
    title: "Limbic Referral Assistant",
    serviceName: "Nottingham and Nottinghamshire Talking Therapies",
    organisationName: "Nottingham and Nottinghamshire Talking Therapies",
    organisationTerms: vhgTerms,
    organisationPhoneNumbers: vhgPhoneNumbers,
    organisationGenericPhoneNumber: "0333 0153 494",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/nhs-logo.png",
    userMessageBackground: "#005EB8",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [IAPTIDs.VHG_NOTTS],
    iaptCCGMap: { [CCGCode.NOTTINGHAM_NOTTINGHAMSHIRE]: IAPTIDs.VHG_NOTTS },
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_VHG,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsVHG),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsVHG)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsVHG)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-keeping-safe-logo.png",
    keepingSafeTitle: "Vita Health",
    freetextLimit: 500
  },
  VHG_NOTTS_FULLSCREEN: {
    title: "Limbic Referral Assistant",
    serviceName: "Nottingham and Nottinghamshire Talking Therapies",
    organisationName: "Nottingham and Nottinghamshire Talking Therapies",
    organisationTerms: vhgTerms,
    organisationPhoneNumbers: vhgPhoneNumbers,
    organisationGenericPhoneNumber: "0333 0153 494",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/nhs-logo.png",
    userMessageBackground: "#005EB8",
    fullscreen: true,
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-notts.png"],
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [IAPTIDs.VHG_NOTTS],
    iaptCCGMap: { [CCGCode.NOTTINGHAM_NOTTINGHAMSHIRE]: IAPTIDs.VHG_NOTTS },
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_VHG,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsVHG),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsVHG)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsVHG)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vhg/vhg-keeping-safe-logo.png",
    keepingSafeTitle: "Vita Health",
    freetextLimit: 500
  },
  ESSEX: {
    title: "Therapy For You Referral Assistant",
    serviceName: "Therapy For You",
    organisationName: "Therapy For You",
    organisationTerms: essexTerms,
    organisationPhoneNumbers: essexOrganisationPhoneNumbers,
    organisationGenericPhoneNumber: essexOrganisationPhoneNumbers,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#c00b74",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX, IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX],
    iaptGPMap: iaptGPMapEssex,
    iaptCCGMap: {
      [CCGCode.NE_ESSEX]: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
      [CCGCode.CASTLE_POINT_ROCHFORD]: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
      [CCGCode.SOUTHEND]: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX
    },
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_ESSEX,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsEssex),
    customClinicalPaths: [],
    complexComorbidPath,
    undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/essex/keeping-safe-logo.png",
    keepingSafeTitle: "Keeping yourself safe - Therapy For You",
    freetextLimit: 500
  },
  ESSEX_FULLSCREEN: {
    title: "Therapy For You Referral Assistant",
    serviceName: "Therapy For You",
    organisationName: "Therapy For You",
    organisationTerms: essexTerms,
    organisationPhoneNumbers: essexOrganisationPhoneNumbers,
    organisationGenericPhoneNumber: essexOrganisationPhoneNumbers,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#c00b74",
    fullscreen: true,
    formLink: "https://www.therapyforyou.co.uk/ieso",
    directReferral: true,
    eligibleIAPTIds: [IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX, IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX],
    iaptGPMap: iaptGPMapEssex,
    iaptCCGMap: {
      [CCGCode.NE_ESSEX]: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX,
      [CCGCode.CASTLE_POINT_ROCHFORD]: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX,
      [CCGCode.SOUTHEND]: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX
    },
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_ESSEX,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsEssex),
    customClinicalPaths: [],
    complexComorbidPath,
    undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/essex/keeping-safe-logo.png",
    keepingSafeTitle: "Keeping yourself safe - Therapy For You",
    freetextLimit: 500
  },
  ESSEX_DEMO: {
    title: "Therapy For You Referral Assistant",
    serviceName: "Therapy For You",
    organisationName: "Therapy For You",
    backendInstanceID: "ESSEX_E2E_DEMO",
    organisationTerms: essexTerms,
    organisationPhoneNumbers: essexOrganisationPhoneNumbers,
    organisationGenericPhoneNumber: essexOrganisationPhoneNumbers,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#c00b74",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO, IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO],
    iaptGPMap: iaptGPMapEssexDemo,
    iaptCCGMap: {
      [CCGCode.NE_ESSEX]: IAPTIDs.THERAPY_FOR_YOU_NE_ESSEX_DEMO,
      [CCGCode.CASTLE_POINT_ROCHFORD]: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO,
      [CCGCode.SOUTHEND]: IAPTIDs.THERAPY_FOR_YOU_SE_ESSEX_DEMO
    },
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_ESSEX,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsEssex),
    customClinicalPaths: [],
    complexComorbidPath,
    undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/essex/keeping-safe-logo.png",
    keepingSafeTitle: "Keeping yourself safe - Therapy For You",
    freetextLimit: 500
  },
  INSIGHT_TALKING_THERAPIES: {
    title: "Everyturn Referral Assistant",
    serviceName: "Everyturn Mental Health",
    organisationName: "Everyturn Mental Health",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    userMessageBackground: "#533767",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.INSIGHT_BASSETLAW,
      IAPTIDs.INSIGHT_NOTTS,
      IAPTIDs.INSIGHT_MEDWAY,
      IAPTIDs.INSIGHT_PETTERSBOROUGH,
      IAPTIDs.INSIGHT_WIRRAL,
      IAPTIDs.INSIGHT_KENT,
      IAPTIDs.INSIGHT_DERBY
    ],
    iaptCCGMap: {
      [CCGCode.BASSETLAW]: IAPTIDs.INSIGHT_BASSETLAW,
      [CCGCode.NOTTINGHAM_NOTTINGHAMSHIRE]: IAPTIDs.INSIGHT_NOTTS,
      [CCGCode.CAMBRIDGESHIRE_PETERBOROUGH]: IAPTIDs.INSIGHT_PETTERSBOROUGH,
      [CCGCode.WIRRAL]: IAPTIDs.INSIGHT_WIRRAL,
      [CCGCode.DERBY_DERBYSHIRE]: IAPTIDs.INSIGHT_DERBY
    },
    iaptGPMap: iaptGPMapInsight,
    discussionStepsOrder: stepsOrder_INSIGHT,
    dialoguesMap: dialoguesMap_INSIGHT,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    keepingSafeTitle: "Keeping yourself safe - Everyturn Mental Health",
    adsmEnabled: true
  },
  INSIGHT_TALKING_THERAPIES_FULLSCREEN: {
    title: "Everyturn Referral Assistant",
    serviceName: "Everyturn Mental Health",
    organisationName: "Everyturn Mental Health",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    userMessageBackground: "#533767",
    fullscreen: true,
    formLink: "https://www.insightiapt.org/self-refer/",
    directReferral: true,
    iaptIcons: [],
    eligibleIAPTIds: [
      IAPTIDs.INSIGHT_BASSETLAW,
      IAPTIDs.INSIGHT_NOTTS,
      IAPTIDs.INSIGHT_MEDWAY,
      IAPTIDs.INSIGHT_PETTERSBOROUGH,
      IAPTIDs.INSIGHT_WIRRAL,
      IAPTIDs.INSIGHT_KENT,
      IAPTIDs.INSIGHT_DERBY
    ],
    iaptCCGMap: {
      [CCGCode.BASSETLAW]: IAPTIDs.INSIGHT_BASSETLAW,
      [CCGCode.NOTTINGHAM_NOTTINGHAMSHIRE]: IAPTIDs.INSIGHT_NOTTS,
      [CCGCode.CAMBRIDGESHIRE_PETERBOROUGH]: IAPTIDs.INSIGHT_PETTERSBOROUGH,
      [CCGCode.WIRRAL]: IAPTIDs.INSIGHT_WIRRAL,
      [CCGCode.DERBY_DERBYSHIRE]: IAPTIDs.INSIGHT_DERBY
    },
    iaptGPMap: iaptGPMapInsight,
    discussionStepsOrder: stepsOrder_INSIGHT,
    dialoguesMap: dialoguesMap_INSIGHT,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    keepingSafeTitle: "Keeping yourself safe - Everyturn Mental Health",
    adsmEnabled: true
  },
  NORFOLK: {
    title: "Wellbeing Norfolk Referral Assistant",
    serviceName: "Wellbeing",
    organisationName: "Wellbeing",
    organisationTerms: norfolkTerms,
    organisationPhoneNumbers: "0300 123 1503",
    organisationGenericPhoneNumber: "0300 123 1503",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00AAD5",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.NORFOLK_WAVENEY],
    iaptCCGMap: {
      [CCGCode.NORFOLK_WAVENEY]: IAPTIDs.NORFOLK_WAVENEY
    },
    iaptGPMap: iaptGPMapNorfolk,
    discussionStepsOrder: stepsOrder,
    // NOTE: Norfolk & Suffolk will be 2 different bots (i.e. 2 different Keys)
    // because they will be shared on two different websites, therefore they
    // should share the dialoguesMap, but have different eligibleIAPTIds
    dialoguesMap: dialogueMap_NORFOLK,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsNorfolk),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsNorfolk)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsNorfolk)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/norfolk/logo-keeping-safe.png",
    keepingSafeTitle: "Keeping yourself safe - Wellbeing Norfolk",
    freetextLimit: 500
  },
  NORFOLK_FULLSCREEN: {
    title: "Wellbeing Norfolk Referral Assistant",
    serviceName: "Norfolk and Waveney Wellbeing",
    organisationName: "Wellbeing Norfolk",
    organisationTerms: norfolkTerms,
    organisationPhoneNumbers: "0300 123 1503",
    organisationGenericPhoneNumber: "0300 123 1503",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00AAD5",
    fullscreen: true,
    formLink: "https://gateway.mayden.co.uk/referral-v2/4f947c6a-1997-4e27-8d89-ecc0905bc7cc",
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/norfolk/logo.png"],
    eligibleIAPTIds: [IAPTIDs.NORFOLK_WAVENEY],
    iaptCCGMap: {
      [CCGCode.NORFOLK_WAVENEY]: IAPTIDs.NORFOLK_WAVENEY
    },
    iaptGPMap: iaptGPMapNorfolk,
    discussionStepsOrder: stepsOrder,
    // NOTE: Norfolk & Suffolk will be 2 different bots (i.e. 2 different Keys)
    // because they will be shared on two different websites, therefore they
    // should share the dialoguesMap, but have different eligibleIAPTIds
    dialoguesMap: dialogueMap_NORFOLK,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsNorfolk),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsNorfolk)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsNorfolk)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/norfolk/logo-keeping-safe.png",
    keepingSafeTitle: "Keeping yourself safe - Wellbeing Norfolk",
    freetextLimit: 500
  },
  INSIGHT_WAITLIST: {
    title: "Everyturn Referral Assistant",
    serviceName: "Everyturn Mental Health",
    backendInstanceID: "INSIGHT_WAITLIST",
    organisationName: "Everyturn Mental Health",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    userMessageBackground: "#533767",
    fullscreen: false,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_Insight_Waitlist,
    dialoguesMap: dialoguesMapINSIGHT_WAITLIST,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    keepingSafeTitle: "Keeping yourself safe - Everyturn Mental Health"
  },
  INSIGHT_FOLLOWUP: {
    title: "Everyturn Referral Assistant",
    serviceName: "Everyturn Mental Health",
    organisationName: "Everyturn Mental Health",
    backendInstanceID: "INSIGHT_FOLLOWUP",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    userMessageBackground: "#533767",
    fullscreen: false,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_Insight_Waitlist,
    dialoguesMap: dialoguesMapINSIGHT_FOLLOWUP,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    keepingSafeTitle: "Keeping yourself safe - Everyturn Mental Health"
  },
  INSIGHT_TALKING_THERAPIES_DEMO: {
    title: "Everyturn Referral Assistant",
    serviceName: "Everyturn Mental Health",
    organisationName: "Everyturn Mental Health",
    backendInstanceID: "INSIGHT_E2E_DEMO",
    organisationTerms: insightTerms,
    organisationPhoneNumbers: insightPhoneNumbers,
    crisisPhoneNumbers: insightCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0300 123 4502",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    userMessageBackground: "#533767",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.INSIGHT_BASSETLAW_DEMO,
      IAPTIDs.INSIGHT_NOTTS_DEMO,
      IAPTIDs.INSIGHT_MEDWAY_DEMO,
      IAPTIDs.INSIGHT_PETTERSBOROUGH_DEMO,
      IAPTIDs.INSIGHT_WIRRAL_DEMO,
      IAPTIDs.INSIGHT_KENT_DEMO,
      IAPTIDs.INSIGHT_DERBY_DEMO
    ],
    iaptCCGMap: {
      [CCGCode.BASSETLAW]: IAPTIDs.INSIGHT_BASSETLAW_DEMO,
      [CCGCode.NOTTINGHAM_NOTTINGHAMSHIRE]: IAPTIDs.INSIGHT_NOTTS_DEMO,
      [CCGCode.CAMBRIDGESHIRE_PETERBOROUGH]: IAPTIDs.INSIGHT_PETTERSBOROUGH_DEMO,
      [CCGCode.WIRRAL]: IAPTIDs.INSIGHT_WIRRAL_DEMO,
      [CCGCode.DERBY_DERBYSHIRE]: IAPTIDs.INSIGHT_DERBY_DEMO
    },
    iaptGPMap: iaptGPMapInsightDEMO,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_INSIGHT,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsInsight),
    customClinicalPaths: insightCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsInsight)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsInsight)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/insight/everyturn-logo.svg",
    keepingSafeTitle: "Keeping yourself safe - Everyturn Mental Health"
  },
  ITALK: {
    title: "italk Referral Assistant",
    serviceName: "italk NHS talking therapies service",
    organisationName: "italk",
    backendInstanceID: "ITALK_MAIN",
    organisationTerms: italkTerms,
    organisationPhoneNumbers: italkPhoneNumbers,
    crisisPhoneNumbers: italkCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0238 038 3920",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/italk/keeping-safe-logo.png",
    userMessageBackground: "#429221",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.ITALK],
    iaptGPMap: iaptGPMapItalk,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_ITALK,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/italk/keeping-safe-logo.png",
    keepingSafeTitle: "Keeping yourself safe - italk",
    freetextLimit: 500
  },
  ITALK_FULLSCREEN: {
    title: "italk Referral Assistant",
    serviceName: "italk NHS talking therapies service",
    organisationName: "italk",
    backendInstanceID: "ITALK_MAIN",
    organisationTerms: italkTerms,
    organisationPhoneNumbers: italkPhoneNumbers,
    crisisPhoneNumbers: italkCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0238 038 3920",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/italk/keeping-safe-logo.png",
    userMessageBackground: "#429221",
    fullscreen: true,
    directReferral: true,
    formLink: "https://www.italk.org.uk/self-refer/",
    eligibleIAPTIds: [IAPTIDs.ITALK],
    iaptGPMap: iaptGPMapItalk,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_ITALK,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/italk/keeping-safe-logo.png",
    keepingSafeTitle: "Keeping yourself safe - italk"
  },
  LINCOLNSHIRE: {
    backendInstanceID: "LINCS_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "Lincolnshire Talking Therapies",
    organisationName: "Lincolnshire Talking Therapies",
    organisationTerms: lincolnshireTerms,
    organisationPhoneNumbers: lincolnshirePhoneNumbers,
    crisisPhoneNumbers: lincolnshireCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0303 123 4000",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#01AEF0",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.LINCS_GAINSBOROUGH,
      IAPTIDs.LINCS_LOUTH,
      IAPTIDs.LINCS_LINCOLN,
      IAPTIDs.LINCS_SKEGNESS,
      IAPTIDs.LINCS_SLEAFORD,
      IAPTIDs.LINCS_BOSTON,
      IAPTIDs.LINCS_GRANTHAM,
      IAPTIDs.LINCS_SPALDING,
      IAPTIDs.LINCS_STAMFORD
    ],
    iaptGPMap: iaptGPMapLincolnshire,
    discussionStepsOrder: stepsOrder_LINCS,
    dialoguesMap: dialoguesMap_LINCOLNSHIRE,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsLincolnshire),
    customClinicalPaths: lincolnshireCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsLincolnshire)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsLincolnshire)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/lincolnshire/logo.png",
    keepingSafeTitle: "Lincolnshire Talking Therapies",
    freetextLimit: 500,
    adsmEnabled: true
  },
  LINCOLNSHIRE_FULLSCREEN: {
    backendInstanceID: "LINCS_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "Lincolnshire Talking Therapies",
    organisationName: "Lincolnshire Talking Therapies",
    organisationTerms: lincolnshireTerms,
    organisationPhoneNumbers: lincolnshirePhoneNumbers,
    crisisPhoneNumbers: lincolnshireCrisisPhoneNumbers,
    organisationGenericPhoneNumber: "0303 123 4000",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#01AEF0",
    fullscreen: true,
    formLink: "https://gateway.mayden.co.uk/referral-v2/c2ce2fcb-3586-4e64-8405-deac6ce440b1",
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/lincolnshire/logo.png"],
    eligibleIAPTIds: [
      IAPTIDs.LINCS_GAINSBOROUGH,
      IAPTIDs.LINCS_LOUTH,
      IAPTIDs.LINCS_LINCOLN,
      IAPTIDs.LINCS_SKEGNESS,
      IAPTIDs.LINCS_SLEAFORD,
      IAPTIDs.LINCS_BOSTON,
      IAPTIDs.LINCS_GRANTHAM,
      IAPTIDs.LINCS_SPALDING,
      IAPTIDs.LINCS_STAMFORD
    ],
    iaptGPMap: iaptGPMapLincolnshire,
    discussionStepsOrder: stepsOrder_LINCS,
    dialoguesMap: dialoguesMap_LINCOLNSHIRE,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsLincolnshire),
    customClinicalPaths: lincolnshireCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsLincolnshire)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsLincolnshire)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/lincolnshire/logo.png",
    keepingSafeTitle: "Lincolnshire Talking Therapies",
    freetextLimit: 500,
    adsmEnabled: true
  },
  KENT_MEDWAY_CCG: {
    title: "Limbic Wellbeing Assistant",
    serviceName: "NHS talk therapy support",
    organisationName: "Talking Wellness",
    organisationTerms: kentMedwayTerms,
    organisationPhoneNumbers: kentMedwayPhoneNumbers,
    organisationGenericPhoneNumber: "1634 917110",
    ccgService: ccgServices.KentMedway,
    skipOnlineReferral: true,
    wellbeingHubEmails: kentMedwayWellbeingHubEMails,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.DOVER_KM,
      IAPTIDs.FAVERSHAM_KM,
      IAPTIDs.INSIGHT_KENT,
      IAPTIDs.WITH_YOU_KM
    ],
    discussionStepsOrder: stepsOrder_KM_CCG,
    dialoguesMap: dialoguesMap_KM_CCG,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath,
    undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/kmpt/kmpt-keeping-safe-logo.jpg",
    keepingSafeTitle: "Talking Wellness"
  },
  KENT_MEDWAY_CCG_FULLSCREEN: {
    title: "Limbic Wellbeing Assistant",
    serviceName: "NHS talk therapy support",
    organisationName: "Talking Wellness",
    organisationTerms: kentMedwayTerms,
    organisationPhoneNumbers: kentMedwayPhoneNumbers,
    organisationGenericPhoneNumber: "1634 917110",
    ccgService: ccgServices.KentMedway,
    wellbeingHubEmails: kentMedwayWellbeingHubEMails,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: true,
    directReferral: true,
    iaptIcons: [],
    eligibleIAPTIds: [
      IAPTIDs.DOVER_KM,
      IAPTIDs.FAVERSHAM_KM,
      IAPTIDs.INSIGHT_KENT,
      IAPTIDs.WITH_YOU_KM
    ],
    discussionStepsOrder: stepsOrder_KM_CCG,
    dialoguesMap: dialoguesMap_KM_CCG,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath,
    undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/kmpt/kmpt-keeping-safe-logo.jpg",
    keepingSafeTitle: "Talking Wellness"
  },
  NOTTINGHAMSHIRE: {
    title: "Limbic Referral Assistant",
    serviceName: "Let's Talk Wellbeing",
    organisationName: "Let's Talk Wellbeing",
    organisationTerms: nottinghamshireTerms,
    organisationPhoneNumbers: nottinghamshirePhoneNumbers,
    organisationGenericPhoneNumber: "01332 265659",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.NOTTINGHAMSHIRE_NOTTS, IAPTIDs.NOTTINGHAMSHIRE_NOTTS_CC],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_NOTTINGHAMSHIRE,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsNottinghamshire),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsNottinghamshire)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsNottinghamshire)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/nottinghamshire/notts-logo.png",
    keepingSafeTitle: "Keeping Yourself Safe: Let's Talk - Wellbeing",
    freetextLimit: 500
  },
  NOTTINGHAMSHIRE_FULLSCREEN: {
    title: "Limbic Referral Assistant",
    serviceName: "Let's Talk Wellbeing",
    organisationName: "Let's Talk Wellbeing",
    organisationTerms: nottinghamshireTerms,
    organisationPhoneNumbers: nottinghamshirePhoneNumbers,
    organisationGenericPhoneNumber: "01332 265659",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: true,
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/nottinghamshire/notts-logo.png"],
    eligibleIAPTIds: [IAPTIDs.NOTTINGHAMSHIRE_NOTTS, IAPTIDs.NOTTINGHAMSHIRE_NOTTS_CC],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_NOTTINGHAMSHIRE,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsNottinghamshire),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsNottinghamshire)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsNottinghamshire)[0],
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/nottinghamshire/notts-logo.png",
    keepingSafeTitle: "Keeping Yourself Safe: Let's Talk - Wellbeing",
    freetextLimit: 500
  },
  TRENT_PTS: {
    title: "Limbic Referral Assistant",
    serviceName: "Trent PTS",
    organisationName: "Trent PTS",
    organisationTerms: trentTerms,
    organisationPhoneNumbers: trentPhoneNumbers,
    organisationGenericPhoneNumber: "01332 265659",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.TRENT_NOTTS,
      IAPTIDs.TRENT_DERBY_WOODLANDS,
      IAPTIDs.TRENT_DERBY_SALTERGATE
    ],
    discussionStepsOrder: stepsOrder_TRENT_PTS,
    dialoguesMap: dialoguesMap_TRENT_PTS,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsTrent),
    customClinicalPaths: trentCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsTrent)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsTrent)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent-logo.png",
    keepingSafeTitle: "Trent PTS",
    adsmEnabled: true
  },
  TRENT_PTS_FULLSCREEN: {
    title: "Limbic Referral Assistant",
    serviceName: "Trent PTS",
    organisationName: "Trent PTS",
    organisationTerms: trentTerms,
    organisationPhoneNumbers: trentPhoneNumbers,
    organisationGenericPhoneNumber: "01332 265659",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent.png",
    userMessageBackground: "#00A7CF",
    fullscreen: true,
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/nhs_logos-trent.png"],
    eligibleIAPTIds: [
      IAPTIDs.TRENT_NOTTS,
      IAPTIDs.TRENT_DERBY_WOODLANDS,
      IAPTIDs.TRENT_DERBY_SALTERGATE
    ],
    discussionStepsOrder: stepsOrder_TRENT_PTS,
    dialoguesMap: dialoguesMap_TRENT_PTS,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsTrent),
    customClinicalPaths: trentCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsTrent)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsTrent)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent-logo.png",
    keepingSafeTitle: "Trent PTS",
    adsmEnabled: true
  },
  TRENT_PTS_DEMO: {
    title: "Limbic Referral Assistant",
    serviceName: "Trent PTS",
    organisationName: "Trent PTS",
    backendInstanceID: "TRENT_E2E_DEMO",
    organisationTerms: trentTerms,
    organisationPhoneNumbers: trentPhoneNumbers,
    organisationGenericPhoneNumber: "01332 265659",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    eligibleIAPTIds: [
      IAPTIDs.TRENT_NOTTS_DEMO,
      IAPTIDs.TRENT_DERBY_WOODLANDS_DEMO,
      IAPTIDs.TRENT_DERBY_SALTERGATE_DEMO
    ],
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_TRENT_PTSDemo,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsTrent),
    customClinicalPaths: trentCustomClinicalPaths,
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsTrent)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsTrent)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/trent-pts/trent-logo.png",
    keepingSafeTitle: "Trent PTS"
  },
  SABP: {
    title: "Limbic Referral Assistant",
    serviceName: "Mind Matters Surrey NHS",
    organisationName: "Mind Matters Surrey NHS",
    organisationTerms: sabpPhoneTerms,
    organisationPhoneNumbers: sabpPhoneNumbers,
    organisationGenericPhoneNumber: "0300 330 5450",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#6A6983",
    fullscreen: false,
    eligibleIAPTIds: [IAPTIDs.MIND_MATTERS_SAB],
    iaptGPMap: iaptGPMapSABP,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_SABP,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsSABP),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsSABP)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsSABP)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/sabp/mind-matters-logo.png",
    keepingSafeTitle: "Mind Matters Surrey NHS Stay safe message",
    freetextLimit: 500
  },
  SABP_FULLSCREEN: {
    title: "Limbic Referral Assistant",
    serviceName: "Mind Matters",
    organisationName: "Mind Matters",
    organisationTerms: sabpPhoneTerms,
    organisationPhoneNumbers: sabpPhoneNumbers,
    organisationGenericPhoneNumber: "0300 330 5450",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#6A6983",
    fullscreen: true,
    formLink: "https://gateway.mayden.co.uk/referral-v2/cb5a6c97-0eea-48eb-9d31-aa0621081ada",
    directReferral: true,
    iaptIcons: [],
    eligibleIAPTIds: [IAPTIDs.MIND_MATTERS_SAB],
    iaptGPMap: iaptGPMapSABP,
    discussionStepsOrder: stepsOrder,
    dialoguesMap: dialoguesMap_SABP,
    defaultClinicalPaths: mergeOverrides(clinicalPaths, clinicalPathsSABP),
    customClinicalPaths: [],
    complexComorbidPath: mergeOverrides([complexComorbidPath], clinicalPathsSABP)[0],
    undeterminedPath: mergeOverrides([undeterminedPath], clinicalPathsSABP)[0],
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/sabp/mind-matters-logo.png",
    keepingSafeTitle: "Mind Matters Surrey",
    freetextLimit: 500
  },
  GREATER_MANCHESTER_HUB: {
    backendInstanceID: "GMHUB_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "Greater Manchester Resilience service",
    organisationName: "Greater Manchester Resilience Hub",
    organisationTerms: gmHubTerms,
    organisationPhoneNumbers: "0333 009 5071",
    organisationGenericPhoneNumber: "0333 009 5071",
    emails: gmHubEmails,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00B3A8",
    fullscreen: false,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_GM_HUB,
    dialoguesMap: dialoguesMap_GM_HUB,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo: "",
    keepingSafeTitle: "Greater Manchester Resilience Keeping Safe Message",
    ageThreshold: 16
  },
  GREATER_MANCHESTER_HUB_FULLSCREEN: {
    backendInstanceID: "GMHUB_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "Greater Manchester Resilience service",
    organisationName: "Greater Manchester Resilience Hub",
    organisationTerms: gmHubTerms,
    organisationPhoneNumbers: "0333 009 5071",
    organisationGenericPhoneNumber: "0333 009 5071",
    emails: gmHubEmails,
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00B3A8",
    fullscreen: true,
    directReferral: true,
    iaptIcons: ["https://limbic-web-bot.s3.eu-west-2.amazonaws.com/gmhub/logo.png"],
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_GM_HUB,
    dialoguesMap: dialoguesMap_GM_HUB,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo: "",
    keepingSafeTitle: "Greater Manchester Resilience Keeping Safe Message",
    ageThreshold: 16
  },
  HEALIX: {
    backendInstanceID: "HEALIX_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "Mind Right service",
    organisationName: undefined,
    organisationTerms: "",
    organisationPhoneNumbers: "0330 094 8733",
    organisationGenericPhoneNumber: "0330 094 8733",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#00A7CF",
    fullscreen: false,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_HEALIX,
    dialoguesMap: dialogueMap_HEALIX,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo:
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/healix/keeping-safe-logo.png",
    keepingSafeTitle: "Healix"
  },
  VITALITY: {
    backendInstanceID: "VITALITY_MAIN",
    title: "Limbic Referral Assistant",
    serviceName: "IPRS Health service",
    organisationName: "IPRS Health",
    organisationTerms: "",
    organisationPhoneNumbers: "0345 070 3040",
    organisationGenericPhoneNumber: "0345 070 3040",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/logo-lb.png",
    userMessageBackground: "#6a6983",
    fullscreen: true,
    iaptIcons: [
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/keeping-safe-logo.png",
      "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/limbic-logo.png"
    ],
    enableParams: true,
    directReferral: true,
    eligibleIAPTIds: [],
    discussionStepsOrder: stepsOrder_VITALITY,
    dialoguesMap: dialogueMap_VITALITY,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/vitality/logo-leaflet.png",
    keepingSafeTitle: "IPRS Health",
    enableSOS: true
  },
  MENTAL_HEALTH_MATTERS: {
    title: "Limbic Self Referral Assistant",
    serviceName: "NHS Talking Therapies",
    organisationName: "NHS Talking Therapies",
    organisationTerms: MHMTerms,
    organisationPhoneNumbers: MHMOrganisationPhoneNumbers,
    crisisPhoneNumbers: MHMCrisisPhoneNumbers,
    iaptCCGMap: {
      [CCGCode.WARRINGTON]: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
      [CCGCode.S_SEFTON]: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
      [CCGCode.NORTHUMBERLAND]: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND
    },
    iaptGPMap: iaptGPMapMHM,
    organisationGenericPhoneNumber: "0123 4567 890",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#b40378",
    fullscreen: false,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [
      IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
      IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
      IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON
    ],
    discussionStepsOrder: stepsOrder_MHM,
    dialoguesMap: dialoguesMap_MHM,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    keepingSafeTitle: "NHS Talking Therapies",
    adsmEnabled: true
  },
  MENTAL_HEALTH_MATTERS_FULLSCREEN: {
    title: "Limbic Self Referral Assistant",
    serviceName: "NHS Talking Therapies",
    organisationName: "NHS Talking Therapies",
    organisationTerms: MHMTerms,
    organisationPhoneNumbers: MHMOrganisationPhoneNumbers,
    crisisPhoneNumbers: MHMCrisisPhoneNumbers,
    iaptCCGMap: {
      [CCGCode.WARRINGTON]: IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
      [CCGCode.S_SEFTON]: IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON,
      [CCGCode.NORTHUMBERLAND]: IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND
    },
    iaptGPMap: iaptGPMapMHM,
    organisationGenericPhoneNumber: "0123 4567 890",
    logo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    userMessageBackground: "#b40378",
    fullscreen: true,
    directReferral: true,
    faqLink: "https://limbic.ai/faq",
    eligibleIAPTIds: [
      IAPTIDs.MENTAL_HEALTH_MATTERS_WARRINGTON,
      IAPTIDs.MENTAL_HEALTH_MATTERS_NORTHUMBERLAND,
      IAPTIDs.MENTAL_HEALTH_MATTERS_SEFTON
    ],
    discussionStepsOrder: stepsOrder_MHM,
    dialoguesMap: dialoguesMap_MHM,
    defaultClinicalPaths: clinicalPaths,
    customClinicalPaths: [],
    complexComorbidPath: complexComorbidPath,
    undeterminedPath: undeterminedPath,
    keepingSafeLogo: "https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/logo.png",
    keepingSafeTitle: "NHS Talking Therapies",
    adsmEnabled: true
  }
}

// TODO: replace these when the insight ones are no longer used anywhere
apiKeysMap.EVERYTURN = { ...apiKeysMap.INSIGHT_TALKING_THERAPIES }
apiKeysMap.EVERYTURN_FULLSCREEN = { ...apiKeysMap.INSIGHT_TALKING_THERAPIES_FULLSCREEN }
apiKeysMap.EVERYTURN_WAITLIST = { ...apiKeysMap.INSIGHT_WAITLIST }
apiKeysMap.EVERYTURN_FOLLOWUP = { ...apiKeysMap.INSIGHT_FOLLOWUP }
apiKeysMap.EVERYTURN_DEMO = { ...apiKeysMap.INSIGHT_TALKING_THERAPIES_DEMO }
