import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostSABPScriptState = State

export class UnderAgeSignpostSABPScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostSABPScript"

  /** Script Steps */

  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    const threshold = this.getIAPTServiceAgeThreshold(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, there's a special type of mental health support for people aged ${threshold} or under living in Surrey`,
        `${iaptName} do not currently provide this`,
        "To find the best option for you, you can visit Mindworks Surrey [here](https://www.mindworks-surrey.org/)",
        "It's the new emotional wellbeing and mental health service for children and young people in Surrey",
        "Or you can talk to your doctor and they will help you"
      ],
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: "okay" },
          { body: "Thanks", value: "thanks" }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.closeDiscussion
    }
  }
}

export default class UnderAgeSignpostSABPDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderageSignpostSABP
  readonly name: string = "UnderAgeSignpostSABPDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostSABPDialogue.id, new UnderAgeSignpostSABPScript(), state, snapshot)
  }
}
