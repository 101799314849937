import GoodbyeScript, { GoodbyeScriptState } from "./GoodbyeScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type {
  IStepData,
  IStepResult,
  StepResultBodyType
} from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import getQRCodeURL from "../../../utils/getQRCodeURL"
import { BaseScriptStateSchema } from "../../BaseScript"
import { z, ZodSchema } from "zod"
import { AppointmentSkippedReason } from "../bookAppointment/BookAppointmentITalkDialogue"

interface WindowWithLimbicNameSpace extends Window {
  DEEP_LINK: string
}

declare let window: WindowWithLimbicNameSpace

window.DEEP_LINK = String(process.env.REACT_APP_DEEP_LINK ?? "enabled")

const deepLinkEnabled = window.DEEP_LINK !== "disabled"

interface State extends GoodbyeScriptState {
  appointment?: string
  appointmentSkippedReason?: string
  assessmentPreference?: "telephone" | "digital"
}

export type GoodbyeITalkScriptState = State

export const GoodbyeITalkStateSchema = BaseScriptStateSchema.extend({
  appointment: z.string().optional(),
  appointmentSkippedReason: z.string().optional(),
  assessmentPreference: z.union([z.literal("telephone"), z.literal("digital")]).optional()
})

export class GoodbyeITalkScript extends GoodbyeScript {
  readonly name: string = "GoodbyeITalkScript"

  /** Script Steps */

  @step.logState
  sayDynamicLink(d: IStepData<State>): IStepResult {
    if (!deepLinkEnabled || !d.state.careSignupURL) {
      return { nextStep: this.sayFinalWords }
    }
    const qrCodeURL = getQRCodeURL(d.state.careSignupURL)
    return {
      body: [
        "I'm looking forward to going on this journey with you",
        "We can continue our conversations in my mobile app",
        `If you're already on your mobile device, just click [here](${d.state.careSignupURL}) to download it`,
        "If you're on a desktop computer, you can scan the QR code below",
        {
          type: "imageAttachment",
          image: qrCodeURL
        }
      ],
      prompt: {
        id: this.getPromptId("sayDynamicLink"),
        type: "inlinePicker",
        choices: [{ body: "Done", value: true }]
      },
      nextStep: this.sayFinalWords
    }
  }

  /** Generic Handlers */

  async onHandleRecapMessage(
    _state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    if (!body.length) {
      return { nextStep: this.askFeedback }
    }

    const choices: any[] = [{ body: "Okay" }, recapMessage && { body: "I understand" }] //
      .filter(Boolean)

    return {
      body,
      prompt: {
        id: this.getPromptId("recap"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.askFeedback
    }
  }

  // Changing order of recap messages
  async getRecapMessage(state: State): Promise<string | void> {
    const recaps: string[] = []
    const referredYouMessage = await this.getReferredYouMessage(state)
    referredYouMessage && recaps.push(referredYouMessage)

    const highRiskContactMessage = await this.getHighRiskContactMessage(state)
    highRiskContactMessage && recaps.push(highRiskContactMessage)

    const moderateRiskContactMessage = await this.getModerateRiskContactMessage(state)
    moderateRiskContactMessage && recaps.push(moderateRiskContactMessage)

    const adminWillCallMessage = await this.getAdminWillCallMessage(state)
    adminWillCallMessage && recaps.push(adminWillCallMessage)

    const treatmentMessage = await this.getTreatmentMessage(state)
    treatmentMessage && recaps.push(treatmentMessage)

    if (state.appointment) {
      const appointmentMessage = await this.getAppointmentMessage(state)
      appointmentMessage && recaps.push(appointmentMessage)
    }

    if (state.appointmentSkippedReason) {
      const appointmentSkippedMessage = await this.getAppointmentSkippedMessage(state)
      appointmentSkippedMessage && recaps.push(appointmentSkippedMessage)
    }

    const formattedRecap = recaps.length > 1 ? recaps.map((i, idx) => `${idx + 1}. ${i}`) : recaps
    const recapString = formattedRecap.join("\n")

    if (recapString?.length) {
      return `Just to recap:\n\n${recapString}`
    }
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const serviceName = this.rootStore.configStore.serviceName
      return state.isIdleSubmitted //
        ? `It looks like there hasn’t been any activity for some time so I've referred you to ${serviceName}`
        : `I've referred you to ${serviceName}`
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted && state.needsAssessmentCall) {
      return "A member of the team should be in contact with you regarding your referral within 14 days"
    }
  }

  async getHighRiskContactMessage(_state: State): Promise<string | void> {
    const serviceName = this.rootStore.configStore.serviceName
    if (this.clinicalStore.isHighRisk) {
      return `Please remember, ${serviceName} is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111 and select Option 2. You can also contact the Samaritans on 116123 to talk through how you are feeling`
    }
  }

  async getModerateRiskContactMessage(_state: State): Promise<string | void> {
    const serviceName = this.rootStore.configStore.serviceName

    if (this.clinicalStore.isModerateRisk) {
      return `Please remember, ${serviceName} is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111 and select Option 2. You can also contact the Samaritans on 116123 to talk through how you are feeling`
    }
  }

  async getAppointmentMessage(state: State): Promise<string | void> {
    return `The following appointment was booked:\n${state.appointment}`
  }

  async getAppointmentSkippedMessage(state: State): Promise<string | void> {
    const serviceName = this.rootStore.configStore.serviceName
    const organisationPhoneNumbers = this.rootStore.configStore.organisationPhoneNumbers

    const assessmentSkippedMessage =
      state.appointmentSkippedReason === AppointmentSkippedReason.FAILURE
        ? "I was not able to book an appointment for you"
        : `Since ${state.appointmentSkippedReason}, I did not book you in`

    const assessmentSkippedContact =
      state.assessmentPreference === "telephone"
        ? `\n\n${serviceName} will contact you in the next 14 days`
        : `\n\nPlease give a call to ${serviceName} on the following numbers: ${organisationPhoneNumbers}`

    return assessmentSkippedMessage + assessmentSkippedContact
  }

  getStateSchema(): ZodSchema | undefined {
    return GoodbyeITalkStateSchema
  }
}

/* istanbul ignore next */
export default class GoodbyeITalkDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeITalk
  readonly name: string = "GoodbyeITalkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeITalkDialogue.id, new GoodbyeITalkScript(), state, snapshot)
  }
}
