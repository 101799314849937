import React from "react"
import "./DeviceLabel.scss"
import {
  Caution,
  DateManufacturer,
  Eifu,
  Lot,
  Manufacturer,
  Ref,
  Udi,
  Ukca
} from "../../../assets/images/deviceLabels/index"
import * as htmlToImage from "html-to-image"
import packageJson from "../../../../package.json"

export default function DeviceLabel(): JSX.Element {
  const openDeviceLabel = async (): Promise<void> => {
    const node = document.getElementById("device-label")
    if (!node) return
    const base64 = await htmlToImage.toJpeg(node, { quality: 0.95 })
    const newTab = window.open("", "_blank")
    if (!newTab) return
    newTab.document.write(
      `<!DOCTYPE html><head><title>Limbic Access - Device Label</title></head><body style="display:flex;justify-content:center;padding:30px;"><img alt="device label" src="${base64}" width="auto" height="auto"></body></html>`
    )
    newTab.document.close()
  }
  const version = packageJson.version

  return (
    <div
      id="device-label"
      className="lb-device-label-box"
      onClick={openDeviceLabel}
      title="Device label"
    >
      <div className="lb-device-label-container">
        <img src={Manufacturer} alt="Manufacturer" />
        <div className="lb-label">
          <p id="label-text">Limbic, Kemp House 160 City Road, LONDON EC1V 2NX</p>
        </div>
      </div>
      <div className="lb-device-label-container">
        <img src={DateManufacturer} alt="date manufacturer" />
        <div className="lb-label">
          <p id="label-text">2022-08</p>
        </div>
      </div>
      <div className="lb-device-label-container">
        <img src={Lot} alt="Lot" />
        <div className="lb-label">
          <p id="label-text">V{version?.split(".")[0]}</p>
        </div>
      </div>
      <div className="lb-device-label-container">
        <img src={Ref} alt="Ref" />
        <div className="lb-label">
          <p id="label-text">Limbic Access</p>
        </div>
      </div>
      <div className="lb-device-label-container">
        <img src={Udi} alt="Udi" />
        <div className="lb-label">
          <p id="label-text">(01)05065011383011(8012){version}</p>
        </div>
      </div>
      <div className="lb-device-label-container">
        <img src={Eifu} className="lb-logo-big" alt="Eifu" />
      </div>
      <div className="lb-device-label-container">
        <img src={Ukca} className="lb-logo-big" alt="Ukca" />
      </div>
      <div className="lb-device-label-container">
        <img src={Caution} alt="caution" />
      </div>
    </div>
  )
}
