import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import GoodbyeScript from "./GoodbyeScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { TrackingEvents } from "../../../models/Constants"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { StepResultBodyType } from "../../../backend/chatbot/models/IStep"

type State = GoodbyeScriptState

export class GoodbyeCCGScript extends GoodbyeScript {
  readonly name: string = "GoodbyeCCGScript"

  /** Script Steps */

  @step.logState
  askWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    if (this.clinicalStore.isCrisis) {
      this.track(TrackingEvents.SKIP_WHERE_DID_YOU_HEAR)
      return { nextStep: this.sayIHopeIHelped }
    }
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: `Before we close, where did you hear about ${organisationName}?`,
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "text",
        cancelIsEmptySubmit: true
      },
      nextStep: this.handleWhereDidYouHearAboutUsWithCrisis
    }
  }

  /** Generic Handler */

  async onHandleRecapMessage(
    state: State,
    body: StepResultBodyType[],
    recapMessage: string | void
  ): Promise<IStepResult | void> {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        ...body,
        `If you want to contact ${organisationName} about your referral please contact kmpt.wellbeingwebsite@nhs.net`
      ],
      prompt: {
        id: this.getPromptId("onHandleRecapMessage"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.askWhereDidYouHearAboutUs
    }
  }

  async getHighRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isHighRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return `Because you identified as being in crisis, I have alerted ${iaptName} just to be safe`
    }
  }

  async getModerateRiskContactMessage(state: State): Promise<string | void> {
    if (this.clinicalStore.isModerateRisk) {
      const organisationName = this.rootStore.configStore.organisationName
      const iaptName = this.getIAPTName(state) || organisationName
      return `Because you identified as being in crisis, I have alerted ${iaptName} just to be safe`
    }
  }
}

/* istanbul ignore next */
export default class GoodbyeCCGDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeCCG
  readonly name: string = "GoodbyeCCGDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeCCGDialogue.id, new GoodbyeCCGScript(), state, snapshot)
  }
}
