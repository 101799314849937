type VHGEmails = {
  "vhg-derby": Email
  "vhg-bb": Email
  vhg: Email
  "vhg-bnssg": Email
  "vhg-kent": Email
  "vhg-llr": Email
  "vhg-westessex": Email
  "vhg-notts": Email
}

type Email = {
  emails: string[]
  riskEmails: string[]
  riskEmailsCC?: string[]
  riskEmailsBCC?: string[]
}

export const VHGEmailsProduction: VHGEmails = {
  "vhg-derby": {
    emails: ["limbic.appt.derby@vhg.co.uk"],
    riskEmails: ["iapt.derbyshire@vhg.co.uk"],
    riskEmailsCC: [],
    riskEmailsBCC: []
  },
  "vhg-bb": {
    emails: ["limbic.appt.bb@vhg.co.uk"],
    riskEmails: ["admin@vhg.co.uk"],
    riskEmailsCC: [],
    riskEmailsBCC: []
  },
  vhg: {
    emails: ["limbic.appt.calderdale@vhg.co.uk"],
    riskEmails: ["iapt.calderdale@vhg.co.uk"],
    riskEmailsCC: [],
    riskEmailsBCC: []
  },
  "vhg-bnssg": {
    emails: ["limbic.appt.bnssg@vhg.co.uk"],
    riskEmails: ["bnssg.iapt.admin@vhg.co.uk"],
    riskEmailsCC: [],
    riskEmailsBCC: []
  },
  "vhg-kent": {
    emails: ["limbic.appt.dgss@vhg.co.uk"],
    riskEmails: ["iapt.kentmedway@vhg.co.uk"],
    riskEmailsCC: [],
    riskEmailsBCC: []
  },
  "vhg-llr": {
    emails: ["limbic.appt.llr@vhg.co.uk"],
    riskEmails: ["llr.iapt.admin@vhg.co.uk"],
    riskEmailsCC: [],
    riskEmailsBCC: []
  },
  "vhg-westessex": {
    emails: ["limbic.appt.we@vhg.co.uk"],
    riskEmails: ["we.iapt.admin@vhg.co.uk"],
    riskEmailsCC: [],
    riskEmailsBCC: []
  },
  "vhg-notts": {
    emails: ["notts.iapt.admin@notts-talk.co.uk"],
    riskEmails: ["rrt@notts-talk.co.uk"],
    riskEmailsCC: [],
    riskEmailsBCC: []
  }
}

export const VHGEmailsStaging: VHGEmails = {
  "vhg-derby": {
    emails: ["limbic.project@vhg.co.uk"],
    riskEmails: ["limbic.project@vhg.co.uk"],
    riskEmailsCC: ["limbic.project+duty@vhg.co.uk"],
    riskEmailsBCC: []
  },
  "vhg-bb": {
    emails: ["limbic.project@vhg.co.uk"],
    riskEmails: ["limbic.project@vhg.co.uk"],
    riskEmailsCC: ["limbic.project+duty@vhg.co.uk"],
    riskEmailsBCC: []
  },
  vhg: {
    emails: ["limbic.project@vhg.co.uk"],
    riskEmails: ["limbic.project@vhg.co.uk"],
    riskEmailsCC: ["limbic.project+duty@vhg.co.uk"],
    riskEmailsBCC: []
  },
  "vhg-bnssg": {
    emails: ["limbic.project@vhg.co.uk"],
    riskEmails: ["limbic.project@vhg.co.uk"],
    riskEmailsCC: ["limbic.project+duty@vhg.co.uk"],
    riskEmailsBCC: []
  },
  "vhg-kent": {
    emails: ["limbic.project@vhg.co.uk"],
    riskEmails: ["limbic.project@vhg.co.uk"],
    riskEmailsCC: ["limbic.project+duty@vhg.co.uk"],
    riskEmailsBCC: []
  },
  "vhg-llr": {
    emails: ["limbic.project@vhg.co.uk"],
    riskEmails: ["limbic.project@vhg.co.uk"],
    riskEmailsCC: ["limbic.project+duty@vhg.co.uk"],
    riskEmailsBCC: []
  },
  "vhg-westessex": {
    emails: ["limbic.project@vhg.co.uk"],
    riskEmails: ["limbic.project@vhg.co.uk"],
    riskEmailsCC: ["limbic.project+duty@vhg.co.uk"],
    riskEmailsBCC: []
  },
  "vhg-notts": {
    emails: ["limbic.project@vhg.co.uk"],
    riskEmails: ["limbic.project@vhg.co.uk"],
    riskEmailsCC: ["limbic.project+duty@vhg.co.uk"],
    riskEmailsBCC: []
  }
}
