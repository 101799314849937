import { z, ZodSchema } from "zod"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import { ClinicalGroups, DiscussionSteps } from "../../../models/Constants"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IInlinePickerSingleSelectPrompt } from "../../../backend/chatbot/models/IPrompt"

interface State extends BaseScriptState {
  userAborted?: boolean
}

export type SelfReferralPitchDemoPrivateProvidersScriptState = State

export const SelfReferralPitchDemoPrivateProvidersScriptStateSchema = BaseScriptStateSchema.extend({
  userAborted: z.boolean().optional()
})

export class SelfReferralPitchDemoPrivateProvidersScript extends BaseScript<State> {
  readonly name: string = "SelfReferralPitchDemoPrivateProvidersScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    const isBelowCaseness = this.getIsBelowCaseness()
    return {
      nextStep: isBelowCaseness //
        ? this.sayBelowCaseness
        : this.askWantToRefer
    }
  }

  @step.logState
  sayBelowCaseness(_d: IStepData<State>): IStepResult {
    return {
      body: "It might still be good to speak to someone about your problems",
      nextStep: this.askWantToRefer
    }
  }

  @step.logState
  askWantToRefer(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "If you'd like, I can book you in for a chat with a mental health professional...",
        "Shall I do that now?"
      ],
      prompt: {
        id: this.getPromptId("askWantToRefer"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes please", value: true },
          { body: "Not right now", value: false },
          { body: "Help me decide", value: "decide" }
        ]
      } as IInlinePickerSingleSelectPrompt,
      nextStep: this.handleWantToRefer
    }
  }

  @step.logState
  handleWantToRefer(d: IStepData<State, boolean | "decide">): IStepResult {
    if (d.response === "decide") return { body: "Sure, no problem", nextStep: this.explainReferral }
    if (d.response) return { body: "Wonderful!", nextStep: this.end }
    d.state.userAborted = true // TODO
    return { nextStep: this.suggestWellbeing }
  }

  @step.logState
  explainReferral(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `So I'll be booking you in to speak to someone from ${organisationName}`,
        "This is a talking therapy service that helps people who are experiencing similar issues to you"
      ],
      prompt: {
        id: this.getPromptId("explainReferral"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Tell me more", value: false },
          { body: "I'm convinced - let's book a call", value: true }
        ]
      },
      nextStep: this.handleExplainReferral
    }
  }

  @step.logState
  handleExplainReferral(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.end }
    }
    return { nextStep: this.tellMeMore1 }
  }

  @step.logState
  tellMeMore1(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "Talking therapies can help you deal with negative thoughts and feelings, and make positive changes",
        `${organisationName} offers a variety of therapeutic options, and will select one that fits best to your needs. This can be more effective than medication for adults experiencing similar issues to you`
      ],
      prompt: {
        id: this.getPromptId("tellMeMore1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Tell me more", value: false },
          { body: "I'm convinced - let's book a call", value: true }
        ]
      },
      nextStep: this.handleTellMeMore1
    }
  }

  @step.logState
  handleTellMeMore1(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.end }
    }
    return { nextStep: this.tellMeMore2 }
  }

  @step.logState
  tellMeMore2(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Given you've just been through the toughest year in history, I really think talking to a professional might help you...",
        "... even if it's just a little",
        "It can't hurt, right?"
      ],
      prompt: {
        id: this.getPromptId("tellMeMore2"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Ok, book me in for a call", value: true },
          { body: "No thanks - I don't want to book a call", value: false }
        ]
      },
      nextStep: this.handleTellMeMore2
    }
  }

  @step.logState
  handleTellMeMore2(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.end }
    }
    d.state.userAborted = true // TODO
    return { nextStep: this.suggestWellbeing }
  }

  @step.logState
  suggestWellbeing(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `Okay ${name}, it's your decision`,
        "If you don't want to speak to a health professional, I think you would really benefit from some online materials about wellbeing"
      ],
      prompt: {
        id: this.getPromptId("sayDeclined"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: false },
          { body: "Actually, book me in for a chat with a mental health professional", value: true }
        ]
      },
      nextStep: this.handleWellbeing
    }
  }

  @step.logState
  handleWellbeing(d: IStepData<State, boolean>): IStepResult {
    if (d.response) return { body: "No problem!", nextStep: this.end }
    return { nextStep: this.goToWellBeing }
  }

  @step.logState
  goToWellBeing(d: IStepData<State>): IStepResult {
    const WellBeingDialogue = this.discussionStore.getDialogueClass(DiscussionSteps.WellbeingHub)
    return {
      nextDialogue: WellBeingDialogue //
        ? new WellBeingDialogue({ ...d.state })
        : undefined,
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralPitchDemoPrivateProvidersScriptStateSchema
  }

  getIsBelowCaseness(): boolean {
    const clinicalPath = this.rootStore.clinicalStore.clinicalPath
    return [
      ClinicalGroups.Undetermined,
      ClinicalGroups.BelowCaseness,
      ClinicalGroups.BelowCaseness_MS_FI,
      ClinicalGroups.BelowCaseness_S_FI
    ].includes(clinicalPath?.clinicalGroup as any)
  }
}

/* istanbul ignore next */
export default class SelfReferralPitchDemoPrivateProvidersDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralPitchDemoPrivateProviders
  readonly name: string = "SelfReferralPitchDemoPrivateProvidersDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralPitchDemoPrivateProvidersDialogue.id,
      new SelfReferralPitchDemoPrivateProvidersScript(),
      state,
      snapshot
    )
  }
}
