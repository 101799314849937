import React from "react"
import IAppLaunchConfig from "../../../models/IAppLaunchConfig"
import SidebarEssex from "./SidebarEssex"
import SidebarInsight from "./SidebarInsight"
import SidebarSABP from "./SidebarSABP"
import SidebarNottinghamshire from "./SidebarNottinghamshire"
import SidebarGMHub from "./SidebarGMHub"
import SidebarLincolnshire from "./SidebarLincolnshire"
import SidebarVitality from "./SidebarVitality"
import SidebarNorfolk from "./SidebarNorfolk"
import { Props } from "./Sidebar"
import SidebarITalk from "./SidebarITalk"
import SidebarMHM from "./SidebarMHM"
import SidebarLimbic from "./SidebarLimbic"

export const sidebarsMap: Record<IAppLaunchConfig["API_KEY"], React.FC<Props>> = {
  ESSEX_FULLSCREEN: SidebarEssex,
  INSIGHT_TALKING_THERAPIES_FULLSCREEN: SidebarInsight,
  ITALK_FULLSCREEN: SidebarITalk,
  SABP_FULLSCREEN: SidebarSABP,
  NOTTINGHAMSHIRE_FULLSCREEN: SidebarNottinghamshire,
  GREATER_MANCHESTER_HUB_FULLSCREEN: SidebarGMHub,
  NORFOLK_FULLSCREEN: SidebarNorfolk,
  LINCOLNSHIRE_FULLSCREEN: SidebarLincolnshire,
  VITALITY: SidebarVitality,
  MENTAL_HEALTH_MATTERS: SidebarMHM,
  LIMBIC_WEBSITE: SidebarLimbic
}
