import { step } from "../../../backend/chatbot/decorators/step"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { UnderAgeSignpostScript, UnderAgeSignpostScriptState } from "./UnderAgeSignpostDialogue"
import { DialogueIDs } from "../../DialogueIDs"

type State = UnderAgeSignpostScriptState
export type UnderAgeSignpostEssexScriptState = State

export class UnderAgeSignpostEssexScript extends UnderAgeSignpostScript {
  readonly name: string = "UnderAgeSignpostEssexScript"

  /** Script Steps */
  @step.logState
  sayNotEligible(d: IStepData<State>): IStepResult {
    return {
      body: [
        "If you are under the age of 18 or are the family member of someone under the age of 18 who is in crisis, please call North East London NHS Foundation Trust's Southend, Essex and Thurrock Children's and Adolescent Mental Health Services (SET CAMHS), 0800 953 0222 or email via [SET-CAMHS.referrals@nelft.nhs.uk](SET-CAMHS.referrals@nelft.nhs.uk)",
        "Or you can talk to your doctor and they can help you",
        "If you need urgent help or out of hours help please call Mental Health Direct 0800 995 1000"
      ],
      prompt: {
        id: this.getPromptId("sayNotEligible"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Okay", value: "okay" },
          { body: "Thanks", value: "thanks" }
        ]
      },
      nextStep: this.handleNotEligible
    }
  }
}

export default class UnderAgeSignpostDialogue extends Dialogue<State> {
  static id = DialogueIDs.UnderageSignpostEssex
  readonly name: string = "UnderAgeSignpostEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(UnderAgeSignpostDialogue.id, new UnderAgeSignpostEssexScript(), state, snapshot)
  }
}
