import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { TrackingEvents } from "../../../models/Constants"
import { DialogueIDs } from "../../DialogueIDs"
import {
  EligibilityCheckWithPDSScript,
  EligibilityCheckWithPDSScriptState
} from "./EligibilityCheckWithPDSScript"

interface State extends EligibilityCheckWithPDSScriptState {
  mainProblemAlcoholOrDrugs?: boolean
  signpostUrgentSupport?: boolean
}

export type EligibilityCheckNorfolkScriptState = State

export class EligibilityCheckNorfolkScript extends EligibilityCheckWithPDSScript {
  readonly name: string = "EligibilityCheckNorfolkScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askAreYouInCrisis }
  }

  @step.logState
  askAreYouInCrisis(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Before we go any further I need to establish if you need more urgent help than we can offer, because we are not a crisis service",
        "So, are you currently experiencing a mental health crisis and require immediate help to keep yourself safe?"
      ],
      prompt: {
        id: this.getPromptId("askAreYouInCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askAreYouInCrisis"
      },
      nextStep: this.handleAreYouInCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>) => {
    d.state.experiencingCrisisAndSuicidalThoughts = d.response
    d.state.signpostUrgentSupport = d.response
  })
  handleAreYouInCrisis(d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: d.response //
        ? this.sayCrisis
        : this.askIsMainProblemAboutAlcoholOrDrugs
    }
  }

  @step.logState
  askIsMainProblemAboutAlcoholOrDrugs(_d: IStepData<State>): IStepResult {
    return {
      body: "Is the main problem you want to work on managing your drug and/or alcohol use?",
      prompt: {
        id: this.getPromptId("askIsMainProblemAboutAlcoholOrDrugs"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askIsMainProblemAboutAlcoholOrDrugs"
      },
      nextStep: this.handleIsMainProblemAboutAlcoholOrDrugs
    }
  }

  @step.logStateAndResponse
  async handleIsMainProblemAboutAlcoholOrDrugs(d: IStepData<State, boolean>): Promise<IStepResult> {
    d.state.mainProblemAlcoholOrDrugs = d.response
    if (d.response) {
      return { nextStep: this.askGoToChangeGrowLive }
    }
    return { nextStep: this.continueEligibilityCheck }
  }

  @step.logState
  askGoToChangeGrowLive(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "If you like, I can put you through to Change Grow Live",
        "If this is a main concern for you, then it might be a good fit",
        "Here is a link for you to check out\n\n👉 [Change Grow Live](https://changegrowlive.org/)"
      ],
      prompt: {
        id: this.getPromptId("askGoToChangeGrowLive"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: `No, carry on with ${organisationName}`,
            value: false
          },
          {
            body: "Switch to Change Grow Live",
            value: true
          }
        ]
      },
      nextStep: this.handleGoToChangeGrowLive
    }
  }

  @step.logStateAndResponse
  handleGoToChangeGrowLive(d: IStepData<State, boolean>): IStepResult {
    d.state.mainProblemAlcoholOrDrugs = d.response
    if (d.response) {
      this.track(TrackingEvents.GO_TO_CHANGE_GROW_LIVE)
      return { nextStep: this.goToAlcoholOrDrugsSignpost }
    }
    return { nextStep: this.continueEligibilityCheck }
  }

  @step.logState
  goToAlcoholOrDrugsSignpost(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `It's been great chatting to you, ${name}`,
        "I'll take you there right now",
        "Write 'hi' or 'hello' at any time if you want to carry on with me instead"
      ],
      prompt: {
        id: this.getPromptId("goToAlcoholOrDrugsSignpost"),
        trackResponse: true,
        type: "inlinePicker",
        isUndoAble: true,
        choices: [
          {
            body: "Change Grow Live 👋",
            onPress: () => {
              const a = document.createElement("a")
              a.setAttribute("href", "https://changegrowlive.org/")
              a.setAttribute("target", "_blank")
              a.click()
            }
          }
        ],
        textPrompt: {
          placeholder: 'Type "Hello Limbic" to continue here',
          forceValue: true
        }
      },
      nextStep: this.handleSayHelloToContinue
    }
  }

  @step.logState
  handleSayHelloToContinue(d: IStepData<State, string>): IStepResult {
    const name = this.getName(d.state)
    return { body: `Welcome back ${name}`, nextStep: this.continueEligibilityCheck }
  }

  @step.logState
  sayCrisis(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    this.rootStore.referralStore.setCustomField("earlyCrisis", true)
    return {
      body: [
        `Sorry to hear that ${name}`,
        "It is normal for people to have thoughts of this nature at times",
        "However, it sounds like you might need more immediate help. We are not an emergency response service",
        "To get more appropriate help, you can call NHS 111 and selection Option 2",
        "If you need urgent, life threatening medical help please call 999",
        "Alternatively, you can also call Samaritans on 116 123"
      ],
      prompt: {
        id: this.getPromptId("sayCrisis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [{ body: "I understand" }, { body: "Okay" }]
      },
      nextStep: this.handleSayCrisis
    }
  }

  @step.logState
  handleSayCrisis(_d: IStepData<State>): IStepResult {
    this.referralStore.setCustomField(
      "crisisNumbersShared",
      "999 and Mental Health Crisis Helpline (0800 915 4644)"
    )
    return { nextStep: this.goToGoodbye }
  }

  @step.logState
  continueEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askPostCodeOfUser }
  }

  @step.logState
  @step.setState<State>({ retryPostcodeTimes: 0 } as Partial<State>)
  askPostCodeOfUser(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Ok ${name}. Could you please type your postcode below`,
      prompt: {
        id: this.getPromptId("askPostCodeOfUser"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handlePostCodeOfUserWithCrisis
    }
  }

  /** Generic Handlers */
}

export default class EligibilityCheckNorfolkDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckNorfolk
  readonly name: string = "EligibilityCheckNorfolkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckNorfolkDialogue.id, new EligibilityCheckNorfolkScript(), state, snapshot)
  }
}
