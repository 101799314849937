import React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core"
import "./SOSButton.scss"

function SOSButton(): JSX.Element {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const toggleDialog = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Dialog
        className="lb-sos-dialog"
        disableEnforceFocus
        onClose={toggleDialog}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
      >
        <DialogContent>
          <DialogContentText className="lb-sos-dialog-text">
            <span className="lb-sos-text">
              If you need urgent help with your mental health, you can contact Mind, Samaritans or
              Papyrus. Or text SHOUT to 85258 for 24/7 mental health support
            </span>
            <br />
            <br />
            <span className="lb-sos-text">
              If you or someone you know might harm themselves, visit your nearest A&E or call 999
            </span>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={toggleDialog} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className="lb-sos-container">
        <Button
          onClick={toggleDialog}
          classes={{ root: "lb-sos-button", label: "lb-sos-button-label" }}
          variant="outlined"
        >
          SOS
        </Button>
      </div>
    </>
  )
}

export default SOSButton
