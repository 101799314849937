import { z, ZodSchema } from "zod"
import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptStateSchema } from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"
import { TrackingEvents } from "../../../models/Constants"
import sendEmail from "../../../backend/api/sendEmail"
import { SendEmailStatus } from "../../../models/ISendEmail"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"

interface State extends SelfReferralIAPTScriptState {
  role?: string
  organisation?: string
  mainIssue?: string
  someoneAtRisk?: boolean
  teamSize?: string
  supportFormat?: string
}

export type SelfReferralWellbeingHubManagerTeamScriptState = State

export const SelfReferralWellbeingHubManagerTeamScriptStateSchema =
  SelfReferralIAPTScriptStateSchema.extend({
    role: z.string().optional(),
    organisation: z.string().optional(),
    mainIssue: z.string().optional(),
    someoneAtRisk: z.boolean().optional(),
    teamSize: z.string().optional(),
    supportFormat: z.string().optional()
  })

export class SelfReferralWellbeingHubManagerTeamScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralWellbeingHubManagerTeamScript"

  onHandleSizeOfTeamWithCrisis?(state: State): Promise<IStepResult | void>
  onHandleWelfareSituationThatSupportIsNeededWithCrisis?(state: State): Promise<IStepResult | void>

  /** Script Steps */

  @step.logState
  @step.setState<State>({ addressLookupCounter: 0, postcodeLookupCounter: 0 })
  start(_d: IStepData<State>): IStepResult {
    this.timeEvent(this.name)
    return { nextStep: this.askWannaDoSelfReferral }
  }

  @step.logState
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }

  @step
  askFullName(_d: IStepData<State>): IStepResult {
    return {
      body: ["What's your full name? ✏️", "Feel free to use initials or an alias if preferred"],
      nextStep: this.showPromptForFullName
    }
  }

  @step
  sayPleaseGiveFullName(_d: IStepData<State>): IStepResult {
    return {
      body: ["Please enter your name", "Feel free to use initials or an alias if preferred"],
      nextStep: this.showPromptForFullName
    }
  }

  @step.logState
  showPromptForFullName(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showPromptForFullName"),
        type: "text",
        forceValue: true
      },
      nextStep: this.handleFullNameWithCrisis
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerTeamScript) => {
      const username = d.response?.trim()
      d.state.username = username
      script.rootStore.applicationStore.setUsername(username)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerTeamScript) => s.sayPleaseGiveFullName
  })
  async handleFullNameWithCrisis(_d: IStepData<State, string>): Promise<IStepResult> {
    return { nextStep: this.checkFullName }
  }

  @step.logState
  async checkFullName(d: IStepData<State>): Promise<IStepResult> {
    if (!d.state.username || d.state.username.trim() === "") {
      return { nextStep: this.sayPleaseGiveFullName }
    }
    return { nextStep: this.askRole }
  }

  @step.logState
  @step.logState
  askRole(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const roles = this.getRoles(d.state)
    if (!roles?.length) {
      this.logBreadcrumb("ROLES NOT FOUND", d.state, { roles })
      this.logMessage("ROLES NOT FOUND")
      return { nextStep: this.askOrganisation }
    }

    return {
      body: `Thanks ${name}. Which of these job categories best describes your role? This is to help us support you, all staff will be accepted`,
      prompt: {
        id: this.getPromptId("askRole"),
        type: "inlinePicker",
        choices: roles.map(r => ({ body: r, value: r })),
        textPrompt: {
          forceValue: false,
          placeholder: "Other (please describe)"
        },
        isUndoAble: true
      },
      nextStep: this.handleRole
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerTeamScript) => {
      d.state.role = d.response
      script.referralStore.setCustomField<State>("role", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerTeamScript) => s.askOrganisation
  })
  handleRole(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askOrganisation }
  }

  @step.logState
  @step.logState
  askOrganisation(d: IStepData<State>): IStepResult {
    const organisations = this.getOrganisations(d.state)
    if (!organisations?.length) {
      this.logBreadcrumb("ORGANISATIONS NOT FOUND", d.state, { organisations })
      this.logMessage("ORGANISATIONS NOT FOUND")
      return { nextStep: this.askEmail }
    }

    return {
      body: "And what is the organisation you work for?",
      prompt: {
        id: this.getPromptId("askOrganisation"),
        type: "inlinePicker",
        choices: organisations.map(o => ({ body: o, value: o })),
        isUndoAble: true
      },
      nextStep: this.handleOrganisation
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerTeamScript) => {
      d.state.organisation = d.response
      script.referralStore.setCustomField<State>("organisation", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerTeamScript) => s.askEmail
  })
  handleOrganisation(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askEmail }
  }

  @step.logState
  askEmail(_d: IStepData<State>): IStepResult {
    return {
      body: "What is your email address?",
      prompt: { id: this.getPromptId("askEmail"), type: "email" },
      nextStep: this.handleEmail
    }
  }

  @step.logState
  async goToCollectPhoneNumber(d: IStepData<State>): Promise<IStepResult> {
    const result = await super.goToCollectPhoneNumber(d)
    return { ...result, nextStep: this.askWelfareSituationThatSupportIsNeeded }
  }

  @step.logState
  askWelfareSituationThatSupportIsNeeded(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thanks ${name}. Could you briefly outline the wellbeing situation you would like support with?`,
      prompt: {
        id: this.getPromptId("askWelfareSituationThatSupportIsNeeded"),
        type: "text",
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleWelfareSituationThatSupportIsNeededWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerTeamScript) => {
      d.state.mainIssue = d.response
      script.referralStore.setCustomField<State>("mainIssue", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerTeamScript) => s.askIsAnyoneAtRisk
  })
  async handleWelfareSituationThatSupportIsNeededWithCrisis(
    d: IStepData<State, string>
  ): Promise<IStepResult> {
    const result = await this.onHandleWelfareSituationThatSupportIsNeededWithCrisis?.(d.state)
    if (result) return result
    return { nextStep: this.askIsAnyoneAtRisk }
  }

  @step.logState
  askIsAnyoneAtRisk(_d: IStepData<State>): IStepResult {
    return {
      body: "In your opinion, is any member of the team at risk, in crisis or unable to keep themself safe?",
      prompt: {
        id: this.getPromptId("askIsAnyoneAtRisk"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        isUndoAble: true
      },
      nextStep: this.handleIsAnyoneAtRisk
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, boolean>, script: SelfReferralWellbeingHubManagerTeamScript) => {
      d.state.someoneAtRisk = d.response
      script.referralStore.setCustomField<State>("someoneAtRisk", d.response)
    }
  )
  handleIsAnyoneAtRisk(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return { nextStep: this.sayRiskSignposting }
    }
    // TODO: When we have the backend setup we also need to create a referral for ManagerTeam
    // i.e. nextStep: this.onReferralFinished - and send email there
    return { nextStep: this.askSizeOfTeam }
  }

  @step.logState
  askSizeOfTeam(_d: IStepData<State>): IStepResult {
    return {
      body: `What is the size of the team requiring support?`,
      prompt: {
        id: this.getPromptId("askSizeOfTeam"),
        type: "text",
        forceValue: true,
        cancelIsEmptySubmit: false
      },
      nextStep: this.handleSizeOfTeamWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerTeamScript) => {
      d.state.teamSize = d.response
      script.referralStore.setCustomField<State>("teamSize", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerTeamScript) => s.askIsAnyoneAtRisk
  })
  async handleSizeOfTeamWithCrisis(d: IStepData<State, string>): Promise<IStepResult> {
    const result = await this.onHandleSizeOfTeamWithCrisis?.(d.state)
    if (result) return result
    return { nextStep: this.askFormatOfSupport }
  }

  @step.logState
  askFormatOfSupport(d: IStepData<State>): IStepResult {
    const supportFormats = this.getSupportFormats(d.state)
    if (!supportFormats?.length) {
      this.logBreadcrumb("SUPPORT FORMATS NOT FOUND", d.state, { supportFormats })
      this.logMessage("SUPPORT FORMATS NOT FOUND")
      return { nextStep: this.askOrganisation }
    }

    return {
      body: "Do you know what format you would like this support to take?",
      prompt: {
        id: this.getPromptId("askFormatOfSupport"),
        type: "inlinePicker",
        choices: supportFormats.map(s => ({ body: s, value: s })),
        isUndoAble: true
      },
      nextStep: this.handleFormatOfSupport
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: SelfReferralWellbeingHubManagerTeamScript) => {
      d.state.supportFormat = d.response
      script.referralStore.setCustomField<State>("supportFormat", d.response)
    }
  )
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralWellbeingHubManagerTeamScript) => s.submitReferralEmail
  })
  handleFormatOfSupport(_d: IStepData<State, string>): IStepResult {
    // TODO: When we have the backend setup we also need to create a referral for ManagerTeam
    // i.e. nextStep: this.onReferralFinished - and send email there
    return { nextStep: this.submitReferralEmail }
  }

  sayRiskSignposting(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Thanks ${name}`,
        `${organisationName} will follow up with the team, however it is not a crisis service`,
        "There is some additional information you can share with them if they are in crisis:",
        "For urgent mental health support and advice:",
        "Call NHS 111 for physical or mental health emergencies (available 24/7)",
        "Samaritans: call 116 123 (available 24/7)",
        "SHOUT85258: text 'SHOUT' to 85258 (available 24/7)",
        "Always dial 999 in an emergency or, to contact the Police in a non - emergency, use 101"
      ],
      prompt: {
        id: this.getPromptId("sayRiskSignposting"),
        type: "inlinePicker",
        choices: [
          { body: "Ok", value: false },
          { body: "I understand", value: false }
        ],
        isUndoAble: true
      },
      // TODO: When we have the backend setup we also need to create a referral for ManagerIndividual
      // i.e. nextStep: this.onReferralFinished - and send email there
      nextStep: this.askSizeOfTeam
    }
  }

  @step.logState
  sayReferralSucceeded(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "And that's everything",
        `You've officially submitted this referral to ${organisationName}`
      ],
      nextStep: this.end
    }
  }

  @step.logState
  async submitReferralEmail(d: IStepData<State>): Promise<IStepResult> {
    const emails = this.rootStore.configStore.wellbeingHubEmails ?? []

    try {
      // prettier-ignore
      const text = this.createReferralEmail(d.state)

      // TODO: need to confirm if custom `from` email is needed or just
      // use the default noreply@limbic.ai
      const status = await sendEmail({
        from: "customersupport@limbic.ai",
        subject: "Limbic Referral | Team Support",
        to: emails,
        text
      })
      if (status === SendEmailStatus.SendEmailFailed) {
        d.state.referralSubmitted = false
        d.state.referralSubmissionFailed = true
        this.track(TrackingEvents.MANAGER_TEAM_REFERRAL_NOT_SUBMITTED)
        return { nextStep: this.sayReferralFailed }
      }
      d.state.referralSubmitted = true
      d.state.referralSubmissionFailed = false
      this.track(TrackingEvents.MANAGER_TEAM_REFERRAL_SUBMITTED)
    } catch (e) {
      this.logException(e, "onReferralFinished -> sendEmail")
    }
    return { nextStep: this.sayReferralSucceeded }
  }

  @step.logState
  sayReferralFailed(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit the provided data to ${organisationName}`,
        "I've notified my creators of this issue",
        `If you don't wish to wait, you can manually refer yourself by visiting this page and completing a self-referral`
      ],
      prompt: {
        id: this.getPromptId("sayReferralFailed"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.goToGoodbye
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralWellbeingHubManagerTeamScriptStateSchema
  }

  // TODO: When we have the backend setup we also need to create a referral for ManagerIndividual
  // i.e. nextStep: this.onReferralFinished - and send email there
  async getReferralPayload(_state: State): Promise<Record<string, any>> {
    return {}
  }

  async onHandlePhoneNumber(_state: State): Promise<IStepResult> {
    return { nextStep: this.askWelfareSituationThatSupportIsNeeded }
  }

  async onHandleEmail(_state: State): Promise<IStepResult> {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  createReferralEmail(state: State): string {
    // prettier-ignore
    return `
    <html lang='en'>
      <head>
      <title>Limbic Referral | Team Support</title>
      ${this.getEmailHTMLStyle()}
      </head>
      <body>
        <h1 style="text-align: left;">Limbic Referral | Team Support</h1>
        <b>Referrer name:</b> ${state.username}<br/>
        <b>Referral Type:</b> Team Support<br/>
        <hr/>
        <h3>Referrer Info</h3>
        <b>Name:</b> ${state.username}<br/>
        <b>Role:</b> ${state.role}<br/>
        <b>Organisation:</b> ${state.organisation}<br/>
        <b>Email:</b> ${state.email}<br/>
        <b>Phone Number:</b> ${state.phoneNumber}<br/>
        <h3>Situation Details</h3>
        <b>Situation/issue details:</b> ${state.mainIssue}<br/>
        <b>Team member(s) at risk:</b> ${state.someoneAtRisk}<br/>
        <b>Team size:</b> ${state.teamSize}<br/>
        <b>Support format:</b> ${state.supportFormat}<br/>
      </body>
    </html>
    `.replace(/undefined/gi, "-").replace(/true/gi, "Yes").replace(/false/gi, "No")
  }

  getSupportFormats(_state: State): string[] {
    return []
  }

  getRoles(_state: State): string[] {
    return []
  }

  getOrganisations(_state: State): string[] {
    return []
  }
}

export default class SelfReferralWellbeingHubManagerTeamDialogue extends AdHocDialogue<
  State,
  SelfReferralWellbeingHubManagerTeamScript
> {
  static id = DialogueIDs.SelfReferralWellbeingHubManagerTeam
  readonly name: string = "SelfReferralWellbeingHubManagerTeamDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      SelfReferralWellbeingHubManagerTeamDialogue.id,
      new SelfReferralWellbeingHubManagerTeamScript(),
      state,
      snapshot
    )
  }
}
