import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PHQ9Script } from "./PHQ9"
import type { PHQ9ScriptState } from "./PHQ9"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { RiskLevelReason } from "../../../models/Constants"

type State = PHQ9ScriptState

export class PHQ9NorfolkScript extends PHQ9Script {
  readonly name: string = "PHQ9NorfolkScript"

  /** Generic Handlers */
  async onHandleRiskQ9Score(state: State): Promise<IStepResult | void> {
    this.setCrisisDetected(state)
    this.rootStore.applicationStore.setCurrentProgress(0.88)
    const isRisk = this.clinicalStore.isRisk
    if (!isRisk && state.php9q9Score === 3) {
      this.setRiskLevelHigh(state, RiskLevelReason.PHQ9_Q9)
    }
    return { nextStep: this.goToRiskPathway }
  }
}

export default class PHQ9NorfolkDialogue extends Dialogue<State> {
  static id = DialogueIDs.PHQ9Norfolk
  readonly name: string = "survey:PHQ9_Norfolk Dialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PHQ9NorfolkDialogue.id, new PHQ9NorfolkScript(), state, snapshot)
  }
}
