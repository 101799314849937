import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { AssessmentWithADSMScript, AssessmentWithADSMScriptState } from "./AssessmentWithADSMScript"

type State = AssessmentWithADSMScriptState
export type AssessmentADSMInsightScriptState = State

export class AssessmentADSMInsightScript extends AssessmentWithADSMScript {
  readonly name: string = "AssessmentADSMInsightScript"

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

/* istanbul ignore next */
export default class AssessmentADSMInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentADSMInsight
  readonly name: string = "AssessmentADSMInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentADSMInsightDialogue.id, new AssessmentADSMInsightScript(), state, snapshot)
  }
}
