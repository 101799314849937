import moment from "moment"
import { z, ZodSchema } from "zod"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { SelfReferralIAPTScriptState } from "./SelfReferralIAPTScript"
import SelfReferralIAPTScript, { SelfReferralIAPTScriptStateSchema } from "./SelfReferralIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { isValidLandlineNumber, isValidMobilePhone } from "../../../utils/isvalidPhoneNumber"
import type {
  CIVIL_STATUS_MAYDEN_ITALK,
  IDefaultChatFlowSettingsCollectAlcoholConsumption,
  LIMBIC_IMPACT_LEVEL,
  NATIONALITY_MAYDEN,
  ReferralPayloadMaydenITalk
} from "@limbic/types"
import {
  ARMED_FORCES_MAYDEN_ITALK,
  DISABILITY_MAYDEN_ITALK,
  ETHNICITY_MAYDEN_ITALK,
  GENDER_MAYDEN_ITALK,
  GenderBirthAssigned,
  LANGUAGE_MAYDEN_ITALK,
  LTC_MAYDEN_ITALK,
  PERINATAL_MAYDEN_ITALK,
  RELIGION_MAYDEN_ITALK,
  SEXUALITY_MAYDEN_ITALK
} from "@limbic/types"
import invariant from "../../../utils/invariant"
import {
  disabilities,
  ethnicities,
  exArmedForces,
  genders,
  languages,
  ltcs,
  maritalStatuses,
  nationalities,
  perinatalStatuses,
  religions,
  sameGenderAsBirth,
  sexualities
} from "../../../config/referralForms/italk-form"
import { fullNameRegex } from "../../../utils/fullNameRegex"
import { PronounMaydenITalk } from "@limbic/types/dist/access/remote/mayden/referral"
import { joinWithOr } from "../../../utils/array"
import { DiscussionSteps } from "../../../models/Constants"
import { BaseScriptState } from "../../BaseScript"

export enum ContactOptions {
  "Telephone" = "PHONE",
  "E-mail" = "EMAIL",
  "Letter" = "POST",
  "Text message" = "SMS"
}

interface State extends SelfReferralIAPTScriptState {
  mhSupport?: boolean
  mhDiagnosis?: boolean
  nhsStaff?: boolean
  consentShareInfoHub?: boolean
  nhsOrganisation?: string
  emailPermission?: boolean
  preferredContactMethod?: ContactOptions
  permissionToContactGP?: boolean
  nameOfNextOfKin?: string
  phoneNumberOfNextOfKin?: string
  relationshipOfNextOfKin?: string
  nationality?: string
  requiresInterpreter?: boolean
  accommodationStatus?: string
  accessibilityConsiderations?:
    | "No impairment"
    | "Visual impairment"
    | "Hearing impairment"
    | "Other"
  workWithPhysicalHealthTeams?: boolean
  education?: "No" | "Yes - School" | "Yes - College" | "Yes - University" | "Yes - Other"
  familyArmedForces?: string
  maritalStatus?: string
  drugsToManageMood?: boolean
  agreeProvideDetailsOfNextOfKin?: boolean
  ltcOtherDetails?: string
  preferredTitle?: "Mr" | "Ms" | "Mx" | "Mrs" | "Miss" | "Dr" | "Rev" | "Prof" | "Sir" | "Lady"
  preferredPronouns?: PronounMaydenITalk
}

export type SelfReferralITalkState = State

export const SelfReferralITalkStateSchema = SelfReferralIAPTScriptStateSchema.extend({
  mhSupport: z.boolean().optional(),
  mhDiagnosis: z.boolean().optional(),
  nhsStaff: z.boolean().optional(),
  consentShareInfoHub: z.boolean().optional(),
  nhsOrganisation: z.string().optional(),
  emailPermission: z.boolean().optional(),
  preferredContactMethod: z.nativeEnum(ContactOptions).optional(),
  permissionToContactGP: z.boolean().optional(),
  nameOfNextOfKin: z.string().optional(),
  phoneNumberOfNextOfKin: z.string().optional(),
  relationshipOfNextOfKin: z.string().optional(),
  nationality: z.string().optional(),
  requiresInterpreter: z.boolean().optional(),
  accessibilityConsiderations: z
    .union([
      z.literal("No impairment"),
      z.literal("Visual impairment"),
      z.literal("Hearing impairment"),
      z.literal("Other")
    ])
    .optional(),
  workWithPhysicalHealthTeams: z.boolean().optional(),
  education: z
    .union([
      z.literal("No"),
      z.literal("Yes - School"),
      z.literal("Yes - College"),
      z.literal("Yes - University"),
      z.literal("Yes - Other")
    ])
    .optional(),
  familyArmedForces: z.string().optional(),
  maritalStatus: z.string().optional(),
  drugsToManageMood: z.boolean().optional(),
  agreeProvideDetailsOfNextOfKin: z.boolean().optional(),
  ltcOtherDetails: z.string().optional(),
  preferredTitle: z
    .union([
      z.literal("Mr"),
      z.literal("Mrs"),
      z.literal("Miss"),
      z.literal("Ms"),
      z.literal("Dr"),
      z.literal("Rev"),
      z.literal("Prof"),
      z.literal("Mx"),
      z.literal("Sir"),
      z.literal("Lady")
    ])
    .optional(),
  preferredPronouns: z
    .union([
      z.literal("She/Her/Her"),
      z.literal("He/Him/His"),
      z.literal("They/Them/Their"),
      z.literal("Ae/Aer/Aer"),
      z.literal("Ey/Em/Eir"),
      z.literal("Fae/Faer/Faer"),
      z.literal("Per/Per/Pers"),
      z.literal("Ve/Ver/Vers"),
      z.literal("Xe/Xem/Xyr"),
      z.literal("Ze/Hir/Hir")
    ])
    .optional()
})

export class SelfReferralITalkScript extends SelfReferralIAPTScript {
  readonly name: string = "SelfReferralITalkScript"

  /** Script Steps */

  @step.logState
  startSelfReferralPart1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askMHSupport }
  }

  @step.logState
  askMHSupport(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently, or have been in the last 12 months, under the care of a secondary mental health service? (such as a Community Mental Health Team or Acute Mental Health Team)",
      prompt: {
        id: this.getPromptId("askMHSupport"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askMHSupport"
      },
      nextStep: this.handleMHSupport
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralITalkScript) => {
    d.state.mhSupport = d.response
    script.referralStore.setCustomField<State>("mhSupport", d.response)
  })
  handleMHSupport(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askMHDiagnosis }
  }

  @step.logState
  askMHDiagnosis(_d: IStepData<State>): IStepResult {
    return {
      body: "Have you ever received a mental health diagnosis such as Bi-Polar, Schizophrenia or a Personality Disorder?",
      prompt: {
        id: this.getPromptId("askMHDiagnosis"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askMHDiagnosis"
      },
      nextStep: this.handleMHDiagnosis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralITalkScript) => {
    d.state.mhDiagnosis = d.response
    script.referralStore.setCustomField<State>("mhDiagnosis", d.response)
  })
  handleMHDiagnosis(_d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: this.askNHSStaff
    }
  }

  @step.logState
  askNHSStaff(_d: IStepData<State>): IStepResult {
    return {
      body: "Do you currently work in health or social care",
      prompt: {
        id: this.getPromptId("askNHSStaff"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askNHSStaff"
      },
      nextStep: this.handleNHSStaff
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralITalkScript) => {
    d.state.nhsStaff = d.response
    script.referralStore.setCustomField<State>("nhsStaff", d.response)
  })
  handleNHSStaff(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return {
        body: [
          "If you work for the NHS, or any other health and care organisation in Hampshire and the Isle of Wight, you can also visit [https://www.hiowstaff.nhs.uk](https://www.hiowstaff.nhs.uk) to access support",
          "The HIOW Staff Support Hub is a new dedicated place for health and care staff to access support for your mental health"
        ],
        nextStep: this.askConsentToShareInformationWithHub
      }
    }
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  askConsentToShareInformationWithHub(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you happy to share information with HioW staff support hub for potential additional support?",
      prompt: {
        id: this.getPromptId("askConsentToShareInformationWithHub"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askConsentToShareInformationWithHub"
      },
      nextStep: this.handleConsentToShareInformationWithHub
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralITalkScript) => {
    d.state.consentShareInfoHub = d.response
    script.referralStore.setCustomField<State>("consentShareInfoHub", d.response)
  })
  handleConsentToShareInformationWithHub(_d: IStepData<State, boolean>): IStepResult {
    return { nextStep: this.askWhichOrganisationYouWorkFor }
  }

  @step.logState
  askWhichOrganisationYouWorkFor(_d: IStepData<State>): IStepResult {
    return {
      body: "Which organisation do you work for?",
      prompt: {
        id: this.getPromptId("askWhichOrganisationYouWorkFor"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          // TODO: May need to add more
          {
            body: "Southern Health NHS Foundation Trust",
            value: "Southern Health NHS Foundation Trust"
          },
          {
            body: "Hampshire Hospitals Foundation Trust",
            value: "Hampshire Hospitals Foundation Trust"
          },
          { body: "University Hospital Southampton", value: "University Hospital Southampton" },
          { body: "Isle of Wight NHS Trust", value: "Isle of Wight NHS Trust" },
          {
            body: "Porstmouth Hospitals University NHS Trust",
            value: "Porstmouth Hospitals University NHS Trust"
          },
          { body: "Hampsire County Council", value: "Hampsire County Council" },
          {
            body: "SCAS",
            value: "SCAS"
          },
          {
            body: "Other",
            value: "Other"
          }
        ],
        dataPointsName: "askWhichOrganisationYouWorkFor"
      },
      nextStep: this.handleWhichOrganisationYouWorkFor
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralITalkScript) => {
    d.state.nhsOrganisation = d.response
    script.referralStore.setCustomField<State>("nhsOrganisation", d.response)
  })
  handleWhichOrganisationYouWorkFor(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.goToCollectPhoneNumber }
  }

  @step.logState
  askDoYouHaveAnEmail(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Do you have an email address?",
        "(Please note: An email address is necessary for booking an appointment)"
      ],
      prompt: {
        id: this.getPromptId("askDoYouHaveAnEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes, I have an email", value: true },
          { body: "No, I don't have an email", value: false }
        ]
      },
      nextStep: this.handleDoYouHaveAnEmail
    }
  }

  @step.logState
  askPermissionToSendEmail(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        "Do we have permission to send you an email to that address?",
        `(Please note: If you do not provide consent you will not be sent a confirmation email or booking reminders for any contact with ${organisationName})`
      ],
      prompt: {
        id: this.getPromptId("askPermissionToSendEmail"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPermissionToSendEmail"
      },
      nextStep: this.handlePermissionToSendEmail
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralITalkScript) => {
    d.state.emailPermission = d.response
    script.referralStore.setCustomField<State>("emailPermission", d.response)
  })
  handlePermissionToSendEmail(_d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: this.askPreferredContactMethod
    }
  }

  @step.logState
  askPreferredContactMethod(d: IStepData<State>): IStepResult {
    let options = Object.keys(ContactOptions)
    if (!d.state.emailPermission) {
      options = options.filter(i => i !== "E-mail")
    }
    return {
      body: "And what is your preferred method for correspondence?",
      prompt: {
        id: this.getPromptId("askPreferredContactMethod"),
        trackResponse: true,
        type: "inlinePicker",
        choices: options.map(o => ({ body: o, value: ContactOptions[o] })),
        dataPointsName: "askPreferredContactMethod"
      },
      nextStep: this.handlePreferredContactMethod
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, ContactOptions>, script: SelfReferralITalkScript) => {
    d.state.preferredContactMethod = d.response
    script.referralStore.setCustomField<State>("preferredContactMethod", d.response)
  })
  handlePreferredContactMethod(_d: IStepData<State, ContactOptions>): IStepResult {
    return { nextStep: this.checkPostCodeFromAddressLookup }
  }

  @step.logState
  askAccommodationStatus(_d: IStepData<State>): IStepResult {
    return {
      body: "What is your accommodation status?",
      prompt: {
        id: this.getPromptId("askAccommodationStatus"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Owner Occupier", value: "Owner Occupier" },
          { body: "Tenant - Private Rented", value: "Tenant - Private Rented" },
          {
            body: "Tenant - Local Authority / Housing Association",
            value: "Tenant - Local Authority / Housing Association"
          },
          { body: "Living with Family", value: "Living with Family" },
          { body: "Living with Friends", value: "Living with Friends" },
          { body: "University/College Accommodation", value: "University/College Accommodation" }
        ],
        textPrompt: {
          forceValue: true,
          placeholder: "Other"
        },
        dataPointsName: "askAccommodationStatus"
      },
      nextStep: this.handleAccommodationStatus
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralITalkScript) => {
    d.state.accommodationStatus = d.response
    script.referralStore.setCustomField<State>("accommodationStatus", d.response)
  })
  handleAccommodationStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPermissionToContactGP }
  }

  @step.logState
  askPermissionToContactGP(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: [
        `Do we have permission to update your GP that you have referred to ${organisationName}?`
      ],
      prompt: {
        id: this.getPromptId("askPermissionToContactGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPermissionToContactGP"
      },
      nextStep: this.handlePermissionToContactGP
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralITalkScript) => {
    d.state.permissionToContactGP = d.response
    script.referralStore.setCustomField<State>("permissionToContactGP", d.response)
  })
  handlePermissionToContactGP(_d: IStepData<State, boolean>): IStepResult {
    return {
      nextStep: this.askProvideDetailsNextOfKin
    }
  }

  @step.logState
  askProvideDetailsNextOfKin(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Are you happy to provide details of your next of kin?",
        "This provides the team involved with your care with relevant information should there be an emergency",
        "You can decline to give us next of kin details if you with and this will not affect your treatment with us"
      ],
      prompt: {
        id: this.getPromptId("askProvideDetailsNextOfKin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askProvideDetailsNextOfKin"
      },
      nextStep: this.handleProvideDetailsNextOfKin
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralITalkScript) => {
    d.state.agreeProvideDetailsOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("agreeProvideDetailsOfNextOfKin", d.response)
  })
  handleProvideDetailsNextOfKin(d: IStepData<State, boolean>): IStepResult {
    if (d.response) {
      return {
        nextStep: this.askNameOfNextOfKin
      }
    }
    return {
      nextStep: this.askGender
    }
  }

  @step.logState
  askNameOfNextOfKin(_d: IStepData<State>): IStepResult {
    return {
      body: "What's their full name?",
      prompt: {
        id: this.getPromptId("askNameOfNextOfKin"),
        type: "text",
        validation: [new RegExp(fullNameRegex)],
        validationExplainer: ["Please enter their full name"],
        placeholder: "Please type their name",
        forceValue: true
      },
      nextStep: this.handleNameOfNextOfKinWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralITalkScript) => {
    d.state.nameOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("nameOfNextOfKin", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralITalkScript) => s.askPhoneNumberOfNextOfKin
  })
  handleNameOfNextOfKinWithCrisis(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askRelationshipOfNextOfKin
    }
  }

  @step.logState
  askRelationshipOfNextOfKin(d: IStepData<State>): IStepResult {
    const nameOfNextOfKin = d.state.nameOfNextOfKin
    return {
      body: `What's ${nameOfNextOfKin}'s relationship to you?`,
      prompt: {
        id: this.getPromptId("askRelationshipOfNextOfKin"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Parent", value: "Parent" },
          { body: "Sibling", value: "Sibling" },
          { body: "Child", value: "Child" },
          { body: "Partner", value: "Spouse" },
          { body: "Family member", value: "Family member" },
          { body: "Friend", value: "Friend" },
          { body: "Carer", value: "Carer" }
        ],
        textPrompt: {
          forceValue: true,
          placeholder: "Other (Please specify)"
        },
        dataPointsName: "askRelationshipOfNextOfKin"
      },
      nextStep: this.handleRelationshipOfNextOfKinWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.relationshipOfNextOfKin = d.response
  })
  @step.checkInputForCrisis({ getNextStep: (s: SelfReferralITalkScript) => s.askGender })
  handleRelationshipOfNextOfKinWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPhoneNumberOfNextOfKin }
  }

  @step.logState
  askPhoneNumberOfNextOfKin(d: IStepData<State>): IStepResult {
    const nameOfNextOfKin = d.state.nameOfNextOfKin
    return {
      body: `What's ${nameOfNextOfKin}'s phone number?`,
      prompt: {
        id: this.getPromptId("askPhoneNumberOfNextOfKin"),
        trackResponse: true,
        type: "phoneNumber"
      },
      nextStep: this.handlePhoneNumberOfNextOfKin
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralITalkScript) => {
    d.state.phoneNumberOfNextOfKin = d.response
    script.referralStore.setCustomField<State>("phoneNumberOfNextOfKin", d.response)
  })
  handlePhoneNumberOfNextOfKin(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askGender
    }
  }

  @step.logState
  askGender(d: IStepData<State>): IStepResult {
    const genders = this.getGenders(d.state)
    if (!genders?.length) {
      this.logBreadcrumb("GENDERS NOT FOUND", d.state, { genders })
      this.logMessage("GENDERS NOT FOUND")
      return { nextStep: this.askPrimaryLanguage }
    }

    return {
      body: [
        "Thank you.  We now need to ask you some demographic questions.  We ask this in order to ensure we are equally supporting all members of our community",
        "Which gender do you identify as?"
      ],
      prompt: {
        id: this.getPromptId("askGender"),
        trackResponse: true,
        type: "inlinePicker",
        choices: genders.map(g => ({ body: g, value: g })),
        dataPointsName: "askGender"
      },
      nextStep: this.handleGender
    }
  }

  @step.logState
  askMaritalStatus(d: IStepData<State>): IStepResult {
    const maritalStatuses = this.getMaritalStatuses(d.state)

    if (!maritalStatuses?.length) {
      this.logBreadcrumb("MARITAL STATUSES NOT FOUND", d.state, { maritalStatuses })
      this.logMessage("MARITAL STATUSES NOT FOUND")
      return { nextStep: this.askDisabilityStatus }
    }

    return {
      body: "What is your current relationship status?",
      prompt: {
        id: this.getPromptId("askMaritalStatus"),
        type: "inlinePicker",
        choices: maritalStatuses.map(g => ({ body: g, value: g })),
        dataPointsName: "askMaritalStatus"
      },
      nextStep: this.handleMaritalStatus
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralITalkScript) => {
    d.state.maritalStatus = d.response
    script.referralStore.setCustomField<State>("maritalStatus", d.response)
  })
  handleMaritalStatus(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askNationality }
  }

  @step.logState
  askRequireInterpreter(d: IStepData<State>): IStepResult {
    if (d.state.spineInterpreterRequired !== undefined) {
      return { nextStep: this.askDisability }
    }
    return {
      body: "Do you require an interpreter?",
      prompt: {
        id: this.getPromptId("askRequireInterpreter"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ]
      },
      nextStep: this.handleRequireInterpreter
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralITalkScript) => {
    d.state.requiresInterpreter = d.response
    script.referralStore.setCustomField<State>("requiresInterpreter", d.response)
  })
  handleRequireInterpreter(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askDisability }
  }

  @step.logState
  askAccessibilityConsiderations(_d: IStepData<State>): IStepResult {
    return {
      body: "Are there any other accessibility considerations we should be aware of before making contact?",
      prompt: {
        id: this.getPromptId("askAccessibilityConsiderations"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "No impairment", value: "No impairment" },
          { body: "Visual impairment", value: "Visual impairment" },
          { body: "Hearing impairment", value: "Hearing impairment" },
          { body: "Other", value: "Other" }
        ],
        dataPointsName: "askAccessibilityConsiderations"
      },
      nextStep: this.handleAccessibilityConsiderations
    }
  }

  @step.logStateAndResponse
  @step.handleResponse(
    (
      d: IStepData<State, "No impairment" | "Visual impairment" | "Hearing impairment" | "Other">,
      script: SelfReferralITalkScript
    ) => {
      d.state.accessibilityConsiderations = d.response
      script.referralStore.setCustomField<State>("accessibilityConsiderations", d.response)
    }
  )
  handleAccessibilityConsiderations(
    _d: IStepData<State, "No impairment" | "Visual impairment" | "Hearing impairment" | "Other">
  ): IStepResult {
    return {
      nextStep: this.askLongTermMedicalCondition
    }
  }

  @step.logState
  askLongTermMedicalConditionOtherDetails(_d: IStepData<State>): IStepResult {
    return {
      body: "Please specify",
      prompt: {
        id: this.getPromptId("askLongTermMedicalConditionOtherDetails"),
        trackResponse: true,
        type: "text",
        forceValue: true,
        isUndoAble: true,
        dataPointsName: "askLongTermMedicalConditionOtherDetails"
      },
      nextStep: this.handleLongTermMedicalConditionOtherDetailsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>) => {
    d.state.ltcOtherDetails = d.response
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralITalkScript) => s.askDoesLTCAffectMood
  })
  handleLongTermMedicalConditionOtherDetailsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askDoesLTCAffectMood }
  }

  @step.logState
  askDoesLTCAffectMood(d: IStepData<State>): IStepResult {
    let ltcs
    if (d.state.longTermMedicalCondition?.includes("Other")) {
      if (d.state.longTermMedicalCondition?.length === 1) ltcs = d.state?.ltcOtherDetails
      else {
        const filteredLtcs = d.state.longTermMedicalCondition.filter(i => i !== "Other")
        d.state.ltcOtherDetails && filteredLtcs.push(d.state.ltcOtherDetails)
        ltcs = joinWithOr(filteredLtcs)
      }
    } else {
      ltcs = joinWithOr(d.state.longTermMedicalCondition)
    }

    return {
      body: `Does your ${ltcs} impact your mood?`,
      prompt: {
        id: this.getPromptId("askDoesLTCAffectMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askDoesLTCAffectMood"
      },
      nextStep: this.handleDoesLTCAffectMood
    }
  }

  @step.logState
  askPhysicalHealthTeams(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "It can be helpful for us to work together with your physical health teams",
        "Are you happy for us to keep them updated about your care?"
      ],
      prompt: {
        id: this.getPromptId("askPhysicalHealthTeams"),
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askPhysicalHealthTeams"
      },
      nextStep: this.handlePhysicalHealthTeams
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralITalkScript) => {
    d.state.workWithPhysicalHealthTeams = d.response
    script.referralStore.setCustomField<State>("workWithPhysicalHealthTeams", d.response)
  })
  handlePhysicalHealthTeams(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.askPerinatal }
  }

  @step.logState
  askPerinatal(d: IStepData<State>): IStepResult {
    const perinatalStatuses = this.getPerinatalStatuses(d.state)
    if (!perinatalStatuses?.length) {
      this.logBreadcrumb("PERINATAL STATUSES NOT FOUND", d.state, { perinatalStatuses })
      this.logMessage("PERINATAL STATUSES NOT FOUND")
      return { nextStep: this.askDisabilityStatus }
    }
    return {
      body: [
        "Are you currently pregnant, or do you have a child under the age of 1?",
        "This includes if you are a father or co-parent"
      ],
      prompt: {
        id: this.getPromptId("askPerinatal"),
        trackResponse: true,
        type: "inlinePickerMultiSelect",
        choices: perinatalStatuses.map(g => ({ body: g, value: g })),
        dataPointsName: "askPerinatal"
      },
      nextStep: this.handlePerinatal
    }
  }

  @step.logState
  askEducation(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently in education?",
      prompt: {
        id: this.getPromptId("askEducation"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "No", value: "No" },
          { body: "Yes - School", value: "Yes - School" },
          { body: "Yes - College", value: "Yes - College" },
          { body: "Yes - University", value: "Yes - University" },
          { body: "Yes - Other", value: "Yes - Other" }
        ],
        dataPointsName: "askEducation"
      },
      nextStep: this.handleEducation
    }
  }

  @step.logStateAndResponse
  handleEducation(
    d: IStepData<
      State,
      "No" | "Yes - School" | "Yes - College" | "Yes - University" | "Yes - Other"
    >
  ): IStepResult {
    d.state.education = d.response
    this.referralStore.addClinicalNote(`Education: ${d.response}`)
    return { nextStep: this.askExArmedForces }
  }

  @step.logState
  askFamilyInArmedForces(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you a spouse, partner, ex partner or child of either a serving personnel or ex serving member of the Armed Forces?",
      prompt: {
        id: this.getPromptId("askFamilyInArmedForces"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "No", value: "No" },
          {
            body: "Yes - spouse/partner/child of serving personnel",
            value: "Yes - spouse/partner/child of serving personnel"
          },
          {
            body: "Yes - spouse/partner/child of ex serving member of Armed Forces ",
            value: "Yes - spouse/partner/child of ex serving member of Armed Forces "
          }
        ],
        dataPointsName: "askFamilyInArmedForces"
      },
      nextStep: this.handleFamilyInArmedForces
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralITalkScript) => {
    d.state.familyArmedForces = d.response
    script.referralStore.setCustomField<State>("familyArmedForces", d.response)
  })
  handleFamilyInArmedForces(_d: IStepData<State, string>): IStepResult {
    return {
      nextStep: this.askMainIssue
    }
  }

  @step.logState
  askMainIssue(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Thanks. You're doing really well",
        "What's the main issue that has brought you here today?"
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: true,
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralITalkScript) => {
    d.state.mainIssue = d.response
    script.referralStore.setCustomField<State>("mainIssue", d.response)
  })
  @step.checkInputForCrisis({
    getNextStep: (s: SelfReferralITalkScript) => s.goToCollectAlcoholConsumption
  })
  handleMainIssueWithCrisis(d: IStepData<State, string>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Thank you for sharing, I know that may have been difficult. You're making good progress ${name}, let's continue with the referral`,
      nextStep: this.goToCollectAlcoholConsumption
    }
  }

  @step.logState
  askDrugsToManageMood(_d: IStepData<State>): IStepResult {
    return {
      body: "Are you currently using any non prescribed drugs to manage your mood?",
      prompt: {
        id: this.getPromptId("askDrugsToManageMood"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Yes", value: true },
          { body: "No", value: false }
        ],
        dataPointsName: "askDrugsToManageMood"
      },
      nextStep: this.handleDrugsToManageMood
    }
  }

  @step.logStateAndResponse
  @step.handleResponse((d: IStepData<State, boolean>, script: SelfReferralITalkScript) => {
    d.state.drugsToManageMood = d.response
    script.referralStore.setCustomField<State>("drugsToManageMood", d.response)
  })
  async handleDrugsToManageMood(d: IStepData<State, boolean>): Promise<IStepResult> {
    this.setPeople({ substances: d.response })
    return {
      nextStep: this.askWhatIsYourGoal
    }
  }

  @step.logState
  askWhereDidYouHearAboutUs(_d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    return {
      body: `Before we finish this part, how did you hear about ${organisationName}?`,
      prompt: {
        id: this.getPromptId("askWhereDidYouHearAboutUs"),
        type: "inlinePicker",
        choices: [
          { body: `Used ${organisationName} before`, value: `Used ${organisationName} before` },
          { body: "GP", value: "GP" },
          { body: "Recommended by friend/family", value: "Recommended by friend/family" },
          { body: "Other healthcare professional", value: "Other healthcare professional" },
          { body: "Word of mouth", value: "Word of mouth" },
          { body: "Social media", value: "Social media" },
          { body: "Job centre/employment services", value: "Job centre/employment services" },
          { body: "A workshop at your organisation", value: "A workshop at your organisation" },
          { body: "Event", value: "Event" },
          { body: "Poster/leaflet", value: "Poster/leaflet" },
          { body: "Received a letter", value: "Received a letter" },
          { body: "Search engine (eg. Google)", value: "Search engine (eg. Google)" }
        ],
        textPrompt: {
          cancelIsEmptySubmit: true,
          forceValue: false,
          placeholder: "Other"
        },
        isUndoAble: true
      },
      nextStep: this.handleWhereDidYouHearAboutUsWithCrisis
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, string>, script: SelfReferralITalkScript) => {
    d.state.whereDidYouHearAboutService = d.response
    script.referralStore.setCustomField<State>("whereDidYouHearAboutService", d.response)
  })
  @step.checkInputForCrisis({
    disableDetectionIfWrong: false,
    getNextStep: (s: SelfReferralITalkScript) => s.doReferralSubmission
  })
  handleWhereDidYouHearAboutUsWithCrisis(_d: IStepData<State, string>): IStepResult {
    return { nextStep: this.doReferralSubmission }
  }

  @step.logState
  goToCollectAlcoholConsumption(d: IStepData<State>): IStepResult {
    const CollectAlcoholConsumptionDialogue = this.discussionStore.getDialogueClass(
      DiscussionSteps.CollectAlcoholConsumption
    )

    const collectAlcoholConsumptionState: IDefaultChatFlowSettingsCollectAlcoholConsumption = {
      skipFollowUpQuestions: true
    }

    const nextDialogue = CollectAlcoholConsumptionDialogue
      ? new CollectAlcoholConsumptionDialogue({
          ...d.state,
          ...collectAlcoholConsumptionState
        } as BaseScriptState)
      : undefined

    return {
      nextDialogue,
      nextStep: this.askDrugsToManageMood
    }
  }

  /* Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return SelfReferralITalkStateSchema
  }

  getMaritalStatuses(state: State): string[] {
    return state.iapt?.referralForm?.maritalStatuses ?? []
  }

  getGenderSameAsBirthValues(state: State): string[] {
    return state.iapt?.referralForm?.sameGenderAsBirth ?? []
  }

  async onHandleDoYouHaveAnEmail(state: State): Promise<IStepResult | void> {
    if (state.userHasEmail) {
      return {
        nextStep: this.askEmail
      }
    }
    return {
      nextStep: this.askPreferredContactMethod
    }
  }

  async onHandleEmail(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askPermissionToSendEmail
    }
  }

  async onHandlePermissionToSendMailToAddress(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askAccommodationStatus
    }
  }

  async onHandleGender(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askSameGenderAsBirth
    }
  }

  async onHandleGenderSameAsBirth(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askEthnicity
    }
  }

  async onHandleEthnicity(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askSexuality
    }
  }

  async onHandleSexuality(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askMaritalStatus
    }
  }

  async onHandleNationality(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askReligion }
  }

  async onHandleReligion(_state: State): Promise<IStepResult | void> {
    return { nextStep: this.askPrimaryLanguage }
  }

  async onHandlePrimaryLanguage(state: State): Promise<IStepResult | void> {
    return {
      nextStep: state.primaryLanguage?.match(/english/gi) //
        ? this.askDisability
        : this.askRequireInterpreter
    }
  }

  async onHandleDisability(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askAccessibilityConsiderations
    }
  }

  async onHandleLongTermMedicalCondition(state: State): Promise<IStepResult | void> {
    if (state.longTermMedicalCondition && state.longTermMedicalCondition[0] === "No") {
      return {
        nextStep: this.askPhysicalHealthTeams
      }
    }
    return {
      nextStep: this.askDoesLTCAffectMood
    }
  }

  async onHandleDoesLTCAffectMood(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askPhysicalHealthTeams
    }
  }

  async onHandlePerinatal(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askEducation
    }
  }

  async onHandleExArmedForces(_state: State): Promise<IStepResult | void> {
    return {
      nextStep: this.askFamilyInArmedForces
    }
  }

  async onHandleWhatIsYourGoalWithCrisis(state: State): Promise<IStepResult> {
    return { nextStep: this.askWhereDidYouHearAboutUs }
  }

  async getReferralPayload(state: State): Promise<ReferralPayloadMaydenITalk> {
    const instanceID = state.iapt?.backendInstanceID
    invariant(instanceID, "Cannot create referral without an Instance ID")
    const isValidMobile = isValidMobilePhone(state.phoneNumber || "0")
    const isValidLandline = isValidLandlineNumber(state.phoneNumber || "0") && !isValidMobile

    return {
      instanceID,
      nameFirst: this.getName(state),
      nameLast: this.getLastName(state),
      nameFirstPreferred: state.preferredName,
      title: state.preferredTitle,
      pronounsPreferredITalk: state.preferredPronouns,
      whereHeard: state.whereDidYouHearAboutService,
      dob: moment(state.birthday).format("YYYY-MM-DD"),
      addressHome: {
        address1: state.address,
        address2: state.address2,
        // If address is entered manually then city/county/postcode are undefined
        // Pass an alternate value to avoid errors in the referral submission
        city: state.city || state.address,
        county: state.county || state.address || "n/a",
        postcode: state.userPostcode?.postcode || state.invalidPostcodeEntered || "unknown",
        consentMail: !!state.canSendMailToAddress
      },
      nhsNumber: state.nhsNumber,
      gpName: state.odsGP?.name ?? state.gp?.name,
      gpPractice: state.odsGP?.name ?? state.gp?.name,
      phoneHome: isValidLandline
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: false,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      phoneMobile: isValidMobile
        ? {
            cc: "", // Country Code
            number: state.phoneNumber!,
            isMobile: true,
            consentSMS: !!state.canSendTextMessagesToPhoneNumber,
            consentVM: !!state.canLeaveVoicemailToPhoneNumber
          }
        : undefined,
      email: state.email,
      consentEmail: state.canSendEmail,
      consentDataShare: true,
      consentDataStore: true,
      output: this.referralStore.referralType,
      riskLevel: this.clinicalStore.riskLevel,
      riskLevelReason: this.clinicalStore.riskLevelReason,
      triggerWords: this.clinicalStore.triggerWords,
      alcohol: state.alcohol,
      substancesAreMedication: state.substancesAreMedications,
      substancesInfo:
        !state.substancesAreMedications && state.substancesInfo
          ? [state.substancesInfo]
          : undefined,
      medication:
        state.substancesAreMedications && state.medicationInfo //
          ? [state.medicationInfo]
          : undefined,
      medicationWithinDosage: state.substancesAreMedications
        ? !!state.medicationWithinDoseRange
        : undefined,
      ltc: this.getLTC(state),
      ltcAffectMood: state.ltcAffectsMood,
      ltcMoodImpact: this.getLTCMoodImpact(state),
      disability: this.getDisability(state),
      language: this.getLanguage(state),
      interpreter: state.requiresInterpreter,
      sexuality: this.getSexuality(state),
      gender: this.getGender(state),
      genderSameAsBirthAssigned: this.getGenderSameAsBirthAssigned(state),
      ethnicity: this.getEthnicity(state),
      armedForces: this.getArmedForced(state),
      civilStatus: this.getMaritalStatus(state),
      nationality: this.getNationality(state),
      religion: this.getReligion(state),
      problemInOwnWords: state.mainIssue,
      perinatal: this.getPerinatal(state),
      questionnaires: this.getQuestionnairesPayload(state),
      consentResearch: state.consentResearch,
      currentSupport: state.mhSupport,
      currentSupportDetailsOther: state.mhSupport ? "Yes" : "No",
      diagnosisMHProfessional: state.mhDiagnosis,
      keyWorker: state.nhsStaff,
      consentDataShareHioWHub: state.consentShareInfoHub,
      orgHioWHub: state.nhsOrganisation,
      correspondencePreferred: state.preferredContactMethod,
      consentDataShareGP: state.permissionToContactGP,
      emergencyContactName: state.nameOfNextOfKin,
      emergencyContactRelationship: state.relationshipOfNextOfKin,
      emergencyContactPhone: state.phoneNumberOfNextOfKin,
      accessibilityIssue: state.accessibilityConsiderations,
      consentDataSharePhysicalHealth: state.workWithPhysicalHealthTeams,
      educationSetting: state.education,
      substances: state.drugsToManageMood,
      accommodationStatusDetails: state.accommodationStatus,
      clinicalNotes: this.referralStore.clinicalNotes,
      treatmentExpectation: state.therapyGoal
    }
  }

  getNationalities(state: State): string[] {
    return state.iapt?.referralForm?.nationalities ?? []
  }

  getLanguage(state: State): LANGUAGE_MAYDEN_ITALK | undefined {
    return languages[state.primaryLanguage!]
  }

  getEthnicity(state: State): ETHNICITY_MAYDEN_ITALK {
    return ethnicities[state.ethnicity!] ?? "NOT_ANSWERED"
  }

  getArmedForced(state: State): ARMED_FORCES_MAYDEN_ITALK {
    return exArmedForces[state.isExArmedForces!] ?? "NOT_ANSWERED"
  }

  getMaritalStatus(state: State): CIVIL_STATUS_MAYDEN_ITALK {
    return maritalStatuses[state.maritalStatus!] ?? "NOT_ANSWERED"
  }

  getReligion(state: State): RELIGION_MAYDEN_ITALK {
    return religions[state.religion!] ?? "UNKNOWN"
  }

  getSexuality(state: State): SEXUALITY_MAYDEN_ITALK | undefined {
    // NOT_LISTED when the response if a free text (custom input)
    return sexualities[state.sexuality!] || "NOT_LISTED"
  }

  getNationality(state: State): NATIONALITY_MAYDEN {
    return nationalities[state.nationality!] || "NOT_LISTED"
  }

  getGender(state: State): GENDER_MAYDEN_ITALK {
    return genders[state.gender!] ?? "NOT_LISTED"
  }

  getGenderSameAsBirthAssigned(state: State): GenderBirthAssigned {
    const genderSameAsBirthAssigned = sameGenderAsBirth[state.sameGenderAsBirth!]
    return genderSameAsBirthAssigned ?? "UNKNOWN"
  }

  getPerinatal(state: State): PERINATAL_MAYDEN_ITALK {
    return perinatalStatuses[state.perinatalStatus!] ?? "NOT_ANSWERED"
  }

  getLTCMoodImpact(state: State): LIMBIC_IMPACT_LEVEL | undefined {
    const map: Record<string, LIMBIC_IMPACT_LEVEL> = {
      little: "LITTLE",
      somewhat: "SOMEWHAT",
      very: "VERY"
    }
    return map[state.ltcMoodImpact!]
  }

  getLTC(state: State): LTC_MAYDEN_ITALK[] | undefined {
    const ltc = state.longTermMedicalCondition?.map(i => ltcs[i]).filter(Boolean)
    return ltc?.length ? ltc : undefined
  }

  getDisability(state: State): DISABILITY_MAYDEN_ITALK {
    if (state.disabilityStatus === false) return "NONE"
    if (!state.disabilityStatus || !state.disability?.length) {
      this.logBreadcrumb("getDisability without answer", state)
      this.logMessage("getDisability without answer")
    }
    return disabilities[state.disability!] ?? ["NOT_ANSWERED"]
  }
}

/* istanbul ignore next */
export default class SelfReferralITalkDialogue extends Dialogue<State> {
  static id = DialogueIDs.SelfReferralITalk
  readonly name: string = "SelfReferralITalkDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(SelfReferralITalkDialogue.id, new SelfReferralITalkScript(), state, snapshot)
  }
}
