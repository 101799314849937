import { step } from "../../../backend/chatbot/decorators/step"
import {
  EligibilityCheckIAPTScript,
  EligibilityCheckIAPTState
} from "./EligibilityCheckIAPTDialogue"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import type { IGPService } from "../../../models/IGPService"
import getIAPTById from "../../../utils/getIAPTById"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { IAPTIDs } from "../../../models/IIAPTService"

const gpServices: IGPService[] = [
  {
    id: "0",
    pimsCode: "",
    nacsCode: "",
    name: "Demo Eligible GP",
    formattedName: "Demo Eligible GP",
    postcode: "S43 4PL",
    phoneNumber: "+44 1234 567890",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  },
  {
    id: "1",
    pimsCode: "",
    nacsCode: "",
    name: "Demo Out of Area GP",
    formattedName: "Demo Out of Area GP",
    postcode: "S43 4PL",
    phoneNumber: "+44 1234 567890",
    ccg: {
      id: "10398209",
      name: "NHS Derby And Derbyshire CCG",
      code: "15M"
    },
    isCustom: true
  }
]

type State = EligibilityCheckIAPTState
export type EligibilityCheckDemoState = State
export class EligibilityCheckDemoScript extends EligibilityCheckIAPTScript {
  readonly name: string = "EligibilityCheckDemoScript"

  /** Script Steps */

  @step.logState
  startEligibilityCheck(_d: IStepData<State>): IStepResult {
    return { nextStep: this.saySelectYourGP }
  }

  @step.logState
  saySelectYourGP(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: [
        `So ${name}, in order to find the right health service for you, I first need to locate your registered GP`,
        "Please select your GP from the list",
        "(You can also type to filter them by name)"
      ],
      prompt: {
        id: this.getPromptId("saySelectYourGP"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          ...gpServices.map(gp => ({ body: gp.formattedName, value: gp })),
          { body: "I don't know", value: null, backgroundColor: "#da4b4b" }
        ]
      },
      nextStep: this.handleSelectYourGP
    }
  }

  @step.logState
  handleSelectYourGP(d: IStepData<State, IGPService | null>): IStepResult {
    this.setGP(d.state, d.response || undefined)
    if (!d.state.gp) return { nextStep: this.sayItsImportantToFindGP }

    const isEligible = d.state.gp?.id === "0"
    if (isEligible) {
      const iapt = getIAPTById(IAPTIDs.DEMO)
      this.setIAPT(d.state, iapt)
    }

    const isIneligible = d.state.gp?.id === "1"
    if (isIneligible) {
      return { nextStep: this.sayWeDontSupportYourGP }
    }

    return { nextStep: this.checkUnderAgedForIAPT }
  }

  @step.logState
  sayItsImportantToFindGP(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Okay",
        "It's important that we identify your GP in order to find the right mental health service for you"
      ],
      nextStep: this.sayWithoutGPICannotReferYou
    }
  }

  @step.logState
  sayWeDontSupportYourGP(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    return {
      body: `Unfortunately, ${iaptName} doesn't support your GP`,
      nextStep: this.closeWithCallIntoService
    }
  }

  /** Generic Handlers */

  getNeedsToSelfReferManually(state: State): boolean {
    return false
  }
}

/* istanbul ignore next */
export default class EligibilityCheckDemoDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckDemo
  readonly name: string = "EligibilityCheckDemoDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(EligibilityCheckDemoDialogue.id, new EligibilityCheckDemoScript(), state, snapshot)
  }
}
