import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScriptState } from "./GetNameScript"
import { GetNameIAPTScript } from "./GetNameIAPTDialogue"
import { Titles } from "../../../models/Constants"

type State = GetNameScriptState
export type GetNameNottinghamshireScriptState = State

export class GetNameNottinghamshireScript extends GetNameIAPTScript {
  readonly name: string = "GetNameNottinghamshireScript"

  /** Script Steps */

  @step
  sayNiceToMeetYou(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    return {
      body: `Nice to meet you ${name}`,
      prompt: {
        id: this.getPromptId("sayNiceToMeetYou"),
        type: "inlinePicker",
        choices: [{ body: "Nice to meet you too" }]
      },
      nextStep: this.askHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  askHowWouldYouLikeToBeAddressed(d: IStepData<State>): IStepResult {
    const titles = this.getTitles()
    if (!titles?.length) {
      this.logBreadcrumb("TITLES NOT FOUND", d.state, { titles })
      this.logMessage("TITLES NOT FOUND")
      return { nextStep: this.end }
    }

    return {
      body: "And how would you like to be addressed?",
      prompt: {
        id: this.getPromptId("askHowWouldYouLikeToBeAddressed"),
        type: "inlinePicker",
        choices: titles.map(title => ({ body: title, value: title })),
        isUndoAble: true
      },
      nextStep: this.handleHowWouldYouLikeToBeAddressed
    }
  }

  @step.logState
  @step.handleResponse((d: IStepData<State, Titles>, script: GetNameNottinghamshireScript) => {
    d.state.nameTitle = d.response
  })
  handleHowWouldYouLikeToBeAddressed(d: IStepData<State, string>): IStepResult {
    return { nextStep: this.end }
  }

  /** Generic Handlers */

  getTitles(): Titles[] {
    return [
      Titles.MR,
      Titles.MRS,
      Titles.MISS,
      Titles.MS,
      Titles.DR,
      Titles.REV,
      Titles.PROF,
      Titles.MX
    ]
  }
}

/* istanbul ignore next */
export default class GetNameNottinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameNottinghamshire
  readonly name: string = "GetNameNottinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameNottinghamshireDialogue.id, new GetNameNottinghamshireScript(), state, snapshot)
  }
}
