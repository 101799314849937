import { ITreatmentOption } from "../../../../models/IClinicalPath"

export const treatments: ITreatmentOption[] = [
  {
    name: "ieso",
    formattedName: "typed Cognitive Behavioural Therapy online",
    age: [18],
    order: 0,
    explainer: [
      "For CBT online which is delivered by our partner IESO, you use an instant messaging platform (similar to Whatsapp) to access your one to one support from your therapist",
      "You will have appointments scheduled as normal but instead of communicating face-to-face or via video call in your appointment, you will be typing to one another",
      "This method of therapy is proven to be as effective as face-to-face therapy and IESO will typically look to start treatment within 1 month of assessment which can be significantly faster than waiting for talking therapy options"
    ],
    acceptedResponse: [
      "Once your registration is complete and IESO have received your referral, they will be in contact with you",
      "IESO aim to make contact following referral within 30 days"
    ]
  },
  {
    name: "webinar",
    formattedName: "wellbeing webinar",
    referralType: "Step 1 Limbic Assessment",
    age: [18],
    order: 1,
    explainMore: {
      text: [
        "Wellbeing webinars consist of 6 two hour sessions taking place over 6 consecutive weeks. Webinars are delivered by two qualified therapists and provide you with Cognitive Behavioural Therapy (CBT) tools and techniques for managing symptoms of Depression and Anxiety. The webinar is a ‘one way facing conversation’ therefore the facilitators or other participants cannot see you- making you feel more comfortable when attending. If you wish to actively participate within the sessions there is a chat function for you to use"
      ],
      // Webinar cannot be declined
      choices: [
        { body: "Ok", value: true },
        { body: "👍", value: true }
      ]
    }
  },
  {
    name: "ccbt",
    formattedName: "digital therapy",
    referralType: "Step 1 Limbic Assessment",
    age: [18],
    order: 2,
    explainMore: {
      text: [
        "SilverCloud is our evidence-based, interactive digital therapy platform. SilverCloud is based on Cognitive Behavioural Therapy (CBT) principles and can support individuals with symptoms of Depression and Anxiety across a range of online learning modules. The program provides a very flexible approach to therapy as it can be used via any smart device or laptop and you do not have fixed appointment times. Support is provided once per week from your dedicated therapist via email. Prior to using SilverCloud, one of our therapists will contact you by telephone to explain how the system works and discuss the most appropriate modules for you to complete",
        "Digital therapy is conducted using computer learning and is completed in a person's own time without the need for fixed appointments. You are supported to work through the computer learning by a named practitioner"
      ],
      choices: [
        { body: "Sounds interesting", value: true },
        { body: "No thanks", value: false }
      ]
    },
    acceptedResponse: [
      "One of Everyturn's practitioners will speak to you to discuss the most appropriate treatment option for you. "
    ]
  },
  {
    name: "gsh",
    formattedName: "guided self help",
    referralType: "Step 1 Limbic Assessment",
    age: [18],
    order: 3,
    acceptedResponse: [
      "One of Everyturn's practitioners will speak to you to discuss the most appropriate treatment option for you. "
    ]
  }
]

export default treatments
