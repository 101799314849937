import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { PeaceOfMindScript } from "./PeaceOfMindScript"
import type { PeaceOfMindScriptState } from "./PeaceOfMindScript"

type State = PeaceOfMindScriptState
export type PeaceOfMindIAPTScriptState = State

export class PeaceOfMindIAPTScript extends PeaceOfMindScript {
  readonly name: string = "PeaceOfMindIAPTScript"
}

/* istanbul ignore next */
export default class PeaceOfMindIAPTDialogue extends Dialogue<State> {
  static id = DialogueIDs.PeaceOfMindIAPT
  readonly name: string = "PeaceOfMindIAPTDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(PeaceOfMindIAPTDialogue.id, new PeaceOfMindIAPTScript(), state, snapshot)
  }
}
