import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { IStepResult } from "../../../backend/chatbot/models/IStep"
import { IStepData } from "../../../backend/chatbot/models/IStep"
import AssessmentIAPTScript, { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import { step } from "../../../backend/chatbot/decorators/step"
import { DiscussionSteps } from "../../../models/Constants"

type State = AssessmentIAPTScriptState
export type AssessmentInsightScriptState = State

export class AssessmentInsightScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentInsightScript"

  /** Script Steps */

  @step.logState
  start(d: IStepData<State>): IStepResult {
    if (this.rootStore.clinicalStore.isADSMActive) {
      return { nextStep: this.goToAssessmentADSM }
    }
    return super.start(d)
  }

  @step.logState
  goToAssessmentADSM(d: IStepData<State>): IStepResult {
    // prettier-ignore
    const AssessmentADSMDialogue = this.rootStore.discussionStore.getDialogueClass(DiscussionSteps.AssessmentADSM)
    if (AssessmentADSMDialogue) {
      const nextDialogue = new AssessmentADSMDialogue(d.state)
      return { nextDialogue, nextStep: this.end }
    }

    this.logException(new Error("no nextDialogue"), "goToAssessmentADSM")
    return { nextStep: this.step1 }
  }

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayReadyForWASAS }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    this.rootStore.applicationStore.setCurrentProgress(1)
    return { nextStep: this.sayThatsEverything }
  }
}

/* istanbul ignore next */
export default class AssessmentInsightDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentInsight
  readonly name: string = "AssessmentInsightDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentInsightDialogue.id, new AssessmentInsightScript(), state, snapshot)
  }
}
