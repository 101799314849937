import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { step } from "../../../backend/chatbot/decorators/step"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { GetNameScriptState } from "./GetNameScript"
import { GetNameIAPTScript } from "./GetNameIAPTDialogue"

type State = GetNameScriptState
export type GetNameInsightFollowUpScriptState = State

export class GetNameInsightFollowUpScript extends GetNameIAPTScript {
  readonly name: string = "GetNameInsightFollowUpScript"

  /** Script Steps */

  @step
  step1(_d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }

  async onCheckFullName(_state: State): Promise<IStepResult> {
    return {
      nextStep: this.end
    }
  }
}

/* istanbul ignore next */
export default class GetNameInsightFollowUpDialogue extends Dialogue<State> {
  static id = DialogueIDs.GetNameInsightFollowUp
  readonly name: string = "GetNameInsightFollowUpDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameInsightFollowUpDialogue.id, new GetNameInsightFollowUpScript(), state, snapshot)
  }
}
