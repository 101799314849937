import { z } from "zod"

export const WorkshopStartDatesSchema = z.object({
  id: z.string(),
  startDate: z.string()
})

export const IWorkshopIntervalSchema = z.object({
  description: z.string(),
  startDates: z.array(WorkshopStartDatesSchema)
})

export const ITalkResponseWorkshopSchema = z.object({
  title: z.string(),
  eligibleGPs: z.array(IWorkshopIntervalSchema).optional(),
  isOnline: z.boolean(),
  content: z.string(),
  capacity: z.number(),
  workshopUrl: z.string()
})

export interface ITalkResponseWorkshop {
  title: string
  intervals: Array<IWorkshopInterval>
  isOnline: boolean
  content: string
  capacity: number
  workshopUrl: string
}

export interface IWorkshopInterval {
  description: string
  startDates: Array<{
    id: number
    startDate: number
  }>
}

export interface ITalkResponseShowAppointments {
  telephone: number[]
  workshops: ITalkResponseWorkshop[]
}

export interface ITalkReserveAppointmentResponse {
  success: boolean
  summary: string
  timestamp?: number
  workshop?: number
  startDate?: number
  booking?: number
}
