import React from "react"
import { observer } from "mobx-react"
import "./Sidebar.scss"
import { useConfigStore } from "../../contexts/RootStoreContext"

const URL_RESOURCES = "https://www.sabp.nhs.uk/our-services/advice-guidance/getting-help-crisis"
export interface Props {
  visible?: boolean
}

function Sidebar(props: Props): JSX.Element | null {
  const config = useConfigStore()
  const { visible } = props
  if (!visible) {
    return null
  }

  return (
    <div className="lb-sidebar-container">
      <article className="lb-sidebar-card">
        <div className="lb-header">
          <img src={config.logo} className="lb-header-img" alt="" />
          <h3 className="subtitle">Refer yourself to {config.organisationName}</h3>
        </div>
        <p>
          We are your local NHS talking therapies service. It's a free service. We offer a range of
          short-term psychological therapies to adults
        </p>
        <p>
          Referring yourself through Limbic makes referring to Mind Matters Surrey NHS easier and
          quicker. If you are not in need of urgent help today and prefer to fill in a standard web
          form, you can do so <a href={config.formLink}>here</a>
        </p>
        <p>
          If you feel at risk from yourself or others or if you need urgent, life threatening
          medical help please call 999
        </p>
        <p>
          Alternatively, you can call the Mental Health Crisis Helpline on 0800 915 4644 if it's not
          a life threatening situation, or click{" "}
          <a href={URL_RESOURCES} target="_blank" rel="noreferrer">
            here
          </a>{" "}
          for more resources.
        </p>
        {config.iaptIcons?.map?.(link => (
          <img key={link} className="nhs-logo" src={link} alt={link} />
        ))}
      </article>
      <div className="lb-sidebar-spacer" />
      <article className="lb-sidebar-card full-height">
        <p>
          Limbic helps people access psychological support quicker and easier. Your data is secured
          according to the highest NHS standards.
        </p>
        <img
          className="badges"
          src="https://limbic-web-bot.s3.eu-west-2.amazonaws.com/assets/images/data-pics.png"
          alt={"Badges"}
        />
      </article>
    </div>
  )
}

export default observer(Sidebar)
