import { GetNameScript, GetNameScriptState } from "./GetNameScript"
import { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import AdHocDialogue from "../../../backend/chatbot/AdHocDialogue"

type State = GetNameScriptState

export class GetNameLimbicScript extends GetNameScript {
  readonly name = "GetNameLimbicScript"

  /** Script StepS */

  step1(d: IStepData<State>): IStepResult {
    return { nextStep: this.askFullName }
  }
}

/* istanbul ignore next */
export default class GetNameLimbicDialogue extends AdHocDialogue<State, GetNameLimbicScript> {
  static id = DialogueIDs.GetNameLimbic
  readonly name: string = "GetNameLimbicDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GetNameLimbicDialogue.id, new GetNameLimbicScript(), state, snapshot)
  }
}
