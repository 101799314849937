import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { step } from "../../../backend/chatbot/decorators/step"
import { DialogueIDs } from "../../DialogueIDs"
import AssessmentIAPTScript from "./AssessmentIAPTScript"
import type { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import dialoguesRegistry from "../../dialoguesRegistry"

type State = AssessmentIAPTScriptState

export class AssessmentNottinghamshireScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentNottinghamshireScript"

  @step.logState
  goToGAD7(d: IStepData<State>): IStepResult {
    const GAD7Dialogue = dialoguesRegistry.get(DialogueIDs.GAD7)
    return {
      body: [
        "Ok, let's carry on with the mental health check in",
        "This next set of 7 questions is designed to measure common symptoms of anxiety"
      ],
      nextDialogue: new GAD7Dialogue({ ...d.state }),
      nextStep: this.handleGAD7
    }
  }

  /** Generic Handlers */

  async onHandleIAPTPhobiaScale(_state: State): Promise<IStepResult<State>> {
    return {
      nextStep: this.sayReadyForWASAS
    }
  }

  async onHandleIAPTWorkAndSocialAdjustment(_state: State): Promise<IStepResult<State>> {
    return { nextStep: this.sayThatsEverything }
  }
}

/* istanbul ignore next */
export default class AssessmentNottinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentNottinghamshire
  readonly name: string = "AssessmentNottinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      AssessmentNottinghamshireDialogue.id,
      new AssessmentNottinghamshireScript(),
      state,
      snapshot
    )
  }
}
