import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { step } from "../../../backend/chatbot/decorators/step"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import AssessmentIAPTScript, { AssessmentIAPTScriptState } from "./AssessmentIAPTScript"

type State = AssessmentIAPTScriptState

export type AssessmentWellbeingHubScriptState = State

export class AssessmentWellbeingHubScript extends AssessmentIAPTScript {
  readonly name: string = "AssessmentWellbeingHubScript"

  /** Script Steps */
  @step.logState
  start(_d: IStepData<State>): IStepResult {
    const wellbeingHubUserType = this.wellbeingHubStore.userType
    if (wellbeingHubUserType !== "individual") {
      return {
        nextStep: this.goToGoodbye
      }
    }
    this.rootStore.applicationStore.setTotalProgressBars(4)
    this.rootStore.applicationStore.setCurrentProgressBar(1)
    this.rootStore.applicationStore.setCurrentProgress(0)
    return {
      nextStep: this.step1
    }
  }
}

export default class AssessmentWellbeingHubDialogue extends Dialogue<State> {
  static id = DialogueIDs.AssessmentWellbeingHub
  readonly name: string = "AssessmentWellbeingHubDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(AssessmentWellbeingHubDialogue.id, new AssessmentWellbeingHubScript(), state, snapshot)
  }
}
