import { z, ZodSchema } from "zod"
import BaseScript, { BaseScriptState, BaseScriptStateSchema } from "../../BaseScript"
import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import dialoguesRegistry from "../../dialoguesRegistry"
import { step } from "../../../backend/chatbot/decorators/step"
import sendEmail from "../../../backend/api/sendEmail"
import { TrackingEvents } from "../../../models/Constants"
import { SendEmailStatus } from "../../../models/ISendEmail"
import type { IStepData, IStepResult } from "../../../backend/chatbot/models/IStep"
import { EligibilityCheckCCGState } from "../eligibility/EligibilityCheckCCGDialogue"

interface State extends BaseScriptState {
  bookMentalHealthProfessional?: boolean
  bookMentalHealthProfessionalFailed?: boolean
  userAborted?: boolean
  mainIssue?: string
  accessibilityConsiderations?:
    | "No impairment"
    | "Visual impairment"
    | "Hearing impairment"
    | "Other"
}

export type BookMentalHealthProfessionalKMState = State

export const BookMentalHealthProfessionalKMStateSchema = BaseScriptStateSchema.extend({
  bookMentalHealthProfessional: z.boolean().optional(),
  bookMentalHealthProfessionalFailed: z.boolean().optional(),
  userAborted: z.boolean().optional(),
  mainIssue: z.string().optional(),
  accessibilityConsiderations: z
    .union([
      z.literal("No impairment"),
      z.literal("Visual impairment"),
      z.literal("Hearing impairment"),
      z.literal("Other")
    ])
    .optional()
})

export class BookMentalHealthProfessionalKMScript extends BaseScript<State> {
  readonly name: string = "BookMentalHealthProfessionalKMScript"

  /** Script Steps */

  @step.logState
  start(_d: IStepData<State>): IStepResult {
    return { nextStep: this.step1 }
  }

  @step.logState
  step1(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Would you like to talk to a mental health professional who can go through the different support options available?",
        "Or would you like to make a direct referral into your local NHS talk therapy service?"
      ],
      prompt: {
        id: this.getPromptId("step1"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: "I'd like to discuss options with a mental health professional",
            value: "mentalHealthProfessional"
          },
          { body: "I'd like to refer myself for NHS talk therapy", value: "selfReferral" },
          { body: "Help me decide", value: "helpMeDecide" }
        ]
      },
      nextStep: this.handleTalkToMentalHealthProfessional
    }
  }

  @step.logState
  handleTalkToMentalHealthProfessional(d: IStepData<State>): IStepResult {
    if (d.response === "mentalHealthProfessional") {
      return { nextStep: this.askMainIssue }
    }
    if (d.response === "selfReferral") {
      return { nextStep: this.end }
    }
    return { nextStep: this.explainMentalHealthProfessional }
  }

  @step.logState
  askMainIssue(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "No problem!",
        "Before I complete your booking would you like to tell me the main issue that has brought you here today?",
        "(Please try to describe your thoughts, feelings, things that trouble you, and the impact this is having on your life in a few sentences)"
      ],
      prompt: {
        id: this.getPromptId("askMainIssue"),
        type: "text",
        forceValue: false,
        cancelIsEmptySubmit: true,
        cancelLabel: "skip",
        dataPointsName: "askMainIssue"
      },
      nextStep: this.handleMainIssueWithCrisis
    }
  }

  @step.logState
  @step.handleResponse(
    (d: IStepData<State, string>, script: BookMentalHealthProfessionalKMScript) => {
      d.state.mainIssue = d.response
      script.referralStore.setCustomField<State>("mainIssue", d.response)
    }
  )
  @step.checkInputForCrisis({
    getNextStep: (s: BookMentalHealthProfessionalKMScript) => s.arrangeMentalHealthProfessional
  })
  handleMainIssueWithCrisis(d: IStepData<State, string>): IStepResult {
    return {
      ...(d.response && { body: "Thank you for sharing, I know that may have been difficult" }),
      nextStep: this.arrangeMentalHealthProfessional
    }
  }

  @step.logState
  explainMentalHealthProfessional(_d: IStepData<State>): IStepResult {
    const ccgService = this.rootStore.configStore.ccgService

    return {
      body: [
        `So, ${ccgService?.formattedName} has a number of trained mental health professionals who can contact you to discuss support options available`,
        "If you decide together that you would benefit from further therapy, they may suggest you make a referral into your local NHS talk therapy service",
        "Alternatively, you are free to self-refer into NHS talk therapy at any time, and I can help you do this now if you would like"
      ],
      prompt: {
        id: this.getPromptId("explainMentalHealthProfessional"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: "I'd like to discuss options with a mental health professional",
            value: "mentalHealthProfessional"
          },
          { body: "I'd like to refer myself for NHS talk therapy", value: "selfReferral" },
          { body: "What can I expect from talk therapy?", value: "explainTalkingTherapies" }
        ]
      },
      nextStep: this.handleExplainMentalHealthProfessional
    }
  }

  @step.logState
  handleExplainMentalHealthProfessional(d: IStepData<State>): IStepResult {
    if (d.response === "mentalHealthProfessional") {
      return { nextStep: this.arrangeMentalHealthProfessional }
    }
    if (d.response === "selfReferral") {
      return { nextStep: this.end }
    }
    return { nextStep: this.explainTalkingTherapies }
  }

  @step.logState
  explainTalkingTherapies(_d: IStepData<State>): IStepResult {
    return {
      body: [
        "Well...",
        "Talking therapies can help you deal with negative thoughts and feelings, and make positive changes in your life",
        "The NHS use a specific therapy called 'cognitive behavioural therapy', which can be more effective than medication for adults experiencing similar issues to you",
        "Given you've just been through the toughest year in NHS history, talking therapy might be able to help you with the issues you described earlier"
      ],
      prompt: {
        id: this.getPromptId("explainTalkingTherapies"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: "I'd like to discuss options with a mental health professional",
            value: "mentalHealthProfessional"
          },
          { body: "I'd like to refer myself for NHS talk therapy", value: "selfReferral" },
          { body: "I don't want to do either", value: false }
        ]
      },
      nextStep: this.giveSecondChance
    }
  }

  @step.logState
  giveSecondChance(d: IStepData<State>): IStepResult {
    const name = this.getName(d.state)
    const ccgService = this.rootStore.configStore.ccgService
    const choices =
      d.response === "secondChance"
        ? [
            {
              body: "Ok",
              value: true
            }
          ]
        : [
            {
              body: "Ok",
              value: true
            },
            { body: "Wait, let me see the choices again", value: false }
          ]

    return {
      body: [`Ok ${name}, it's your decision`, `I will inform ${ccgService?.formattedName}`],
      prompt: {
        id: this.getPromptId("giveSecondChance"),
        trackResponse: true,
        type: "inlinePicker",
        choices
      },
      nextStep: this.handleGiveSecondChance
    }
  }

  @step.logState
  async handleGiveSecondChance(d: IStepData<State>): Promise<IStepResult> {
    if (d.response) {
      const userAborted = await this.submitEmail(d.state, "aborted")
      if (!userAborted) {
        this.setPeople({ userAbortedNotificationFailed: true })
        return { nextStep: this.sayBookingFailed }
      }
      d.state.userAborted = true
      this.setPeople({ userAbortedNotificationSuccess: true })
      return { nextStep: this.goToWellBeing }
    }
    return { nextStep: this.showChoicesAgain }
  }

  @step.logState
  showChoicesAgain(_d: IStepData<State>): IStepResult {
    return {
      prompt: {
        id: this.getPromptId("showChoicesAgain"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          {
            body: "I'd like to discuss options with a mental health professional",
            value: "mentalHealthProfessional"
          },
          { body: "I'd like to refer myself for NHS talk therapy", value: "selfReferral" },
          { body: "I don't want to do either", value: "secondChance" }
        ]
      },
      nextStep: this.giveSecondChance
    }
  }

  @step.logState
  handleExplainTalkingTherapies(d: IStepData<State>): IStepResult {
    if (d.response === "mentalHealthProfessional") {
      return { nextStep: this.arrangeMentalHealthProfessional }
    }
    if (d.response === "selfReferral") {
      return { nextStep: this.end }
    }
    return { nextStep: this.explainTalkingTherapies }
  }

  @step.logState
  arrangeMentalHealthProfessional(_d: IStepData<State>): IStepResult {
    return {
      body: "I can create the booking for you now",
      prompt: {
        id: this.getPromptId("arrangeMentalHealthProfessional"),
        trackResponse: true,
        type: "inlinePicker",
        choices: [
          { body: "Thanks", value: true },
          { body: "Ok", value: false }
        ]
      },
      nextStep: this.bookMentalHealthProfessional
    }
  }

  @step.logState
  async bookMentalHealthProfessional(d: IStepData<State, boolean>): Promise<IStepResult> {
    const talkToMentalHealthProfessionalSubmitted = await this.submitEmail(
      d.state,
      "talkToMentalHealthProfessional"
    )
    if (!talkToMentalHealthProfessionalSubmitted) {
      d.state.bookMentalHealthProfessional = false
      d.state.bookMentalHealthProfessionalFailed = true
      this.setPeople({ bookMentalHealthProfessionalFailed: true })
      return { nextStep: this.sayBookingFailed }
    }
    d.state.bookMentalHealthProfessional = true
    d.state.bookMentalHealthProfessionalFailed = false
    this.setPeople({ bookMentalHealthProfessionalSubmitted: true })
    // TODO: Not sure if these two are actually needed
    // await this.onReferralFinished?.(d.state)
    // if (this.clinicalStore.isRisk) await this.onRiskReferralFinished(d.state)
    if (d.response) {
      return {
        body: "You are welcome!",
        nextStep: this.goToWellBeing
      }
    }
    return {
      nextStep: this.goToWellBeing
    }
  }

  @step.logState
  sayBookingFailed(d: IStepData<State>): IStepResult {
    const organisationName = this.rootStore.configStore.organisationName
    const iaptName = this.getIAPTName(d.state) || organisationName
    const wellbeingHubEmails = this.rootStore.configStore.wellbeingHubEmails ?? []
    return {
      body: [
        `Oops... I'm really sorry about this, but it seems like something has gone wrong when trying to submit your data to ${iaptName}`,
        "I've notified my creators of this issue",
        `Please send an email to ${organisationName} at ${wellbeingHubEmails[0]}`
      ],
      prompt: {
        id: this.getPromptId("sayBookingFailed"),
        type: "inlinePicker",
        choices: [{ body: "Okay" }]
      },
      nextStep: this.goToWellBeing
    }
  }

  @step.logState
  goToWellBeing(d: IStepData<State>): IStepResult {
    const WellBeingDialogue = dialoguesRegistry.get(DialogueIDs.WellBeingCCG)
    return {
      nextDialogue: new WellBeingDialogue({ ...d.state }),
      nextStep: this.end
    }
  }

  /** Generic Handlers */

  getStateSchema(): ZodSchema | undefined {
    return BookMentalHealthProfessionalKMStateSchema
  }

  async submitEmail(
    state: State,
    category: "aborted" | "talkToMentalHealthProfessional"
  ): Promise<boolean> {
    const types: Record<string, string> = {
      aborted:
        "completed mental health check-in but did not want to refer into the NHS Staff Mental Wellbeing Hub",
      talkToMentalHealthProfessional: "wants to talk to a Mental Health Professional"
    }
    const subjects: Record<string, string> = {
      aborted: "User Chose Not to Make a Referral",
      talkToMentalHealthProfessional: "New referral to NHS Staff Mental Wellbeing Hub"
    }
    const errors: Record<string, string> = {
      aborted: "abortBooking",
      talkToMentalHealthProfessional: "submitTalkToMentalHealthProfessional"
    }
    try {
      const type = types[category]
      const text = this.createBookingEmail(state, this.clinicalStore.isRisk, type)
      const wellbeingHubEmails = this.rootStore.configStore.wellbeingHubEmails ?? []
      const to = wellbeingHubEmails.filter(Boolean) as string[]
      const subject = subjects[category]
      const organisationName = this.rootStore.configStore.organisationName
      const status = await sendEmail({ to, subject: `Limbic | ${subject}`, text }, organisationName)
      if (status === SendEmailStatus.SendEmailFailed) {
        state.bookMentalHealthProfessionalFailed = true
        this.track(TrackingEvents.BOOK_MENTAL_HEALTH_PROFESSIONAL_NOT_SUBMITTED)
        return false
      }
      if (category === "aborted") this.track(TrackingEvents.BOOKING_ABORTED)
      if (category === "talkToMentalHealthProfessional")
        this.track(TrackingEvents.BOOK_MENTAL_HEALTH_PROFESSIONAL_SUBMITTED)
    } catch (e) {
      this.logException(e, errors[category])
      return false
    }
    return true
  }

  getPersonalInfoHTML(state: State): string {
    const personalInfo = super.getPersonalInfoHTML(state)
    const organisation = this.referralStore.getCustomField<EligibilityCheckCCGState>("organisation")
    const mainIssue = state.mainIssue
    const accessibilityConsiderations = state.accessibilityConsiderations
    const requiresUrgentSupport =
      this.referralStore.getCustomField<EligibilityCheckCCGState>("requiresUrgentSupport")
    const additionalDetails = `<b>Organisation: </b> ${organisation ?? "-"}<br/>
<b>Main issue seeking support for: </b> ${mainIssue ?? "-"}<br/>
<b>Accessibility considerations: </b> ${accessibilityConsiderations ?? "-"}<br/>
<b>User's answer on keeping safe: </b> ${
      requiresUrgentSupport ? "No, I cannot keep myself safe" : "Yes, I can keep myself safe"
    }<br/>`

    return ([] as Array<string | false | undefined>)
      .concat(personalInfo, additionalDetails)
      .filter(Boolean)
      .join("\n")
  }

  getReferralSubmittedIndicatorHTML(): undefined {
    // we never send the referral to the service
    // so on reason to add the indicator
    return undefined
  }
}

/* istanbul ignore next */
export default class BookMentalHealthProfessionalKMDialogue extends Dialogue<State> {
  static id = DialogueIDs.BookMentalHealthProfessionalKM
  readonly name: string = "BookMentalHealthProfessionalKMDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      BookMentalHealthProfessionalKMDialogue.id,
      new BookMentalHealthProfessionalKMScript(),
      state,
      snapshot
    )
  }
}
