import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import { EligibilityCheckIAPTScript } from "./EligibilityCheckIAPTDialogue"
import type { EligibilityCheckIAPTState } from "./EligibilityCheckIAPTDialogue"

type State = EligibilityCheckIAPTState

export type EligibilityCheckNottinghamshireScriptState = State

// IMPORTANT: Notts have requested to not use Spine for now:
// REASON: Notts want to be able to signpost ineligible patients to their nearest IAPT service
// but we can't currently do that with the spine version of the eligibility flow
export class EligibilityCheckNottinghamshireScript extends EligibilityCheckIAPTScript {
  readonly name: string = "EligibilityCheckNottinghamshireScript"

  /** Script Steps */

  /** Generic Handlers */
}

/* istanbul ignore next */
export default class EligibilityCheckNottinghamshireDialogue extends Dialogue<State> {
  static id = DialogueIDs.EligibilityCheckNottinghamshire
  readonly name: string = "EligibilityCheckNottinghamshireDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(
      EligibilityCheckNottinghamshireDialogue.id,
      new EligibilityCheckNottinghamshireScript(),
      state,
      snapshot
    )
  }
}
