import Dialogue, { IDialogueSnapshot } from "../../../backend/chatbot/Dialogue"
import { DialogueIDs } from "../../DialogueIDs"
import type { GoodbyeScriptState } from "./GoodbyeScript"
import GoodbyeScript from "./GoodbyeScript"
import { IStepData, IStepResult, StepResultBodyType } from "../../../backend/chatbot/models/IStep"
import getQRCodeURL from "../../../utils/getQRCodeURL"
import { step } from "../../../backend/chatbot/decorators/step"

interface State extends GoodbyeScriptState {
  canKeepSelfSafe?: boolean
  requiresUrgentSupport?: boolean
}

export type GoodbyeEssexScriptState = State

interface WindowWithLimbicNameSpace extends Window {
  DEEP_LINK: string
}

declare let window: WindowWithLimbicNameSpace

window.DEEP_LINK = String(process.env.REACT_APP_DEEP_LINK ?? "enabled")

const deepLinkEnabled = window.DEEP_LINK !== "disabled"

export class GoodbyeEssexScript extends GoodbyeScript {
  readonly name: string = "GoodbyeEssexScript"

  /** Script Steps */
  @step
  async sayGoodBye(d: IStepData<State>): Promise<IStepResult> {
    void this.referralStore.updateReferral({
      isHelpful: d.state.isHelpful,
      improvementSuggestion: d.state.improvementSuggestion
    })
    this.referralStore.stopPinging()

    return {
      body: "Well done for taking this important step towards better mental health",
      nextStep: this.sayFinalWords
    }
  }

  /** Generic Handlers */
  async getIntroMessage(state: State): Promise<string | void> {
    if (state.needsToCall) {
      return
    }
    return state.requiresUrgentSupport
      ? "I'll be happy to help you with your journey after you've received this immediate support"
      : "I'm looking forward to going on this journey with you"
  }

  async getReferredYouMessage(state: State): Promise<string | void> {
    if (state.referralSubmitted) {
      const serviceName = this.rootStore.configStore.serviceName
      const referralMessage = `I've referred you to ${serviceName}\n`
      return state.isIdleSubmitted //
        ? `It looks like there hasn't been any activity for some time so I've referred you to ${serviceName}`
        : referralMessage
    }
    const organisationName = this.rootStore.configStore.organisationName

    if (state.needsToCall) {
      const reason = this.referralStore.getCustomField("callInReason")
      let body = `You said you would like to talk with a human, therefore I did not refer you to ${organisationName}`
      if (reason === "dataConsent") {
        body = `Because you did not consent to sharing your data with your GP or wider NHS, I cannot refer you to ${organisationName}`
      }
      if (reason === "mobilePhone") {
        body = `You said you don't have a mobile phone number, therefore I cannot refer you to ${organisationName}`
      }
      return `${body}\n\nYou can reach ${organisationName} on the phone numbers above`
    }
    if (state.requiresUrgentSupport) {
      const iaptName = this.getIAPTName(state) || organisationName
      return `Because you are in need of urgent support, I cannot refer you into ${iaptName}\n`
    }
  }

  async getAdminWillCallMessage(state: State): Promise<string | void> {
    const organisationName = this.rootStore.configStore.organisationName
    let msg = state.requiresUrgentSupport
      ? ""
      : `${organisationName} will contact you either by phone or via a text/emailed link to our 'Choose and Book service' in the next working day`
    if (state.needsToCall) {
      return
    }
    if (this.clinicalStore.isHighRisk || this.clinicalStore.isModerateRisk) {
      msg =
        msg +
        `${
          state.requiresUrgentSupport ? "" : "\n\n"
        }Please remember, ${organisationName} is not an emergency response service. If you need urgent help you are encouraged to contact 999 or NHS 111 and select Option 2\n\nYou can also contact the Samaritans on 116123 to talk through how you are feeling`
    }
    if (!state.requiresUrgentSupport) {
      msg =
        msg +
        `\n\nIf you feel that your referral is something you would like to discuss with the team please call us on 01268 739128/01206 334001 to progress this`
    }
    return msg
  }

  async getHighRiskContactMessage(_state: State): Promise<string | void> {
    // Risk is handled in 'getAdminWillCallMessage'
    return ""
  }

  async getModerateRiskContactMessage(_state: State): Promise<string | void> {
    // Risk is handled in 'getAdminWillCallMessage'
    return ""
  }

  async getCustomRecapMessage(state: State): Promise<StepResultBodyType[] | undefined> {
    const hasDynamicLink = deepLinkEnabled && state.careSignupURL
    const isCrisis = this.clinicalStore.isCrisis
    const name = this.getName(state)
    const lastMessage = state.requiresUrgentSupport
      ? `I look forward to talking with you again ${name}`
      : `Well, it's been a pleasure getting to know you ${name}`
    if (state.referralSubmitted && hasDynamicLink) {
      const qrCodeURL = getQRCodeURL(state.careSignupURL!)

      return [
        "We can continue our conversations in my mobile app",
        `If you're already on your mobile device, just click [here](${state.careSignupURL}) to download it`,
        "If you're on a desktop computer, you can scan the QR code below",
        {
          type: "imageAttachment",
          image: qrCodeURL
        },
        isCrisis ? `Thank you for sharing this information with me ${name}` : lastMessage
      ]
    }
    if (state.needsToCall) {
      return
    }
    return [isCrisis ? `Thank you for sharing this information with me ${name}` : lastMessage]
  }
}

export default class GoodbyeEssexDialogue extends Dialogue<State> {
  static id = DialogueIDs.GoodbyeEssex
  readonly name: string = "GoodbyeEssexDialogue"
  constructor(state: State, snapshot?: IDialogueSnapshot<State>) {
    super(GoodbyeEssexDialogue.id, new GoodbyeEssexScript(), state, snapshot)
  }
}
