import { z } from "zod"
import type { ICCG } from "./ICCG"
import type { IODSGP } from "@limbic/types"
import { CCGCode } from "./ICCG"
import { ICCGSchema } from "./ICCG"

export interface IGPService {
  id: string
  pimsCode: string
  nacsCode: string
  name: string
  formattedName: string
  postcode: string
  ccg: ICCG
  phoneNumber?: string
  isCustom?: boolean
  address1?: string
  address2?: string
}

export const IGPServiceSchema = z.object({
  id: z.string(),
  pimsCode: z.string(),
  nacsCode: z.string(),
  name: z.string(),
  formattedName: z.string(),
  postcode: z.string(),
  ccg: ICCGSchema,
  phoneNumber: z.string().optional(),
  isCustom: z.boolean().optional(),
  address1: z.string().optional(),
  address2: z.string().optional()
})

export interface IGPServiceODS extends IODSGP {
  ccgs: CCGCode[]
  isCustom?: boolean
}

export const IGPServiceODSSchema = z.object({
  id: z.string(),
  secondaryID: z.string().optional(),
  name: z.string(),
  ccgs: z.array(z.nativeEnum(CCGCode)),
  phone: z.array(z.string()),
  address: z
    .object({
      address1: z.string().optional(),
      address2: z.string().optional(),
      address3: z.string().optional(),
      city: z.string().optional(),
      county: z.string().optional(),
      province: z.string().optional(),
      postcode: z.string().optional(),
      country: z.string().optional()
    })

    .optional(),
  isCustom: z.boolean().optional()
})
